import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import MainCheckBox from "../common/MainCheckBox";
import KoganProductsTableRow from "./KoganProductsTableRow";
import CustomizedSnackbars from "./notification";
import EditIcon from "@mui/icons-material/Edit";
import KoganCategorySelect from "../settings/KoganCategorySelect";

import {
  Grid,
  Tooltip,
  Button,
  Paper,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  IconButton,
} from "@mui/material";

const KoganProductsTable = ({ koganProducts, count, setCount }) => {
  const total_counts = useSelector((state) => state.total_counts);

  const [selectedSkus, setSelectedSkus] = useState([]);
  console.log("selectedSkus", selectedSkus);

  const [bulkEditCategory, setBulkEditCategory] = useState(false);
  const [koganCategoryLevel1, setKoganCategoryLevel1] =
    useState("Select a Category");
  const [koganCategoryLevel2, setKoganCategoryLevel2] =
    useState("Select a Category");

  const [koganCategoryCode, setKoganCategoryCode] = useState("");
  console.log("koganCategoryCode", koganCategoryCode);

  const handleBulkSaveKoganProductCategory = () => {
    setBulkEditCategory(!bulkEditCategory);
    console.log("kogancategory", koganCategoryLevel1, koganCategoryLevel2);

    fetch("https://api.saleprodev.com/productimporter/kogancategory", {
      method: "post",
      body: JSON.stringify({
        category1: koganCategoryLevel1,
        category2: koganCategoryLevel2,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("kogancategoryRes", res);
        setKoganCategoryCode(res);
      });
  };

  useEffect(() => {
    if (koganCategoryCode !== "") {
      let filteredSelectedSkus = [];
      if (selectedSkus.length !== 0) {
        filteredSelectedSkus = selectedSkus.map((sku) => {
          return {
            product_sku: sku.product_sku,
            category: koganCategoryCode,
            category2: koganCategoryLevel2,
          };
        });
      }
      console.log("filteredSelectedSkus", filteredSelectedSkus);

      fetch("https://api.saleprodev.com/productimporter/koganproductcategory", {
        method: "post",
        body: JSON.stringify(filteredSelectedSkus),
      })
        .then((res) => res.json())
        .then((res) => {
          console.log("koganproductcategory", res);
          //Not updated instantly
          if (res.status === "AsyncResponsePending") {
            setTimeout(() => {
              setCount(count + 1);
            }, 1000);

            setOpenSnackbars({
              open: true,
              message: "Updated, please check after 6hs.",
            });
          }
        });
    }
  }, [koganCategoryCode]);

  const [radioValue, setRadioValue] = useState("");
  const handleChangeRadioButton = (event) => {
    setRadioValue(event.target.value);
  };
  console.log("radioValue", radioValue);

  useEffect(() => {
    if (radioValue !== "") {
      handleBulkDisableKoganProducts();
    }
  }, [radioValue]);

  const handleBulkDisableKoganProducts = () => {
    let filteredSelectedSkus = [];
    if (selectedSkus.length !== 0) {
      if (radioValue === "on") {
        filteredSelectedSkus = selectedSkus.map((sku) => {
          return { product_sku: sku.product_sku, enabled: true };
        });
      }
      if (radioValue === "off") {
        filteredSelectedSkus = selectedSkus.map((sku) => {
          return { product_sku: sku.product_sku, enabled: false };
        });
      }
    }

    console.log("filteredSelectedSkus", filteredSelectedSkus);

    fetch("https://api.saleprodev.com/productimporter/koganproductstatus", {
      method: "post",
      body: JSON.stringify(filteredSelectedSkus),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("koganproductstatus", response);
        if (response.status === "AsyncResponsePending") {
          setTimeout(() => {
            setCount(count + 1);
          }, 3000);

          setOpenSnackbars({
            open: true,
            message: "Pending, please check after 6hs.",
          });
        }
      });
  };

  const [openSnackbars, setOpenSnackbars] = useState({
    open: false,
    message: "",
  });
  const handleCloseSnackbars = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbars({ open: false, message: "" });
  };

  return (
    koganProducts.length !== 0 && (
      <>
        <TableContainer component={Paper} my={2}>
          <Table
            sx={{
              minWidth: 1000,
            }}
            aria-label="caption table"
          >
            <TableHead>
              <TableRow
                sx={{ display: "grid", gridTemplateColumns: "5% 20% 55% 20%" }}
              >
                <TableCell>
                  <MainCheckBox
                    allItems={koganProducts}
                    setSelectedItems={setSelectedSkus}
                  />
                </TableCell>
                <TableCell>
                  <Grid
                    item
                    container
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="body2">SKU</Typography>
                    {selectedSkus.length !== 0 && (
                      <Typography variant="body2">{`(${selectedSkus.length} Skus Selected)`}</Typography>
                    )}
                  </Grid>
                </TableCell>

                <TableCell align="center">
                  <Grid
                    item
                    container
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="body2">Category</Typography>
                    {selectedSkus.length !== 0 && bulkEditCategory && (
                      <Grid
                        sx={{
                          width: "100%",
                          display: "grid",
                          gridTemplateColumns: "50% 50%",
                        }}
                      >
                        <KoganCategorySelect
                          koganCategoryLevel1={koganCategoryLevel1}
                          koganCategoryLevel2={koganCategoryLevel2}
                          setKoganCategoryLevel1={setKoganCategoryLevel1}
                          setKoganCategoryLevel2={setKoganCategoryLevel2}
                        />
                      </Grid>
                    )}
                  </Grid>
                </TableCell>
                <TableCell align="center">
                  <Grid
                    item
                    container
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="body2">Action</Typography>
                    <Grid
                      item
                      container
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={4}>
                        {selectedSkus.length !== 0 && bulkEditCategory ? (
                          <Grid container flexDirection="column">
                            <Button
                              size="small"
                              disabled={
                                koganCategoryLevel1 === "Select a Category" ||
                                koganCategoryLevel2 === "Select a Category"
                              }
                              onClick={handleBulkSaveKoganProductCategory}
                            >
                              Save
                            </Button>

                            <Button
                              size="small"
                              onClick={() =>
                                setBulkEditCategory(!bulkEditCategory)
                              }
                            >
                              Cancel
                            </Button>
                          </Grid>
                        ) : (
                          <Tooltip
                            arrow
                            placement="top"
                            title="Bulk Change Category"
                          >
                            <IconButton
                              size="small"
                              disabled={selectedSkus.length === 0}
                              onClick={() =>
                                setBulkEditCategory(!bulkEditCategory)
                              }
                            >
                              <EditIcon
                                fontSize="20px"
                                sx={{ cursor: "pointer", mr: 0.25 }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>

                      <Grid item xs={8}>
                        <RadioGroup
                          row
                          value={radioValue}
                          onChange={handleChangeRadioButton}
                        >
                          <FormControlLabel
                            value="on"
                            control={
                              <Radio
                                size="small"
                                disabled={selectedSkus.length === 0}
                              />
                            }
                            label="On"
                          />
                          <FormControlLabel
                            value="off"
                            control={
                              <Radio
                                size="small"
                                disabled={selectedSkus.length === 0}
                              />
                            }
                            label="Off"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {koganProducts.map((product, index) => (
                <KoganProductsTableRow
                  key={index}
                  product={product}
                  count={count}
                  setCount={setCount}
                  selectedSkus={selectedSkus}
                  setSelectedSkus={setSelectedSkus}
                />
              ))}
            </TableBody>
          </Table>

          <CustomizedSnackbars
            handleClose={handleCloseSnackbars}
            open={openSnackbars.open}
            message={openSnackbars.message}
          />
        </TableContainer>
      </>
    )
  );
};

export default KoganProductsTable;
