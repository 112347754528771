import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";

import toast from "react-hot-toast";
import axios from "axios";
import {
    Button,
    Grid,
    Dialog,
    DialogActions,
    DialogTitle,
    Typography,
    useMediaQuery
} from "@mui/material";

export const AliexpressToken = (props) => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState({});
    const [showApiKey, setShowApiKey] = useState(false);

    useEffect(() => {
        getUserToken();
    }, []);

    const getUserProfile = () => {
        axios
            .get(`${process.env.REACT_APP_API_DOMAIN_NAME}/auth/getprofile`, {
                withCredentials: true,
            })
            .then((res) => {
                let user = res.data[0];
                console.log("User", user);
                setUser(user);
            });
    };

    const getUserToken = () => {
        axios
            .get(`${process.env.REACT_APP_API_DOMAIN_NAME}/getalitokeninfo`)
            .then((res) => {
               if(res.data){
                 setToken({
                    lastUpdated:new Date(res.data.updatedAt)?.toISOString()?.split('T')[0].toString(),
                    expiry:new Date(+res.data.expiretime).toISOString()?.split('T')[0].toString()

                })

               }
            });
    };

    const generateApiKey = () => {
        axios
            .get(`${process.env.REACT_APP_API_DOMAIN_NAME}/auth/generatekey`, {
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.status === "success") {
                    getUserProfile();
                    toast.success("Api Key Generated Successfully");
                } else {
                    toast.error(res.data.message);
                }
            });
    };

    const handleClickShowApiKey = () => {
        setShowApiKey(!showApiKey);
    };

    const onCopy = () => {
        let ele = document.getElementById("api_key");
        navigator.clipboard.writeText(ele.value);
        toast.success("Api Key Copied");
    };

    const [openDialog, setOpenDialog] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <Grid
            container
            sx={{
                py: 2,
                borderBottom: (theme) => `1px dashed ${theme.palette.border}`,
            }}
        >
            <Grid item container xs={3} sx={{
                alignItems: "center",
            }}>
                <Typography color="textPrimary" variant="h6">
                    AliExpress Token
                </Typography>
            </Grid>

            <Grid item xs={9}>
                <Grid
                    item
                    container
                    my={2}
                    sx={{
                        flexDirection: { xs: "column", sm: "row" },
                        justifyContent: "flex-end",
                        alignItems: "center",
                    }}
                >
                    <Grid
                        item
                        container
                        xs={12}
                        sm={8}
                        mt={{ xs: 2, sm: 0 }}
                        justifyContent={{ xs: "center", sm: "right" }}
                    >

                        <Grid item alignItems="left">
                            <Typography variant="body2">Last Renew Date: {token.lastUpdated}</Typography>
                            <Typography variant="body2">Next Renew Date: {token.expiry}</Typography>
                        </Grid>

                        <Grid item ml={2}>
                            <Button
                                variant="contained"
                                onClick={handleOpenDialog}
                            >
                                Renew
                            </Button>

                            <Dialog
                                fullScreen={fullScreen}
                                open={openDialog}
                                onClose={handleCloseDialog}
                                aria-labelledby="responsive-dialog-title"
                            >
                                <DialogTitle id="responsive-dialog-title">
                                    Are you sure you want to renew Aliexpress token?
                                </DialogTitle>

                                <DialogActions>
                                    <Button autoFocus onClick={handleCloseDialog}>
                                        Cancel
                                    </Button>
                                    <Button
                                        component='a'
                                        variant="contained"
                                        href="https://oauth.aliexpress.com/authorize?response_type=code&client_id=33612499&redirect_uri=https://api.saleprodev.com/aliexpresstoken&view=web&sp=ae"
                                        target="_blank"
                                        onClick={handleCloseDialog}
                                    >
                                        Confirm
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
