import React, { useEffect, useState } from "react";
import { Grid, Button, Chip, TextField, Autocomplete } from "@mui/material";
import { SmallContainedButton } from "../common/SmallContainedButton";
import { InStockCheckbox } from "./instockcheckbox";

export default function MatterHornCategorySelect({
  selectedMatterHornCategoryLevel1,
  setSelectedMatterHornCategoryLevel1,
  selectedMatterHornCategoryLevel2,
  setSelectedMatterHornCategoryLevel2,
  selectedMatterHornCategoryLevel3,
  setSelectedMatterHornCategoryLevel3,
  selectedMatterHornCategoryLevel4,
  setSelectedMatterHornCategoryLevel4,
  selectedMatterHornCategoryLevel5,
  setSelectedMatterHornCategoryLevel5,
  skulist,
  setSkuList,
  filterby,
  loading_products,
  fetchproductcount,
  setFetchProductCount,
}) {
  const [categorieslevel1, setCategoriesLevel1] = useState([]);
  const [categorieslevel2, setCategoriesLevel2] = useState([]);
  const [categorieslevel3, setCategoriesLevel3] = useState([]);
  const [categorieslevel4, setCategoriesLevel4] = useState([]);
  const [categorieslevel5, setCategoriesLevel5] = useState([]);
  useEffect(() => {
    fetch(
      "https://api.saleprodev.com/productimporter/matterhorncategorieslevel1"
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel1(response.categories_level_1);
      });
  }, []);

  useEffect(() => {
    setSelectedMatterHornCategoryLevel2("Select a Category");
    setSelectedMatterHornCategoryLevel3("Select a Category");
    setSelectedMatterHornCategoryLevel4("Select a Category");
    setSelectedMatterHornCategoryLevel5("Select a Category");
    fetch(
      "https://api.saleprodev.com/productimporter/matterhorncategorieslevel2",
      {
        method: "post",
        body: JSON.stringify({ category: selectedMatterHornCategoryLevel1 }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel2(response.categories_level_2);
      });
  }, [selectedMatterHornCategoryLevel1]);

  useEffect(() => {
    setSelectedMatterHornCategoryLevel3("Select a Category");
    setSelectedMatterHornCategoryLevel4("Select a Category");
    setSelectedMatterHornCategoryLevel5("Select a Category");
    fetch(
      "https://api.saleprodev.com/productimporter/matterhorncategorieslevel3",
      {
        method: "post",
        body: JSON.stringify({
          category:
            selectedMatterHornCategoryLevel1 +
            "/" +
            selectedMatterHornCategoryLevel2,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel3(response.categories_level_3);
      });
  }, [selectedMatterHornCategoryLevel2]);

  useEffect(() => {
    setSelectedMatterHornCategoryLevel4("Select a Category");
    setSelectedMatterHornCategoryLevel5("Select a Category");
    fetch(
      "https://api.saleprodev.com/productimporter/matterhorncategorieslevel4",
      {
        method: "post",
        body: JSON.stringify({
          category:
            selectedMatterHornCategoryLevel1 +
            "/" +
            selectedMatterHornCategoryLevel2 +
            "/" +
            selectedMatterHornCategoryLevel3,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel4(response.categories_level_4);
      });
  }, [selectedMatterHornCategoryLevel3]);

  useEffect(() => {
    setSelectedMatterHornCategoryLevel5("Select a Category");
    fetch(
      "https://api.saleprodev.com/productimporter/matterhorncategorieslevel5",
      {
        method: "post",
        body: JSON.stringify({
          category:
            selectedMatterHornCategoryLevel1 +
            "/" +
            selectedMatterHornCategoryLevel2 +
            "/" +
            selectedMatterHornCategoryLevel3 +
            "/" +
            selectedMatterHornCategoryLevel4,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel5(response.categories_level_5);
      });
  }, [selectedMatterHornCategoryLevel4]);

  const handleChange = (event, value) => {
    console.log("value", value);
    if (value === null) {
      setSelectedMatterHornCategoryLevel1("Select a Category");
    } else {
      setSelectedMatterHornCategoryLevel1(value);
    }
  };

  const handleChange2 = (event, value) => {
    console.log("value", value);
    if (value === null) {
      setSelectedMatterHornCategoryLevel2("Select a Category");
    } else {
      setSelectedMatterHornCategoryLevel2(value);
    }
  };

  const handleChange3 = (event, value) => {
    console.log("value", value);
    if (value === null) {
      setSelectedMatterHornCategoryLevel3("Select a Category");
    } else {
      setSelectedMatterHornCategoryLevel3(value);
    }
  };

  const handleChange4 = (event, value) => {
    console.log("value", value);
    if (value === null) {
      setSelectedMatterHornCategoryLevel4("Select a Category");
    } else {
      setSelectedMatterHornCategoryLevel4(value);
    }
  };

  const handleChange5 = (event, value) => {
    console.log("value", value);
    if (value === null) {
      setSelectedMatterHornCategoryLevel5("Select a Category");
    } else {
      setSelectedMatterHornCategoryLevel5(value);
    }
  };

  const defaultProps1 = {
    options: categorieslevel1,
    getOptionLabel: (option) => option,
  };

  const defaultProps2 = {
    options: categorieslevel2,
    getOptionLabel: (option) => option,
  };

  const defaultProps3 = {
    options: categorieslevel3,
    getOptionLabel: (option) => option,
  };

  const defaultProps4 = {
    options: categorieslevel4,
    getOptionLabel: (option) => option,
  };

  const defaultProps5 = {
    options: categorieslevel5,
    getOptionLabel: (option) => option,
  };

  return filterby === "category" ? (
    <>
      <Autocomplete
        size="small"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        {...defaultProps1}
        value={selectedMatterHornCategoryLevel1}
        onChange={(event, value) => handleChange(event, value)}
        renderInput={(params) => <TextField {...params} />}
      />
      <Autocomplete
        size="small"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        {...defaultProps2}
        value={selectedMatterHornCategoryLevel2}
        onChange={(event, value) => handleChange2(event, value)}
        renderInput={(params) => <TextField {...params} />}
      />
      <Autocomplete
        size="small"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        {...defaultProps3}
        value={selectedMatterHornCategoryLevel3}
        onChange={(event, value) => handleChange3(event, value)}
        renderInput={(params) => <TextField {...params} />}
      />
      <Autocomplete
        size="small"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        {...defaultProps4}
        value={selectedMatterHornCategoryLevel4}
        onChange={(event, value) => handleChange4(event, value)}
        renderInput={(params) => <TextField {...params} />}
      />
      <Autocomplete
        size="small"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        {...defaultProps5}
        value={selectedMatterHornCategoryLevel5}
        onChange={(event, value) => handleChange5(event, value)}
        renderInput={(params) => <TextField {...params} />}
      />

      <InStockCheckbox />
    </>
  ) : (
    <>
      <Grid item sx={{ position: "relative" }}>
        <TextField
          multiline
          maxRows={5}
          placeholder="Enter SKUs"
          // skulist={skulist}
          // setSkuList={setSkuList}
          onChange={(event) => setSkuList(event.target.value)}
          sx={{
            mr: 0.5,
            width: "500px",
            fontSize: "0.8rem",
            "& legend": {
              width: "unset",
            },
            "& .MuiFormControl-root, .MuiOutlinedInput-root": {
              p: "0px!important",
              minHeight: 26,
            },
          }}
        />

        {skulist === "" ? (
          ""
        ) : (
          <Chip
            size="small"
            color="primary"
            variant="outlined"
            label={`${skulist.split(/\r\n|\r|\n/).length} Items`}
            sx={{
              height: 20,
              position: "absolute",
              transform:
                skulist.split(/\r\n|\r|\n/).length <= 1
                  ? "translate(-95px, 3px)"
                  : "translate(-95px, 6px)",
            }}
          />
        )}
      </Grid>

      <Grid item>
        <SmallContainedButton
          width="60px"
          disabled={loading_products ? true : false}
          onClick={(event) => setFetchProductCount(fetchproductcount + 1)}
        >
          Find
        </SmallContainedButton>
      </Grid>
    </>
  );
}
