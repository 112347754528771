import React, { useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Typography, Tooltip } from "@mui/material";

const RatingFilter = ({ setRating }) => {
  const [stars, setStars] = useState({
    star1: false,
    star2: false,
    star3: false,
    star4: false,
    star5: false,
  });

  const handleClickStar1 = () => {
    setRating(1);
    setStars({
      star1: true,
      star2: false,
      star3: false,
      star4: false,
      star5: false,
    });
  };

  const handleClickStar2 = () => {
    setRating(2);
    setStars({
      star1: true,
      star2: true,
      star3: false,
      star4: false,
      star5: false,
    });
  };

  const handleClickStar3 = () => {
    setRating(3);
    setStars({
      star1: true,
      star2: true,
      star3: true,
      star4: false,
      star5: false,
    });
  };

  const handleClickStar4 = () => {
    setRating(4);
    setStars({
      star1: true,
      star2: true,
      star3: true,
      star4: true,
      star5: false,
    });
  };

  const handleClickStar5 = () => {
    setRating(5);
    setStars({
      star1: true,
      star2: true,
      star3: true,
      star4: true,
      star5: true,
    });
  };

  const handleResetRating = () => {
    setRating(0);
    setStars({
      star1: false,
      star2: false,
      star3: false,
      star4: false,
      star5: false,
    });
  };

  return (
    <>
      <Typography variant="body2" sx={{ mx: 0.5 }}>
        Rating:
      </Typography>

      <StarIcon
        fontSize="1.25rem"
        color={stars.star1 === true ? "error" : "disabled"}
        onClick={handleClickStar1}
        sx={{ cursor: "pointer" }}
      />
      <StarIcon
        fontSize="1.25rem"
        color={stars.star2 === true ? "error" : "disabled"}
        onClick={handleClickStar2}
        sx={{ cursor: "pointer" }}
      />
      <StarIcon
        fontSize="1.25rem"
        color={stars.star3 === true ? "error" : "disabled"}
        onClick={handleClickStar3}
        sx={{ cursor: "pointer" }}
      />
      <StarIcon
        fontSize="1.25rem"
        color={stars.star4 === true ? "error" : "disabled"}
        onClick={handleClickStar4}
        sx={{ cursor: "pointer" }}
      />
      <StarIcon
        fontSize="1.25rem"
        color={stars.star5 === true ? "error" : "disabled"}
        onClick={handleClickStar5}
        sx={{ cursor: "pointer" }}
      />

      <Typography variant="body2" sx={{ mx: 0.5 }}>
        & Up
      </Typography>

      <Tooltip arrow placement="top" title="Reset Rating">
        <RestartAltIcon
          fontSize="small"
          onClick={handleResetRating}
          sx={{ cursor: "pointer" }}
        />
      </Tooltip>
    </>
  );
};

export default RatingFilter;
