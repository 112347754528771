const initialState = {
  searchField: "",
  articles: [],
  locale: "",
  store_menu: [
    { name: "NZ Shopify", value: "nzshopify" },
    { name: "AU Shopify", value: "aushopify" },
    { name: "US Shopify", value: "usshopify" },
    { name: "GK Shopify", value: "gkshopify" },
    { name : "dropshipzonev2", value : "dropshipzonev2"}
  ],
  supplier_menu: [
    { name: "Banggood", value: "bangood" },
    { name: "AliExpress", value: "aliexpress" },
    { name: "WeFullfill", value: "wefullfill" },
    { name: "Dropsite", value: "dropsite" },
    { name: "Soga", value: "soga" },
    { name: "VidaXL", value: "vidaxl" },
    { name: "Dropshipzone", value: "dropship" },
    { name: "Matterhorn", value: "matterhorn" },
    { name: "FragranceX", value: "fragrancex" },
    { name: "WeDropship", value: "wedropship" },
    { name: "iDropship", value: "idropship" },
    { name: "BigBuy", value: "bigbuy" },
    { name: "IBA Crafts", value: "ibacrafts" },
    { name: "Serrano", value: "serrano" },
    // { name: "Ingram Micro", value: "ingrammicro" },
    // { name: "Griffati", value: "griffati" },
    { name: "Sunsky", value: "sunsky" }

  ],
  warehouse_menu: [
    { name: "CN", value: "CN" },
    { name: "AU", value: "AU" },
    { name: "US", value: "US" },
    { name: "EU", value: "EU" },
  ],
  variant_type_menu: [
    { name: "Single Variant", value: "single" },
    { name: "Multiple Variants", value: "multiple" },
  ],
  collection_menu: ["collection 1", "collection 2", "collection 3"],
  description_menu: [
    { name: "Original", value: "Original" },
    { name: "Simple", value: "Simple" },
    { name: "Images Only", value: "Images Only" },
    { name: "Simple & Original", value: "Simple & Original" },
  ],
  sku_preference_menu: [
    { name: "ShopMaster SKU", value: "shopmaster_sku" },
    { name: "Source Product ID", value: "source_product_id" },
  ],
  sub_settings: "order",
  tools_menu: "aliexpress_mapping",
  settings_item: "",
  pricing_rules: ["ON", "OFF"],
  pricing_formula: ["Simple", "Advanced", "Formula"],
  pricing_rules_selected: "",
  pricing_formula_selected: "",
  country_list_shipfrom: [
    "CN",
    "USA",
    "EU",
    "AU",
    "FR",
    "GWTR",
    "RU",
    "ES",
    "CZ",
    "AE",
    "BR",
    "PL",
    "JP",
    "SA",
    "NL",
    "HK",
    "UK",
  ],
  token: [],
  country_list_shipto: [
    "Australia",
    "New Zealand",
    "United States",
    "United Kingdom",
    "Canada",
  ],
  selected_country_shipfrom: "",
  selected_country_shipto: "",
  shipping_methods: [],
  selected_shipping_method: "",
  import_options: [
    "Import by Category",
    "Import by Product ID",
    "Import by URL",
  ],
  selected_import_option: "",
  categories_level1: [],
  categories_level2: [],
  categories_level3: [],
  selected_category_level2: "",
  selected_category_level3: "",
  selected_category_level4: "",
  selected_category_level5: "",
  selected_category_level6: "",
  categories_level4: [],
  categories_level5: [],
  categories_level6: [],
  imported_products: [],
  imported_products_filter: [],
  show_imported_filtered_products: false,
  loading: false,
  loading_orders: false,
  checkedInStock: false,
  checkedHasVideo: false,
  import_page: 1,
  import_total_pages: 5,
  total_counts: 0,
  products_per_page: 40,
  orders_nz_shopify: [],
  selected_supplier: "",
  selected_warehouse: "ALL",
  selected_variant_type: "single",
  dropship_orders: [],
  fragrancex_orders: [],
  aliexpress_orders: [],
  order_from_date: "",
  order_to_date: "",
  orders_from_to: [],
  show_orders: "",
  show_products: "new",
  listing_status: "",
  purchased_orders: [],
  selected_store: "nzshopify",
  selected_collection: "",
  search_filter_listings: "SKU",
  fullfillment2: {
    location_id: 35132997697,
    status: "success",
    tracking_numbers: [""],
    tracking_urls: [""],
    tracking_company: "",
  },
  shipped_orders: [],
  custom_sheet: [],
  video_sheet: [],
  loading_custom_sheet: false,
  loading_video_sheet: false,
  store: "nzshopify",
  sales_channels: "All NZ",
  purchasingorder: ["", false],
  tracking: ["", false],
  carrier: "NA",
  search_filter_orders: "shopifyorderno",
  loading_products: false,
  importingproduct: false,
  description_preference: "Original",
  sku_preference: "shopmaster_sku",
  open_report: { open: false, report: "" },
  access: "allowed",
};

export const rootReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "CHANGE_SEARCH_FIELD":
      return Object.assign({}, state, { searchField: action.payload });
    case "CHANGE_ARTICLES":
      return Object.assign({}, state, { articles: action.payload });
    case "CHANGE_SUBSETTINGS":
      return Object.assign({}, state, { sub_settings: action.payload });
    case "CHANGE_LOCALE":
      return Object.assign({}, state, { locale: action.payload });
    case "CHANGE_TOOLS_MENU":
      return Object.assign({}, state, { tools_menu: action.payload });
    case "CHANGE_SETTINGS_ITEM":
      return Object.assign({}, state, { settings_item: action.payload });
    case "CHANGE_PRICING_RULE":
      return Object.assign({}, state, {
        pricing_rules_selected: action.payload,
      });
    case "CHANGE_FORMULA":
      return Object.assign({}, state, {
        pricing_formula_selected: action.payload,
      });
    case "CHANGE_TOKEN":
      return Object.assign({}, state, { token: action.payload });
    case "CHANGE_SELECTED_COUNTRY_SHIPFROM":
      return Object.assign({}, state, {
        selected_country_shipfrom: action.payload,
      });
    case "CHANGE_SELECTED_COUNTRY_SHIPTO":
      return Object.assign({}, state, {
        selected_country_shipto: action.payload,
      });
    case "CHANGE_SELECTED_SHIPPING_METHOD":
      return Object.assign({}, state, {
        selected_shipping_method: action.payload,
      });
    case "CHANGE_SHIPPING_METHODS":
      return Object.assign({}, state, { shipping_methods: action.payload });
    case "CHANGE_IMPORT_OPTION":
      return Object.assign({}, state, {
        selected_import_option: action.payload,
      });
    case "CHANGE_CATEGORIES_LEVEL1":
      return Object.assign({}, state, { categories_level1: action.payload });
    case "CHANGE_CATEGORIES_LEVEL2":
      return Object.assign({}, state, { categories_level2: action.payload });
    case "CHANGE_CATEGORIES_LEVEL3":
      return Object.assign({}, state, { categories_level3: action.payload });
    case "CHANGE_SELECTED_CATEGORY_LEVEL2":
      return Object.assign({}, state, {
        selected_category_level2: action.payload,
      });
    case "CHANGE_SELECTED_CATEGORY_LEVEL3":
      return Object.assign({}, state, {
        selected_category_level3: action.payload,
      });
    case "CHANGE_CATEGORIES_LEVEL4":
      return Object.assign({}, state, { categories_level4: action.payload });
    case "CHANGE_CATEGORIES_LEVEL5":
      return Object.assign({}, state, { categories_level5: action.payload });
    case "CHANGE_CATEGORIES_LEVEL6":
      return Object.assign({}, state, { categories_level6: action.payload });
    case "CHANGE_SELECTED_CATEGORY_LEVEL4":
      return Object.assign({}, state, {
        selected_category_level4: action.payload,
      });
    case "CHANGE_SELECTED_CATEGORY_LEVEL5":
      return Object.assign({}, state, {
        selected_category_level5: action.payload,
      });
    case "CHANGE_SELECTED_CATEGORY_LEVEL6":
      return Object.assign({}, state, {
        selected_category_level6: action.payload,
      });
    case "CHANGE_IMPORTED_PRODUCTS":
      return Object.assign({}, state, { imported_products: action.payload });
    case "CHANGE_IMPORTED_PRODUCTS_FILTER":
      return Object.assign({}, state, {
        imported_products_filter: action.payload,
      });
    case "CHANGE_SHOW_IMPORTED_PRODUCTS_FILTER":
      return Object.assign({}, state, {
        show_imported_filtered_products: action.payload,
      });
    case "CHANGE_LOADING":
      return Object.assign({}, state, { loading: action.payload });
    case "CHANGE_CHECKED_IN_STOCK":
      return Object.assign({}, state, { checkedInStock: action.payload });
    case "CHANGE_CHECKED_HAS_VIDEO":
      return Object.assign({}, state, { checkedHasVideo: action.payload });
    case "CHANGE_IMPORT_PAGE":
      return Object.assign({}, state, { import_page: action.payload });
    case "CHANGE_IMPORT_TOTAL_PAGES":
      return Object.assign({}, state, { import_total_pages: action.payload });
    case "CHANGE_TOTAL_COUNTS":
      return Object.assign({}, state, { total_counts: action.payload });
    case "CHANGE_PRODUCTS_PER_PAGE":
      return Object.assign({}, state, { products_per_page: action.payload });
    case "CHANGE_ORDERS_NZ_SHOPIFY":
      return Object.assign({}, state, { orders_nz_shopify: action.payload });
    case "CHANGE_SELECTED_SUPPLIER":
      return Object.assign({}, state, { selected_supplier: action.payload });
    case "CHANGE_SELECTED_WAREHOUSE":
      return Object.assign({}, state, { selected_warehouse: action.payload });
    case "CHANGE_SELECTED_VARIANT_TYPE":
      return Object.assign({}, state, {
        selected_variant_type: action.payload,
      });
    case "CHANGE_DROPSHIP_ORDERS":
      return Object.assign({}, state, { dropship_orders: action.payload });
    case "CHANGE_ORDER_FROM_DATE":
      return Object.assign({}, state, { order_from_date: action.payload });
    case "CHANGE_ORDER_TO_DATE":
      return Object.assign({}, state, { order_to_date: action.payload });
    case "CHANGE_ORDERS_FROM_TO":
      return Object.assign({}, state, { orders_from_to: action.payload });
    case "CHANGE_SHOW_ORDERS":
      return Object.assign({}, state, { show_orders: action.payload });
    case "CHANGE_PURCHASED_ORDERS":
      return Object.assign({}, state, { purchased_orders: action.payload });
    case "CHANGE_SELECTED_STORE":
      return Object.assign({}, state, { selected_store: action.payload });
    case "CHANGE_SELECTED_COLLECTION":
      return Object.assign({}, state, { selected_collection: action.payload });
    case "CHANGE_SEARCH_FILTER_LISTINGS":
      return Object.assign({}, state, {
        search_filter_listings: action.payload,
      });
    case "CHANGE_SEARCH_FILTER_ORDERS":
      return Object.assign({}, state, { search_filter_orders: action.payload });
    case "CHANGE_FULLFILLMENT":
      return Object.assign({}, state, { fullfillment2: action.payload });
    case "CHANGE_LOADING_ORDERS":
      return Object.assign({}, state, { loading_orders: action.payload });
    case "CHANGE_SHIPPED_ORDERS":
      return Object.assign({}, state, { shipped_orders: action.payload });
    case "CHANGE_CUSTOM_SHEET":
      return Object.assign({}, state, { custom_sheet: action.payload });
    case "CHANGE_LOADING_CUSTOM_SHEET":
      return Object.assign({}, state, { loading_custom_sheet: action.payload });
    case "CHANGE_VIDEO_SHEET":
      return Object.assign({}, state, { video_sheet: action.payload });
    case "CHANGE_LOADING_VIDEO_SHEET":
      return Object.assign({}, state, { loading_video_sheet: action.payload });
    case "CHANGE_FRAGRANCEX_ORDERS":
      return Object.assign({}, state, { fragrancex_orders: action.payload });
    case "CHANGE_ALIEXPRESS_ORDERS":
      return Object.assign({}, state, { aliexpress_orders: action.payload });
    case "CHANGE_STORE":
      return Object.assign({}, state, { store: action.payload });
    case "CHANGE_SALES_CHANNELS":
      return Object.assign({}, state, { sales_channels: action.payload });
    case "CHANGE_COLLECTION":
      return Object.assign({}, state, { collection: action.payload });
    case "CHANGE_PURCHASING_ORDER":
      return Object.assign({}, state, { purchasingorder: action.payload });
    case "CHANGE_TRACKING":
      return Object.assign({}, state, { tracking: action.payload });
    case "CHANGE_CARRIER":
      return Object.assign({}, state, { carrier: action.payload });
    case "CHANGE_SHOW_PRODUCTS":
      return Object.assign({}, state, { show_products: action.payload });
    case "CHANGE_LISTING_STATUS":
      return Object.assign({}, state, { listing_status: action.payload });
    case "CHANGE_LOADING_PRODUCTS":
      return Object.assign({}, state, { loading_products: action.payload });
    case "CHANGE_IMPORTING_PRODUCT":
      return Object.assign({}, state, { importingproduct: action.payload });
    case "CHANGE_DESCRIPTION_PREFERENCE":
      return Object.assign({}, state, {
        description_preference: action.payload,
      });
    case "CHANGE_SKU_PREFERENCE":
      return Object.assign({}, state, {
        sku_preference: action.payload,
      });
    case "CHANGE_OPEN_REPORT":
      return Object.assign({}, state, { open_report: action.payload });
    case "CHANGE_ACCESS":
      return Object.assign({}, state, { access: action.payload });
    case "CHANGE_SLOT_ID":
      return Object.assign({}, state, { slot_id: action.payload });
    default:
      return state;
  }
};
