import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { setSearch, setSearchFilterListings } from "../../actions";

import {
  MenuItem,
  Select,
  Grid,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
  InputLabel,
  FormControl,
} from "@mui/material";

export default function SourcingSearchbar({ selected_supplier }) {
  const dispatch = useDispatch();

  const [searchCategory, setSearchCategory] = useState("Product ID / SKU");
  const handleChangeSearchCategory = (event) => {
    setSearchCategory(event.target.value);
  };

  const [searchInputValue, setSearchInputValue] = useState("");
  const handleChangeSearchInput = (event) => {
    setSearchInputValue(event.target.value);
    console.log("value", event.target.value);
    dispatch(setSearchFilterListings(event.target.value));
  };

  const handleSearchByEnter = (event, value) => {
    if (event.key === "Enter") {
      dispatch(setSearch(event.target.value));
    }
  };

  const handleSearchByClick = () => {
    dispatch(setSearch(searchInputValue));
  };

  console.log("searchCategory", searchCategory);

  return (
    <>
      <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
        Search By
      </Typography>

      <Select
        value={searchCategory}
        onChange={handleChangeSearchCategory}
        sx={{
          mx: 0.5,
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
        }}
      >
        <MenuItem value="Keywords" sx={{ height: 26, fontSize: "0.8rem" }}>
          <Typography variant="body2">Keywords</Typography>
        </MenuItem>

        {selected_supplier === "aliexpress" && (
          <MenuItem
            disabled
            value="Store Name"
            sx={{ height: 26, fontSize: "0.8rem" }}
          >
            <Typography variant="body2">Store Name</Typography>
          </MenuItem>
        )}

        {selected_supplier === "aliexpress" && (
          <MenuItem
            disabled
            value="Store ID"
            sx={{ height: 26, fontSize: "0.8rem" }}
          >
            <Typography variant="body2">Store ID</Typography>
          </MenuItem>
        )}

        <MenuItem value="Brand Name" sx={{ height: 26, fontSize: "0.8rem" }}>
          <Typography variant="body2">Brand Name</Typography>
        </MenuItem>

        <MenuItem
          value="Product ID / SKU"
          sx={{ height: 26, fontSize: "0.8rem" }}
        >
          <Typography variant="body2">Product ID / SKU</Typography>
        </MenuItem>
      </Select>

      {/* <TextField
       label="Search your order"
       size="small"
       id="outlined-size-small"
       variant="standard"
       onKeyPress={(event) => handleSearchByEnter(event)}
     /> */}

      <OutlinedInput
        size="small"
        value={searchInputValue}
        onChange={handleChangeSearchInput}
        onKeyPress={handleSearchByEnter}
        placeholder={searchCategory}
        sx={{
          width: 180,
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              sx={{ width: "24px", height: "24px" }}
              aria-label="Search your order"
              onClick={handleSearchByClick}
              edge="end"
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </>
  );
}
