import React, { useState, useEffect } from "react";
import moment from "moment";

import ControlledCheckbox from "./checkbox.js";
import no_image from "../assets/images/no_image.jpg";

import { Card, Grid, Box, Tooltip, Typography } from "@mui/material";

export default function IbaCraftsProductsLayout({
  product,
  handleImport,
  selectedProducts,
  setSelectedProducts,
  fetchproducts,
}) {
  const formatDate = (date) => moment(date).format("DD/MM/YYYY HH:mm:ss");

  const CalculatePrice = () => {
    let prices = [];
    if (product.has_variants) {
      product.has_variants.map((item) => {
        prices.push(parseFloat(item.price));
      });
      return `${Math.min(...prices)} - ${Math.max(...prices)}`;
    }
  };

  const CalculateStock = () => {
    let prices = [];
    if (product.has_variants) {
      product.has_variants.map((item) => {
        prices.push(parseFloat(item.quantity));
      });
      return `${Math.min(...prices)} - ${Math.max(...prices)}`;
    }
  };


  const getImage =()=>{
    if(Array.isArray(product.image_urls))
    {
      return product.image_urls[0]
    }
    return product?.image_urls?.split(',')[0]
  }

  return (
    <Card sx={{ width: "100%", mt: 1 }}>
      <Grid
        container
        sx={{
          minHeight: "70px",
          display: "Grid",
          gridTemplateColumns: "2% 4% 35% 8% 8% 10% 8% 5% 5% 5% 6% 4%",
          alignItems: "center",
        }}
      >
        {/* Checkbox */}
        <Grid container justifyContent="center">
          <ControlledCheckbox
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            product={product}
          />
        </Grid>

        {/* Image */}
        <Grid container justifyContent="center" alignItems="center" p={0.5}>
          <Tooltip
            arrow
            placement="top"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  padding: 0,
                },
              },
            }}
            title={
              <img
                src={
                    getImage()
                }
                alt=""
                style={{
                  width: "250px",
                }}
              />
            }
          >
            <Grid
              item
              justifyContent="center"
              alignContent="center"
              sx={{
                minHeight: "60px",
                border: (theme) => `1px solid ${theme.palette.border}`,
              }}
            >
              <img
                src={
                  product.image_urls
                    ? getImage()
                    : no_image
                }
                alt=""
                width="60px"
              />
            </Grid>
          </Tooltip>
        </Grid>

        {/* Title & Sku # & Product ID */}
        <Grid container flexDirection="column" pl={1}>
          {product.item_name && product.item_name.length > 90 ? (
            <Tooltip arrow placement="top" item_name={product.item_name}>
              <Typography variant="body2">
                {`${product.item_name.slice(0, 90)}...`}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="body2">{product.item_name}</Typography>
          )}

          <Grid container>
            <Typography variant="body2" color="primary">
              {product.sku && `Sku #: ${product.sku}`}
            </Typography>

            {/* <Typography component="a" href={`https://app.wefullfill.com/users/products/wefullfill/${product.id}`} target="_blank" variant="body2" color="primary" sx={{ mx: 3 }}>
              {product.id && `Product ID:  ${product.id}`}
            </Typography> */}
          </Grid>
        </Grid>

        {/* Barcode */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.barcode === null || product.barcode === ""
              ? "N/A"
              : product.barcode}
          </Typography>
        </Grid>

        {/* Weight */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {!product.weight
              ? "N/A"
              : `${product?.weight}kg`}
          </Typography>
        </Grid>

        {/* Cost */}
        <Grid container justifyContent="center">
        <Typography variant="body2">{product.product_cost}</Typography>
        </Grid>

        {/* Shipping Cost */}
        <Grid container flexDirection="column" alignItems="center">
          <Typography variant="body2">
            {product.shipping_cost? product.shipping_cost : 'N/A'}
          </Typography>
        </Grid>

        {/* Variation */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {Array.isArray(product.variants)
              ? product.variants.length
              : 0}
          </Typography>
        </Grid>

        <Grid
          container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="body2">
            {product?.warehouse?.split(" ")[0]}
          </Typography>

          <Typography variant="body2">{product.quantity}</Typography>
        </Grid>

        <Grid container justifyContent="center">
          <Typography
            component="a"
            href="/"
            // href={product.supplier.url}
            target="_blank"
            variant="body2"
            color="primary"
          >
            Ibacrafts
          </Typography>
        </Grid>

        {/* Created At */}
        <Grid container justifyContent="center">
          <Typography variant="body2" textAlign="center">
            {formatDate(product.createdAt)}
          </Typography>
        </Grid>

        {/* <div style={{ textAlign: "center" }}>
          {product.import_status === null && "Not Imported"}
          {product.import_status === "imported" && "Imported"}
          {product.import_status === "exported" && "Exported"}
          {product.import_status === "deleted" && "Deleted"}
        </div> */}
        {
          // <div style={{textAlign:"center"}}>
          //   <Typography style={{color:"#5b5b5e"}}><Button variant="contained" size="small" onClick={(event) => handleImport(product)}>Import</Button></Typography>
          // </div>
        }
      </Grid>
    </Card>
  );
}
