import React from "react";
import {TextField,Button} from '@mui/material'
import {useFormik} from "formik"
import * as Yup from "yup"
import Axios from "axios"
import {useSelector, useDispatch} from "react-redux"




const style = {
  marginTop:"1%"
}

export function DropshipMapProduct({supplier}) {
  const store = useSelector(state => state.store)
  const schema = Yup.object({
    sku:Yup.string().required("sku is required"),
    variantid:Yup.string().required("variantid is required"),
    supplier_sku:Yup.string().required("supplier_sku is required")
  })

  const formik = useFormik({
    initialValues:{
      sku:"",
      inventory_item_id:""
    },
    onSubmit: async (values, helpers) => {
      values.supplier = supplier
      values.store = store
      console.log(values)
      try {
        Axios.post(`https://api.saleprodev.com/inventoryerror/mapsku`, values).then(response => {
          console.log(response)
          if(response.data.errors){
            alert(response.data.errors[0].message)
          }
          if(response.data.sku){
            alert("Success")
          } else {
            if(response.data.status !== 200){
              alert("Please Check Variant ID")
            }
          }


        })
      } catch(error){
        helpers.setErrors({submit:error.message})
      }
    },
    validationSchema:schema
  })

  return (
    <form style={style} onSubmit={formik.handleSubmit}>
      <TextField
      size="small"
      placeholder="sku"
      label="sku"
      name="sku"
      error={formik.errors.sku}
      helperText={formik.errors.sku}
      onChange={formik.handleChange}
      style={{width:"200px",marginLeft:"1%"}}
      />
      <TextField
      size="small"
      label="variantid"
      placeholder="variantid"
      name="variantid"
      error={formik.errors.variantid}
      helperText={formik.errors.variantid}
      onChange={formik.handleChange}
      style={{width:"200px",marginLeft:"1%"}}
      />
      <TextField
      size="small"
      label="supplier_sku"
      placeholder="supplier_sku"
      name="supplier_sku"
      error={formik.errors.supplier_sku}
      helperText={formik.errors.supplier_sku}
      onChange={formik.handleChange}
      style={{width:"200px",marginLeft:"1%"}}
      />
      <Button variant="contained" type="submit" name="submit" style={{marginLeft:"1%"}}>Submit</Button>
    </form>
  )
}

export function BangoodMapProduct({supplier}) {
  const store = useSelector(state => state.store)
  const schema = Yup.object({
    sku:Yup.string().required("sku is required"),
    variantid:Yup.string().required("variantid is required"),
    supplier_sku:Yup.string().required("supplier_sku is required"),
    warehouse:Yup.string().required("warehouse is required")
  })

  const formik = useFormik({
    initialValues:{
      sku:"",
      inventory_item_id:""
    },
    onSubmit: async (values, helpers) => {
      values.supplier = supplier
      values.store = store
      console.log(values)
      try {
        Axios.post(`https://api.saleprodev.com/inventoryerror/mapsku`, values).then(response => {
          console.log(response)
          if(response.data.errors){
            alert(response.data.errors[0].message)
          }
          if(response.data.sku){
            alert("Success")
          } else {
            if(response.data.status !== 200){
              alert("Please Check Variant ID")
            }
          }


        })
      } catch(error){
        helpers.setErrors({submit:error.message})
      }
    },
    validationSchema:schema
  })

  return (
    <form style={style} onSubmit={formik.handleSubmit}>
      <TextField
      size="small"
      placeholder="sku"
      label="sku"
      name="sku"
      error={formik.errors.sku}
      helperText={formik.errors.sku}
      onChange={formik.handleChange}
      style={{width:"200px",marginLeft:"1%"}}
      />
      <TextField
      size="small"
      label="variantid"
      placeholder="variantid"
      name="variantid"
      error={formik.errors.variantid}
      helperText={formik.errors.variantid}
      onChange={formik.handleChange}
      style={{width:"200px",marginLeft:"1%"}}
      />
      <TextField
      size="small"
      label="supplier_sku"
      placeholder="supplier_sku"
      name="supplier_sku"
      error={formik.errors.supplier_sku}
      helperText={formik.errors.supplier_sku}
      onChange={formik.handleChange}
      style={{width:"200px",marginLeft:"1%"}}
      />
      <TextField
      size="small"
      label="warehouse"
      placeholder="warehouse"
      name="warehouse"
      error={formik.errors.warehouse}
      helperText={formik.errors.warehouse}
      onChange={formik.handleChange}
      style={{width:"200px",marginLeft:"1%", marginTop:"1%"}}
      />
      <Button variant="contained" type="submit" name="submit" style={{marginLeft:"1%", marginTop:"1%"}}>Submit</Button>
    </form>
  )
}

export function AliExpressMapProduct({supplier}) {
  const store = useSelector(state => state.store)
  const schema = Yup.object({
    sku:Yup.string().required("sku is required"),
    variantid:Yup.string().required("variantid is required"),
    supplier_sku:Yup.string().required("supplier_sku is required"),
    warehouse:Yup.string().required("warehouse is required"),
    ali_sku:Yup.string().required("AliExpress Sku is required")
  })

  const formik = useFormik({
    initialValues:{
      sku:"",
      inventory_item_id:""
    },
    onSubmit: async (values, helpers) => {
      values.supplier = supplier
      values.store = store
      console.log(values)
      try {
        Axios.post(`https://api.saleprodev.com/inventoryerror/mapsku`, values).then(response => {
          console.log(response)
          if(response.data.errors){
            alert(response.data.errors[0].message)
          }
          if(response.data.sku){
            alert("Success")
          } else {
            if(response.data.status !== 200){
              alert("Please Check Variant ID")
            }
          }


        })
      } catch(error){
        helpers.setErrors({submit:error.message})
      }
    },
    validationSchema:schema
  })

  return (
    <form style={style} onSubmit={formik.handleSubmit}>
      <TextField
      size="small"
      placeholder="sku"
      label="sku"
      name="sku"
      error={formik.errors.sku}
      helperText={formik.errors.sku}
      onChange={formik.handleChange}
      style={{width:"200px",marginLeft:"1%"}}
      />
      <TextField
      size="small"
      label="variantid"
      placeholder="variantid"
      name="variantid"
      error={formik.errors.variantid}
      helperText={formik.errors.variantid}
      onChange={formik.handleChange}
      style={{width:"200px",marginLeft:"1%"}}
      />
      <TextField
      size="small"
      label="supplier_sku"
      placeholder="supplier_sku"
      name="supplier_sku"
      error={formik.errors.supplier_sku}
      helperText={formik.errors.supplier_sku}
      onChange={formik.handleChange}
      style={{width:"200px",marginLeft:"1%"}}
      />
      <TextField
      size="small"
      label="warehouse"
      placeholder="warehouse"
      name="warehouse"
      error={formik.errors.warehouse}
      helperText={formik.errors.warehouse}
      onChange={formik.handleChange}
      style={{width:"200px",marginLeft:"1%", marginTop:"1%"}}
      />
      <TextField
      size="small"
      label="AliExpress SKU"
      placeholder="AliExpress SKU"
      name="ali_sku"
      error={formik.errors.ali_sku}
      helperText={formik.errors.ali_sku}
      onChange={formik.handleChange}
      style={{width:"200px",marginLeft:"1%", marginTop:"1%"}}
      />
      <Button variant="contained" type="submit" name="submit" style={{marginLeft:"1%", marginTop:"1%"}}>Submit</Button>
    </form>
  )
}

export function MapProduct({supplier}) {
  return (
    <>
    {(
      supplier === "dropship" ||
      supplier ==='fragrancex' ||
      supplier === "vidaxl" ||
      supplier === "wefullfill" ||
      supplier === "matterhorn" ||
      supplier === "dropsite" ||
      supplier === "soga"
      ) &&
    <DropshipMapProduct supplier={supplier}/>
  }
  {supplier === "bangood" &&
  <BangoodMapProduct/>
  }
  {supplier === "aliexpress" &&
  <AliExpressMapProduct/>
  }
    </>
  )
}

export default MapProduct
