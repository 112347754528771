import React from "react";
import { useScrollTrigger, Fab, Box, Fade, Grid } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const ScrollTop = (props) => {
  const { children, window, scrollToTopId } = props;
  // const scrollTopTrigger = useScrollTrigger({
  //   disableHysteresis: true,
  //   threshold: 100,
  // });

  const handleScrollTop = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      `#${scrollToTopId}`
    );

    if (anchor) {
      console.log("anchor exist");
      anchor.scrollIntoView({
        block: "center",
      });
    }
  };

  return (
    // <Fade in={scrollTopTrigger}>
    <Box onClick={handleScrollTop} role="presentation" my={1}>
      {children}
    </Box>
    // </Fade>
  );
};

const ScrollBottom = (props) => {
  const { children, window, scrollToBottomId } = props;
  const scrollBottomTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleScrollBottom = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      `#${scrollToBottomId}`
    );

    if (anchor) {
      console.log("anchor exist");
      anchor.scrollIntoView({
        block: "center",
      });
    }
  };

  return (
    // <Fade in={scrollBottomTrigger}>
    <Box onClick={handleScrollBottom} role="presentation" my={1}>
      {children}
    </Box>
    // </Fade>
  );
};

const ScrollToTopOrBottom = (props) => (
  <>
    <Grid
      sx={{
        position: "fixed",
        bottom: "36px",
        right: "6px",
      }}
    >
      <ScrollTop {...props}>
        <Fab
          size="small"
          aria-label="scroll back to top"
          color="primary"
          sx={{
            width: "36px",
            height: "36px",
            borderRadius: 2.5,
            boxShadow: "none",
          }}
        >
          <KeyboardArrowUpIcon sx={{ color: "bg.paper" }} />
        </Fab>
      </ScrollTop>

      <ScrollBottom {...props}>
        <Fab
          size="small"
          aria-label="scroll to bottom"
          color="primary"
          sx={{
            width: "36px",
            height: "36px",
            borderRadius: 2.5,
            boxShadow: "none",
          }}
        >
          <KeyboardArrowDownIcon sx={{ color: "bg.paper" }} />
        </Fab>
      </ScrollBottom>
    </Grid>
  </>
);

export default ScrollToTopOrBottom;
