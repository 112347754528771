import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCheckedInStock } from "../../actions";
import { Typography, Checkbox } from "@mui/material";

export const InStockCheckbox = () => {
  const dispatch = useDispatch();
  const checkedInStock = useSelector((state) => state.checkedInStock);

  const handleChange = (event) => {
    dispatch(setCheckedInStock(event.target.checked));
  };

  return (
    <>
      <Checkbox
        checked={checkedInStock}
        onChange={handleChange}
        disableRipple
        sx={{ p: 0 }}
      />
      <Typography variant="body2" sx={{ mr: 0.5 }}>
        In Stock
      </Typography>
    </>
  );
};
