import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Modal from '@mui/material/Modal';
import EditIcon from '@mui/icons-material/Edit';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import PaginationOutlined_listings from "./pagination.js"
import {CardHeader,CardContent} from '@mui/material'
import {setSearch, setSelectedStore, setProductsPerPage, setImportPage, setImportTotalPages,setArticles,setSubsettings, setToken, setLoading,setCountrylist,setShippingMethods, setShowImportedProductsFilter,setCategoriesLevel1, setCategoriesLevel2,setCategoriesLevel3, setCategoriesLevel4,setCategoriesLevel5, setCategoriesLevel6,setImportedProducts,setImportedProductsFilter,setSelectedCategoryLevel2,setSelectedCategoryLevel3, setSelectedCategoryLevel4, setSelectedCategoryLevel5, setSelectedCategoryLevel6} from "../../actions"
import {useSelector, useDispatch} from "react-redux"

export default function FormatKogan({product}) {

  const products_per_page = useSelector(state => state.products_per_page)
  const dispatch = useDispatch()
  const [links, setLinks] = useState([])
  const [products_nz_shopify, setProductsNZShopify] = useState([])
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const import_page = useSelector(state => state.import_page)
  const import_total_pages = useSelector(state => state.import_total_pages)
  const selected_store = useSelector(state => state.selected_store)


  return (

            <Grid item xs={12} sm={12} lg={12} style={{marginLeft:"1%", marginRight:"1%"}}>
            <Card style={{marginTop:"1%"}}>
            <div style={{display:"grid", gridTemplateColumns:"3% 7% 40% 15% 10% 10% 10% 5% 5%", gridGap:"5px"}}>
            <div style={{textAlign:"center", marginTop:"30%"}}>
            <input type="checkbox" id="horns" name="horns"/>
            </div>
            <div><img src={product.images[0]} width="80" height="80"/></div>
            <div>
            <div style={{fontSize:"14px"}}>{product.product_title}</div>
            <div style={{fontSize:"14px"}}>{product.product_sku}</div>
            </div>
            <div style={{fontSize:"14px", textAlign:"center"}}>{product.offer_data.NZD.price}</div>

            <div style={{textAlign:"center", fontSize:"14px"}}>{""}</div>
            <div style={{textAlign:"center", fontSize:"14px"}}>
            {product.stock}
            </div>
            <div style={{fontSize:"14px",textAlign:"center"}}>{""}</div>
              <div onClick={(event) => setOpen(true)} style={{cursor:"pointer", fontSize:"14px"}}><EditIcon/></div>
            </div>

              </Card>
              </Grid>
      )
}
