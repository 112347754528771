import TextField from "@mui/material/TextField";

export const SmallOutlinedTextField = ({
  label,
  name,
  value,
  onChange,
  width,
  height,
}) => (
  <TextField
    fullWidth
    size="small"
    label={label}
    name={name}
    value={value}
    onChange={onChange}
    sx={{ my: 0.5, width: width }}
    InputProps={{
      sx: {
        height: height ? height : 30,
        fontSize: "0.8rem",
      },
    }}
    InputLabelProps={{
      sx: {
        fontSize: "0.8rem",
        top: () => (value === null || value === "" ? "-2px" : "2px"),
        "&.Mui-focused": {
          transform: () =>
            value === null || value === ""
              ? "translate(14px, -4px) scale(0.8)"
              : "translate(14px, -8px) scale(0.8)",
        },
      },
    }}
  />
);
