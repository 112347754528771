import * as React from "react";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import {
  setSearch,
  setArticles,
  setSubsettings,
  setPricingrule,
  setPricingFormula,
  setSelectedCountryShipFrom,
  setSelectedCountryShipTo,
  setSelectedImportOption,
} from "../../actions";

export default function ToggleButtons({ options, type }) {
  const [alignment, setAlignment] = React.useState("left");
  const pricing_rules_selected = useSelector(
    (state) => state.pricing_rules_selected
  );
  const pricing_formula_selected = useSelector(
    (state) => state.pricing_formula_selected
  );
  const selected_country_shipfrom = useSelector(
    (state) => state.selected_country_shipfrom
  );
  const selected_country_shipto = useSelector(
    (state) => state.selected_country_shipto
  );
  const loading = useSelector((state) => state.loading);
  const dispatch = useDispatch();
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
    console.log("newAlignment", newAlignment);
    if (type === "pricingrule") {
      dispatch(setPricingrule(newAlignment));
    }
    if (type === "importoption") {
      dispatch(setSelectedImportOption(newAlignment));
    }
    if (type === "formula") {
      dispatch(setPricingFormula(newAlignment));
    }
  };
  console.log("pricing_formula_selected", pricing_formula_selected);
  return (
    <>
      <ToggleButtonGroup
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
      >
        {options.map((value) => {
          return (
            <ToggleButton value={value} aria-label="left aligned">
              <Typography>{value}</Typography>
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </>
  );
}
