import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import {
  setSearch,
  setProductsPerPage,
  setImportPage,
  setImportTotalPages,
  setArticles,
  setSubsettings,
  setToken,
  setLoading,
  setCountrylist,
  setShippingMethods,
  setShowImportedProductsFilter,
  setCategoriesLevel1,
  setCategoriesLevel2,
  setCategoriesLevel3,
  setCategoriesLevel4,
  setCategoriesLevel5,
  setCategoriesLevel6,
  setImportedProducts,
  setImportedProductsFilter,
  setSelectedCategoryLevel2,
  setSelectedCategoryLevel3,
  setSelectedCategoryLevel4,
  setSelectedCategoryLevel5,
  setSelectedCategoryLevel6,
} from "../../actions";
import { useSelector, useDispatch } from "react-redux";
export default function KoganCategorySelect({
  koganCategoryLevel1,
  koganCategoryLevel2,
  setKoganCategoryLevel1,
  setKoganCategoryLevel2,
}) {
  const dispatch = useDispatch();
  const [categorieslevel1, setCategoriesLevel1] = useState([]);
  const [categorieslevel2, setCategoriesLevel2] = useState([]);
  const [categorieslevel3, setCategoriesLevel3] = useState([]);
  const [categorieslevel4, setCategoriesLevel4] = useState([]);
  const [categorieslevel5, setCategoriesLevel5] = useState([]);
  const [brands, setBrands] = useState([]);
  useEffect(() => {
    fetch("https://api.saleprodev.com/productimporter/kogancategorieslevel1")
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel1(response.categories_level_1);
      });
  }, []);

  useEffect(() => {
    setKoganCategoryLevel2("Select a Category");
    fetch("https://api.saleprodev.com/productimporter/kogancategorieslevel2", {
      method: "post",
      body: JSON.stringify({ category: koganCategoryLevel1 }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel2(response.categories_level_2);
      });
  }, [koganCategoryLevel1]);

  const handleChange = (event, value) => {
    console.log("value", value);
    value === null
      ? setKoganCategoryLevel1("Select a Category")
      : setKoganCategoryLevel1(value);
  };

  const handleChange2 = (event, value) => {
    console.log("value", value);
    value === null
      ? setKoganCategoryLevel2("Select a Category")
      : setKoganCategoryLevel2(value);
  };

  const defaultProps1 = {
    options: categorieslevel1,
    getOptionLabel: (option) => option,
  };

  const defaultProps2 = {
    options: categorieslevel2,
    getOptionLabel: (option) => option,
  };

  return (
    <>
      <Autocomplete
        {...defaultProps1}
        size="small"
        value={koganCategoryLevel1}
        onChange={(event, value) => handleChange(event, value)}
        renderInput={(params) => (
          <TextField {...params} />
        )}
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
      />
      <Autocomplete
        {...defaultProps2}
        size="small"
        value={koganCategoryLevel2}
        onChange={(event, value) => handleChange2(event, value)}
        renderInput={(params) => (
          <TextField {...params} />
        )}
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
      />
    </>
  );
}
