import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  setSearch,
  setArticles,
  setSubsettings,
  setOrdersNzShopify,
  setSelectedSupplier,
  setDropshipOrders,
  setOrderToDate,
  setOrderFromDate,
  setPurchasedOrders,
} from "../../actions";

export default function CancelModal({ order, handleCancel }) {
  const order_from_date = useSelector((state) => state.order_from_date);
  const order_to_date = useSelector((state) => state.order_to_date);
  const dispatch = useDispatch();
  const purchased_orders = useSelector((state) => state.purchased_orders);
  const [trackingnumber, setTrackingNumber] = useState("");
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const handleOpenCancelModal = () => {
    setOpenCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setOpenCancelModal(false);
  };

  return (
    <>
      <MenuItem
        onClick={handleOpenCancelModal}
        sx={{ height: 24, fontSize: "0.8rem" }}
      >
        Cancel
      </MenuItem>
      <Modal
        open={openCancelModal}
        onClose={handleCloseCancelModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to cancel order No. {order.orderid}
          </Typography>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={(event) => handleCancel(order)}
          >
            YES
          </Button>
          <Button
            style={{ marginLeft: "10%", backgroundColor: "green" }}
            variant="contained"
            size="small"
            onClick={handleCloseCancelModal}
          >
            NO
          </Button>
        </Box>
      </Modal>
    </>
  );
}
