import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import LinearProgress from "@mui/material/LinearProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function FindProductsReportModal({
  openFindProductsReport,
  setOpenFindProductsReport,
  loading_products,
}) {
  const handleClose = () =>
    setOpenFindProductsReport({ open: false, report: [] });

  return (
    <Modal
      open={openFindProductsReport.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        style={{ width: "800px", height: "600px", overflow: "scroll" }}
      >
        {loading_products ? (
          <>
            <Typography>Finding Products</Typography>
            <LinearProgress />
          </>
        ) : (
          <>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              Report
            </Typography>

            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {Array.isArray(openFindProductsReport.report) === true ? (
                <div>
                  <div style={{ display: "grid", gridTemplateColumns: "100%" }}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h6"
                    >
                      Number of ID's Succeed - (
                      {
                        openFindProductsReport.report.filter(
                          (product) => product.code === 0
                        ).length
                      }
                      )
                    </Typography>
                  </div>
                </div>
              ) : (
                ""
              )}
            </Typography>

            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {Array.isArray(openFindProductsReport.report) === true ? (
                <div>
                  <div style={{ display: "grid", gridTemplateColumns: "100%" }}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h6"
                    >
                      Number of ID's Failed - (
                      {
                        openFindProductsReport.report.filter(
                          (product) => product.code !== 0
                        ).length
                      }
                      )
                    </Typography>
                    {openFindProductsReport.report
                      .filter((product) => product.code !== 0)
                      .map((item) => (
                        <Typography>
                          {item.product_id} ({item.msg})
                        </Typography>
                      ))}
                  </div>
                </div>
              ) : (
                ""
              )}
            </Typography>
          </>
        )}
      </Box>
    </Modal>
  );
}
