import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={1} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbarsFailed({
  open,
  handleClose,
  message,
  handleSave,
  order,
}) {
  return (
    // <Stack spacing={2} sx={{ width: "100%" }}>
    <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        <div>
          {message} {order.orderid ? order.orderid : ""}
        </div>
      </Alert>
    </Snackbar>
    // </Stack>
  );
}
