import React, { useEffect, useState } from "react";
import {
  Grid,
  Chip,
  Button,
  InputAdornment,
  TextField,
  Autocomplete,
} from "@mui/material";
import DropshipVendorIdFilter from "./DropshipVendorIdFilter";
import { SmallContainedButton } from "../common/SmallContainedButton";
import { InStockCheckbox } from "./instockcheckbox";

export default function SerranoCategorySelect({
  selectedSerranoCategoryLevel1,
  setSelectedSerranoCategoryLevel1,
  selectedSerranoCategoryLevel2,
  setSelectedSerranoCategoryLevel2,
  skulist,
  setSkuList,
  filterby,
  loading_products,
  fetchproductcount,
  setFetchProductCount,
}) {
  const [categorieslevel1, setCategoriesLevel1] = useState([]);
  const [categorieslevel2, setCategoriesLevel2] = useState([]);
  const [brands, setBrands] = useState([]);
  
  useEffect(() => {
    fetch("https://api.saleprodev.com/productimporter/serranocategorieslevel1")
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel1(response.categories_level_1.sort());
      });

    // fetch("https://api.saleprodev.com/productimporter/serranobrands")
    //   .then((response) => response.json())
    //   .then((response) => {
    //     console.log("brands", response);
    //     setBrands(response.brands);
    //   });

//     fetch("https://api.saleprodev.com/productimporter/serranovendors")
//       .then((response) => response.json())
//       .then((response) => {
//         console.log("VendorIDs", response);
//         let index = response.vendors.indexOf(null);
//         if (index !== -1) {
//           response.vendors[index] = "Dropship";
//           response.vendors.unshift(response.vendors.splice(index, 1)[0]);
//         }

//         setVendorIDs(response.vendors);
//       });
  }, []);

  useEffect(() => {
    setSelectedSerranoCategoryLevel2("Select a Category");
    fetch(
      "https://api.saleprodev.com/productimporter/serranocategorieslevel2",
      {
        method: "post",
        body: JSON.stringify({ category: selectedSerranoCategoryLevel1 }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel2(response.categories_level_2.sort());
      });
  }, [selectedSerranoCategoryLevel1]);

  const handleChange = (event, value) => {
    console.log("value", value);
    if (value === null) {
      setSelectedSerranoCategoryLevel1("Select a Category");
    } else {
      setSelectedSerranoCategoryLevel1(value);
    }
  };

  const handleChange2 = (event, value) => {
    console.log("value", value);
    if (value === null) {
      setSelectedSerranoCategoryLevel2("Select a Category");
    } else {
      setSelectedSerranoCategoryLevel2(value);
    }
  };

//   const handleChangeBrand = (event, value) => {
//     console.log("Brand", value);
//     if (value === null) {
//       setSelectedDropshipBrand("Select a Brand");
//     } else {
//       setSelectedDropshipBrand(value);
//     }
//   };

//   const handleChangeVendorID = (event, value) => {
//     console.log("VendorID", value);
//     if (value === null) {
//       setSelectedDropshipVendorID("Select a Vendor ID");
//     } else {
//       setSelectedDropshipVendorID(value);
//     }
//   };

  const defaultProps1 = {
    options: categorieslevel1,
    getOptionLabel: (option) => option,
  };

  const defaultProps2 = {
    options: categorieslevel2,
    getOptionLabel: (option) => option,
  };

//   const defaultProps3 = {
//     options: brands,
//     getOptionLabel: (option) => option,
//   };

//   const defaultProps4 = {
//     options: vendorIDs,
//     getOptionLabel: (option) => option,
//   };

  return filterby === "category" ? (
    <>
      <Autocomplete
        size="small"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            p: "0px!important",
            height: 26,
          },
        }}
        {...defaultProps1}
        value={selectedSerranoCategoryLevel1}
        onChange={(event, value) => handleChange(event, value)}
        renderInput={(params) => <TextField {...params} />}
      />

      <Autocomplete
        size="small"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            p: "0px!important",
            height: 26,
          },
        }}
        {...defaultProps2}
        value={selectedSerranoCategoryLevel2}
        onChange={(event, value) => handleChange2(event, value)}
        renderInput={(params) => <TextField {...params} />}
      />
     

      <InStockCheckbox />
    </>
  ) : (
    <>
      <Grid item sx={{ position: "relative" }}>
        <TextField
          multiline
          maxRows={5}
          placeholder="Enter SKUs"
          // skulist={skulist}
          // setSkuList={setSkuList}
          onChange={(event) => setSkuList(event.target.value)}
          sx={{
            mr: 0.5,
            width: "500px",
            fontSize: "0.8rem",
            "& legend": {
              width: "unset",
            },
            "& .MuiFormControl-root, .MuiOutlinedInput-root": {
              p: "0px!important",
              minHeight: 26,
            },
          }}
        />

        {skulist === "" ? (
          ""
        ) : (
          <Chip
            size="small"
            color="primary"
            variant="outlined"
            label={`${skulist.split(/\r\n|\r|\n/).length} Items`}
            sx={{
              height: 20,
              position: "absolute",
              transform:
                skulist.split(/\r\n|\r|\n/).length <= 1
                  ? "translate(-95px, 3px)"
                  : "translate(-95px, 6px)",
            }}
          />
        )}
      </Grid>

      <Grid item>
        <SmallContainedButton
          width="60px"
          disabled={loading_products ? true : false}
          onClick={(event) => setFetchProductCount(fetchproductcount + 1)}
        >
          Find
        </SmallContainedButton>
      </Grid>
    </>
  );
}
