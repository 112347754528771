import React, { useState } from "react";
import { Typography, Select, MenuItem, Grid } from "@mui/material";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";

const Sort = ({
  ascending,
  sortCategory,
  setSortCategory,
  handleSortAscending,
  handleSortDescending,
}) => {
  //   const [sortCategory, setSortCategory] = useState("order_date");

  const handleChangeSortCategory = (event) => {
    setSortCategory(event.target.value);
  };
  return (
    <Grid container alignItems="center" xs={2.5} my={0.5}>
      <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
        Sort By
      </Typography>

      <Select
        value={sortCategory}
        onChange={handleChangeSortCategory}
        size="small"
        sx={{
          ml: 1,
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
        }}
      >
        <MenuItem value="order_id" sx={{ height: 26 }}>
          <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
            Order ID
          </Typography>
        </MenuItem>

        <MenuItem value="order_date" sx={{ height: 26 }}>
          <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
            Order Date
          </Typography>
        </MenuItem>
      </Select>

      <Grid
        item
        container
        justifyContent="center"
        alignContent="center"
        sx={{
          ml: "5px",
          width: 26,
          height: 26,
          backgroundColor: "bg.paper",
          border: (theme) => `1px solid ${theme.palette.border}`,
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        <SignalCellularAltIcon
          color={ascending ? "primary" : "disabled"}
          onClick={handleSortAscending}
        />
      </Grid>

      <Grid
        item
        container
        justifyContent="center"
        alignContent="center"
        sx={{
          width: 26,
          height: 26,
          backgroundColor: "bg.paper",
          border: (theme) => `1px solid ${theme.palette.border}`,
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        <SignalCellularAltIcon
          color={ascending ? "disabled" : "primary"}
          onClick={handleSortDescending}
          sx={{ transform: "scale(-1, 1)" }}
        />
      </Grid>
    </Grid>
  );
};
export default Sort;
