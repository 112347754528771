import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect({margintype, handleChange}) {


  return (
    <Box sx={{ minWidth: 140,height:30, }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={margintype}
          label="Age"
          onChange={handleChange}
          style={{width:"140px", height:"30px"}}
        >
          <MenuItem value="Percentage">Percentage</MenuItem>
          <MenuItem value="Fixed Value">Fixed Value</MenuItem>
        </Select>
    </Box>
  );
}
