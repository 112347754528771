import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { Loader } from "../common/Loader";

import {
  Grid,
  Container,
  Card,
  CardContent,
  Typography,
  Box,
  CardActions,
  Button,
  CardHeader,
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { setAccess, setStore } from "../../actions";
import RefreshIcon from "@mui/icons-material/Refresh";
import BarChart from "./chart.js";
import BasicTable from "./table.js";
import PieChart from "./piechart.js";
import Donut from "./donut.js";
import {
  VictoryBar,
  VictoryChart,
  VictoryLine,
  VictoryPie,
  VictoryTheme,
  VictoryLabel,
  VictoryGroup,
  VictoryAxis,
} from "victory";

function Dashboard() {
  const dispatch = useDispatch();
  const [totalstockupdates, setTotalStockUpdates] = useState(0);
  const [totalpriceupdates, setTotalPriceUpdates] = useState(0);
  const [senttoshopify, setSentToShopify] = useState(0);
  const [senttoshopifyprice, setSentToShopifyPrice] = useState(0);
  const [calllimitreached, setCallLimitReached] = useState(0);
  const [notfound, setNotFound] = useState(0);
  const [notfoundprice, setNotFoundPrice] = useState(0);
  const [blanks, setBlanks] = useState(0);
  const [blanksprice, setBlanksPrice] = useState(0);
  const [count, setCount] = useState(0);
  const [locationerror, setLocationError] = useState(0);
  const [data, setData] = useState([]);
  const [report, setReport] = useState("scripts");
  const [reportdata, setReportData] = useState([]);
  const [loading, setLoading] = useState(true);
  let from_date = new Date(Date.now() - 604800000).toISOString().slice(0, 16);
  let to_date = new Date().toISOString().slice(0, 16);
  const [from, setFrom] = useState(from_date);
  const [to, setTO] = useState(to_date);
  const [action, setAction] = useState("");
  const [actionTime, setActionTime] = useState("");
  const store = useSelector((state) => state.store);

  useEffect(() => {
    setLoading(true);
    if (store === "nzshopify" && report === "scripts") {
      fetch("https://api.saleprodev.com/shopifynzvariants", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status) {
            console.log(response);
            dispatch(setAccess(response.status));
          } else {
            console.log(response);
            setData(response.array);
            setLoading(false);
          }
        });
    }
    if (store === "nzshopify" && report === "orders") {
      let from_pass = new Date(from);
      let to_pass = new Date(to);
      console.log(from_pass.toISOString());
      // console.log(from_pass, to_pass)
      fetch("https://api.saleprodev.com/shopifynzordersplits", {
        method: "POST",
        body: JSON.stringify({ from: from, to: to }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status) {
            console.log(response);
            dispatch(setAccess(response.status));
          } else {
            console.log(response);
            setReportData(response.array);
            setLoading(false);
          }
        });
    }
    if (store === "aushopify" && report === "scripts") {
      fetch("https://api.saleprodev.com/shopifyauvariants")
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setData(response);
          setLoading(false);
        });
    }
    if (store === "aushopify" && report === "orders") {
      let from_pass = new Date(from);
      let to_pass = new Date(to);
      console.log(from_pass.toISOString());
      // console.log(from_pass, to_pass)
      fetch("https://api.saleprodev.com/shopifyauordersplits", {
        method: "POST",
        body: JSON.stringify({ from: from, to: to }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status) {
            console.log(response);
            dispatch(setAccess(response.status));
          } else {
            console.log(response);
            setReportData(response.array);
            setLoading(false);
          }
        });
    }
    if (store === "usshopify" && report === "scripts") {
      fetch("https://api.saleprodev.com/shopifyusvariants")
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setData(response);
          setLoading(false);
        });
    }
    if (store === "gkshopify" && report === "scripts") {
      fetch("https://api.saleprodev.com/shopifygkvariants")
        .then((response) => response.json())
        .then((response) => {
          console.log("gkshopify response", response);
          setData(response);
          setLoading(false);
        });
    }

    if (store === "kogan" && report === "scripts") {
      fetch("https://api.saleprodev.com/koganstatistics2")
        .then((response) => response.json())
        .then((response) => {
          console.log("kogan response", response.array);
          setData(response.array);
          setLoading(false);
        });
    }
  }, [store, report, from, to]);

  useEffect(() => {
    setData([]);
  }, [store, report, from, to]);

  useEffect(() => {
    setReportData([]);
  }, [store, report, from, to]);

  const handleChangeTO = (event) => {
    setTO(event.target.value);
  };

  const handleChangeFrom = (event) => {
    setFrom(event.target.value);
  };
  const actions = [
    {
      secondaryDomain: "script",
      endpoint: "allstockandprice",
      title: "Update All Stock And Price",
    },

    {
      secondaryDomain: "aliscript",
      endpoint: "updatealiexpressshippingnz",
      title: "Update AliExpress Shipping - NZ",
    },

    {
      secondaryDomain: "aliscript",
      endpoint: "updatealiexpressshippingau",
      title: "Update AliExpress Shipping - AU",
    },
    {
      secondaryDomain: "aliscript",
      endpoint: "updatealiexpressshippinggk",
      title: "Update AliExpress Shipping - GK",
    },
    {
      secondaryDomain: "aliscript",
      endpoint: "aliexpressproductfetchforall",
      title: "AliExpress Product Fetch For All",
    },
    {
      secondaryDomain: "aliscript",
      endpoint: "aliexpressproductfetchfornull",
      title: "AliExpress Product Fetch For Null",
    },
    {
      secondaryDomain: "aliscript",
      endpoint: "stocksyncaliexpressnz",
      title: "Stock Sync AliExpress - NZ",
    },

    {
      secondaryDomain: "aliscript",
      endpoint: "stocksyncaliexpressau",
      title: "Stock Sync AliExpress - AU",
    },

    {
      secondaryDomain: "aliscript",
      endpoint: "stocksyncaliexpressus",
      title: "Stock Sync AliExpress - US",
    },
    {
      secondaryDomain: "aliscript",
      endpoint: "stocksyncaliexpressgk",
      title: "Stock Sync AliExpress - GK",
    },

    {
      secondaryDomain: "aliscript",
      endpoint: "pricesyncaliexpressnz",
      title: "Price Sync AliExpress - NZ",
    },

    {
      secondaryDomain: "aliscript",
      endpoint: "pricesyncaliexpressau",
      title: "Price Sync AliExpress - AU",
    },

    {
      secondaryDomain: "aliscript",
      endpoint: "pricesyncaliexpressus",
      title: "Price Sync AliExpress - US",
    },

    {
      secondaryDomain: "aliscript",
      endpoint: "pricesyncaliexpressgk",
      title: "Price Sync AliExpress - GK",
    },

    {
      secondaryDomain: "script",
      endpoint: "dropshipscript",
      title: "Dropship Script",
    },

    {
      secondaryDomain: "script",
      endpoint: "fragrancexscript",
      title: "Fragrancex Script",
    },

    {
      secondaryDomain: "script",
      endpoint: "wefulfillscript",
      title: "Wefulfill Script",
    },

    {
      secondaryDomain: "script",
      endpoint: "matterhornscript",
      title: "Matterhorn Script",
    },

    {
      secondaryDomain: "script",
      endpoint: "vidaxlscript",
      title: "Vidaxl Script",
    },

    {
      secondaryDomain: "script",
      endpoint: "bangoodscript",
      title: "Bangood Script",
    },

    {
      secondaryDomain: "script",
      endpoint: "dropsitescript",
      title: "Dropsite Script",
    },

    {
      secondaryDomain: "script",
      endpoint: "sogascript",
      title: "Soga Script",
    },

    {
      secondaryDomain: "script",
      endpoint: "wedropshipscript",
      title: "Wedropship Script",
    },
  ];

  const actionTimes = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];
  const handleChangeAction = (e) => {
    setAction(e.target.value);
  };

  const handleChangeActionTime = (e) => {
    setActionTime(e.target.value);
  };

  const handleSubmitAction = () => {
    actions.map((actionItem) => {
      if (action === actionItem.title) {
        fetch(
          `https://${actionItem.secondaryDomain}.saleprodev.com/${actionItem.endpoint}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              time: actionTime,
            }),
          }
        )
          .then((res) => res.json())
          .then((res) => toast.success(res.message))
          .catch((error) => toast.error(error));
      }
    });
  };

  return (
    <Grid container m={1}>
      <Grid item xs={12} mb={2}>
        <Card sx={{ borderRadius: 4 }}>
          <CardHeader
            title={<Typography variant="h6">Quick Filter:</Typography>}
          />

          <CardContent>
            <Grid container>
              <Grid item xs={1}>
                Store:
              </Grid>
              <Grid item xs={1}>
                {store === "nzshopify" ? (
                  <Button variant="outlined" size="small">
                    NZ Shopify
                  </Button>
                ) : (
                  <Button
                    onClick={(event) => {
                      dispatch(setStore("nzshopify"));
                      window.sessionStorage.setItem("store", "nzshopify");
                    }}
                    size="small"
                  >
                    NZ Shopify
                  </Button>
                )}
              </Grid>
              <Grid item xs={1}>
                {store === "aushopify" ? (
                  <Button variant="outlined" size="small">
                    AU Shopify
                  </Button>
                ) : (
                  <Button
                    onClick={(event) => {
                      dispatch(setStore("aushopify"));
                      window.sessionStorage.setItem("store", "aushopify");
                    }}
                    size="small"
                  >
                    AU Shopify
                  </Button>
                )}
              </Grid>
              <Grid item xs={1}>
                {store === "usshopify" ? (
                  <Button variant="outlined" size="small">
                    US Shopify
                  </Button>
                ) : (
                  <Button
                    onClick={(event) => {
                      dispatch(setStore("usshopify"));
                      window.sessionStorage.setItem("store", "usshopify");
                    }}
                    size="small"
                  >
                    US Shopify
                  </Button>
                )}
              </Grid>

              <Grid item xs={1}>
                {store === "gkshopify" ? (
                  <Button variant="outlined" size="small">
                    GK Shopify
                  </Button>
                ) : (
                  <Button
                    onClick={(event) => {
                      dispatch(setStore("gkshopify"));
                      window.sessionStorage.setItem("store", "gkshopify");
                    }}
                    size="small"
                  >
                    GK Shopify
                  </Button>
                )}
              </Grid>

              <Grid item xs={1}>
                {store === "kogan" ? (
                  <Button variant="outlined" size="small">
                    Kogan
                  </Button>
                ) : (
                  <Button
                    onClick={(event) => {
                      dispatch(setStore("kogan"));
                      window.sessionStorage.setItem("store", "kogan");
                    }}
                    size="small"
                  >
                    Kogan
                  </Button>
                )}
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: "1%" }}>
              <Grid item xs={1}>
                Report:
              </Grid>
              <Grid item xs={1}>
                {report === "scripts" ? (
                  <Button variant="outlined" size="small">
                    SCRIPTS
                  </Button>
                ) : (
                  <Button
                    onClick={(event) => {
                      setReport("scripts");
                    }}
                    size="small"
                  >
                    SCRIPTS
                  </Button>
                )}
              </Grid>
              <Grid item xs={1}>
                {report === "orders" ? (
                  <Button variant="outlined" size="small">
                    ORDERS
                  </Button>
                ) : (
                  <Button
                    onClick={(event) => {
                      setReport("orders");
                    }}
                    size="small"
                  >
                    ORDERS
                  </Button>
                )}
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: "1%" }}>
              <Grid item xs={1}>
                Date:
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  id="datetime-local"
                  size="small"
                  label="From"
                  type="datetime-local"
                  defaultValue={from}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeFrom}
                  value={from}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  id="datetime-local"
                  size="small"
                  label="To"
                  type="datetime-local"
                  defaultValue={to}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeTO}
                  value={to}
                  sx={{ mx: 1 }}
                />
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: "1%" }}>
              <Grid item xs={1}>
                Actions:
              </Grid>
              <Grid item xs={3}>
                <FormControl size="small" fullWidth>
                  <InputLabel id="action-select-label">Actions</InputLabel>
                  <Select
                    labelId="action-select-label"
                    id="action-select"
                    value={action}
                    label="Actions"
                    onChange={handleChangeAction}
                  >
                    {actions.map((actionItem) => (
                      <MenuItem value={actionItem.title}>
                        {actionItem.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={1} mx={1}>
                <FormControl size="small" fullWidth>
                  <InputLabel id="action_time_select_label">Act On</InputLabel>
                  <Select
                    labelId="action_time_select_label"
                    id="action_time_select"
                    value={actionTime}
                    label="Act On"
                    onChange={handleChangeActionTime}
                  >
                    {actionTimes.map((actionTimeItem) => (
                      <MenuItem value={actionTimeItem}>
                        {actionTimeItem}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={1}>
                <Button variant="contained" onClick={handleSubmitAction}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid container spacing={2}>
        {(data.length === 0 && report === "scripts") ||
        (reportdata.length === 0 && report === "orders") ? (
          <Grid item container xs={12}>
            <Loader size="100px" />
          </Grid>
        ) : (
          ""
        )}

        {report === "scripts" &&
          data.map((item) => {
            return (
              <Grid item xs={12} md={6}>
                <Box>
                  <Card
                    sx={{
                      borderRadius: 4,
                      minHeight: "300px",
                      minWidth: "600px",
                    }}
                  >
                    {loading === false ? (
                      <PieChart data={item} supplier="dropship" />
                    ) : (
                      <Grid item container>
                        <Loader size="100px" />
                      </Grid>
                    )}
                  </Card>
                </Box>
              </Grid>
            );
          })}

        {report === "orders" && reportdata.length !== 0 ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginTop: "1%" }}
          >
            <Card
              style={{
                minHeight: "50px",
                minWidth: "600px",
                marginRight: "2%",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "20% 20%",
                  fontSize: "20px",
                  fontStyle: "bold",
                }}
              >
                <div>TOTAL ORDERS:</div>
                <div>
                  {reportdata.reduce(function (acc, obj) {
                    return acc + obj.total;
                  }, 0)}
                </div>
                <div>TOTAL SALES:</div>
                <div>
                  {reportdata
                    .reduce(function (acc, obj) {
                      return acc + parseFloat(obj.sales);
                    }, 0)
                    .toFixed(2)}
                </div>
                <div>TOTAL COST:</div>
                <div>
                  {reportdata
                    .reduce(function (acc, obj) {
                      return acc + parseFloat(obj.cost);
                    }, 0)
                    .toFixed(2)}
                </div>
              </div>
            </Card>
          </Grid>
        ) : (
          ""
        )}

        {report === "orders" &&
          reportdata.map((item) => {
            return (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                style={{ marginTop: "1%" }}
              >
                <Box>
                  <Card
                    style={{
                      minHeight: "300px",
                      minWidth: "600px",
                      marginRight: "3%",
                    }}
                  >
                    {loading === false ? (
                      <Donut item={item} />
                    ) : (
                      <Grid item container>
                        <Loader size="100px" />
                      </Grid>
                    )}
                  </Card>
                </Box>
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
}

export default Dashboard;
