export const setSearch = (text) => ({
  type: "CHANGE_SEARCH_FIELD",
  payload: text,
});

export const setLocale = (data) => ({
  type: "CHANGE_LOCALE",
  payload: data,
});

export const setArticles = (data) => ({
  type: "CHANGE_ARTICLES",
  payload: data,
});

export const setSubsettings = (data) => ({
  type: "CHANGE_SUBSETTINGS",
  payload: data,
});

export const setSettingsItem = (data) => ({
  type: "CHANGE_SETTINGS_ITEM",
  payload: data,
});

export const setToolsMenu = (data) => ({
  type: "CHANGE_TOOLS_MENU",
  payload: data,
});

export const setPricingrule = (data) => ({
  type: "CHANGE_PRICING_RULE",
  payload: data,
});

export const setPricingFormula = (data) => ({
  type: "CHANGE_FORMULA",
  payload: data,
});

export const setCountrylist = (data) => ({
  type: "CHANGE_COUNTRY_LIST",
  payload: data,
});

export const setToken = (data) => ({
  type: "CHANGE_TOKEN",
  payload: data,
});

export const setSelectedCountryShipFrom = (data) => ({
  type: "CHANGE_SELECTED_COUNTRY_SHIPFROM",
  payload: data,
});

export const setSelectedCountryShipTo = (data) => ({
  type: "CHANGE_SELECTED_COUNTRY_SHIPTO",
  payload: data,
});

export const setSelectedShippingMethod = (data) => ({
  type: "CHANGE_SELECTED_SHIPPING_METHOD",
  payload: data,
});

export const setShippingMethods = (data) => ({
  type: "CHANGE_SHIPPING_METHODS",
  payload: data,
});

export const setSelectedImportOption = (data) => ({
  type: "CHANGE_IMPORT_OPTION",
  payload: data,
});

export const setCategoriesLevel1 = (data) => ({
  type: "CHANGE_CATEGORIES_LEVEL1",
  payload: data,
});

export const setCategoriesLevel2 = (data) => ({
  type: "CHANGE_CATEGORIES_LEVEL2",
  payload: data,
});

export const setCategoriesLevel3 = (data) => ({
  type: "CHANGE_CATEGORIES_LEVEL3",
  payload: data,
});

export const setCategoriesLevel4 = (data) => ({
  type: "CHANGE_CATEGORIES_LEVEL4",
  payload: data,
});

export const setCategoriesLevel5 = (data) => ({
  type: "CHANGE_CATEGORIES_LEVEL5",
  payload: data,
});

export const setCategoriesLevel6 = (data) => ({
  type: "CHANGE_CATEGORIES_LEVEL6",
  payload: data,
});

export const setSelectedCategoryLevel2 = (data) => ({
  type: "CHANGE_SELECTED_CATEGORY_LEVEL2",
  payload: data,
});

export const setSelectedCategoryLevel3 = (data) => ({
  type: "CHANGE_SELECTED_CATEGORY_LEVEL3",
  payload: data,
});

export const setSelectedCategoryLevel4 = (data) => ({
  type: "CHANGE_SELECTED_CATEGORY_LEVEL4",
  payload: data,
});

export const setSelectedCategoryLevel5 = (data) => ({
  type: "CHANGE_SELECTED_CATEGORY_LEVEL5",
  payload: data,
});

export const setSelectedCategoryLevel6 = (data) => ({
  type: "CHANGE_SELECTED_CATEGORY_LEVEL6",
  payload: data,
});

export const setImportedProducts = (data) => ({
  type: "CHANGE_IMPORTED_PRODUCTS",
  payload: data,
});

export const setImportedProductsFilter = (data) => ({
  type: "CHANGE_IMPORTED_PRODUCTS_FILTER",
  payload: data,
});

export const setShowImportedProductsFilter = (data) => ({
  type: "CHANGE_SHOW_IMPORTED_PRODUCTS_FILTER",
  payload: data,
});

export const setLoading = (data) => ({
  type: "CHANGE_LOADING",
  payload: data,
});

export const setCheckedInStock = (data) => ({
  type: "CHANGE_CHECKED_IN_STOCK",
  payload: data,
});

export const setCheckedHasVideo = (data) => ({
  type: "CHANGE_CHECKED_HAS_VIDEO",
  payload: data,
});

export const setImportPage = (data) => ({
  type: "CHANGE_IMPORT_PAGE",
  payload: data,
});

export const setImportTotalPages = (data) => ({
  type: "CHANGE_IMPORT_TOTAL_PAGES",
  payload: data,
});

export const setTotalCounts = (data) => ({
  type: "CHANGE_TOTAL_COUNTS",
  payload: data,
});

export const setProductsPerPage = (data) => ({
  type: "CHANGE_PRODUCTS_PER_PAGE",
  payload: data,
});

export const setOrdersNzShopify = (data) => ({
  type: "CHANGE_ORDERS_NZ_SHOPIFY",
  payload: data,
});

export const setSelectedSupplier = (data) => ({
  type: "CHANGE_SELECTED_SUPPLIER",
  payload: data,
});

export const setSelectedWarehouse = (data) => ({
  type: "CHANGE_SELECTED_WAREHOUSE",
  payload: data,
});

export const setSelectedVariantType = (data) => ({
  type: "CHANGE_SELECTED_VARIANT_TYPE",
  payload: data,
});

export const setDropshipOrders = (data) => ({
  type: "CHANGE_DROPSHIP_ORDERS",
  payload: data,
});
export const setOrderFromDate = (data) => ({
  type: "CHANGE_ORDER_FROM_DATE",
  payload: data,
});

export const setOrderToDate = (data) => ({
  type: "CHANGE_ORDER_TO_DATE",
  payload: data,
});

export const setOrdersFromTo = (data) => ({
  type: "CHANGE_ORDERS_FROM_TO",
  payload: data,
});

export const setShowOrders = (data) => ({
  type: "CHANGE_SHOW_ORDERS",
  payload: data,
});

export const setReport = (data) => ({
  type: "CHANGE_REPORTS",
  payload: data,
});

export const setSlotId = (slotId) => ({
  type: "CHANGE_SLOT_ID",
  payload: slotId,
});

export const setPurchasedOrders = (data) => ({
  type: "CHANGE_PURCHASED_ORDERS",
  payload: data,
});

export const setSelectedStore = (data) => ({
  type: "CHANGE_SELECTED_STORE",
  payload: data,
});

export const setSelectedCollection = (data) => ({
  type: "CHANGE_SELECTED_COLLECTION",
  payload: data,
});

export const setSearchFilterListings = (data) => ({
  type: "CHANGE_SEARCH_FILTER_LISTINGS",
  payload: data,
});

export const setFullfillment2 = (data) => ({
  type: "CHANGE_FULLFILLMENT",
  payload: data,
});

export const setLoadingOrders = (data) => ({
  type: "CHANGE_LOADING_ORDERS",
  payload: data,
});

export const setShippedOrders = (data) => ({
  type: "CHANGE_SHIPPED_ORDERS",
  payload: data,
});

export const setCustomSheet = (data) => ({
  type: "CHANGE_CUSTOM_SHEET",
  payload: data,
});

export const setVideoSheet = (data) => ({
  type: "CHANGE_VIDEO_SHEET",
  payload: data,
});

export const setLoadingCustomSheet = (data) => ({
  type: "CHANGE_LOADING_CUSTOM_SHEET",
  payload: data,
});

export const setLoadingVideoSheet = (data) => ({
  type: "CHANGE_LOADING_VIDEO_SHEET",
  payload: data,
});

export const setFragrancexOrders = (data) => ({
  type: "CHANGE_FRAGRANCEX_ORDERS",
  payload: data,
});

export const setAliExpressOrders = (data) => ({
  type: "CHANGE_ALIEXPRESS_ORDERS",
  payload: data,
});

export const setStore = (data) => ({
  type: "CHANGE_STORE",
  payload: data,
});

export const setSalesChannels = (data) => ({
  type: "CHANGE_SALES_CHANNELS",
  payload: data,
});

export const setCollection = (data) => ({
  type: "CHANGE_COLLECTION",
  payload: data,
});

export const setPurchasingOrder = (data) => ({
  type: "CHANGE_PURCHASING_ORDER",
  payload: data,
});

export const setTracking = (data) => ({
  type: "CHANGE_TRACKING",
  payload: data,
});

export const setCarrier = (data) => ({
  type: "CHANGE_CARRIER",
  payload: data,
});

export const setSearchFilterOrders = (data) => ({
  type: "CHANGE_SEARCH_FILTER_ORDERS",
  payload: data,
});

export const setShowProducts = (data) => ({
  type: "CHANGE_SHOW_PRODUCTS",
  payload: data,
});

export const setListingStatus = (data) => ({
  type: "CHANGE_LISTING_STATUS",
  payload: data,
});

export const setLoadingProducts = (data) => ({
  type: "CHANGE_LOADING_PRODUCTS",
  payload: data,
});

export const setImportingProduct = (data) => ({
  type: "CHANGE_IMPORTING_PRODUCT",
  payload: data,
});

export const setDescriptionPreference = (data) => ({
  type: "CHANGE_DESCRIPTION_PREFERENCE",
  payload: data,
});

export const setSkuPreference = (data) => ({
  type: "CHANGE_SKU_PREFERENCE",
  payload: data,
});

export const setOpenReport = (data) => ({
  type: "CHANGE_OPEN_REPORT",
  payload: data,
});

export const setAccess = (data) => ({
  type: "CHANGE_ACCESS",
  payload: data,
});
