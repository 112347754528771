import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";

import { setImportPage } from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import BasicSelect from "../orders/basicselect";
import { Typography } from "@mui/material";

export default function PaginationOutlined({
  page,
  total_counts,
  selectedOrders,
  setSelectedOrders,
}) {
  const dispatch = useDispatch();
  const import_page = useSelector((state) => state.import_page);
  const import_total_pages = useSelector((state) => state.import_total_pages);
  const products_per_page = useSelector((state) => state.products_per_page);
  const show_orders = useSelector((state) => state.show_orders);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (import_page !== 1) {
      dispatch(setImportPage(1));
    }
  }, [products_per_page]);

  // useEffect(()=>{
  //   if (import_page == 1) {
  //     dispatch(setImportPage(1));
  //   }
  // },[count])

  const handleChangePage = (event, value) => {
    dispatch(setImportPage(value));
    setCount(count + 1);
    window.localStorage.setItem("import_page", value);
    if (show_orders === "to_purchase") {
      let element = document.getElementById("maincheckbox");
      if (selectedOrders.length !== 0) {
        setSelectedOrders([]);
      }
      if (element.checked) {
        element.click();
      } else {
        selectedOrders.map((order) => {
          let element2 = document.getElementById(order.id);
          if (element2.checked) {
            element2.click();
          }
        });
      }
    }
  };

  console.log("total_counts", total_counts);
  console.log("import_page", import_page);
  console.log("products_per_page", products_per_page);
  console.log("import_total_pages", import_total_pages);
  return (
    <>
      <Typography variant="body2">Found: </Typography>

      <Grid
        item
        container
        justifyContent="center"
        alignContent="center"
        sx={{
          mr: 0.25,
          width: 60,
          height: 26,
          backgroundColor: "bg.paper",
          border: (theme) => `1px solid ${theme.palette.border}`,
          borderRadius: "4px",
        }}
      >
        <Typography variant="body2">{total_counts}</Typography>
      </Grid>

      <Typography variant="body2" sx={{ mr: 0.5 }}>
        {page === "orders" && "Orders"}
        {page === "sourcing" && "Products"}
        {page === "listings" && "Listings"}
        {page === "tools" && "SKUs"}
      </Typography>

      <Typography variant="body2" sx={{ mx: 0.5 }}>
        {`${import_page} of ${import_total_pages} Pages`}
      </Typography>

      {import_page <= 1 ? (
        <>
          <Grid
            item
            container
            justifyContent="center"
            alignContent="center"
            sx={{
              mr: 0.25,
              width: 26,
              height: 26,
              backgroundColor: "bg.paper",
              border: (theme) => `1px solid ${theme.palette.border}`,
              borderRadius: "4px",
            }}
          >
            <FirstPageIcon color="disabled" />
          </Grid>

          <Grid
            item
            container
            justifyContent="center"
            alignContent="center"
            sx={{
              mr: 0.25,
              width: 26,
              height: 26,
              backgroundColor: "bg.paper",
              border: (theme) => `1px solid ${theme.palette.border}`,
              borderRadius: "4px",
            }}
          >
            <ChevronLeftIcon color="disabled" />
          </Grid>
        </>
      ) : (
        <>
          <Grid
            item
            container
            justifyContent="center"
            alignContent="center"
            onClick={(event) => handleChangePage(event, 1)}
            sx={{
              mr: 0.25,
              width: 26,
              height: 26,
              backgroundColor: "bg.paper",
              border: (theme) => `1px solid ${theme.palette.border}`,
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            <FirstPageIcon color="primary" />
          </Grid>

          <Grid
            item
            container
            justifyContent="center"
            alignContent="center"
            onClick={(event) =>
              handleChangePage(event, parseInt(import_page) - 1)
            }
            sx={{
              mr: 0.25,
              width: 26,
              height: 26,
              backgroundColor: "bg.paper",
              border: (theme) => `1px solid ${theme.palette.border}`,
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            <ChevronLeftIcon color="primary" />
          </Grid>
        </>
      )}

      {import_page === import_total_pages ? (
        <>
          <Grid
            item
            container
            justifyContent="center"
            alignContent="center"
            sx={{
              mr: 0.25,
              width: 26,
              height: 26,
              backgroundColor: "bg.paper",
              border: (theme) => `1px solid ${theme.palette.border}`,
              borderRadius: "4px",
            }}
          >
            <ChevronRightIcon color="disabled" />
          </Grid>

          <Grid
            item
            container
            justifyContent="center"
            alignContent="center"
            sx={{
              mr: 0.25,
              width: 26,
              height: 26,
              backgroundColor: "bg.paper",
              border: (theme) => `1px solid ${theme.palette.border}`,
              borderRadius: "4px",
            }}
          >
            <LastPageIcon color="disabled" />
          </Grid>
        </>
      ) : (
        <>
          <Grid
            item
            container
            justifyContent="center"
            alignContent="center"
            onClick={(event) =>
              handleChangePage(event, parseInt(import_page) + 1)
            }
            sx={{
              mr: 0.25,
              width: 26,
              height: 26,
              backgroundColor: "bg.paper",
              border: (theme) => `1px solid ${theme.palette.border}`,
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            <ChevronRightIcon color="primary" />
          </Grid>

          <Grid
            item
            container
            justifyContent="center"
            alignContent="center"
            onClick={(event) => handleChangePage(event, import_total_pages)}
            sx={{
              mr: 0.25,
              width: 26,
              height: 26,
              backgroundColor: "bg.paper",
              border: (theme) => `1px solid ${theme.palette.border}`,
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            <LastPageIcon color="primary" />
          </Grid>
        </>
      )}
      <BasicSelect
        page={page}
        selectedOrders={selectedOrders}
        setSelectedOrders={setSelectedOrders}
      />
    </>
  );
}
