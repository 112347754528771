import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DownloadIcon from "@mui/icons-material/Download";
import { useSelector, useDispatch } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import { useExcelDownloder } from "react-xls";
import { Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import MyDatePicker from './MyDatePicker'

import {
  setLoadingCustomSheet,
  setPurchasingOrder,
  setSearch,
  setStore,
  setAliExpressOrders,
  setArticles,
  setSubsettings,
  setOrdersNzShopify,
  setLoadingOrders,
  setSelectedSupplier,
  setDropshipOrders,
  setOrdersFromTo,
  setShowOrders,
  setImportTotalPages,
  setCustomSheet,
  setFragrancexOrders,
} from "../../actions";
import { Loader } from "../common/Loader";
import moment from "moment";
import DatePicker from "./DatePicker";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: 'flex'
};

export default function CustomSheetModal({
  handleCloseCustom,
  opencustom,
  setOpenCustom,
  check,
  supplier,
  store
}) {
  const dispatch = useDispatch();
  const { ExcelDownloder, Type } = useExcelDownloder();
  const handleOpen = () => setOpenCustom(true);
  const loading_custom_sheet = useSelector(
    (state) => state.loading_custom_sheet
  );
  const [customsheet, setCustomSheet] = useState([]);
  const lastWeekDate = moment(moment().format('YYYY-MM-DD')).subtract(7, 'd')
  const [date, setDate] = useState(lastWeekDate);

  useEffect(() => {
    console.log("hii called====>> ")
    if(check === 'ordernew')
    {
      console.log("===================hfhfghghhjhjh",date.year())
      if (opencustom === true && ((date.year()).toString()).length == 4) {
        dispatch(setLoadingCustomSheet(true));
        fetch(`https://api.saleprodev.com/customsheet/${date.format('YYYY-MM-DD')}`)
          .then((response) => response.json())
          .then((response) => {
            console.log("customsheet", response);
            let customsheet = [];
            response.map((item) => {
              const date = moment(item.updatedAt.slice(0, 10)).format("DD/MM/YYYY")
              item.Date = date;
              // item['ShipperName1'] = "Sales Pro"
              // item['ShipperName2'] = "C/O Prallel Deals Limited"
              // item['ShipperAddr1'] = "Suite 126, 117 Old Pittwater Road"
              // item['ShipperAddr2'] = ""
              // item['ShipperAddr3'] = ""
              // item['Shipper_City'] ="Brookvale"
              // item['Shipper_State'] = "NSW"
              // item['Shipper_Postcode'] = "2100"
              // item['Shipper_Country'] = "Australia"
              customsheet.push(item);
            });
            setCustomSheet({ customsheet: customsheet });
            dispatch(setLoadingCustomSheet(false));
          });
      }
    }else if(check === 'sourcing'){
      if (opencustom === true) {
        dispatch(setLoadingCustomSheet(true));
        fetch(
          `https://api.saleprodev.com/getDiscontinuedProduct?supplier=${supplier}&store=${store.slice(0,2)}`,
          {
            method: "get",
            headers: { "Content-Type": "application/json" },
          }
        )
          .then((response) => response.json())
          .then((response) => {
            const disContinuedProduct = response.data;
            // const newdata = disContinuedProduct.slice(0, 10).filter((el)=> el);
            setCustomSheet({ customsheet: [...disContinuedProduct] });
            dispatch(setLoadingCustomSheet(false));
          }).catch((err)=>{
           console.log("console ho ja errror------->> ", err)
           dispatch(setLoadingCustomSheet(false));
           setOpenCustom(false)
          });
      }
    }
  }, [opencustom, date]);

  const handleChange = (value) =>{
    console.log('vallllllllllllllllllll', value.format()) 
    if(value.format() === 'Invalid Date')
    {
      setDate(lastWeekDate)
    }else{
      setDate(value)
    }
  
  }
  console.log("customsheet", customsheet);
  return (
    <>
     {check !== 'sourcing' && <MenuItem onClick={handleOpen} sx={{ height: 28, fontSize: "14px" }}>NZ Customs Sheet</MenuItem>}
      <Modal
        open={opencustom}
        onClose={handleCloseCustom}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
               {check == 'ordernew' ? <Grid container>
               <MyDatePicker handleChange = {handleChange} 
               date = {date}
               /> 
            </Grid>: null }
          {loading_custom_sheet === false ? (
            <ExcelDownloder
              data={customsheet}
              filename={check === 'sourcing' ? `discontinued_sheet_${store.slice(0,2)}_${moment(). format('YYYY-MM-DD')}` : "customsheet"}
              type={"button"} // or type={'button'}
              style={{  width: '100%', marginLeft: check === 'sourcing' ? "0%" : "15%"}}
            >
              {check === 'sourcing' ? 'Download Discontinued Sheet' : 'DOWNLOAD CUSTOMS SHEET'}
            </ExcelDownloder>
          ) : (
            <Grid container>
              <Loader size="30px" />
            </Grid>
          )}
        </Box>
      </Modal>
    </>
  );
}
