import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

import "./report.css";

import { setSlotId } from "../../actions";

const Productexportreport = ({ handlePage, page }) => {
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [store, setStore] = useState("nzshopify");
  const storeOptions = ["nzshopify", "aushopify", "gkshopify"];

  const [supplier, setSupplier] = useState("aliexpress");
  const supplierOptions = ["aliexpress"];
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 20;

  useEffect(() => {
    setLoading(true); // Set loading to true before the API request

    fetchDataFromAPI(supplier, store, currentPage);
  }, [currentPage, supplier, store]);

  const fetchDataFromAPI = (supplierValue, storeValue, page) => {
    const requestBody = {
      supplier: supplierValue,
      store: storeValue,
      page,
      perPage,
    };

    fetch("https://api.saleprodev.com/exportlogs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("API request failed:", error);
      });
  };

  const handleDetails = (item) => {
    setSelectedRow(item);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const formatDateFromISO = (isoDate) => {
    const date = new Date(isoDate);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    return date.toLocaleString(undefined, options);
  };
  

  

  return (
    <div className="container">
      <div className="container mt-5">
      <h2 class="text-center">Product Export Details</h2>
        <div className="row">
          <div className="col-8">
            <div className="row">
              <div className="col-4">
                <Select
                  value={supplier}
                  onChange={(e) => setSupplier(e.target.value)}
                >
                  {supplierOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="col-4">
                <Select
                  value={store}
                  onChange={(e) => setStore(e.target.value)}
                >
                  {storeOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="col-4">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={fetchDataFromAPI}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {loading ? (
          <div className="loader-container">
          <CircularProgress />
        </div>
        ) : (
          // Render your data and table when not loading
          <div className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Batch Id</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Success</TableCell>
                  <TableCell>Failed</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.slot_id}</TableCell>
                    <TableCell>{item.count}</TableCell>
                    <TableCell>{item.success}</TableCell>
                    <TableCell>{item.failed}</TableCell>
                    <TableCell>{formatDateFromISO(item.latestCreatedAt)}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          dispatch(setSlotId(item.slot_id));
                          window.localStorage.setItem("report_detail", "");
                          handlePage(null, "report_detail");
                        }}
                      >
                        Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className="pagination">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>{currentPage}</span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={data.length < perPage}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Productexportreport;
