import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  CardActions,
  Button,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  setSearch,
  setAccess,
  setArticles,
  setSubsettings,
  setToken,
  setStore,
  setCountrylist,
  setShowImportedProductsFilter,
  setShippingMethods,
  setCategoriesLevel1,
  setCategoriesLevel2,
  setCategoriesLevel3,
  setCategoriesLevel4,
  setCategoriesLevel5,
  setCategoriesLevel6,
  setImportedProducts,
  setImportedProductsFilter,
  setSelectedCategoryLevel2,
  setSelectedCategoryLevel3,
  setSelectedCategoryLevel4,
  setSelectedCategoryLevel5,
  setSelectedCategoryLevel6,
} from "../../actions";
import RefreshIcon from "@mui/icons-material/Refresh";
import BarChart from "./chart.js";
import BasicTable from "./table.js";
import PieChart from "./piechart.js";
import {
  VictoryBar,
  VictoryChart,
  VictoryLine,
  VictoryPie,
  VictoryTheme,
  VictoryLabel,
  VictoryGroup,
  VictoryAxis,
} from "victory";

function DashboardRestricted() {
  const dispatch = useDispatch();
  const [totalstockupdates, setTotalStockUpdates] = useState(0);
  const [totalpriceupdates, setTotalPriceUpdates] = useState(0);
  const [senttoshopify, setSentToShopify] = useState(0);
  const [senttoshopifyprice, setSentToShopifyPrice] = useState(0);
  const [calllimitreached, setCallLimitReached] = useState(0);
  const [notfound, setNotFound] = useState(0);
  const [notfoundprice, setNotFoundPrice] = useState(0);
  const [blanks, setBlanks] = useState(0);
  const [blanksprice, setBlanksPrice] = useState(0);
  const [count, setCount] = useState(0);
  const [locationerror, setLocationError] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const store = useSelector((state) => state.store);
  const access = useSelector((state) => state.access);

  return (
    <Grid
      container
      spacing={2}
      style={{ marginLeft: "0.5%", marginRight: "1%" }}
    >
      <div>No Permission to View</div>
    </Grid>
  );
}

export default DashboardRestricted;
