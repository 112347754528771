import React, {useState, useEffect} from "react"
import "./sourcing.css"
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoginIcon from '@mui/icons-material/Login';
import Button from '@mui/material/Button';
import ToggleButtons from "../togglebutton/togglebutton"
import {useSelector, useDispatch} from "react-redux"
import {setSearch, setSelectedSupplier, setProductsPerPage,setImportTotalPages,setImportPage, setArticles,setSubsettings, setToken, setLoading,setCountrylist,setShippingMethods, setShowImportedProductsFilter,setCategoriesLevel1, setCategoriesLevel2,setCategoriesLevel3, setCategoriesLevel4,setCategoriesLevel5, setCategoriesLevel6,setImportedProducts,setImportedProductsFilter,setSelectedCategoryLevel2,setSelectedCategoryLevel3, setSelectedCategoryLevel4, setSelectedCategoryLevel5, setSelectedCategoryLevel6} from "../../actions"
import BasicSelect from "../categoryselect/categoryselect"
import ProductInfo from "../productinfo/productinforev"
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import {CardHeader,CardContent} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import PaginationOutlined from "../pagination/pagination"
import PaginationOutlined_dropship from "../products/pagination"
import ImportByProductID from "../importbyproductid/importbyproductid"
import DropShipProduct from "../products/dropship.js"

function Sourcing() {
  const import_options = useSelector(state => state.import_options)
  const selected_import_option = useSelector(state => state.selected_import_option)
  const categories_level1 = useSelector(state => state.categories_level1)
  const categories_level2 = useSelector(state => state.categories_level2)
  const categories_level3 = useSelector(state => state.categories_level3)
  const categories_level4 = useSelector(state => state.categories_level4)
  const categories_level5 = useSelector(state => state.categories_level5)
  const categories_level6 = useSelector(state => state.categories_level6)
  const selected_category_level2 = useSelector(state => state.selected_category_level2)
  const selected_category_level3 = useSelector(state => state.selected_category_level3)
  const selected_category_level4 = useSelector(state => state.selected_category_level4)
  const selected_category_level5 = useSelector(state => state.selected_category_level5)
  const selected_country_shipfrom = useSelector(state => state.selected_country_shipfrom)
  const selected_country_shipto = useSelector(state => state.selected_country_shipto)
  const import_page = useSelector(state => state.import_page)
  const import_total_pages = useSelector(state => state.import_total_pages)
  const products_per_page = useSelector(state => state.products_per_page)
  const imported_products_filter = useSelector(state => state.imported_products_filter)
  const imported_products = useSelector(state => state.imported_products)
  const selected_shipping_method = useSelector(state => state.selected_shipping_method)
  const selected_supplier = useSelector(state => state.selected_supplier)
  const show_imported_filtered_products = useSelector(state => state.show_imported_filtered_products)
  const loading = useSelector(state => state.loading)
  const token = useSelector(state => state.token)
  const dispatch = useDispatch()
  console.log(selected_import_option)


  // useEffect(() => {
  //   const data = async () => {
  //     // await fetch("https://api.banggood.com/getAccessToken?app_id=bg614e8e254eca7&app_secret=5e76df83d25c08505022011c3d0f75f9", {headers:{"access-control-allow-origin" : "*"}}).then(response => response.json()).then(response => console.log(response))
  //     await fetch("http://localhost:3001/bangood").then(response => response.json()).then(response => {
  //       console.log("token", response)
  //       dispatch(setToken(response))
  //     })
  //   }
  //
  //     data()
  //
  // }, [])

  useEffect(() => {
    const data = async () => {

        let categories = []
        let categories_final = []

          await fetch("http://localhost:3001/bangoodproductcategories", {
            method:"POST",
            body: JSON.stringify({token:token.access_token, page:1})
          }).then(response => response.json()).then(response => {
              if(response.cat_list){
                categories.push(...response.cat_list)
              }
          })

          await fetch("http://localhost:3001/bangoodproductcategories", {
            method:"POST",
            body: JSON.stringify({token:token.access_token, page:2})
          }).then(response => response.json()).then(response => {
              if(response.cat_list){
                categories.push(...response.cat_list)
              }
          })

          await fetch("http://localhost:3001/bangoodproductcategories", {
            method:"POST",
            body: JSON.stringify({token:token.access_token, page:3})
          }).then(response => response.json()).then(response => {
              if(response.cat_list){
                categories.push(...response.cat_list)
              }
          })

          await fetch("http://localhost:3001/bangoodproductcategories", {
            method:"POST",
            body: JSON.stringify({token:token.access_token, page:4})
          }).then(response => response.json()).then(response => {
              if(response.cat_list){
                categories.push(...response.cat_list)
              }
          })

          await fetch("http://localhost:3001/bangoodproductcategories", {
            method:"POST",
            body: JSON.stringify({token:token.access_token, page:5})
          }).then(response => response.json()).then(response => {
              if(response.cat_list){
                categories.push(...response.cat_list)
              }
          })

          await fetch("http://localhost:3001/bangoodproductcategories", {
            method:"POST",
            body: JSON.stringify({token:token.access_token, page:6})
          }).then(response => response.json()).then(response => {
              if(response.cat_list){
                categories.push(...response.cat_list)
              }
          })

          await fetch("http://localhost:3001/bangoodproductcategories", {
            method:"POST",
            body: JSON.stringify({token:token.access_token, page:7})
          }).then(response => response.json()).then(response => {
              if(response.cat_list){
                categories.push(...response.cat_list)
              }
          })

          await fetch("http://localhost:3001/bangoodproductcategories", {
            method:"POST",
            body: JSON.stringify({token:token.access_token, page:8})
          }).then(response => response.json()).then(response => {
              if(response.cat_list){
                categories.push(...response.cat_list)
              }
          })

        console.log(categories)
        if(categories.length >= 3984){
          dispatch(setCategoriesLevel1(categories))
        }
      }
    data()
  }, [token])

useEffect(() => {
  let subcategories = []
  if(Array.isArray(categories_level1)){
    categories_level1.map(item => {
      if(item.parent_id === "0"){
        subcategories.push(item)
      }
    })
  }
console.log(subcategories)
dispatch(setCategoriesLevel2(subcategories))
},[categories_level1])

useEffect(() => {
  let subcategories = []

    categories_level1.map(item => {
      if(item.parent_id === selected_category_level2){
        subcategories.push(item)
      }
    })

console.log(subcategories)
dispatch(setCategoriesLevel3(subcategories))
},[selected_category_level2])

useEffect(() => {
  let subcategories = []

    categories_level1.map(item => {
      if(item.parent_id === selected_category_level3){
        subcategories.push(item)
      }
    })


dispatch(setCategoriesLevel4(subcategories))
},[selected_category_level3])

useEffect(() => {
  let subcategories = []

    categories_level1.map(item => {
      if(item.parent_id === selected_category_level4){
        subcategories.push(item)
      }
    })


dispatch(setCategoriesLevel5(subcategories))
},[selected_category_level4])

useEffect(() => {
  let subcategories = []

    categories_level1.map(item => {
      if(item.parent_id === selected_category_level5){
        subcategories.push(item)
      }
    })


dispatch(setCategoriesLevel6(subcategories))
},[selected_category_level5])

const handleImport = async () => {

  dispatch(setImportedProducts([]))
  dispatch(setImportedProductsFilter([]))
  dispatch(setShowImportedProductsFilter(true))

  const items = []
  let array = []
  let filtered_array = []
  if(selected_category_level3 !== ""){


      if(products_per_page === 20){
        await fetch("http://localhost:3001/bangoodproducts", {
        method:"POST",
        body: JSON.stringify({token:token.access_token, cat_id:selected_category_level4, page:import_page})
      }).then(response => response.json()).then(response => {
        if(response.product_list){
          console.log("response",response)
          dispatch(setImportTotalPages(response.page_total))
          items.push(...response.product_list)
        }
      })
      }

      if(products_per_page > 20){
        let no_of_pages = 0
        for(let i= parseInt((products_per_page/20))*(import_page-1) + 1;i<=parseInt((products_per_page/20))*(import_page-1) + parseInt((products_per_page/20)) ;i++){
          await fetch("http://localhost:3001/bangoodproducts", {
          method:"POST",
          body: JSON.stringify({token:token.access_token, cat_id:selected_category_level4, page:i})
        }).then(response => response.json()).then(response => {
          if(response.product_list){
            console.log("response",response)
            if(Number.isInteger(response.product_total/products_per_page)){
              no_of_pages = parseInt(response.product_total/products_per_page)
            }else{
              no_of_pages = parseInt(response.product_total/products_per_page) + 1
            }
            dispatch(setImportTotalPages(no_of_pages))
            items.push(...response.product_list)
          }

        })
        }

      }

  console.log(items)

  items.map(item => {
    fetch("http://localhost:3001/bangoodproductinfo", {
      method:"POST",
      body: JSON.stringify({token:token.access_token, product_id:item.product_id})
    }).then(response => response.json()).then(response => {

        array = [...array, {...response, product_id:item.product_id}]
        dispatch(setImportedProducts(array))

    })

  })

}
}
//
// useEffect(() => {
//   const filtered_array = []
//   let filtered_array_shipto = []
//   if(imported_products.length % 10 === 0 && selected_category_level4 !== "" && selected_category_level2 !== "" && selected_category_level3 !== ""){
//     imported_products.map(item => {
//       if(item.code === 0){
//         item.warehouse_list.map(element => {
//           if(element.warehouse === selected_country_shipfrom){
//             filtered_array.push({...item})
//           }
//         })
//       }
//     })
//     console.log("filtered_array", filtered_array)
//     filtered_array.map(item => {
//       fetch("http://localhost:3001/shipping", {
//         method:"POST",
//         body: JSON.stringify({token:token.access_token, product_id:item.product_id, warehouse:selected_country_shipfrom, country:selected_country_shipto})
//       }).then(response => response.json()).then(response => {
//         console.log(response)
//         if(response.shipmethod_list){
//           item.shipment = response.shipmethod_list
//           if(item.shipment.find(o => o.shipmethod_name === selected_shipping_method) !== undefined){
//             filtered_array_shipto = [...filtered_array_shipto, item]
//             dispatch(setImportedProductsFilter(filtered_array_shipto))
//           }
//         }
//       })
//     })
//
//   }
//
//
// }, [imported_products])

console.log(categories_level1,categories_level2, categories_level3, categories_level4, categories_level5, categories_level6)
console.log("imported_products", imported_products)
console.log("imported_products_filter", imported_products_filter)
console.log("loading", loading)

  return (
    <Grid container style={{marginTop:"2%", marginLeft:"1%", marginRight:"1%"}}>
      <Grid style={{textAlign:"center"}} item xs={12} sm={12} lg={12}>
          <ToggleButtons options={import_options} type="importoption"/>
          <div style={{marginLeft:"2%", fontSize:"100px"}}>
          {
          //   (loading && selected_import_option === "Import by Category")?(
          //   <CircularProgress size={80}/>
          // ):""
        }
          </div>
      </Grid>
      <Grid style={{}} item xs={12} sm={12} lg={12}>
          <Card>
            <CardHeader title="Quick filter"/>
            <CardContent>
              <Grid container>
                <Grid item xs={1} sm={1} lg={1}>
                  Supplier:
                </Grid>
                <Grid item xs={1} sm={1} lg={1}>
                {(selected_supplier === "bangood")?(
                  <Button variant="contained">Bangood</Button>
                ):(
                  <Button onClick={(event) => dispatch(setSelectedSupplier("bangood"))}>Bangood</Button>
                )}

                </Grid>
                <Grid item xs={1} sm={1} lg={1}>
                {(selected_supplier === "dropshipzone")?(
                  <Button variant="contained">DROPSHIPZONE</Button>
                ):(
                  <Button onClick={(event) => dispatch(setSelectedSupplier("dropshipzone"))}>DROPSHIPZONE</Button>
                )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
      </Grid>

      <Grid item xs={2} sm={2} lg={2} style={{marginTop:"2%"}}>
      {selected_import_option === "Import by Category" && selected_supplier === "bangood" && Array.isArray(categories_level2) ?(
          <>
          <BasicSelect label="Choose a Category" menu={categories_level2} type="CATLEVEL2"/>

          </>
      ):("")}

      </Grid>
      <Grid item xs={2} sm={2} lg={2} style={{marginTop:"2%", marginLeft:"0.3%"}}>
      {selected_import_option === "Import by Category" && selected_supplier === "bangood" && Array.isArray(categories_level2) ?(
          <>

          <BasicSelect label="Choose a SubCategory" menu={categories_level3} type="CATLEVEL3"/>

          </>
      ):("")}

      </Grid>
      <Grid item xs={2} sm={2} lg={2} style={{marginTop:"2%", marginLeft:"0.3%"}}>
      {selected_import_option === "Import by Category" && selected_supplier === "bangood" && Array.isArray(categories_level2) ?(
          <>

          <BasicSelect label="Choose a SubCategory2" menu={categories_level4} type="CATLEVEL4"/>
          </>
      ):("")}

      </Grid>
      <Grid item xs={2} sm={2} lg={2} style={{marginTop:"2%", marginLeft:"0.3%"}}>
      {selected_import_option === "Import by Category" && selected_supplier === "bangood" && Array.isArray(categories_level2) ?(
          <>

          <BasicSelect label="Choose a SubCategory3" menu={categories_level5} type="CATLEVEL5"/>
          </>
      ):("")}

      </Grid>
      <Grid item xs={2} sm={2} lg={2} style={{marginTop:"2%", marginLeft:"0.3%"}}>
      {selected_import_option === "Import by Category" && selected_supplier === "bangood" && Array.isArray(categories_level2) ?(
          <>

          <BasicSelect label="Choose a SubCategory3" menu={categories_level6} type="CATLEVEL6"/>
          </>
      ):("")}

      </Grid>
      <Grid item xs={1} sm={1} lg={1} style={{marginTop:"2%"}}>
      {selected_import_option === "Import by Category" && selected_supplier === "bangood" && Array.isArray(categories_level2) ?(
          <>
          <IconButton onClick={(event) => handleImport(event)}  color="primary" aria-label="upload picture" component="span" style={{height:"50px", width:"50px", marginLeft:"18%", backgroundColor:"#4fa6e8", color:"white"}}>
            <SearchIcon/>
          </IconButton>
        </>
      ):("")
    }
      </Grid>

      <Grid container spacing={2} style={{marginTop:"1%", marginLeft:"0%", marginRight:"2%"}}>
      {selected_supplier === "bangood" &&
      <div style={{marginLeft:"35%"}}>
      <PaginationOutlined handleImport={handleImport}/>
      </div>
      }


      <Grid item xs={12} lg={12} sm={12}>

      {selected_supplier === "bangood" &&
        <Card>
        <div style={{display:"grid", gridTemplateColumns:"3% 7% 35% 5% 10% 15% 10% 5%", gridGap:"5px"}}>
          <div style={{textAlign:"center", marginTop:"30%"}}>
          </div>

          <div>
          <p>Image</p>
          </div>

          <div><p>Products</p></div>
          <div style={{textAlign:"center"}}>Cost</div>
          <div style={{textAlign:"center"}}>Variants</div>
          <div style={{textAlign:"center"}}>Supplier</div>
          <div style={{textAlign:"center"}}>Stock</div>
        </div>
        </Card>
      }



      </Grid>

      {(selected_import_option === "Import by Category" && selected_supplier === "dropshipzone")?(
        <Grid item xs={12} lg={12} sm={12} >
          <DropShipProduct/>
        </Grid>
      ):""}

      {selected_import_option === "Import by Category" && selected_supplier === "bangood" && Array.isArray(categories_level2) && Array.isArray(imported_products_filter) && show_imported_filtered_products?(
        <>
        {
          imported_products.map(item => {
          return (
            <Grid item xs={12} lg={12} sm={12} >
            <Card>
              <div style={{display:"grid", gridTemplateColumns:"100%", gridGap:"5px"}}>
              {
                // <div style={{textAlign:"center", marginTop:"30%"}}>
                // <input type="checkbox" id="horns" name="horns"/>
                // </div>
                // <div><img src={Object.values(item.image_list.home)[0]} width="100" height="100"/></div>
                // <div>
                // <a target="_blank" href={`https://www.banggood.com/search/${item.product_id}.html?from=nav&ab_version=2`}><p>{item.product_name}</p></a>
                // <p>{item.product_id}</p>
                // </div>
                // <div style={{textAlign:"center"}}>USD 1.68</div>
                // <div style={{textAlign:"center"}}>
                //   <div>USD 0.74</div>
                //   <div>China Post</div>
                //   <div>United States</div>
                // </div>
                // <div style={{textAlign:"center"}}>{item.poa_list.length}</div>
                // <div style={{textAlign:"center"}}>Bangood</div>
              }


                <ProductInfo product_id={item.product_id} token={token} cat_id={selected_category_level4} product={item}/>
              </div>

            </Card>

            </Grid>

          )
        })
      }
        </>
      ):("")
    }
      </Grid>


      <Grid item xs={12} sm={12} lg={12} style={{marginTop:"2%"}}>
      {selected_import_option === "Import by Product ID" ?(
          <div>
            <ImportByProductID/>
          </div>
      ):("")
    }
      </Grid>
      <div style={{marginTop:"3%", marginLeft:"40%"}}>

      </div>

    </Grid>

    )
}

export default Sourcing;
