import React, { useEffect, useState } from "react";
import KoganCategorySelect from "./KoganCategorySelect";

import { Grid, Button, TextField } from "@mui/material";

export default function KoganCategories({
  koganCategoryLevel1,
  koganCategoryLevel2,
  setKoganCategoryLevel1,
  setKoganCategoryLevel2,
  createkoganmapping,
  setTagName,
  tag_name,
}) {
  return (
    <Grid
      sx={{
        display: "grid",
        gridTemplateColumns: "20% 20% 30% 30%",
      }}
    >
      <Grid item p={1}>
        <TextField
          size="small"
          value={tag_name}
          placeholder="Enter Type"
          onChange={(event) => setTagName(event.target.value)}
          sx={{
            width: "100%",
            fontSize: "0.8rem",
            "& legend": {
              width: "unset",
            },
          }}
        />
      </Grid>

      <KoganCategorySelect
        koganCategoryLevel1={koganCategoryLevel1}
        koganCategoryLevel2={koganCategoryLevel2}
        setKoganCategoryLevel1={setKoganCategoryLevel1}
        setKoganCategoryLevel2={setKoganCategoryLevel2}
      />

      <Grid item p={1}>
        <Button
          variant="contained"
          onClick={(event) => createkoganmapping()}
          sx={{ width: "100%" }}
        >
          ADD
        </Button>
      </Grid>
    </Grid>
  );
}
