import React from "react";
import BasicSelect from "../../select/select";
import { useSelector, useDispatch } from "react-redux";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { setSettingsItem } from "../../../actions";

import {
  Card,
  Grid,
  Typography,
  Button,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItemButton,
  CircularProgress,
  Breadcrumbs,
} from "@mui/material";

export const ImportSettingsDetail = () => {
  const dispatch = useDispatch();
  const country_list_shipfrom = useSelector(
    (state) => state.country_list_shipfrom
  );
  const country_list_shipto = useSelector((state) => state.country_list_shipto);
  const shipping_methods = useSelector((state) => state.shipping_methods);

  return (
    <Card elevation={0} sx={{ my: 1, minHeight: "90vh" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          px: 4,
          py: 2,
          borderBottom: (theme) => `1px solid ${theme.palette.border}`,
        }}
      >
        <Button
          size="small"
          variant="text"
          startIcon={<ChevronLeftIcon />}
          onClick={() => dispatch(setSettingsItem(""))}
          sx={{
            "& .MuiButton-startIcon": {
              mr: 0.5,
            },
          }}
        >
          Back
        </Button>
        <Typography variant="h6">Import Settings</Typography>
        <Button size="small" variant="contained" disabled>
          Save
        </Button>
      </Grid>

      <Grid
        container
        p={4}
        xs={8}
        flexDirection="column"
        alignContent="flex-start"
      >
        <Grid
          container
          sx={{
            py: 2,
            borderBottom: (theme) => `1px dashed ${theme.palette.border}`,
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1">Ship from</Typography>
          </Grid>
          <Grid item xs={6}>
            <BasicSelect
              label="Choose a Country"
              menu={country_list_shipfrom}
              type="shipfrom"
            />
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            py: 2,
            borderBottom: (theme) => `1px dashed ${theme.palette.border}`,
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1">Ship To</Typography>
          </Grid>
          <Grid item xs={6}>
            <BasicSelect
              label="Choose a Country"
              menu={country_list_shipto}
              type="shipto"
            />
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            py: 2,
            borderBottom: (theme) => `1px dashed ${theme.palette.border}`,
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1">Shipping Method</Typography>
          </Grid>
          <Grid item xs={6}>
            <BasicSelect
              label="Choose a shipping method"
              menu={shipping_methods}
              type="shippingmethod"
            />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
