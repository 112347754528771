import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const LinearProgressWithLabel = (props) => {
    console.log("props.value", props.value)
    return (
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            {/* <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${(props.value)}%`}</Typography>
            </Box> */}
        </Box>
    )



}