import React, { useState, useEffect } from "react";

import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function AppBar({ handlePage, handleLogout }) {
  const [orders, setOrders] = useState("");
  return (
    <div className="App">
      <Navbar bg="light" expand="lg">
        <Container id="navbar">
          <Navbar.Brand href="#home">SalesPro Admin Panel</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link onClick={(event) => handlePage(event, "home")}>
                Home
              </Nav.Link>

              <Nav.Link
                onClick={(event) => handlePage(event, "supplierproducts")}
              >
                Supplier Products
              </Nav.Link>

              <div onClick={(event) => handlePage(event, "shopifyorders")}>
                <Nav.Link>Shopify Orders</Nav.Link>
              </div>
              <div onClick={(event) => handlePage(event, "shopifyproducts")}>
                <Nav.Link>Shopify Products</Nav.Link>
              </div>
            </Nav>
            <Form className="d-flex">
              <FormControl
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
              />
              <Button variant="outline-success">Search</Button>
            </Form>
            <Nav className="me-auto">
              <div onClick={(event) => handleLogout(event)}>
                <Nav.Link>Log Out</Nav.Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default AppBar;
