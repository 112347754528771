import React, { useEffect, useState } from "react";
import { Grid, Autocomplete, TextField, Box } from "@mui/material";

export default function DSZCategorySelect({
  DSZCategoryLevel1,
  DSZCategoryLevel2,
  setDSZCategoryLevel1,
  setDSZCategoryLevel2,
  DSZCategoryLevel3,
  setDSZCategoryLevel3,
}) {
  const [categorieslevel1, setCategoriesLevel1] = useState([]);
  const [categorieslevel2, setCategoriesLevel2] = useState([]);
 const [categorieslevel3, setCategoriesLevel3] = useState([{id: 1, title: 'wefullfill'},{id: 2, title: 'aliExpress'}]);

 console.log("this is DSZL3----------------------->>>>",DSZCategoryLevel3, );

const Fetchlevel2 = () =>
{
    try {
        if(!DSZCategoryLevel1.category_id) return;
        setDSZCategoryLevel2({id: null, title: 'Select a Category'});
        console.log("----------->> DSZCategoryLevel1", DSZCategoryLevel1)
        fetch("https://api.saleprodev.com/productimporter/dropshipv2categorieslevel2", {
          method: "post",
          body: JSON.stringify({ category1: DSZCategoryLevel1.category_id }),
        })
          .then((response) => response.json())
          .then((response) => {
            console.log(response);
            setCategoriesLevel2(response.categories_level_2);
          });
    } catch (error) {
        
    }
}

  useEffect(() => {
    fetch("https://api.saleprodev.com/productimporter/dropshipv2categorieslevel1")
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel1(response.categories_level_1);
      });
  }, []);

//   console.log("DSZCategoryLevel1=-=---=-===========>>> ", DSZCategoryLevel1)

  useEffect(() => {

    Fetchlevel2();

  }, [DSZCategoryLevel1]);

  const handleChange = (event, value) => {
    console.log("value", value);
    value === null
      ? setDSZCategoryLevel1({id: null, title: 'Select a Category'})
      : setDSZCategoryLevel1(value);
  };

  const handleChange2 = (event, value) => {
    console.log("value", value);
    value === null
      ? setDSZCategoryLevel2({id: null, title: 'Select a Category'})
      : setDSZCategoryLevel2(value);
  };

  const handleChange3 = (event, value) => {
    console.log("value", value);
    value === null
      ? setDSZCategoryLevel3({id: null, title: 'Select a Supplier'})
      : setDSZCategoryLevel3(value);
  };


  const defaultProps1 = {
    options: categorieslevel1,
    getOptionLabel: (option) => option,
  };

  const defaultProps2 = {
    options: categorieslevel2,
    getOptionLabel: (option) => option,
  };

  const defaultProps3 = {
    options: categorieslevel3,
    getOptionLabel: (option) => option,
  };
   



  return (
    <>
      <Grid item p={1}>
        <Autocomplete
          size="small"
          {...defaultProps3}
          value={DSZCategoryLevel3.title}
          onChange={(event, value) => handleChange3(event, value)}
          renderInput={(params) => <TextField {...params} />}
          sx={{
            "& legend": {
              width: "unset",
            },
          }}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option.title}
            </Box>
          )}
        />
      </Grid>

      <Grid item p={1}>
        <Autocomplete
          size="small"
          {...defaultProps1}
          value={DSZCategoryLevel1.title}
          onChange={(event, value) => handleChange(event, value)}
          renderInput={(params) => <TextField {...params} />}
          sx={{
            "& legend": {
              width: "unset",
            }, 
          }}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option.title}
            </Box>
          )}
        />
      </Grid>

      <Grid item p={1}>
        <Autocomplete
          size="small"
          {...defaultProps2}
          value={DSZCategoryLevel2.title}
          onChange={(event, value) => handleChange2(event, value)}
          renderInput={(params) => <TextField {...params} />}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option.title}
            </Box>
          )}
          sx={{
            "& legend": {
              width: "unset",
            },
          }}
        />
      </Grid>
    </>
  );
}
