import * as React from "react";
import Checkbox from "@mui/material/Checkbox";

export default function MainCheckbox({
  orders_nz_shopify,
  start,
  end,
  selectedOrders,
  setSelectedOrders,
}) {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    console.log("abc", orders_nz_shopify);
    if (event.target.checked) {
      let array = [];
      orders_nz_shopify.map((order) => {
        let element = document.getElementById(`${order.orderid}_checkbox`);
        if (element.checked) {
          console.log("do nothing");
        } else {
          element.checked = true;
        }

        array.push(order);
      });
      console.log("array", array);
      setSelectedOrders([...array]);
    } else {
      orders_nz_shopify.map((order) => {
        let element = document.getElementById(`${order.orderid}_checkbox`);
        if (element.checked) {
          element.checked = false;
        }
      });
      setSelectedOrders([]);
    }
  };

  return <input type="checkbox" onChange={handleChange} id="maincheckbox" />;
}
