import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function BangoodVariantSelect({
  productvariants,
  setProductVariants,
}) {
  const handleChange = (event) => {
    setProductVariants(event.target.value);
  };

  return (
    <RadioGroup
      row
      value={productvariants}
      onChange={handleChange}
      sx={{
        ml: 1,
        "& .MuiRadio-root": {
          p: "5px",
        },
      }}
    >
      <FormControlLabel
        value="all"
        control={<Radio size="small" />}
        label="All"
      />
      <FormControlLabel
        value="standard"
        control={<Radio size="small" />}
        label="Standard SKU"
      />
      <FormControlLabel
        value="multivariant"
        control={<Radio size="small" />}
        label="MultiVariant"
      />
    </RadioGroup>
  );
}
