import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import "./checkbox.css";

export default function ControlledCheckbox({
  product,
  checkboxID,
  setSelectedProducts,
  selectedProducts,
}) {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    console.log("target", event.target);
    if (event.target.checked === true) {
      let array = [...selectedProducts];
      array.push(product);
      setSelectedProducts(array);
    }
    if (event.target.checked === false) {
      let array = [...selectedProducts];
      let index = array.indexOf(product);
      array.splice(index, 1);
      setSelectedProducts(array);
    }
  };

  // console.log("selected", selectedProducts, product)

  return (
    <input
      type="checkbox"
      onChange={handleChange}
      // id={product ? product.ItemId : ""}
      id={checkboxID}
    />
  );
}
