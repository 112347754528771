import React, { Component } from 'react';
import Chart from 'react-apexcharts'



const Donut = ({item}) => {

  let series = [item.shipped, item.purchased, item.pending]
  let marginseries = [parseFloat(item.cost), (parseFloat(item.sales) - parseFloat(item.cost))]
  var options = {
         series: [44, 55, 41, 17, 15],
         colors:['#34eb3a', '#ebeb34', '#eb343a'],
         chart: {
         width: 380,
         type: 'pie',
       },
       plotOptions: {
         pie: {
           donut:{
             labels:{
               show:true,
               total:{
                 show:true,
                 color:"#F44336"
               },
             }
           },
           startAngle: -90,
           endAngle: 270
         }
       },
       labels:["Shipped", 'Purchased', 'Pending'],
       legend: {
         formatter: function(val, opts) {
           return val + " - " + opts.w.globals.series[opts.seriesIndex]
         },
       },
       title: {
         text: `Orders- ${item.supplier.toUpperCase()}`
       },
       responsive: [{
         breakpoint: 480,
         options: {
           chart: {
             width: 200
           },
           legend: {
             position: 'bottom'
           }
         }
       }]
       };

       var marginoptions = {
              series: [44, 55, 41, 17, 15],
              colors:['#0717fa', '#34eb3a'],
              chart: {
              width: 380,
              type: 'pie',
            },
            plotOptions: {
              pie: {
                donut:{
                  labels:{
                    show:true,
                    total:{
                      show:true,
                      color:"#F44336"
                    },
                  }
                },
                startAngle: -90,
                endAngle: 270
              }
            },
            labels:['Cost', 'Margin'],
            legend: {
              formatter: function(val, opts) {
                return val + " - " + opts.w.globals.series[opts.seriesIndex]
              },
            },
            title: {
              text: `Sales- ${item.supplier.toUpperCase()}`
            },
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
            }

       // options = {
       //   chart: {
       //     type: 'bar'
       //   },
       //   series: [{
       //     data: [{
       //       x: 'category A',
       //       y: 10
       //     }, {
       //       x: 'category B',
       //       y: 18
       //     }, {
       //       x: 'category C',
       //       y: 13
       //     }]
       //   }]
       // }

  return (
    <div style={{display:"grid", gridTemplateColumns:"50% 50%"}}>
    {
    <Chart options={options}  series={series} type="donut" width="380" />
    }
    <Chart options={marginoptions}  series={marginseries} type="donut" width="380" />
    </div>
  )
}

export default Donut
