import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import "./checkbox.css";

export default function ControlledCheckbox({
  order,
  setSelectedOrders,
  selectedOrders,
}) {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    console.log("target", event.target);
    if (event.target.checked === true) {
      let array = [...selectedOrders];
      array.push(order);
      setSelectedOrders(array);
    }
    if (event.target.checked === false) {
      let array = [...selectedOrders];
      let index = array.indexOf(order);
      array.splice(index, 1);
      setSelectedOrders(array);
    }
  };

  console.log("selectedOrders", selectedOrders);
  return (
    <input
      type="checkbox"
      onChange={handleChange}
      id={`${order.orderid}_checkbox`}
      style={{ fontSize: "110%" }}
    />
  );
}
