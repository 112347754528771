import React, { useState, useEffect } from "react";
import moment from "moment";
import ControlledCheckbox from "./wedropshipcheckbox.js";
import no_image from "../assets/images/no_image.jpg";

import { Card, Grid, Box, Tooltip, Typography } from "@mui/material";

export default function WeDropshipProductsLayout({
  product,
  fetchproducts,
  selectedProducts,
  setSelectedProducts,
  handleImport,
}) {
  const formatDate = (date) => moment(date).format("DD/MM/YYYY HH:mm:ss");

  return (
    <Card sx={{ width: "100%", mt: 1 }}>
      <Grid
        container
        sx={{
          minHeight: "70px",
          display: "Grid",
          gridTemplateColumns: "2% 4% 35% 8% 8% 10% 8% 5% 5% 5% 6% 4%",
          alignItems: "center",
        }}
      >
        {/* Checkbox */}
        <Grid container justifyContent="center">
          <ControlledCheckbox
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            product={product}
          />
        </Grid>

        {/* Image */}
        <Grid container justifyContent="center" alignItems="center" p={0.5}>
          <Tooltip
            arrow
            placement="top"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  padding: 0,
                },
              },
            }}
            title={
              <img
                src={product.images ? product.images.split(";")[0] : no_image}
                alt=""
                style={{
                  width: "250px",
                }}
              />
            }
          >
            <Grid
              item
              justifyContent="center"
              alignContent="center"
              sx={{
                minHeight: "60px",
                border: (theme) => `1px solid ${theme.palette.border}`,
              }}
            >
              <img
                src={product.images ? product.images.split(";")[0] : no_image}
                alt=""
                width="60px"
              />
            </Grid>
          </Tooltip>
        </Grid>

        {/* Title & Sku # & Product ID */}
        <Grid container flexDirection="column" pl={1}>
          {product.name && product.name.length > 90 ? (
            <Tooltip arrow placement="top" title={product.name}>
              <Typography variant="body2">
                {`${product.name.slice(0, 90)}...`}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="body2">{product.name}</Typography>
          )}

          <Grid container>
            <Typography
              component="a"
              target="_blank"
              href={product.url}
              variant="body2"
              color="primary"
            >
              {product.sku && `Sku #: ${product.sku}`}
            </Typography>
          </Grid>
        </Grid>

        {/* Barcode */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.upc === null || product.upc === "" ? "N/A" : product.upc}
          </Typography>
        </Grid>

        {/* Weight */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.weight_kg === null || product.weight_kg === ""
              ? "N/A"
              : `${product.weight_kg}kg`}
          </Typography>
        </Grid>

        {/* Cost */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.price_inc_gst &&
              parseFloat(product.price_inc_gst).toFixed(2)}AUD
          </Typography>
        </Grid>

        {/* Shipping Cost */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.shipping_cost ? product.shipping_cost : "N/A"}
          </Typography>
        </Grid>

        {/* Variation */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {Array.isArray(product.variants) ? product.variants.length : 0}
          </Typography>
        </Grid>

        {/* Stock */}
        <Grid container justifyContent="center">
          <Typography variant="body2">{product.stock_on_hand}</Typography>
        </Grid>

        {/* Supplier */}
        <Grid container justifyContent="center">
          <Typography variant="body2">WeDropship</Typography>
        </Grid>

        {/* Created At */}
        <Grid container justifyContent="center">
          <Typography variant="body2" textAlign="center">
            {formatDate(product.createdAt)}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
