import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import "./settings.css";
import { useSelector, useDispatch } from "react-redux";
import AcccessibleTable from "./table.js";
import PaginationOutlined_orders from "./pagination.js";
import { setImportTotalPages } from "../../actions";
import { padding } from "@mui/system";
import DSZCategories from "./DSZCategories.js";

const DSZsetting = () => {
  const dispatch = useDispatch();
  const [DSZCategoryLevel1, setDSZCategoryLevel1] =
    React.useState({id: null, title: 'Select a Category'});
  const [DSZCategoryLevel2, setDSZCategoryLevel2] =
    React.useState({id: null, title: 'Select a Category'});
    const [DSZCategoryLevel3, setDSZCategoryLevel3] =
    React.useState({id: null, title: 'Select a Supplier'});
  const [category, setCategory] = React.useState("");
  const [DSZmappings, setDSZMappings] = useState([]);
  const [mappingcreated, setMappingCreated] = useState(0);
  const products_per_page = useSelector((state) => state.products_per_page);
  const import_page = useSelector((state) => state.import_page);
  const [tagname, setTagName] = useState("");
  const searchField = useSelector((state) => state.searchField);
  console.log(searchField);
  useEffect(() => {
    fetch("https://api.saleprodev.com/productimporter/getdszv2mappings", {
      method: "post",
      body: JSON.stringify({
        products_per_page: products_per_page,
        page: import_page,
        search: searchField,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response.data);
        dispatch(
          setImportTotalPages(parseInt(response.count / products_per_page) + 1)
        );
        console.log("res.DATA===> ", response.data)
        setDSZMappings(response.data);
      });
  }, [mappingcreated, products_per_page, import_page, searchField]);

  useEffect(() => {
    if (
      DSZCategoryLevel1.title !== "Select a Category" &&
      DSZCategoryLevel2.title !== "Select a Category"
    ) {
      fetch("https://api.saleprodev.com/productimporter/dszv2category", {
        method: "post",
        body: JSON.stringify({
          category1: DSZCategoryLevel1.category_id,
          category2: DSZCategoryLevel2.category_id,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setCategory(response);
        });
    }
  }, [DSZCategoryLevel1, DSZCategoryLevel2]);

  useEffect(() => {
    setDSZCategoryLevel1({id: null, title: 'Select a Category'});
  }, [mappingcreated]);

  useEffect(() => {
    setTagName("");
  }, [mappingcreated]);

  const createDSZmapping = () => {
    console.log({
      mapping: {
        tag_name: tagname,
        categories_level_1: DSZCategoryLevel1.title,
        categories_level_2: DSZCategoryLevel2.title,
        category_id : DSZCategoryLevel2.category_id,
        supplier : DSZCategoryLevel3.title,
        DSZcategory: category,
      },
    });
    if (
      tagname === "" ||
      DSZCategoryLevel1.title === "Select a Category" ||
      DSZCategoryLevel2.title === "Select a Category"
    ) {
      alert("Details missing");
    } else {
      let mapping = {
        mapping: {
          tag_name: tagname,
          category_level_1: DSZCategoryLevel1.title,
          category_level_2: DSZCategoryLevel2.title,
          category_id : DSZCategoryLevel2.category_id,
          supplier : DSZCategoryLevel3.title,
          // DSZcategory: category,
        },
      };
      fetch("https://api.saleprodev.com/productimporter/createdszv2mappings", {
        method: "post",
        body: JSON.stringify(mapping),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          if (response.status === "success") {
            setMappingCreated(mappingcreated + 1);
          } else {
            alert(response.message);
          }
        });
    }
  };

  const handleDelete = (tag_name) => {
    fetch("https://api.saleprodev.com/productimporter/deletedszv2mappings", {
      method: "post",
      body: JSON.stringify({ tag_name: tag_name }),
    })
      .then((response) => response.json())
      .then((response) => {
        setMappingCreated(mappingcreated + 1);
      });
  };

  // console.log("DSZ Setting----------------------=========>",DSZCategoryLevel3)

  return (
    <Grid container p={1}>
      <Container maxWidth="xl">
      <h2 class="text-center">DSZ Mapping</h2>
        <DSZCategories
          DSZCategoryLevel1={DSZCategoryLevel1}
          DSZCategoryLevel2={DSZCategoryLevel2}
          setDSZCategoryLevel1={setDSZCategoryLevel1}
          setDSZCategoryLevel2={setDSZCategoryLevel2}
          DSZCategoryLevel3={DSZCategoryLevel3}
          setDSZCategoryLevel3={setDSZCategoryLevel3}
          createDSZmapping={createDSZmapping}
          setTagName={setTagName}
          tag_name={tagname}

        />
        <PaginationOutlined_orders />
        <AcccessibleTable
          DSZmappings={DSZmappings}
          handleDelete={handleDelete}
        />
      </Container>
    </Grid>
  );
}

export default DSZsetting;
