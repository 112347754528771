import React, { useState } from "react";
import moment from "moment";
import no_image from "../assets/images/no_image.jpg";
import { Card, Grid, Tooltip, Typography } from "@mui/material";
import ControlledCheckbox from "./sogacheckbox.js";

export default function SogaProductsLayout({
  product,
  fetchproducts,
  selectedProducts,
  setSelectedProducts,
  handleImport,
}) {
  const formatDate = (date) => moment(date).format("DD/MM/YYYY HH:mm:ss");
  // const [proxyURL, setProxyURL] = useState('')

  // if (product && product.image_link_1) {
  //   const imageUrl = product.image_link_1
  //   console.log("imageUrl", imageUrl)

  //   fetch(imageUrl)
  //     .then(response => {
  //       console.log('Response Status:', response.status);

  //       if (!response.ok) {
  //         throw new Error(`Failed to fetch image: ${response.statusText}`);
  //       }
  //       return response.blob();
  //     })
  //     .then(blob => {
  //       console.log('Blob:', blob);
  //       const blobUrl = URL.createObjectURL(blob);
  //       setProxyURL(blobUrl)
  //     })
  //     .catch(error => {
  //       console.error('Fetch Error:', error);
  //     });
  // }

  return (
    <Card sx={{ width: "100%", mt: 1 }}>
      <Grid
        container
        sx={{
          minHeight: "70px",
          display: "Grid",
          gridTemplateColumns: "2% 4% 35% 8% 8% 10% 8% 5% 5% 5% 6% 4%",
          alignItems: "center",
        }}
      >
        {/* Checkbox */}
        <Grid container justifyContent="center">
          <ControlledCheckbox
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            product={product}
          />
        </Grid>

        {/* Image */}
        <Grid container justifyContent="center" alignItems="center" p={0.5}>
          <Tooltip
            arrow
            placement="top"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  padding: 0,
                },
              },
            }}
            title={
              <img
                src={product.image_link_1 ? product.image_link_1 : no_image}
                alt=""
                style={{
                  width: "250px",
                }}
              />
            }
          >
            <Grid
              item
              justifyContent="center"
              alignContent="center"
              sx={{
                minHeight: "60px",
                border: (theme) => `1px solid ${theme.palette.border}`,
              }}
            >
              <img
                src={product.image_link_1 ? product.image_link_1 : no_image}
                alt=""
                width="60px"
              />
            </Grid>
          </Tooltip>
        </Grid>

        {/* Title & Product ID */}
        <Grid container flexDirection="column" pl={1}>
          {product.title && product.title.length > 90 ? (
            <Tooltip arrow placement="top" title={product.title}>
              <Typography variant="body2">
                {`${product.title.slice(0, 90)}...`}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="body2">{product.title}</Typography>
          )}

          <Grid item>
            <Typography component="a" href={`https://www.sogainternational.com/search?q=${product.sku}`} target="_blank" variant="body2" color="primary">
              Sku #: {product.sku}
            </Typography>
          </Grid>
        </Grid>

        {/* Barcode */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.gtin === null || product.gtin === ""
              ? "N/A"
              : product.gtin}
          </Typography>
        </Grid>

        {/* Weight */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.weight === undefined ||
              product.weight === null ||
              product.weight === ""
              ? "N/A"
              : `${product.weight}`}
          </Typography>
        </Grid>

        {/* Cost */}
        <Grid container justifyContent="center">
          <Typography variant="body2">{product.costpricenzd}</Typography>
        </Grid>

        {/* Shipping Cost */}
        <Grid container justifyContent="center">
          <Typography variant="body2">{product.shipping}</Typography>
        </Grid>

        {/* Variation */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {Array.isArray(product.variants) ? product.variants.length : 0}
          </Typography>
        </Grid>

        {/* Stock */}
        <Grid container justifyContent="center">
          <Typography variant="body2">{product.stock}</Typography>
        </Grid>

        {/* Supplier */}
        <Grid container justifyContent="center">
          <Typography variant="body2">Soga</Typography>
        </Grid>

        {/* Created At */}
        <Grid container justifyContent="center">
          <Typography variant="body2" textAlign="center">
            {formatDate(product.createdAt)}
          </Typography>
        </Grid>

        {/* <div style={{ textAlign: "center" }}>
          {product.import_status === null && "Not Imported"}
          {product.import_status === "imported" && "Imported"}
          {product.import_status === "exported" && "Exported"}
          {product.import_status === "deleted" && "Deleted"}
        </div> */}
        {
          // <div style={{textAlign:"center"}}>
          //   <Typography style={{color:"#5b5b5e"}}><Button variant="contained" size="small" onClick={(event) => handleImport(product)}>Import</Button></Typography>
          // </div>
        }
      </Grid>
    </Card>
  );
}
