import React, { useEffect, useState } from "react";
import { Grid, Chip, TextField, Autocomplete } from "@mui/material";

export default function Idropshipshippingselect({
//   selectedIDropshipCategoryLevel1,      
//   setSelectedIDropshipCategoryLevel1,
//   selectedIDropshipCategoryLevel2,
//   setSelectedIDropshipCategoryLevel2,
//   selectedIDropshipBrand, 
  selectedIDropshipShippingMethod,
  setSelectedIDropshipShippingMethod,
//   skulist,              
//   setSkuList,
//   filterby,
//   loading_products,
//   fetchproductcount,
//   setFetchProductCount,
}) {
//   const [categorieslevel1, setCategoriesLevel1] = useState([]);
//   const [categorieslevel2, setCategoriesLevel2] = useState([]);
//   const [iDropshipBrands, setIDropshipBrands] = useState([]);

  const shippingMethods = ["Free Shipping", "Ship By AMS"];

//   useEffect(() => {
//     fetch(
//       "https://api.saleprodev.com/productimporter/idropshipcategorieslevel1"
//     )
//       .then((response) => response.json())
//       .then((response) => {
//         console.log(response);
//         setCategoriesLevel1(response.categories_level_1.sort());
//       });

//     fetch("https://api.saleprodev.com/productimporter/idropshipbrands")
//       .then((response) => response.json())
//       .then((response) => {
//         console.log("brands", response);
//         setIDropshipBrands(response.brands.sort());
//       });
//   }, []);

//   useEffect(() => {
//     console.log(
//       "selectedIDropshipCategoryLevel1",
//       selectedIDropshipCategoryLevel1
//     );
//     setSelectedIDropshipCategoryLevel2("Select a Category");
//     fetch(
//       "https://api.saleprodev.com/productimporter/idropshipcategorieslevel2",
//       {
//         method: "post",
//         body: JSON.stringify({ category: selectedIDropshipCategoryLevel1 }),
//       }
//     )
//       .then((response) => response.json())
//       .then((response) => {
//         console.log("categories_level_2response", response);
//         setCategoriesLevel2(response.categories_level_2.sort());
//       });
//   }, [selectedIDropshipCategoryLevel1]);

//   const handleChange = (event, value) => {
//     console.log("value", value);
//     if (value === null) {
//       setSelectedIDropshipCategoryLevel1("Select a Category");
//     } else {
//       setSelectedIDropshipCategoryLevel1(value);
//     }
//   };

//   const handleChange2 = (event, value) => {
//     console.log("value", value);
//     if (value === null) {
//       setSelectedIDropshipCategoryLevel2("Select a Category");
//     } else {
//       setSelectedIDropshipCategoryLevel2(value);
//     }
//   };

//   const handleChangeBrand = (event, value) => {
//     console.log("Brand", value);
//     if (value === null) {
//       setSelectedIDropshipBrand("Select a Brand");
//     } else {
//       setSelectedIDropshipBrand(value);
//     }
//   };

  const handleChangeShippingMethod = (event, value) => {
    console.log("ShippingMethod", value);
    if (value === null) {
      setSelectedIDropshipShippingMethod();
    } else {
      setSelectedIDropshipShippingMethod(value);
    }
  };

//   const defaultProps1 = {
//     options: categorieslevel1,
//     getOptionLabel: (option) => option,
//   };

//   const defaultProps2 = {
//     options: categorieslevel2,
//     getOptionLabel: (option) => option,
//   };

//   const defaultProps3 = {
//     options: iDropshipBrands,
//     getOptionLabel: (option) => option,
//   };

  const defaultProps4 = {
    options: shippingMethods,
    getOptionLabel: (option) => option,
  };

  return (
    <>
    <Autocomplete
    size="small"
    sx={{
      mr: 0.5,
      width: "200px",
      height: 26,
      fontSize: "0.8rem",
      "& legend": {
        width: "unset",
      },
      "& .MuiFormControl-root, .MuiOutlinedInput-root": {
        height: 26,
        p: "0px!important",
      },
    }}
    {...defaultProps4}
    value={selectedIDropshipShippingMethod}
    onChange={(event, value) => handleChangeShippingMethod(event, value)}
    renderInput={(params) => (
      <TextField {...params} placeholder="Select Shipping" />
    )}
  />
  </>
  );
}


