import React, {useState} from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function ShipToSelect({value,handleChange}) {
const list = ["Australia", "New Zealand", "United States", "United Kingdom", "Canada", "Gokinzo"]

  return (
    <Box sx={{ minWidth: 140,height:30, }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label="Age"
          onChange={handleChange}
          style={{width:"140px", height:"30px"}}
        >
          {list.map(item => {
            return (
              <MenuItem value={item}>{item}</MenuItem>
            )
          })}
        </Select>
    </Box>
  );
}
