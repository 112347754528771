import React, {useState, useEffect} from "react"
import Card from '@mui/material/Card';
import BasicSelect from "../chunks/dropdown"
import CardMedia from '@mui/material/CardMedia';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import EditIcon from '@mui/icons-material/Edit';
import ProductInfo from "../productinfo/productinfo"
import {useSelector, useDispatch} from "react-redux"
import {setSearch, setArticles,setSubsettings, setToken} from "../../actions"


function SupplierProducts() {
  const theme = useTheme();
  const [category, setCategory] = useState("")

  const [products, setProducts] = useState("")
  const [selectedcategory, setSelectedcategory] = React.useState('');
  const token = useSelector(state => state.token)
  const dispatch = useDispatch()
  const styles =
{

media: {
  height: 0,
  paddingTop: '56.25%', // 16:9,
  marginTop:'30'
}
  };

  useEffect(() => {
    const data = async () => {
      // await fetch("https://api.banggood.com/getAccessToken?app_id=bg614e8e254eca7&app_secret=5e76df83d25c08505022011c3d0f75f9", {headers:{"access-control-allow-origin" : "*"}}).then(response => response.json()).then(response => console.log(response))
      await fetch("http://localhost:3001/bangood").then(response => response.json()).then(response => dispatch(setToken(response)))
    }

      data()

  }, [])

  useEffect(() => {
    const data = async () => {

        await fetch("http://localhost:3001/bangoodproductcategories", {
          method:"POST",
          body: JSON.stringify({token:token.access_token})
        }).then(response => response.json()).then(response => setCategory(response))

      // await fetch("https://api.banggood.com/getAccessToken?app_id=bg614e8e254eca7&app_secret=5e76df83d25c08505022011c3d0f75f9", {headers:{"access-control-allow-origin" : "*"}}).then(response => response.json()).then(response => console.log(response))
    }
    data()
  }, [token])

  useEffect(() => {
    const data = async () => {

        await fetch("http://localhost:3001/bangoodproducts", {
          method:"POST",
          body: JSON.stringify({token:token.access_token, cat_id:selectedcategory})
        }).then(response => response.json()).then(response => setProducts(response))

      // await fetch("https://api.banggood.com/getAccessToken?app_id=bg614e8e254eca7&app_secret=5e76df83d25c08505022011c3d0f75f9", {headers:{"access-control-allow-origin" : "*"}}).then(response => response.json()).then(response => console.log(response))
    }

    data()
  }, [selectedcategory])



  const handleChange = (event) => {
    console.log(event.target.value)
    setSelectedcategory(event.target.value);
  };

console.log("token", token)
console.log("category", category)
console.log("products", products)


  return (
    <div >
    <div style={{marginTop:"2%", marginBottom:"2%"}}>
    {token !== ""?(
      <BasicSelect category={category} handleChange={handleChange} selectedcategory={selectedcategory}/>
    ):""
    }
    </div>
    <Grid container spacing={2}>
    {products.product_list &&
      products.product_list.map(product => {
      return(
        <Grid item xs={12} lg={12} sm={12}>
        <Card>
          <div style={{display:"grid", gridTemplateColumns:"10% 10% 30% 10% 10% 10% 10% 10%"}}>
            <div style={{textAlign:"center", marginTop:"30%"}}>
            <input type="checkbox" id="horns" name="horns"/>
            </div>
            <div><img src={product.img} width="100" height="100"/></div>
            <div>
            <p>{product.product_name}</p>
            <p>{product.product_id}</p>
            </div>
            <div>USD 1.68</div>
            <div>
              <div>USD 0.74</div>
              <div>China Post</div>
              <div>United States</div>
            </div>
            <div>0</div>
            <div>Bangood</div>
            {// <div style={{textAlign:"center", marginTop:"10%"}}><EditIcon/></div>
            }
            <ProductInfo product_id={product.product_id} token={token} cat_id={product.cat_id} product={product}/>
          </div>

        </Card>

        </Grid>

      )

    })

    }
    </Grid>

    </div>
    )
}

export default SupplierProducts;
