import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { setSearch, setProductsPerPage, setSearchFilterListings, setImportPage, setImportTotalPages, setArticles, setSubsettings, setToken, setLoading, setCountrylist, setShippingMethods, setShowImportedProductsFilter, setCategoriesLevel1, setCategoriesLevel2, setCategoriesLevel3, setCategoriesLevel4, setCategoriesLevel5, setCategoriesLevel6, setImportedProducts, setImportedProductsFilter, setSelectedCategoryLevel2, setSelectedCategoryLevel3, setSelectedCategoryLevel4, setSelectedCategoryLevel5, setSelectedCategoryLevel6 } from "../../actions"
import { useSelector, useDispatch } from "react-redux"

import {
  Grid
} from "@mui/material";

export default function Filters({ selected_vendor, setSelectedVendor, selected_tag, setSelectedTag }) {
  const products_per_page = useSelector(state => state.products_per_page)
  const searchField = useSelector(state => state.searchField)
  const store = useSelector(state => state.store)
  const selected_store = useSelector(state => state.selected_store)
  const [vendors, setVendors] = useState([])
  const dispatch = useDispatch()

  const handleChange = (event) => {
    console.log("value", event.target.value)
    setSelectedVendor(event.target.value)
  };


  useEffect(() => {
    fetch("https://api.saleprodev.com/getnzshopifyvendors").then(response => response.json()).then(response => {
      console.log("vendors", response)
      setVendors(response)
    })
  }, [])


  return (
    <>
      {store === "nzshopify" &&
        <FormControl
          sx={{
            width: "200px",
            mr: 0.5,
            height: 30,
            fontSize: "0.8rem",
            "& legend": {
              width: "unset",
            },

            "& .MuiInputLabel-root": {
              transform: () =>
                selected_vendor.length === 0
                  ? "translate(14px, 6px) scale(0.8)"
                  : "",
            },
          }}
        >
          <InputLabel id="demo-simple-select-label">Vendor</InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selected_vendor}
            label="Vendorrrr"
            onChange={handleChange}
            sx={{
              height: 30,
              fontSize: "0.8rem",
            }}
          >
            {vendors.map(item => {
              return (
                <MenuItem value={item} >{item}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
      }

      <Grid item alignItems="center">
        <TextField
          label="Search"
          size="small"
          id="outlined-size-small"
          variant="outlined"
          value={searchField}
          onChange={(event) => dispatch(setSearch(event.target.value))}
          sx={{ mr: 0.5 }}
          InputProps={{
            sx: {
              height: 30,
              fontSize: "0.8rem",
            },
          }}
          InputLabelProps={{
            sx: {
              fontSize: "0.8rem",
              top: () => (searchField === null || searchField === "" ? "-2px" : "2px"),
              "&.Mui-focused": {
                transform: () =>
                  searchField === null || searchField === ""
                    ? "translate(14px, -4px) scale(0.8)"
                    : "translate(14px, -8px) scale(0.8)",
              },
            },
          }} />

        <TextField
          label="Tag"
          size="small"
          id="outlined-size-small"
          variant="outlined"
          value={selected_tag}
          onChange={(event) => setSelectedTag(event.target.value)}
          InputProps={{
            sx: {
              height: 30,
              fontSize: "0.8rem",
            },
          }}
          InputLabelProps={{
            sx: {
              fontSize: "0.8rem",
              top: () => (selected_tag.length === 0 ? "-2px" : "2px"),
              "&.Mui-focused": {
                transform: () =>
                  selected_tag.length === 0
                    ? "translate(14px, -4px) scale(0.8)"
                    : "translate(14px, -8px) scale(0.8)",
              },
            },
          }} />
      </Grid>
    </>
  );
}
