import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import KoganSettings from "./kogansettings.js";

import { SettingsSideMenu } from "./SettingsSideMenu";
import { SettingsBreadcrumbs } from "./SettingsBreadcrumbs";

import { OrderSettingsList } from "./OrderSettings/OrderSettingsList";

import { InventorySettingsList } from "./InventorySettings/InventorySettingsList";

import { DropshipSettingsList } from "./DropshipSettings/DropshipSettingsList";
import { GeneralSettingsDetail } from "./DropshipSettings/GeneralSettingsDetail";
import { PricingRulesDetail } from "./DropshipSettings/PricingRulesDetail";
import { ProductsMonitorDetail } from "./DropshipSettings/ProductsMonitorDetail";
import { AutoOrderSettingsDetail } from "./DropshipSettings/AutoOrderSettingsDetail";
import { ImportSettingsDetail } from "./DropshipSettings/ImportSettingsDetail";

import { ListingSettingsList } from "./ListingSettings/ListingSettingsList";

import { ManageChannelsList } from "./ManageChannels/ManageChannelsList";

import { ManageSuppliersList } from "./ManageSuppliers/ManageSuppliersList";
import { AliexpressSettingsDetail } from "./ManageSuppliers/AliexpressSettingsDetail/AliexpressSettingsDetail.js";

import SettingsIcon from "@mui/icons-material/Settings";
import PaidIcon from "@mui/icons-material/Paid";
import MonitorIcon from "@mui/icons-material/Monitor";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

import { Grid } from "@mui/material";

export const general_data = [
  {
    sub_settings: "order",
    sub_settings_title: "Order Settings",
    settings_items: [
      {
        item_icon: <SettingsIcon />,
        item_code: "order_settings_item1",
        item_title: "Order Settings Item 1",
        item_desc: "Order Settings, SKU and Decscription Preferences",
      },
      {
        item_icon: <PaidIcon />,
        item_code: "order_settings_item2",
        item_title: "Order Settings Item 2",
        item_desc: "Order Settings, SKU and Decscription Preferences",
      },
      {
        item_icon: <MonitorIcon />,
        item_code: "order_settings_item3",
        item_title: "Order Settings Item 3",
        item_desc: "Order Settings, SKU and Decscription Preferences",
      },
      {
        item_icon: <PublishedWithChangesIcon />,
        item_code: "order_settings_item4",
        item_title: "Order Settings Item 4",
        item_desc: "Order Settings, SKU and Decscription Preferences",
      },
      {
        item_icon: <SystemUpdateAltIcon />,
        item_code: "order_settings_item5",
        item_title: "Order Settings Item 5",
        item_desc: "Order Settings, SKU and Decscription Preferences",
      },
    ],
  },

  {
    sub_settings: "inventory",
    sub_settings_title: "Inventory Settings",
    settings_items: [
      {
        item_icon: <SettingsIcon />,
        item_code: "inventory_settings_item 1",
        item_title: "Inventory Settings Item 1",
        item_desc: "Inventory Settings, SKU and Decscription Preferences",
      },
      {
        item_icon: <PaidIcon />,
        item_code: "inventory_settings_item 2",
        item_title: "Inventory Settings Item 2",
        item_desc:
          "Inventory Settings  Lorem ipsum dolor sit, amet consectetur adipis",
      },
      {
        item_icon: <MonitorIcon />,
        item_code: "inventory_settings_item 3",
        item_title: "Inventory Settings Item 3",
        item_desc:
          "Inventory Settings Lorem ipsum dolor sit, amet consectetur adipis",
      },
      {
        item_icon: <PublishedWithChangesIcon />,
        item_code: "inventory_settings_item 4",
        item_title: "Inventory Settings Item 4",
        item_desc:
          "Inventory Settings Lorem ipsum dolor sit, amet consectetur adipis",
      },
      {
        item_icon: <SystemUpdateAltIcon />,
        item_code: "inventory_settings_item 5",
        item_title: "Inventory Settings Item 5",
        item_desc:
          "Inventory Settings Lorem ipsum dolor sit, amet consectetur adipis",
      },
    ],
  },

  {
    sub_settings: "dropship",
    sub_settings_title: "Dropship Settings",
    settings_items: [
      {
        item_icon: <SettingsIcon />,
        item_code: "general_settings",
        item_title: "General Settings",
        item_desc: "Set Default store, SKU and Decscription Preferences",
      },
      {
        item_icon: <PaidIcon />,
        item_code: "pricing_rules",
        item_title: "Pricing Rules",
        item_desc:
          "Pricing Rules Lorem ipsum dolor sit, amet consectetur adipis",
      },
      {
        item_icon: <MonitorIcon />,
        item_code: "products_monitor",
        item_title: "Products Monitor",
        item_desc:
          "Products Monitor Lorem ipsum dolor sit, amet consectetur adipis",
      },
      {
        item_icon: <PublishedWithChangesIcon />,
        item_code: "auto_order_settings",
        item_title: "Auto Order Settings",
        item_desc:
          "Auto Order Settings Lorem ipsum dolor sit, amet consectetur adipis",
      },
      {
        item_icon: <SystemUpdateAltIcon />,
        item_code: "import_settings",
        item_title: "Import Settings",
        item_desc:
          "Import Settings Lorem ipsum dolor sit, amet consectetur adipis",
      },
    ],
  },

  {
    sub_settings: "listing",
    sub_settings_title: "Listing Settings",
    settings_items: [
      {
        item_icon: <SettingsIcon />,
        item_code: "listing_settings_item 1",
        item_title: "Listing Settings Item 1",
        item_desc: "Listing Settings, SKU and Decscription Preferences",
      },
      {
        item_icon: <PaidIcon />,
        item_code: "listing_settings_item 2",
        item_title: "Listing Settings Item 2",
        item_desc:
          "Listing Settings  Lorem ipsum dolor sit, amet consectetur adipis",
      },
      {
        item_icon: <MonitorIcon />,
        item_code: "listing_settings_item 3",
        item_title: "Listing Settings Item 3",
        item_desc:
          "Listing Settings Lorem ipsum dolor sit, amet consectetur adipis",
      },
      {
        item_icon: <PublishedWithChangesIcon />,
        item_code: "listing_settings_item 4",
        item_title: "Listing Settings Item 4",
        item_desc:
          "Listing Settings Lorem ipsum dolor sit, amet consectetur adipis",
      },
      {
        item_icon: <SystemUpdateAltIcon />,
        item_code: "listing_settings_item 5",
        item_title: "Listing Settings Item 5",
        item_desc:
          "Listing Settings Lorem ipsum dolor sit, amet consectetur adipis",
      },
    ],
  },
];

export const integrations_data = [
  {
    sub_settings: "channels",
    sub_settings_title: "Manage Channels",
    settings_items: [
      {
        item_icon: <SettingsIcon />,
        item_code: "channels_kogan",
        item_title: "Connected",
        item_desc: "Add Your Store Now!",
      },
      {
        item_icon: <PaidIcon />,
        item_code: "channels_shopify",
        item_title: "Connected",
        item_desc: "Add Your Store Now!",
      },
    ],
  },

  {
    sub_settings: "suppliers",
    sub_settings_title: "Manage Suppliers",
    settings_items: [
      {
        item_icon: <SettingsIcon />,
        item_code: "suppliers_aliexpress",
        item_title: "AliExpress Settings",
        item_desc: "Explore More AliExpress Settings Here",
      },
      {
        item_icon: <SettingsIcon />,
        item_code: "suppliers_banggood",
        item_title: "Banggood Settings",
        item_desc:
          "Explore More Banggood Settings Here",
      },
      {
        item_icon: <SettingsIcon />,
        item_code: "suppliers_wefulfill",
        item_title: "Wefulfill Settings",
        item_desc:
          "Explore More Wefulfill Settings Here",
      },
      {
        item_icon: <SettingsIcon />,
        item_code: "suppliers_dropsite",
        item_title: "Dropsite Settings",
        item_desc:
          "Explore More Dropsite Settings Here",
      },
      {
        item_icon: <SettingsIcon />,
        item_code: "suppliers_soga",
        item_title: "Soga Settings",
        item_desc:
          "Explore More Soga Settings Here",
      },
      {
        item_icon: <SettingsIcon />,
        item_code: "suppliers_vidaxl",
        item_title: "VidaXL Settings",
        item_desc:
          "Explore More VidaXL Settings Here",
      },
      {
        item_icon: <SettingsIcon />,
        item_code: "suppliers_dropshipzone",
        item_title: "Dropshipzone Settings",
        item_desc:
          "Explore More Dropshipzone Settings Here",
      },
      {
        item_icon: <SettingsIcon />,
        item_code: "suppliers_matterhorn",
        item_title: "Matterhorn Settings",
        item_desc:
          "Explore More Matterhorn Settings Here",
      },

      {
        item_icon: <SettingsIcon />,
        item_code: "suppliers_fragranceX",
        item_title: "FragranceX Settings",
        item_desc:
          "Explore More FragranceX Settings Here",
      },
      {
        item_icon: <SettingsIcon />,
        item_code: "suppliers_wedropship",
        item_title: "WeDropship Settings",
        item_desc:
          "Explore More WeDropship Settings Here",
      },
      {
        item_icon: <SettingsIcon />,
        item_code: "suppliers_idropship",
        item_title: "iDropship Settings",
        item_desc:
          "Explore More iDropship Settings Here",
      },
      {
        item_icon: <SettingsIcon />,
        item_code: "suppliers_bigbuy",
        item_title: "BigBuy Settings",
        item_desc:
          "Explore More BigBuy Settings Here",
      },
      {
        item_icon: <SettingsIcon />,
        item_code: "suppliers_ingrammicro",
        item_title: "Ingram Micro Settings",
        item_desc:
          "Explore More Ingram Micro Settings Here",
      },
      {
        item_icon: <SettingsIcon />,
        item_code: "suppliers_ibacrafts",
        item_title: "IBA Crafts Settings",
        item_desc:
          "Explore More IBA Crafts Settings Here",
      },
      {
        item_icon: <SettingsIcon />,
        item_code: "suppliers_griffati",
        item_title: "Griffati Settings",
        item_desc:
          "Explore More Griffati Settings Here",
      },
    ],
  },

  {
    sub_settings: "carriers",
    sub_settings_title: "Manage Carriers",
    settings_items: [
      {
        item_icon: <SettingsIcon />,
        item_code: "carriers_settings_item 1",
        item_title: "Carriers Settings Item 1",
        item_desc: "Carriers Settings, SKU and Decscription Preferences",
      },
      {
        item_icon: <PaidIcon />,
        item_code: "carriers_settings_item 2",
        item_title: "Carriers Settings Item 2",
        item_desc:
          "Carriers Settings  Lorem ipsum dolor sit, amet consectetur adipis",
      },
      {
        item_icon: <MonitorIcon />,
        item_code: "carriers_settings_item 3",
        item_title: "Carriers Settings Item 3",
        item_desc:
          "Carriers Settings Lorem ipsum dolor sit, amet consectetur adipis",
      },
      {
        item_icon: <PublishedWithChangesIcon />,
        item_code: "carriers_settings_item 4",
        item_title: "Carriers Settings Item 4",
        item_desc:
          "Carriers Settings Lorem ipsum dolor sit, amet consectetur adipis",
      },
      {
        item_icon: <SystemUpdateAltIcon />,
        item_code: "carriers_settings_item 5",
        item_title: "Carriers Settings Item 5",
        item_desc:
          "Carriers Settings Lorem ipsum dolor sit, amet consectetur adipis",
      },
    ],
  },
];

const Settings = ({ page }) => {
  const dispatch = useDispatch();
  const subSettings = useSelector((state) => state.sub_settings);
  const settingsItem = useSelector((state) => state.settings_item);
  const [settingsMenu, setSettingsMenu] = useState("general");

  console.log("settingsItem", settingsItem);

  return (
    <div style={{ overflowX: "auto", overflowY: "hidden" }}>
      <Grid container id="content" xs={12} sx={{ minWidth: "1920px" }} py={1}>
        <SettingsSideMenu setSettingsMenu={setSettingsMenu} />

        <Grid item xs={11} px={1}>
          <SettingsBreadcrumbs
            page={page}
            settingsMenu={settingsMenu}
            settingsItem={settingsItem}
            general_data={general_data}
            integrations_data={integrations_data}
          />

          {/* General */}
          {subSettings === "order" && (
            <>
              {settingsItem === "" && (
                <OrderSettingsList
                  orderSettingsItems={general_data[0].settings_items}
                />
              )}
            </>
          )}

          {subSettings === "inventory" && (
            <>
              {settingsItem === "" && (
                <InventorySettingsList
                  inventorySettingsItems={general_data[1].settings_items}
                />
              )}
            </>
          )}

          {subSettings === "dropship" && (
            <>
              {settingsItem === "" && (
                <DropshipSettingsList
                  dropshipSettingsItems={general_data[2].settings_items}
                />
              )}

              {settingsItem === "general_settings" && <GeneralSettingsDetail />}

              {settingsItem === "pricing_rules" && <PricingRulesDetail />}

              {settingsItem === "products_monitor" && <ProductsMonitorDetail />}

              {settingsItem === "auto_order_settings" && (
                <AutoOrderSettingsDetail />
              )}

              {settingsItem === "import_settings" && <ImportSettingsDetail />}
            </>
          )}

          {subSettings === "listing" && (
            <>
              {settingsItem === "" && (
                <ListingSettingsList
                  listingSettingsItems={general_data[3].settings_items}
                />
              )}
            </>
          )}

          {/* Integrations */}
          {subSettings === "channels" && (
            <>
              {settingsItem === "" && (
                <ManageChannelsList
                  manageChannelsItems={integrations_data[0].settings_items}
                />
              )}
            </>
          )}

          {subSettings === "suppliers" && (
            <>
              {settingsItem === "" && (
                <ManageSuppliersList
                  manageSuppliersItems={integrations_data[1].settings_items}
                />
              )}

              {settingsItem === "suppliers_aliexpress" && <AliexpressSettingsDetail />}
              {/* {settingsItem === "suppliers_soga" && <AliexpressSettingsDetail />} */}
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Settings;
