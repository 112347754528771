import React ,{useState, useEffect} from 'react'
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";

import English from './lang/en.json'
import Chinese from './lang/cn.json'
import Hindi from './lang/in.json'

let lang = English;
const language = window.localStorage.getItem("language")
  if(language === 'en-US'){
    lang = English;
  }
  
  if(language === 'zh-CN'){
    lang = Chinese;
  }
  
  if(language === 'hi-IN'){
    lang = Hindi;
  }



const LanguageWrapper = ({children}) => {
    const locale = useSelector((state) => state.locale);
    const [messages, setMessages] = useState(lang)

    useEffect(()=>{
      if(locale === 'en-US'){
        setMessages(English)
    }
    if(locale === 'zh-CN'){
        setMessages(Chinese)
    }
    if(locale === 'hi-IN'){
        setMessages(Hindi)
    }
    },[locale])

    return (
        <IntlProvider messages={messages} locale={locale}>
           {children}
        </IntlProvider>
  )
}

export default LanguageWrapper