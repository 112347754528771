import React from "react";

const MainCheckBox = ({ allItems, setSelectedItems }) => {
  const handleChange = (e) => {
    if (e.target.checked === true) {
      let array = [];
      allItems.map((item) => {
        let element = document.getElementById(`checkbox_${item.product_sku}`);
        if (element.checked) {
          console.log("checked");
        } else {
          element.checked = true;
        }
        array.push(item);
      });
      setSelectedItems([...array]);
    } else {
      allItems.map((item) => {
        let element = document.getElementById(`checkbox_${item.product_sku}`);
        if (element.checked) {
          element.checked = false;
        }
      });
      setSelectedItems([]);
    }
  };

  return <input id="maincheckbox" type="checkbox" onChange={handleChange} />;
};

export default MainCheckBox;
