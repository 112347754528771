import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Loader } from "../common/Loader";

import {
  Grid,
  MenuItem,
  Select,
  Tooltip,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";

export default function BigbuyCarriers({ carrier, setCarrier, order }) {
  const [availablecarriers, setAvailableCarriers] = useState({
    carriers: [],
    orderid: "",
  });
  const [loading, setLoading] = useState(false);
  const store = useSelector((state) => state.store);

  useEffect(() => {
    setLoading(true);
    fetch("https://api.saleprodev.com/getbigbuycarriers", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sku: JSON.parse(order.line_items)[0].sku,
        store: store,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("getbigbuycarriers", response);
        setAvailableCarriers({
          orderid: order.orderid,
          carriers: response.shippingOptions.sort(
            (carrierA, carrierB) => carrierA.cost - carrierB.cost
          ),
        });

        if (response.error) {
          setAvailableCarriers({
            orderid: order.orderid,
            carriers: [
              {
                name: response.error,
                value: response.error,
                amount: "Carrier Not Available",
                currency: "",
                duration: "",
              },
            ],
          });
        }
        if (response.msg) {
          setAvailableCarriers({
            orderid: order.orderid,
            carriers: [
              {
                name: response.msg,
                value: response.msg,
                amount: "Carrier Not Available",
                currency: "",
                duration: "",
              },
            ],
          });
        }
        setLoading(false);
      });
  }, []);

  const handleChange = (event) => {
    setCarrier(event.target.value);
  };

  console.log("carrier", carrier);
  return (
    <Grid container flexDirection="column" py={1}>
      <FormControl sx={{ my: 1, minWidth: 150 }}>
        <InputLabel id="demo-select-small">Choose Carrier</InputLabel>
        <Select
          labelId="demo-select-small"
          id="demo-select-small"
          value={carrier}
          label="Choose Carrierrrrr"
          onChange={handleChange}
          size="small"
          // onClick={handleShowCarriers}
          sx={{
            height: 30,
            fontSize: "0.8rem",
          }}
        >
          {availablecarriers.carriers.length !== 0 &&
            (loading ? (
              <Grid
                container
                py={1}
                justifyContent="center"
                alignItems="center"
              >
                <Loader size={30} />
              </Grid>
            ) : (
              availablecarriers.carriers.map((carrierObj, index) => (
                <MenuItem key={index} value={carrierObj}>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={9}>
                      {carrierObj.shippingService.name.length > 20 ? (
                        <Grid container>
                          <Tooltip
                            arrow
                            placement="top"
                            title={carrierObj.shippingService.name}
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "0.8rem" }}
                            >
                              {carrierObj.shippingService.name.slice(0, 20) +
                                " ..."}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      ) : (
                        <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                          {carrierObj.shippingService.name}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={3} textAlign="right">
                      <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                        {carrierObj.cost === 0
                          ? "Free"
                          : carrierObj.cost + " EUR"}
                      </Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              ))
            ))}
        </Select>
      </FormControl>
    </Grid>
  );
}
