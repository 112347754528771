import React from "react";
import BasicSelect from "../../../select/select";
import { useSelector, useDispatch } from "react-redux";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { setSettingsItem } from "../../../../actions";

import {
  Card,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { AliexpressToken } from "./AliexpressToken";

export const AliexpressSettingsDetail = () => {
  const dispatch = useDispatch();
  const country_list_shipfrom = useSelector(
    (state) => state.country_list_shipfrom
  );

  return (
    <Card elevation={0} sx={{ my: 1, minHeight: "90vh" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          px: 4,
          py: 2,
          borderBottom: (theme) => `1px solid ${theme.palette.border}`,
        }}
      >
        <Button
          size="small"
          variant="text"
          startIcon={<ChevronLeftIcon />}
          onClick={() => dispatch(setSettingsItem(""))}
          sx={{
            "& .MuiButton-startIcon": {
              mr: 0.5,
            },
          }}
        >
          Back
        </Button>
        <Typography variant="h6">AliExpress Settings</Typography>
        <Button size="small" variant="contained" disabled>
          Save
        </Button>
      </Grid>

      <Grid
        container
        p={4}
        xs={8}
        flexDirection="column"
        alignContent="flex-start"
      >
        <AliexpressToken />
      </Grid>
    </Card>
  );
};
