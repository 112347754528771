import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  setSearch,
  setProductsPerPage,
  setImportPage,
  setImportTotalPages,
  setArticles,
  setSubsettings,
  setToken,
  setLoading,
  setCountrylist,
  setShippingMethods,
  setShowImportedProductsFilter,
  setCategoriesLevel1,
  setCategoriesLevel2,
  setCategoriesLevel3,
  setCategoriesLevel4,
  setCategoriesLevel5,
  setCategoriesLevel6,
  setImportedProducts,
  setImportedProductsFilter,
  setSelectedCategoryLevel2,
  setSelectedCategoryLevel3,
  setSelectedCategoryLevel4,
  setSelectedCategoryLevel5,
  setSelectedCategoryLevel6,
} from "../../actions";
import { useSelector, useDispatch } from "react-redux";
export default function BasicSelect({
  page,
  handleImport,
  setSelectedOrders,
  selectedOrders,
}) {
  const products_per_page = useSelector((state) => state.products_per_page);
  const show_orders = useSelector((state) => state.show_orders);
  const dispatch = useDispatch();
  const handleChange = (event) => {
    console.log("value", event.target.value);
    dispatch(setProductsPerPage(event.target.value));
    if (show_orders === "to_purchase") {
      let element = document.getElementById("maincheckbox");
      if (selectedOrders.length !== 0) {
        setSelectedOrders([]);
      }
      if (element.checked) {
        element.click();
      } else {
        selectedOrders.map((order) => {
          let element2 = document.getElementById(order.id);
          if (element2.checked) {
            element2.click();
          }
        });
      }
    }
  };

  return (
    <Select
      value={products_per_page}
      onChange={handleChange}
      sx={{
        height: 26,
        "& legend": {
          width: "unset",
        },
      }}
    >
      <MenuItem value={20} sx={{ height: 26, fontSize: "0.8rem" }}>
        20
      </MenuItem>
      <MenuItem value={40} sx={{ height: 26, fontSize: "0.8rem" }}>
        40
      </MenuItem>
      <MenuItem value={60} sx={{ height: 26, fontSize: "0.8rem" }}>
        60
      </MenuItem>
      <MenuItem value={80} sx={{ height: 26, fontSize: "0.8rem" }}>
        80
      </MenuItem>
      <MenuItem value={100} sx={{ height: 26, fontSize: "0.8rem" }}>
        100
      </MenuItem>
      <MenuItem value={150} sx={{ height: 26, fontSize: "0.8rem" }}>
        150
      </MenuItem>
      <MenuItem value={200} sx={{ height: 26, fontSize: "0.8rem" }}>
        200
      </MenuItem>
      <MenuItem value={250} sx={{ height: 26, fontSize: "0.8rem" }}>
        250
      </MenuItem>
      <MenuItem value={500} sx={{ height: 26, fontSize: "0.8rem" }}>
        500
      </MenuItem>
      {page !== "tools" && (
        <MenuItem value={1000} sx={{ height: 26, fontSize: "0.8rem" }}>
          1000
        </MenuItem>
      )}
    </Select>
  );
}
