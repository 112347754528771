import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { setSettingsItem } from "../../../actions";
import { useTheme } from "@mui/material/styles";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import {
  Grid,
  Chip,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  TextField,
  Switch,
  Paper,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TextareaAutosize,
  TableHead,
  TableRow,
  useMediaQuery
} from "@mui/material";
import BasicSelect from "../../select/select";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";

export const ProductsMonitorDetail = () => {
  const dispatch = useDispatch();
  const store_menu = useSelector((state) => state.store_menu);
  const supplier_menu = useSelector((state) => state.supplier_menu);
  const selected_store = useSelector((state) => state.selected_store);
  const selected_supplier = useSelector((state) => state.selected_supplier);
  const [sku, setSKU] = useState("");
  const [skudetails, setSkuDetails] = useState([]);
  const [stockmonitor, setStockMonitor] = useState(false);
  const [pricemonitor, setPriceMonitor] = useState(false);
  const [checkBulkBlockStockMonitor, setCheckBulkBlockStockMonitor] =
    useState(false);
  const [checkBulkBlockPriceMonitor, setCheckBulkBlockPriceMonitor] =
    useState(false);

  console.log(selected_store);
  console.log(selected_supplier);

  const fetchsku = () => {
    fetch("https://api.saleprodev.com/skusearch", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sku: sku.split("\n"),
        store: selected_store,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.length === 0) {
          alert("SKU Not found");
        } else {
          setSkuDetails(response);
        }
      });
  };

  const handleSearchSku = (event) => {
    setSkuDetails([]);
    fetchsku();
  };

  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteSkus = () => {
    handleCloseDialog();

    fetch("https://api.saleprodev.com/deleteskus", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sku: sku.split("\n"),
        store: selected_store,
        supplier: selected_supplier,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.status === "success") {
          alert("Skus Deleted Successfully");
          setSkuDetails([]);
        } else {
          alert("Something went wrong...");
        }
      });
  }

  const handleClearStock = (id) => {
    fetch("https://api.saleprodev.com/clearstock", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sku: id,
        store: selected_store,
        supplier: selected_supplier,
      }),
    })
      .then((res) => res.json())
      .then(() => fetchsku());
  };

  const handleBulkClearStock = () => {
    fetch("https://api.saleprodev.com/bulkclearstock", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sku: sku.split("\n"),
        store: selected_store,
        supplier: selected_supplier,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("res", res);
        fetchsku();
      });
  };

  const handleStockMonitor = (event, id) => {
    console.log(event.target.checked);
    if (event.target.checked === false) {
      fetch("https://api.saleprodev.com/blockstockmonitor", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sku: id,
          store: selected_store,
          supplier: selected_supplier,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          fetchsku();
        });
    } else {
      fetch("https://api.saleprodev.com/activatestockmonitor", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sku: id,
          store: selected_store,
          supplier: selected_supplier,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          fetchsku();
        });
    }
  };

  const handleBulkBlockStockMonitor = () => {
    if (sku.length !== 0) {
      if (checkBulkBlockStockMonitor === false) {
        fetch("https://api.saleprodev.com/bulkblockstockmonitor", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            store: selected_store,
            supplier: selected_supplier,
            sku: sku.split("\n"),
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            fetchsku();
          });
      } else {
        fetch("https://api.saleprodev.com/bulkactivatestockmonitor", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            store: selected_store,
            supplier: selected_supplier,
            sku: sku.split("\n"),
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            fetchsku();
          });
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    handleBulkBlockStockMonitor();
  }, [checkBulkBlockStockMonitor]);

  const handleChangeBulkBlockStockMonitor = () => {
    setCheckBulkBlockStockMonitor(!checkBulkBlockStockMonitor);
  };

  const handlePriceMonitor = (event, id) => {
    if (event.target.checked === false) {
      fetch("https://api.saleprodev.com/blockpricemonitor", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sku: id,
          store: selected_store,
          supplier: selected_supplier,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          fetchsku();
        });
    } else {
      fetch("https://api.saleprodev.com/activatepricemonitor", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sku: id,
          store: selected_store,
          supplier: selected_supplier,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          fetchsku();
        });
    }
  };

  const handleBulkBlockPriceMonitor = () => {
    if (sku.length !== 0) {
      if (checkBulkBlockPriceMonitor === false) {
        console.log("sku.length", sku.length)
        fetch("https://api.saleprodev.com/bulkblockpricemonitor", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            sku: sku.split("\n"),
            store: selected_store,
            supplier: selected_supplier,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            fetchsku();
          });
      } else {
        fetch("https://api.saleprodev.com/bulkactivatepricemonitor", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            sku: sku.split("\n"),
            store: selected_store,
            supplier: selected_supplier,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            fetchsku();
          });
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    handleBulkBlockPriceMonitor();
  }, [checkBulkBlockPriceMonitor]);

  const handleChangeBulkBlockPriceMonitor = () => {
    setCheckBulkBlockPriceMonitor(!checkBulkBlockPriceMonitor);
  };
  console.log("sku", sku.split("\n"));
  console.log("skudetails", skudetails);
  return (
    <Card elevation={0} sx={{ my: 1, minHeight: "90vh" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          px: 4,
          py: 2,
          borderBottom: (theme) => `1px solid ${theme.palette.border}`,
        }}
      >
        <Button
          size="small"
          variant="text"
          startIcon={<ChevronLeftIcon />}
          onClick={() => dispatch(setSettingsItem(""))}
          sx={{
            "& .MuiButton-startIcon": {
              mr: 0.5,
            },
          }}
        >
          Back
        </Button>
        <Typography variant="h6">Products Monitor</Typography>
        <Button size="small" variant="contained" disabled>
          Save
        </Button>
      </Grid>

      <Grid
        container
        p={4}
        xs={8}
        flexDirection="column"
        alignContent="flex-start"
      >
        <Grid
          container
          sx={{
            py: 2,
            borderBottom: (theme) => `1px dashed ${theme.palette.border}`,
          }}
        >
          <Grid item xs={4}>
            <Typography variant="body1">Store</Typography>
          </Grid>

          <Grid item xs={8}>
            <BasicSelect
              label="Select store"
              menu={store_menu}
              type="choosestore"
            />
          </Grid>
        </Grid>


        <Grid
          container
          sx={{
            py: 2,
            borderBottom: (theme) => `1px dashed ${theme.palette.border}`,
          }}
        >
          <Grid container item xs={4} alignContent="center">
            <Typography variant="body1">Supplier</Typography>
          </Grid>

          <Grid item xs={8}>
            <BasicSelect
              label="Select Supplier"
              menu={supplier_menu}
              type="choosesupplier"
            />
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            py: 2,
          }}
        >
          <Grid item xs={4}>
            <Typography variant="body1">Shopify SKU</Typography>
          </Grid>

          <Grid item xs={8}>
            <Grid item>
              {sku === "" ? (
                ""
              ) : (
                <Chip
                  size="small"
                  color="primary"
                  variant="outlined"
                  label={`${sku.split(/\r\n|\r|\n/).length} SKUs`}
                  sx={{
                    height: 20,
                    position: "absolute",
                    transform:
                      sku.split(/\r\n|\r|\n/).length <= 1
                        ? "translate(630px, 3px)"
                        : "translate(630px, 3px)",
                  }}
                />
              )}

              <TextField
                multiline
                maxRows={5}
                value={sku}
                placeholder="Search Shopify SKU"
                onChange={(event) => setSKU(event.target.value)}
                sx={{
                  mr: 0.5,
                  width: "100%",
                  fontSize: "0.8rem",
                  "& legend": {
                    width: "unset",
                  },
                  "& .MuiFormControl-root, .MuiOutlinedInput-root": {
                    p: "0px!important",
                    minHeight: 26,
                  },
                }}
              />
            </Grid>

            <Grid container justifyContent="center" alignItems="center" my={1}>
              {sku && sku !== "" && sku.split(/\r\n|\r|\n/).length <= 1000 ?
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleSearchSku}
                  sx={{
                    mr: 1
                  }
                  }
                >
                  Search
                </Button>
                :
                <Button
                  disabled
                  size="small"
                  variant="contained"
                  sx={{
                    mr: 1
                  }
                  }
                >
                  Search
                </Button>

              }

              {skudetails && skudetails.length === 0 ?
                <Button
                  disabled
                  size="small"
                  variant="contained"
                >
                  Delete
                </Button>
                :
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleOpenDialog}
                >
                  Delete
                </Button>
              }

              {sku && sku !== "" && sku.split(/\r\n|\r|\n/).length > 1000 && <Typography variant="body2" color="error" ml={1}>(Please search less than 1000 Skus once.)</Typography>}

              <Dialog
                fullScreen={fullScreen}
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                  Are you sure you want to delete these Skus?
                </DialogTitle>

                <DialogActions>
                  <Button autoFocus onClick={handleCloseDialog}>
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handleDeleteSkus}
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>

            </Grid>

            {skudetails.length !== 0 && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6">SKU NO.</Typography>
                        <Chip
                          size="small"
                          color="primary"
                          variant="outlined"
                          label={`${skudetails.length} SKUs`}
                          sx={{
                            height: 20,
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Grid
                          container
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Typography variant="h6">Stock</Typography>

                          <Button
                            variant="contained"
                            sx={{ fontSize: "12px", px: "6px", py: "3px" }}
                            onClick={handleBulkClearStock}
                          >
                            Clear All
                          </Button>
                        </Grid>
                      </TableCell>

                      <TableCell align="center">
                        <Grid
                          container
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Typography variant="h6">Stock Monitor</Typography>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography variant="body1"></Typography>
                            <Switch
                              name="block_all_switch_stock"
                              checked={checkBulkBlockStockMonitor}
                              onChange={handleChangeBulkBlockStockMonitor}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>

                      <TableCell align="center">
                        <Grid
                          container
                          flexDirection="column"
                          alignContent="center"
                        >
                          <Typography variant="h6">Price Monitor</Typography>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography variant="body1"></Typography>
                            <Switch
                              name="block_all_switch_price"
                              checked={checkBulkBlockPriceMonitor}
                              onChange={handleChangeBulkBlockPriceMonitor}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {skudetails.map((item) => (
                      <TableRow
                        key={item.sku}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {item.sku}
                        </TableCell>
                        <TableCell align="center">
                          <Grid
                            container
                            flexDirection={{ xs: "column", md: "row" }}
                            justifyContent="center"
                            alignItems="center"
                          >
                            {item.shopifystock}
                            <Button
                              variant="outlined"
                              disabled={item.shopifystock == 0}
                              sx={{
                                fontSize: "10px",
                                p: 0,
                                mx: "2px",
                              }}
                              onClick={() => handleClearStock(item.sku)}
                            >
                              Clear
                            </Button>
                          </Grid>
                        </TableCell>

                        {[
                          "bangood",
                          "aliexpress",
                          "wefullfill",
                          "dropsite",
                          "soga",
                          "vidaxl",
                          "dropship",
                          "matterhorn",
                          "fragrancex",
                          "wedropship",
                          "idropship",
                          "bigbuy",
                          "sunsky"
                        ].includes(item.supplier) ? (
                          <>
                            <TableCell align="center">
                              <Switch
                                name="gilad"
                                checked={item.stock_monitor}
                                onChange={(event) =>
                                  handleStockMonitor(event, item.sku)
                                }
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </TableCell>

                            <TableCell align="center">
                              <Switch
                                checked={item.price_monitor}
                                onChange={(event) =>
                                  handlePriceMonitor(event, item.sku)
                                }
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell align="center">
                              <Switch
                                disabled
                                checked={item.stock_monitor}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Switch
                                disabled
                                checked={item.price_monitor}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card >
  );
};
