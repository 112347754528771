import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {setSearch, setProductsPerPage, setImportPage, setImportTotalPages,setArticles,setSubsettings, setToken, setLoading,setCountrylist,setShippingMethods, setShowImportedProductsFilter,setCategoriesLevel1, setCategoriesLevel2,setCategoriesLevel3, setCategoriesLevel4,setCategoriesLevel5, setCategoriesLevel6,setImportedProducts,setImportedProductsFilter,setSelectedCategoryLevel2,setSelectedCategoryLevel3, setSelectedCategoryLevel4, setSelectedCategoryLevel5, setSelectedCategoryLevel6} from "../../actions"
import {useSelector, useDispatch} from "react-redux"
export default function KoganCategorySelect({koganCategoryLevel1, koganCategoryLevel2, setKoganCategoryLevel1, setKoganCategoryLevel2}) {

  const dispatch = useDispatch()
  const [categorieslevel1, setCategoriesLevel1] = useState([])
  const [categorieslevel2, setCategoriesLevel2] = useState([])
  const [categorieslevel3, setCategoriesLevel3] = useState([])
  const [categorieslevel4, setCategoriesLevel4] = useState([])
  const [categorieslevel5, setCategoriesLevel5] = useState([])
  const [brands, setBrands] = useState([])
  useEffect(() => {

    fetch("https://api.saleprodev.com/productimporter/kogancategorieslevel1").then(response => response.json()).then(response => {
      console.log(response)
      setCategoriesLevel1(response.categories_level_1)
    })


  },[])

  useEffect(() => {
    setKoganCategoryLevel2("Select a Category")
    fetch("https://api.saleprodev.com/productimporter/kogancategorieslevel2", {
      method:"post",
      body:JSON.stringify({category:koganCategoryLevel1})
    }).then(response => response.json()).then(response => {
      console.log(response)
      setCategoriesLevel2(response.categories_level_2)
    })
  },[koganCategoryLevel1])




  const handleChange = (event) => {
    console.log("value",event.target.value)
    setKoganCategoryLevel1(event.target.value)

  };

  const handleChange2 = (event) => {
    console.log("value",event.target.value)
    setKoganCategoryLevel2(event.target.value)

  };






  return (
    <Box sx={{ width: 1200}}>
      <FormControl style={{marginTop:"1%", marginLeft:"1%"}}>
        <InputLabel id="demo-simple-select-label"></InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={koganCategoryLevel1}
          label="No"
          onChange={handleChange}
          style={{height:"30px", width:"300px"}}
        >
        <MenuItem value="Select a Category">Select a Category</MenuItem>
        {categorieslevel1.map(item => {
          return (
            <MenuItem value={item}>{item}</MenuItem>
          )
        })}

        </Select>
      </FormControl>
      <FormControl style={{marginTop:"1%", marginLeft:"1%"}}>
        <InputLabel id="demo-simple-select-label"></InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={koganCategoryLevel2}
          label="No"
          onChange={handleChange2}
          style={{height:"30px", width:"300px"}}
        >
        <MenuItem value="Select a Category">Select a Category</MenuItem>
        {categorieslevel2.map(item => {
          return (
            <MenuItem value={item}>{item}</MenuItem>
          )
        })}

        </Select>
      </FormControl>

    </Box>
  );
}
