import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import KoganCategorySelect from "./kogancategoryselect.js";
import BangoodSelectNZ from "./bangoodselectnz.js";
import CloseIcon from "@mui/icons-material/Close";
import { LinearProgressWithLabel } from './LinearProgressWithLabel.js'
import MultipleSelectCheckmarks from './MultiStoreSelect.js'

import {
  Button,
  Card,
  Typography,
  CircularProgress,
  Grid,
  Modal,
  Box,
  TextField,
  LinearProgress,
} from "@mui/material";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function ProgressBar({ items }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const finishedItems = items.filter(item => item.finished);
    const progressPercentage = (finishedItems.length / items.length) * 100;
    setProgress(progressPercentage);
  }, [items]);

  return (
    <LinearProgress variant="determinate" value={progress} />
  );
}

export default function BulkExportModal({
  open,
  handleClose,
  selectedProducts,
  handleBulkExport,
  exportingProducts,
  warehouse_bangood_nz,
  setWarehouseBangoodNZ,
  shippingmethod_bangood_nz,
  setShippingMethodBangoodNZ,
  tags,
  setTags,
  exportstatus,
  product_type,
  setProductType,
  permissiontoexport,
  handleExportAliExpress,
  exportingStatus,
  setExportingStatus,
}) {
  const [storestoexport, setStoresToExport] = React.useState([]);
  const [koganCategoryLevel1, setKoganCategoryLevel1] =
    React.useState("Select a Category");
  const [koganCategoryLevel2, setKoganCategoryLevel2] =
    React.useState("Select a Category");
  const [category, setCategory] = React.useState("");
  const store = useSelector((state) => state.store);
  const selected_supplier = useSelector((state) => state.selected_supplier);
  const [count, setCount] = React.useState(0);
  console.log("storestoexport", storestoexport);

  useEffect(() => {
    setStoresToExport([]);
  }, []);

  useEffect(() => { }, [exportstatus]);

  useEffect(() => {
    setCount(count + 1);
  }, [storestoexport]);

  useEffect(() => {
    if (
      koganCategoryLevel1 !== "Select a Category" &&
      koganCategoryLevel2 !== "Select a Category"
    ) {
      fetch("https://api.saleprodev.com/productimporter/kogancategory", {
        method: "post",
        body: JSON.stringify({
          category1: koganCategoryLevel1,
          category2: koganCategoryLevel2,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setCategory(response);
        });
    }
  }, [koganCategoryLevel1, koganCategoryLevel2]);

  const CalculatePrice = (product) => {
    let prices = [];
    if (product.variants) {
      product.variants.map((item) => {
        prices.push(parseFloat(item.price));
      });
      return `${Math.min(...prices)} - ${Math.max(...prices)}`;
    }
  };

  const CalculateStock = (product) => {
    let prices = [];
    if (product.variants) {
      product.variants.map((item) => {
        prices.push(parseFloat(item.inventory_quantity));
      });
      return `${Math.min(...prices)} - ${Math.max(...prices)}`;
    }
  };

  const ProductLayout = ({ product }) => {
    return (
      <Card
        style={{ zIndex: "1000", marginTop: "1%", backgroundColor: "white" }}
      >
        <Grid item xs={12} sm={12} lg={12}>
          <div
            style={{
              display: "Grid",
              gridTemplateColumns: "3% 10% 40% 10% 10% 10% 10% 10%",
              gridGap: "5px",
              marginTop: "10px",
              marginBottom: "10px",
              fontSize: "14px",
            }}
          >
            <div style={{ textAlign: "left" }}></div>
            <div style={{ textAlign: "center" }}>
              <Box sx={{ marginRight: "10px", border: "1px solid #e3e3e3" }}>
                <img
                  src={
                    product.images && product.images[0]
                      ? product.images[0].src
                      : ""
                  }
                  alt=""
                  height="100"
                  width="80"
                />
              </Box>
            </div>
            <div>
              <div>
                <div>{product.title}</div>
                <div>{product.sku}</div>
              </div>
              <div style={{ color: "#999797", marginTop: "10px" }}>
                <a
                  href={product.url}
                  target="_blank"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {product.id}
                </a>
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <div>Price Range</div>
              <div>{CalculatePrice(product)}</div>
            </div>
            <div style={{ textAlign: "center" }}>
              <div>Variants</div>
              <div>
                {Array.isArray(product.variants) ? product.variants.length : 0}
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <div>Supplier</div>
              <a
                href="https://matterhorn-wholesale.com/"
                target="_blank"
                style={{ textDecoration: "none", color: "black" }}
              >
                {product.supplier}
              </a>
            </div>
            <div style={{ textAlign: "center" }}>
              <div>Stock Range</div>
              <div>{CalculateStock(product)}</div>
            </div>
            <div style={{ textAlign: "center" }}></div>
          </div>
        </Grid>
      </Card>
    );
  };

  console.log("exportstatus", exportstatus);

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90vw", md: "35vw" },
          height: { xs: "50vh", md: "40vh" },
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 4,
          p: 3,
        }}
      >
        {exportingStatus === "beforeExport" && (
          <Grid container flexDirection="column" justifyContent="space-between" sx={{ height: "100%" }}>
            <Grid item container flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              {/* Modal Header */}
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                pb={2}
              >
                <Typography variant="h5">
                  Export To Shopify
                </Typography>
                <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
              </Grid>

              {/* Modal Content */}
              <Grid item container flexDirection="column">
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  py={1}
                >
                  <Typography variant="body2">Please Select the Store to create draft listings</Typography>
                </Grid>

                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  py={1}
                >
                  <Grid item container alignItems="center">
                    <Grid item xs={4}>
                      <Typography variant="body1">Store</Typography>
                    </Grid>

                    <Grid item xs={8}>
                      <MultipleSelectCheckmarks storestoexport={storestoexport} setStoresToExport={setStoresToExport} />
                    </Grid>

                  </Grid>

                  <Grid item container alignItems="center">
                    <Grid item xs={4}></Grid>

                    <Grid item container xs={8} >
                      {exportstatus.total === 0 &&
                        selected_supplier === "aliexpress" && (
                          <>
                            <Grid item xs={6} pr={0.5}>
                              <TextField
                                name="tags"
                                size="small"
                                placeholder="Tag"
                                value={tags}
                                sx={{
                                  height: 26,
                                  fontSize: "0.8rem",
                                  "& legend": {
                                    width: "unset",
                                  },
                                  "& .MuiFormControl-root, .MuiOutlinedInput-root": {
                                    height: 26,
                                    p: "0px!important",
                                  },
                                }}
                                onChange={(event) => setTags(event.target.value)}
                              />
                            </Grid>
                            <Grid item xs={6} pl={0.5}>
                              <TextField
                                size="small"
                                placeholder="Type"
                                value={product_type}
                                sx={{
                                  height: 26,
                                  fontSize: "0.8rem",
                                  "& legend": {
                                    width: "unset",
                                  },
                                  "& .MuiFormControl-root, .MuiOutlinedInput-root": {
                                    height: 26,
                                    p: "0px!important",
                                  },
                                }}
                                onChange={(event) => setProductType(event.target.value)}
                              />
                            </Grid>

                          </>
                        )}
                    </Grid>
                  </Grid>

                </Grid>

                {
                  //   storestoexport.includes("kogan")?(
                  //   <Button variant="contained" onClick={(event) => {
                  //     let copy = storestoexport
                  //     let index = copy.indexOf('kogan')
                  //     console.log(index, copy)
                  //     if(index !== -1){
                  //       copy.splice(index, 1)
                  //       console.log(copy)
                  //       setStoresToExport(copy)
                  //       setCount(count + 1)
                  //     }
                  //   }}>KOGAN</Button>
                  // ):(
                  //   <Button variant="outlined" onClick={(event) => setStoresToExport([...storestoexport, "kogan"])}>KOGAN</Button>
                  // )
                }

                {storestoexport.includes("kogan") ? (
                  <KoganCategorySelect
                    koganCategoryLevel1={koganCategoryLevel1}
                    koganCategoryLevel2={koganCategoryLevel2}
                    setKoganCategoryLevel1={setKoganCategoryLevel1}
                    setKoganCategoryLevel2={setKoganCategoryLevel2}
                  />
                ) : (
                  ""
                )}

                {selected_supplier === "bangood" ? (
                  <BangoodSelectNZ
                    koganCategoryLevel1={koganCategoryLevel1}
                    koganCategoryLevel2={koganCategoryLevel2}
                    setKoganCategoryLevel1={setKoganCategoryLevel1}
                    setKoganCategoryLevel2={setKoganCategoryLevel2}
                    warehouse_bangood_nz={warehouse_bangood_nz}
                    setWarehouseBangoodNZ={setWarehouseBangoodNZ}
                    shippingmethod_bangood_nz={shippingmethod_bangood_nz}
                    setShippingMethodBangoodNZ={setShippingMethodBangoodNZ}
                    tags={tags}
                    setTags={setTags}
                    product_type={product_type}
                    setProductType={setProductType}
                  />
                ) : (
                  ""
                )}

                {permissiontoexport &&
                  permissiontoexport.selectedProducts &&
                  exportstatus.total === 0 ? (
                  <Grid container>
                    <Grid item container>
                      <Grid item>
                        <Typography variant="body1">
                          Existing Products(
                          {permissiontoexport.existing_products.length})
                        </Typography>
                      </Grid>
                      <Grid item ml={0.5}>
                        <Typography variant="caption" color="error">
                          We will skip these products
                        </Typography>
                      </Grid>
                    </Grid>

                    {/* {permissiontoexport.existing_products.map((product) => {
                  return <ProductLayout product={product} />;
                })} */}

                    <Grid item container>
                      <Grid item >
                        <Typography variant="body1">
                          Shipping Cost Missing Products(
                          {permissiontoexport.shippingmissing_products.length})
                        </Typography>
                      </Grid>

                      <Grid item ml={0.5}>
                        <Typography variant="caption" color="error">
                          We will skip these products
                        </Typography>
                      </Grid>
                    </Grid>


                    {/* {permissiontoexport.shippingmissing_products.map((product) => {
                  return <ProductLayout product={product} />;
                })} */}
                    <Grid item>
                      <Typography variant="body1">
                        Exporting Products(
                        {permissiontoexport.selectedProducts.length})
                      </Typography>
                      {/* {permissiontoexport.selectedProducts.map((product) => {
                  return <ProductLayout product={product} />;
                })} */}
                    </Grid>

                  </Grid>
                ) : (
                  ""
                )}
                {/* {permissiontoexport &&
              permissiontoexport.selectedProducts === undefined && (
                <div style={{ overflow: "scroll", height: "400px" }}>
                  {exportstatus.total === 0 &&
                    selectedProducts.map((product) => {
                      console.log("product", product);
                      return <ProductLayout product={product} />;
                    })}
                </div>
              )} */}
                {exportstatus.total !== 0 && (
                  <div style={{ marginLeft: "35%", marginTop: "10%" }}>
                    <div style={{ marginBottom: "2%", fontSize: "12px" }}>
                      <Typography>{exportstatus.message}</Typography>
                    </div>
                    <CircularProgressWithLabel
                      variant="determinate"
                      value={(exportstatus.created / exportstatus.total) * 100}
                      size={150}
                    />
                  </div>
                )}

              </Grid>
            </Grid>

            {/* Modal Footer */}
            <Grid item container justifyContent={{ xs: "center", md: "flex-end" }}>
              {permissiontoexport &&
                permissiontoexport.selectedProducts === undefined && (
                  <>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      sx={{ mx: 1 }}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="contained"
                      color="success"
                      disabled={storestoexport.length === 0}
                      onClick={(event) =>
                        handleBulkExport(storestoexport, category)
                      }
                    >
                      EXPORT
                    </Button>
                  </>
                )}

              {permissiontoexport &&
                permissiontoexport.selectedProducts &&
                permissiontoexport.selectedProducts.length !== 0 && (
                  <>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      sx={{ mx: 1 }}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="contained"
                      color="success"
                      onClick={(event) => handleExportAliExpress()}
                    >
                      CONTINUE
                    </Button>
                  </>
                )}
            </Grid>
          </Grid>
        )}

        {exportingStatus === "exporting" && (
          <Grid container flexDirection="column" justifyContent="space-between" sx={{ height: "100%" }}>
            <Grid item container flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              {/* Modal Header */}
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                pb={2}
              >
                <Typography variant="h5">
                  Export To Shopify Draft Listings
                </Typography>
                <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
              </Grid>

              {/* Modal Content */}
              <Grid item container flexDirection="column">
                <Grid container py={2} width='100%'>
                  <LinearProgressWithLabel
                    value={Math.round(exportstatus.created / selectedProducts.length * 100)}
                    color="info"
                    sx={{
                      borderRadius: 3,
                      flexGrow: 1,
                      height: "18px",
                    }}
                  />
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  py={1}
                >
                  <Typography variant="h6">Creating draft listings...</Typography>
                  <Typography variant="h6">
                    {exportstatus.created}/{selectedProducts.length}
                  </Typography>
                </Grid>

                <Grid
                  container
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  px={1}
                >
                  <Typography variant="body1">Exported:{exportstatus.created}</Typography>
                  <Typography variant="body1">Failed:{exportstatus?.failed?exportstatus?.failed : 0}</Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* Modal Footer */}
            <Grid item container justifyContent={{ xs: "center", md: "flex-end" }}>
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{ mx: 1 }}
              >
                Cancel
              </Button>
              {/* <Button variant="contained">Draft Listings</Button> */}
            </Grid>
          </Grid>
        )}

        {exportingStatus === "exportFinished" && (
          <Grid container flexDirection="column" justifyContent="space-between" sx={{ height: "100%" }}>
            <Grid item container flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              {/* Modal Header */}
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                pb={2}
              >
                <Typography variant="h5">
                  Exporting
                </Typography>
                <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
              </Grid>

              {/* Modal Content */}
              <Grid item container flexDirection="column">
                <Grid container py={2} width='100%'>
                  <LinearProgressWithLabel
                    value={100}
                    color="success"
                    sx={{
                      borderRadius: 3,
                      flexGrow: 1,
                      height: "18px",
                    }}
                  />
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  py={1}
                >
                  <Typography variant="h6">Create draft listings Completed</Typography>
                  <Typography variant="h6">
                    {exportstatus.created}/{selectedProducts.length}
                  </Typography>
                </Grid>

                <Grid
                  container
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  px={1}
                >
                  <Typography variant="body1">Exported:{exportstatus.created}</Typography>
                  <Typography variant="body1">Failed:0</Typography>
                </Grid>
              </Grid>

            </Grid>

            {/* Modal Footer */}
            <Grid item container justifyContent={{ xs: "center", md: "flex-end" }}>
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{ mx: 1 }}
              >
                Close
              </Button>

              {store === "nzshopify" && (
                <Button
                  variant="contained"
                  component="a"
                  href="https://go-slash.myshopify.com/admin/products?selectedView=draft&status=DRAFT&order=created_at%20desc"
                  target="_blank"
                >
                  Draft Listings
                </Button>
              )}

              {store === "aushopify" && (
                <Button
                  variant="contained"
                  component="a"
                  href="https://goslash-au.myshopify.com/admin/products?selectedView=draft&status=DRAFT&order=created_at%20desc"
                  target="_blank"
                >
                  Draft Listings
                </Button>
              )}

              {store === "usshopify" && (
                <Button
                  variant="contained"
                  component="a"
                  href="https://paralleldeals.myshopify.com/admin/products?selectedView=draft&status=DRAFT&order=created_at%20desc"
                  target="_blank"
                >
                  Draft Listings
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </Modal>
  );
}
