import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import ImportedControlledCheckbox from "./importedcheckbox.js";
import ExportModal from "./exportmodal.js";

import EditIcon from "@mui/icons-material/Edit";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import AliExpressIcon from "../assets/icons/aliexpress_icon.png";
import { SourcingAliexpressCarriersSelect } from "./SourcingAliexpressCarriersSelect";

import no_image from "../assets/images/no_image.jpg";

import {
  Button,
  Grid,
  Tooltip,
  Typography,
  Card,
  IconButton,
  MenuItem,
  Menu,
} from "@mui/material";

export default function ImportedProductsLayout({
  prices,
  product,
  showProducts,
  checkboxID,
  setCurrency,
  selectedProducts,
  setSelectedProducts,
  handleExport,
}) {
  const selected_supplier = useSelector((state) => state.selected_supplier);
  const store = useSelector((state) => state.store);
  const [openExportModal, setOpenExportModal] = useState(false);
  const handleCloseExportModal = () => setOpenExportModal(false);
  const [minCost, setMinCost] = useState(0);
  const [maxCost, setMaxCost] = useState(0);
  const [minWeight, setMinWeight] = useState(0);
  const [maxWeight, setMaxWeight] = useState(0);
  const [showVariants, setShowVariants] = useState(false);

  // const CalculatePrice = () => {
  //   let prices = [];
  //   if (Array.isArray(product.variants)) {
  //     product.variants.map((item) => {
  //       prices.push(parseFloat(item.price).toFixed(2));
  //     });
  //     return `${Math.min(...prices)} - ${Math.max(...prices)}`;
  //   }
  // };

  let skuPropertyValues;
  let uniqueSkuPropertyValues;
  if (selected_supplier === "aliexpress") {
    if (
      product.aliexpress_product &&
      product.aliexpress_product.result &&
      product.aliexpress_product.result.currency_code &&
      product.aliexpress_product.result.currency_code !== null
    ) {
      setCurrency(product.aliexpress_product.result.currency_code);
    }

    if (
      product.aliexpress_product &&
      product.aliexpress_product.result &&
      product.aliexpress_product.result.aeop_ae_product_s_k_us &&
      product.aliexpress_product.result.aeop_ae_product_s_k_us
        .aeop_ae_product_sku &&
      Array.isArray(
        product.aliexpress_product.result.aeop_ae_product_s_k_us
          .aeop_ae_product_sku
      )
    ) {
      skuPropertyValues =
        product.aliexpress_product.result.aeop_ae_product_s_k_us.aeop_ae_product_sku.map(
          (item) => {
            let shipsFrom;
            if (
              item.aeop_s_k_u_propertys &&
              item.aeop_s_k_u_propertys.aeop_sku_property.length > 0
            ) {
              shipsFrom = item.aeop_s_k_u_propertys.aeop_sku_property.filter(
                (property) => property.sku_property_name === "Ships From"
              );
            }

            if (
              // item.aeop_s_k_u_propertys &&
              // item.aeop_s_k_u_propertys.aeop_sku_property &&
              // item.aeop_s_k_u_propertys.aeop_sku_property[1] &&
              // item.aeop_s_k_u_propertys.aeop_sku_property[1].sku_property_name &&
              // item.aeop_s_k_u_propertys.aeop_sku_property[1].sku_property_name ===
              //   "Ships From"
              shipsFrom &&
              shipsFrom.length > 0
            ) {
              return shipsFrom[0].sku_property_value;
            }
          }
        );
      uniqueSkuPropertyValues = [...new Set(skuPropertyValues)];
      // console.log("uniqueSkuPropertyValues", uniqueSkuPropertyValues);
    }
  }

  // let shippingCostList;

  // if (selected_supplier === "aliexpress") {
  //   shippingCostList = product.aliexpress_shipping.map((item, index) => {
  //     let name = "unknown";
  //     if (item.service_name === "UBI") {
  //       name = "UBI";
  //     }
  //     if (item.service_name === "CAINIAO_STANDARD") {
  //       name = "AliExpress Standard";
  //     }

  //     if (item.service_name === "CAINIAO_PREMIUM") {
  //       name = "AliExpress Premium";
  //     }

  //     if (item.service_name === "YANWEN_AM") {
  //       name = "Special Line-YW";
  //     }
  //     if (item.service_name === "EMS_ZX_ZX_US") {
  //       name = "ePacket";
  //     }
  //     if (item.service_name === "DHL") {
  //       name = "DHL";
  //     }
  //     if (item.service_name === "USPS") {
  //       name = "USPS";
  //     }
  //     if (item.service_name === "UPS") {
  //       name = "UPS";
  //     }
  //     if (item.service_name === "UPSE") {
  //       name = "UPSE";
  //     }
  //     if (item.service_name === "EMS") {
  //       name = "EMS";
  //     }
  //     if (item.service_name === "E_EMS") {
  //       name = "E_EMS";
  //     }
  //     if (item.service_name === "FEDEX") {
  //       name = "FEDEX";
  //     }
  //     if (item.service_name === "FEDEX_IE") {
  //       name = "FEDEX_IE";
  //     }
  //     if (item.service_name === "Other") {
  //       name = "Seller's Shipping Method";
  //     }
  //     if (item.service_name === "SGP_OMP") {
  //       name = "4PX Singapore Post OM Pro";
  //     }
  //     if (item.service_name === "CAINIAO_STANDARD_SG") {
  //       name = "Cainiao Standard For Special Goods";
  //     }

  //     if (name === "unknown") {
  //       name = name + "-" + item.service_name;
  //     }
  //     return (
  //       <Grid key={index} container>
  //         <Grid item xs={9}>
  //           <Typography variant="body2">{name}</Typography>
  //         </Grid>
  //         <Grid item xs={3}>
  //           <Typography variant="body2">
  //             {item.freight.currency_code + " " + item.freight.amount}
  //           </Typography>
  //         </Grid>
  //       </Grid>
  //     );
  //   });
  // }

  const handleDownloadVideo = (videoName, videoURL) => {
    const link = document.createElement("a");
    link.download = videoName;
    link.href = videoURL;
    link.target = "_blank";
    link.click();
  };

  useEffect(() => {
    CalculatePriceAndWeight();
  }, [product]);

  const CalculatePriceAndWeight = () => {
    let prices = [];
    let weight = [];

    if (Array.isArray(product.variants)) {
      product.variants.map((item) => {
        prices.push(parseFloat(item.price).toFixed(2));
        weight.push(item.weight);
      });
      setMinCost(Math.min(...prices));
      setMaxCost(Math.max(...prices));
      setMinWeight(Math.min(...weight));
      setMaxWeight(Math.max(...weight));
    }
  };

  const CalculateStock = () => {
    let stockQty = [];
    if (Array.isArray(product.variants)) {
      product.variants.map((item) => {
        stockQty.push(parseFloat(item.inventory_quantity));
      });
      // console.log(
      //   "stockQtyTotal",
      //   stockQty.reduce((acc, qty) => acc + qty, 0)
      // );
      stockQty = stockQty.filter((el)=> !isNaN(el))

      // return `${Math.min(...stockQty)} - ${Math.max(...stockQty)}`;
      const total = stockQty.reduce((acc, qty) => acc + qty, 0);
      return isNaN(total) ? 0 : total;
    }
  };

  const formatDate = (date) => moment(date).format("DD/MM/YYYY HH:mm:ss");

  //Export Dropdown Menu
  const [exportMenuAnchorEl, setExportMenuAnchorEl] = useState(null);
  const openExportMenu = Boolean(exportMenuAnchorEl);
  const handleOpenExportMenu = (event) => {
    setExportMenuAnchorEl(event.currentTarget);
  };
  const handleCloseExportMenu = () => {
    setExportMenuAnchorEl(null);
  };

  //More Dropdown Menu
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);
  const openMoreMenu = Boolean(moreMenuAnchorEl);
  const handleOpenMoreMenu = (event) => {
    setMoreMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMoreMenu = () => {
    setMoreMenuAnchorEl(null);
  };

  let supplier_sku_url = "";

  if (product) {
    if (selected_supplier === "bangood") {
      supplier_sku_url = `https://banggood.com/search/${product.sku}.html`;
    }

    if (selected_supplier === "aliexpress") {
      supplier_sku_url = `https://www.aliexpress.com/item/${product.product_id}.html`;
    }

    if (selected_supplier === "wefullfill") {
      supplier_sku_url = `https://app.wefullfill.com/users/products/wefullfill/${product.product_id}`;
    }

    // if (selected_supplier === "dropsite") {
    //   supplier_sku_url = "https://dropsite.com.au/";
    // }

    if (selected_supplier === "soga") {
      supplier_sku_url = `https://www.sogainternational.com/search?q=${product.product_id}`;
    }

    if (selected_supplier === "vidaxl") {
      supplier_sku_url = `https://www.vidaxl.com.au/catalogsearch/result?q=${product.product_id}`;
    }

    if (selected_supplier === "dropship") {
      supplier_sku_url = `https://www.dropshipzone.com.au/catalogsearch/result/?q=${product.sku}`;
    }

    // if (selected_supplier === "matterhorn") {
    //   supplier_sku_url = "https://matterhorn-wholesale.com//";
    // }

    if (selected_supplier === "fragrancex") {
      let brandNameWithDash = product.tags ? product.tags[0]
        .slice(6)
        .toLowerCase()
        .replace(/ /g, "-") : '';
      // console.log("brandNameWithDash", brandNameWithDash)
      let titleWithDash = product.title ? product.title.toLowerCase().replace(/ /g, "-") : '';
      // console.log("titleWithDash", titleWithDash)

      supplier_sku_url = ` https://www.fragrancex.com/products/${brandNameWithDash}/${titleWithDash}`;
    }

    if (selected_supplier === "wedropship") {
      supplier_sku_url = "https://www.wedropship.com.au/";
    }

    if (selected_supplier === "idropship") {
      supplier_sku_url = `https://idropship.com.au/catalogsearch/result/?q=${product.product_id}`;
    }

    if (selected_supplier === "bigbuy") {
      supplier_sku_url = `https://www.bigbuy.eu/search/result?q=${product.product_id}`;
    }

    if (selected_supplier === "sunsky") {
      supplier_sku_url = `https://www.sunsky-online.com/p/${product.product_id}.htm`;
    }

    // if (selected_supplier === "ingrammicro") {
    //   supplier_sku_url = "https://nz.ingrammicro.com/Site/home";
    // }

    // if (selected_supplier === "ibacrafts") {
    //   supplier_sku_url = "https://ibacrafts.com/";
    // }
  }
  return (
    <>
      <Card sx={{ width: "100%", mt: 1 }}>
        <Grid
          container
          sx={{
            display: "Grid",
            gridTemplateColumns:
              "2% 4% 18% 5% 5% 7% 14% 5% 4% 7% 10% 5% 5% 5% 4%",
            alignItems: "center",
          }}
        >
          {/* Checkbox */}
          <Grid container justifyContent="center">
            <ImportedControlledCheckbox
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              product={product}
              checkboxID={checkboxID}
            />
          </Grid>

          {/* Image */}
          <Grid container justifyContent="center" alignItems="center" p={0.5}>
            <Tooltip
              arrow
              placement="top"
              PopperProps={{
                sx: {
                  "& .MuiTooltip-tooltip": {
                    padding: 0,
                  },
                },
              }}
              title={
                <img
                  src={
                    product.images && product.images[0]
                      ? product.images[0].src
                      : no_image
                  }
                  alt=""
                  style={{
                    width: "250px",
                  }}
                />
              }
            >
              <Grid
                item
                justifyContent="center"
                alignContent="center"
                sx={{ border: (theme) => `1px solid ${theme.palette.border}` }}
              >
                <img
                  src={
                    product.images && product.images[0]
                      ? product.images[0].src
                      : no_image
                  }
                  alt=""
                  width="60px"
                />
              </Grid>
            </Tooltip>
          </Grid>

          {/* Title & Sku # & Product ID */}
          <Grid container flexDirection="column" pl={1}>
            {product.title && product.title.length > 70 ? (
              <Tooltip arrow placement="top" title={product.title}>
                <Typography variant="body2">
                  {`${product.title.slice(0, 70)}...`}
                </Typography>
              </Tooltip>
            ) : (
              <Typography variant="body2">{product.title}</Typography>
            )}

            <Grid container>
              {selected_supplier === "aliexpress" &&
                (product.aliexpress_product &&
                product.aliexpress_product.result.aeop_a_e_multimedia &&
                product.aliexpress_product.result.aeop_a_e_multimedia
                  .aeop_a_e_videos &&
                product.aliexpress_product.result.aeop_a_e_multimedia
                  .aeop_a_e_videos.aeop_ae_video &&
                product.aliexpress_product.result.aeop_a_e_multimedia
                  .aeop_a_e_videos.aeop_ae_video.length !== 0 ? (
                  <Tooltip
                    arrow
                    placement="top"
                    title="Click To Download Video"
                  >
                    <VideocamIcon
                      fontSize="small"
                      color="success"
                      sx={{ cursor: "pointer", mr: 1 }}
                      onClick={() =>
                        handleDownloadVideo(
                          `${product.aliexpress_product.result.aeop_a_e_multimedia.aeop_a_e_videos.aeop_ae_video[0].media_id}.mp4`,
                          `https://video.aliexpress-media.com/play/u/ae_sg_item/${product.aliexpress_product.result.aeop_a_e_multimedia.aeop_a_e_videos.aeop_ae_video[0].ali_member_id}/p/1/e/6/t/10301/${product.aliexpress_product.result.aeop_a_e_multimedia.aeop_a_e_videos.aeop_ae_video[0].media_id}.mp4`
                        )
                      }
                    />
                  </Tooltip>
                ) : (
                  <VideocamOffIcon
                    fontSize="small"
                    color="disabled"
                    sx={{ mr: 1 }}
                  />
                ))}

              {selected_supplier === "bigbuy" &&
                (Array.isArray(product.images) &&
                product.images[product.images.length - 1].src !==
                  "https://www.youtube.com/watch?v=0" ? (
                  <Tooltip
                    arrow
                    placement="top"
                    title="Click To Download Video"
                  >
                    <VideocamIcon
                      fontSize="small"
                      color="success"
                      sx={{ cursor: "pointer", mr: 1 }}
                      onClick={() =>
                        handleDownloadVideo(
                          `video_${product.product_id}.mp4`,
                          product.images[product.images.length - 1].src
                        )
                      }
                    />
                  </Tooltip>
                ) : (
                  <VideocamOffIcon
                    fontSize="small"
                    color="disabled"
                    sx={{ mr: 1 }}
                  />
                ))}

              <Typography variant="body2" color="primary" sx={{ mr: 3 }}>
                {product.sku && `Sku #: ${product.sku}`}
              </Typography>

              <Typography
                component="a"
                href={supplier_sku_url}
                target="_blank"
                variant="body2"
                color="primary"
              >
                Product ID: {product.product_id}
              </Typography>
            </Grid>
          </Grid>

          {/* Barcode */}
          <Grid container justifyContent="center">
            <Typography variant="body2">
              {product.barcode === null || product.barcode === "" ? (
                <>
                  {product.variants &&
                    product.variants.length === 1 &&
                    product.variants[0].barcode}
                  {product.variants && product.variants.length > 1 && "..."}
                </>
              ) : (
                product.barcode
              )}
            </Typography>
          </Grid>

          {/* Weight */}
          <Grid container justifyContent="center">
            <Typography variant="body2">
              {!product?.weight
                ? product.variants && !product.variants[0].weight
                  ? "N/A"
                  : minWeight + " - " + maxWeight
                : product?.weight}
            </Typography>
          </Grid>

          {/* Cost */}
          <Grid container justifyContent="center">
            {selected_supplier !== "bangood" ? (
              <Typography variant="body2">
                {minCost + " - " + maxCost}
              </Typography>
            ) : (
              selected_supplier === "bangood" &&
              (product.warehouse_price && product.warehouse_price.length > 2 ? (
                <Tooltip
                  arrow
                  placement="top"
                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        padding: 0,
                      },
                    },
                  }}
                  title={
                    <Grid container flexDirection="column" p={1}>
                      {product.warehouse_price &&
                        product.warehouse_price.map((item, index) => (
                          <Typography key={index} variant="body2">
                            {item.warehouse} {item.warehouse_price}
                          </Typography>
                        ))}
                    </Grid>
                  }
                >
                  <Grid container flexDirection="column" alignItems="center">
                    {product.warehouse_price &&
                      product.warehouse_price.slice(0, 2).map((item, index) => (
                        <Typography key={index} variant="body2">
                          {item.warehouse} {item.warehouse_price}
                        </Typography>
                      ))}
                    <Typography variant="body2">...</Typography>
                  </Grid>
                </Tooltip>
              ) : (
                <Grid container flexDirection="column" alignItems="center">
                  {product.warehouse_price &&
                    product.warehouse_price.map((item, index) => (
                      <Typography key={index} variant="body2">
                        {item.warehouse} {item.warehouse_price}
                      </Typography>
                    ))}
                </Grid>
              ))
            )}
          </Grid>

          {/* Shipping Cost */}
          <Grid container justifyContent="center" alignItems="center">
            {selected_supplier === "aliexpress" ? (
              <SourcingAliexpressCarriersSelect
                carrierList={product.aliexpress_shipping}
                // setAliexpressCarrier={setAliexpressCarrier}
                productId={product.product_id}
              />
            ) : (
              <Typography variant="body2">N/A</Typography>
            )}
          </Grid>

          {/* Shipping Cost */}
          {/* <Grid container flexDirection="column" alignItems="center">
          <Typography variant="body2">
            {selected_supplier === "aliexpress" ? (
              product.aliexpress_shipping.length > 1 ? (
                <Tooltip
                  arrow
                  placement="top"
                  PopperProps={{
                    sx: {
                      width: "300px",
                      "& .MuiTooltip-tooltip": {
                        padding: 0,
                      },
                    },
                  }}
                  title={
                    <Grid container flexDirection="column" p={1}>
                      {shippingCostList}
                    </Grid>
                  }
                >
                  <Grid container flexDirection="column" alignItems="center">
                    <Typography variant="body2">
                      {product.aliexpress_shippingcost}
                    </Typography>
                    <Typography variant="body2">...</Typography>
                  </Grid>
                </Tooltip>
              ) : (
                <Grid container flexDirection="column" alignItems="center">
                  {product.aliexpress_shipping.map((item, index) => (
                    <Grid key={index} container>
                      <Grid item xs={9}>
                        <Typography variant="body2">
                          {item.service_name.length > 20
                            ? item.service_name.slice(0, 20) + "..."
                            : item.service_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {item.freight.currency_code +
                            " " +
                            item.freight.amount}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )
            ) : (
              "N/A"
            )}
          </Typography>
        </Grid> */}

          {/* Ships From */}
          <Grid container flexDirection="column" alignItems="center">
            {/* {uniqueSkuPropertyValues.length > 0 &&
            uniqueSkuPropertyValues.map((item) => (
              <Typography variant="body2">{item}</Typography>
            ))} */}

            {selected_supplier === "aliexpress" &&
              (Array.isArray(uniqueSkuPropertyValues) &&
              uniqueSkuPropertyValues.length > 2 ? (
                <Tooltip
                  arrow
                  placement="top"
                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        padding: 0,
                      },
                    },
                  }}
                  title={
                    <Grid container flexDirection="column" p={1}>
                      {uniqueSkuPropertyValues.map((item, index) => (
                        <Typography key={index} variant="body2">
                          {item}
                        </Typography>
                      ))}
                    </Grid>
                  }
                >
                  <Grid container flexDirection="column" alignItems="center">
                    {uniqueSkuPropertyValues.slice(0, 2).map((item, index) => (
                      <Typography key={index} variant="body2">
                        {item}
                      </Typography>
                    ))}
                    <Typography variant="body2">...</Typography>
                  </Grid>
                </Tooltip>
              ) : (
                <Grid container flexDirection="column" alignItems="center">
                  {Array.isArray(uniqueSkuPropertyValues) &&
                    uniqueSkuPropertyValues.map((item, index) => (
                      <Typography
                        key={index}
                        variant="body2"
                        color={item === "China" ? "primary" : ""} // Change to selected_warehouse === "CN" later
                      >
                        {item}
                      </Typography>
                    ))}
                </Grid>
              ))}
          </Grid>

          {/* Variation */}
          <Grid container justifyContent="center">
            {selected_supplier == 'vidaxl' || selected_supplier == 'dropship' ? 
            <Typography variant="body2">
            {product.number_of_packages ? product.number_of_packages : 0}
          </Typography>
            :Array.isArray(product.variants) && product.variants.length > 1 ? (
              <Typography
                variant="body2"
                color="primary"
                sx={{ cursor: "pointer" }}
                onClick={() => setShowVariants(!showVariants)}
              >
                {product.variants.length}
              </Typography>
            ) : (
              <Typography variant="body2">
                {Array.isArray(product.variants)
                  ? product.variants.length
                  : "N/A"}
              </Typography>
            )}
          </Grid>

          {/* Stock */}
          <Grid container justifyContent="center">
            {selected_supplier !== "bangood" ? (
              <Typography variant="body2">
                {selected_supplier === "aliexpress" &&
                  `${product.aliexpress_shipfrom}: ${CalculateStock()}`}
                {selected_supplier === "wefullfill" &&
                  `${
                    product.warehouse === "AU" || !product.warehouse
                      ? "Australia"
                      : product.warehouse
                  }: ${CalculateStock()}`}
                {selected_supplier !== "aliexpress" &&
                  selected_supplier !== "wefullfill" &&
                  CalculateStock()}

                {console.log("This is product warehouse------------->>>>>>>>>>",product)}
              </Typography>
            ) : product.warehouse_stock &&
              product.warehouse_stock.length > 2 ? (
              <Tooltip
                arrow
                placement="top"
                PopperProps={{
                  sx: {
                    "& .MuiTooltip-tooltip": {
                      padding: 0,
                    },
                  },
                }}
                title={
                  <Grid container flexDirection="column" p={1}>
                    {product.warehouse_stock &&
                      product.warehouse_stock.map((item, index) => (
                        <Typography key={index} variant="body2">
                          {item.warehouse} {item.stock_list[0].stock}
                        </Typography>
                      ))}
                  </Grid>
                }
              >
                <Grid container flexDirection="column" alignItems="center">
                  {product.warehouse_stock &&
                    product.warehouse_stock.slice(0, 2).map((item, index) => (
                      <Typography key={index} variant="body2">
                        {item.warehouse} {item.stock_list[0].stock}
                      </Typography>
                    ))}
                  <Typography variant="body2">...</Typography>
                </Grid>
              </Tooltip>
            ) : (
              <Grid container flexDirection="column" alignItems="center">
                {product.warehouse_stock &&
                  product.warehouse_stock.map((item, index) => (
                    <Typography key={index} variant="body2">
                      {item.warehouse} {item.stock_list[0].stock}
                    </Typography>
                  ))}
              </Grid>
            )}
          </Grid>

          {/* Supplier */}
          <Grid container flexDirection="column" alignItems="flex-start">
            <Typography
              component="a"
              href="/"              // href={product.supplier.url}
              target="_blank"
              variant="body2"
              color="primary"
            >
              {product.supplier === "aliexpress" && (
                <Grid container>
                  <img
                    src={AliExpressIcon}
                    alt="AliExpress Icon"
                    style={{ width: "16px", height: "16px" }}
                  />
                  {product.aliexpress_store.store_name !== "" &&
                    product.aliexpress_store.store_name !== null && (
                      <Tooltip
                        arrow
                        placement="top"
                        title={product.aliexpress_store.store_name}
                      >
                        <a
                          href={`https://www.aliexpress.com/store/${product.aliexpress_store.store_id}`}
                          target="_blank"
                        >
                          <Typography variant="body2" color="error" mx={0.5}>
                            {product.aliexpress_store.store_name.length < 16
                              ? product.aliexpress_store.store_name
                              : product.aliexpress_store.store_name.slice(
                                  0,
                                  15
                                ) + "..."}
                          </Typography>
                        </a>
                      </Tooltip>
                    )}
                </Grid>
              )}
              {product.supplier === "bangood" && "Banggood"}
              {product.supplier === "sunsky" && "Sunsky"}
              {product.supplier === "wefullfill" && "WeFullfill"}
              {product.supplier === "dropsite" && "Dropsite"}
              {product.supplier === "soga" && "Soga"}
              {(product.supplier === "VidaXL" ||  product.supplier === "vidaxl") && "VidaXL"}
              {product.supplier === "dropship" && "Dropshipzone"}
              {product.supplier === "matterhorn" && "Matterhorn"}
              {product.supplier === "fragrancex" && "FragranceX"}
              {product.supplier === "wedropship" && "WeDropship"}
              {product.supplier === "idropship" && "iDropship"}
              {product.supplier === "bigbuy" && "BigBuy"}
              {product.supplier === "ingrammicro" && "Ingram Micro"}
              {product.supplier === "griffati" && "Griffati"}
              {product.supplier === "serrano" && "Serrano"}
              {product.supplier === "ibacrafts" && "IBA Crafts"}
            </Typography>

            {product.supplier === "aliexpress" && (
              <>
                <Typography variant="body2" color="neutral.400">
                  Rating:
                  {product.aliexpress_product.result.avg_evaluation_rating}
                </Typography>
                <Typography variant="body2" color="neutral.400">
                  Orders: {product.aliexpress_product.result.order_count}
                </Typography>
              </>
            )}
          </Grid>

          {/* Status */}
          <Grid container justifyContent="flex-start">
            <Typography variant="body2">
              {store === "nzshopify" && product.export_status_nz}
              {store === "aushopify" && product.export_status_au}
              {store === "usshopify" && product.export_status_us}
              {store === "gkshopify" && product.export_status_gk}
              {store === "kogan" && product.export_status_kg}
            </Typography>
          </Grid>

          {/* Created At */}
          <Grid container justifyContent="flex-start">
            <Typography variant="body2">
              {product.supplier === "aliexpress"
                ? formatDate(product.aliexpress_product.result.gmt_create)
                : formatDate(product.createdAt)}
            </Typography>
          </Grid>

          {/* Imported At */}
          <Grid container justifyContent="flex-start">
            <Typography variant="body2">
              {product.importedAt && formatDate(product.importedAt)}
            </Typography>
          </Grid>

          {/* Action */}
          <Grid container flexDirection="column">
            {showProducts !== "exported" && showProducts !== "deleted" && (
              <>
                <Grid container justifyContent="center">
                  <Tooltip arrow placement="top" title="Edit Listing">
                    <IconButton
                      size="small"
                      id="EditIcon"
                      // onClick={handleOpenExportMenu}
                    >
                      <EditIcon fontSize="20px" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip arrow placement="top" title="Lightning">
                    <IconButton
                      size="small"
                      id="FlashOnIcon"
                      // onClick={handleOpenExportMenu}
                    >
                      <FlashOnIcon fontSize="20px" />
                    </IconButton>
                  </Tooltip>
                </Grid>

                <Grid container justifyContent="center">
                  <Tooltip arrow placement="top" title="Export To Store">
                    <IconButton
                      size="small"
                      id="ExitToAppIcon"
                      onClick={handleOpenExportMenu}
                    >
                      <ExitToAppIcon fontSize="20px" />
                    </IconButton>
                  </Tooltip>

                  <Menu
                    id="export-menu"
                    MenuListProps={{
                      "aria-labelledby": "ExitToAppIcon",
                    }}
                    anchorEl={exportMenuAnchorEl}
                    open={openExportMenu}
                    onClose={handleCloseExportMenu}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                  >
                    <MenuItem onClick={() => setOpenExportModal(true)}>
                      <Typography variant="body2">Shopify</Typography>
                    </MenuItem>
                    <MenuItem disabled>
                      <Typography variant="body2">eBay</Typography>
                    </MenuItem>

                    <ExportModal
                      open={openExportModal}
                      handleClose={handleCloseExportModal}
                      handleExport={handleExport}
                      product={product}
                    />
                  </Menu>

                  <Tooltip arrow placement="top" title="More">
                    <IconButton
                      size="small"
                      id="MoreHorizIcon"
                      onClick={handleOpenMoreMenu}
                    >
                      <MoreHorizIcon fontSize="20px" />
                    </IconButton>
                  </Tooltip>

                  <Menu
                    id="more-menu"
                    MenuListProps={{
                      "aria-labelledby": "MoreIcon",
                    }}
                    anchorEl={moreMenuAnchorEl}
                    open={openMoreMenu}
                    onClose={handleCloseMoreMenu}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                  >
                    <MenuItem disabled>
                      <Typography variant="body2">More Action</Typography>
                    </MenuItem>
                    <MenuItem disabled>
                      <Typography variant="body2">More Action</Typography>
                    </MenuItem>
                  </Menu>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Card>
      {showVariants && (
        <Card sx={{ width: "100%", mt: 1 }}>
          <Grid
            container
            sx={{
              display: "Grid",
              gridTemplateColumns: "24% 6% 6% 6% 14% 8% 4% 8% 10% 5% 5% 4%",
              alignItems: "center",
            }}
          >
            {/* SKU No. */}
            <Grid container flexDirection="column" alignItems="center">
              {product.variants &&
                product.variants.map((variant) => (
                  <Typography variant="body2">
                    {variant.sku === undefined ||
                    variant.sku === null ||
                    variant.sku === ""
                      ? "N/A"
                      : variant.sku}
                  </Typography>
                ))}
            </Grid>

            {/* Barcode */}
            <Grid container flexDirection="column" alignItems="center">
              {product.variants &&
                product.variants.map((variant) => (
                  <Typography variant="body2">
                    {variant.barcode === undefined ||
                    variant.barcode === null ||
                    variant.barcode === ""
                      ? "N/A"
                      : variant.barcode}
                  </Typography>
                ))}
            </Grid>

            {/* Weight */}
            <Grid container flexDirection="column" alignItems="center">
              {product.variants &&
                product.variants.map((variant) => (
                  <Typography variant="body2">
                    {variant.weight === undefined ||
                    variant.weight === null ||
                    variant.weight === ""
                      ? "N/A"
                      : `${variant.weight}kg`}
                  </Typography>
                ))}
            </Grid>

            {/* Cost */}
            <Grid container flexDirection="column" alignItems="center">
              {selected_supplier === "bangood"
                ? product.warehouse_price &&
                  product.warehouse_price.map((item, index) => (
                    <Typography variant="body2" key={index}>
                      {item.warehouse} {item.warehouse_price}
                    </Typography>
                  ))
                : product.variants &&
                  product.variants.map((variant, index) => (
                    <Typography variant="body2" key={index}>
                      {variant.warehouse} {variant.price}
                    </Typography>
                  ))}
            </Grid>

            {/* Shipping Cost */}
            <Grid container flexDirection="column" alignItems="center">
              <Typography variant="body2">-</Typography>
            </Grid>

            {/* Blank */}
            <Grid container flexDirection="column" alignItems="center">
              <Typography variant="body2">-</Typography>
            </Grid>

            {/* Variation */}
            <Grid container flexDirection="column" alignItems="center">
              <Typography variant="body2">-</Typography>
            </Grid>

            {/* Stock */}
            <Grid container flexDirection="column" alignItems="center">
              {selected_supplier === "bangood"
                ? product.warehouse_stock &&
                  product.warehouse_stock.map((item, index) => (
                    <Typography variant="body2" key={index}>
                      {item.warehouse} {item.stock_list[0].stock}
                    </Typography>
                  ))
                : product.variants &&
                  product.variants.map((variant, index) => (
                    <Typography variant="body2" key={index}>
                      {variant.warehouse} {variant.inventory_quantity}
                    </Typography>
                  ))}
            </Grid>

            {/* Supplier */}
            <Grid container flexDirection="column" alignItems="flex-start">
              <Typography variant="body2">-</Typography>
            </Grid>

            {/* Status */}
            <Grid container flexDirection="column" alignItems="flex-start">
              <Typography variant="body2">-</Typography>
            </Grid>

            {/* Created At */}
            <Grid container flexDirection="column" alignItems="center">
              <Typography variant="body2">-</Typography>
            </Grid>

            {/* Action */}
            <Grid container flexDirection="column" alignItems="center">
              <Typography variant="body2">-</Typography>
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
}
