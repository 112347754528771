import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SupplierSelect({ value, handleChange }) {
  return (
    <Box sx={{ minWidth: 140, height: 30 }}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label="Age"
        onChange={handleChange}
        style={{ width: "140px", height: "30px" }}
      >
        <MenuItem value="Bangood">Bangood</MenuItem>
        <MenuItem value="Ali Express">Ali Express</MenuItem>
        <MenuItem value="wefullfill">WeFullFill</MenuItem>
        <MenuItem value="matterhorn">Matterhorn</MenuItem>
        <MenuItem value="bigbuy">BigBuy</MenuItem>
      </Select>
    </Box>
  );
}
