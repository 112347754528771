import React, { useEffect, useState } from "react";
import SingleCheckBox from "../common/SingleCheckBox";
import CustomizedSnackbars from "../orders/notification";
import EditIcon from "@mui/icons-material/Edit";
import KoganCategorySelect from "../settings/KoganCategorySelect";

import {
  Switch,
  FormControlLabel,
  Radio,
  RadioGroup,
  TableRow,
  TableCell,
  Grid,
  Button,
  IconButton,
  Typography,
} from "@mui/material";

const KoganProductsTableRow = ({
  product,
  selectedSkus,
  setSelectedSkus,
  count,
  setCount,
}) => {
  const [editCategory, setEditCategory] = useState(false);

  const [koganCategoryLevel1, setKoganCategoryLevel1] =
    useState("Select a Category");
  const [koganCategoryLevel2, setKoganCategoryLevel2] =
    useState("Select a Category");

  const [koganCategoryCode, setKoganCategoryCode] = useState("");

  const handleSaveKoganProductCategory = () => {
    setEditCategory(!editCategory);
    console.log("kogancategory", koganCategoryLevel1, koganCategoryLevel2);

    fetch("https://api.saleprodev.com/productimporter/kogancategory", {
      method: "post",
      body: JSON.stringify({
        category1: koganCategoryLevel1,
        category2: koganCategoryLevel2,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("kogancategoryRes", res);
        setKoganCategoryCode(res);
      });
  };

  useEffect(() => {
    if (koganCategoryCode !== "") {
      fetch("https://api.saleprodev.com/productimporter/koganproductcategory", {
        method: "post",
        body: JSON.stringify([
          {
            product_sku: product.product_sku,
            category: koganCategoryCode,
            category2: koganCategoryLevel2,
          },
        ]),
      })
        .then((res) => res.json())
        .then((res) => {
          console.log("koganproductcategory", res);
          //Not updated instantly
          if (res.status === "AsyncResponsePending") {
            setTimeout(() => {
              setCount(count + 1);
            }, 1000);

            setOpenSnackbars({
              open: true,
              message: "Updated, please check after 6hs.",
            });
          }
        });
    }
  }, [koganCategoryCode]);

  const [radioValue, setRadioValue] = useState("");
  const handleChangeRadioButton = (event) => {
    setRadioValue(event.target.value);
  };
  console.log("radioValue", radioValue);

  useEffect(() => {
    if (radioValue !== "") {
      handleDisableKoganProduct();
    }
  }, [radioValue]);

  const handleDisableKoganProduct = (event) => {
    fetch("https://api.saleprodev.com/productimporter/koganproductstatus", {
      method: "post",
      body: JSON.stringify([
        { product_sku: product.product_sku, enabled: radioValue },
      ]),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("koganproductstatus", response);
        if (response.status === "AsyncResponsePending") {
          setTimeout(() => {
            setCount(count + 1);
          }, 1000);

          setOpenSnackbars({
            open: true,
            message: "Pending, please check after 6hs.",
          });
        }
      });
  };

  const [openSnackbars, setOpenSnackbars] = useState({
    open: false,
    message: "",
  });

  const handleCloseSnackbars = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbars({ open: false, message: "" });
  };

  return (
    <>
      <TableRow
        key={product.product_sku}
        sx={{ display: "grid", gridTemplateColumns: "5% 20% 55% 20%" }}
      >
        <TableCell component="th" scope="row">
          <SingleCheckBox
            id={product.product_sku}
            item={product}
            selectedItems={selectedSkus}
            setSelectedItems={setSelectedSkus}
          />
        </TableCell>

        <TableCell component="th" scope="row">
          {product.product_sku}
        </TableCell>

        <TableCell align="center">
          {editCategory ? (
            <Grid sx={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
              <KoganCategorySelect
                koganCategoryLevel1={koganCategoryLevel1}
                koganCategoryLevel2={koganCategoryLevel2}
                setKoganCategoryLevel1={setKoganCategoryLevel1}
                setKoganCategoryLevel2={setKoganCategoryLevel2}
              />
            </Grid>
          ) : (
            <Grid container justifyContent="center">
              <Typography variant="body2">
                {product.category_frontend}
              </Typography>
              {product.category !== product.category_frontend && (
                <Typography variant="body2" color="error">
                  &nbsp;(Pending)
                </Typography>
              )}
            </Grid>
          )}
        </TableCell>

        <TableCell align="center">
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={4}>
              {editCategory ? (
                <Grid container flexDirection="column">
                  <Button
                    size="small"
                    disabled={
                      koganCategoryLevel1 === "Select a Category" ||
                      koganCategoryLevel2 === "Select a Category"
                    }
                    onClick={handleSaveKoganProductCategory}
                  >
                    Save
                  </Button>

                  <Button
                    size="small"
                    onClick={() => setEditCategory(!editCategory)}
                  >
                    Cancel
                  </Button>
                </Grid>
              ) : (
                <IconButton
                  size="small"
                  onClick={() => setEditCategory(!editCategory)}
                >
                  <EditIcon
                    fontSize="20px"
                    sx={{ cursor: "pointer", mr: 0.25 }}
                  />
                </IconButton>
              )}
            </Grid>

            <Grid item xs={8}>
              <RadioGroup
                row
                // value={radioValue}
                value={product.enabled_frontend}
                onChange={handleChangeRadioButton}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio size="small" />}
                  label="On"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio size="small" />}
                  label="Off"
                />
              </RadioGroup>

              {/* <Switch
                id={`Switch_${product.product_sku}`}
                size="small"
                checked={product.enabled_frontend}
                onChange={handleDisableKoganProduct}
              /> */}
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>

      <CustomizedSnackbars
        handleClose={handleCloseSnackbars}
        open={openSnackbars.open}
        message={openSnackbars.message}
      />
    </>
  );
};

export default KoganProductsTableRow;
