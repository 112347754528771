import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSubsettings, setSettingsItem } from "../../actions";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Divider,
  List,
  ListItemButton,
} from "@mui/material";

export const SettingsSideMenu = ({ setSettingsMenu }) => {
  const dispatch = useDispatch();
  const subSettings = useSelector((state) => state.sub_settings);

  //Accordion
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (
      subSettings === "order" ||
      subSettings === "inventory" ||
      subSettings === "dropship" ||
      subSettings === "listing" ||
      subSettings === "company" ||
      subSettings === "advanced"
    ) {
      setExpanded("panel1");
      setSettingsMenu("General");
    }

    if (
      subSettings === "channels" ||
      subSettings === "suppliers" ||
      subSettings === "carriers"
    ) {
      setExpanded("panel2");
      setSettingsMenu("Integrations");
    }

    if (
      subSettings === "my_account" ||
      subSettings === "staff_accounts" ||
      subSettings === "change_password"
    ) {
      setExpanded("panel3");
      setSettingsMenu("Account");
    }

    if (subSettings === "billing_details") {
      setExpanded("panel4");
      setSettingsMenu("Billing");
    }
  }, [subSettings]);

  return (
    <Grid item xs={1}>
      <List
        component="nav"
        id="orders_side_menu_list"
        sx={{
          py: 0,
          bgcolor: "background.paper",
          "& .MuiAccordionSummary-root": {
            m: 0,
            p: 0,
            minHeight: "100%",
          },
          "& .MuiAccordionSummary-content": {
            m: 0,
          },

          "& .MuiAccordionDetails-root": {
            p: 0,
          },

          "& .MuiListItemButton-root": {
            "&:hover": {
              backgroundColor: (theme) => theme.palette.primary.light,
            },
          },
        }}
      >
        <Grid
          item
          sx={{
            p: 0.5,
            pl: 1,
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 700 }}>
            Settings
          </Typography>
        </Grid>

        <Divider sx={{ color: (theme) => theme.palette.neutral[300] }} />

        <Accordion
          disableGutters
          elevation={0}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <ListItemButton
              sx={{
                p: 0.75,
                pl: 2,
                backgroundColor: "",
              }}
            >
              <Typography variant="body2">General</Typography>
            </ListItemButton>
          </AccordionSummary>

          <AccordionDetails>
            {subSettings === "order" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">Order Settings</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setSettingsItem(""));
                  dispatch(setSubsettings("order"));
                }}
              >
                <Typography variant="body2">Order Settings</Typography>
              </ListItemButton>
            )}

            {subSettings === "inventory" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">Inventory Settings</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setSettingsItem(""));
                  dispatch(setSubsettings("inventory"));
                }}
              >
                <Typography variant="body2">Inventory Settings</Typography>
              </ListItemButton>
            )}

            {subSettings === "dropship" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">Dropship Settings</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setSettingsItem(""));
                  dispatch(setSubsettings("dropship"));
                }}
              >
                <Typography variant="body2">Dropship Settings</Typography>
              </ListItemButton>
            )}

            {subSettings === "listing" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">Listing Settings</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setSettingsItem(""));
                  dispatch(setSubsettings("listing"));
                }}
              >
                <Typography variant="body2">Listing Settings</Typography>
              </ListItemButton>
            )}

            {subSettings === "company" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">Company</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setSettingsItem(""));
                  dispatch(setSubsettings("company"));
                }}
              >
                <Typography variant="body2">Company</Typography>
              </ListItemButton>
            )}

            {subSettings === "advanced" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">Advanced</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setSettingsItem(""));
                  dispatch(setSubsettings("advanced"));
                }}
              >
                <Typography variant="body2">Advanced</Typography>
              </ListItemButton>
            )}
          </AccordionDetails>
        </Accordion>

        <Divider sx={{ color: (theme) => theme.palette.neutral[300] }} />

        <Accordion
          disableGutters
          elevation={0}
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <ListItemButton
              sx={{
                p: 0.75,
                pl: 2,
                backgroundColor: "",
              }}
            >
              <Typography variant="body2">Integrations</Typography>
            </ListItemButton>
          </AccordionSummary>

          <AccordionDetails>
            {subSettings === "channels" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">Manage Channels</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setSettingsItem(""));
                  dispatch(setSubsettings("channels"));
                }}
              >
                <Typography variant="body2">Manage Channels</Typography>
              </ListItemButton>
            )}

            {subSettings === "suppliers" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">Manage Suppliers</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setSettingsItem(""));
                  dispatch(setSubsettings("suppliers"));
                }}
              >
                <Typography variant="body2">Manage Suppliers</Typography>
              </ListItemButton>
            )}

            {subSettings === "carriers" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">Manage Carriers</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setSettingsItem(""));
                  dispatch(setSubsettings("carriers"));
                }}
              >
                <Typography variant="body2">Manage Carriers</Typography>
              </ListItemButton>
            )}
          </AccordionDetails>
        </Accordion>

        <Divider sx={{ color: (theme) => theme.palette.neutral[300] }} />

        <Accordion
          disableGutters
          elevation={0}
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary aria-controls="panel3-content" id="panel3-header">
            <ListItemButton
              sx={{
                p: 0.75,
                pl: 2,
                backgroundColor: "",
              }}
            >
              <Typography variant="body2">Account</Typography>
            </ListItemButton>
          </AccordionSummary>

          <AccordionDetails>
            {subSettings === "my_account" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">My Account</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setSettingsItem(""));
                  dispatch(setSubsettings("my_account"));
                }}
              >
                <Typography variant="body2">My Account</Typography>
              </ListItemButton>
            )}

            {subSettings === "staff_accounts" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">Staff Accounts</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setSettingsItem(""));
                  dispatch(setSubsettings("staff_accounts"));
                }}
              >
                <Typography variant="body2">Staff Accounts</Typography>
              </ListItemButton>
            )}

            {subSettings === "change_password" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">Change Password</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setSettingsItem(""));
                  dispatch(setSubsettings("change_password"));
                }}
              >
                <Typography variant="body2">Change Password</Typography>
              </ListItemButton>
            )}
          </AccordionDetails>
        </Accordion>

        <Divider sx={{ color: (theme) => theme.palette.neutral[300] }} />

        <Accordion
          disableGutters
          elevation={0}
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary aria-controls="panel4-content" id="panel4-header">
            <ListItemButton
              sx={{
                p: 0.75,
                pl: 2,
                backgroundColor: "",
              }}
            >
              <Typography variant="body2">Billing</Typography>
            </ListItemButton>
          </AccordionSummary>

          <AccordionDetails>
            {subSettings === "billing_details" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">Billing Details</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setSettingsItem(""));
                  dispatch(setSubsettings("billing_details"));
                }}
              >
                <Typography variant="body2">Billing Details</Typography>
              </ListItemButton>
            )}
          </AccordionDetails>
        </Accordion>
      </List>
    </Grid>
  );
};
