import React, { useEffect, useState } from "react";
import DSZCategorySelect from "./DSZCategorySelect";

import { Grid, Button, TextField } from "@mui/material";

export default function DSZCategories({
  DSZCategoryLevel1,
  DSZCategoryLevel2,
  setDSZCategoryLevel1,
  setDSZCategoryLevel2,
  DSZCategoryLevel3,
  setDSZCategoryLevel3,
  createDSZmapping,
  setTagName,
  tag_name,
}) {
  return (
    <Grid
      sx={{
        display: "grid",
        gridTemplateColumns: "20% 20% 30% 30%",
      }}
    >
      <Grid item p={1}>
        <TextField
          size="small"
          value={tag_name}
          placeholder="Enter Type"
          onChange={(event) => setTagName(event.target.value)}
          sx={{
            width: "100%",
            fontSize: "0.8rem",
            "& legend": {
              width: "unset",
            },
          }}
        />
      </Grid>

      <DSZCategorySelect
        DSZCategoryLevel1={DSZCategoryLevel1}
        DSZCategoryLevel2={DSZCategoryLevel2}
        setDSZCategoryLevel1={setDSZCategoryLevel1}
        setDSZCategoryLevel2={setDSZCategoryLevel2}
        DSZCategoryLevel3={DSZCategoryLevel3}
        setDSZCategoryLevel3={setDSZCategoryLevel3}

      />

      <Grid item p={1}>
        <Button
          variant="contained"
          onClick={(event) => createDSZmapping()}
          sx={{ width: "100%" }}
        >
          ADD
        </Button>
      </Grid>
    </Grid>
  );
}
