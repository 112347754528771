import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Grid } from "@mui/material";

import {
  setSelectedWarehouse,
} from "../../actions";

const WarehouseFilter = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.store);
  const selected_supplier = useSelector((state) => state.selected_supplier);
  const selected_warehouse = useSelector((state) => state.selected_warehouse);

  const [warehouseArray, setWarehouseArray] = useState([])

  useEffect(() => {
    // dispatch(setSelectedWarehouse("CN"))

    if (store === "nzshopify") {
      // if (selected_supplier === "bangood") {
      //   setWarehouseArray(["CN", "NZ", "AU", "US", "EU", "UK", "HK", "CZ"])
      // }

      if (selected_supplier === "wefullfill") {
        // setWarehouseArray(["CN"])
        setWarehouseArray(["CN", "AU", "US", "UK"])
      } else {
        setWarehouseArray([])
      }
    }

    if (store === "aushopify") {
      if (selected_supplier === "wefullfill") {
        // setWarehouseArray(["CN", "AU"])
        setWarehouseArray(["CN", "AU", "US", "UK"])
      } else {
        setWarehouseArray([])
      }
    }

    if (store === "usshopify") {
      if (selected_supplier === "wefullfill") {
        // setWarehouseArray(["CN", "US"])
        setWarehouseArray(["CN", "AU", "US", "UK"])
      } else {
        setWarehouseArray([])
      }
    }

    if (store === "gkshopify") {
      if (selected_supplier === "wefullfill") {
        setWarehouseArray(["CN", "AU", "US", "UK"])
      } else {
        setWarehouseArray([])
      }
    }

  }, [store, selected_supplier])

  return (warehouseArray.length !== 0 && (
    <Grid container alignItems="center" my={1.2}>
      <Grid item xs={1}>
        <Typography variant="body2">Warehouse</Typography>
      </Grid>

      <Grid
        item
        container
        xs={11}
        sx={{
          "& .MuiTypography-root": {
            cursor: "pointer",
          },
        }}
      >
        {warehouseArray.map((warehouse, index) => (
          <Grid key={index} item mx={1.2}>
            {selected_warehouse === warehouse ? (
              <Typography variant="body2" color="primary">
                {warehouse}
              </Typography>
            ) : (
              <Typography
                variant="body2"
                onClick={() => {
                  dispatch(setSelectedWarehouse(warehouse));
                }}
              >
                {warehouse}
              </Typography>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  )

  )
}

export default WarehouseFilter