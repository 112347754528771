import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {setSearch, setProductsPerPage, setImportPage, setImportTotalPages,setArticles,setSubsettings, setToken, setLoading,setCountrylist,setShippingMethods, setShowImportedProductsFilter,setCategoriesLevel1, setCategoriesLevel2,setCategoriesLevel3, setCategoriesLevel4,setCategoriesLevel5, setCategoriesLevel6,setImportedProducts,setImportedProductsFilter,setSelectedCategoryLevel2,setSelectedCategoryLevel3, setSelectedCategoryLevel4, setSelectedCategoryLevel5, setSelectedCategoryLevel6} from "../../actions"
import {useSelector, useDispatch} from "react-redux"
export default function BasicSelect({handleImport, setSelectedOrders, selectedOrders}) {
  const products_per_page = useSelector(state => state.products_per_page)
  const show_orders = useSelector(state => state.show_orders)
  const dispatch = useDispatch()
  const handleChange = (event) => {
    console.log("value",event.target.value)
    dispatch(setProductsPerPage(event.target.value));
    if(show_orders === "to_purchase"){
      let element = document.getElementById("maincheckbox")
      if(selectedOrders.length !== 0){
        setSelectedOrders([])
      }
      if(element.checked){
        element.click()
      }else{
        selectedOrders.map(order => {
          let element2 = document.getElementById(order.id)
          if(element2.checked){
            element2.click()
          }
        })
      }
    }

  };


  return (
    <Box sx={{ minWidth: 80}}>
      <FormControl>
        <InputLabel id="demo-simple-select-label"></InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={products_per_page}
          label="No"
          onChange={handleChange}
          style={{height:"30px"}}
        >
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={40}>40</MenuItem>
          <MenuItem value={60}>60</MenuItem>
          <MenuItem value={80}>80</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={150}>150</MenuItem>
          <MenuItem value={200}>200</MenuItem>
          <MenuItem value={250}>250</MenuItem>
          <MenuItem value={500}>500</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
