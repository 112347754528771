import React, { useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

import BasicSelect from "../select/select";
import { Loader } from "../common/Loader";

import {
  Button,
  Grid,
  TextField,
  Typography,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@mui/material";
import { Update } from "@mui/icons-material";
import moment from "moment";
import { error } from "toastr";

const SkuChecker = () => {
  const [loading, setLoading] = useState(false);
  const store_menu = useSelector((state) => state.store_menu);
  const selected_store = useSelector((state) => state.selected_store);

  const [sku, setSku] = useState("");
  const [skuArray, setSkuArray] = useState([]);
  const [supplierSku, setSupplierSku] = useState("");
  const [newSupplierSku, setNewSupplierSku] = useState("");
  const [storeName, setStoreName] = useState("");
  const [shopifyStock ,setshopifyStock] = useState("");
  const [supplier, setSupplier] = useState("");
  const [newSupplier, setNewSupplier] = useState("");
  const [productID, setProductID] = useState("");
  const [newStock,setNewStock] = useState("");
  const [aliproductstatus,setAliproductstatus] = useState("");
  const [update,setUpdate] = useState("");

  console.log("selected_store", selected_store);
  console.log("sku", sku);
  console.log("supplierSku", supplierSku);

  const handleChangeSku = (e) => {
    setSupplierSku("");
    setProductID("");
    setSku(e.target.value);
  };

  const handleChangeSupplierSku = (e) => {
    setSku("");
    setSkuArray([]);
    setProductID("");
    setSupplierSku(e.target.value);
  };

  const handleChangeProductID = (e) => {
    setSku("");
    setSkuArray([]);
    setSupplierSku("");
    setProductID(e.target.value);
  };

  const handleSearchSupplierSkuByEnterSku = () => {
    setLoading(true);
    fetch("https://api.saleprodev.com/getsupplierskunz", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sku: sku.trim(),
        store: selected_store,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("supplier_sku", response);
        setSupplierSku(response.supplier_sku);
        setNewSupplierSku(response.supplier_sku);
        setStoreName(response.store_name);
        setSupplier(response.supplier);
        setNewSupplier(response.supplier);
        setshopifyStock(response.shopifystock);
        setNewStock(response.newstock);
        setAliproductstatus(response.ali_productstatus);
        setUpdate(response.updatedAt);
        setLoading(false);
      }).catch((err)=>
      {
        setLoading(false);
        alert(err);
      });
  };


  const handleSearchSkuByEnterSupplierSku = () => {
    setLoading(true);
    fetch("https://api.saleprodev.com/getskubysuppliersku", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        supplier_sku: supplierSku.trim(),
        store: selected_store,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("skuArray", response);
        setSkuArray(response.sku);
        setshopifyStock(response.sku[0].shopifystock)
        setNewStock(response.sku[0].newstock)
        setAliproductstatus(response.sku[0].ali_productstatus);
        setUpdate(response.sku[0].updatedAt);
        setLoading(false);
        console.log("thisss is the response---------------->>>>",response.sku[0])
      }).catch((error)=>
      {
        alert(error);
        setLoading(false)
      });

  };

  const handleSearchSkuByEnterProductID = () => {
    setLoading(true);
    fetch("https://api.saleprodev.com/getskubyproductid", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        product_id: productID.trim(),
        store: selected_store,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("skuArray", response);
        setSkuArray(response.sku);
        setLoading(false);
      }).catch((error)=>
      {
        alert(error);
        setLoading(false);
      });
  };

  //Search By Select
  const [searchBy, setSearchBy] = useState("sku");
  const handleChangeSearchBy = (e) => {
    setSku("");
    setSkuArray([]);
    setSupplierSku("");
    setSearchBy(e.target.value);
  };
  const SearchBySelect = (
    <Grid container item xs={4} alignItems="center">
      <Typography variant="body1" sx={{ mr: 0.5 }}>
        Check By
      </Typography>

      <Select
        size="small"
        value={searchBy}
        onChange={handleChangeSearchBy}
        sx={{
          width: "250px",
          "& legend": {
            width: "unset",
          },
        }}
      >
        <MenuItem value="sku" sx={{ height: 26, fontSize: "0.8rem" }}>
          <Typography variant="body1">Shopify SKU</Typography>
        </MenuItem>

        <MenuItem value="supplierSku" sx={{ height: 26, fontSize: "0.8rem" }}>
          <Typography variant="body1">Supplier SKU</Typography>
        </MenuItem>

        <MenuItem value="productID" sx={{ height: 26, fontSize: "0.8rem" }}>
          <Typography variant="body1">Product ID(AliExpress Only)</Typography>
        </MenuItem>
      </Select>
    </Grid>
  );

  const handleUpdateSupplierSku = () => {
    fetch("https://api.saleprodev.com/updatesuppliersku", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        store: selected_store,
        sku: sku,
        previous_supplier: supplier,
        new_supplier: newSupplier,
        // previous_supplier_sku: supplierSku,
        new_supplier_sku: newSupplierSku
      }),
    })
      .then((res) => res.json())
      .then(res => {
        if (res.status === "success") {
          toast.success("Supplier Sku Updated");
        } else {
          toast.error(res.error);
        }
      })
      .catch(err => toast.error(err.message))
  }

  const suppliers = [
    "bangood",
    "aliexpress",
    "wefullfill",
    "dropsite",
    "soga",
    "vidaxl",
    "dropship",
    "matterhorn",
    "fragrancex",
    "wedropship",
    "idropship",
    "bigbuy",
    'sunsky',
    'serrano',
    'IBAcrafts',
    "ingrammicro",
  ];

  const handleUpdateSupplier = () => {
    fetch("https://api.saleprodev.com/updatesupplier", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        store: selected_store,
        sku: sku,
        previous_supplier: supplier,
        new_supplier: newSupplier,
        new_supplier_sku: newSupplierSku
      }),
    })
      .then((res) => res.json())
      .then(res => {
        console.log("res", res)
        if (res.status === "success") {
          toast.success("Supplier Updated");
        } else {
          toast.error(res.error);
        }
      })
      .catch(err => toast.error(err.message))
  };


  return (
    <Grid container p={1}>
      <Container
        maxWidth="lg"
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Grid
          container
          px={4}
          py={2}
          flexDirection="column"
          alignContent="center"
        >
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            py={2}
          >
            <Typography variant="h6">--- Sku Checker ---</Typography>
          </Grid>

          <Grid container py={1}>
            <Grid container item xs={4} alignContent="center">
              <Typography variant="body1">Store</Typography>
            </Grid>

            <Grid item xs={8}>
              <BasicSelect
                label="Select Store *"
                menu={store_menu}
                type="choosestore"
              />
            </Grid>
          </Grid>

          <Grid container py={1}>
            {SearchBySelect}

            {searchBy === "sku" && (
              <Grid item container xs={8}>
                <Grid item xs={10}>
                  <TextField
                    size="small"
                    value={sku}
                    placeholder="Enter SKU To Check Supplier SKU"
                    onChange={handleChangeSku}
                    sx={{
                      mr: 0.5,
                      width: "100%",
                      fontSize: "0.8rem",
                      "& legend": {
                        width: "unset",
                      },
                      "& .MuiFormControl-root, .MuiOutlinedInput-root": {
                        p: "0px!important",
                        minHeight: 26,
                      },
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={2}
                  justifyContent="center"
                  alignItems="center"
                  pl={1}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={handleSearchSupplierSkuByEnterSku}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            )}

            {searchBy === "supplierSku" && (
              <Grid item container xs={8}>
                <Grid item xs={10}>
                  <TextField
                    size="small"
                    value={supplierSku}
                    placeholder="Enter Supplier SKU To Check SKU"
                    onChange={handleChangeSupplierSku}
                    sx={{
                      mr: 0.5,
                      width: "100%",
                      fontSize: "0.8rem",
                      "& legend": {
                        width: "unset",
                      },
                      "& .MuiFormControl-root, .MuiOutlinedInput-root": {
                        p: "0px!important",
                        minHeight: 26,
                      },
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={2}
                  justifyContent="center"
                  alignItems="center"
                  pl={1}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={handleSearchSkuByEnterSupplierSku}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            )}

            {searchBy === "productID" && (
              <Grid item container xs={8}>
                <Grid item xs={10}>
                  <TextField
                    size="small"
                    value={productID}
                    placeholder="Enter Product ID To Check SKU(AliExpress Only)"
                    onChange={handleChangeProductID}
                    sx={{
                      mr: 0.5,
                      width: "100%",
                      fontSize: "0.8rem",
                      "& legend": {
                        width: "unset",
                      },
                      "& .MuiFormControl-root, .MuiOutlinedInput-root": {
                        p: "0px!important",
                        minHeight: 26,
                      },
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={2}
                  justifyContent="center"
                  alignItems="center"
                  pl={1}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={handleSearchSkuByEnterProductID}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>

          {loading ? (
            <Grid container py={1}>
              <Loader size="30px" />
            </Grid>
          ) : (
            <Grid container>
              {searchBy === "sku" && sku !== "" && supplierSku !== "" && (
                <Grid container>
                  <Grid item container py={1}>
                    <Grid item xs={4}>
                      <Typography variant="body1">Supplier SKU</Typography>
                    </Grid>

                    {/* <Grid item xs={8}>
                    {supplierSku}
                  </Grid> */}

                    <Grid item container xs={8}>
                      <Grid item xs={10}>
                        <TextField
                          size="small"
                          value={newSupplierSku}
                          onChange={(e) => setNewSupplierSku(e.target.value)}
                          sx={{
                            mr: 0.5,
                            width: "100%",
                            fontSize: "0.8rem",
                            "& legend": {
                              width: "unset",
                            },
                            "& .MuiFormControl-root, .MuiOutlinedInput-root": {
                              p: "0px!important",
                              minHeight: 26,
                            },
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={2}
                        justifyContent="center"
                        alignItems="center"
                        pl={1}
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={handleUpdateSupplierSku}
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>

                  </Grid>

                  <Grid item container py={1}>

                    <Grid item xs={4}>
                      <Typography variant="body1">Supplier</Typography>
                    </Grid>

                    {/* <Grid item xs={8}>
                    {supplier}
                  </Grid> */}

                    <Grid item container xs={8}>
                      <Grid item xs={10}>
                        <FormControl fullWidth size="small">
                          <InputLabel
                            id="supplier-select-label"
                            sx={{
                              top: () =>
                                supplier === null || supplier === ""
                                  ? "-2px"
                                  : "2px",
                            }}
                          >
                            Supplier
                          </InputLabel>
                          <Select
                            label="Supplier"
                            value={newSupplier}
                            labelId="supplier-select-label"
                            onChange={(e) => setNewSupplier(e.target.value)}
                          >
                            {suppliers.map((supplier) => (
                              <MenuItem key={supplier} value={supplier}>
                                <Typography variant="body2">
                                  {supplier.charAt(0).toUpperCase() + supplier.slice(1)}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid
                        item
                        xs={2}
                        justifyContent="center"
                        alignItems="center"
                        pl={1}
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={handleUpdateSupplier}
                        >
                          Update
                        </Button>
                      </Grid>

                    </Grid>
                  </Grid>



                  {supplier === "aliexpress" && <>
                    <Grid item container py={1}>
                      <Grid item xs={4}>
                        <Typography variant="body1">Store Name</Typography>
                      </Grid>

                      <Grid item xs={8}>
                        {storeName}
                      </Grid>
                    </Grid>
                  </>}
                  { <>
                    <Grid item container py={1}>
                      <Grid item xs={4}>
                        <Typography variant="body1">Shopify Stock</Typography>
                      </Grid>

                      <Grid item xs={8}>
                        {shopifyStock}
                      </Grid>
                    </Grid>
                  </>}
                  {<>
                    <Grid item container py={1}>
                      <Grid item xs={4}>
                        <Typography variant="body1">New Stock</Typography>
                      </Grid>

                      <Grid item xs={8}>
                        {newStock}
                      </Grid>
                    </Grid>
                  </>}
                  {supplier === "aliexpress" && <>
                    <Grid item container py={1}>
                      <Grid item xs={4}>
                        <Typography variant="body1">Product Status</Typography>
                      </Grid>

                      <Grid item xs={8}>
                        {aliproductstatus}
                      </Grid>
                    </Grid>
                  </>}
                  { <>
                    <Grid item container py={1}>

                      <Grid item xs={4}>
                        <Typography variant="body1">Last Updated</Typography>
                      </Grid>

                      <Grid item xs={8}>
                        {moment(update).format("DD/MM/YYYY")}
                      </Grid>
                    </Grid>
                  </>}
                </Grid>
              )}

              {searchBy === "supplierSku" &&
                supplierSku !== "" &&
                Array.isArray(skuArray) &&
                skuArray.length !== 0 && (
                  <Grid container item>
                  
                  <Grid item container py={1}>

                    <Grid item xs={4}>
                      <Typography variant="body1">SKU</Typography>
                    </Grid>

                    <Grid item xs={8}>
                      {skuArray.map((item, index) => (
                        <Typography key={index} variant="body1">
                          {item.sku}
                        </Typography>
                      ))}
                    </Grid>

                    </Grid>

                    <Grid item container py={1}>

                    <Grid item xs={4}>
                      <Typography variant="body1">Supplier</Typography>
                    </Grid>

                    <Grid item xs={8}>
                      {skuArray.map((item, index) => (
                        <Typography key={index} variant="body1">
                          {item.supplier}
                        </Typography>
                      ))}
                    </Grid>

                    </Grid>


                    <Grid item container py={1}>

                    <Grid item xs={4}>
                      <Typography variant="body1">Store Name</Typography>
                    </Grid>

                    <Grid item xs={8}>
                      {skuArray.map((item, index) => (
                        <Typography key={index} variant="body1">
                          {item.ali_storename}
                        </Typography>
                      ))}
                    </Grid>

                    </Grid>

                    <Grid item container py={1}>

                    <Grid item xs={4}>
                      <Typography variant="body1">Shopify Stock</Typography>
                    </Grid>
                    
                     <Grid item xs={8}>
                        {shopifyStock}
                      </Grid>
                    </Grid>

                      <Grid item container py={1}>
                      <Grid item xs={4}>
                      <Typography variant="body1">New Stock</Typography>
                    </Grid>
                    
                     <Grid item xs={8}>
                        {newStock}
                      </Grid>
                      </Grid>

                      <Grid item container py={1}>
                      <Grid item xs={4}>
                      <Typography variant="body1">Product Status</Typography>
                    </Grid>
                    
                     <Grid item xs={8}>
                        {aliproductstatus}
                      </Grid>
                      </Grid>
                    
                      <Grid item container py={1}>
                      <Grid item xs={4}>
                      <Typography variant="body1">Last Updated</Typography>
                    </Grid>
                    
                     <Grid item xs={8}>
                        {moment(update).format("DD/MM/YYYY")}
                      </Grid>
                     </Grid>

                  </Grid>
                    

                )}
            </Grid>
          )}
        </Grid>
      </Container>
    </Grid>
  );
};
export default SkuChecker;
