import React, { useState, useEffect } from "react";
import moment from "moment";
import no_image from "../assets/images/no_image.jpg";
import { Card, Grid, Box, Tooltip, Typography } from "@mui/material";
import ControlledCheckbox from "./checkbox.js";

export default function SerranoProductsLayout({
  product,
  checkboxID,
  handleImport,
  selectedProducts,
  setSelectedProducts,
  fetchproducts,
}) {
  const formatDate = (date) => moment(date).format("DD/MM/YYYY HH:mm:ss");

  return (
    <Card sx={{ width: "100%", mt: 1 }}>
      <Grid
        container
        sx={{
          minHeight: "70px",
          display: "Grid",
          gridTemplateColumns: "2% 4% 35% 8% 8% 10% 8% 5% 5% 5% 6% 4%",
          alignItems: "center",
        }}
      >
        {/* Checkbox */}
        <Grid container justifyContent="center">
          <ControlledCheckbox
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            product={product}
            checkboxID={checkboxID}
          />
        </Grid>

        {/* Image */}
        <Grid container justifyContent="center" alignItems="center" p={0.5}>
          <Tooltip
            arrow
            placement="top"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  padding: 0,
                },
              },
            }}
            title={
              <img
                src={product.image ? product.image : no_image}
                alt=""
                style={{
                  width: "250px",
                }}
              />
            }
          >
            <Grid
              item
              justifyContent="center"
              alignContent="center"
              sx={{
                minHeight: "70px",
                border: (theme) => `1px solid ${theme.palette.border}`,
              }}
            >
              <img
                src={product.image ? product.image : no_image}
                alt=""
                width="60px"
              />
            </Grid>
          </Tooltip>
        </Grid>

        {/* Title & Product ID */}
        <Grid container flexDirection="column" pl={1}>
          {product.name && product.name.length > 90 ? (
            <Tooltip arrow placement="top" title={product.name}>
              <Typography variant="body2">
                {`${product.name.slice(0, 90)}...`}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="body2">{product.name}</Typography>
          )}

          <Grid item>
            <Typography
              component="a"
              target="_blank"
              href={product.url}
              variant="body2"
              color="primary"
            >
              Product ID: {product.id}
            </Typography>
          </Grid>
        </Grid>

        {/* Barcode */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.eancode === undefined ||
              product.eancode === null ||
              product.eancode === ""
              ? "N/A"
              : product.eancode}
          </Typography>
        </Grid>

        {/* Weight */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.weight === undefined ||
              product.weight === null ||
              product.weight === ""
              ? "N/A"
              : `${parseFloat(product.weight).toFixed(2)}`}
          </Typography>
        </Grid>

        {/* Cost */}
        <Grid container justifyContent="center">
          <Typography variant="body2">{product.member_price}</Typography>
        </Grid>

        {/* Shipping Cost */}
        <Grid container justifyContent="center">
          <Typography variant="body2">

            {product.zone_rates && product.zone_rates.nsw_m
              ? product.zone_rates.nsw_m
              : 0}
          </Typography>
        </Grid>

        {/* Variation */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {Array.isArray(product.variants) ? product.variants.length : 0}
          </Typography>
        </Grid>

        {/* Stock */}
        <Grid container justifyContent="center">
          <Typography variant="body2">{product.stock}</Typography>
        </Grid>

        {/* Supplier */}
        <Grid container justifyContent="center">
          <Typography variant="body2">Sennaro</Typography>
        </Grid>

        {/* Created At */}
        <Grid container justifyContent="center">
          <Typography variant="body2" textAlign="center">
            {formatDate(product.createdAt)}
          </Typography>
        </Grid>

        {/* <div style={{ textAlign: "center" }}>
          {product.import_status === null && "Not Imported"}
          {product.import_status === "imported" && "Imported"}
          {product.import_status === "exported" && "Exported"}
          {product.import_status === "deleted" && "Deleted"}
        </div> */}
        {
          // <div style={{textAlign:"center"}}>
          //   <Typography style={{color:"#5b5b5e"}}><Button variant="contained" size="small" onClick={(event) => handleImport(product)}>Import</Button></Typography>
          // </div>
        }
      </Grid>
    </Card>
  );
}
