import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Axios from "axios"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip'
import {useSelector, useDispatch} from "react-redux"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  height:500,
  overflow:"scroll",
  fontSize:"15px"
};


const columns = [
  {
    field: 'sku',
    headerName: 'SKU',
    width: 150,
    editable: true,
  },
  {
    field: 'shopifystock',
    headerName: 'Shopify Stock',
    width: 150,
    editable: true,
  },
  {
    field: 'newstock',
    headerName: 'New Stock',
    width: 110,
    editable: true,
  }
]


export function Row({item}) {

  const [editvariantid, setEditVariantID] = useState(false)
  const [variantid, setVariantID] = useState("")
  const dispatch = useDispatch()
  const store = useSelector(state => state.store)
  let date = null
  if(item.newstock_updated){
    date = new Date(item.newstock_updated)
  }
  const handleSave = (event) => {
    let id = event.target.id

    if(id === "variantid" && event.key === "Enter"){
      let obj = {sku:item.sku, variantid:event.target.value, store:store}
      console.log(obj)
      Axios.post(`https://api.saleprodev.com/inventoryerror/editvariantid`, obj).then(response => {
        if(response.data[0] === 1){
          alert("Success")
        } else {
          if(response.data.status !== 200){
            console.log(response)
            alert("Failed")
          }
        }

      })
      setEditVariantID(false)
    }

  }

  const handleDelete = (sku) => {
    let obj = {sku:item.sku,store:store}
    Axios.post(`https://api.saleprodev.com/inventoryerror/deletesku`, obj).then(response => {
      if(response.data === 1){
        alert("Success")
      }
    })
  }
  return (
    <TableRow>
    <TableCell>
      <Tooltip title={<div style={{cursor:"pointer"}} onClick={() => handleDelete(item.sku)}>Delete</div>}>
        <div>{item.sku}</div>
      </Tooltip>
    </TableCell>
    <TableCell align="center">
    {item.shopifystock}
    </TableCell>
    <TableCell align="center">
    {item.newstock}
    </TableCell>
    <TableCell align="center" >
      {item.id}
    </TableCell>
    <TableCell align="center" onDoubleClick={() => setEditVariantID(true)}>
      {editvariantid?(
        <input id="variantid" placeholder="variantid" type="text" onChange={(event) => setVariantID(event.target.value)} onKeyPress={(event) => handleSave(event)}/>
      ):(
        item.variantid
      )}
    </TableCell>
    <TableCell align="center">
      {item.inventory_item_id}
    </TableCell>
    <TableCell align="center">
    <div>{date && date.toLocaleString()}</div>
    </TableCell>
  </TableRow>
  )
}


export default function FixModal({open, setOpen, error, data}) {
  const dispatch = useDispatch()
  const [errors, setErrors] = useState([])
  const store = useSelector(state => state.store)
  useEffect(() => {
    setErrors([])
    if(open){
      Axios.post(`https://api.saleprodev.com/inventoryerror/errors`, {store:store, supplier:data.supplier}).then(response => {
        console.log(response)
        setErrors(response.data)
      })
    }

  },[open])

  const handleFix = () => {
    Axios.post(`https://api.saleprodev.com/inventoryerror/fix`, {errors:errors, store:store}).then(response => {
      console.log(response)
    })
  }

  const handleClose = () => setOpen(false);
  console.log(store)
  return (
    <div>
      <div style={{cursor:"pointer"}} onClick={() => setOpen(true)}>{error.toFixed(2)} %</div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{display:"grid", gridTemplateColumns:"70% 30%"}}>
            <div>{data.supplier.toUpperCase()} ({errors.length})</div>
            <Button variant="contained" onClick={() => handleFix()}>FIX IT</Button>
          </div>
          <Table>
          <TableHead>
          <TableRow>
            <TableCell>SKU</TableCell>
            <TableCell align="center">Shopify Stock</TableCell>
            <TableCell align="center">New Stock</TableCell>
            <TableCell align="center">ID</TableCell>
            <TableCell align="center">Variant ID</TableCell>
            <TableCell align="center">Inventory ID</TableCell>
            <TableCell align="center">New Stock Updated</TableCell>
          </TableRow>
        </TableHead>
          {errors.map(item => {
            return (
              <Row item={item}/>
            )
          })}
        </Table>
        </Box>
      </Modal>
    </div>
  );
}
