import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Chip,
  TextField,
  Autocomplete,
  Box,
} from "@mui/material";
import BangoodVariantSelect from "./bangoodvariantselect.js";
import { SmallContainedButton } from "../common/SmallContainedButton";
import { InStockCheckbox } from "./instockcheckbox";

export default function SunSkySelect({
  selectedSunskyCategoryLevel1,
  setSelectedSunskyCategoryLevel1,
  selectedSunskyCategoryLevel2,
  setSelectedSunskyCategoryLevel2,
  selectedSunskyCategoryLevel3,
  setSelectedSunskyCategoryLevel3,
  selectedSunskyCategoryLevel4,
  setSelectedSunskyCategoryLevel4,
  skulist,
  setSkuList,
  filterby,
  loading_products,
  fetchproductcount,
  setFetchProductCount,
  setTags,
  setProductType,
}) {
  const [categorieslevel1, setCategoriesLevel1] = useState([]);
  const [categorieslevel2, setCategoriesLevel2] = useState([]);
  const [categorieslevel3, setCategoriesLevel3] = useState([]);
  const [categorieslevel4, setCategoriesLevel4] = useState([]);
  const [categorieslevel5, setCategoriesLevel5] = useState([]);
  const [disable, setdisable] = useState(true)

  const [brands, setBrands] = useState([]);
  useEffect(() => {
    setdisable(true)
    setSelectedSunskyCategoryLevel1({ id: null, name: "Select a Category" });
    fetch("https://api.saleprodev.com/productimporter/sunskycategorieslevel1")
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel1(response.categories_level_1);
      })
      .catch((err) => {
        console.log("errrrrror ", err);
      });
  }, []);

  useEffect(() => {
    setSelectedSunskyCategoryLevel2({ id: null, name: "Select a Category" });
    setSelectedSunskyCategoryLevel3({ id: null, name: "Select a Category" });
    setSelectedSunskyCategoryLevel4({ id: null, name: "Select a Category" });
    fetch("https://api.saleprodev.com/productimporter/sunskycategorieslevel2", {
      method: "post",
      body: JSON.stringify({ category: selectedSunskyCategoryLevel1.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel2(response.categories_level_2);
      })
      .catch((err) => {
        console.log("errrrrror ", err);
      });
  }, [selectedSunskyCategoryLevel1]);

  useEffect(() => {
    setSelectedSunskyCategoryLevel4({ id: null, name: "Select a Category" });
    setSelectedSunskyCategoryLevel3({ id: null, name: "Select a Category" });
    fetch("https://api.saleprodev.com/productimporter/sunskycategorieslevel3", {
      method: "post",
      body: JSON.stringify({ category: selectedSunskyCategoryLevel2.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel3(response.categories_level_3);
      })
      .catch((err) => {
        console.log("errrrrror ", err);
      });
  }, [selectedSunskyCategoryLevel2]);

  useEffect(() => {
    setSelectedSunskyCategoryLevel4({ id: null, name: "Select a Category" });
    fetch("https://api.saleprodev.com/productimporter/sunskycategorieslevel4", {
      method: "post",
      body: JSON.stringify({ category: selectedSunskyCategoryLevel3.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel4(response.categories_level_4);
      })
      .catch((err) => {
        console.log("errrrrror ", err);
      });
  }, [selectedSunskyCategoryLevel3]);

  const handleProductImport = () => {
    setdisable(true)
    let category = selectedSunskyCategoryLevel3?.id;
    if(!category)
      {
        category = selectedSunskyCategoryLevel2?.id
      }
    fetch("https://api.saleprodev.com/productimporter/importsunskyproducts", {
      method: "post",
      body: JSON.stringify({ category }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("response ", response)
        const msg = response?.success;
        alert(msg)
        setdisable(false)
      })
      .catch((err) => {
        console.log("errrrrror ", err);
        setdisable(false)
      });
  }

  const handleChange = (event, value) => {
    console.log("value", value);
    if (value === null) {
      setdisable(true)
      setSelectedSunskyCategoryLevel1({ id: null, name: "Select a Category" });
    } else {
      setSelectedSunskyCategoryLevel1(value);
    }
  };

  const handleChange2 = (event, value) => {
    console.log("value", value);
    if (value === null) {
      setdisable(true)
      setSelectedSunskyCategoryLevel2({ id: null, name: "Select a Category" });
    } else {
      setdisable(false)
      setSelectedSunskyCategoryLevel2(value);
    }
  };

  const handleChange3 = (event, value) => {
    console.log("value", value);
    if (value === null) {
      // setdisable(true)
      setSelectedSunskyCategoryLevel3({ id: null, name: "Select a Category" });
    } else {
      setdisable(false)
      setSelectedSunskyCategoryLevel3(value);
    }
  };

  const handleChange4 = (event, value) => {
    console.log("value", value);
    if (value === null) {
      setSelectedSunskyCategoryLevel4({ id: null, name: "Select a Category" });
    } else {
      setSelectedSunskyCategoryLevel4(value);
    }
  };

  const defaultProps1 = {
    options: categorieslevel1,
    getOptionLabel: (option) => option,
  };

  const defaultProps2 = {
    options: categorieslevel2,
    getOptionLabel: (option) => option,
  };

  const defaultProps3 = {
    options: categorieslevel3,
    getOptionLabel: (option) => option,
  };

  const defaultProps4 = {
    options: categorieslevel4,
    getOptionLabel: (option) => option,
  };
  

  return filterby === "category" ? (
    <>
      <Autocomplete
        {...defaultProps1}
        size="small"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        value={selectedSunskyCategoryLevel1.name}
        onChange={(event, value) => handleChange(event, value)}
        renderInput={(params) => <TextField {...params} />}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            {option.name}
          </Box>
        )}
      />
      <Autocomplete
        {...defaultProps2}
        size="small"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        value={selectedSunskyCategoryLevel2.name}
        onChange={(event, value) => handleChange2(event, value)}
        renderInput={(params) => <TextField {...params} />}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            {option.name}
          </Box>
        )}
      />
      <Autocomplete
        {...defaultProps3}
        size="small"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        value={selectedSunskyCategoryLevel3.name}
        onChange={(event, value) => handleChange3(event, value)}
        renderInput={(params) => <TextField {...params} />}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            {option.name}
          </Box>
        )}
      />

{/* <Autocomplete
        {...defaultProps4}
        size="small"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        value={selectedSunskyCategoryLevel4.name}
        onChange={(event, value) => handleChange4(event, value)}
        renderInput={(params) => <TextField {...params} />}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.name}
          </Box>
        )}
      /> */}
      <SmallContainedButton disabled={disable} width={140} onClick={handleProductImport}>
        import product
      </SmallContainedButton>

      <TextField
        size="small"
        placeholder="Tag"
        sx={{
          mr: 0.5,
          width: "110px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        onChange={(event) => setTags(event.target.value)}
      />

      <TextField
        size="small"
        placeholder="Type"
        sx={{
          mr: 0.5,
          width: "110px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        onChange={(event) => setProductType(event.target.value)}
      />

      <InStockCheckbox />
    </>
  ) : (
    <>
      <Grid item sx={{ position: "relative" }}>
        <TextField
          multiline
          maxRows={5}
          placeholder="Enter SKUs"
          // skulist={skulist}
          // setSkuList={setSkuList}
          onChange={(event) => setSkuList(event.target.value)}
          sx={{
            mr: 0.5,
            width: "500px",
            fontSize: "0.8rem",
            "& legend": {
              width: "unset",
            },
            "& .MuiFormControl-root, .MuiOutlinedInput-root": {
              minHeight: 26,
              p: "0px!important",
            },
          }}
        />

        {skulist === "" ? (
          ""
        ) : (
          <Chip
            size="small"
            color="primary"
            variant="outlined"
            label={`${skulist.split(/\r\n|\r|\n/).length} Items`}
            sx={{
              height: 20,
              position: "absolute",
              transform:
                skulist.split(/\r\n|\r|\n/).length <= 1
                  ? "translate(-95px, 3px)"
                  : "translate(-95px, 6px)",
            }}
          />
        )}
      </Grid>

      <TextField
        size="small"
        placeholder="Tag"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        onChange={(event) => setTags(event.target.value)}
      />

      <TextField
        size="small"
        placeholder="Type"
        onChange={(event) => setProductType(event.target.value)}
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
      />

      <SmallContainedButton
        width="60px"
        disabled={loading_products ? true : false}
        onClick={(event) => setFetchProductCount(fetchproductcount + 1)}
      >
        Find
      </SmallContainedButton>
    </>
  );
}
