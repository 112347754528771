import React, { useState, useEffect } from "react";

import {
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";

export default function VidaxlCarriers({
  vidaxlCarrier,
  setVidaxlCarrier,
  order,
}) {

  if (order && order.initial_carrier_name && order.initial_carrier_name !== null) {
    setVidaxlCarrier(order.initial_carrier_name)
  }

  const handleChange = (event) => {
    setVidaxlCarrier(event.target.value);
  };

  return (
    <Grid key={order.orderid} container flexDirection="column" py={1}>
      <FormControl sx={{ my: 1, minWidth: 150 }}>
        <InputLabel id="demo-select-small">Choose Carrier</InputLabel>
        <Select
          labelId="demo-select-small"
          id="demo-select-small"
          value={vidaxlCarrier}
          label="Choose Carrierrrrr"
          onChange={handleChange}
          size="small"
          sx={{
            height: 30,
            fontSize: "0.8rem",
          }}
        >
          <MenuItem value="ams">
            <Grid container justifyContent="space-between">
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                  AMS
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>

          <MenuItem value="AUSFF">
            <Grid container justifyContent="space-between">
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                  AUSFF
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
}
