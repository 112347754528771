import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {setSearch, setProductsPerPage, setImportPage, setImportTotalPages,setArticles,setSubsettings, setToken, setLoading,setCountrylist,setShippingMethods, setShowImportedProductsFilter,setCategoriesLevel1, setCategoriesLevel2,setCategoriesLevel3, setCategoriesLevel4,setCategoriesLevel5, setCategoriesLevel6,setImportedProducts,setImportedProductsFilter,setSelectedCategoryLevel2,setSelectedCategoryLevel3, setSelectedCategoryLevel4, setSelectedCategoryLevel5, setSelectedCategoryLevel6} from "../../actions"
import {useSelector, useDispatch} from "react-redux"
export default function BangoodSelectNZ({warehouse_bangood_nz, setWarehouseBangoodNZ, shippingmethod_bangood_nz, setShippingMethodBangoodNZ, tags, setTags, product_type, setProductType}) {

  const dispatch = useDispatch()
  const [categorieslevel1, setCategoriesLevel1] = useState([])
  const [categorieslevel2, setCategoriesLevel2] = useState([])
  const [categorieslevel3, setCategoriesLevel3] = useState([])
  const [categorieslevel4, setCategoriesLevel4] = useState([])
  const [categorieslevel5, setCategoriesLevel5] = useState([])

  const [brands, setBrands] = useState([])
  const warehouses = ["CN", "AU", "USA"]
  const [shippingmethods, setShippingMethods] = useState([])



  useEffect(() => {

    if(warehouse_bangood_nz === "CN"){
      setShippingMethods([{name:"nz_direct_mail", value:"LC_SHIPMENT_NZ_DIRECT_MAIL"},
      {name:"airmail_airmail", value:"LC_SHIPMENT_AIRMAIL_AIRMAIL"},
      {name:"standard_shipping", value:"LC_SHIPMENT_HKAIRMAIL_HKAIRMAIL"},
      {name:"banggood_priority_shipping", value:"LC_SHIPMENT_BANGGOOD_PRIORITY_SHIPPING"},
      {name:"banggood_expedited_shipping", value:"LC_SHIPMENT_BANGGOOD_EXPEDITED_SHIPPING"}
      ])
    }

    if(warehouse_bangood_nz === "AU"){
      setShippingMethods([
      {name:"nz_standard_shipping", value:"LC_SHIPMENT_NZ_STANDARD_SHIPPING"},
      {name:"au_standard_ship", value:"LC_SHIPMENT_AU_STANDARD_SHIP"},
      {name:"au_express_ship", value:"LC_SHIPMENT_AU_EXPRESS_SHIP"}
      ])
    }

    if(warehouse_bangood_nz === "USA"){
      setShippingMethods([
      {name:"us_express_ship", value:"LC_SHIPMENT_US_EXPRESS_SHIP"},
      {name:"us_standard_ship", value:"LC_SHIPMENT_US_STANDARD_SHIP"}
      ])
    }


  },[warehouse_bangood_nz])






  const handleChange = (event) => {
    console.log("value",event.target.value)
    setWarehouseBangoodNZ(event.target.value)

  };

  const handleChange2 = (event) => {
    console.log("value",event.target.value)
    setShippingMethodBangoodNZ(event.target.value)

  };






  return (
    <Box sx={{ width: 1200}}>

    <div style={{display:"grid", gridTemplateColumns:"20% 30% 30%", marginTop:"2%", marginLeft:"1%"}}>
      <FormControl style={{marginTop:"1%", marginLeft:"1%", marginBottom:"1%"}}>
        <InputLabel id="demo-simple-select-label">Warehouse</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={warehouse_bangood_nz}
          label="No"
          onChange={handleChange}
          style={{height:"30px", width:"150px"}}
        >

        {warehouses.map(item => {
          return (
            <MenuItem value={item}>{item}</MenuItem>
          )
        })}

        </Select>
      </FormControl>
      <FormControl style={{marginTop:"1%", marginLeft:"1%"}}>
        <InputLabel id="demo-simple-select-label">Shippind methods</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={shippingmethod_bangood_nz}
          label="No"
          onChange={handleChange2}
          style={{height:"30px", width:"300px"}}
        >

        {shippingmethods.map(item => {
          return (
            <MenuItem value={item.value}>{item.name}</MenuItem>
          )
        })}

        </Select>
      </FormControl>
      <div style={{display:"grid", gridTemplateColumns:"50% 50%", gridGap:"1%"}}>
      <input name="tags" type="text" placeholder="Enter Tag" value={tags} style={{marginTop:"1%"}} onChange={(event) => setTags(event.target.value)}/>
      <input name="product_type" type="text" placeholder="Enter Product Type" value={product_type} style={{marginTop:"1%"}} onChange={(event) => setProductType(event.target.value)}/>
      </div>
      </div>

    </Box>
  );
}
