import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import EditIcon from "@mui/icons-material/Edit";
import Select from "@mui/material/Select";
import PaginationOutlined_listings from "./pagination.js";
import ControlledCheckbox from "./checkbox.js";
import VariantSelect from "./variantselect.js";
import no_image from "../assets/images/no_image.jpg";

import {
  Button,
  Grid,
  Tooltip,
  Typography,
  Card,
  IconButton,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";

export default function Format({
  product,
  listingStatus,
  selectedProducts,
  setSelectedProducts,
}) {
  const products_per_page = useSelector((state) => state.products_per_page);
  const store = useSelector((state) => state.store);
  const [count, setCount] = React.useState(0);
  const dispatch = useDispatch();
  const [links, setLinks] = useState([]);
  const [products_nz_shopify, setProductsNZShopify] = useState([]);
  const [selectedvariant, setSelectedVariant] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);
  const import_page = useSelector((state) => state.import_page);
  const import_total_pages = useSelector((state) => state.import_total_pages);
  const selected_store = useSelector((state) => state.selected_store);

  const CalculatePrice = () => {
    let prices = [];
    if (Array.isArray(product.variants)) {
      product.variants.map((item) => {
        prices.push(parseFloat(item.price));
      });
      return `${Math.min(...prices)} - ${Math.max(...prices)}`;
    }
  };

  const CalculateStock = () => {
    let prices = [];
    if (Array.isArray(product.variants)) {
      product.variants.map((item) => {
        prices.push(parseFloat(item.inventory_quantity));
      });
      return `${Math.min(...prices)} - ${Math.max(...prices)}`;
    }
  };

  let shopify_sku_url = "";

  if (product) {
    if (store === 'nzshopify') {
      shopify_sku_url = `https://go-slash.myshopify.com/admin/products/${product.id}`
    }

    if (store === 'aushopify') {
      shopify_sku_url = `https://goslash-au.myshopify.com/admin/products/${product.id}`
    }

    if (store === 'usshopify') {
      shopify_sku_url = `https://goslash-us.myshopify.com/admin/products/${product.id}`
    }

    if (store === 'gkshopify') {
      shopify_sku_url = `https://gokinzo.myshopify.com/admin/products/${product.id}`
    }
  }

  return (
    <Card sx={{ width: "100%", mt: 1 }}>
      <Grid
        container
        sx={{
          display: "Grid",
          gridTemplateColumns: "2% 4% 34% 15% 15% 10% 10% 5% 5%",
          alignItems: "center",
        }}
      >
        {/* Checkbox */}
        <Grid container justifyContent="center">
          <ControlledCheckbox
            product={product}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
          />
        </Grid>

        {/* Image */}
        <Grid container justifyContent="center" alignItems="center" p={0.5}>
          <Tooltip
            arrow
            placement="top"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  padding: 0,
                },
              },
            }}
            title={
              <img
                src={
                  listingStatus === "deleted"
                    ? product.images && product.images[0]
                      ? product.images[0].src
                      : no_image
                    : product.image
                      ? product.image.src
                      : no_image
                }
                alt=""
                style={{
                  width: "250px",
                }}
              />
            }
          >
            <Grid
              item
              justifyContent="center"
              alignContent="center"
              sx={{ border: (theme) => `1px solid ${theme.palette.border}` }}
            >
              <img
                src={
                  listingStatus === "deleted"
                    ? product.images && product.images[0]
                      ? product.images[0].src
                      : no_image
                    : product.image
                      ? product.image.src
                      : no_image
                }
                alt=""
                width="60px"
              />
            </Grid>
          </Tooltip>
        </Grid>

        {/* Title & Sku # & Product ID */}
        <Grid container flexDirection="column" pl={1}>
          {product.title && product.title.length > 70 ? (
            <Tooltip arrow placement="top" title={product.title}>
              <Typography variant="body2">
                {`${product.title.slice(0, 70)}...`}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="body2">{product.title}</Typography>
          )}

          <Grid container>
            <Typography
              component="a"
              href={shopify_sku_url}
              target="_blank"
              variant="body2"
              color="primary"
            >
              Product ID: {product.id}
            </Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.variants.length}
          </Typography>
        </Grid>

        <Grid container justifyContent="center">
          <Typography variant="body2">
            NZ$ {CalculatePrice()}
          </Typography>
        </Grid>

        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.vendor}
          </Typography>
        </Grid>

        <Grid container justifyContent="center">
          <Typography variant="body2">
            {CalculateStock()}
          </Typography>
        </Grid>

        <Grid container justifyContent="center">
          <Typography variant="body2">
            {listingStatus === "deleted"
              ? product.export_status_nz
              : product.status}
          </Typography>
        </Grid>

        <Grid container justifyContent="center">
          <IconButton
            onClick={(event) => setOpen(true)}
          >
            <EditIcon fontSize="30px" />
          </IconButton>
        </Grid>

      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ marginTop: "5%", marginLeft: "20%", marginRight: "20%" }}
      >
        <Card>
          <VariantSelect product={product} />
        </Card>
      </Modal>
    </Card >
  );
}
