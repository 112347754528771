import React, { useState } from "react";
import { SmallContainedButton } from "../common/SmallContainedButton";

import { Typography, TextField, Grid } from "@mui/material";

const PriceFilter = ({ prices, setPrices, handleFilterByPrice, handleClearPrice }) => {
  const handleChange = (e) => {
    setPrices({ ...prices, [e.target.name]: e.target.value });
  };

  console.log("prices", prices)

  return (
    <>
      <Typography variant="body2" sx={{ mx: 0.5 }}>
        Price:
      </Typography>
      <TextField
        size="small"
        label="Min"
        name="minPrice"
        value={prices.minPrice}
        onChange={handleChange}
        sx={{ width: "54px" }}
        InputProps={{
          sx: {
            height: 26,
            fontSize: "0.8rem",
          },
        }}
        InputLabelProps={{
          sx: {
            fontSize: "0.8rem",
            top: () =>
              prices.minPrice === null || prices.minPrice === ""
                ? "-4px"
                : "2px",
            "&.Mui-focused": {
              transform: () =>
                prices.minPrice === null || prices.minPrice === ""
                  ? "translate(14px, -4px) scale(0.8)"
                  : "translate(14px, -8px) scale(0.8)",
            },
          },
        }}
      />
      <Typography variant="body2" sx={{ mx: 0.5 }}>
        -
      </Typography>
      <TextField
        size="small"
        label="Max"
        name="maxPrice"
        value={prices.maxPrice}
        onChange={handleChange}
        sx={{ width: "54px", mr: 1 }}
        InputProps={{
          sx: {
            height: 26,
            fontSize: "0.8rem",
          },
        }}
        InputLabelProps={{
          sx: {
            fontSize: "0.8rem",
            top: () =>
              prices.maxPrice === null || prices.maxPrice === ""
                ? "-4px"
                : "2px",
            "&.Mui-focused": {
              transform: () =>
                prices.maxPrice === null || prices.maxPrice === ""
                  ? "translate(14px, -4px) scale(0.8)"
                  : "translate(14px, -8px) scale(0.8)",
            },
          },
        }}
      />

      <SmallContainedButton
        disabled={
          ((prices.minPrice === null ||
            prices.minPrice === "") &&
            (prices.maxPrice === null ||
              prices.maxPrice === "")) ||
          parseFloat(prices.minPrice) > parseFloat(prices.maxPrice)
        }
        width="50px"
        onClick={handleFilterByPrice}>
        OK
      </SmallContainedButton>

      <SmallContainedButton
        disabled={
          ((prices.minPrice === null ||
            prices.minPrice === "") &&
            (prices.maxPrice === null ||
              prices.maxPrice === ""))
        }
        width="50px"
        onClick={handleClearPrice}>
        Clear
      </SmallContainedButton>
    </>
  );
};

export default PriceFilter;
