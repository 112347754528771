import React, {useState} from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function ShippingMethodSelect({shipfrom,shipto, value, handleChange}) {
  const list_CN_NZ = ["LC_SHIPMENT_NZ_DIRECT_MAIL", "LC_SHIPMENT_AIRMAIL_AIRMAIL"]
  const list_CN_AU = ["LC_SHIPMENT_STANDARD_MAIL_REGISTER", "LC_SHIPMENT_AU_DIRECT_MAIL", "LC_SHIPMENT_PRIORITY_MAIL", "LC_SHIPMENT_EXPRESS_SHIPPING", "LC_SHIPMENT_BANGGOOD_PRIORITY_SHIPPING", "LC_SHIPMENT_BANGGOOD_EXPEDITED_SHIPPING"]
  const list_CN_US = ["LC_SHIPMENT_BANGGOOD_EXPRESS", "LC_SHIPMENT_AIRMAIL_AIRMAIL", "LC_SHIPMENT_EXPRESS_SHIPPING"]
  const list_CN_UK = ["LC_SHIPMENT_BANGGOOD_EXPRESS"]
  const list_US_US = ["LC_SHIPMENT_US_STANDARD_SHIP", "LC_SHIPMENT_US_EXPRESS_SHIP"]
  const list_US_CAN = ["LC_SHIPMENT_US_INTER_SHIP"]
  const list_US_AUS = ["LC_SHIPMENT_US_INTER_SHIP"]
  const list_AU_AU = ["LC_SHIPMENT_AU_STANDARD_SHIP","LC_SHIPMENT_AU_EXPRESS_SHIP"]
  const list_AU_NZ = ["LC_SHIPMENT_NZ_STANDARD_SHIPPING","LC_SHIPMENT_NZ_STANDARD_SHIPPING_REGISTER"]
  const list_UK_UK = ["LC_SHIPMENT_NZ_STANDARD_SHIPPING","LC_SHIPMENT_NZ_STANDARD_SHIPPING_REGISTER"]

  const list = () => {
    switch(shipfrom + " " + shipto){
      case "CN New Zealand":
        return list_CN_NZ
      case "CN Australia":
          return list_CN_AU
      case "CN United States":
          return list_CN_US
      case "CN United Kingdom":
          return list_CN_UK
      case "USA United States":
          return list_US_US
      case "USA Canada":
          return list_US_CAN
      case "USA Australia":
          return list_US_AUS
      case "AU Australia":
          return list_AU_AU
      case "AU New Zealand":
          return list_AU_NZ
      case "UK United Kingdom":
          return list_UK_UK
      default:
        return []
    }
  }

  return (
    <Box sx={{ minWidth: 250,height:30, }}>

    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      label="Age"
      onChange={handleChange}
      style={{width:"250px", height:"30px"}}>
        {list().map(item => {
            return (
              <MenuItem value={item}>{item}</MenuItem>
            )
          })
        }
        </Select>

    </Box>
  );
}
