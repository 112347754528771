import React from "react";
import { Grid, Typography, Tooltip } from "@mui/material";
import ContentCopy from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import { SmallContainedButton } from "../common/SmallContainedButton";

export const SupplierOrderNumber = ({
  order,
  supplier_order_no,
  supplier_order_link,
  editSupplierOrderNo,
  setEditSupplierOrderNo,
  handleSaveSupplierOrderNo,
  handleCopyText,
}) => (
  <Grid container alignItems="center">
    <Grid item xs={3.2}>
      <Typography variant="body2">Supplier Order:</Typography>
    </Grid>

    <Grid item container alignContent="center" xs={8.8}>
      {editSupplierOrderNo ? (
        <>
          #
          <input
            type="text"
            // value={supplierOrderNo}
            // onChange={handleChangeSupplierOrderNo}
            placeholder={
              supplier_order_no === null
                ? "Add Supplier Order #"
                : supplier_order_no
            }
            onKeyPress={(event) => handleSaveSupplierOrderNo(event, order)}
            style={{ width: "180px", height: "24px", fontSize: "14px" }}
          />
          {supplier_order_no !== null && (
            <ContentCopy
              fontSize="20px"
              sx={{ cursor: "pointer" }}
              onClick={() => handleCopyText(supplier_order_no)}
            />
          )}
          <SmallContainedButton
            width={50}
            variant="text"
            onClick={() => setEditSupplierOrderNo(!editSupplierOrderNo)}
          >
            Cancel
          </SmallContainedButton>
        </>
      ) : supplier_order_no === "" || supplier_order_no === null ? (
        <Typography
          variant="body2"
          color="primary"
          sx={{ cursor: "pointer" }}
          onClick={() => setEditSupplierOrderNo(!editSupplierOrderNo)}
        >
          +Add
        </Typography>
      ) : (
        <Grid container justifyContent="space-between">
          <Tooltip arrow placement="top" title={supplier_order_no}>
            <Typography
              variant="body2"
              component="a"
              href={supplier_order_link}
              target="_blank"
              sx={{
                textDecoration: "none",
                display: "grid",
                alignContent: "center",
              }}
            >
              #
              {supplier_order_no.length < 11
                ? supplier_order_no
                : supplier_order_no.slice(0, 11) + "..."}
            </Typography>
          </Tooltip>

          <Grid item>
            <Tooltip arrow placement="top" title="Edit Supplier Order #">
              <EditIcon
                fontSize="20px"
                sx={{ mx: 0.5, cursor: "pointer" }}
                onClick={() => setEditSupplierOrderNo(!editSupplierOrderNo)}
              />
            </Tooltip>
            <ContentCopy
              fontSize="20px"
              sx={{ cursor: "pointer" }}
              onClick={() => handleCopyText(supplier_order_no)}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  </Grid>
);
