import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NativePickers from "../sourcing/select";

import { Card, Typography, Grid } from "@mui/material";

import { setStore, setSelectedSupplier } from "../../actions";

export const ListingsQuickFilter = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.store);
  const supplier_menu = useSelector((state) => state.supplier_menu);
  const selected_supplier = useSelector((state) => state.selected_supplier);

  const handleSelectSupplier = (supplier) => {
    dispatch(setSelectedSupplier(supplier));
    window.sessionStorage.setItem("selected_supplier", supplier);
  };

  const handleSelectStore = (store) => {
    dispatch(setStore(store));
    window.sessionStorage.setItem("store", store);
  };

  const handleToken = () => {
    fetch("https://api.saleprodev.com/fetchtoken");
  };

  return (
    <Card elevation={0} sx={{ width: "100%", px: 2, py: 1.2 }}>
      <Grid container pb={0.2}>
        <Typography variant="body1" sx={{ fontWeight: 700 }}>
          Quick Filter
        </Typography>
      </Grid>

      <Grid container alignItems="center" my={1.2}>
        <Grid item xs={1}>
          <Typography variant="body2">Suppliers</Typography>
        </Grid>

        <Grid
          item
          container
          xs={11}
          sx={{
            "& .MuiTypography-root": {
              cursor: "pointer",
            },
          }}
        >
          <Grid item mx={1.2}>
            {selected_supplier === "" ? (
              <Typography variant="body2" color="primary">
                All
              </Typography>
            ) : (
              <Typography
                variant="body2"
                color="disabled"
              // onClick={() => handleSelectSupplier("")}
              >
                All
              </Typography>
            )}
          </Grid>

          {supplier_menu.map((supplier, index) => (
            <Grid item key={index} mx={1.2}>
              {selected_supplier === supplier.value ? (
                <Typography variant="body2" color="primary">
                  {supplier.name}
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  onClick={() => {
                    // handleSelectSupplier(supplier.value);
                    // index === 0 && handleToken();
                    if (supplier.value !== 'ingrammicro' && supplier.value !== 'griffati') {
                      handleSelectSupplier(supplier.value);
                      index === 0 && handleToken();
                    }
                  }}
                  style={{
                    cursor: supplier.value === 'ingrammicro' || supplier.value === 'griffati' ? 'not-allowed' : 'pointer',
                    color: supplier.value === 'ingrammicro' || supplier.value === 'griffati' ? 'gray' : 'inherit',
                  }}
                >
                  {supplier.name}
                </Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/* Temporarily Hidden */}
      {/* <Grid container alignItems="center" my={1.2}>
        <Grid item xs={1}>
          <Typography variant="body2">Website</Typography>
        </Grid>

        <Grid
          item
          container
          xs={11}
          sx={{
            "& .MuiTypography-root": {
              cursor: "pointer",
            },
          }}
        >
          <Grid item mx={1.2}>
            <Typography variant="body2" color="disabled">
              All
            </Typography>
          </Grid>

          <Grid item mx={1}>
            <Typography variant="body2" color="disabled">
              Global
            </Typography>
          </Grid>

          <Grid item mx={1.2}>
            <Typography variant="body2" color="disabled">
              AU
            </Typography>
          </Grid>

          <Grid item mx={1.3}>
            <Typography variant="body2" color="disabled">
              CN
            </Typography>
          </Grid>

          <Grid item mx={1.3}>
            <Typography variant="body2" color="disabled">
              NZ
            </Typography>
          </Grid>
        </Grid>
      </Grid> */}

      {/* Temporarily Hidden */}
      {/* <Grid container alignItems="center" my={1.2}>
        <Grid item xs={1}>
          <Typography variant="body2">Warehouse</Typography>
        </Grid>

        <Grid
          item
          container
          xs={11}
          sx={{
            "& .MuiTypography-root": {
              cursor: "pointer",
            },
          }}
        >
          <Grid item mx={1.2}>
            <Typography variant="body2" color="disabled">
              All
            </Typography>
          </Grid>

          <Grid item mx={1.2}>
            <Typography variant="body2" color="disabled">
              AU
            </Typography>
          </Grid>

          <Grid item mx={1.3}>
            <Typography variant="body2" color="disabled">
              CN
            </Typography>
          </Grid>

          <Grid item mx={1.3}>
            <Typography variant="body2" color="disabled">
              NZ
            </Typography>
          </Grid>
        </Grid>
      </Grid> */}

      <Grid
        sx={{
          borderBottom: (theme) => `1px dashed ${theme.palette.border}`,
        }}
      ></Grid>

      <Grid container alignItems="center" my={1.2}>
        <Grid item xs={1}>
          <Typography variant="body2">My Stores</Typography>
        </Grid>

        <Grid
          item
          container
          xs={11}
          sx={{
            "& .MuiTypography-root": {
              cursor: "pointer",
            },
          }}
        >
          <Grid item mx={1.2}>
            {store === "all" ? (
              <Typography variant="body2" color="primary">
                All
              </Typography>
            ) : (
              <Typography
                variant="body2"
                color="disabled"
              //
              // onClick={() => handleSelectStore("all")}
              >
                All
              </Typography>
            )}
          </Grid>

          <Grid item mx={1}>
            {store === "nzshopify" ? (
              <Typography variant="body2" color="primary">
                NZ Shopify
              </Typography>
            ) : (
              <Typography
                variant="body2"
                onClick={() => handleSelectStore("nzshopify")}
              >
                NZ Shopify
              </Typography>
            )}
          </Grid>

          <Grid item mx={0.8}>
            {store === "aushopify" ? (
              <Typography variant="body2" color="primary">
                AU Shopify
              </Typography>
            ) : (
              <Typography
                variant="body2"
                onClick={() => handleSelectStore("aushopify")}
              >
                AU Shopify
              </Typography>
            )}
          </Grid>

          <Grid item mx={1}>
            {store === "usshopify" ? (
              <Typography variant="body2" color="primary">
                US Shopify
              </Typography>
            ) : (
              <Typography
                variant="body2"
                onClick={() => handleSelectStore("usshopify")}
              >
                US Shopify
              </Typography>
            )}
          </Grid>

          <Grid item mx={1}>
            {store === "gkshopify" ? (
              <Typography variant="body2" color="primary">
                GK Shopify
              </Typography>
            ) : (
              <Typography
                variant="body2"
                onClick={() => handleSelectStore("gkshopify")}
              >
                GK Shopify
              </Typography>
            )}
          </Grid>

          <Grid item mx={1}>
            {store === "kogan" ? (
              <Typography variant="body2" color="primary">
                Kogan
              </Typography>
            ) : (
              <Typography
                variant="body2"
                onClick={() => handleSelectStore("kogan")}
              >
                Kogan
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* Temporarily Hidden */}
      {/* <Grid container alignItems="center" my={1.2}>
        <Grid item xs={1}>
          <Typography variant="body2">Countries</Typography>
        </Grid>

        <Grid
          item
          container
          xs={11}
          sx={{
            "& .MuiTypography-root": {
              cursor: "pointer",
            },
          }}
        >
          <Grid item mx={1.2}>
            <Typography variant="body2" color="disabled">
              All
            </Typography>
          </Grid>

          <Grid item mx={1}>
            <Typography variant="body2" color="disabled">
              Australia
            </Typography>
          </Grid>

          <Grid item mx={2.3}>
            <Typography variant="body2" color="disabled">
              China
            </Typography>
          </Grid>

          <Grid item mx={1.2}>
            <Typography variant="body2" color="disabled">
              UK
            </Typography>
          </Grid>

          <Grid item mx={1.2}>
            <Typography variant="body2" color="disabled">
              New Zealand
            </Typography>
          </Grid>

          <Grid item mx={1.2}>
            <Typography variant="body2" color="disabled">
              United States
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container alignItems="center" my={1.2}>
        <Grid item xs={1}>
          <Typography variant="body2">Product Type</Typography>
        </Grid>

        <Grid
          item
          container
          xs={11}
          sx={{
            "& .MuiTypography-root": {
              cursor: "pointer",
            },
          }}
        >
          <Grid item mx={1.2}>
            <Typography variant="body2" color="disabled">
              All
            </Typography>
          </Grid>

          <Grid item mx={1}>
            <Typography variant="body2" color="disabled">
              Standalone
            </Typography>
          </Grid>

          <Grid item mx={2.3}>
            <Typography variant="body2" color="disabled">
              Has Variations
            </Typography>
          </Grid>
        </Grid>
      </Grid> */}

      <Grid
        sx={{
          borderBottom: (theme) => `1px dashed ${theme.palette.border}`,
        }}
      ></Grid>

      <Grid container alignItems="center" my={1.2}>
        <Grid item xs={1}>
          <Typography variant="body2">Imported Date</Typography>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          xs={11}
          sx={{
            "& .MuiTypography-root": {
              cursor: "pointer",
            },
          }}
        >
          <Grid item mx={1.2}>
            <Typography variant="body2" color="disabled">
              All
            </Typography>
          </Grid>

          <Grid item mx={1}>
            <Typography variant="body2" color="disabled">
              Today
            </Typography>
          </Grid>

          <Grid item mx={1.2}>
            <Typography variant="body2" color="disabled">
              7 Days
            </Typography>
          </Grid>

          <Grid item mx={1.2}>
            <Typography variant="body2" color="disabled">
              30 Days
            </Typography>
          </Grid>

          <NativePickers />
        </Grid>
      </Grid>
    </Card>
  );
};
