import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCheckedHasVideo } from "../../actions";
import { Typography, Checkbox } from "@mui/material";

export const HasVideoCheckbox = () => {
  const dispatch = useDispatch();
  const checkedHasVideo = useSelector((state) => state.checkedHasVideo);

  const handleChange = (event) => {
    dispatch(setCheckedHasVideo(event.target.checked));
  };

  return (
    <>
      <Checkbox
        checked={checkedHasVideo}
        onChange={handleChange}
        disableRipple
        sx={{ p: 0 }}
      />
      <Typography variant="body2" sx={{ mr: 0.5 }}>
        Video
      </Typography>
    </>
  );
};
