import React, { useState, useEffect } from "react";
import moment from "moment";
import no_image from "../assets/images/no_image.jpg";
import { Card, Grid, Tooltip, Typography } from "@mui/material";

import ControlledCheckbox from "./bangoodcheckbox.js";

export default function DropshipProductsLayout({
  product,
  handleImport,
  selectedProducts,
  setSelectedProducts,
  fetchproducts,
}) {
  console.log("selectedProducts", selectedProducts);

  const formatDate = (date) => moment(date).format("DD/MM/YYYY HH:mm:ss");

  return (
    <Card sx={{ width: "100%", mt: 1 }}>
      <Grid
        container
        sx={{
          minHeight: "70px",
          display: "Grid",
          gridTemplateColumns: "2% 4% 35% 8% 8% 10% 8% 5% 5% 5% 6% 4%",
          alignItems: "center",
        }}
      >
        {/* Checkbox */}
        <Grid container justifyContent="center">
          <ControlledCheckbox
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            product={product}
          />
        </Grid>

        {/* Image */}
        <Grid container justifyContent="center" alignItems="center" p={0.5}>
          <Tooltip
            arrow
            placement="top"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  padding: 0,
                },
              },
            }}
            title={
              <img
                src={
                  product.image_list
                    ? typeof product.image_list.view === "string"
                      ? product.image_list.view
                      : product.image_list.view["1"]
                    : no_image
                }
                alt=""
                style={{
                  width: "250px",
                }}
              />
            }
          >
            <Grid
              item
              justifyContent="center"
              alignContent="center"
              sx={{
                minHeight: "60px",
                border: (theme) => `1px solid ${theme.palette.border}`,
              }}
            >
              <img
                src={
                  product.image_list
                    ? typeof product.image_list.view === "string"
                      ? product.image_list.view
                      : product.image_list.view["1"]
                    : no_image
                }
                alt=""
                width="60px"
              />
            </Grid>
          </Tooltip>
        </Grid>

        {/* Title & Product ID */}
        <Grid container flexDirection="column" pl={1}>
          {product.product_name && product.product_name.length > 90 ? (
            <Tooltip arrow placement="top" title={product.product_name}>
              <Typography variant="body2">
                {`${product.product_name.slice(0, 90)}...`}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="body2">{product.product_name}</Typography>
          )}

          <Grid container>
            <Typography
              component="a"
              href={`https://nz.banggood.com/search/${product.product_id}.html`}
              target="_blank"
              variant="body2"
              color="primary"
            >
              Product ID: {product.product_id}
            </Typography>
          </Grid>
        </Grid>

        {/* Barcode */}
        <Grid container justifyContent="center">
          <Typography variant="body2">N/A</Typography>
        </Grid>

        {/* Weight */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.weight === null || product.weight === ""
              ? "N/A"
              : `${product.weight}kg`}
          </Typography>
        </Grid>

        {/* Cost */}
        <Grid container justifyContent="center">
          {product.warehouse_list && product.warehouse_list.length > 2 ? (
            <Tooltip
              arrow
              placement="top"
              PopperProps={{
                sx: {
                  "& .MuiTooltip-tooltip": {
                    padding: 0,
                  },
                },
              }}
              title={
                <Grid container flexDirection="column" p={1}>
                  {product.warehouse_list &&
                    product.warehouse_list.map((item, index) => (
                      <Typography key={index} variant="body2">
                        {item.warehouse} {item.warehouse_price}
                      </Typography>
                    ))}
                </Grid>
              }
            >
              <Grid container flexDirection="column" alignItems="center">
                {product.warehouse_list &&
                  product.warehouse_list.slice(0, 2).map((item, index) => (
                    <Typography key={index} variant="body2">
                      {item.warehouse} {item.warehouse_price}
                    </Typography>
                  ))}
                <Typography variant="body2">...</Typography>
              </Grid>
            </Tooltip>
          ) : (
            <Grid container flexDirection="column" alignItems="center">
              {product.warehouse_list &&
                product.warehouse_list.map((item, index) => (
                  <Typography key={index} variant="body2">
                    {item.warehouse} {item.warehouse_price}
                  </Typography>
                ))}
            </Grid>
          )}
        </Grid>

        {/* Shipping Cost */}
        <Grid container justifyContent="center">
          <Typography variant="body2">N/A</Typography>
        </Grid>

        {/* Variation */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.poa_list && product.poa_list.length}
          </Typography>
        </Grid>

        <Grid container justifyContent="center">
          {product.stock &&
            Array.isArray(product.stock) &&
            product.stock.map((item) => {
              return (
                <Grid
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "50% 50%",
                    mx: 1,
                  }}
                >
                  <Typography variant="body2">{item.warehouse}</Typography>
                  <Grid ml={1}>
                    {item.stock_list &&
                      Array.isArray(item.stock_list) &&
                      item.stock_list.map((stock) => {
                        return (
                          <Typography variant="body2">{stock.stock}</Typography>
                        );
                      })}
                  </Grid>
                </Grid>
              );
            })}
        </Grid>

        <Grid container justifyContent="center">
          <Typography
            component="a"
            href="/"
            // href={product.supplier.url}
            target="_blank"
            variant="body2"
            color="primary"
          >
            Banggood
          </Typography>
        </Grid>

        {/* Created At */}
        <Grid container justifyContent="center">
          <Typography variant="body2" textAlign="center">
            {formatDate(product.createdAt)}
          </Typography>
        </Grid>

        {/* <div style={{ textAlign: "center" }}>
          {product.import_status === null && "Not Imported"}
          {product.import_status === "imported" && "Imported"}
          {product.import_status === "exported" && "Exported"}
          {product.import_status === "deleted" && "Deleted"}
        </div> */}
        {
          // <div style={{textAlign:"center"}}>
          //   <Typography style={{color:"#5b5b5e"}}><Button variant="contained" size="small" onClick={(event) => handleImport(product)}>Import</Button></Typography>
          // </div>
        }
      </Grid>
    </Card>
  );
}
