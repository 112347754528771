import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';

export default function SogaMainCheckbox({products, selectedProducts, setSelectedProducts}) {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    console.log("abc",products)
    if(event.target.checked){
      let array = []
      products.map(product => {
        let element = document.getElementById(product.sku)
        if(element.checked){
          console.log("do nothing")
        }else{
          element.checked = true
        }

        array.push(product)
      })
      console.log("array", array)
      setSelectedProducts([...array])
    }
    else{
      products.map(product => {
        let element = document.getElementById(product.sku)
        if(element.checked){
          element.checked = false
        }
      })
      setSelectedProducts([])
    }

  };

  return (
    <input type="checkbox" onChange={handleChange} id="maincheckbox"/>
  );
}
