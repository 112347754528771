import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";

import {
  Grid,
  MenuItem,
  Select,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import {
  setSearch,
  setProductsPerPage,
  setSearchFilterListings,
  setSearchFilterOrders,
  setImportPage,
  setImportTotalPages,
  setArticles,
  setSubsettings,
  setToken,
  setLoading,
  setCountrylist,
  setShippingMethods,
  setShowImportedProductsFilter,
  setCategoriesLevel1,
  setCategoriesLevel2,
  setCategoriesLevel3,
  setCategoriesLevel4,
  setCategoriesLevel5,
  setCategoriesLevel6,
  setImportedProducts,
  setImportedProductsFilter,
  setSelectedCategoryLevel2,
  setSelectedCategoryLevel3,
  setSelectedCategoryLevel4,
  setSelectedCategoryLevel5,
  setSelectedCategoryLevel6,
} from "../../actions";

export default function Searchbar() {
  const dispatch = useDispatch();

  const products_per_page = useSelector((state) => state.products_per_page);
  const search_filter_orders = useSelector(
    (state) => state.search_filter_orders
  );

  const categories = [
    "SKU #",
    "Order #",
    "Store Name",
    "Customer Name",
    "Marketplace Order #",
  ];

  const [searchCategory, setSearchCategory] = useState("Order #");
  const handleChangeSearchCategory = (event) => {
    setSearchCategory(event.target.value);
  };

  const [searchInputValue, setSearchInputValue] = useState("");
  const handleChangeSearchInput = (event) => {
    setSearchInputValue(event.target.value);
    console.log("value", event.target.value);
    dispatch(setSearchFilterListings(event.target.value));
  };

  const handleSearchByEnter = (event, value) => {
    if (event.key === "Enter") {
      dispatch(setSearch(event.target.value));
    }
  };

  const handleSearchByClick = () => {
    dispatch(setSearch(searchInputValue));
  };

  console.log("searchCategory", searchCategory);

  return (
    <>
      <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
        Search By
      </Typography>

      <Select
        value={searchCategory}
        onChange={handleChangeSearchCategory}
        sx={{
          mx: 0.5,
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
        }}
      >
        {categories.map((item, index) => (
          <MenuItem key={`${index}_${item}`} value={item} sx={{ height: 26 }}>
            <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
              {item}
            </Typography>
          </MenuItem>
        ))}
      </Select>

      {/* <TextField
       label="Search your order"
       size="small"
       id="outlined-size-small"
       variant="standard"
       onKeyPress={(event) => handleSearchByEnter(event)}
     /> */}

      <OutlinedInput
        value={searchInputValue}
        onChange={handleChangeSearchInput}
        onKeyPress={handleSearchByEnter}
        placeholder={searchCategory}
        sx={{
          width: 170,
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              sx={{ width: "24px", height: "24px" }}
              aria-label="Search your order"
              onClick={handleSearchByClick}
              edge="end"
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </>
  );
}
