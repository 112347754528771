import React, { useEffect, useState } from "react";
import { Grid, Autocomplete, TextField } from "@mui/material";

export default function KoganCategorySelect({
  koganCategoryLevel1,
  koganCategoryLevel2,
  setKoganCategoryLevel1,
  setKoganCategoryLevel2,
}) {
  const [categorieslevel1, setCategoriesLevel1] = useState([]);
  const [categorieslevel2, setCategoriesLevel2] = useState([]);
  useEffect(() => {
    fetch("https://api.saleprodev.com/productimporter/kogancategorieslevel1")
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel1(response.categories_level_1);
      });
  }, []);

  useEffect(() => {
    setKoganCategoryLevel2("Select a Category");
    fetch("https://api.saleprodev.com/productimporter/kogancategorieslevel2", {
      method: "post",
      body: JSON.stringify({ category: koganCategoryLevel1 }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel2(response.categories_level_2);
      });
  }, [koganCategoryLevel1]);

  const handleChange = (event, value) => {
    console.log("value", value);
    value === null
      ? setKoganCategoryLevel1("Select a Category")
      : setKoganCategoryLevel1(value);
  };

  const handleChange2 = (event, value) => {
    console.log("value", value);
    value === null
      ? setKoganCategoryLevel2("Select a Category")
      : setKoganCategoryLevel2(value);
  };

  const defaultProps1 = {
    options: categorieslevel1,
    getOptionLabel: (option) => option,
  };

  const defaultProps2 = {
    options: categorieslevel2,
    getOptionLabel: (option) => option,
  };

//  const handleSuplier = (e, v) =>
//  {
//   if(!v)
//   {
//     setCategoriesLevel3(['Select a Supplier'])
//     return;
//   }

//   setCategoriesLevel3((prev)=>[...prev, v].filter((el)=> el !== 'Select a Supplier'))
//  }

  return (
    <>
      {/* <Grid item p={1}>
        <Autocomplete
          size="small"
          {...{
            options: ['wefullfill', 'aliexpress'],
            getOptionLabel: (option) => option,
          }}
          value={categorieslevel3}
          onChange={(event, value) => handleSuplier(event, value)}
          renderInput={(params) => <TextField {...params} />}
          sx={{
            "& legend": {
              width: "unset",
            },
          }}
        />
      </Grid> */}

      <Grid item p={1}>
        <Autocomplete
          size="small"
          {...defaultProps1}
          value={koganCategoryLevel1}
          onChange={(event, value) => handleChange(event, value)}
          renderInput={(params) => <TextField {...params} />}
          sx={{
            "& legend": {
              width: "unset",
            },
          }}
        />
      </Grid>

      <Grid item p={1}>
        <Autocomplete
          size="small"
          {...defaultProps2}
          value={koganCategoryLevel2}
          onChange={(event, value) => handleChange2(event, value)}
          renderInput={(params) => <TextField {...params} />}
          sx={{
            "& legend": {
              width: "unset",
            },
          }}
        />
      </Grid>
    </>
  );
}
