import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

function createData2(name, category1, category2, categoryid, supplier) {
  console.log(name, category1, category2, categoryid, supplier);
  return { name, category1, category2, categoryid, supplier };
}

export default function AcccessibleTable({
  koganmappings,
  DSZmappings,
  handleDelete,
}) {
  const [rows, setRows] = useState([]);
  const data = koganmappings ? koganmappings : DSZmappings;
  useEffect(() => {
    console.log(
      "koganmappings========================>>>>>>>>>>>>>>>>>>>>>",
      data
    );
    let array = [];
    data?.map((item) => {
      if (koganmappings) {
        array?.push(
          createData(
            item.tag_name,
            item.category_level_1,
            item.category_level_2,
            item.kogancategory
          )
        );
      } else {
        array?.push(
          createData2(
            item.tag_name,
            item.category_level_1,
            item.category_level_2,
            item.category_id,
            item.supplier
          )
        );
      }
    });
    setRows(array);
  }, [data]);

  return (
    <TableContainer component={Paper} style={{ marginTop: "2%" }}>
      <Table sx={{ minWidth: 1000 }} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell>PRODUCT TYPE</TableCell>
            {!koganmappings && <TableCell align="center">Supplier</TableCell>}
            <TableCell align="center">Category1</TableCell>
            <TableCell align="center">Category2</TableCell>
            <TableCell align="center">KoganCategoryID</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) =>
            koganmappings ? (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="center">{row.calories}</TableCell>
                <TableCell align="center">{row.fat}</TableCell>
                <TableCell align="center">{row.carbs}</TableCell>
                <TableCell align="center">
                  <DeleteForeverIcon
                    onClick={(event) => handleDelete(row.name)}
                    style={{ cursor: "pointer" }}
                  />
                </TableCell>
              </TableRow>
            ) : (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="center">{row.supplier}</TableCell>
                <TableCell align="center">{row.category1}</TableCell>
                <TableCell align="center">{row.category2}</TableCell>
                <TableCell align="center">{row.categoryid}</TableCell>
                <TableCell align="center">
                  <DeleteForeverIcon
                    onClick={(event) => handleDelete(row.name)}
                    style={{ cursor: "pointer" }}
                  />
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
