import React, { useState, useEffect } from "react";
import moment from "moment";
import no_image from "../assets/images/no_image.jpg";
import { Card, Grid, Tooltip, Typography } from "@mui/material";

import ControlledCheckbox from "./bangoodcheckbox.js";

export default function SunskyProductsLayout({
  product,
  handleImport,
  selectedProducts,
  setSelectedProducts,
  fetchproducts,
}) {


  const formatDate = (date) => moment(date).format("DD/MM/YYYY HH:mm:ss");

  return (
    <Card sx={{ width: "100%", mt: 1 }}>
      <Grid
        container
        sx={{
          minHeight: "70px",
          display: "Grid",
          gridTemplateColumns: "2% 4% 35% 8% 8% 10% 8% 5% 5% 5% 6% 4%",
          alignItems: "center",
        }}
      >
        {/* Checkbox */}
        <Grid container justifyContent="center">
          <ControlledCheckbox
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            product={product}
          />
        </Grid>

        {/* Image */}
        <Grid container justifyContent="center" alignItems="center" p={0.5}>
          <Tooltip
            arrow
            placement="top"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  padding: 0,
                },
              },
            }}
            title={
              <img
                src={
                  product.image_list
                    ? typeof product.image_list.view === "string"
                      ? product.image_list.view
                      : product.image_list.view["1"]
                    : no_image
                }
                alt=""
                style={{
                  width: "250px",
                }}
              />
            }
          >
            <Grid
              item
              justifyContent="center"
              alignContent="center"
              sx={{
                minHeight: "60px",
                border: (theme) => `1px solid ${theme.palette.border}`,
              }}
            >
              <img
                src={
                  product.image_list
                    ? typeof product.image_list.view === "string"
                      ? product.image_list.view
                      : product.image_list.view["1"]
                    : no_image
                }
                alt=""
                width="60px"
              />
            </Grid>
          </Tooltip>
        </Grid>

        {/* Title & Product ID */}
        <Grid container flexDirection="column" pl={1}>
          {product.name && product.name.length > 90 ? (
            <Tooltip arrow placement="top" title={product.name}>
              <Typography variant="body2">
                {`${product.name.slice(0, 90)}...`}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="body2">{product.name}</Typography>
          )}

          <Grid container>
            <Typography
              component="a"
              href={`https://www.sunsky-online.com/p/${product.product_id}.htm`}
              target="_blank"
              variant="body2"
              color="primary"
            >
              Product ID: {product.product_id}
            </Typography>
          </Grid>
        </Grid>

        {/* Barcode */}
        <Grid container justifyContent="center">
          <Typography variant="body2">N/A</Typography>
        </Grid>

        {/* Weight */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.packWeight === null || product.packWeight === ""
              ? "N/A"
              : `${product.unitWeight} kg`}
          </Typography>
        </Grid>

        {/* Cost */}

            <Grid container flexDirection="column" alignItems="center">
             <Typography variant="body2">
                      {product.price ?? ''}
                    </Typography>
            </Grid>

        {/* Shipping Cost */}
        <Grid container justifyContent="center">
          <Typography variant="body2">N/A</Typography>
        </Grid>

        {/* Variation */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product?.optionList ? product?.optionList?.items.length : 0}
          </Typography>
        </Grid>

        <Grid container justifyContent="center">
         80
        </Grid>

        <Grid container justifyContent="center">
          <Typography
            component="a"
            href="/"
            // href={product.supplier.url}
            target="_blank"
            variant="body2"
            color="primary"
          >
            SunSky
          </Typography>
        </Grid>

        {/* Created At */}
        <Grid container justifyContent="center">
          <Typography variant="body2" textAlign="center">
            {formatDate(product.createdAt)}
          </Typography>
        </Grid>

        {/* <div style={{ textAlign: "center" }}>
          {product.import_status === null && "Not Imported"}
          {product.import_status === "imported" && "Imported"}
          {product.import_status === "exported" && "Exported"}
          {product.import_status === "deleted" && "Deleted"}
        </div> */}
        {
          // <div style={{textAlign:"center"}}>
          //   <Typography style={{color:"#5b5b5e"}}><Button variant="contained" size="small" onClick={(event) => handleImport(product)}>Import</Button></Typography>
          // </div>
        }
      </Grid>
    </Card>
  );
}
