import Button from "@mui/material/Button";

export const SmallContainedButton = ({
  color,
  width,
  variant,
  onClick,
  disabled,
  children,
}) => (
  <Button
    color={color}
    variant={variant ? variant : "contained"}
    onClick={onClick}
    disabled={disabled}
    sx={{
      width: width ? width : 100,
      height: 26,
      fontSize: "14px",
      textTransform: "none",
      m: 0.25,
    }}
  >
    {children}
  </Button>
);
