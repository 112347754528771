import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setSubsettings, setSettingsItem } from "../../actions";

import { Card, Grid, Typography, Breadcrumbs } from "@mui/material";

export const SettingsBreadcrumbs = ({
  page,
  settingsMenu,
  general_data,
  integrations_data,
  settingsItem,
}) => {
  const dispatch = useDispatch();
  const subSettings = useSelector((state) => state.sub_settings);

  return (
    <Card elevation={0} sx={{ px: 2, py: 0.5 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography
          variant="body2"
          sx={{
            cursor: "pointer",
            "&:hover": {
              color: "primary.main",
            },
          }}
          onClick={(event) => dispatch(setSubsettings("order"))}
        >
          {page.charAt(0).toUpperCase() + page.slice(1)}
        </Typography>

        <Typography variant="body2">{settingsMenu}</Typography>

        <Typography
          variant="body2"
          onClick={() => dispatch(setSettingsItem(""))}
          sx={{
            cursor: settingsItem === "" ? "auto" : "pointer",
            "&:hover": {
              color: settingsItem === "" ? "" : "primary.main",
            },
          }}
        >
          {settingsMenu === "General" &&
            general_data.map(
              (item) =>
                subSettings === item.sub_settings && item.sub_settings_title
            )}

          {settingsMenu === "Integrations" &&
            integrations_data.map(
              (item) =>
                subSettings === item.sub_settings && item.sub_settings_title
            )}
        </Typography>

        {settingsItem !== "" && (
          <Typography variant="body2">
            {settingsMenu === "General" &&
              general_data.map((item) =>
                item.settings_items.map(
                  (item) => settingsItem === item.item_code && item.item_title
                )
              )}

            {settingsMenu === "Integrations" &&
              integrations_data.map((item) =>
                item.settings_items.map(
                  (item) => settingsItem === item.item_code && item.item_title
                )
              )}
          </Typography>
        )}
      </Breadcrumbs>
    </Card>
  );
};
