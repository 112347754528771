import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "../common/Loader";

import {
  Grid,
  MenuItem,
  Select,
  Tooltip,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";

export default function AliexpressCarriers({ carrier, setCarrier, order }) {
  const [availablecarriers, setAvailableCarriers] = React.useState({
    carriers: [],
    orderid: "",
  });
  const [loading, setLoading] = useState(false);
  const store = useSelector((state) => state.store);

  console.log("sku", JSON.parse(order.line_items)[0].sku)

  useEffect(() => {
    setLoading(true);
    fetch("https://api.saleprodev.com/aliexpressshippingfororders", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        supplier_sku: JSON.parse(order.line_items)[0].sku,
        store: store,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("carriers", response);
        if (
          response.result &&
          response.result.aeop_freight_calculate_result_for_buyer_d_t_o_list &&
          response.result.aeop_freight_calculate_result_for_buyer_d_t_o_list
            .aeop_freight_calculate_result_for_buyer_dto
        ) {
          let carriers = [];
          response.result.aeop_freight_calculate_result_for_buyer_d_t_o_list.aeop_freight_calculate_result_for_buyer_dto.map(
            (item) => {
              let name = "unknown";
              if (item.service_name === "UBI") {
                name = "UBI";
              }
              if (item.service_name === "CAINIAO_STANDARD") {
                name = "AliExpress Standard Shipping";
              }

              if (item.service_name === "CAINIAO_PREMIUM") {
                name = "AliExpress Premium Shipping";
              }

              if (item.service_name === "YANWEN_AM") {
                name = "Special Line-YW";
              }
              if (item.service_name === "EMS_ZX_ZX_US") {
                name = "ePacket";
              }
              if (item.service_name === "DHL") {
                name = "DHL";
              }
              if (item.service_name === "USPS") {
                name = "USPS";
              }
              if (item.service_name === "UPS") {
                name = "UPS";
              }
              if (item.service_name === "UPSE") {
                name = "UPSE";
              }
              if (item.service_name === "EMS") {
                name = "EMS";
              }
              if (item.service_name === "E_EMS") {
                name = "E_EMS";
              }
              if (item.service_name === "FEDEX") {
                name = "FEDEX";
              }
              if (item.service_name === "FEDEX_IE") {
                name = "FEDEX_IE";
              }
              if (item.service_name === "Other") {
                name = "Seller's Shipping Method";
              }
              if (item.service_name === "SGP_OMP") {
                name = "4PX Singapore Post OM Pro";
              }
              if (item.service_name === "CAINIAO_STANDARD_SG") {
                name = "Cainiao Standard For Special Goods";
              }
              if (item.service_name === "CAINIAO_FULFILLMENT_STD") {
                name = "CAINIAO FULFILLMENT STD";
              }
              if (name === "unknown") {
                carriers.push({
                  name: name + "-" + item.service_name,
                  value: item.service_name,
                  amount: `$${item.freight.amount}`,
                  currency: item.freight.currency_code,
                  duration: item.estimated_delivery_time,
                });
              } else {
                carriers.push({
                  name: name,
                  value: item.service_name,
                  amount: `$${item.freight.amount}`,
                  currency: item.freight.currency_code,
                  duration: item.estimated_delivery_time,
                });
              }
            }
          );
          console.log("AvailableCarriers", carriers);
          setAvailableCarriers({ orderid: order.orderid, carriers: carriers });
        }
        if (response.error) {
          setAvailableCarriers({
            orderid: order.orderid,
            carriers: [
              {
                name: response.error,
                value: response.error,
                amount: "Carrier Not Available",
                currency: "",
                duration: "",
              },
            ],
          });
        }
        if (response.msg) {
          setAvailableCarriers({
            orderid: order.orderid,
            carriers: [
              {
                name: response.msg,
                value: response.msg,
                amount: "Carrier Not Available",
                currency: "",
                duration: "",
              },
            ],
          });
        }
        setLoading(false);
      });
  }, []);

  const handleChange = (event) => {
    setCarrier(event.target.value);
  };

  console.log("carrier", carrier);
  return (
    <Grid container flexDirection="column" py={1}>
      <FormControl sx={{ my: 1, minWidth: 150 }}>
        <InputLabel id="demo-select-small">Choose Carrier</InputLabel>
        <Select
          labelId="demo-select-small"
          id="demo-select-small"
          value={carrier}
          label="Choose Carrierrrrr"
          onChange={handleChange}
          size="small"
          // onClick={handleShowCarriers}
          sx={{
            height: 30,
            fontSize: "0.8rem",
          }}
        >
          {availablecarriers.carriers.length !== 0 &&
            availablecarriers.orderid === order.orderid &&
            (loading ? (
              <Grid
                container
                py={1}
                justifyContent="center"
                alignItems="center"
              >
                <Loader size={30} />
              </Grid>
            ) : (
              availablecarriers.carriers.map((carrierObj, index) => (
                <MenuItem key={index} value={carrierObj}>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={9}>
                      {carrierObj.name.length > 20 ? (
                        <Grid container>
                          <Tooltip
                            arrow
                            placement="top"
                            title={carrierObj.name}
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "0.8rem" }}
                            >
                              {carrierObj.name.slice(0, 20) + " ..."}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      ) : (
                        <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                          {carrierObj.name}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={3} textAlign="right">
                      <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                        {carrierObj.amount === "$0"
                          ? "Free"
                          : carrierObj.amount + carrierObj.currency}
                      </Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              ))
            ))}
        </Select>
      </FormControl>
    </Grid>
  );
}
