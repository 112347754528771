import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Card } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

export default function SkuMapperVariantList({
  product,
  sku,
  supplierSku,
  variantID,
  setOpen,
  setCount,
  count,
  selected,
  setSelected,
}) {
  // const [selected, setSelected] = useState({ id: "", sku: "" });
  const [image, setImage] = useState("");
  const store = useSelector((state) => state.store);
  const selected_store = useSelector((state) => state.selected_store);
  const selected_variant_type = useSelector(
    (state) => state.selected_variant_type
  );
  const handleClick = (id, supplier_sku, sku) => {
    setSelected({ id: id, supplier_sku: supplier_sku, sku: sku });
    // if (store === "nzshopify") {
    //   fetch("https://api.saleprodev.com/aliexpressmapvariant", {
    //     method: "post",
    //     body: JSON.stringify({ id: id, supplier_sku: supplier_sku, sku: sku }),
    //   })
    //     .then((response) => response.json())
    //     .then((response) => {
    //       console.log(response);
    //       setOpen({ open: true, message: "Success" });
    //       setCount(count + 1);
    //     });
    // }
    // if (store === "aushopify") {
    //   fetch("https://api.saleprodev.com/aliexpressmapvariantau", {
    //     method: "post",
    //     body: JSON.stringify({ id: id, supplier_sku: supplier_sku, sku: sku }),
    //   })
    //     .then((response) => response.json())
    //     .then((response) => {
    //       console.log(response);
    //       setOpen({ open: true, message: "Success" });
    //       setCount(count + 1);
    //     });
    // }
    // if (store === "usshopify") {
    //   fetch("https://api.saleprodev.com/aliexpressmapvariantus", {
    //     method: "post",
    //     body: JSON.stringify({ id: id, supplier_sku: supplier_sku, sku: sku }),
    //   })
    //     .then((response) => response.json())
    //     .then((response) => {
    //       console.log(response);
    //       setOpen({ open: true, message: "Success" });
    //       setCount(count + 1);
    //     });
    // }
  };

  console.log("product", product);
  console.log("variantID", variantID);
  useEffect(() => {
    console.log("selected_variant_type", selected_variant_type);

    if (selected_store === "nzshopify") {
      fetch("https://aliscript.saleprodev.com/getshopifyvariantimage", {
        method: "post",
        body: JSON.stringify({
          variantid: variantID,
          variantType: selected_variant_type,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("image", response);
          if (response.src) {
            setImage(response);
          }
          if (response.errors) {
            setImage(response.errors);
          }
        });
    }
    if (selected_store === "aushopify") {
      fetch("https://aliscript.saleprodev.com/getshopifyvariantimageau", {
        method: "post",
        body: JSON.stringify({
          variantid: variantID,
          variantType: selected_variant_type,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          if (response.src) {
            setImage(response);
          }
          if (response.errors) {
            setImage(response.errors);
          }
        });
    }
    if (selected_store === "usshopify") {
      fetch("https://aliscript.saleprodev.com/getshopifyvariantimageus", {
        method: "post",
        body: JSON.stringify({
          variantid: variantID,
          variantType: selected_variant_type,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.src) {
            setImage(response);
          }
          if (response.errors) {
            setImage(response.errors);
          }
        });
    }

    if (selected_store === "gkshopify") {
      fetch("https://aliscript.saleprodev.com/getshopifyvariantimagegk", {
        method: "post",
        body: JSON.stringify({
          variantid: variantID,
          variantType: selected_variant_type,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.src) {
            setImage(response);
          }
          if (response.errors) {
            setImage(response.errors);
          }
        });
    }
  }, []);

  console.log("selected", selected);
  return (
    <Card style={{ width: "80vw", marginTop: "2%" }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <div style={{ backgroundColor: "black", color: "white" }}>
            {selected_store.toUpperCase()}
          </div>
          <div
            style={{
              alignItems: "left",
              textAlign: "left",
              marginLeft: "10%",
              display: "grid",
              gridTemplateColumns: "50% 50%",
            }}
          >
            <div>
              <div style={{ fontWeight: "bold" }}>Supplier Sku:</div>
              <div>{supplierSku}</div>
            </div>
            <div>
              <div style={{ fontWeight: "bold" }}>Shopify Sku:</div>
              <div>{sku}</div>
            </div>
            {image.src === "Image Not Found" ||
              image.src === "Product Not Found" ? (
              <div>
                <div style={{ color: "red" }}>{image.src}</div>
                <div>OPTION1:{image.option1}</div>
                <div>OPTION2:{image.option2}</div>
                <div>OPTION3:{image.option3}</div>
              </div>
            ) : (
              <div>
                <img src={image.src} alt="" height={100} width={100} />
                <div>OPTION1:{image.option1}</div>
                <div>OPTION2:{image.option2}</div>
                <div>OPTION3:{image.option3}</div>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ backgroundColor: "black", color: "white" }}>
            ALIEXPRESS
          </div>
          <div
            style={{
              alignItems: "left",
              textAlign: "left",
              height: "700px",
              overflow: "scroll",
            }}
          >
            {product.ali_info.result.aeop_ae_product_s_k_us
              .aeop_ae_product_sku &&
              product.ali_info.result.aeop_ae_product_s_k_us.aeop_ae_product_sku.map(
                (item, index) => {
                  return (
                    <Grid item xs={12} key={index}>
                      <div
                        style={{ backgroundColor: "#f7f7f7", marginTop: "1%" }}
                      >
                        {product.ali_info.result.aeop_ae_product_s_k_us
                          .aeop_ae_product_sku.length === 1 ? (
                          selected.id === item.id ? (
                            <div
                              style={{
                                fontWeight: "bold",
                                cursor: "pointer",
                                backgroundColor: "red",
                              }}
                            // onClick={(event) =>
                            //   handleClick(
                            //     item.id,
                            //     product.supplier_sku,
                            //     product.sku
                            //   )
                            // }
                            >
                              {item.id}
                            </div>
                          ) : (
                            <div
                              style={{ fontWeight: "bold", cursor: "pointer" }}
                              onClick={(event) =>
                                handleClick(item.id, supplierSku, sku)
                              }
                            >
                              {item.id}
                            </div>
                          )
                        ) : selected.id === item.id ? (
                          <div
                            id={item.id}
                            style={{
                              display: "grid",
                              gridTemplateColumns: "30% 30% 30%",
                              backgroundColor: "red",
                              cursor: "pointer",
                            }}
                          // onClick={(event) =>
                          //   handleClick(
                          //     item.id,
                          //     product.supplier_sku,
                          //     product.sku
                          //   )
                          // }
                          >
                            {item.aeop_s_k_u_propertys &&
                              item.aeop_s_k_u_propertys.aeop_sku_property.map(
                                (prop, index) => {
                                  return (
                                    <Grid item key={index}>
                                      {prop.property_value_definition_name ? (
                                        <div>
                                          {prop.sku_property_name}:{" "}
                                          {prop.property_value_definition_name}
                                        </div>
                                      ) : (
                                        <div>
                                          {prop.sku_property_name}:{" "}
                                          {prop.sku_property_value}
                                        </div>
                                      )}

                                      {prop.sku_image && (
                                        <img
                                          src={prop.sku_image}
                                          alt=""
                                          height={100}
                                          width={100}
                                        />
                                      )}
                                    </Grid>
                                  );
                                }
                              )}
                          </div>
                        ) : (
                          <div
                            id={item.id}
                            style={{
                              display: "grid",
                              gridTemplateColumns: "30% 30% 30%",
                              cursor: "pointer",
                            }}
                            onClick={(event) =>
                              handleClick(item.id, supplierSku, sku)
                            }
                          >
                            {item.aeop_s_k_u_propertys &&
                              item.aeop_s_k_u_propertys.aeop_sku_property.map(
                                (prop, index) => {
                                  return (
                                    <Grid item key={index}>
                                      {prop.property_value_definition_name ? (
                                        <div>
                                          {prop.sku_property_name}:{" "}
                                          {prop.property_value_definition_name}
                                        </div>
                                      ) : (
                                        <div>
                                          {prop.sku_property_name}:{" "}
                                          {prop.sku_property_value}
                                        </div>
                                      )}
                                      {prop.sku_image && (
                                        <img
                                          src={prop.sku_image}
                                          alt=""
                                          height={100}
                                          width={100}
                                        />
                                      )}
                                    </Grid>
                                  );
                                }
                              )}
                          </div>
                        )}
                      </div>
                    </Grid>
                  );
                }
              )}

            <div
              style={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={(event) => handleClick("Not Found", supplierSku, sku)}
            >
              NOT FOUND
            </div>
            <div
              style={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={(event) =>
                handleClick("Image Missing", supplierSku, sku)
              }
            >
              IMAGE MISSING IN SHOPIFY
            </div>
            <div
              style={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={(event) =>
                handleClick("Product Missing", supplierSku, sku)
              }
            >
              PRODUCT MISSING IN SHOPIFY
            </div>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
}
