import React, {useEffect} from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {setSearch, setImportPage, setImportTotalPages,setArticles,setSubsettings, setToken, setLoading,setCountrylist,setShippingMethods, setShowImportedProductsFilter,setCategoriesLevel1, setCategoriesLevel2,setCategoriesLevel3, setCategoriesLevel4,setCategoriesLevel5, setCategoriesLevel6,setImportedProducts,setImportedProductsFilter,setSelectedCategoryLevel2,setSelectedCategoryLevel3, setSelectedCategoryLevel4, setSelectedCategoryLevel5, setSelectedCategoryLevel6} from "../../actions"
import {useSelector, useDispatch} from "react-redux"
import BasicSelect from "./basicselect"

export default function PaginationOutlined_orders({selectedOrders, setSelectedOrders}) {
  const dispatch = useDispatch()
  const import_page = useSelector(state => state.import_page)
  const import_total_pages = useSelector(state => state.import_total_pages)
  const products_per_page = useSelector(state => state.products_per_page)
  const show_orders = useSelector(state => state.show_orders)
  const searchField = useSelector((state) => state.searchField);
  const handleChange = (event, value) => {
  dispatch(setImportPage(value));
  window.localStorage.setItem("import_page", value)

  }

  useEffect(() => {


  },[])

console.log("import_page",import_page)
console.log("products_per_page",products_per_page)
  return (
    <div style={{display:"grid", gridTemplateColumns:"30% 30% 10% 30%", marginTop:"2%"}}>
    <div></div>
    <Stack spacing={2}>
      <Pagination count={import_total_pages} page={import_page} variant="outlined" color="primary" onChange={handleChange} size="small"/>
    </Stack>
    <BasicSelect selectedOrders={selectedOrders} setSelectedOrders={setSelectedOrders}/>
    <input type="text" value={searchField} placeholder="search" onChange={(event) => dispatch(setSearch(event.target.value))}/>
    </div>
  );
}
