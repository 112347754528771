import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import "./checkbox.css";

export default function ImportedControlledCheckbox({
  product,
  checkboxID,
  setSelectedProducts,
  selectedProducts,
}) {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    if (event.target.checked === true) {
      let array = [...selectedProducts];
      array.push(product);
      setSelectedProducts(array);
    }
    if (event.target.checked === false) {
      let array = [...selectedProducts];
      let index = array.indexOf(product);
      array.splice(index, 1);
      setSelectedProducts(array);
    }
  };

  return <input type="checkbox" onChange={handleChange} id={checkboxID} />;
}
