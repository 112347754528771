import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomizedSnackbars from "./notification.js";
import ToolsPagination from "./ToolsPagination.js";
import Format from "./format.js";

import {
  Box,
  Grid,
  Select,
  Typography,
  TextField,
  CircularProgress,
  Button,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import {
  setSearch,
  setStore,
  setLoadingProducts,
  setImportPage,
  setImportTotalPages,
  setArticles,
  setSubsettings,
  setToolsMenu,
  setToken,
  setCountrylist,
  setSelectedCountryShipFrom,
  setSelectedCountryShipTo,
  setSelectedShippingMethod,
  setShippingMethods,
} from "../../actions";

const AliExpressMapping = ({ page }) => {
  const [products, setProducts] = useState([]);
  const [selected, setSelected] = useState({ id: "", sku: "" });
  const [storeid, setStoreid] = useState("");
  const [sku, setSKU] = useState("");
  const [open, setOpen] = useState({ open: false, message: "" });
  const [count, setCount] = useState(0);
  const [count3, setCount3] = useState(0);
  const import_page = useSelector((state) => state.import_page);
  const import_total_pages = useSelector((state) => state.import_total_pages);
  const loading_products = useSelector((state) => state.loading_products);
  const store = useSelector((state) => state.store);
  const [aliVariantName, setAliVariantName] = useState("");
  const [openMapVariantModal, setOpenMapVariantModal] = useState(false);
  const tools_menu = useSelector((state) => state.tools_menu);
  const dispatch = useDispatch();
  const total_counts = useSelector((state) => state.total_counts);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [status, setStatus] = useState("pending");

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);

  };

  const findproducts = () => {
    // dispatch(setImportPage(1));
    dispatch(setLoadingProducts(true));
    if (store === "nzshopify") {
      fetch("https://aliscript.saleprodev.com/aliexpressmapping", {
        method: "post",
        body: JSON.stringify({
          page: import_page,
          products_per_page: 1,
          storeid,
          sku,
          page_name: 'tool',
          status,
          ali_productStatus : ['onSelling', null],
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("this is NZ response", response);
          setProducts(response);
          dispatch(setImportTotalPages(response.count));
          dispatch(setLoadingProducts(false));
        }).catch((err)=>
        { 
          dispatch(setLoadingProducts(false));
          console.error("there is error fetching data",err)
        });
    }
    if (store === "aushopify") {
      fetch("https://aliscript.saleprodev.com/aliexpressmappingau", {
        method: "post",
        body: JSON.stringify({
          page: import_page,
          products_per_page: 1,
          storeid,
          sku,
          page_name: 'tool',
          status,
          ali_productStatus : ['onSelling', null],
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setProducts(response);
          dispatch(setImportTotalPages(response.count));
          dispatch(setLoadingProducts(false));
        }).catch((err)=>
        {
          dispatch(setLoadingProducts(false))
          console.error("there is error in fetching data",err);
        });
    }
    if (store === "usshopify") {
      fetch("https://aliscript.saleprodev.com/aliexpressmappingus", {
        method: "post",
        body: JSON.stringify({
          page: import_page,
          products_per_page: 1,
          storeid,
          sku,
          page_name: 'tool',
          status
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setProducts(response);
          dispatch(setImportTotalPages(response.count));
          dispatch(setLoadingProducts(false));
        }).catch((err)=>
          {
            dispatch(setLoadingProducts(false))
            console.error("there is error in fetching data",err);
          });;
    }

    if (store === "gkshopify") {
      fetch("https://aliscript.saleprodev.com/aliexpressmappinggk", {
        method: "post",
        body: JSON.stringify({
          page: import_page,
          products_per_page: 1,
          storeid,
          sku,
          page_name: 'tool',
          status,
          ali_productStatus : ['onSelling', null],
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setProducts(response);
          dispatch(setImportTotalPages(response.count));
          dispatch(setLoadingProducts(false));
        }).catch((err)=>
          {
            dispatch(setLoadingProducts(false))
            console.error("there is error in fetching data",err);
          });;
    }
  };

  //Logic to update the product list when store, page changes.
  useEffect(() => {
    findproducts();
  }, [count, store, import_page]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen({ open: false, message: "" });
  };

  const handleCloseMapVariantModal = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMapVariantModal(false);
  };

  console.log("products", products);
  console.log("selected", selected);
  return (
    <Grid container flexDirection="column" alignItems="center" m={2}>

      {/* Shops */}
      <Grid item my={2}>
        {store === "nzshopify" ? (
          <Button variant="contained">NZ Shopify</Button>
        ) : (
          <Button onClick={(event) => dispatch(setStore("nzshopify"))}>
            NZ Shopify
          </Button>
        )}
        {store === "aushopify" ? (
          <Button variant="contained">AU Shopify</Button>
        ) : (
          <Button onClick={(event) => dispatch(setStore("aushopify"))}>
            AU Shopify
          </Button>
        )}

        {store === "usshopify" ? (
          <Button variant="contained">US Shopify</Button>
        ) : (
          <Button onClick={(event) => dispatch(setStore("usshopify"))}>
            US Shopify
          </Button>
        )}

        {store === "gkshopify" ? (
          <Button variant="contained">GK Shopify</Button>
        ) : (
          <Button onClick={(event) => dispatch(setStore("gkshopify"))}>
            GK Shopify
          </Button>
        )}
      </Grid>

      <Grid
        sx={{
          display: "grid",
          gridTemplateColumns: "30% 30% 15% 10% 15%",
        }}
      >
        <Grid item container p={1} justifyContent="center" alignItems="center">
          <TextField
            size="small"
            value={storeid}
            placeholder="STORE ID"
            onChange={(event) => setStoreid(event.target.value)}
            sx={{
              width: "100%",
              fontSize: "0.8rem",
              "& legend": {
                width: "unset",
              },
            }}
          />
        </Grid>

        <Grid item container p={1} justifyContent="center" alignItems="center">
          <TextField
            size="small"
            placeholder="SKU"
            value={sku}
            onChange={(event) => setSKU(event.target.value)}
            sx={{
              width: "100%",
              fontSize: "0.8rem",
              "& legend": {
                width: "unset",
              },
            }}
          />
        </Grid>

        <Grid item container p={1} justifyContent="center" alignItems="center">
          <Select
            value={status}
            onChange={handleChangeStatus}
            size="small"
            sx={{
              // height: 26,
              // fontSize: "0.8rem",
              "& legend": {
                width: "unset",
              },
            }}
          >
            <MenuItem value="pending">
              <Typography variant="body2" >
                Pending
              </Typography>
            </MenuItem>

            <MenuItem value="done">
              <Typography variant="body2" >
                Done
              </Typography>
            </MenuItem>
          </Select>
        </Grid>

        <Grid item container p={1} justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            onClick={() => findproducts()}
            sx={{ width: "100%" }}
          >
            Find
          </Button>
        </Grid>

        <Grid item container p={1} justifyContent="center" alignItems="center">
          <Typography variant="body1">
            Pending:{products.count && products.count}
          </Typography>
        </Grid>
      </Grid>

      {/* Shops
      <Grid item my={2}>
        {store === "nzshopify" ? (
          <Button variant="contained">NZ Shopify</Button>
        ) : (
          <Button onClick={(event) => dispatch(setStore("nzshopify"))}>
            NZ Shopify
          </Button>
        )}
        {store === "aushopify" ? (
          <Button variant="contained">AU Shopify</Button>
        ) : (
          <Button onClick={(event) => dispatch(setStore("aushopify"))}>
            AU Shopify
          </Button>
        )}

        {store === "usshopify" ? (
          <Button variant="contained">US Shopify</Button>
        ) : (
          <Button onClick={(event) => dispatch(setStore("usshopify"))}>
            US Shopify
          </Button>
        )}

        {store === "gkshopify" ? (
          <Button variant="contained">GK Shopify</Button>
        ) : (
          <Button onClick={(event) => dispatch(setStore("gkshopify"))}>
            GK Shopify
          </Button>
        )}
      </Grid> */}

      {/* Pagination & Variant List */}
      <Grid item container justifyContent="center" alignItems="center">
        {loading_products ? (
          <CircularProgress />
        ) : (
          <>
            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="center"
              my={0.5}
              sx={{ width: "80vw" }}
            >
              <ToolsPagination
                page={page}
                total_counts={total_counts}
                selectedOrders={selectedOrders}
                setSelectedOrders={setSelectedOrders}
              />
            </Grid>
            {products.products &&
              products.products.map((product) => {
                return (
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    my={0.5}
                  >
                    <Format
                      product={product}
                      setOpen={setOpen}
                      setCount={setCount}
                      setCount3={setCount3}
                      status={status}
                      count={count}
                      count3={count3}
                      page_name="tool"
                      setAliVariantName={setAliVariantName}
                      handleCloseMapVariantModal={handleCloseMapVariantModal}
                    />
                  </Grid>
                )
              })}

            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="center"
              my={0.5}
              sx={{ width: "80vw" }}
            >
              <ToolsPagination
                page={page}
                total_counts={total_counts}
                selectedOrders={selectedOrders}
                setSelectedOrders={setSelectedOrders}
              />
            </Grid>
          </>
        )}
      </Grid>

      <CustomizedSnackbars
        open={open.open}
        message={open.message}
        handleClose={handleClose}
      />
    </Grid>
  );
};
export default AliExpressMapping;
