import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";

export default function SkuMapperDialog({
  sku,
  openSkuMapperDialog,
  handleCloseSkuMapperDialog,
  handleSubmitAliexpressProducts,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const selected_store = useSelector((state) => state.selected_store);
  const [supplierSku, setSupplierSku] = useState("");

  useEffect(() => {
    fetch("https://api.saleprodev.com/getsupplierskunz", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sku,
        store: selected_store,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("supplier_skuresponse", response);
        setSupplierSku(response.supplier_sku);
      });
  }, []);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={openSkuMapperDialog}
      onClose={handleCloseSkuMapperDialog}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {supplierSku !== "product not found" ? "Warning" : "Map New Product?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {supplierSku !== "product not found" &&
            "Product already exists, are you sure you want to overwrite?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCloseSkuMapperDialog}>
          Cancel
        </Button>
        <Button onClick={handleSubmitAliexpressProducts} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
