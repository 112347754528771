import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import LoginIcon from "@mui/icons-material/Login";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import GeneralSettings from "../generalsettings/generalsettings";
import NativePickers from "./select";
import DropShipProduct from "./dropshipproduct";
import CustomizedSnackbars from "./notification.js";
import CustomizedSnackbarsFailed from "./failednotification.js";
import PurchasedOrders from "./purchasedorders.js";
import PaginationOutlined_orders from "../common/Pagination.js";
import Image from "./image.js";
import ControlledCheckbox from "./checkbox.js";
import MainCheckbox from "./maincheckbox";
import ShippedOrders from "./shippedorders.js";
import Searchbar from "../common/Searchbar.js";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Loader } from "../common/Loader";

import { useSelector, useDispatch } from "react-redux";
import {
  setSearch,
  setStore,
  setAliExpressOrders,
  setArticles,
  setSubsettings,
  setOrdersNzShopify,
  setLoadingOrders,
  setSelectedSupplier,
  setDropshipOrders,
  setOrdersFromTo,
  setShowOrders,
  setImportTotalPages,
  setCustomSheet,
  setFragrancexOrders,
} from "../../actions";

function Orders() {
  const [orders, setOrders] = useState("");
  const [selectedOrders, setSelectedOrders] = useState([]);
  const sub_settings = useSelector((state) => state.sub_settings);
  const orders_nz_shopify = useSelector((state) => state.orders_nz_shopify);
  const selected_supplier = useSelector((state) => state.selected_supplier);
  const dropship_orders = useSelector((state) => state.dropship_orders);
  const order_from_date = useSelector((state) => state.order_from_date);
  const order_to_date = useSelector((state) => state.order_to_date);
  const orders_from_to = useSelector((state) => state.orders_from_to);
  const show_orders = useSelector((state) => state.show_orders);
  const import_total_pages = useSelector((state) => state.import_total_pages);
  const import_page = useSelector((state) => state.import_page);
  const products_per_page = useSelector((state) => state.products_per_page);
  const fragrancex_orders = useSelector((state) => state.fragrancex_orders);
  const aliexpress_orders = useSelector((state) => state.aliexpress_orders);
  const loading_orders = useSelector((state) => state.loading_orders);
  const searchField = useSelector((state) => state.searchField);
  const store = useSelector((state) => state.store);
  const shipped_orders = useSelector((state) => state.shipped_orders);
  const [token, setToken] = useState("");
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState({ open: false, message: "" });
  const [message, setMessage] = useState("");
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState([]);
  const [orderspage1, setOrdersPage1] = useState([]);
  const [orderspage2, setOrdersPage2] = useState([]);
  const start = (import_page - 1) * products_per_page;
  const end = start + products_per_page;
  const [finalorders, setFinalOrders] = useState([]);
  const [orders_nz_shopify_raw, setOrdersNzShopifyRaw] = useState([]);
  const custom_sheet = useSelector((state) => state.custom_sheet);
  const [array, setArray] = useState([]);
  const [openfailed, setOpenFailed] = React.useState({
    open: false,
    message: "",
    order: "",
  });
  const [purchasedorderscheck, setPurchasedOrdersCheck] = useState([]);
  const [paymentStatus, setPaymentStatus] = React.useState("");

  useEffect(() => {
    if (show_orders === "to_purchase") {
      dispatch(
        setImportTotalPages(parseInt(orders_from_to.length / products_per_page))
      );
    }
  }, [orders_from_to, start, end, show_orders]);

  useEffect(() => {
    const data = async () => {
      dispatch(setLoadingOrders(true));
      setOrdersNzShopifyRaw([]);
      if (store === "nzshopify") {
        await fetch("http://34.129.244.90/shopifyorderpagesrev")
          .then((response) => response.json())
          .then((response) => {
            let array = [
              "https://go-slash.myshopify.com/admin/api/2021-10/orders.json?limit=250&status=any",
              ...response,
            ];
            setPages(array);
          });
      }
      if (store === "aushopify") {
        await fetch("http://34.129.244.90/shopifyorderpagesrevau")
          .then((response) => response.json())
          .then((response) => {
            let array = [
              "https://goslash-au.myshopify.com/admin/api/2021-07/orders.json?limit=250&status=any",
              ...response,
            ];
            setPages(array);
          });
      }
      if (store === "usshopify") {
        await fetch("http://localhost:3001/shopifyorderpagesusrev")
          .then((response) => response.json())
          .then((response) => {
            let array = [
              "https://paralleldeals.myshopify.com/admin/api/2021-10/orders.json?limit=250&status=any",
              ...response,
            ];
            setPages(array);
          });
      }
    };
    data();
  }, [store]);

  useEffect(() => {
    const data = async () => {
      await fetch("http://34.129.244.90/getorders")
        .then((response) => response.json())
        .then((response) => {
          setPurchasedOrdersCheck(response);
        });
    };
    data();
  }, [show_orders, count]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event) => {
    setPaymentStatus(event.target.value);
  };

  const handleCloseFailed = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenFailed({ open: false, message: "", order: "" });
  };

  useEffect(() => {
    if (pages.length !== 0 && store === "nzshopify") {
      fetch(`http://34.129.244.90/shopifyordersnzlink`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ link: pages[0] }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setOrdersPage1(response.orders);
        });

      fetch(`http://34.129.244.90/shopifyordersnzlink`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ link: pages[1] }),
      })
        .then((response) => response.json())
        .then((response) => {
          setOrdersPage2(response.orders);
          dispatch(setLoadingOrders(false));
        });
    }

    if (pages.length !== 0 && store === "aushopify") {
      fetch(`http://34.129.244.90/shopifyordersaulink`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ link: pages[0] }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setOrdersPage1(response.orders);
        });

      fetch(`http://34.129.244.90/shopifyordersaulink`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ link: pages[1] }),
      })
        .then((response) => response.json())
        .then((response) => {
          setOrdersPage2(response.orders);
          dispatch(setLoadingOrders(false));
        });
    }

    if (pages.length !== 0 && store === "usshopify") {
      fetch(`http://34.129.244.90/shopifyordersuslink`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ link: pages[0] }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setOrdersPage1(response.orders);
        });

      fetch(`http://34.129.244.90/shopifyordersuslink`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ link: pages[1] }),
      })
        .then((response) => response.json())
        .then((response) => {
          setOrdersPage2(response.orders);
          dispatch(setLoadingOrders(false));
        });
    }
  }, [pages]);

  useEffect(() => {
    // dispatch(setOrdersNzShopify([...orderspage1, ...orderspage2]))

    setOrdersNzShopifyRaw([...orderspage1, ...orderspage2]);
  }, [orderspage1, orderspage2]);

  useEffect(() => {
    if (selected_supplier === "dropshipzone") {
      let array = [];
      orders_nz_shopify_raw.map((order) => {
        if (order.line_items.length > 1) {
          console.log("problem order", order);
          for (let index = 0; index < order.line_items.length; index++) {
            let order1 = { ...order };
            order1.dropship_order_no = "";
            if (
              order1.referring_site !== null &&
              order1.referring_site.includes("trademe")
            ) {
              order1.dropship_order_no =
                order1.note.slice(52) + "_" + String(index);
            }
            if (order1.source_name === "web") {
              order1.dropship_order_no = order1.name + "_" + String(index);
            }
            if (order1.tags && order1.tags.includes("TheMarket")) {
              order1.dropship_order_no =
                order1.source_identifier + "_" + String(index);
            }
            if (order1.tags && order1.tags.includes("Onceit")) {
              order1.dropship_order_no =
                order1.source_identifier + "_" + String(index);
            }
            order1.line_items = [order.line_items[index]];
            console.log("problem resolved", order1);
            array.push(order1);
          }
        } else {
          order.dropship_order_no = "";
          if (
            order.referring_site !== null &&
            order.referring_site.includes("trademe")
          ) {
            order.dropship_order_no = order.note.slice(52);
          }
          if (order.source_name === "web") {
            order.dropship_order_no = order.name;
          }
          if (order.tags && order.tags.includes("TheMarket")) {
            order.dropship_order_no = order.source_identifier;
          }
          if (order.tags && order.tags.includes("Onceit")) {
            order.dropship_order_no = order.source_identifier;
          }
          array.push(order);
        }
      });

      console.log("problem resolved orders", array);
      dispatch(setOrdersNzShopify(array));
    } else {
      dispatch(setOrdersNzShopify(orders_nz_shopify_raw));
    }

    //  testing finish
  }, [orders_nz_shopify_raw, selected_supplier]);

  useEffect(() => {
    const drop_orders = [];
    const fragrance_orders = [];
    const aliexpress_orders = [];
    orders_nz_shopify.map((order) => {
      if (
        order.line_items.filter(
          (item) =>
            item.vendor === "DZAU" ||
            item.vendor === "DZAUNEW" ||
            item.vendor === "DZAUV"
        ).length !== 0
      ) {
        drop_orders.push(order);
      }
    });

    orders_nz_shopify.map((order) => {
      if (
        order.line_items.filter((item) => item.vendor === "GOUS").length !== 0
      ) {
        fragrance_orders.push(order);
      }
    });

    orders_nz_shopify.map((order) => {
      if (
        order.line_items.filter((item) => item.vendor === "PDUS").length !== 0
      ) {
        fragrance_orders.push(order);
      }
    });

    orders_nz_shopify.map((order) => {
      let sku_list = [];
      order.line_items.map((item) => {
        sku_list.push(item.sku);
      });

      sku_list.map((sku) => {
        fetch(`http://34.129.244.90/isthisaliexpresssku`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ sku: sku }),
        })
          .then((response) => response.json())
          .then((response) => {
            console.log("aliexpress", response);

            if (response === "Yes") {
              aliexpress_orders.push(order);
            }
          })
          .then((response) => {
            dispatch(setAliExpressOrders(aliexpress_orders));
          });
      });
    });

    dispatch(setDropshipOrders(drop_orders));
    dispatch(setFragrancexOrders(fragrance_orders));
  }, [orders_nz_shopify]);

  useEffect(() => {
    const date_orders = [];
    if (selected_supplier === "") {
      orders_nz_shopify.map((order) => {
        let date = new Date(order.created_at);
        let to = new Date(order_to_date);
        let from = new Date(order_from_date);
        if (order_from_date !== "" && order_to_date !== "") {
          if (
            date.getTime() <= to.getTime() &&
            date.getTime() >= from.getTime()
          ) {
            date_orders.push(order);
          }
        } else {
          date_orders.push(order);
        }
      });
    }
    if (selected_supplier === "dropshipzone") {
      dropship_orders.map((order) => {
        let date = new Date(order.created_at);
        let to = new Date(order_to_date);
        let from = new Date(order_from_date);
        if (order_from_date !== "" && order_to_date !== "") {
          if (
            date.getTime() <= to.getTime() &&
            date.getTime() >= from.getTime()
          ) {
            date_orders.push(order);
          }
        } else {
          date_orders.push(order);
        }
      });
    }

    if (selected_supplier === "fragrancex") {
      fragrancex_orders.map((order) => {
        let date = new Date(order.created_at);
        let to = new Date(order_to_date);
        let from = new Date(order_from_date);
        if (order_from_date !== "" && order_to_date !== "") {
          if (
            date.getTime() <= to.getTime() &&
            date.getTime() >= from.getTime()
          ) {
            date_orders.push(order);
          }
        } else {
          date_orders.push(order);
        }
      });
    }

    if (selected_supplier === "aliexpress") {
      aliexpress_orders.map((order) => {
        let date = new Date(order.created_at);
        let to = new Date(order_to_date);
        let from = new Date(order_from_date);
        if (order_from_date !== "" && order_to_date !== "") {
          if (
            date.getTime() <= to.getTime() &&
            date.getTime() >= from.getTime()
          ) {
            date_orders.push(order);
          }
        } else {
          date_orders.push(order);
        }
      });
    }

    console.log("date_orders", date_orders);
    console.log("Search", searchField);

    dispatch(
      setOrdersFromTo(
        date_orders.filter((order) =>
          order.name.toLowerCase().includes(searchField.toLowerCase())
        )
      )
    );
    setCount(count + 1);
  }, [
    order_to_date,
    order_from_date,
    selected_supplier,
    orders_nz_shopify,
    dropship_orders,
    searchField,
    fragrancex_orders,
    aliexpress_orders,
  ]);

  const handleSelectedPurchase = () => {
    selectedOrders.map((order) => {
      if (order.line_items.length > 1) {
        console.log("do nothing");
      } else {
        order.line_items.map((item) => {
          handleBulkPurchase(item, order);
        });
      }
    });
  };

  const handleBulkPurchase = async (item, order) => {
    await fetch(`http://34.129.244.90/dropshiptoken`, {
      method: "post",
      body: JSON.stringify({
        email: "sales@salespro.net.nz",
        password: "na+axuh9bayl_a#5=Me0",
      }),
    })
      .then((response) => response.json())
      .then(async (response) => {
        console.log(response);
        await fetch(`http://34.129.244.90/placeorder`, {
          method: "post",
          body: JSON.stringify({
            token: "jwt " + response.token,
            data: {
              your_order_no: order.dropship_order_no,
              first_name: "Export Department",
              last_name: order.name,
              address1: "118 Denison Street",
              address2: "AMS",
              suburb: "Hillsdale",
              state: "New South Wales",
              postcode: "2036",
              telephone: "409980939",
              comment:
                "All deliveries must be delivered to the warehouse roller door and no where else",
              order_items: [{ sku: item.sku, qty: item.quantity }],
            },
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            console.log(response);
            if (response.status === -1) {
              setOpenFailed({
                open: true,
                message: response.errmsg,
                order: order,
              });
              console.log("fail response", response);
            }
            if (response.status === 1) {
              console.log("success response", response);
              setOpen({ open: true, message: "Order Placed Successfully" });
              handleSave(order);
            }
            if (Array.isArray(response)) {
              if (response[0].status === -1) {
                setOpenFailed({
                  open: true,
                  message: response[0].errmsg,
                  order: order,
                });
              }
              if (response[0].status === 1) {
                setOpen({ open: true, message: "Order Placed Successfully" });
                handleSave(order);
              }
            }
          });
      });
  };

  const handlePurchase = async (item, order) => {
    if (selected_supplier === "dropshipzone") {
      await fetch(`http://34.129.244.90/dropshiptoken`, {
        method: "post",
        body: JSON.stringify({
          email: "sales@salespro.net.nz",
          password: "na+axuh9bayl_a#5=Me0",
        }),
      })
        .then((response) => response.json())
        .then(async (response) => {
          console.log("token", response);
          await fetch(`http://34.129.244.90/placeorder`, {
            method: "post",
            body: JSON.stringify({
              token: "jwt " + response.token,
              data: {
                your_order_no: order.dropship_order_no,
                first_name: "Export Department",
                last_name: order.name,
                address1: "118 Denison Street",
                address2: "AMS",
                suburb: "Hillsdale",
                state: "New South Wales",
                postcode: "2036",
                telephone: "409980939",
                comment:
                  "All deliveries must be delivered to the warehouse roller door and no where else",
                order_items: [{ sku: item.sku, qty: item.quantity }],
              },
            }),
          })
            .then((response) => response.json())
            .then((response) => {
              console.log("orderresponse", response);
              if (response.status === -1) {
                setOpenFailed({
                  open: true,
                  message: response.errmsg,
                  order: order,
                });
                console.log("fail response", response);
              }
              if (response.status === 1) {
                console.log("success response", response);
                setOpen({ open: true, message: "Order Placed Successfully" });
                handleSave(order);
              }
              if (Array.isArray(response)) {
                if (response[0].status === -1) {
                  setOpenFailed({
                    open: true,
                    message: response[0].errmsg,
                    order: order,
                  });
                }
                if (response[0].status === 1) {
                  setOpen({ open: true, message: "Order Placed Successfully" });
                  handleSave(order);
                }
              }
            });
        });
    }
    if (selected_supplier === "fragrancex") {
      fetch("http://localhost:3001/placefragrancexorder", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ order: order }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("fragrance", response);
          if (response.Message === "Order 1 failed") {
            setOpenFailed({
              open: true,
              message: response.OrderResults[0].Message,
              order: order,
            });
          } else {
            setOpen({ open: true, message: response.Message });
            handleSave(order);
          }
        });
    }

    if (selected_supplier === "aliexpress") {
      fetch("http://34.129.244.90/placealiexpressorder", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ order: order }),
      });
    }
  };

  useEffect(() => {}, [open]);

  const handleSave = (order) => {
    console.log(JSON.stringify(order));
    fetch(`http://34.129.244.90/createorder`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(order),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCount(count + 1);
      });
  };

  console.log(orders_nz_shopify);
  console.log(dropship_orders);

  console.log("pages", pages);
  console.log("orders", orders_from_to);
  console.log("orderspage1", orderspage1);
  console.log("orderspage2", orderspage2);
  console.log("purchasedorderscheck", purchasedorderscheck);
  console.log("id", document.getElementById(4220668084404));
  // console.log("purchasedorderscheck", purchasedorderscheck.filter(element => (element.orderid === order.name && JSON.parse(element.line_items)[0].id === order.line_items[0].id)))
  return (
    <Grid container>
      <Grid item xs={1} sm={1} lg={1} style={{ marginTop: "2%" }}>
        <Grid item xs={12} sm={12} lg={12} style={{ marginLeft: "3%" }}>
          {show_orders === "to_purchase" ? (
            <Button variant="contained">To Purchase</Button>
          ) : (
            <Button onClick={(event) => dispatch(setShowOrders("to_purchase"))}>
              To Purchase
            </Button>
          )}

          {show_orders === "purchased" ? (
            <Button style={{ marginTop: "3%" }} variant="contained">
              PURCHASED
            </Button>
          ) : (
            <Button
              style={{ marginTop: "3%" }}
              onClick={(event) => dispatch(setShowOrders("purchased"))}
            >
              PURCHASED
            </Button>
          )}
          {show_orders === "shipped" ? (
            <Button variant="contained">SHIPPED</Button>
          ) : (
            <Button onClick={(event) => dispatch(setShowOrders("shipped"))}>
              SHIPPED
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid
        style={{ marginLeft: "1%", marginRight: "1%", marginTop: "1%" }}
        item
        xs={10}
        sm={10}
        lg={10}
      >
        <Card>
          <CardHeader title="Quick filter" />
          <CardContent>
            <Grid container>
              <Grid item xs={1} sm={1} lg={1}>
                Shopify Store:
              </Grid>
              <Grid item xs={1} sm={1} lg={1}>
                <Button disabled>ALL</Button>
              </Grid>
              <Grid item xs={1} sm={1} lg={1}>
                {store === "nzshopify" ? (
                  <Button variant="contained">NZ Shopify</Button>
                ) : (
                  <Button
                    onClick={(event) => {
                      window.localStorage.setItem("store", "nzshopify");
                      dispatch(setStore("nzshopify"));
                    }}
                  >
                    NZ Shopify
                  </Button>
                )}
              </Grid>
              <Grid item xs={1} sm={1} lg={1}>
                {store === "aushopify" ? (
                  <Button variant="contained">AU Shopify</Button>
                ) : (
                  <Button
                    onClick={(event) => {
                      dispatch(setStore("aushopify"));
                      window.localStorage.setItem("store", "aushopify");
                    }}
                  >
                    AU Shopify
                  </Button>
                )}
              </Grid>
              <Grid item xs={1} sm={1} lg={1}>
                {store === "usshopify" ? (
                  <Button variant="contained">US Shopify</Button>
                ) : (
                  <Button
                    onClick={(event) => {
                      dispatch(setStore("usshopify"));
                      window.localStorage.setItem("store", "usshopify");
                    }}
                  >
                    US Shopify
                  </Button>
                )}
              </Grid>
              <Grid container>
                <Grid item xs={1} sm={1} lg={1}>
                  <Typography
                    style={{ fontSize: "20px", fontFamily: "Garamond" }}
                  >
                    Warehouses:
                  </Typography>
                </Grid>

                <Grid item xs={4} sm={4} lg={4}>
                  <Button disabled>ALL</Button>

                  <Button
                    style={{ fontFamily: "Garamond", fontSize: "14px" }}
                    disabled
                  >
                    AU
                  </Button>

                  <Button
                    style={{ fontFamily: "Garamond", fontSize: "14px" }}
                    disabled
                  >
                    CN
                  </Button>

                  <Button
                    style={{ fontFamily: "Garamond", fontSize: "14px" }}
                    disabled
                  >
                    NZ
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={1} sm={1} lg={1}>
                Supplier:
              </Grid>
              <Grid item xs={1} sm={1} lg={1}>
                {selected_supplier === "" ? (
                  <Button variant="contained">All</Button>
                ) : (
                  <Button
                    onClick={(event) => dispatch(setSelectedSupplier(""))}
                  >
                    All
                  </Button>
                )}
              </Grid>
              <Grid item xs={1} sm={1} lg={1}>
                {selected_supplier === "bangood" ? (
                  <Button variant="contained">Bangood</Button>
                ) : (
                  <Button
                    onClick={(event) =>
                      dispatch(setSelectedSupplier("bangood"))
                    }
                  >
                    Banggood
                  </Button>
                )}
              </Grid>
              {selected_supplier === "aliexpress" ? (
                <Button variant="contained">AliExpress</Button>
              ) : (
                <Button
                  onClick={(event) => {
                    dispatch(setSelectedSupplier("aliexpress"));
                  }}
                >
                  AliExpress
                </Button>
              )}

              <Button
                disabled
                style={{
                  marginLeft: "1%",
                  fontFamily: "Garamond",
                  fontSize: "14px",
                }}
              >
                DropSite
              </Button>

              <Button
                disabled
                style={{
                  marginLeft: "1%",
                  fontFamily: "Garamond",
                  fontSize: "14px",
                }}
              >
                BigBuy
              </Button>

              <Button
                disabled
                style={{
                  marginLeft: "1%",
                  fontFamily: "Garamond",
                  fontSize: "14px",
                }}
              >
                Chinavasion
              </Button>

              <Button
                disabled
                style={{
                  marginLeft: "1%",
                  fontFamily: "Garamond",
                  fontSize: "14px",
                }}
              >
                CJDropshipping
              </Button>
              {selected_supplier === "dropshipzone" ? (
                <Button variant="contained">Dropshipzone</Button>
              ) : (
                <Button
                  onClick={(event) => {
                    dispatch(setSelectedSupplier("dropshipzone"));
                  }}
                >
                  Dropshipzone
                </Button>
              )}
              <Button
                disabled
                style={{
                  marginLeft: "1%",
                  fontFamily: "Garamond",
                  fontSize: "14px",
                }}
              >
                Dove
              </Button>

              <Button
                disabled
                style={{
                  marginLeft: "1%",
                  fontFamily: "Garamond",
                  fontSize: "14px",
                }}
              >
                FragranceNet
              </Button>
              {selected_supplier === "fragrancex" ? (
                <Button variant="contained">FragranceX</Button>
              ) : (
                <Button
                  onClick={(event) => {
                    dispatch(setSelectedSupplier("fragrancex"));
                  }}
                >
                  FragranceX
                </Button>
              )}
            </Grid>
            <Grid container>
              <Grid item xs={1} sm={1} lg={1}>
                <Typography
                  style={{ fontSize: "20px", fontFamily: "Garamond" }}
                >
                  Countries:
                </Typography>
              </Grid>

              <Grid item xs={5} sm={5} lg={5}>
                <Button disabled>ALL</Button>

                <Button
                  style={{ fontFamily: "Garamond", fontSize: "14px" }}
                  disabled
                >
                  Australia
                </Button>

                <Button
                  style={{ fontFamily: "Garamond", fontSize: "14px" }}
                  disabled
                >
                  China
                </Button>

                <Button
                  style={{ fontFamily: "Garamond", fontSize: "14px" }}
                  disabled
                >
                  New Zealand
                </Button>

                <Button
                  style={{ fontFamily: "Garamond", fontSize: "14px" }}
                  disabled
                >
                  United Kingdom
                </Button>

                <Button
                  style={{ fontFamily: "Garamond", fontSize: "14px" }}
                  disabled
                >
                  United States
                </Button>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "1%" }}>
              <Grid item xs={1} sm={1} lg={1}>
                <Typography
                  style={{ fontSize: "20px", fontFamily: "Garamond" }}
                >
                  Order Status:
                </Typography>
              </Grid>

              <FormControl style={{ width: "12%" }} size="small">
                <InputLabel
                  style={{ fontFamily: "Garamond" }}
                  id="demo-simple-select-autowidth-label"
                >
                  Payment Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={paymentStatus}
                  onChange={handleChange}
                  label="Payment Status"
                >
                  <MenuItem value="">
                    <em style={{ fontFamily: "Garamond" }}>None</em>
                  </MenuItem>
                  <MenuItem style={{ fontFamily: "Garamond" }} value={10}>
                    Paid
                  </MenuItem>
                  <MenuItem style={{ fontFamily: "Garamond" }} value={20}>
                    Unpaid
                  </MenuItem>
                </Select>
              </FormControl>

              <FormControl
                style={{ width: "12%", marginLeft: "2%" }}
                size="small"
              >
                <InputLabel
                  style={{ fontFamily: "Garamond" }}
                  id="demo-simple-select-autowidth-label"
                >
                  Fulfillment Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={paymentStatus}
                  onChange={handleChange}
                  label="Payment Status"
                >
                  <MenuItem value="">
                    <em style={{ fontFamily: "Garamond" }}>None</em>
                  </MenuItem>
                  <MenuItem style={{ fontFamily: "Garamond" }} value={10}>
                    Fulfilled
                  </MenuItem>
                  <MenuItem style={{ fontFamily: "Garamond" }} value={20}>
                    Unfulfilled
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid container style={{ marginTop: "1%" }}>
              <Grid item xs={1} sm={1} lg={1}>
                Date:
              </Grid>
              <Grid item xs={11} sm={11} lg={11}>
                <NativePickers />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid container style={{ marginTop: "1%" }}>
        <Grid item xs={3} sm={3} lg={3}>
          {show_orders === "to_purchase" && (
            <div style={{ marginLeft: "35%", marginTop: "5%" }}>
              <MainCheckbox
                orders_from_to={orders_from_to}
                start={start}
                end={end}
                setSelectedOrders={setSelectedOrders}
                selectedOrders={selectedOrders}
              />
              {selectedOrders.length !== 0 ? (
                <div>
                  <Button
                    style={{ marginLeft: "2%" }}
                    variant="outlined"
                    onClick={(event) => handleSelectedPurchase(event)}
                  >
                    PURCHASE SELECTED
                  </Button>
                  <div
                    style={{
                      fontSize: "12px",
                      color: "blue",
                      marginLeft: "2%",
                    }}
                  >
                    Selected Orders: {selectedOrders.length}
                  </div>
                </div>
              ) : (
                <Button style={{ marginLeft: "2%" }} disabled>
                  PURCHASE SELECTED
                </Button>
              )}
            </div>
          )}
          {show_orders === "shipped" && (
            <div style={{ marginLeft: "35%", marginTop: "5%" }}>
              <div></div>
            </div>
          )}
        </Grid>
        <Grid
          item
          xs={0.5}
          sm={0.5}
          lg={0.5}
          style={{
            fontFamily: "Garamond",
            backgroundColor: "",
            marginLeft: "2%",
          }}
        >
          <Typography style={{ fontSize: "12px", marginTop: "26%" }} />
          Sort By:
        </Grid>

        <FormControl
          style={{ width: "10%", marginTop: "0.5%", margi: "5%" }}
          size="small"
        >
          <InputLabel
            style={{ fontFamily: "Garamond" }}
            id="demo-simple-select-autowidth-label"
          >
            Order Details
          </InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={paymentStatus}
            onChange={handleChange}
            label="Payment Status"
          >
            <MenuItem value="">
              <em style={{ fontFamily: "Garamond" }}>None</em>
            </MenuItem>
            <MenuItem style={{ fontFamily: "Garamond" }} value={10}>
              Order Number
            </MenuItem>
            <MenuItem style={{ fontFamily: "Garamond" }} value={20}>
              Date
            </MenuItem>
            <MenuItem style={{ fontFamily: "Garamond" }} value={30}>
              Customer name
            </MenuItem>
            <MenuItem style={{ fontFamily: "Garamond" }} value={40}>
              Payment status
            </MenuItem>
            <MenuItem style={{ fontFamily: "Garamond" }} value={50}>
              Fulfillment status
            </MenuItem>
            <MenuItem style={{ fontFamily: "Garamond" }} value={60}>
              Total
            </MenuItem>
          </Select>
        </FormControl>
        <Grid
          item
          xs={4}
          sm={4}
          lg={4}
          style={{ textAlign: "center", marginTop: "1%", marginLeft: "2%" }}
        >
          <PaginationOutlined_orders
            selectedOrders={selectedOrders}
            setSelectedOrders={setSelectedOrders}
          />
        </Grid>

        <Grid
          item
          xs={1}
          sm={1}
          lg={1}
          style={{ textAlign: "center", marginBottom: "1%" }}
        >
          <Searchbar />
        </Grid>
      </Grid>
      {show_orders === "to_purchase" &&
        orders_from_to.slice(start, end).map((order) => {
          return (
            <Grid container>
              <Grid item xs={1} sm={1} lg={1}></Grid>
              <Grid
                item
                xs={10}
                sm={10}
                lg={10}
                style={{ marginLeft: "1%", marginRight: "1%", marginTop: "1%" }}
              >
                <Card style={{ backgroundColor: "#f2f7f7" }}>
                  <CardContent>
                    {order.line_items.map((item, index) => {
                      return (
                        <div style={{ marginTop: "1%" }}>
                          <Paper
                            style={{
                              display: "grid",
                              gridTemplateColumns: "2% 7% 33% 10% 10% 35%",
                              gridGap: "1%",
                              backgroundColor: "",
                              minHeight: "60px",
                            }}
                          >
                            <div
                              style={{ marginLeft: "10%", marginTop: "10%" }}
                            >
                              <ControlledCheckbox
                                order={order}
                                setSelectedOrders={setSelectedOrders}
                                selectedOrders={selectedOrders}
                              />
                            </div>
                            <div
                              style={{
                                marginLeft: "1%",
                                fontSize: "12px",
                                marginTop: "2%",
                              }}
                            >
                              <Image
                                productid={item.product_id}
                                order={order}
                              />
                            </div>
                            <div
                              style={{
                                marginLeft: "1%",
                                fontSize: "12px",
                                marginTop: "2%",
                              }}
                            >
                              <div>SKU: {item.sku}</div>
                              <div>Title: {item.name}</div>
                              <div>Vendor: {item.vendor}</div>
                            </div>
                            <div
                              style={{
                                marginLeft: "1%",
                                fontSize: "12px",
                                marginTop: "10%",
                              }}
                            ></div>
                            <div
                              style={{
                                marginLeft: "1%",
                                fontSize: "12px",
                                marginTop: "10%",
                              }}
                            >
                              <div>Sold at: NZ$ {item.price}</div>
                            </div>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "70% 10%",
                              }}
                            >
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "20% 20%",
                                }}
                              >
                                <div
                                  style={{
                                    marginLeft: "1%",
                                    marginBottom: "1%",
                                    marginTop: "10%",
                                  }}
                                >
                                  <Button variant="contained" size="small">
                                    Edit
                                  </Button>
                                </div>
                                <div
                                  style={{
                                    marginLeft: "0%",
                                    marginBottom: "1%",
                                    marginTop: "10%",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                  >
                                    Save
                                  </Button>
                                </div>
                              </div>
                              {purchasedorderscheck.filter(
                                (element) =>
                                  element.orderid === order.name &&
                                  JSON.parse(element.line_items)[0].id ===
                                    order.line_items[0].id
                              ).length === 0 ? (
                                <div
                                  style={{
                                    marginLeft: "0%",
                                    marginBottom: "1%",
                                    marginTop: "10%",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    color="success"
                                    size="small"
                                    onClick={(event) =>
                                      handlePurchase(item, order)
                                    }
                                  >
                                    Purchase
                                  </Button>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    marginLeft: "0%",
                                    marginBottom: "1%",
                                    marginTop: "10%",
                                  }}
                                >
                                  <Button
                                    disabled
                                    variant="contained"
                                    color="success"
                                    size="small"
                                    onClick={(event) =>
                                      handlePurchase(item, order)
                                    }
                                  >
                                    Purchased
                                  </Button>
                                </div>
                              )}
                            </div>
                          </Paper>
                        </div>
                      );
                    })}
                  </CardContent>
                  <Card
                    style={{
                      marginTop: "1%",
                      marginLeft: "1%",
                      marginRight: "1%",
                      marginBottom: "2%",
                    }}
                  >
                    <CardHeader
                      subheader={
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "20% 20% 50%",
                            gridGap: "1%",
                          }}
                        >
                          <div>
                            <div style={{ fontSize: "12px" }}>
                              {`Order No. ${order.name}`}
                            </div>
                            <div style={{ fontSize: "12px" }}>
                              {`Received at: ${order.created_at}`}
                            </div>

                            <div style={{ fontSize: "12px" }}>
                              Notes: {order.note === null ? "N/A" : order.note}
                            </div>
                            <div style={{ fontSize: "12px" }}>
                              Additional Details: {order.source_identifier}
                            </div>
                          </div>
                          <Card>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "100%",
                                marginLeft: "1%",
                              }}
                            >
                              <div style={{ fontSize: "12px" }}>
                                Name:{" "}
                                {order.shipping_address
                                  ? order.shipping_address.name
                                  : ""}
                              </div>
                              <div style={{ fontSize: "12px" }}>
                                Phone:{" "}
                                {order.shipping_address
                                  ? order.shipping_address.phone
                                  : ""}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "100%",
                                marginLeft: "1%",
                              }}
                            >
                              <div style={{ fontSize: "12px" }}>
                                Address:{" "}
                                {order.shipping_address
                                  ? order.shipping_address.address1 +
                                    order.shipping_address.address2
                                  : ""}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "100%",
                                marginLeft: "1%",
                              }}
                            >
                              <div style={{ fontSize: "12px" }}>
                                City:{" "}
                                {order.shipping_address
                                  ? order.shipping_address.city
                                  : ""}
                              </div>
                              <div style={{ fontSize: "12px" }}>
                                Province:{" "}
                                {order.shipping_address
                                  ? order.shipping_address.province
                                  : ""}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "100%",
                                marginLeft: "1%",
                              }}
                            >
                              <div style={{ fontSize: "12px" }}>
                                Zip:{" "}
                                {order.shipping_address
                                  ? order.shipping_address.zip
                                  : ""}
                              </div>
                            </div>
                          </Card>
                          <Card>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "20% 30% 20% 30%",
                                marginLeft: "1%",
                              }}
                            >
                              <div style={{ fontSize: "12px" }}>
                                Your Order No:
                              </div>
                              <div style={{ fontSize: "12px" }}>
                                <input value={order.dropship_order_no} />
                              </div>

                              {order.line_items.map((item) => {
                                return (
                                  <>
                                    <div style={{ fontSize: "12px" }}>SKU:</div>
                                    <div style={{ fontSize: "12px" }}>
                                      <input value={item.sku} />
                                    </div>
                                    <div style={{ fontSize: "12px" }}>
                                      Quantity:
                                    </div>
                                    <div style={{ fontSize: "12px" }}>
                                      <input value={item.quantity} />
                                    </div>
                                  </>
                                );
                              })}
                              <div style={{ fontSize: "12px" }}>
                                First Name:
                              </div>
                              <div style={{ fontSize: "12px" }}>
                                <input value="Export Department" />
                              </div>
                              <div style={{ fontSize: "12px" }}>Last Name:</div>
                              <div style={{ fontSize: "12px" }}>
                                <input value={order.name} />
                              </div>
                              <div style={{ fontSize: "12px" }}>
                                Address Line1:
                              </div>
                              <div style={{ fontSize: "12px" }}>
                                <input value="118 Denison Street" />
                              </div>
                              <div style={{ fontSize: "12px" }}>
                                Address Line2:
                              </div>
                              <div style={{ fontSize: "12px" }}>
                                <input value="AMS" />
                              </div>
                              <div style={{ fontSize: "12px" }}>Suburb:</div>
                              <div style={{ fontSize: "12px" }}>
                                <input value="Hillsdale" />
                              </div>
                              <div style={{ fontSize: "12px" }}>Post Code:</div>
                              <div style={{ fontSize: "12px" }}>
                                <input value="2036" />
                              </div>
                              <div style={{ fontSize: "12px" }}>State:</div>
                              <div style={{ fontSize: "12px" }}>
                                <input value="New South Wales" />
                              </div>
                              <div style={{ fontSize: "12px" }}>Phone:</div>
                              <div style={{ fontSize: "12px" }}>
                                <input value="409980939" />
                              </div>
                            </div>
                          </Card>
                        </div>
                      }
                    />
                  </Card>
                </Card>
              </Grid>
            </Grid>
          );
        })}
      {show_orders === "purchased" && <PurchasedOrders />}
      {show_orders === "shipped" && <ShippedOrders />}
      {loading_orders === true ? (
        <Grid item xs={12} sm={12} lg={12} style={{ marginLeft: "50%" }}>
          <Loader size="30px" />
        </Grid>
      ) : (
        ""
      )}
      <CustomizedSnackbars
        handleClose={handleClose}
        open={open.open}
        message={open.message}
      />
      <CustomizedSnackbarsFailed
        handleClose={handleCloseFailed}
        open={openfailed.open}
        message={openfailed.message}
        handleSave={handleSave}
        order={openfailed.order}
      />
    </Grid>
  );
}

export default Orders;
