import React, { useEffect, useState } from "react";
import { Grid, Button, Chip, TextField, Autocomplete } from "@mui/material";
import { SmallContainedButton } from "../common/SmallContainedButton";
import { InStockCheckbox } from "./instockcheckbox";
import { HasVideoCheckbox } from "./hasvideocheckbox";

export default function BigBuyCategorySelect({
  selectedBigBuyCategoryLevel1,
  setSelectedBigBuyCategoryLevel1,
  selectedBigBuyCategoryLevel2,
  setSelectedBigBuyCategoryLevel2,
  selectedBigBuyCategoryLevel3,
  setSelectedBigBuyCategoryLevel3,
  selectedBigBuyBrand,
  setSelectedBigBuyBrand,
  selectedBigBuyCondition,
  setSelectedBigBuyCondition,
  skulist,
  setSkuList,
  filterby,
  loading_products,
  fetchproductcount,
  setFetchProductCount,
}) {
  const [categorieslevel1, setCategoriesLevel1] = useState([]);
  const [categorieslevel2, setCategoriesLevel2] = useState([]);
  const [categorieslevel3, setCategoriesLevel3] = useState([]);
  const [bigBuyBrands, setBigBuyBrands] = useState([]);
  const [bigBuyConditions, setBigBuyConditions] = useState([]);

  useEffect(() => {
    fetch("https://api.saleprodev.com/productimporter/bigbuycategorieslevel1")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setCategoriesLevel1(res.categories_level_1);
      });

    fetch("https://api.saleprodev.com/productimporter/bigbuybrands")
      .then((res) => res.json())
      .then((res) => {
        console.log("bigbuybrands", res);
        setBigBuyBrands(res.brands);
      });

    fetch("https://api.saleprodev.com/productimporter/bigbuyconditions")
      .then((res) => res.json())
      .then((res) => {
        console.log("bigbuyconditions", res);
        setBigBuyConditions(res.conditions);
      });
  }, []);

  useEffect(() => {
    setSelectedBigBuyCategoryLevel2(null);
    setSelectedBigBuyCategoryLevel3(null);
    if (selectedBigBuyCategoryLevel1 !== null) {
      fetch(
        "https://api.saleprodev.com/productimporter/bigbuycategorieslevel2",
        {
          method: "post",
          body: JSON.stringify({
            category: selectedBigBuyCategoryLevel1.id,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setCategoriesLevel2(res.categories_level_2);
        });
    }
  }, [selectedBigBuyCategoryLevel1]);

  useEffect(() => {
    setSelectedBigBuyCategoryLevel3(null);
    if (
      selectedBigBuyCategoryLevel1 !== null &&
      selectedBigBuyCategoryLevel2 !== null
    ) {
      fetch(
        "https://api.saleprodev.com/productimporter/bigbuycategorieslevel3",
        {
          method: "post",
          body: JSON.stringify({
            category: selectedBigBuyCategoryLevel2.id,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setCategoriesLevel3(res.categories_level_3);
        });
    }
  }, [selectedBigBuyCategoryLevel2]);

  const handleChange = (event, value) => {
    console.log("value", value);
    if (value === null) {
      setSelectedBigBuyCategoryLevel1(null);
    } else {
      setSelectedBigBuyCategoryLevel1(value);
    }
  };

  const handleChange2 = (event, value) => {
    console.log("value", value);
    if (value === null) {
      setSelectedBigBuyCategoryLevel2(null);
    } else {
      setSelectedBigBuyCategoryLevel2(value);
    }
  };

  const handleChange3 = (event, value) => {
    console.log("value", value);
    if (value === null) {
      setSelectedBigBuyCategoryLevel3(null);
    } else {
      setSelectedBigBuyCategoryLevel3(value);
    }
  };

  const handleChangeBigBuyBrand = (event, value) => {
    console.log("brand", value);
    if (value === null) {
      setSelectedBigBuyBrand("");
    } else {
      setSelectedBigBuyBrand(value);
    }
  };

  const handleChangeBigBuyCondition = (event, value) => {
    console.log("Condition", value);
    if (value === null) {
      setSelectedBigBuyCondition("");
    } else {
      setSelectedBigBuyCondition(value);
    }
  };

  const defaultProps1 = {
    options: categorieslevel1,
    getOptionLabel: (option) => option.name,
  };

  const defaultProps2 = {
    options: categorieslevel2,
    getOptionLabel: (option) => option.name,
  };

  const defaultProps3 = {
    options: categorieslevel3,
    getOptionLabel: (option) => option.name,
  };

  const defaultProps4 = {
    options: bigBuyBrands,
    getOptionLabel: (option) => option,
  };

  const defaultProps5 = {
    options: bigBuyConditions,
    getOptionLabel: (option) => option,
  };

  return filterby === "category" ? (
    <>
      <Autocomplete
        size="small"
        sx={{
          mr: 0.5,
          width: "190px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        {...defaultProps1}
        value={selectedBigBuyCategoryLevel1}
        onChange={(event, value) => handleChange(event, value)}
        renderInput={(params) => (
          <TextField placeholder="Select A Category" {...params} />
        )}
      />
      <Autocomplete
        size="small"
        sx={{
          mr: 0.5,
          width: "190px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        {...defaultProps2}
        value={selectedBigBuyCategoryLevel2}
        onChange={(event, value) => handleChange2(event, value)}
        renderInput={(params) => (
          <TextField placeholder="Select A Category" {...params} />
        )}
      />
      <Autocomplete
        size="small"
        sx={{
          mr: 0.5,
          width: "190px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        {...defaultProps3}
        value={selectedBigBuyCategoryLevel3}
        onChange={(event, value) => handleChange3(event, value)}
        renderInput={(params) => (
          <TextField placeholder="Select A Category" {...params} />
        )}
      />

      <Autocomplete
        size="small"
        sx={{
          mr: 0.5,
          width: "190px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        {...defaultProps4}
        value={selectedBigBuyBrand}
        onChange={(event, value) => handleChangeBigBuyBrand(event, value)}
        renderInput={(params) => (
          <TextField placeholder="Select A Brand" {...params} />
        )}
      />

      <Autocomplete
        size="small"
        sx={{
          mr: 0.5,
          width: "190px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        {...defaultProps5}
        value={selectedBigBuyCondition}
        onChange={(event, value) => handleChangeBigBuyCondition(event, value)}
        renderInput={(params) => (
          <TextField placeholder="Select Condition" {...params} />
        )}
      />
      <InStockCheckbox />
      <HasVideoCheckbox />
    </>
  ) : (
    <>
      <Grid item sx={{ position: "relative" }}>
        <TextField
          multiline
          maxRows={5}
          placeholder="Enter SKUs"
          onChange={(event) => setSkuList(event.target.value)}
          sx={{
            mr: 0.5,
            width: "500px",
            fontSize: "0.8rem",
            "& legend": {
              width: "unset",
            },
            "& .MuiFormControl-root, .MuiOutlinedInput-root": {
              p: "0px!important",
              minHeight: 26,
            },
          }}
        />

        {skulist === "" ? (
          ""
        ) : (
          <Chip
            size="small"
            color="primary"
            variant="outlined"
            label={`${skulist.split(/\r\n|\r|\n/).length} Items`}
            sx={{
              height: 20,
              position: "absolute",
              transform:
                skulist.split(/\r\n|\r|\n/).length <= 1
                  ? "translate(-95px, 3px)"
                  : "translate(-95px, 6px)",
            }}
          />
        )}
      </Grid>

      <Grid item>
        <SmallContainedButton
          width="60px"
          disabled={loading_products ? true : false}
          onClick={(event) => setFetchProductCount(fetchproductcount + 1)}
        >
          Find
        </SmallContainedButton>
      </Grid>
    </>
  );
}
