import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Box, Grid, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import {
  setSearch,
  setDescriptionPreference,
  setSkuPreference,
  setArticles,
  setSubsettings,
  setToken,
  setCountrylist,
  setSelectedCountryShipFrom,
  setSelectedCountryShipTo,
  setSelectedShippingMethod,
  setShippingMethods,
  setSelectedStore,
  setSelectedCollection,
} from "../../actions";

export default function CustomizedRadioGroup({ label, columns, menu, type }) {
  // const [check, setCheck] = useState(false);

  const selected_country_shipfrom = useSelector(
    (state) => state.selected_country_shipfrom
  );
  const selected_country_shipto = useSelector(
    (state) => state.selected_country_shipto
  );
  const selected_shipping_method = useSelector(
    (state) => state.selected_shipping_method
  );
  const country_list_shipfrom = useSelector(
    (state) => state.country_list_shipfrom
  );
  const country_list_shipto = useSelector((state) => state.country_list_shipto);
  const shipping_methods = useSelector((state) => state.shipping_methods);
  const selected_store = useSelector((state) => state.selected_store);
  const selected_collection = useSelector((state) => state.selected_collection);
  const description_preference = useSelector(
    (state) => state.description_preference
  );
  const sku_preference = useSelector((state) => state.sku_preference);

  const dispatch = useDispatch();
  const handleChange = (event) => {
    // console.log("event.target.checked", event.target.checked);
    // setCheck(event.target.checked);

    if (type === "shipfrom") {
      dispatch(setSelectedCountryShipFrom(event.target.value));
    }
    if (type === "shipto") {
      dispatch(setSelectedCountryShipTo(event.target.value));
    }
    if (type === "shippingmethod") {
      dispatch(setSelectedShippingMethod(event.target.value));
    }
    if (type === "choosestore") {
      dispatch(setSelectedStore(event.target.value));
    }
    if (type === "choosecollection") {
      dispatch(setSelectedCollection(event.target.value));
    }
    if (type === "description") {
      dispatch(setDescriptionPreference(event.target.value));
      window.localStorage.setItem("description_preference", event.target.value);
    }
    if (type === "skupreference") {
      dispatch(setSkuPreference(event.target.value));
    }
  };
  useEffect(() => {
    if (
      selected_country_shipfrom === "CN" &&
      selected_country_shipto === "United States"
    ) {
      dispatch(
        setShippingMethods([
          "LC_SHIPMENT_BANGGOOD_EXPRESS",
          "LC_SHIPMENT_AIRMAIL_AIRMAIL",
          "LC_SHIPMENT_EXPRESS_SHIPPING",
        ])
      );
    }
    if (
      selected_country_shipfrom === "CN" &&
      selected_country_shipto === "Australia"
    ) {
      dispatch(
        setShippingMethods([
          "LC_SHIPMENT_STANDARD_MAIL_REGISTER",
          "LC_SHIPMENT_AU_DIRECT_MAIL",
          "LC_SHIPMENT_PRIORITY_MAIL",
          "LC_SHIPMENT_EXPRESS_SHIPPING",
        ])
      );
    }
    if (
      selected_country_shipfrom === "CN" &&
      selected_country_shipto === "New Zealand"
    ) {
      dispatch(
        setShippingMethods([
          "LC_SHIPMENT_NZ_DIRECT_MAIL",
          "LC_SHIPMENT_AIRMAIL_AIRMAIL",
        ])
      );
    }
    if (
      selected_country_shipfrom === "CN" &&
      selected_country_shipto === "United Kingdom"
    ) {
      dispatch(setShippingMethods(["LC_SHIPMENT_BANGGOOD_EXPRESS"]));
    }
    if (
      selected_country_shipfrom === "USA" &&
      selected_country_shipto === "United States"
    ) {
      dispatch(
        setShippingMethods([
          "LC_SHIPMENT_US_STANDARD_SHIP",
          "LC_SHIPMENT_US_EXPRESS_SHIP",
        ])
      );
    }
    if (
      selected_country_shipfrom === "USA" &&
      selected_country_shipto === "Canada"
    ) {
      dispatch(setShippingMethods(["LC_SHIPMENT_US_INTER_SHIP"]));
    }
    if (
      selected_country_shipfrom === "USA" &&
      selected_country_shipto === "Australia"
    ) {
      dispatch(setShippingMethods(["LC_SHIPMENT_US_INTER_SHIP"]));
    }
    if (
      selected_country_shipfrom === "AU" &&
      selected_country_shipto === "Australia"
    ) {
      dispatch(
        setShippingMethods([
          "LC_SHIPMENT_AU_STANDARD_SHIP",
          "LC_SHIPMENT_AU_EXPRESS_SHIP",
        ])
      );
    }
    if (
      selected_country_shipfrom === "AU" &&
      selected_country_shipto === "New Zealand"
    ) {
      dispatch(
        setShippingMethods([
          "LC_SHIPMENT_NZ_STANDARD_SHIPPING",
          "LC_SHIPMENT_NZ_STANDARD_SHIPPING_REGISTER",
        ])
      );
    }

    if (
      selected_country_shipfrom === "UK" &&
      selected_country_shipto === "United Kingdom"
    ) {
      dispatch(
        setShippingMethods([
          "LC_SHIPMENT_UK_STANDARD_SHIPPING_REG",
          "LC_SHIPMENT_UK_STANDARD_SHIPPING",
        ])
      );
    }
  }, [selected_country_shipfrom, selected_country_shipto]);

  console.log(
    selected_country_shipfrom,
    selected_country_shipto,
    shipping_methods,
    selected_shipping_method
  );

  const value = () => {
    switch (type) {
      case "shippingmethod":
        return selected_shipping_method;
      case "shipfrom":
        return selected_country_shipfrom;
      case "shipto":
        return selected_country_shipto;
      case "choosestore":
        return selected_store;
      case "choosecollection":
        return selected_collection;
      case "description":
        return description_preference;
      case "skupreference":
        return sku_preference;
      default:
        return "";
    }
  };
  return (
    <>
      {/* <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          label={label}
          value={value()}
          onChange={handleChange}
          id="demo-simple-select"
          labelId="demo-simple-select-label"
        >
          {menu.map((item) => {
            return <MenuItem value={item}>{item}</MenuItem>;
          })}
        </Select>
      </FormControl> */}

      <RadioGroup row value={value()} onChange={handleChange}>
        <Grid container>
          {menu.map((item, index) => {
            return (
              <Grid
                key={index}
                item
                container
                xs={3}
                // xs={12 / columns}
                sx={{
                  px: 0.5,
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  container
                  sx={{
                    justifyContent: "center",
                    borderRadius: "5px",
                    border: (theme) => `1px solid ${theme.palette.border}`,
                  }}
                >
                  <FormControlLabel
                    value={item.value}
                    control={<Radio size="small" />}
                    label={item.name}
                    sx={{
                      m: 0,
                    }}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </RadioGroup>
    </>
  );
}
