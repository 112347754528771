import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import BasicSelect from "../select/select";
import { Loader } from "../common/Loader";
import SkuMapperVariantList from "./SkuMapperVariantList";
import CustomizedSnackbars from "./notification.js";
import SkuMapperDialog from "./SkuMapperDialog.js";

import { setLoadingProducts } from "../../actions";

import {
  Grid,
  TextField,
  Typography,
  Card,
  Button,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

const SkuMapper = () => {
  const dispatch = useDispatch();
  const store_menu = useSelector((state) => state.store_menu);
  const supplier_menu = useSelector((state) => state.supplier_menu);
  const warehouse_menu = useSelector((state) => state.warehouse_menu);
  const variant_type_menu = useSelector((state) => state.variant_type_menu);
  const selected_store = useSelector((state) => state.selected_store);
  const selected_supplier = useSelector((state) => state.selected_supplier);
  const selected_warehouse = useSelector((state) => state.selected_warehouse);
  const selected_variant_type = useSelector(
    (state) => state.selected_variant_type
  );
  const [sku, setSku] = useState("");
  const [productID, setProductID] = useState("");
  const [variantID, setVariantID] = useState("");
  const [supplierSku, setSupplierSku] = useState("");
  const [selected, setSelected] = useState({
    id: "",
    supplier_sku: "",
    sku: "",
  });

  const loading_products = useSelector((state) => state.loading_products);
  const [open, setOpen] = useState({ open: false, severity: "", message: "" });
  const [count, setCount] = useState(0);

  const [variantsList, setVariantsList] = useState([]);

  const [stockMonitorStatus, setStockMonitorStatus] = useState(false);
  const [priceMonitorStatus, setPriceMonitorStatus] = useState(false);

  console.log("selected_store", selected_store);
  console.log("selected_supplier", selected_supplier);
  console.log("sku", sku);
  console.log("supplierSku", supplierSku);
  console.log("selected_warehouse", selected_warehouse);
  console.log("productID", productID);
  console.log("stockMonitorStatus", stockMonitorStatus);
  console.log("priceMonitorStatus", priceMonitorStatus);

  const transformsku = (string) => {
    let original_sku = string.split("-");
    let revisedsku = "";
    let code = "BOXTAPLINK";
    original_sku.map((item, index) => {
      if (index === 0) {
        item.split("").map((value) => {
          revisedsku = revisedsku + String(code.indexOf(value));
        });
      } else {
        revisedsku = revisedsku + "-" + item;
      }
    });

    return revisedsku;
  };

  useEffect(() => {
    setProductID("");
    setVariantID("");
  }, [selected_variant_type]);

  const handleMapProduct = () => {
    dispatch(setLoadingProducts(true));
    fetch("https://api.saleprodev.com/aliexpressaddproducttodb", {
      method: "post",
      body: JSON.stringify({
        page: 1,
        products_per_page: 1,
        supplier: selected_supplier,
        // sku: sku,
        // supplier_sku: supplierSku,
        // warehouse: selected_warehouse,
        // id: productID,

        supplier_sku: supplierSku,
        selected_store: selected_store,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("MapProductResponse", response);
        if (response.products.length === 0) {
          setOpen({
            open: true,
            severity: "error",
            message: "Something wrong here",
          });
        } else if (response.products[0].ali_info.result.error_message) {
          setOpen({
            open: true,
            severity: "error",
            message: response.products[0].ali_info.result.error_message,
          });
        } else {
          setVariantsList(response.products);
        }
        // dispatch(
        //   setImportTotalPages(parseInt(response.count / products_per_page) + 1)
        // );
        dispatch(setLoadingProducts(false));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [openSkuMapperDialog, setOpenSkuMapperDialog] = useState(false);
  const handleOpenSkuMapperDialog = () => {
    setOpenSkuMapperDialog(true);
  };

  const handleCloseSkuMapperDialog = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSkuMapperDialog(false);
  };

  const handleSubmitAliexpressProducts = () => {
    dispatch(setLoadingProducts(true));

    let obj = {
      sku,
      store: selected_store,
      warehouse: selected_warehouse,
      supplier: selected_supplier,
      id: productID,
      variantid: variantID,
      variantType: selected_variant_type,
      ali_sku: selected.id,
      supplier_sku: supplierSku,
      ali_storeid: variantsList[0].ali_info.result.store_info.store_id,
      ali_storename: variantsList[0].ali_info.result.store_info.store_name,
      ali_productstatus: variantsList[0].ali_info.result.product_status_type,
      stock_monitor: stockMonitorStatus,
      price_monitor: priceMonitorStatus,
    };

    fetch("https://api.saleprodev.com/aliexpressaddmissingproducttodb", {
      method: "post",
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("SkuMapperSubmit", response);
        setOpen({ open: true, message: response.status });
        dispatch(setLoadingProducts(false));
        handleCloseSkuMapperDialog();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = () => {
    dispatch(setLoadingProducts(true));

    let obj = {
      sku,
      store: selected_store,
      supplier: selected_supplier,
      id: productID,
      variantid: variantID,
      variantType: selected_variant_type,
      supplier_sku: supplierSku,
      stock_monitor: stockMonitorStatus,
      price_monitor: priceMonitorStatus,
    };

    fetch("https://api.saleprodev.com/aliexpressaddmissingproducttodb", {
      method: "post",
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("handleSubmit", response);
        setOpen({ open: true, message: response.status });
        dispatch(setLoadingProducts(false));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setSupplierSku(transformsku(sku));
  }, [sku]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen({ open: false, message: "" });
  };

  return (
    <Grid container flexDirection="column" alignItems="center" mx={2}>
      <Card elevation={0} sx={{ mt: 1, minHeight: "90vh", minWidth: "80vw" }}>
        <Grid
          container
          px={4}
          py={2}
          flexDirection="column"
          alignContent="center"
        >
          <Grid container py={1}>
            <Grid container item xs={4} alignContent="center">
              <Typography variant="body1">Store</Typography>
            </Grid>

            <Grid item xs={8}>
              <BasicSelect
                label="Select Store *"
                menu={store_menu}
                type="choosestore"
              />
            </Grid>
          </Grid>

          <Grid container py={1}>
            <Grid container item xs={4} alignContent="center">
              <Typography variant="body1">Supplier</Typography>
            </Grid>

            <Grid item xs={8}>
              <BasicSelect
                label="Select Supplier *"
                menu={supplier_menu}
                type="choosesupplier"
              />
            </Grid>
          </Grid>

          <Grid container py={1}>
            <Grid container item xs={4} alignContent="center">
              <Typography variant="body1">Supplier SKU</Typography>
            </Grid>

            <Grid item xs={8}>
              <TextField
                size="small"
                value={supplierSku}
                placeholder="Supplier SKU"
                onChange={(event) => setSupplierSku(event.target.value)}
                sx={{
                  mr: 0.5,
                  width: "100%",
                  fontSize: "0.8rem",
                  "& legend": {
                    width: "unset",
                  },
                  "& .MuiFormControl-root, .MuiOutlinedInput-root": {
                    p: "0px!important",
                    minHeight: 26,
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container py={1}>
            <Grid container item xs={4} alignContent="center">
              <Typography variant="body1">Shopify SKU</Typography>
            </Grid>

            <Grid item xs={8}>
              <TextField
                size="small"
                value={sku}
                placeholder="Shopify SKU *"
                onChange={(event) => setSku(event.target.value)}
                sx={{
                  mr: 0.5,
                  width: "100%",
                  fontSize: "0.8rem",
                  "& legend": {
                    width: "unset",
                  },
                  "& .MuiFormControl-root, .MuiOutlinedInput-root": {
                    p: "0px!important",
                    minHeight: 26,
                  },
                }}
              />
            </Grid>
          </Grid>

          {selected_supplier === "aliexpress" && (
            <Grid container py={1}>
              <Grid container item xs={4} alignContent="center">
                <Typography variant="body1">Warehouse</Typography>
              </Grid>

              <Grid item xs={8}>
                <BasicSelect
                  label="Select Warehouse *"
                  menu={warehouse_menu}
                  type="choosewarehouse"
                />
              </Grid>
            </Grid>
          )}

          <Grid container py={1}>
            <Grid container item xs={4} alignContent="center">
              <Typography variant="body1">Variant Type</Typography>
            </Grid>

            <Grid item xs={8}>
              <BasicSelect
                label="Please Select *"
                menu={variant_type_menu}
                type="choosevarianttype"
              />
            </Grid>
          </Grid>

          <Grid container py={1}>
            <Grid container item xs={4} alignContent="center">
              <Typography variant="body1">
                {" "}
                {selected_variant_type === "single"
                  ? "Product ID"
                  : "Variant ID"}
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <TextField
                size="small"
                value={variantID}
                placeholder={
                  selected_variant_type === "single"
                    ? "Enter Product ID *"
                    : "Enter Variant ID *"
                }
                onChange={(event) => setVariantID(event.target.value)}
                sx={{
                  mr: 0.5,
                  width: "100%",
                  fontSize: "0.8rem",
                  "& legend": {
                    width: "unset",
                  },
                  "& .MuiFormControl-root, .MuiOutlinedInput-root": {
                    p: "0px!important",
                    minHeight: 26,
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container py={1}>
            <Grid container item xs={4} alignContent="center">
              <Typography variant="body1">Stock Monitor</Typography>
            </Grid>

            <Grid item xs={8}>
              <ToggleButtonGroup
                value={stockMonitorStatus}
                exclusive
                size="small"
                onChange={() => setStockMonitorStatus(!stockMonitorStatus)}
              >
                <ToggleButton value={true}>
                  <Typography variant="body1">On</Typography>
                </ToggleButton>
                <ToggleButton value={false}>
                  <Typography variant="body1">Off</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>

          <Grid container py={1}>
            <Grid container item xs={4} alignContent="center">
              <Typography variant="body1">Price Monitor</Typography>
            </Grid>

            <Grid item xs={8}>
              <ToggleButtonGroup
                value={priceMonitorStatus}
                exclusive
                size="small"
                onChange={() => setPriceMonitorStatus(!priceMonitorStatus)}
              >
                <ToggleButton value={true}>
                  <Typography variant="body1">On</Typography>
                </ToggleButton>
                <ToggleButton value={false}>
                  <Typography variant="body1">Off</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>

          <Grid container py={1}>
            <Grid item xs={4}></Grid>

            <Grid container item xs={8} justifyContent="center" my={1}>
              <Button
                size="small"
                variant="contained"
                disabled={
                  sku === "" ||
                  variantID === "" ||
                  selected_store === "" ||
                  selected_supplier === "" ||
                  selected_warehouse === ""
                }
                onClick={
                  selected_supplier === "aliexpress"
                    ? handleMapProduct
                    : handleSubmit
                }
              >
                {selected_supplier === "aliexpress" ? "Map Product" : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {loading_products ? (
          <Grid container justifyContent="center" alignContent="center">
            <Loader size="40px" />
          </Grid>
        ) : (
          <>
            <Grid
              container
              px={4}
              py={2}
              flexDirection="column"
              alignContent="center"
            >
              {Array.isArray(variantsList) &&
                variantsList.length !== 0 &&
                variantsList.map((product, index) => (
                  <SkuMapperVariantList
                    key={index}
                    sku={sku}
                    supplierSku={supplierSku}
                    variantID={variantID}
                    product={product}
                    setCount={setCount}
                    count={count}
                    selected={selected}
                    setSelected={setSelected}
                  />
                ))}
            </Grid>

            {Array.isArray(variantsList) && variantsList.length !== 0 && (
              <Grid
                container
                px={4}
                py={2}
                flexDirection="column"
                alignContent="center"
              >
                <Grid container py={1}>
                  <Grid container item xs={4} alignContent="center">
                    <Typography variant="body1">Product ID</Typography>
                  </Grid>

                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      value={productID}
                      placeholder="Enter Product ID"
                      onChange={(event) => setProductID(event.target.value)}
                      sx={{
                        mr: 0.5,
                        width: "100%",
                        fontSize: "0.8rem",
                        "& legend": {
                          width: "unset",
                        },
                        "& .MuiFormControl-root, .MuiOutlinedInput-root": {
                          p: "0px!important",
                          minHeight: 26,
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container py={1}>
                  <Grid container item xs={4} alignContent="center">
                    <Typography variant="body1">Ali SKU</Typography>
                  </Grid>

                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      value={selected.id}
                      placeholder="Ali SKU *"
                      // onChange={(event) => setSupplierSku(event.target.value)}
                      sx={{
                        mr: 0.5,
                        width: "100%",
                        fontSize: "0.8rem",
                        "& legend": {
                          width: "unset",
                        },
                        "& .MuiFormControl-root, .MuiOutlinedInput-root": {
                          p: "0px!important",
                          minHeight: 26,
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container py={1}>
                  <Grid container item xs={4} alignContent="center">
                    <Typography variant="body1">Ali Store ID</Typography>
                  </Grid>

                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      value={
                        variantsList[0].ali_info.result.store_info.store_id
                      }
                      placeholder="Store ID"
                      // onChange={(event) => setSku(event.target.value)}
                      sx={{
                        mr: 0.5,
                        width: "100%",
                        fontSize: "0.8rem",
                        "& legend": {
                          width: "unset",
                        },
                        "& .MuiFormControl-root, .MuiOutlinedInput-root": {
                          p: "0px!important",
                          minHeight: 26,
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container py={1}>
                  <Grid container item xs={4} alignContent="center">
                    <Typography variant="body1">Ali Store Name</Typography>
                  </Grid>

                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      value={
                        variantsList[0].ali_info.result.store_info.store_name
                      }
                      placeholder="Store Name"
                      // onChange={(event) => setProductID(event.target.value)}
                      sx={{
                        mr: 0.5,
                        width: "100%",
                        fontSize: "0.8rem",
                        "& legend": {
                          width: "unset",
                        },
                        "& .MuiFormControl-root, .MuiOutlinedInput-root": {
                          p: "0px!important",
                          minHeight: 26,
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container py={1}>
                  <Grid container item xs={4} alignContent="center">
                    <Typography variant="body1">Ali Product Status</Typography>
                  </Grid>

                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      value={
                        variantsList[0].ali_info.result.product_status_type
                      }
                      placeholder="Ali Product Status"
                      // onChange={(event) => setVariantID(event.target.value)}
                      sx={{
                        mr: 0.5,
                        width: "100%",
                        fontSize: "0.8rem",
                        "& legend": {
                          width: "unset",
                        },
                        "& .MuiFormControl-root, .MuiOutlinedInput-root": {
                          p: "0px!important",
                          minHeight: 26,
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container py={1}>
                  <Grid item xs={4}></Grid>

                  <Grid container item xs={8} justifyContent="center" my={1}>
                    <Button
                      size="small"
                      variant="contained"
                      disabled={
                        variantsList[0].ali_info.result.product_status_type !==
                        "onSelling"
                      }
                      onClick={handleOpenSkuMapperDialog}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>

                <SkuMapperDialog
                  sku={sku}
                  openSkuMapperDialog={openSkuMapperDialog}
                  handleCloseSkuMapperDialog={handleCloseSkuMapperDialog}
                  handleSubmitAliexpressProducts={
                    handleSubmitAliexpressProducts
                  }
                />
              </Grid>
            )}
          </>
        )}
      </Card>
      <CustomizedSnackbars
        open={open.open}
        severity={open.severity}
        message={open.message}
        handleClose={handleClose}
      />
    </Grid>
  );
};
export default SkuMapper;
