import React, { useState, useEffect } from "react";

import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import InputLabel from "@mui/material/InputLabel"; // Import InputLabel
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

const Productexportreportdetail = ({ handlePage, page }) => {
  const slotId = useSelector((state) => state.slot_id); // Access the slot ID from Redux

  const [status, setStatus] = useState("");
  const statusOptions = ["success", "failed"];

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const perPage = 20;

  useEffect(() => {
    if (!slotId) {
      handleClose();
    } else {
      setLoading(true);
      fetchDataFromAPI(currentPage);
    }
  }, [currentPage, slotId]);

  
  

  const fetchDataFromAPI = (page) => {
    const requestBody = {
      page :page ,
      perPage:perPage,
    };

    if (status) {
      requestBody.status = status;
    }

    //   if (status) {
    //     requestBody.status = status;
    //   }

    fetch("https://api.saleprodev.com/exportlogs/" + slotId, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("API request failed:", error);
      });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleClose = () => {
    window.localStorage.setItem("report", "");
    handlePage(null, "report");
  };

  const handleSubmit = () => {
    fetchDataFromAPI(currentPage);
  };

  return (
    <div className="container">
      <div className="container mt-5">
        <h2 class="text-center">Product Export Summary</h2>

        <div className="row">
          <div className="col-8">
            <div className="row">
              <div className="col-2">
                <InputLabel htmlFor="status-select">Choose Status</InputLabel>

                <Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  inputProps={{
                    name: "status",
                    id: "status-select",
                  }}
                  style={{ width: "100px",height:"30px" }}
                >
                  {statusOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div className="col-4">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
          <div className="col-4 ml-auto">
            <IconButton
              edge="end"
              color="inherit"
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="container">
        {loading ? (
          <div className="loader-container">
            <CircularProgress />
          </div>
        ) : (
          <div className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Supplier</TableCell>
                  <TableCell>Supplier sku</TableCell>
                  <TableCell>Store</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Variant count</TableCell>
                  <TableCell>Error</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.supplier}</TableCell>
                    <TableCell>{item.supplier_sku}</TableCell>
                    <TableCell>{item.store}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>{item.variant_count}</TableCell>
                    <TableCell>
                      {item.error_detail ? item.error_detail : "N/A"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className="pagination">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>{currentPage}</span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={data.length < perPage}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Productexportreportdetail;
