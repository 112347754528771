import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';

export default function MyDatePicker({handleChange, date}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
        label="Filter By Date" 
        value={date}
        onChange={(newValue) => handleChange(newValue)}
        renderInput={(params) => <TextField {...params} size="small" />}/>
    </LocalizationProvider>
  );
}