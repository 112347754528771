import React, { useState, useEffect } from "react";
import "./checkbox.css";

export default function ControlledCheckbox({
  product,
  checkboxID,
  setSelectedProducts,
  selectedProducts,
}) {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    if (event.target.checked === true) {
      let array = [...selectedProducts];
      array.push(product);
      setSelectedProducts(array);
    }
    if (event.target.checked === false) {
      let array = [...selectedProducts];
      let index = array.indexOf(product);
      array.splice(index, 1);
      setSelectedProducts(array);
    }
  };

  // console.log("-===============------------->> ", checkboxID, product.sku, product)

  return (
    <input
      type="checkbox"
      onChange={handleChange}
      id={checkboxID ? checkboxID : product.sku ? product.sku : product.id}
    />
  );
}
