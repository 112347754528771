import React, { useState, useEffect } from "react";
import moment from "moment";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import LoginIcon from "@mui/icons-material/Login";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import GeneralSettings from "../generalsettings/generalsettings";
import NativePickers from "./select";
import DatePicker from "./DatePicker.js";
import DropShipProduct from "./dropshipproduct";
import CustomizedSnackbars from "./notification.js";
import CustomizedSnackbarsFailed from "./failednotification.js";
import CustomizedSnackbarsFailedEdit from "./failededitnotification.js";
import PurchasedOrders from "./purchasedorders.js";
import Pagination from "../common/Pagination.js";
import Image from "./image.js";
import ControlledCheckbox from "./checkbox.js";
import MainCheckbox from "./maincheckbox";
import ShippedOrders from "./shippedorders.js";
import Sort from "./sort.js";
import Searchbar from "../common/Searchbar.js";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Menu from "@mui/material/Menu";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OrdersLayout from "./layout.js";
import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import { Loader } from "../common/Loader";
import {
  List,
  Chip,
  ListItemText,
  ListSubheader,
  Collapse,
  ListItemIcon,
  Tooltip,
  Breadcrumbs,
  Link,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import OpenInNewIcon from "@mui/icons-material/ExpandMore";
import Footer from "../footer/footer.js";
import CustomSheetModal from "./customsheetmodal.js";
import ScrollToTopOrBottom from "../tools/scrolltotoporbottom.js";
import { SmallContainedButton } from "../common/SmallContainedButton";

import { useSelector, useDispatch } from "react-redux";
import {
  setPurchasingOrder,
  setSearch,
  setStore,
  setSalesChannels,
  setAliExpressOrders,
  setArticles,
  setSubsettings,
  setOrdersNzShopify,
  setLoadingOrders,
  setSelectedSupplier,
  setDropshipOrders,
  setOrdersFromTo,
  setShowOrders,
  setImportPage,
  setImportTotalPages,
  setCustomSheet,
  setFragrancexOrders,
  setTotalCounts,
} from "../../actions";

function OrdersNew({ page }) {
  const [orders, setOrders] = useState("");
  const [orderCount, setOrderCount] = useState(0);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const sub_settings = useSelector((state) => state.sub_settings);
  const orders_nz_shopify = useSelector((state) => state.orders_nz_shopify);
  const supplier_menu = useSelector((state) => state.supplier_menu);
  const selected_supplier = useSelector((state) => state.selected_supplier);
  const dropship_orders = useSelector((state) => state.dropship_orders);
  const order_from_date = useSelector((state) => state.order_from_date);
  const order_to_date = useSelector((state) => state.order_to_date);
  const orders_from_to = useSelector((state) => state.orders_from_to);
  const show_orders = useSelector((state) => state.show_orders);
  const import_total_pages = useSelector((state) => state.import_total_pages);
  const import_page = useSelector((state) => state.import_page);
  const products_per_page = useSelector((state) => state.products_per_page);
  const fragrancex_orders = useSelector((state) => state.fragrancex_orders);
  const aliexpress_orders = useSelector((state) => state.aliexpress_orders);
  const loading_orders = useSelector((state) => state.loading_orders);
  const searchField = useSelector((state) => state.searchField);
  const store = useSelector((state) => state.store);
  const sales_channels = useSelector((state) => state.sales_channels);
  const shipped_orders = useSelector((state) => state.shipped_orders);
  const purchasingorder = useSelector((state) => state.purchasingorder);
  const [token, setToken] = useState("");
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState({ open: false, message: "" });
  const [message, setMessage] = useState("");
  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [pages, setPages] = useState([]);
  const [orderspage1, setOrdersPage1] = useState([]);
  const [orderspage2, setOrdersPage2] = useState([]);
  const start = (import_page - 1) * products_per_page;
  const end = start + products_per_page;
  const [finalorders, setFinalOrders] = useState([]);
  const [orders_nz_shopify_raw, setOrdersNzShopifyRaw] = useState([]);
  const custom_sheet = useSelector((state) => state.custom_sheet);
  const [array, setArray] = useState([]);
  const total_counts = useSelector((state) => state.total_counts);
  const [indexM, setIndexm] = useState(null);

  //Side Menu Collapse
  const [openedCollapseItem, setOpenedCollapseItem] = useState(false);
  const handleOpenCollapseItem = () => {
    setOpenedCollapseItem(!openedCollapseItem);
  };

  // console.log("orders_nz_shopify===>", orders_nz_shopify.length);

  // console.log("this is the sales_channels in order new header", sales_channels);

  useEffect(() => {
    if (
      show_orders === null ||
      show_orders === "purchased" ||
      show_orders === "fulfilled"
    ) {
      setOpenedCollapseItem(true);
    } else {
      setOpenedCollapseItem(false);
    }
  }, [show_orders]);

  const [openfailed, setOpenFailed] = React.useState({
    open: false,
    message: "",
    order: "",
  });
  const [openfailededit, setOpenFailedEdit] = React.useState({
    open: false,
    message: "",
    order: "",
  });
  const [purchasedorderscheck, setPurchasedOrdersCheck] = useState([]);
  const [paymentStatus, setPaymentStatus] = React.useState("");
  const [paymentStatusSelectValue, setPaymentStatusSelectValue] =
    React.useState("");
  const [fulfillmentStatusSelectValue, setFulfillmentStatusSelectValue] =
    React.useState("");
  const [openList, setOpenList] = React.useState(false);
  // const [carrier, setCarrier] = React.useState("UBI");
  const [carrier, setCarrier] = useState({
    amount: "$0",
    currency: "USD",
    duration: "0day",
    name: "UBI",
    value: "UBI",
  });

  const [vidaxlCarrier, setVidaxlCarrier] = useState("ams");
  const [dropshipzoneCarrier, setDropshipzoneCarrier] = useState(
    "dropshipzone_shipping"
  );

  const [note, setNote] = React.useState(
    "Please ship the order with UBI or Yanwen Express only thanks"
  );
  const [opencustom, setOpenCustom] = React.useState(false);
  const [missingOrder, setMissing] = useState({
    disable: false,
    value: "",
  });
  const handleCloseCustom = () => {
    handleCloseBulkActions();
    setOpenCustom(false);
  };
  const handleOpenList = () => {
    setOpenList(!openList);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event) => {
    setPaymentStatus(event.target.value);
  };

  const handleChangePaymentStatusSelect = (event) => {
    setPaymentStatusSelectValue(event.target.value);
  };

  const handleChangeFulfillmentStatusSelect = (event) => {
    setFulfillmentStatusSelectValue(event.target.value);
  };

  const handleCloseFailed = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenFailed({ open: false, message: "", order: "" });
  };

  const handleCloseFailedEdit = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenFailedEdit({ open: false, message: "", order: "" });
  };

  useEffect(() => {
    dispatch(setOrdersNzShopify([]));
    dispatch(setLoadingOrders(true));
    setAscending(false);

    if (import_page > import_total_pages) {
      dispatch(setImportPage(1));
      window.localStorage.setItem("import_page", 1);
    }

    if (store === "nzshopify") {
      fetch("https://api.saleprodev.com/getshopifyordersnz", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          page: import_page,
          products_per_page: products_per_page,
          search: searchField,
          supplier: selected_supplier,
          purchase_status: show_orders,
          sales_channels: sales_channels,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("response", response);
          dispatch(setOrdersNzShopify(response.orders));
          dispatch(
            setImportTotalPages(
              parseInt(response.count / products_per_page) + 1
            )
          );
          dispatch(setTotalCounts(response.count));
          setOrderCount(response.count);
          setCount2(count2 + 1);
          dispatch(setLoadingOrders(false));
        }).catch((err)=>{

        })
    }

    if (store === "aushopify") {
      fetch("https://api.saleprodev.com/getshopifyordersau", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          page: import_page,
          products_per_page: products_per_page,
          search: searchField,
          supplier: selected_supplier,
          purchase_status: show_orders,
          sales_channels: sales_channels,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("response", response);
          dispatch(setOrdersNzShopify(response.orders));
          dispatch(
            setImportTotalPages(
              parseInt(response.count / products_per_page) + 1
            )
          );
          dispatch(setTotalCounts(response.count));
          setOrderCount(response.count);
          setCount2(count2 + 1);
          dispatch(setLoadingOrders(false));
        });
    }

    if (store === "usshopify") {
      fetch("https://api.saleprodev.com/getshopifyordersus", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          page: import_page,
          products_per_page: products_per_page,
          search: searchField,
          supplier: selected_supplier,
          purchase_status: show_orders,
          // sales_channels: sales_channels
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("response", response);
          dispatch(setOrdersNzShopify(response.orders));
          dispatch(
            setImportTotalPages(
              parseInt(response.count / products_per_page) + 1
            )
          );
          dispatch(setTotalCounts(response.count));
          setOrderCount(response.count);
          setCount2(count2 + 1);
          dispatch(setLoadingOrders(false));
        });
    }

    if (store === "gkshopify") {
      fetch("https://api.saleprodev.com/getshopifyordersgk", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          page: import_page,
          products_per_page: products_per_page,
          search: searchField,
          supplier: selected_supplier,
          purchase_status: show_orders,
          sales_channels: sales_channels,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("response", response);
          dispatch(setOrdersNzShopify(response.orders));
          dispatch(
            setImportTotalPages(
              parseInt(response.count / products_per_page) + 1
            )
          );
          dispatch(setTotalCounts(response.count));
          setOrderCount(response.count);
          setCount2(count2 + 1);
          dispatch(setLoadingOrders(false));
        });
    }
  }, [
    import_page,
    products_per_page,
    import_total_pages,
    searchField,
    selected_supplier,
    show_orders,
    count,
    store,
    sales_channels,
  ]);

  const fetchorders = () => {
    if (store === "nzshopify") {
      fetch("https://api.saleprodev.com/getshopifyordersnz", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          page: import_page,
          products_per_page: products_per_page,
          search: searchField,
          supplier: selected_supplier,
          purchase_status: show_orders,
          sales_channels: sales_channels,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("response", response);
          dispatch(setOrdersNzShopify(response.orders));
          dispatch(
            setImportTotalPages(
              parseInt(response.count / products_per_page) + 1
            )
          );
          dispatch(setTotalCounts(response.count));
          setOrderCount(response.count);
          setCount2(count2 + 1);
        });
    }

    if (store === "aushopify") {
      fetch("https://api.saleprodev.com/getshopifyordersau", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          page: import_page,
          products_per_page: products_per_page,
          search: searchField,
          supplier: selected_supplier,
          purchase_status: show_orders,
          sales_channels: sales_channels,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("response", response);
          dispatch(setOrdersNzShopify(response.orders));
          dispatch(
            setImportTotalPages(
              parseInt(response.count / products_per_page) + 1
            )
          );
          dispatch(setTotalCounts(response.count));
          setOrderCount(response.count);
          setCount2(count2 + 1);
        });
    }

    if (store === "usshopify") {
      fetch("https://api.saleprodev.com/getshopifyordersus", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          page: import_page,
          products_per_page: products_per_page,
          search: searchField,
          supplier: selected_supplier,
          purchase_status: show_orders,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("response", response);
          dispatch(setOrdersNzShopify(response.orders));
          dispatch(
            setImportTotalPages(
              parseInt(response.count / products_per_page) + 1
            )
          );
          dispatch(setTotalCounts(response.count));
          setOrderCount(response.count);
          setCount2(count2 + 1);
        });
    }

    if (store === "gkshopify") {
      fetch("https://api.saleprodev.com/getshopifyordersgk", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          page: import_page,
          products_per_page: products_per_page,
          search: searchField,
          supplier: selected_supplier,
          purchase_status: show_orders,
          sales_channels: sales_channels,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("response", response);
          dispatch(setOrdersNzShopify(response.orders));
          dispatch(
            setImportTotalPages(
              parseInt(response.count / products_per_page) + 1
            )
          );
          dispatch(setTotalCounts(response.count));
          setOrderCount(response.count);
          setCount2(count2 + 1);
        });
    }
  };

  //handleMissingOrder

  const handleMissingOrder = () => {
    if (!missingOrder.value) {
      return alert("Please enter the orderids.");
    }
    setMissing((prev) => {
      return { ...prev, disable: true };
    });

    fetch("https://api.saleprodev.com/addMissingOrder", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        store,
        orderids: missingOrder.value,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setMissing({ value: "", disable: false });
        alert(response.message);
      })
      .catch(() => {
        setMissing({ value: "", disable: false });
      });
  };

  console.log("this is te disableooo-->> ", missingOrder);
  //Sort Orders
  const [ascending, setAscending] = useState(false);
  const [sortCategory, setSortCategory] = useState("order_date");

  const handleSortAscending = () => {
    if (orders_nz_shopify.length !== 0) {
      {
        sortCategory === "order_id" &&
          dispatch(
            setOrdersNzShopify([
              ...orders_nz_shopify.sort((orderA, orderB) =>
                orderA.orderid.localeCompare(orderB.orderid)
              ),
            ])
          );
      }

      {
        sortCategory === "order_date" &&
          dispatch(
            setOrdersNzShopify([
              ...orders_nz_shopify.sort(
                (orderA, orderB) =>
                  moment(orderA.receivedAt).format("x") -
                  moment(orderB.receivedAt).format("x")
              ),
            ])
          );
      }

      setAscending(true);
    }
  };

  useEffect(() => {
    handleSortDescending();
  }, [sortCategory]);

  const handleSortDescending = () => {
    if (orders_nz_shopify.length !== 0) {
      {
        sortCategory === "order_id" &&
          dispatch(
            setOrdersNzShopify([
              ...orders_nz_shopify.sort((orderA, orderB) =>
                orderB.orderid.localeCompare(orderA.orderid)
              ),
            ])
          );
      }

      {
        sortCategory === "order_date" &&
          dispatch(
            setOrdersNzShopify([
              ...orders_nz_shopify.sort(
                (orderA, orderB) =>
                  moment(orderB.receivedAt).format("x") -
                  moment(orderA.receivedAt).format("x")
              ),
            ])
          );
      }

      setAscending(false);
    }
  };

  const [bulkActionsAnchorEl, setBulkActionsAnchorEl] = useState(null);
  const openBulkActions = Boolean(bulkActionsAnchorEl);
  const handleClickBulkActions = (event) => {
    setBulkActionsAnchorEl(event.currentTarget);
  };
  const handleCloseBulkActions = () => {
    setBulkActionsAnchorEl(null);
  };

  const [showAllCustomerDetails, setShowAllCustomerDetails] = useState(false);
  const handleToggleAllCustomerDetails = () => {
    setShowAllCustomerDetails(!showAllCustomerDetails);
  };

  const handleSelectedPurchase = () => {
    handleCloseBulkActions();
    selectedOrders.map((order, index) => {
      const data = () => {
        handlePurchase(order);
      };
      setTimeout(data, index * 100);
    });
  };

  const handleBulkPurchase = async (item, order) => {
    await fetch(`https://api.saleprodev.com/dropshiptoken`, {
      method: "post",
      body: JSON.stringify({
        email: "sales@salespro.net.nz",
        password: "na+axuh9bayl_a#5=Me0",
      }),
    })
      .then((response) => response.json())
      .then(async (response) => {
        console.log(response);
        await fetch(`https://api.saleprodev.com/placeorder`, {
          method: "post",
          body: JSON.stringify({
            token: "jwt " + response.token,
            data: {
              your_order_no: order.dropship_order_no,
              first_name: "Export Department",
              last_name: order.name,
              address1: "118 Denison Street",
              address2: "AMS",
              suburb: "Hillsdale",
              state: "New South Wales",
              postcode: "2036",
              telephone: "409980939",
              comment:
                "All deliveries must be delivered to the warehouse roller door and no where else",
              order_items: [{ sku: item.sku, qty: item.quantity }],
            },
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            console.log(response);
            if (response.status === -1) {
              setOpenFailed({
                open: true,
                message: response.errmsg,
                order: order,
              });
              console.log("fail response", response);
            }
            if (response.status === 1) {
              console.log("success response", response);
              setOpen({ open: true, message: "Order Placed Successfully" });
              handleSave(order);
            }
            if (Array.isArray(response)) {
              if (response[0].status === -1) {
                setOpenFailed({
                  open: true,
                  message: response[0].errmsg,
                  order: order,
                });
              }
              if (response[0].status === 1) {
                setOpen({ open: true, message: "Order Placed Successfully" });
                handleSave(order);
              }
            }
          });
      });
  };

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const handlePurchase = async (order) => {
    if (order.supplier === "") {
      setOpenFailed({
        open: true,
        message: "Please select supplier",
        order: order,
      });
      return;
    }
    if (order.supplier === "dropship" && store === "nzshopify") {
      dispatch(setPurchasingOrder([order.orderid, true]));
      // order.dropship_order_no = "";
      order.dropship_order_no = order.orderid;

      if (order.orderid.includes("_")) {
        if (
          order.referring_site !== null &&
          order.referring_site.includes("trademe")
        ) {
          order.dropship_order_no =
            order.note.slice(52) + "_" + order.orderid.split("_")[1];
        }
        if (order.source_name === "web") {
          order.dropship_order_no = order.orderid;
        }
        if (order.tags === "Kogan NZ") {
          order.dropship_order_no =
            order.note + "_" + order.orderid.split("_")[1];
        }
        if (order.tags && order.tags.includes("TheMarket")) {
          order.dropship_order_no =
            order.source_identifier + "_" + order.orderid.split("_")[1];
        }
        if (order.tags && order.tags.includes("Onceit")) {
          order.dropship_order_no =
            order.source_identifier + "_" + order.orderid.split("_")[1];
        }
      } else {
        if (
          order.referring_site !== null &&
          order.referring_site.includes("trademe")
        ) {
          order.dropship_order_no = order.note.slice(52);
        }
        if (order.source_name === "web") {
          order.dropship_order_no = order.orderid;
        }
        if (order.tags === "Kogan NZ") {
          order.dropship_order_no = order.note;
        }
        if (order.tags && order.tags.includes("kogan")) {
          order.dropship_order_no = order.note.slice(20);
        }
        if (order.tags && order.tags.includes("TheMarket")) {
          order.dropship_order_no = order.source_identifier;
        }
        if (order.tags && order.tags.includes("Onceit")) {
          order.dropship_order_no = order.source_identifier;
        }
      }

      await fetch(`https://api.saleprodev.com/dropshiptoken`, {
        method: "post",
        body: JSON.stringify({
          email: "sales@salespro.net.nz",
          password: "na+axuh9bayl_a#5=Me0",
        }),
      })
        .then((response) => response.json())
        .then(async (response) => {
          console.log("token", response);

          fetch(`https://api.saleprodev.com/getdropshipproductsbysku`, {
            method: "post",
            body: JSON.stringify({
              sku: JSON.parse(order.line_items)[0].sku,
            }),
          })
            .then((response) => response.json())
            .then((res) => {
              let product = res.product;
              console.log("product", product);
              let dataObj;

              if (product && product.length !== 0) {
                if (dropshipzoneCarrier === "ams") {
                  dataObj = {
                    your_order_no: order.dropship_order_no,
                    first_name: "Export Department",
                    last_name: order.orderid,
                    address1: "118 Denison Street",
                    address2: "AMS",
                    suburb: "Hillsdale",
                    state: "New South Wales",
                    postcode: "2036",
                    telephone: "409980939",
                    comment:
                      "All deliveries must be delivered to the warehouse roller door and no where else",
                    order_items: [
                      {
                        sku: JSON.parse(order.line_items)[0].sku,
                        qty: JSON.parse(order.line_items)[0].quantity,
                      },
                    ],
                  };
                }

                if (dropshipzoneCarrier === "AUSFF") {
                  dataObj = {
                    your_order_no: order.dropship_order_no,
                    first_name: "C/O S74457",
                    last_name: order.orderid,
                    address1: "15 Kendall Street",
                    address2: "SalesPro Support",
                    suburb: "Clyde",
                    state: "New South Wales",
                    postcode: "2142",
                    telephone: "+611300345700",
                    comment: "",
                    order_items: [
                      {
                        sku: JSON.parse(order.line_items)[0].sku,
                        qty: JSON.parse(order.line_items)[0].quantity,
                      },
                    ],
                  };
                }

                if (dropshipzoneCarrier === "dropshipzone_shipping") {
                  dataObj = {
                    your_order_no: order.dropship_order_no,
                    first_name: JSON.parse(order.shipping_address).first_name,
                    last_name: JSON.parse(order.shipping_address).last_name,
                    address1: JSON.parse(order.shipping_address).address1,
                    address2: JSON.parse(order.shipping_address).address2,
                    suburb: JSON.parse(order.shipping_address).city,
                    // city: JSON.parse(order.shipping_address).city,
                    state: JSON.parse(order.shipping_address).province,
                    country: "NZ",
                    postcode: JSON.parse(order.shipping_address).zip,
                    telephone: JSON.parse(order.shipping_address).phone,
                    comment: "",
                    order_items: [
                      {
                        sku: JSON.parse(order.line_items)[0].sku,
                        qty: JSON.parse(order.line_items)[0].quantity,
                      },
                    ],
                  };
                }
              } else {
                setOpenFailed({
                  open: true,
                  message: "No Product Found",
                  order: order,
                });
                return;
              }

              console.log("dataObj", dataObj);

              fetch(`https://api.saleprodev.com/placeorder`, {
                method: "post",
                body: JSON.stringify({
                  token: "jwt " + response.token,
                  data: dataObj,
                  order_info: {
                    store: store,
                    carrier_name: dropshipzoneCarrier,
                    orderid: order.orderid,
                  },
                }),
              })
                .then((response) => response.json())
                .then((response) => {
                  console.log("orderresponse", response);
                  if (response.status === -1) {
                    setOpenFailed({
                      open: true,
                      message: response.errmsg,
                      order: order,
                    });
                    console.log("fail response", response);
                  }
                  if (response.status === 1) {
                    console.log("success response", response);
                    setOpen({
                      open: true,
                      message: "Order Placed Successfully",
                    });
                    handleSave(order, order.dropship_order_no);
                  }
                  if (Array.isArray(response)) {
                    if (response[0].status === -1) {
                      setOpenFailed({
                        open: true,
                        message: response[0].errmsg,
                        order: order,
                      });
                    }
                    if (response[0].status === 1) {
                      setOpen({
                        open: true,
                        message: "Order Placed Successfully",
                      });
                      handleSave(order, order.dropship_order_no);
                      setCount(count + 1);
                    }
                  }
                });
            });
          dispatch(setPurchasingOrder(["", false]));
        });
    }

    if (order.supplier === "dropship" && store === "aushopify") {
      dispatch(setPurchasingOrder([order.orderid, true]));
      // order.dropship_order_no = "";
      order.dropship_order_no = order.orderid;

      if (order.orderid.includes("_")) {
        if (
          order.referring_site !== null &&
          order.referring_site.includes("trademe")
        ) {
          order.dropship_order_no =
            order.note.slice(52) + "_" + order.orderid.split("_")[1];
        }
        if (order.source_name === "web") {
          order.dropship_order_no = order.orderid;
        }
        if (order.tags === "Kogan NZ") {
          order.dropship_order_no =
            order.note + "_" + order.orderid.split("_")[1];
        }
        if (order.tags && order.tags.includes("TheMarket")) {
          order.dropship_order_no =
            order.source_identifier + "_" + order.orderid.split("_")[1];
        }
        if (order.tags && order.tags.includes("Onceit")) {
          order.dropship_order_no =
            order.source_identifier + "_" + order.orderid.split("_")[1];
        }
      } else {
        if (
          order.referring_site !== null &&
          order.referring_site.includes("trademe")
        ) {
          order.dropship_order_no = order.note.slice(52);
        }
        if (order.source_name === "web") {
          order.dropship_order_no = order.orderid;
        }
        if (order.tags === "Kogan NZ") {
          order.dropship_order_no = order.note;
        }
        if (order.tags && order.tags.includes("TheMarket")) {
          order.dropship_order_no = order.source_identifier;
        }
        if (order.tags && order.tags.includes("Onceit")) {
          order.dropship_order_no = order.source_identifier;
        }
      }

      await fetch(`https://api.saleprodev.com/dropshiptoken`, {
        method: "post",
        body: JSON.stringify({
          email: "sales@salespro.net.nz",
          password: "na+axuh9bayl_a#5=Me0",
        }),
      })
        .then((response) => response.json())
        .then(async (response) => {
          console.log("token", response);
          await fetch(`https://api.saleprodev.com/placeorder`, {
            method: "post",
            body: JSON.stringify({
              token: "jwt " + response.token,
              data: {
                your_order_no: order.dropship_order_no,
                first_name: JSON.parse(order.shipping_address).first_name,
                last_name: JSON.parse(order.shipping_address).last_name,
                address1: JSON.parse(order.shipping_address).address1,
                address2: JSON.parse(order.shipping_address).address2,
                suburb: "",
                state: JSON.parse(order.shipping_address).province,
                postcode: JSON.parse(order.shipping_address).zip,
                telephone: JSON.parse(order.shipping_address).phone,
                comment:
                  "All deliveries must be delivered to the warehouse roller door and no where else",
                order_items: [
                  {
                    sku: JSON.parse(order.line_items)[0].sku,
                    qty: JSON.parse(order.line_items)[0].quantity,
                  },
                ],
              },
              order_info: {
                store: store,
                carrier_name: dropshipzoneCarrier,
                orderid: order.orderid,
              },
            }),
          })
            .then((response) => response.json())
            .then((response) => {
              console.log("orderresponse", response);
              if (response.status === -1) {
                setOpenFailed({
                  open: true,
                  message: response.errmsg,
                  order: order,
                });
                console.log("fail response", response);
              }
              if (response.status === 1) {
                console.log("success response", response);
                setOpen({ open: true, message: "Order Placed Successfully" });
                handleSave(order, order.dropship_order_no);
              }
              if (Array.isArray(response)) {
                if (response[0].status === -1) {
                  setOpenFailed({
                    open: true,
                    message: response[0].errmsg,
                    order: order,
                  });
                }
                if (response[0].status === 1) {
                  console.log("success response", response);
                  setOpen({ open: true, message: "Order Placed Successfully" });
                  handleSave(order, order.dropship_order_no);
                  setCount(count + 1);
                }
              }
            });
          dispatch(setPurchasingOrder(["", false]));
        });
    }

    if (order.supplier === "fragrancex") {
      dispatch(setPurchasingOrder([order.orderid, true]));
      fetch("https://api.saleprodev.com/placefragrancexorder", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ order: order, store: store }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("fragrance", response);
          if (response.Message === "Order 1 failed") {
            setOpenFailed({
              open: true,
              message: response.OrderResults[0].Message,
              order: order,
            });
          } else {
            setOpen({ open: true, message: response.Message });
            handleSave(order, response.OrderResults[0].OrderId);
          }
          dispatch(setPurchasingOrder(["", false]));
        });
    }

    if (order.supplier === "dropsite") {
      dispatch(setPurchasingOrder([order.orderid, true]));
      fetch("https://api.saleprodev.com/placedropsiteorder", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ order: order, store: store }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("dropsite", response);
          if (response.Message === "Order 1 failed") {
            setOpenFailed({
              open: true,
              message: response.OrderResults[0].Message,
              order: order,
            });
          } else {
            setOpen({ open: true, message: response.Message });
            handleSave(order, order.orderid);
          }
          dispatch(setPurchasingOrder(["", false]));
        });
    }

    if (order.supplier === "soga") {
      dispatch(setPurchasingOrder([order.orderid, true]));
      fetch("https://api.saleprodev.com/placesogaorder", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ order: order, store: store }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("soga", response);
          if (response.Message === "Order 1 failed") {
            setOpenFailed({
              open: true,
              message: response.OrderResults[0].Message,
              order: order,
            });
          } else {
            setOpen({ open: true, message: response.Message });
            handleSave(order, order.orderid);
          }
          dispatch(setPurchasingOrder(["", false]));
        });
    }

    if (order.supplier === "wefullfill" || order.supplier === "wefullfillau") {
      dispatch(setPurchasingOrder([order.orderid, true]));
      fetch(
        `https://api.saleprodev.com/${
          order.supplier === "wefullfillau"
            ? "placewefullfillauorder"
            : "placewefullfillorder"
        }`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ order: order, store: store }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          console.log("wefullfill", response);
          if (response.status === "Order Created Successfully") {
            setOpen({ open: true, message: response.status });
            handleSave(order, "WFI-" + order.orderid);
          } else {
            setOpenFailed({
              open: true,
              message: response.error,
              order: order,
            });
          }
          dispatch(setPurchasingOrder(["", false]));
        });
    }

    if (order.supplier === "aliexpress") {
      // if(store === "gkshopify" || store === "nzshopify")
      // {
      //   order.address2 = order.province;
      //   order.province = 'other'
      // }
      dispatch(setPurchasingOrder([order.orderid, true]));
      fetch("https://api.saleprodev.com/placealiexpressorder", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          order: order,
          store: store,
          carrier: carrier.value,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("response", response);

          if (response.result.is_success) {
            setOpen({ open: true, message: "Order Placed Successfully" });
            handleSave(order, response.result.order_list.number.join());
          }

          if (response.result.error_code) {
            setOpenFailed({
              open: true,
              message: response.result.error_code,
              order: order,
            });
          }

          if (response.status === "Product not found in database") {
            setOpenFailed({
              open: true,
              message: response.status,
              order: order,
            });
          }
          if (response.status === "Store not found") {
            setOpenFailed({
              open: true,
              message: response.status,
              order: order,
            });
          }
          dispatch(setPurchasingOrder(["", false]));
        });
    }

    if (order.supplier === "bangood") {
      let sale_record_id = order.orderid + "ERP" + `${getRandomInt(0, 100)}`;
      dispatch(setPurchasingOrder([order.orderid, true]));
      fetch("https://api.saleprodev.com/placebangoodorder", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          order: order,
          sale_record_id: sale_record_id,
          store: store,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("response", response);
          if (response.response.code === 0) {
            setOpen({ open: true, message: "Order Placed Successfully" });
            handleSave(order, sale_record_id);
            dispatch(setPurchasingOrder(["", false]));
          }
          if (response.response.code === 1) {
            setOpenFailed({
              open: true,
              message: response.response.failure_list[0].error_desc,
              order: order,
            });
            dispatch(setPurchasingOrder(["", false]));
          }

          if (response.response.code !== 0 && response.response.code !== 1) {
            setOpenFailed({
              open: true,
              message: response.response.msg,
              order: order,
            });
            dispatch(setPurchasingOrder(["", false]));
          }
        });
    }

    if (order.supplier === "vidaxl") {
      dispatch(setPurchasingOrder([order.orderid, true]));
      fetch("https://api.saleprodev.com/placevidaxlorder", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          order: order,
          store: store,
          carrier_name: vidaxlCarrier,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("response", response);
          if (response.status) {
            setOpenFailed({
              open: true,
              message: response.message,
              order: order,
            });
            dispatch(setPurchasingOrder(["", false]));
          }
          if (response.order) {
            setOpen({ open: true, message: "Order Placed Successfully" });
            handleSave(order, response.order.id);
            dispatch(setPurchasingOrder(["", false]));
          }
        });
    }

    if (order.supplier === "matterhorn") {
      dispatch(setPurchasingOrder([order.orderid, true]));
      fetch("https://api.saleprodev.com/placematterhornorder", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ order: order, store: store }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("response", response);
          if (response.status === "failed") {
            setOpenFailed({
              open: true,
              message: response.message,
              order: order,
            });
            dispatch(setPurchasingOrder(["", false]));
          }
          if (response.id) {
            setOpen({ open: true, message: "Order Placed Successfully" });
            handleSave(order, response.id);
            dispatch(setPurchasingOrder(["", false]));
          }
        });
    }

    if (order.supplier === "bigbuy") {
      dispatch(setPurchasingOrder([order.orderid, true]));
      fetch("https://api.saleprodev.com/placebigbuyorder", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          order: order,
          store: store,
          carrier:
            carrier.shippingService &&
            carrier.shippingService.name &&
            carrier.shippingService.name,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("response", response);
          if (response.status === "failed") {
            setOpenFailed({
              open: true,
              message: response.message,
              order: order,
            });
            dispatch(setPurchasingOrder(["", false]));
          }
          if (response.order_id) {
            setOpen({ open: true, message: "Order Placed Successfully" });
            handleSave(order, response.order_id);
            dispatch(setPurchasingOrder(["", false]));
          }
        });
    }

    if (order.supplier === "griffati") {
      dispatch(setPurchasingOrder([order.orderid, true]));
      fetch("https://api.saleprodev.com/placegriffatiorder", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          order: order,
          store: store,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("response", response);
          if (response.status === "failed") {
            setOpenFailed({
              open: true,
              message: response.message,
              order: order,
            });
            dispatch(setPurchasingOrder(["", false]));
          }
          if (response.order_id) {
            setOpen({ open: true, message: "Order Placed Successfully" });
            handleSave(order, response.order_id);
            dispatch(setPurchasingOrder(["", false]));
          }
        });
    }

    //for sunsky
    if (order.supplier === "sunsky") {
      dispatch(setPurchasingOrder([order.orderid, true]));
      fetch("https://api.saleprodev.com/placesunskyorder", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          order: order,
          store: store,
          carrier_name: '',
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("response", response, response.result);
          if (response.result !== 'success') {
            alert("i am here one")
            setOpenFailed({
              open: true,
              message: response?.messages[0],
              order: order,
            });
            dispatch(setPurchasingOrder(["", false]));
          }
          if (response.result === 'success') {
            setOpen({ open: true, message: "Order Placed Successfully" });
            console.log("thisis ", order, response.data.number)
            handleSave(order, response.data.number);
            dispatch(setPurchasingOrder(["", false]));
          }
        });
    }
  };

  useEffect(() => {}, [open]);

  useEffect(() => {
    if (store === "aushopify") {
      dispatch(setSalesChannels("All AU"));
    } else if (store === "gkshopify") {
      dispatch(setSalesChannels("All GK"));
    } else if (store === "nzshopify") {
      dispatch(setSalesChannels("All NZ"));
    }
    // dispatch(setSalesChannels("All NZ"))
  }, [store, selected_supplier]);

  const handleCancel = (order) => {
    fetch(`https://api.saleprodev.com/cancelorder`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ order: order, store: store }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("cancel", response);
        if (response.status === "success") {
          setOpen({ open: true, message: "Success" });
          fetchorders();
        } else {
          setOpenFailed({
            open: true,
            message: "Sorry, Can't cancel this order",
            order: order,
          });
        }
      });
  };

  const handleSave = (order, supplier_order_no) => {
    console.log(JSON.stringify(order));
    if (store === "nzshopify") {
      fetch(`https://api.saleprodev.com/saveorder`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          order: order,
          supplier_order_no: supplier_order_no,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setCount(count + 1);
        });
    }

    if (store === "aushopify") {
      fetch(`https://api.saleprodev.com/saveorderau`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          order: order,
          supplier_order_no: supplier_order_no,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setCount(count + 1);
        });
    }

    if (store === "usshopify") {
      fetch(`https://api.saleprodev.com/saveorderus`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          order: order,
          supplier_order_no: supplier_order_no,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setCount(count + 1);
        });
    }

    if (store === "gkshopify") {
      fetch(`https://api.saleprodev.com/saveordergk`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          order: order,
          supplier_order_no: supplier_order_no,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setCount(count + 1);
        });
    }
  };

  console.log("orders_nz_shopify", orders_nz_shopify);
  console.log(dropship_orders);

  console.log("sales_channels", sales_channels);
  console.log("Carrier Name", carrier.name);
  console.log("pages", pages);
  console.log("orders", orders_from_to);
  console.log("orderspage1", orderspage1);
  console.log("orderspage2", orderspage2);
  console.log("purchasedorderscheck", purchasedorderscheck);
  console.log("id", document.getElementById(4220668084404));
  // console.log("purchasedorderscheck", purchasedorderscheck.filter(element => (element.orderid === order.name && JSON.parse(element.line_items)[0].id === order.line_items[0].id)))
  return (
    <div style={{ overflowX: "auto", overflowY: "hidden" }}>
      <Grid container id="content" xs={12} sx={{ minWidth: "1920px" }} p={1}>
        {/* Left Side Menu */}
        <Grid item xs={1}>
          <List
            component="nav"
            id="orders_side_menu_list"
            sx={{
              py: 0,
              bgcolor: "background.paper",
              "& .MuiListItemButton-root": {
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.primary.light,
                },
              },

              "& .MuiChip-root": {
                fontSize: "0.75rem",
                height: "1.2rem",
              },
            }}
          >
            <Grid
              item
              sx={{
                p: 0.5,
                pl: 1,
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Order Manager
              </Typography>
            </Grid>

            <Divider sx={{ color: (theme) => theme.palette.neutral[300] }} />

            {show_orders === "" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 2,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
                onClick={(event) => {
                  dispatch(setShowOrders(""));
                }}
              >
                <Grid container justifyContent="space-between">
                  <Typography variant="body2">All Orders</Typography>

                  {loading_orders ? (
                    <Loader size="14px" />
                  ) : (
                    <Chip color="primary" size="small" label={orderCount} />
                  )}
                </Grid>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 2,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  setOpenedCollapseItem(false);
                  dispatch(setShowOrders(""));
                  window.localStorage.setItem("show_orders", "");
                }}
              >
                <Typography variant="body2">All Orders</Typography>
              </ListItemButton>
            )}

            <Divider sx={{ color: (theme) => theme.palette.neutral[300] }} />

            <ListItemButton
              sx={{
                p: 0.75,
                pl: 2,
                borderLeft: (theme) =>
                  show_orders === null ||
                  show_orders === "purchased" ||
                  show_orders === "fulfilled"
                    ? `4px solid ${theme.palette.primary.main}`
                    : "",
              }}
              onClick={handleOpenCollapseItem}
            >
              <Typography variant="body2">Purchase Orders</Typography>
            </ListItemButton>

            <Collapse in={openedCollapseItem} unmountOnExit>
              <Divider sx={{ color: (theme) => theme.palette.neutral[300] }} />

              {show_orders === null ? (
                <ListItemButton
                  sx={{
                    p: 0.75,
                    pl: 3,
                    borderRight: (theme) =>
                      `4px solid ${theme.palette.primary.main}`,
                    backgroundColor: (theme) => theme.palette.primary.light,
                  }}
                  onClick={(event) => dispatch(setShowOrders(null))}
                >
                  <Grid container justifyContent="space-between">
                    <Typography variant="body2">To Purchase</Typography>

                    {loading_orders ? (
                      <Loader size="14px" />
                    ) : (
                      <Chip color="primary" size="small" label={orderCount} />
                    )}
                  </Grid>
                </ListItemButton>
              ) : (
                <ListItemButton
                  sx={{
                    p: 0.75,
                    pl: 3,
                    backgroundColor: "",
                  }}
                  onClick={(event) => {
                    dispatch(setShowOrders(null));
                    window.localStorage.setItem("show_orders", null);
                  }}
                >
                  <Typography variant="body2">To Purchase</Typography>
                </ListItemButton>
              )}

              {show_orders === "purchased" ? (
                <ListItemButton
                  sx={{
                    p: 0.75,
                    pl: 3,
                    borderRight: (theme) =>
                      `4px solid ${theme.palette.primary.main}`,
                    backgroundColor: (theme) => theme.palette.primary.light,
                  }}
                  onClick={(event) => dispatch(setShowOrders("purchased"))}
                >
                  <Grid container justifyContent="space-between">
                    <Typography variant="body2">Purchased</Typography>

                    {loading_orders ? (
                      <Loader size="14px" />
                    ) : (
                      <Chip color="primary" size="small" label={orderCount} />
                    )}
                  </Grid>
                </ListItemButton>
              ) : (
                <ListItemButton
                  sx={{
                    p: 0.75,
                    pl: 3,
                    backgroundColor: "",
                  }}
                  onClick={(event) => {
                    dispatch(setShowOrders("purchased"));
                    window.localStorage.setItem("show_orders", "purchased");
                  }}
                >
                  <Typography variant="body2">Purchased</Typography>
                </ListItemButton>
              )}

              {show_orders === "fulfilled" ? (
                <ListItemButton
                  sx={{
                    p: 0.75,
                    pl: 3,
                    borderRight: (theme) =>
                      `4px solid ${theme.palette.primary.main}`,
                    backgroundColor: (theme) => theme.palette.primary.light,
                  }}
                  onClick={(event) => dispatch(setShowOrders("fulfilled"))}
                >
                  <Grid container justifyContent="space-between">
                    <Typography variant="body2">Shipped</Typography>

                    {loading_orders ? (
                      <Loader size="14px" />
                    ) : (
                      <Chip color="primary" size="small" label={orderCount} />
                    )}
                  </Grid>
                </ListItemButton>
              ) : (
                <ListItemButton
                  sx={{
                    p: 0.75,
                    pl: 3,
                    backgroundColor: "",
                  }}
                  onClick={(event) => {
                    dispatch(setShowOrders("fulfilled"));
                    window.localStorage.setItem("show_orders", "fulfilled");
                  }}
                >
                  <Typography variant="body2">Shipped</Typography>
                </ListItemButton>
              )}
            </Collapse>

            <Divider sx={{ color: (theme) => theme.palette.neutral[300] }} />

            {show_orders === "refunded" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 2,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
                onClick={(event) => dispatch(setShowOrders("refunded"))}
              >
                <Grid container justifyContent="space-between">
                  <Typography variant="body2">Cancelled</Typography>

                  {loading_orders ? (
                    <Loader size="14px" />
                  ) : (
                    <Chip color="primary" size="small" label={orderCount} />
                  )}
                </Grid>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 2,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  setOpenedCollapseItem(false);
                  dispatch(setShowOrders("refunded"));
                  window.localStorage.setItem("show_orders", "refunded");
                }}
              >
                <Typography variant="body2">Cancelled</Typography>
              </ListItemButton>
            )}

            <Divider sx={{ color: (theme) => theme.palette.neutral[300] }} />

            {show_orders === "archived" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 2,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
                onClick={(event) => dispatch(setShowOrders("archived"))}
              >
                <Grid container justifyContent="space-between">
                  <Typography variant="body2">Archived</Typography>

                  {loading_orders ? (
                    <Loader size="14px" />
                  ) : (
                    <Chip color="primary" size="small" label={orderCount} />
                  )}
                </Grid>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 2,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  setOpenedCollapseItem(false);
                  dispatch(setShowOrders("archived"));
                  window.localStorage.setItem("show_orders", "archived");
                }}
              >
                <Typography variant="body2">Archived</Typography>
              </ListItemButton>
            )}

            <Grid
              sx={{
                borderBottom: (theme) =>
                  `16px solid ${theme.palette.bg.default}`,
              }}
            ></Grid>

            <ListItemButton
              sx={{
                p: 0.75,
                pl: 2,
              }}
            >
              <Typography variant="body2">Manage Tracking</Typography>
            </ListItemButton>
          </List>
        </Grid>

        {/* Right Side Content */}
        <Grid item xs={11} pl={1}>
          {/* Breadcrumb */}
          <Grid item>
            <Card elevation={0} sx={{ px: 2, py: 0.5 }}>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography
                  variant="body2"
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                  onClick={(event) => dispatch(setShowOrders(null))}
                >
                  {page.charAt(0).toUpperCase() + page.slice(1)}
                </Typography>

                <Typography variant="body2">
                  {show_orders === "" && "All Orders"}
                  {show_orders === null && "To Purchase"}
                  {show_orders === "purchased" && "Purchased"}
                  {show_orders === "fulfilled" && "Shipped"}
                  {show_orders === "refunded" && "Cancelled"}
                  {show_orders === "archived" && "Archived"}
                </Typography>
              </Breadcrumbs>
            </Card>
          </Grid>

          {/* Quick Filter */}
          <Grid item my={1}>
            <Card
              elevation={0}
              sx={{
                width: "100%",
                px: 2,
                py: 1.2,
              }}
            >
              <Grid container pb={0.2}>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  Quick Filter
                </Typography>
              </Grid>

              <Grid container alignItems="center" my={1.2}>
                <Grid item xs={1}>
                  <Typography variant="body2">Suppliers</Typography>
                </Grid>

                <Grid
                  item
                  container
                  xs={11}
                  sx={{
                    "& .MuiTypography-root": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <Grid item mx={1.2}>
                    {selected_supplier === "" ? (
                      <Typography variant="body2" color="primary">
                        All
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        onClick={(event) => {
                          dispatch(setSelectedSupplier(""));
                          window.sessionStorage.setItem(
                            "selected_supplier",
                            ""
                          );
                        }}
                      >
                        All
                      </Typography>
                    )}
                  </Grid>

                  {supplier_menu.map((supplier, index) => (
                    <Grid item key={index} mx={1.2}>
                      {selected_supplier === supplier.value ? (
                        <Typography variant="body2" color="primary">
                          {supplier.name}
                        </Typography>
                      ) : (
                        <Typography
                          variant="body2"
                          onClick={() => {
                            if (
                              supplier.value !== "ingrammicro" &&
                              supplier.value !== "griffati"
                            ) {
                              dispatch(setSelectedSupplier(supplier.value));
                              window.sessionStorage.setItem(
                                "selected_supplier",
                                supplier.value
                              );
                            }
                          }}
                          style={{
                            cursor:
                              supplier.value === "ingrammicro" ||
                              supplier.value === "griffati"
                                ? "not-allowed"
                                : "pointer",
                            color:
                              supplier.value === "ingrammicro" ||
                              supplier.value === "griffati"
                                ? "gray"
                                : "inherit",
                          }}
                        >
                          {supplier.name}
                        </Typography>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              {/* Temporarily Hidden */}
              {/* <Grid container alignItems="center" my={1.2}>
                <Grid item xs={1}>
                  <Typography variant="body2">Warehouse</Typography>
                </Grid>

                <Grid
                  item
                  container
                  xs={11}
                  sx={{
                    "& .MuiTypography-root": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <Grid item mx={1.3}>
                    <Typography variant="body2" color="disabled">
                      All
                    </Typography>
                  </Grid>

                  <Grid item mx={1.2}>
                    <Typography variant="body2" color="disabled">
                      AU
                    </Typography>
                  </Grid>

                  <Grid item mx={1.3}>
                    <Typography variant="body2" color="disabled">
                      CN
                    </Typography>
                  </Grid>

                  <Grid item mx={1.3}>
                    <Typography variant="body2" color="disabled">
                      NZ
                    </Typography>
                  </Grid>
                </Grid>
              </Grid> */}

              <Grid
                sx={{
                  borderBottom: (theme) => `1px dashed ${theme.palette.border}`,
                }}
              ></Grid>

              <Grid container alignItems="center" my={1.2}>
                <Grid item xs={1}>
                  <Typography variant="body2">My Stores</Typography>
                </Grid>

                <Grid
                  item
                  container
                  xs={11}
                  sx={{
                    "& .MuiTypography-root": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <Grid item mx={1.2}>
                    {store === "all" ? (
                      <Typography variant="body2" color="primary">
                        All
                      </Typography>
                    ) : (
                      <Typography
                        color="disabled"
                        variant="body2"
                        // onClick={(event) => {
                        //   dispatch(setStore("all"));
                        //   window.sessionStorage.setItem("store", "all");
                        // }}
                      >
                        All
                      </Typography>
                    )}
                  </Grid>

                  <Grid item mx={1}>
                    {store === "nzshopify" ? (
                      <Typography variant="body2" color="primary">
                        NZ Shopify
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        onClick={(event) => {
                          dispatch(setStore("nzshopify"));
                          window.sessionStorage.setItem("store", "nzshopify");
                        }}
                      >
                        NZ Shopify
                      </Typography>
                    )}
                  </Grid>

                  <Grid item mx={0.8}>
                    {store === "aushopify" ? (
                      <Typography variant="body2" color="primary">
                        AU Shopify
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        onClick={(event) => {
                          dispatch(setStore("aushopify"));
                          window.sessionStorage.setItem("store", "aushopify");
                        }}
                      >
                        AU Shopify
                      </Typography>
                    )}
                  </Grid>

                  <Grid item mx={1}>
                    {store === "usshopify" ? (
                      <Typography variant="body2" color="primary">
                        US Shopify
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        onClick={(event) => {
                          dispatch(setStore("usshopify"));
                          window.sessionStorage.setItem("store", "usshopify");
                        }}
                      >
                        US Shopify
                      </Typography>
                    )}
                  </Grid>

                  <Grid item mx={1}>
                    {store === "gkshopify" ? (
                      <Typography variant="body2" color="primary">
                        GK Shopify
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        onClick={(event) => {
                          dispatch(setStore("gkshopify"));
                          window.sessionStorage.setItem("store", "gkshopify");
                        }}
                      >
                        GK Shopify
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* Temporarily Hidden */}
              {/* <Grid container alignItems="center" my={1.2}>
                <Grid item xs={1}>
                  <Typography variant="body2">Countries</Typography>
                </Grid>

                <Grid
                  item
                  container
                  xs={11}
                  sx={{
                    "& .MuiTypography-root": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <Grid item mx={1.2}>
                    <Typography variant="body2" color="disabled">
                      All
                    </Typography>
                  </Grid>

                  <Grid item mx={1}>
                    <Typography variant="body2" color="disabled">
                      Australia
                    </Typography>
                  </Grid>

                  <Grid item mx={2.3}>
                    <Typography variant="body2" color="disabled">
                      China
                    </Typography>
                  </Grid>

                  <Grid item mx={1.2}>
                    <Typography variant="body2" color="disabled">
                      UK
                    </Typography>
                  </Grid>

                  <Grid item mx={1.2}>
                    <Typography variant="body2" color="disabled">
                      New Zealand
                    </Typography>
                  </Grid>

                  <Grid item mx={1.2}>
                    <Typography variant="body2" color="disabled">
                      United States
                    </Typography>
                  </Grid>
                </Grid>
              </Grid> */}

              <Grid container alignItems="center" my={1.2}>
                <Grid item xs={1}>
                  <Typography variant="body2">Sales Channels</Typography>
                </Grid>

                <Grid
                  item
                  container
                  xs={11}
                  sx={{
                    "& .MuiTypography-root": {
                      cursor: "pointer",
                    },
                  }}
                >
                  {store === "nzshopify" && (
                    <>
                      <Grid item mx={1.2}>
                        {sales_channels === "All NZ" ? (
                          <Typography variant="body2" color="primary">All</Typography>
                        ) : (
                          <Typography
                            
                            variant="body2"
                            onClick={(event) => {
                              dispatch(setSalesChannels("All NZ"));
                              // window.sessionStorage.setItem("store", "nzshopify");
                            }}
                          >
                            All
                          </Typography>
                        )}
                      </Grid>

                      <Grid item mx={1}>
                        {sales_channels === "Goslash NZ" ? (
                          <Typography variant="body2" color="primary">Goslash NZ</Typography>
                        ) : (
                          <Typography
                            
                            variant="body2"
                            onClick={(event) => {
                              dispatch(setSalesChannels("Goslash NZ"));
                              // window.sessionStorage.setItem("store", "nzshopify");
                            }}
                          >
                            Goslash NZ
                          </Typography>
                        )}
                      </Grid>

                      <Grid item mx={1}>
                        {sales_channels === "trademe" ? (
                          <Typography variant="body2" color="primary">Trade Me</Typography>
                        ) : (
                          <Typography
                            
                            variant="body2"
                            onClick={(event) => {
                              dispatch(setSalesChannels("trademe"));
                              // window.sessionStorage.setItem("store", "nzshopify");
                            }}
                          >
                            Trade Me
                          </Typography>
                        )}
                      </Grid>

                      <Grid item mx={1.6}>
                        {sales_channels === "TheMarket" ? (
                          <Typography variant="body2" color="primary">The Market</Typography>
                        ) : (
                          <Typography
                            
                            variant="body2"
                            onClick={(event) => {
                              dispatch(setSalesChannels("TheMarket"));
                              // window.sessionStorage.setItem("store", "aushopify");
                            }}
                          >
                            The Market
                          </Typography>
                        )}
                      </Grid>

                      <Grid item mx={1.2}>
                        {sales_channels === "Kogan NZ" ? (
                          <Typography variant="body2" color="primary">Kogan NZ</Typography>
                        ) : (
                          <Typography
                            
                            variant="body2"
                            onClick={(event) => {
                              dispatch(setSalesChannels("Kogan NZ"));
                              // window.sessionStorage.setItem("store", "aushopify");
                            }}
                          >
                            Kogan NZ
                          </Typography>
                        )}
                      </Grid>

                      <Grid item mx={1.2}>
                        {sales_channels === "Onceit" ? (
                          <Typography variant="body2" color="primary">Onceit</Typography>
                        ) : (
                          <Typography
                            
                            variant="body2"
                            onClick={(event) => {
                              dispatch(setSalesChannels("Onceit"));
                              // window.sessionStorage.setItem("store", "aushopify");
                            }}
                          >
                            Onceit
                          </Typography>
                        )}
                      </Grid>

                      <Grid item mx={1.2}>
                        {sales_channels === "MySale NZ" ? (
                          <Typography variant="body2" color="primary">MySale NZ</Typography>
                        ) : (
                          <Typography
                            
                            variant="body2"
                            onClick={(event) => {
                              dispatch(setSalesChannels("MySale NZ"));
                              // window.sessionStorage.setItem("store", "aushopify");
                            }}
                          >
                            MySale NZ
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}

                  {store === "aushopify" && (
                    <>
                      <Grid item mx={1.2}>
                        {sales_channels === "All AU" ? (
                          <Typography variant="body2" color="primary">All</Typography>
                        ) : (
                          <Typography
                            
                            variant="body2"
                            onClick={(event) => {
                              dispatch(setSalesChannels("All AU"));
                            }}
                          >
                            All
                          </Typography>
                        )}
                      </Grid>

                      <Grid item mx={1}>
                        {sales_channels === "Goslash AU" ? (
                          <Typography variant="body2" color="primary">Goslash AU</Typography>
                        ) : (
                          <Typography
                            
                            variant="body2"
                            onClick={(event) => {
                              dispatch(setSalesChannels("Goslash AU"));
                            }}
                          >
                            Goslash AU
                          </Typography>
                        )}
                      </Grid>

                      <Grid item mx={1}>
                        {sales_channels === "Kogan AU" ? (
                          <Typography variant="body2" color="primary">Kogan AU</Typography>
                        ) : (
                          <Typography
                            
                            variant="body2"
                            onClick={(event) => {
                              dispatch(setSalesChannels("Kogan AU"));
                              // window.sessionStorage.setItem("store", "nzshopify");
                            }}
                          >
                            Kogan AU
                          </Typography>
                        )}
                      </Grid>

                      <Grid item mx={1.6}>
                        {sales_channels === "eBay" ? (
                          <Typography variant="body2" color="primary">eBay</Typography>
                        ) : (
                          <Typography
                            
                            variant="body2"
                            onClick={(event) => {
                              dispatch(setSalesChannels("eBay"));
                              // window.sessionStorage.setItem("store", "aushopify");
                            }}
                          >
                            eBay
                          </Typography>
                        )}
                      </Grid>

                      <Grid item mx={1.2}>
                        {sales_channels === "Catch" ? (
                          <Typography variant="body2" color="primary">Catch</Typography>
                        ) : (
                          <Typography
                            
                            variant="body2"
                            onClick={(event) => {
                              dispatch(setSalesChannels("Catch"));
                              // window.sessionStorage.setItem("store", "aushopify");
                            }}
                          >
                            Catch
                          </Typography>
                        )}
                      </Grid>

                      <Grid item mx={1.2}>
                        {sales_channels === "MySale AU" ? (
                          <Typography variant="body2" color="primary">MySale AU</Typography>
                        ) : (
                          <Typography
                            
                            variant="body2"
                            onClick={(event) => {
                              dispatch(setSalesChannels("MySale AU"));
                              // window.sessionStorage.setItem("store", "aushopify");
                            }}
                          >
                            MySale AU
                          </Typography>
                        )}
                      </Grid>

                      <Grid item mx={1.2}>
                        {sales_channels === "MyDeal" ? (
                          <Typography variant="body2" color="primary">MyDeal</Typography>
                        ) : (
                          <Typography
                            
                            variant="body2"
                            onClick={(event) => {
                              dispatch(setSalesChannels("MyDeal"));
                              // window.sessionStorage.setItem("store", "aushopify");
                            }}
                          >
                            MyDeal
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}

                  {store === "usshopify" && (
                    <>
                      <Grid item mx={1.2}>
                        {sales_channels === "All US" ? (
                          <Typography variant="body2" color="disabled">
                            All
                          </Typography>
                        ) : (
                          <Typography
                            color="disabled"
                            variant="body2"
                            onClick={(event) => {
                              dispatch(setSalesChannels("All US"));
                            }}
                          >
                            All
                          </Typography>
                        )}
                      </Grid>

                      <Grid item mx={1.2}>
                        {sales_channels === "Newegg" ? (
                          <Typography variant="body2" color="disabled">
                            Newegg
                          </Typography>
                        ) : (
                          <Typography
                            color="disabled"
                            variant="body2"
                            onClick={(event) => {
                              dispatch(setSalesChannels("Newegg"));
                            }}
                          >
                            Newegg
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}

                  {store === "gkshopify" && (
                    <>
                      <Grid item mx={1.2}>
                        {sales_channels === "All GK" ? (
                          <Typography variant="body2" color="primary">All</Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            onClick={(event) => {
                              dispatch(setSalesChannels("All GK"));
                            }}
                          >
                            All
                          </Typography>
                        )}
                      </Grid>

                      <Grid item mx={1.2}>
                        {sales_channels === "trademe" ? (
                          <Typography variant="body2" color="primary">
                            Trade Me
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            onClick={(event) => {
                              dispatch(setSalesChannels("trademe"));
                            }}
                          >
                            Trade Me
                          </Typography>
                        )}
                      </Grid>

                      <Grid item mx={1.2}>
                        {sales_channels === "TheMarket" ? (
                          <Typography variant="body2" color="primary">
                            The Market
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            onClick={(event) => {
                              dispatch(setSalesChannels("TheMarket"));
                            }}
                          >
                            The Market
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>

              <Grid
                sx={{
                  borderBottom: (theme) => `1px dashed ${theme.palette.border}`,
                }}
              ></Grid>

              <Grid container alignItems="center" my={1.2}>
                <Grid item xs={1}>
                  <Typography variant="body2">Order Status</Typography>
                </Grid>

                <Grid
                  item
                  container
                  xs={11}
                  sx={{
                    "& .MuiTypography-root": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <Select
                    displayEmpty
                    value={paymentStatusSelectValue}
                    onChange={handleChangePaymentStatusSelect}
                    size="small"
                    sx={{
                      mx: 1.2,
                      height: 26,
                      "& legend": {
                        width: "unset",
                      },
                    }}
                  >
                    <MenuItem value="">
                      <Typography variant="body2">Payment Status</Typography>
                    </MenuItem>

                    <MenuItem value="not_paid" sx={{ height: 26 }}>
                      <Typography variant="body2">Awaiting Payment</Typography>
                    </MenuItem>

                    <MenuItem value="paid" sx={{ height: 26 }}>
                      <Typography variant="body2">Paid</Typography>
                    </MenuItem>

                    <MenuItem value="refunded" sx={{ height: 26 }}>
                      <Typography variant="body2">Refunded</Typography>
                    </MenuItem>
                  </Select>

                  <Select
                    displayEmpty
                    value={fulfillmentStatusSelectValue}
                    onChange={handleChangeFulfillmentStatusSelect}
                    size="small"
                    sx={{
                      mx: 1.2,
                      height: 26,
                      "& legend": {
                        width: "unset",
                      },
                    }}
                  >
                    <MenuItem value="">
                      <Typography variant="body2">
                        Fulfillment Status
                      </Typography>
                    </MenuItem>

                    <MenuItem value="not_fulfilled" sx={{ height: 26 }}>
                      <Typography variant="body2">
                        Awaiting Fulfillment
                      </Typography>
                    </MenuItem>

                    <MenuItem value="fulfilled" sx={{ height: 26 }}>
                      <Typography variant="body2">Fulfilled</Typography>
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>

              <Grid container alignItems="center" my={1.2}>
                <Grid item xs={1}>
                  <Typography variant="body2">Date</Typography>
                </Grid>

                <Grid item container xs={11}>
                  <NativePickers />
                  {/* <DatePicker /> */}
                </Grid>
              </Grid>

              <Grid container alignItems="center" my={1.2}>
                <Grid item xs={1}>
                  <Typography variant="body2">Add Missing Orders</Typography>
                </Grid>

                <Grid item container xs={11}>
                  {/* <p>Use comma (,) for multiple orders</p> */}
                  <textarea
                    placeholder="add one order in one time*"
                    spellcheck="false"
                    value={missingOrder.value}
                    style={{ marginLeft: "1%", width: "400px" }}
                    onChange={(event) => {
                      setMissing((prev) => {
                        return { ...prev, value: event.target.value };
                      });
                    }}
                  />
                  <Button
                    variant="contained"
                    disabled={missingOrder.disable}
                    sx={{
                      width: 70,
                      minWidth: 0,
                      minHeight: 0,
                      padding: 0,
                      mx: 0.5,
                    }}
                    cursor="pointer"
                    onClick={handleMissingOrder}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          {/* Sort & Search & Pagination */}
          <Grid
            item
            container
            xs={11.7}
            justifyContent="space-between"
            alignItems="center"
            my={1}
          >
            <Grid item container xs={8} alignItems="center" px={1}>
              <Sort
                ascending={ascending}
                sortCategory={sortCategory}
                setSortCategory={setSortCategory}
                handleSortAscending={handleSortAscending}
                handleSortDescending={handleSortDescending}
              />
              <Searchbar />
            </Grid>

            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="center"
              xs={4}
            >
              <Pagination
                page={page}
                total_counts={total_counts}
                selectedOrders={selectedOrders}
                setSelectedOrders={setSelectedOrders}
              />
            </Grid>
          </Grid>

          {/* Selected Orders */}
          <Grid
            item
            container
            xs={11.7}
            alignContent="center"
            sx={{
              py: 0.5,
              backgroundColor: "bg.paper",
              display: "Grid",
              gridTemplateColumns: "50% 50%",
              borderRadius: "4px",
            }}
          >
            <Grid
              item
              container
              justifyContent="flex-start"
              alignContent="center"
              sx={{
                width: "100%",
                display: "Grid",
                gridTemplateColumns: "4% 96%",
              }}
            >
              <Grid container justifyContent="center">
                <MainCheckbox
                  orders_nz_shopify={orders_nz_shopify}
                  start={start}
                  end={end}
                  setSelectedOrders={setSelectedOrders}
                  selectedOrders={selectedOrders}
                />
              </Grid>

              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <SmallContainedButton
                    width={110}
                    onClick={handleClickBulkActions}
                  >
                    Bulk Actions
                  </SmallContainedButton>

                  <Menu
                    // id="bulk-actions-menu"
                    anchorEl={bulkActionsAnchorEl}
                    open={openBulkActions}
                    onClose={handleCloseBulkActions}
                    MenuListProps={{
                      sx: { width: "100%" },
                    }}
                  >
                    {show_orders === "" && (
                      <>
                        <MenuItem
                          disabled
                          onClick={handleCloseBulkActions}
                          sx={{ height: 26, fontSize: "0.8rem" }}
                        >
                          No Action
                        </MenuItem>
                      </>
                    )}

                    {show_orders === null && (
                      <>
                        <MenuItem
                          disabled={selectedOrders.length === 0}
                          onClick={(event) => handleSelectedPurchase(event)}
                          sx={{ height: 26, fontSize: "0.8rem" }}
                        >
                          Purchase Selected
                        </MenuItem>

                        <MenuItem
                          disabled
                          onClick={handleCloseBulkActions}
                          sx={{ height: 26, fontSize: "0.8rem" }}
                        >
                          Cancel Selected
                        </MenuItem>
                      </>
                    )}

                    {show_orders === "purchased" && (
                      <>
                        <MenuItem
                          disabled
                          // onClick={(event) => handleSelectedPurchase(event)}
                          sx={{ height: 26, fontSize: "0.8rem" }}
                        >
                          Fulfill Selected
                        </MenuItem>

                        <MenuItem
                          disabled
                          onClick={handleCloseBulkActions}
                          sx={{ height: 26, fontSize: "0.8rem" }}
                        >
                          Cancel Selected
                        </MenuItem>
                      </>
                    )}

                    {show_orders === "fulfilled" && (
                      <>
                        <CustomSheetModal
                          opencustom={opencustom}
                          setOpenCustom={setOpenCustom}
                          handleCloseCustom={handleCloseCustom}
                          check={"ordernew"}
                        />

                        <MenuItem
                          disabled
                          // onClick={(event) => handleSelectedPurchase(event)}
                          sx={{ height: 26, fontSize: "0.8rem" }}
                        >
                          Tracking Selected
                        </MenuItem>

                        <MenuItem
                          disabled
                          onClick={handleCloseBulkActions}
                          sx={{ height: 26, fontSize: "0.8rem" }}
                        >
                          Cancel Selected
                        </MenuItem>
                      </>
                    )}

                    {show_orders === "refunded" && (
                      <>
                        <MenuItem
                          disabled
                          sx={{ height: 26, fontSize: "0.8rem" }}
                        >
                          Purchase Selected
                        </MenuItem>

                        <MenuItem
                          disabled
                          sx={{ height: 26, fontSize: "0.8rem" }}
                        >
                          Archive Selected
                        </MenuItem>

                        <MenuItem
                          disabled
                          onClick={handleCloseBulkActions}
                          sx={{ height: 26, fontSize: "0.8rem" }}
                        >
                          Delete Selected
                        </MenuItem>
                      </>
                    )}
                  </Menu>
                </Grid>

                <Grid item mx={1}>
                  <Typography variant="body2">
                    Selected Orders: {selectedOrders.length}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              container
              justifyContent="flex-end"
              alignContent="center"
              sx={{
                width: "100%",
              }}
            >
              <SmallContainedButton onClick={handleToggleAllCustomerDetails}>
                {showAllCustomerDetails ? "Hide All" : "Expand All"}
              </SmallContainedButton>

              <Button
                variant="outlined"
                sx={{
                  width: 24,
                  height: 24,
                  minWidth: 0,
                  minHeight: 0,
                  padding: 0,
                  mx: 0.5,
                }}
                onClick={handleToggleAllCustomerDetails}
              >
                {showAllCustomerDetails ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </Button>
            </Grid>
          </Grid>

          {/* Order List */}
          <Grid container xs={11.7}>
            {orders_nz_shopify.map((order, index) => {
              return (
                <OrdersLayout
                  index={index}
                  key={order.orderid}
                  fetchorders={fetchorders}
                  order={order}
                  selectedOrders={selectedOrders}
                  setSelectedOrders={setSelectedOrders}
                  handlePurchase={handlePurchase}
                  handleSave={handleSave}
                  show_orders={show_orders}
                  setOpenFailedEdit={setOpenFailedEdit}
                  handleCancel={handleCancel}
                  carrier={carrier}
                  setCarrier={setCarrier}
                  vidaxlCarrier={vidaxlCarrier}
                  setVidaxlCarrier={setVidaxlCarrier}
                  dropshipzoneCarrier={dropshipzoneCarrier}
                  setDropshipzoneCarrier={setDropshipzoneCarrier}
                  note={note}
                  setNote={setNote}
                  showAllCustomerDetails={showAllCustomerDetails}
                  count3={count3}
                  setCount3={setCount3}
                  indexM={indexM}
                  setIndexm={setIndexm}
                />
              );
            })}

            {loading_orders && (
              <Grid
                item
                container
                justifyContent="center"
                alignContent="center"
                minHeight="300px"
              >
                <Loader size="40px" />
              </Grid>
            )}
          </Grid>

          <CustomizedSnackbars
            handleClose={handleClose}
            open={open.open}
            message={open.message}
          />
          <CustomizedSnackbarsFailed
            handleClose={handleCloseFailed}
            open={openfailed.open}
            message={openfailed.message}
            handleSave={handleSave}
            order={openfailed.order}
          />
          <CustomizedSnackbarsFailedEdit
            handleClose={handleCloseFailedEdit}
            open={openfailededit.open}
            message={openfailededit.message}
            handleSave={handleSave}
            order={openfailededit.order}
          />
          <ScrollToTopOrBottom
            scrollToTopId="orders_side_menu_list"
            scrollToBottomId="footer"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default OrdersNew;
