import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Divider,
  List,
  ListItemButton,
} from "@mui/material";

export const SourcingSideMenu = ({ setShowProducts }) => {
  const dispatch = useDispatch();
  const show_products = useSelector((state) => state.show_products);

  //Accordion
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (show_products === "new" || show_products === "imported") {
      setExpanded("panel1");
    }

    if (show_products === "exported") {
      setExpanded("panel2");
    }

    if (show_products === "deleted") {
      setExpanded("panel3");
    }
  }, [show_products]);

  return (
    <Grid item xs={1}>
      <List
        component="nav"
        id="sourcing_side_menu_list"
        sx={{
          py: 0,
          bgcolor: "background.paper",
          "& .MuiAccordionSummary-root": {
            m: 0,
            p: 0,
            minHeight: "100%",
          },
          "& .MuiAccordionSummary-content": {
            m: 0,
          },

          "& .MuiAccordionDetails-root": {
            p: 0,
          },

          "& .MuiListItemButton-root": {
            "&:hover": {
              backgroundColor: (theme) => theme.palette.primary.light,
            },
          },
        }}
      >
        <Grid
          item
          sx={{
            p: 0.5,
            pl: 1,
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 700 }}>
            Sourcing
          </Typography>
        </Grid>

        <Divider sx={{ color: (theme) => theme.palette.neutral[300] }} />

        <Accordion
          disableGutters
          elevation={0}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <ListItemButton
              sx={{
                p: 0.75,
                pl: 2,
                borderLeft: (theme) =>
                  show_products === "new" || show_products === "imported"
                    ? `4px solid ${theme.palette.primary.main}`
                    : "",
              }}
            >
              <Typography variant="body2">Import</Typography>
            </ListItemButton>
          </AccordionSummary>

          <AccordionDetails>
            {show_products === "new" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">Product Importer</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setShowProducts("new"));
                  window.localStorage.setItem("show_products", "new");
                }}
              >
                <Typography variant="body2">Product Importer</Typography>
              </ListItemButton>
            )}

            {show_products === "imported" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">Imported Products</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setShowProducts("imported"));
                  window.localStorage.setItem("show_products", "imported");
                }}
              >
                <Typography variant="body2">Imported Products</Typography>
              </ListItemButton>
            )}
          </AccordionDetails>
        </Accordion>

        <Divider sx={{ color: (theme) => theme.palette.neutral[300] }} />

        <Accordion
          disableGutters
          elevation={0}
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <ListItemButton
              sx={{
                p: 0.75,
                pl: 2,
                borderLeft: (theme) =>
                  show_products === "exported"
                    ? `4px solid ${theme.palette.primary.main}`
                    : "",
              }}
            >
              <Typography variant="body2">Export</Typography>
            </ListItemButton>
          </AccordionSummary>

          <AccordionDetails>
            {show_products === "exported" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">Exported Products</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setShowProducts("exported"));
                  window.localStorage.setItem("show_products", "exported");
                }}
              >
                <Typography variant="body2">Exported Products</Typography>
              </ListItemButton>
            )}
          </AccordionDetails>
        </Accordion>

        <Divider sx={{ color: (theme) => theme.palette.neutral[300] }} />

        <Accordion
          disableGutters
          elevation={0}
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary aria-controls="panel3-content" id="panel3-header">
            <ListItemButton
              sx={{
                p: 0.75,
                pl: 2,
                borderLeft: (theme) =>
                  show_products === "deleted"
                    ? `4px solid ${theme.palette.primary.main}`
                    : "",
              }}
            >
              <Typography variant="body2">Delete</Typography>
            </ListItemButton>
          </AccordionSummary>

          <AccordionDetails>
            {show_products === "deleted" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">Deleted Products</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setShowProducts("deleted"));
                  window.localStorage.setItem("show_products", "deleted");
                }}
              >
                <Typography variant="body2">Deleted Products</Typography>
              </ListItemButton>
            )}
          </AccordionDetails>
        </Accordion>
      </List>
    </Grid>
  );
};
