import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect({category, selectedcategory, handleChange}) {

// const [selectedcategory, setSelectedcategory] = React.useState('');
//
//   const handleChange = (event) => {
//     console.log(event.target.value)
//     setSelectedcategory(event.target.value);
//   };
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Product Categories</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedcategory}
          label="Product Categories"
          onChange={handleChange}
        >
          {category.cat_list?(
            category.cat_list.map(item => {
              return (
                <MenuItem value={item.cat_id}>{item.cat_name}</MenuItem>
              )
            })
          ):""

          }

        </Select>
      </FormControl>
    </Box>
  );
}
