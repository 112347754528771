import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import {
  setSearch,
  setSkuPreference,
  setDescriptionPreference,
  setArticles,
  setSubsettings,
  setToken,
  setCountrylist,
  setSelectedCountryShipFrom,
  setSelectedCountryShipTo,
  setSelectedShippingMethod,
  setShippingMethods,
  setSelectedStore,
  setSelectedSupplier,
  setSelectedWarehouse,
  setSelectedVariantType,
  setSelectedCollection,
} from "../../actions";

export default function BasicSelect({ label, menu, type }) {
  const [age, setAge] = React.useState("");
  const selected_country_shipfrom = useSelector(
    (state) => state.selected_country_shipfrom
  );
  const selected_country_shipto = useSelector(
    (state) => state.selected_country_shipto
  );
  const selected_shipping_method = useSelector(
    (state) => state.selected_shipping_method
  );
  const country_list_shipfrom = useSelector(
    (state) => state.country_list_shipfrom
  );
  const country_list_shipto = useSelector((state) => state.country_list_shipto);
  const shipping_methods = useSelector((state) => state.shipping_methods);
  const selected_store = useSelector((state) => state.selected_store);
  const selected_supplier = useSelector((state) => state.selected_supplier);
  const selected_warehouse = useSelector((state) => state.selected_warehouse);
  const selected_variant_type = useSelector(
    (state) => state.selected_variant_type
  );
  const selected_collection = useSelector((state) => state.selected_collection);
  const sku_preference = useSelector((state) => state.sku_preference);
  const description_preference = useSelector(
    (state) => state.description_preference
  );
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setAge(event.target.value);
    if (type === "shipfrom") {
      dispatch(setSelectedCountryShipFrom(event.target.value));
    }
    if (type === "shipto") {
      dispatch(setSelectedCountryShipTo(event.target.value));
    }
    if (type === "shippingmethod") {
      dispatch(setSelectedShippingMethod(event.target.value));
    }
    if (type === "choosestore") {
      dispatch(setSelectedStore(event.target.value));
    }
    if (type === "choosesupplier") {
      dispatch(setSelectedSupplier(event.target.value));
    }
    if (type === "choosewarehouse") {
      dispatch(setSelectedWarehouse(event.target.value));
    }
    if (type === "choosevarianttype") {
      dispatch(setSelectedVariantType(event.target.value));
    }
    if (type === "choosecollection") {
      dispatch(setSelectedCollection(event.target.value));
    }

    if (type === "sku_preference") {
      dispatch(setSkuPreference(event.target.value));
    }

    if (type === "description_preference") {
      dispatch(setDescriptionPreference(event.target.value));
      window.localStorage.setItem("description_preference", event.target.value);
    }
  };
  useEffect(() => {
    if (
      selected_country_shipfrom === "CN" &&
      selected_country_shipto === "United States"
    ) {
      dispatch(
        setShippingMethods([
          "LC_SHIPMENT_BANGGOOD_EXPRESS",
          "LC_SHIPMENT_AIRMAIL_AIRMAIL",
          "LC_SHIPMENT_EXPRESS_SHIPPING",
        ])
      );
    }
    if (
      selected_country_shipfrom === "CN" &&
      selected_country_shipto === "Australia"
    ) {
      dispatch(
        setShippingMethods([
          "LC_SHIPMENT_STANDARD_MAIL_REGISTER",
          "LC_SHIPMENT_AU_DIRECT_MAIL",
          "LC_SHIPMENT_PRIORITY_MAIL",
          "LC_SHIPMENT_EXPRESS_SHIPPING",
        ])
      );
    }
    if (
      selected_country_shipfrom === "CN" &&
      selected_country_shipto === "New Zealand"
    ) {
      dispatch(
        setShippingMethods([
          "LC_SHIPMENT_NZ_DIRECT_MAIL",
          "LC_SHIPMENT_AIRMAIL_AIRMAIL",
        ])
      );
    }
    if (
      selected_country_shipfrom === "CN" &&
      selected_country_shipto === "United Kingdom"
    ) {
      dispatch(setShippingMethods(["LC_SHIPMENT_BANGGOOD_EXPRESS"]));
    }
    if (
      selected_country_shipfrom === "USA" &&
      selected_country_shipto === "United States"
    ) {
      dispatch(
        setShippingMethods([
          "LC_SHIPMENT_US_STANDARD_SHIP",
          "LC_SHIPMENT_US_EXPRESS_SHIP",
        ])
      );
    }
    if (
      selected_country_shipfrom === "USA" &&
      selected_country_shipto === "Canada"
    ) {
      dispatch(setShippingMethods(["LC_SHIPMENT_US_INTER_SHIP"]));
    }
    if (
      selected_country_shipfrom === "USA" &&
      selected_country_shipto === "Australia"
    ) {
      dispatch(setShippingMethods(["LC_SHIPMENT_US_INTER_SHIP"]));
    }
    if (
      selected_country_shipfrom === "AU" &&
      selected_country_shipto === "Australia"
    ) {
      dispatch(
        setShippingMethods([
          "LC_SHIPMENT_AU_STANDARD_SHIP",
          "LC_SHIPMENT_AU_EXPRESS_SHIP",
        ])
      );
    }
    if (
      selected_country_shipfrom === "AU" &&
      selected_country_shipto === "New Zealand"
    ) {
      dispatch(
        setShippingMethods([
          "LC_SHIPMENT_NZ_STANDARD_SHIPPING",
          "LC_SHIPMENT_NZ_STANDARD_SHIPPING_REGISTER",
        ])
      );
    }

    if (
      selected_country_shipfrom === "UK" &&
      selected_country_shipto === "United Kingdom"
    ) {
      dispatch(
        setShippingMethods([
          "LC_SHIPMENT_UK_STANDARD_SHIPPING_REG",
          "LC_SHIPMENT_UK_STANDARD_SHIPPING",
        ])
      );
    }
  }, [selected_country_shipfrom, selected_country_shipto]);

  const value = () => {
    switch (type) {
      case "shippingmethod":
        return selected_shipping_method;
      case "shipfrom":
        return selected_country_shipfrom;
      case "shipto":
        return selected_country_shipto;
      case "choosestore":
        return selected_store;
      case "choosesupplier":
        return selected_supplier;
      case "choosewarehouse":
        return selected_warehouse;
      case "choosevarianttype":
        return selected_variant_type;
      case "choosecollection":
        return selected_collection;
      case "sku_preference":
        return sku_preference;
      case "description_preference":
        return description_preference;
      default:
        return "";
    }
  };
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          label={label}
          value={value()}
          onChange={handleChange}
          id="demo-simple-select"
          labelId="demo-simple-select-label"
        >
          {menu.map((item, index) => {
            return (
              <MenuItem key={index} value={item.value}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
