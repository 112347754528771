import React, { useState, useEffect } from "react";
import Axios from "axios";
import AppBar from "./components/navbar/navbar";
import ShopifyOrders from "./components/shopifyorders/shopifyorders";
import { baseTheme, lightTheme, darkTheme } from "./theme";
import { Toaster } from "react-hot-toast";
import {
  Grid,
  Snackbar,
  Alert,
  AlertTitle,
  IconButton
} from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { Loader } from "./components/common/Loader";

import Login from "./components/login/login";
// import Login from "./components/login/login_copy";
// import LoginRev from "./components/login/loginrev";
import SupplierProducts from "./components/supplierproducts/supplierproducts";

import ProductInfo from "./components/productinfo/productinfo";
import PrimarySearchAppBar from "./components/appbar/appbar";
import Footer from "./components/footer/footer";
import Settings from "./components/settings/settings";
import Sourcing from "./components/sourcing/sourcing";
import SourcingNew from "./components/sourcing/sourcingnew";
import Orders from "./components/orders/orders";
import OrdersNew from "./components/orders/ordersnew";
import ShopifyProducts from "./components/listings/shopifyproducts";
import DropShipProduct from "./components/orders/dropshipproduct";
import Dashboard from "./components/dashboard/dashboard";
import DashboardRestricted from "./components/dashboard/dashboardrestricted";
import Tools from "./components/tools/tools";
import Productexportreport from "./components/report/productexportreport";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import SimpleBar from "simplebar-react";

import "./App.css";

import {
  setSearch,
  setImportPage,
  setDescriptionPreference,
  setShowProducts,
  setStore,
  setShowOrders,
  setSelectedSupplier,
  setArticles,
  setSubsettings,
  setToken,
  setCountrylist,
  setShowImportedProductsFilter,
  setShippingMethods,
  setCategoriesLevel1,
  setCategoriesLevel2,
  setCategoriesLevel3,
  setCategoriesLevel4,
  setCategoriesLevel5,
  setCategoriesLevel6,
  setImportedProducts,
  setImportedProductsFilter,
  setSelectedCategoryLevel2,
  setSelectedCategoryLevel3,
  setSelectedCategoryLevel4,
  setSelectedCategoryLevel5,
  setSelectedCategoryLevel6,
} from "./actions";
import Productexportreportdetail from "./components/report/productexportreportdetail";

function App({ store }) {
  const admin = {
    username: "sales@salespro.net.nz",
    password: "Salespro@2014",
  };

  const [page, setPage] = useState("dashboard");
  const [logged, setLogged] = useState("trying");
  const [adminuser, setAdminuser] = useState("");
  const [adminpassword, setAdminpassword] = useState("");
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const access = useSelector((state) => state.access);
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  Axios.defaults.withCredentials = true;

  const handlePage = (event, id) => {
    setPage(id);
    window.sessionStorage.setItem("page", id);
  };

  const handleTheme = () => {
    if (isDarkTheme) {
      setIsDarkTheme(false);
    } else {
      setIsDarkTheme(true);
    }
  };

  //Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("page") !== null) {
      setPage(window.sessionStorage.getItem("page"));
    }
  }, []);

  useEffect(() => {
    if (window.sessionStorage.getItem("store") !== null) {
      dispatch(setStore(window.sessionStorage.getItem("store")));
    }
  }, []);

  useEffect(() => {
    if (window.sessionStorage.getItem("selected_supplier") !== null) {
      dispatch(
        setSelectedSupplier(window.sessionStorage.getItem("selected_supplier"))
      );
    }
  }, []);

  useEffect(() => {
    if (window.localStorage.getItem("show_products") !== null) {
      dispatch(setShowProducts(window.localStorage.getItem("show_products")));
    }
  }, []);

  useEffect(() => {
    if (window.localStorage.getItem("show_orders") !== null) {
      if (window.localStorage.getItem("show_orders") === "null") {
        dispatch(setShowOrders(null));
      } else {
        dispatch(setShowOrders(window.localStorage.getItem("show_orders")));
      }
    }
  }, []);

  useEffect(() => {
    if (window.localStorage.getItem("import_page") !== null) {
      dispatch(setImportPage(window.localStorage.getItem("import_page")));
    }
  }, []);

  useEffect(() => {
    if (window.localStorage.getItem("description_preference") !== null) {
      dispatch(
        setDescriptionPreference(
          window.localStorage.getItem("description_preference")
        )
      );
    }
  }, []);

  // useEffect(() => {
  //   window.sessionStorage.removeItem("store")
  //   window.sessionStorage.removeItem("selected_supplier")
  // },[page])

  const handleLogin = (event, setSubmitting) => {
    fetch("https://api.saleprodev.com/login/loginuser", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: event.email, password: event.password }),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("loginuser", response);
        if (response.cookie) {
          setLogged("true");

          //Check AliExpress Token Expire Date
          // setOpenSnackbar(true)

        } else {
          alert("Login Details are Incorrect");
        }
        // setSubmitting(false);
      });
  };

  const handleApplyForAccess = () => { };

  const handleLogOut = (event) => {
    fetch("https://api.saleprodev.com/login/logout", {
      method: "get",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("Logout response", response);
        if (response.logged === false) {
          setLogged("false");
        }
      });
  };

  const handleAdminUser = (event, id) => {
    if (id === "user") {
      setAdminuser(event.target.value);
    }
    if (id === "pass") {
      setAdminpassword(event.target.value);
    }
  };

  useEffect(() => {
    fetch("https://api.saleprodev.com/login/login", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.logged) {
          setLogged("true");

          //Check AliExpress Token Expire Date
          // setOpenSnackbar(true)
        } else {
          setLogged("false");
        }
      }).catch((err)=> {
        
      })
  }, []);

  console.log(page);
  return (
    <ThemeProvider
      theme={responsiveFontSizes(
        createTheme(baseTheme, isDarkTheme ? darkTheme : lightTheme)
      )}
    >
      <Grid
        className="mainpage"
        sx={{ backgroundColor: (theme) => theme.palette.bg.default }}
      >
        {logged === "false" ? (
          <Login
            handleLogin={handleLogin}
            handleApplyForAccess={handleApplyForAccess}
            handleAdminUser={handleAdminUser}
          />
        ) : (
          ""
        )}

        {logged === "trying" ? (
          <Grid
            container
            sx={{
              width: "100vw",
              height: "100vh",
            }}
          >
            <Loader size={70} />
          </Grid>
        ) : (
          ""
        )}

        {logged === "true" ? (
          <>
            <PrimarySearchAppBar
              handlePage={handlePage}
              page={page}
              handleLogOut={handleLogOut}
              handleTheme={handleTheme}
            />
            <Grid container minHeight="100vh" mt="40px" justifyContent="center">
              {page === "dashboard" && access === "allowed" ? (
                <Dashboard />
              ) : (
                ""
              )}
              {page === "dashboard" && access === "restricted" ? (
                <DashboardRestricted />
              ) : (
                ""
              )}
              {page === "settings" ? <Settings page={page} /> : ""}
              {page === "shopifyorders" ? <ShopifyOrders /> : ""}
              {page === "supplierproducts" ? <SupplierProducts /> : ""}
              {page === "shopifyproducts" ? <ShopifyProducts /> : ""}
              {page === "sourcing" ? (
                <SourcingNew page={page} handlePage={handlePage} />
              ) : (
                ""
              )}
              {page === "orders" ? <OrdersNew page={page} /> : ""}
              {page === "listings" ? <ShopifyProducts page={page} /> : ""}
              {page === "tools" ? <Tools page={page} /> : ""}
              {page === "report" ? <Productexportreport handlePage={handlePage} page={page}/> : ""}
              {page == "report_detail" ? <Productexportreportdetail handlePage={handlePage} page={page}/>: ""}
            </Grid>
            <Footer />
          </>
        ) : (
          ""
        )}
      </Grid>
      <Toaster position="top-center" toastOptions={{ duration: 3000 }} />

      <Snackbar
        open={openSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity="error"
          sx={{ width: "100%" }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>Warning</AlertTitle>
          {/* {expired ? "Your AliExpress Token is expired, please renew now." :
            `Your AliExpress Token is going to expire in ${daysLeft} days, please renew as soon as possible.`
          } */}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default App;
