import React from "react";
import { useSelector } from "react-redux";
import AliExpressMapping from "./AliExpressMapping";
import KoganSettings from "../settings/kogansettings";
import KoganProductUpdater from "./KoganProductUpdater";
import SkuMapper from "./SkuMapper";
import SkuChecker from "./SkuChecker";
import DSZsetting from "../settings/DSZsetting";

export default function Tools({ page }) {
  const tools_menu = useSelector((state) => state.tools_menu);

  return (
    <>
      {tools_menu === "aliexpress_mapping" && <AliExpressMapping page={page} />}
      {tools_menu === "kogan_mapping" && <KoganSettings />}
      {tools_menu === "dsz_mapping" && <DSZsetting/>}

      {tools_menu === "kogan_product_updater" && (
        <KoganProductUpdater page={page} />
      )}
      {tools_menu === "sku_mapper" && <SkuMapper />}
      {tools_menu === "sku_checker" && <SkuChecker />}
    </>
  );
}
