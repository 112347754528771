import React, {useState, useEffect} from "react"
import Card from '@mui/material/Card';
import BasicSelect from "../chunks/dropdown"
import CardMedia from '@mui/material/CardMedia';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import {useSelector, useDispatch} from "react-redux"
import {setSearch, setArticles,setSubsettings, setToken, setCountrylist,setShippingMethods, setShowImportedProductsFilter,setCategoriesLevel1, setCategoriesLevel2,setCategoriesLevel3, setCategoriesLevel4,setCategoriesLevel5, setCategoriesLevel6,setImportedProducts,setImportedProductsFilter,setSelectedCategoryLevel2,setSelectedCategoryLevel3, setSelectedCategoryLevel4, setSelectedCategoryLevel5, setSelectedCategoryLevel6} from "../../actions"

function ProductInfo({product_id, token, cat_id, product}) {
  const [productinfo, setProductInfo] = useState("")
  const [open, setOpen] = React.useState(false);
  const [price, setPrice] = useState([])
  const [cost, setCost] = useState("")
  const [options, setOptions] = useState({})
  const [variants, setVariants] = useState({})
  const [stock, setStock] = useState([])
  const [shipping, setShipping] = useState("")
  const [addedproduct, setAddedproduct] = useState({})
  const [minquantity, setMinquantity] = useState({min:"", max:""})
  const [maxquantity, setMaxquantity] = useState("")
  const warehouse = "CN"
  const selected_country_shipfrom = useSelector(state => state.selected_country_shipfrom)
  const selected_country_shipto = useSelector(state => state.selected_country_shipto)
  const selected_shipping_method = useSelector(state => state.selected_shipping_method)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const data = async () => {

        await fetch("http://localhost:3001/bangoodproductinfo", {
          method:"POST",
          body: JSON.stringify({token:token.access_token, product_id:product_id})
        }).then(response => response.json()).then(response => setProductInfo(response))

      // await fetch("https://api.banggood.com/getAccessToken?app_id=bg614e8e254eca7&app_secret=5e76df83d25c08505022011c3d0f75f9", {headers:{"access-control-allow-origin" : "*"}}).then(response => response.json()).then(response => console.log(response))
    }

    data()
  }, [])

// useEffect(() => {
//   fetch("http://localhost:3001/shipping", {
//     method:"POST",
//     body: JSON.stringify({token:token.access_token, product_id:product_id, warehouse:selected_country_shipfrom, country:selected_country_shipto})
//   }).then(response => response.json()).then(response => {
//     response.shipmethod_list.map(item => {
//       if(item.shipmethod_name === selected_shipping_method){
//         setShipping(item.shipfee)
//       }
//     })
//   })
// }, [])

  useEffect(() => {
    let prices = []
    if(productinfo.warehouse_list){
      productinfo.warehouse_list.map(item => {
        prices.push({warehouse:item.warehouse, price:item.warehouse_price})
      })
    }
    console.log(prices)
    prices.map(element => {
      if (element.warehouse === selected_country_shipfrom){
        setCost(element.price)
      }
    })
  }, [productinfo])

  useEffect(() => {

    fetch("http://localhost:3001/getstock", {
      method:"POST",
      body: JSON.stringify({token:token.access_token, product_id:product_id})
    }).then(response => response.json()).then(response => setStock(response.stocks))


  },[product_id, token])

  useEffect(() => {
    let obj = {}
    let values = []

    if(productinfo.poa_list && productinfo.poa_list.length !== 0){
      obj.name = productinfo.poa_list[0].option_name
      productinfo.poa_list[0].option_values.map(value => {
        values.push(value.poa_name)
      })
    }

    obj.values = values
    setOptions(obj)
  },[productinfo])

  useEffect(() => {
    let values = []
    if(productinfo.poa_list && productinfo.poa_list.length !== 0){
      let name = productinfo.poa_list[0].option_name
      productinfo.poa_list[0].option_values.map(value => {
        let image = (value.small_image)?(value.small_image.slice(60,96)):""
        let quantity = ""

        if(stock.length !== 0 && stock[0].stock_list){
          stock[0].stock_list.map(item => {
            if(item.poa_id === value.poa_id){
              console.log(item.stock)
              quantity = String(item.stock)
            }
          })
        }


        values.push({option1:value.poa_name, price:(parseFloat(cost) + parseFloat(value.poa_price.slice(1))), inventory_quantity:quantity, sku:(product_id + "_"+ name),inventory_management:"shopify", image_id:""})
      })
    }
    setVariants(values)
  },[productinfo, stock, cost])



console.log("options", options)
console.log("variants", variants)
console.log("cost", cost)
console.log("stocks", stock)
console.log("shipping", shipping)

  const handleExport = async (event) => {
    let images = []


    Object.values(productinfo.image_list.large).map(item => {
      images.push({src:item})
    })

    console.log("images", images)

    productinfo.poa_list[0].option_values.map(item => {
      if(item.large_image){
        images.push({src:item.large_image, alt:item.poa_name})
      }
    })

    console.log(variants)
    await fetch("http://localhost:3001/addproduct", {
      method:"POST",
      body: JSON.stringify({product:{title:productinfo.product_name, body_html:productinfo.description, id:product.product_id, images:images,status:"draft", options:options, variants:variants}})
    }).then(response => response.json()).then(response => setAddedproduct(response))

  }

useEffect(() => {

let variantimages = []
if(addedproduct.images){
  addedproduct.images.map(item => {
    if(item.alt !== null){
      variantimages.push({option:item.alt, image:item.id})
    }
  })
}

  if(addedproduct.variants){
    addedproduct.variants.map(variant => {
      variantimages.map(item => {
        if(item.option === variant.title){
          fetch("http://localhost:3001/updatevariant", {
            method:"POST",
            body: JSON.stringify({id:variant.id, params:{image_id:item.image}})
          }).then(response => response.json()).then(response => console.log(response))
        }
      })

    })

  }

}, [addedproduct])

function convertToPlain(html){

    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || "";
}

console.log(productinfo)
console.log(product)
console.log(addedproduct)

useEffect(() => {
  const quantities = []
  stock.map(item => {
    item.stock_list.map(element => {
      quantities.push(element.stock)
    })
  })
  console.log("quantities", Math.min(...quantities), Math.max(...quantities))
  if(quantities.length !== 0){
    setMinquantity({min:Math.min(...quantities), max:Math.max(...quantities)})
  }
},[stock])

  return (
    <div>
    <div style={{display:"grid", gridTemplateColumns:"3% 7% 30% 10% 12% 10% 10% 10% 5%", gridGap:"5px"}}>
    <div style={{textAlign:"center", marginTop:"30%"}}>
    <input type="checkbox" id="horns" name="horns"/>
    </div>
    <div><img src={Object.values(product.image_list.home)[0]} width="60" height="60"/></div>
    <div>
    <a target="_blank" href={`https://www.banggood.com/search/${product.product_id}.html?from=nav&ab_version=2`}><p>{product.product_name}</p></a>
    <p>{product.product_id}</p>
    </div>
    <div style={{textAlign:"center"}}>USD {cost}</div>
    <div style={{}}>
      <div>USD {shipping}</div>
      <div style={{fontSize:"12px"}}>{selected_shipping_method}</div>
      <div>Warehouse:{selected_country_shipfrom}</div>
    </div>
    <div style={{textAlign:"center"}}>{product.poa_list.length}</div>
    <div style={{textAlign:"center"}}>Bangood</div>
      <div style={{textAlign:"center"}}>
        <p>{minquantity.min} - {minquantity.max}</p>
      </div>

      <div onClick={(event) => setOpen(true)} style={{cursor:"pointer"}}><EditIcon/></div>
    </div>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
         style={{marginTop:"15%", marginLeft:"20%", marginRight:"20%"}}
      >
    <Card >
    <div style={{marginLeft:"2%", marginRight:"3%", marginTop:"3%"}}>
    <div style={{display:"grid", gridTemplateColumns:"50% 50%", gridGap:"2%"}}>
    <label for="title">Product title</label>
    <textarea value={productinfo.product_name} id="title" rows="3" cols="10" wrap="hard"/>
     </div>
     <div style={{display:"grid", gridTemplateColumns:"50% 50%", marginTop:"2%", gridGap:"2%"}}>
     <label for="desc">Product description</label>
     <textarea value={convertToPlain(productinfo.description)} name="text"  rows="10" cols="10" > </textarea>
      </div>
      <div style={{display:"grid", gridTemplateColumns:"15% 35% 15% 35%", marginTop:"2%", gridGap:"1%"}}>
      <label for="price">Product Price</label>
      <div style={{width:"20%"}}>
      <input type="text" id="price" name="name" width="10%"/>
      </div>
       <label for="quantity">Product Quantity</label>
       <div style={{width:"10%"}}>
       <input type="number" id="quantity" name="name" required
        min="0" max="999" size="10"/>
        </div>
       </div>

       <div style={{display:"grid", gridTemplateColumns:"15% 35% 15% 35%", marginTop:"2%", gridGap:"1%"}}>
       <label for="SKU">Product SKU</label>
       <div style={{width:"20%"}}>
       <input value={product.product_id} type="text" id="SKU" name="name" width="10%"/>
       </div>
        <label for="quantity">Product Tag</label>
        <div style={{width:"25%"}}>
        <input type="text" id="quantity" name="name" required
         minlength="10" maxlength="10" size="10"/>
         </div>
        </div>

        <div style={{display:"grid", gridTemplateColumns:"15% 35% 15% 35%", marginTop:"2%", gridGap:"1%"}}>
        <label for="weight">Product Weight</label>
        <div style={{width:"20%"}}>
        <input value={productinfo.weight}type="number" id="weight" name="name" width="10%" min="0" max="999"/>
        </div>
        <Button style={{marginBottom:"6%"}} variant="contained" color="success" onClick={(event) => handleExport(event)}>IMPORT TO STORE</Button>
        </div>
      </div>
      </Card>
      </Modal>
      </div>
    )
}

export default ProductInfo;
