import React, { useState, useEffect } from "react";

import {
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";

export default function DropshipzoneCarriers({
  dropshipzoneCarrier,
  setDropshipzoneCarrier,
  order,
}) {
  const [product, setProduct] = useState();

  useEffect(() => {
    console.log("called-------------->> shivam")
    fetch(`https://api.saleprodev.com/getdropshipproductsbysku`, {
      method: "post",
      body: JSON.stringify({
        sku: JSON.parse(order.line_items)[0].sku,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log("called-------------->> shivam2")
        const product = res.product;
        if(!(product &&
          product.length !== 0 &&
          product[0].vendor_id === "dropship" &&
          product[0].zone_rates &&
          product[0].zone_rates.nz &&
          product[0].zone_rates.nz !== "999" &&
          product[0].zone_rates.nz !== "9999"))
          {
            setDropshipzoneCarrier('ams')
          }else{
            setDropshipzoneCarrier('dropshipzone_shipping')
          }
        setProduct(res.product)});

    // if (order && order.initial_carrier_name && order.initial_carrier_name !== null) {
    //   setDropshipzoneCarrier(order.initial_carrier_name)
    // }
  }, []);

  const handleChange = (event) => {
    setDropshipzoneCarrier(event.target.value);
    console.log("new carrier", event.target.value)
  };

  return (
    <Grid key={order.orderid} container flexDirection="column" py={1}>
      <FormControl sx={{ my: 1, minWidth: 150 }}>
        <InputLabel id="demo-select-small">Choose Carrier</InputLabel>
        <Select
          labelId="demo-select-small"
          id="demo-select-small"
          value={dropshipzoneCarrier}
          label="Choose Carrierrrrr"
          onChange={handleChange}
          size="small"
          sx={{
            height: 30,
            fontSize: "0.8rem",
          }}
        >
          <MenuItem value="ams">
            <Grid container justifyContent="space-between">
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                  AMS
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>

          <MenuItem value="AUSFF">
            <Grid container justifyContent="space-between">
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                  AUSFF
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>

          {product &&
            product.length !== 0 &&
            product[0].vendor_id === "dropship" &&
            product[0].zone_rates &&
            product[0].zone_rates.nz &&
            product[0].zone_rates.nz !== "999" &&
            product[0].zone_rates.nz !== "9999" && (
              <MenuItem value="dropshipzone_shipping">
                <Grid container justifyContent="space-between">
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                      Dropshipzone Shipping
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            )}
        </Select>
      </FormControl>
    </Grid>
  );
}
