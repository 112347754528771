import React, { useState, useEffect } from "react";
import moment from "moment";
import toast from "react-hot-toast";
import { useTheme } from "@mui/material/styles";

import InputLabel from "@mui/material/InputLabel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import no_image from "../assets/images/no_image.jpg";
import { useSelector, useDispatch } from "react-redux";
import ControlledCheckbox from "./checkbox.js";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";

import { Loader } from "../common/Loader";
import { SupplierOrderNumber } from "./SupplierOrderNumber.js";
import MapAliexpressVariantModal from "./MapAliexpressVariantModal.js";
import FulfillModal from "./FulfillModal.js";
import AutofulfillModal from "./AutofulfillModal.js";
import CancelModal from "./cancelmodal.js";
import EditIcon from "@mui/icons-material/Edit";
import AliexpressCarriers from "./carriers.js";
import VidaxlCarriers from "./VidaxlCarriers.js";
import DropshipzoneCarriers from "./DropshipzoneCarriers.js";
import BigbuyCarriers from "./BigbuyCarriers.js";
import MapSkuModal from "./mapskumodal.js";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import PaidIcon from "@mui/icons-material/Paid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ContentCopy from "@mui/icons-material/ContentCopy";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CreditScoreIcon from "@mui/icons-material/CreditScore";

import Dropshipzonelogo from "../assets/images/dropshipzonelogo.png";
import IDropshipLogo from "../assets/images/idropshiplogo.png";
import BigbuyLogo from "../assets/images/bigbuylogo.png";
import FragranceXlogo from "../assets/images/fragrancexlogo.png";
import WeDropshipLogo from "../assets/images/wedropshiplogo.png";
import BangoodLogo from "../assets/images/bangoodlogo.png";
import WeFullFillLogo from "../assets/images/wefullfill.png";
import DropSiteLogo from "../assets/images/dropsite.jpg";
import VidaXLLogo from "../assets/images/vidaxl.jpg";
import MatterHornLogo from "../assets/images/matterhorn.jpg";
import SogaLogo from "../assets/images/soga.png";
import AliExpressIcon from "../assets/icons/aliexpress_icon.png";
import ShopifyPlusIcon from "../assets/icons/shopify_plus_favicon.png";
import sunskylogo from "../assets/images/sunsky.png";

import { SmallContainedButton } from "../common/SmallContainedButton";
import { SmallOutlinedTextField } from "../common/SmallOutlinedTextField";

import { setLoadingProducts } from "../../actions";
import serranologo from "../assets/images/serrano_logo_original.png";
// import OrderSkuEditor from "./orderSkuEditor.js";
// import OrderSkuEditorModel from "./orderSkuEditorModel.js";

export default function OrdersLayout({
  index,
  order,
  carrier,
  selectedOrders,
  setSelectedOrders,
  handlePurchase,
  handleSave,
  show_orders,
  fetchorders,
  setOpenFailedEdit,
  handleCancel,
  setCarrier,
  dropshipzoneCarrier,
  setDropshipzoneCarrier,
  vidaxlCarrier,
  setVidaxlCarrier,
  note,
  setNote,
  showAllCustomerDetails,
  count3,
  setCount3,
  indexM,
  setIndexm,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();

  console.log("hsxxxvvv--->> ", order.supplier, order.orderid);

  const [editCustomer, setEditCustomer] = useState(false);
  const [editFirstName, setEditFirstName] = useState(false);
  const [editLastName, setEditLastName] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [editAddress1, setEditAddress1] = useState(false);
  const [editAddress2, setEditAddress2] = useState(false);
  const [editCity, setEditCity] = useState(false);
  const [editProvince, setEditProvince] = useState(false);
  const [editCountry, setEditCountry] = useState(false);
  const [editZip, setEditZip] = useState(false);
  const [editCompany, setEditCompany] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [editOrderNote, setEditOrderNote] = useState(false);
  const [supplierOrderNo, setSupplierOrderNo] = useState("");
  const [editSupplierOrderNo, setEditSupplierOrderNo] = useState(false);
  const [editShopifySku, setEditShopifySku] = useState(false);
  const [editSupplier, setEditSupplier] = useState(false);
  const [shopifysku, setShopifySku] = useState(
    JSON.parse(order.line_items)[0].sku
  );
  const [selectedSupplier, setSelectedSupp] = useState(order.supplier);
  // const [suppliersku, setSupplierSku] = useState("");
  // const [storeName, setStoreName] = useState("");
  const [aliVariantName, setAliVariantName] = useState("");
  const purchasingorder = useSelector((state) => state.purchasingorder);
  const store = useSelector((state) => state.store);
  const selected_supplier = useSelector((state) => state.selected_supplier);

  const formatDate = (date) => moment(date).format("DD/MM/YYYY, HH:mm");
  let isvalid = false;
  if (
    order.supplier === "aliexpress" &&
    (store === "gkshopify" || store === "nzshopify")
  ) {
    isvalid = true;
  }

  console.log(
    "hjdddd_",
    index + " ",
    order.supplier_sku,
    order.supplier,
    order.orderid,
    order.supplier === "manual" && order.orderid.startsWith("GONZ")
  );
  console.log("order.initial_carrier_name", order.initial_carrier_name);

  console.log("orders line_items", JSON.parse(order.line_items));
  console.log("orders fulfillments", JSON.parse(order.fulfillments));

  const [openMapVariantModal, setOpenMapVariantModal] = useState(false);
  const [mappingProducts, setMappingProducts] = useState([]);
  // const [showeditsuppliersku , setshoweditsuppliersku] = useState(false);

  // const handleOpenMapVariantModalForEditSKU = () =>
  // {
  //   setshoweditsuppliersku(true);
  //   dispatch(setLoadingProducts(true));
  // }

  const handleOpenMapVariantModal = () => {
    setOpenMapVariantModal(true);
    dispatch(setLoadingProducts(true));
    getAliexpressMappingProducts();
  };

  console.log("mapping products---->> ");

  const getAliexpressMappingProducts = () => {
    if (store === "nzshopify") {
      fetch("https://aliscript.saleprodev.com/aliexpressmapping", {
        method: "post",
        body: JSON.stringify({
          page: 1,
          products_per_page: 1,
          storeid: "",
          sku: shopifysku,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          // console.log("bhauiii", response.products)
          setMappingProducts(response.products);
          dispatch(setLoadingProducts(false));
        })
        .catch((err) => {});
    }
    if (store === "aushopify") {
      fetch("https://aliscript.saleprodev.com/aliexpressmappingau", {
        method: "post",
        body: JSON.stringify({
          page: 1,
          products_per_page: 1,
          storeid: "",
          sku: shopifysku,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          setMappingProducts(response.products);
          dispatch(setLoadingProducts(false));
        });
    }
    if (store === "usshopify") {
      fetch("https://aliscript.saleprodev.com/aliexpressmappingus", {
        method: "post",
        body: JSON.stringify({
          page: 1,
          products_per_page: 1,
          storeid: "",
          sku: shopifysku,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          setMappingProducts(response.products);
          dispatch(setLoadingProducts(false));
        });
    }

    if (store === "gkshopify") {
      fetch("https://aliscript.saleprodev.com/aliexpressmappinggk", {
        method: "post",
        body: JSON.stringify({
          page: 1,
          products_per_page: 1,
          storeid: "",
          sku: shopifysku,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          setMappingProducts(response.products);
          dispatch(setLoadingProducts(false));
        });
    }
  };

  useEffect(() => {
    getAliexpressMappingProducts();
  }, [shopifysku]);

  const handleCloseMapVariantModal = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMapVariantModal(false);
    // setshoweditsuppliersku(false);
  };

  const handleEditShopifySku = (event, order) => {
    let line_items = [];
    let copy = JSON.parse(order.line_items)[0];
    copy.sku = event.target.value;
    line_items.push(copy);
    if (event.key === "Enter") {
      fetch("https://api.saleprodev.com/updateordernzsku", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          orderid: order.orderid,
          id: order.id,
          params: { line_items: line_items },
          store: store,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          fetchorders();
          setEditShopifySku(false);
        });
    }
  };

  const handleSaveSupplierOrderNo = (event, order) => {
    if (event.key === "Enter") {
      fetch("https://api.saleprodev.com/updateordernzsupplierorderno", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          orderid: order.orderid,
          supplier_order_no: event.target.value,
          store: store,
        }),
      }).then((response) => {
        fetchorders();
        setEditSupplierOrderNo(false);
      });
    }
  };

  const handleCopyText = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  const handleChangeOrderNote = (event, order) => {
    if (event.key === "Enter") {
      fetch("https://api.saleprodev.com/updateordernzordernote", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: order.id,
          order_note: event.target.value,
          store: store,
        }),
      }).then((response) => {
        fetchorders();
        setEditOrderNote(false);
      });
    }
  };

  //Action Dropdown Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickMoreIcon = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseActionMenu = () => {
    setAnchorEl(null);
  };

  const [showCustomerDetails, setShowCustomerDetails] = useState(false);
  useEffect(() => {
    setShowCustomerDetails(showAllCustomerDetails);
  }, [showAllCustomerDetails]);

  const [values, setValues] = useState({
    first_name:
      order.shipping_address &&
      order.shipping_address !== "null" &&
      JSON.parse(order.shipping_address).first_name
        ? JSON.parse(order.shipping_address).first_name
        : null,

    last_name:
      order.shipping_address &&
      order.shipping_address !== "null" &&
      JSON.parse(order.shipping_address).last_name
        ? JSON.parse(order.shipping_address).last_name
        : null,

    name:
      order.shipping_address &&
      order.shipping_address !== "null" &&
      JSON.parse(order.shipping_address).name
        ? JSON.parse(order.shipping_address).name
        : null,

    phone:
      order.shipping_address &&
      order.shipping_address !== "null" &&
      JSON.parse(order.shipping_address).phone
        ? JSON.parse(order.shipping_address).phone
        : null,

    email: order.email ? order.email : null,

    company:
      order.shipping_address &&
      order.shipping_address !== "null" &&
      JSON.parse(order.shipping_address).company
        ? JSON.parse(order.shipping_address).company
        : null,

    order_memo: order.memo ? order.memo : null,

    address1:
      order.shipping_address &&
      order.shipping_address !== "null" &&
      JSON.parse(order.shipping_address).address1
        ? JSON.parse(order.shipping_address).address1
        : null,

    address2:
      order.shipping_address &&
      order.shipping_address !== "null" &&
      JSON.parse(order.shipping_address).address2
        ? JSON.parse(order.shipping_address).address2
        : null,

    province:
      order.shipping_address &&
      order.shipping_address !== "null" &&
      JSON.parse(order.shipping_address).province
        ? JSON.parse(order.shipping_address).province
        : "other",

    province_code:
      order.shipping_address &&
      order.shipping_address !== "null" &&
      JSON.parse(order.shipping_address).province_code
        ? JSON.parse(order.shipping_address).province_code
        : null,

    city:
      order.shipping_address &&
      order.shipping_address !== "null" &&
      JSON.parse(order.shipping_address).city
        ? JSON.parse(order.shipping_address).city
        : null,

    country:
      order.shipping_address &&
      order.shipping_address !== "null" &&
      JSON.parse(order.shipping_address).country
        ? JSON.parse(order.shipping_address).country
        : null,

    country_code:
      order.shipping_address &&
      order.shipping_address !== "null" &&
      JSON.parse(order.shipping_address).country_code
        ? JSON.parse(order.shipping_address).country_code
        : null,

    latitude:
      order.shipping_address &&
      order.shipping_address !== "null" &&
      JSON.parse(order.shipping_address).latitude
        ? JSON.parse(order.shipping_address).latitude
        : null,

    longitude:
      order.shipping_address &&
      order.shipping_address !== "null" &&
      JSON.parse(order.shipping_address).longitude
        ? JSON.parse(order.shipping_address).longitude
        : null,

    zip:
      order.shipping_address &&
      order.shipping_address !== "null" &&
      JSON.parse(order.shipping_address).zip
        ? JSON.parse(order.shipping_address).zip
        : null,
    customer_message: order.note ? order.note : null,
  });

  const handleChangeCustomerDetails = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (isvalid && values.province !== "other") {
      if (values.province !== "Other") {
        console.log(
          "allllllllsfvedfdfdskjfhsjdfhs",
          values.province,
          values.address2
        );
        let newObj = values;
        //  newObj.address2 = newObj.address2 ? newObj.address2.replace('other ','') : null;
        //  newObj.address2 = newObj.address2 ? newObj.address2.replace('Other ','') : null;
        //  newObj.address2 = newObj.address2 ? newObj.address2.replace(' other','') : null;
        //  console.log("allllllllsfvedfdfdskjfhsjdfhs", newObj.address2, newObj.province)

        newObj.address2 = newObj.address2
          ? `${newObj.address2}, ${newObj.province}`
          : newObj.province;
        newObj.province = "Other";
        setValues({ ...newObj });
      }
    }
  }, []);

  const handleSaveCustomerDetails = (event, order) => {
    let shipping_address = {
      first_name: values.first_name,
      last_name: values.last_name,
      name: values.first_name + " " + values.last_name,
      phone: values.phone,
      company: values.company,
      address1: values.address1,
      address2: values.address2,
      province: values.province,
      province_code: values.province_code,
      city: values.city,
      country: values.country,
      country_code: values.country_code,
      latitude: values.latitude,
      longitude: values.longitude,
      zip: values.zip,
    };

    console.log("shipping_address on Save", shipping_address);

    fetch("https://api.saleprodev.com/updateordernz", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: order.id,
        params: { shipping_address: shipping_address, email: values.email },
        store: store,
      }),
    }).then((res) => {
      //To Do: combine these two apis, or separete update memo api call.
      fetch("https://api.saleprodev.com/updateordernzmemo", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: order.id,
          memo: values.order_memo,
          store: store,
        }),
      });

      if (res && res.status === 200) {
        fetchorders();
        toast.success("Customer Details Saved");
      } else {
        toast.error(res.error);
      }
    });
  };

  const handleEditCustomer = (event, order, tag) => {
    if (event.key === "Enter" && tag === "firstname") {
      let shipping_address = JSON.parse(order.shipping_address);
      let last_name = shipping_address.last_name;
      shipping_address.first_name = event.target.value;
      shipping_address.last_name = last_name;
      shipping_address.name =
        shipping_address.first_name + " " + shipping_address.last_name;

      fetch("https://api.saleprodev.com/updateordernz", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: order.id,
          params: { shipping_address: shipping_address, email: order.email },
          store: store,
        }),
      }).then((response) => {
        fetchorders();
        setEditFirstName(false);
      });
    }

    if (event.key === "Enter" && tag === "lastname") {
      let customer = JSON.parse(order.customer);
      let shipping_address = JSON.parse(order.shipping_address);
      let first_name = shipping_address.first_name;
      shipping_address.first_name = first_name;
      shipping_address.last_name = event.target.value;
      shipping_address.name =
        shipping_address.first_name + " " + shipping_address.last_name;

      fetch("https://api.saleprodev.com/updateordernz", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: order.id,
          params: { shipping_address: shipping_address, email: order.email },
          store: store,
        }),
      }).then((response) => {
        fetchorders();
        setEditLastName(false);
      });
    }

    if (event.key === "Enter" && tag === "email") {
      let email = event.target.value;
      fetch("https://api.saleprodev.com/updateordernz", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: order.id,
          params: {
            shipping_address: JSON.parse(order.shipping_address),
            email: email,
          },
          store: store,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          fetchorders();
          setEditEmail(false);
          if (response.status === "failed") {
            setOpenFailedEdit({
              open: true,
              message: "Please Enter a Valid Email",
              order: order,
            });
          }
        });
    }

    if (event.key === "Enter" && tag === "phone") {
      let shipping_address = JSON.parse(order.shipping_address);
      shipping_address.phone = event.target.value;
      fetch("https://api.saleprodev.com/updateordernz", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: order.id,
          params: { shipping_address: shipping_address, email: order.email },
          store: store,
        }),
      }).then((response) => {
        fetchorders();
        setEditPhone(false);
      });
    }

    if (event.key === "Enter" && tag === "address1") {
      let shipping_address = JSON.parse(order.shipping_address);
      shipping_address.address1 = event.target.value;

      fetch("https://api.saleprodev.com/updateordernz", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: order.id,
          params: { shipping_address: shipping_address, email: order.email },
          store: store,
        }),
      }).then((response) => {
        fetchorders();
        setEditAddress1(false);
      });
    }

    if (event.key === "Enter" && tag === "address2") {
      let shipping_address = JSON.parse(order.shipping_address);
      shipping_address.address2 = event.target.value;

      fetch("https://api.saleprodev.com/updateordernz", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: order.id,
          params: { shipping_address: shipping_address, email: order.email },
          store: store,
        }),
      }).then((response) => {
        fetchorders();
        setEditAddress2(false);
      });
    }

    if (event.key === "Enter" && tag === "province") {
      let shipping_address = JSON.parse(order.shipping_address);
      shipping_address.province = event.target.value;

      fetch("https://api.saleprodev.com/updateordernz", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: order.id,
          params: { shipping_address: shipping_address, email: order.email },
          store: store,
        }),
      }).then((response) => {
        fetchorders();
        setEditProvince(false);
      });
    }

    if (event.key === "Enter" && tag === "zip") {
      let shipping_address = JSON.parse(order.shipping_address);
      shipping_address.zip = event.target.value;

      fetch("https://api.saleprodev.com/updateordernz", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: order.id,
          params: { shipping_address: shipping_address, email: order.email },
          store: store,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          fetchorders();
          setEditZip(false);
          if (response.status === "failed") {
            setOpenFailedEdit({
              open: true,
              message: "Please Enter a valid Zip Code",
              order: order,
            });
          }
        });
    }

    if (event.key === "Enter" && tag === "city") {
      let shipping_address = JSON.parse(order.shipping_address);
      shipping_address.city = event.target.value;

      fetch("https://api.saleprodev.com/updateordernz", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: order.id,
          params: { shipping_address: shipping_address, email: order.email },
          store: store,
        }),
      }).then((response) => {
        fetchorders();
        setEditCity(false);
      });
    }
    if (event.key === "Enter" && tag === "company") {
      let shipping_address = JSON.parse(order.shipping_address);
      shipping_address.company = event.target.value;

      fetch("https://api.saleprodev.com/updateordernz", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: order.id,
          params: { shipping_address: shipping_address, email: order.email },
          store: store,
        }),
      }).then((response) => {
        fetchorders();
        setEditCompany(false);
      });
    }
  };

  const suppliers = [
    "select",
    "bangood",
    "aliexpress",
    "wefullfill",
    "wefullfillau",
    "dropsite",
    "soga",
    "vidaxl",
    "dropship",
    "matterhorn",
    "fragrancex",
    "wedropship",
    "idropship",
    "bigbuy",
    "ingrammicro",
    "ibacrafts",
    "griffati",
    "serrano",
    "sunsky"
  ];

  const handleChangeSupplier = (event, order) => {
    console.log("here is the -->", event.target.value);
    fetch("https://api.saleprodev.com/updateordernzsupplier", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: order.id,
        supplier: event.target.value === "select" ? null : event.target.value,
        orderid: order.orderid,
        store: store,
      }),
      //When changing supplier, supplier of different Orders with SAME order.id change at the same time.
    }).then((response) => {
      setSelectedSupp(event.target.value);
      fetchorders();
      // setEditSupplier(false);
    });
  };

  let supplier_sku_url = "";
  let shopify_sku_url = "";
  let supplier_order_url = "";

  if (order) {
    if (store === "nzshopify") {
      shopify_sku_url = `https://go-slash.myshopify.com/admin/products/${
        JSON.parse(order.line_items)[0].product_id
      }/variants/${JSON.parse(order.line_items)[0].variant_id}`;
    }

    if (store === "aushopify") {
      shopify_sku_url = `https://goslash-au.myshopify.com/admin/products/${
        JSON.parse(order.line_items)[0].product_id
      }/variants/${JSON.parse(order.line_items)[0].variant_id}`;
    }

    if (store === "usshopify") {
      shopify_sku_url = `https://goslash-us.myshopify.com/admin/products/${
        JSON.parse(order.line_items)[0].product_id
      }/variants/${JSON.parse(order.line_items)[0].variant_id}`;
    }

    if (store === "gkshopify") {
      shopify_sku_url = `https://gokinzo.myshopify.com/admin/products/${
        JSON.parse(order.line_items)[0].product_id
      }/variants/${JSON.parse(order.line_items)[0].variant_id}`;
    }

    if (order.supplier && order.supplier === "bangood") {
      supplier_sku_url = `https://banggood.com/search/${order.supplier_sku}.html`;
      supplier_order_url = `https://banggood.com/index.php?com=account&t=ordersDetail&ordersId=${order.supplier_order_no}`;
    }

    if (order.supplier && order.supplier === "aliexpress") {
      supplier_sku_url = `https://www.aliexpress.com/item/${order.supplier_sku}.html`;
      supplier_order_url = `https://trade.aliexpress.com/order_detail.htm?spm=a2g0o.9042311.0.0.ba2b4c4dWL2D1w&orderId=${order.supplier_order_no}`;
    }

    if (order.supplier && order.supplier === "wefullfill") {
      supplier_sku_url = `https://app.wefullfill.com/users/products/wefullfill/${
        JSON.parse(order.line_items)[0].product_id
      }`;
      supplier_order_url = `https://app.wefullfill.com/users/custom-orders?search=${order.supplier_order_no}`;
    }

    if (order.supplier && order.supplier === "dropsite") {
      supplier_sku_url = "https://dropsite.com.au/";
    }

    if (order.supplier && order.supplier === "soga") {
      supplier_sku_url = `https://www.sogainternational.com/search?q=${order.supplier_sku}`;
    }

    if (order.supplier && order.supplier === "vidaxl") {
      supplier_sku_url = `https://www.vidaxl.com.au/catalogsearch/result?q=${order.supplier_sku}`;
      supplier_order_url = `https://b2b.vidaxl.com/orders/view?id=${order.supplier_order_no}`;
    }

    if (order.supplier && order.supplier === "dropship") {
      supplier_sku_url = `https://www.dropshipzone.com.au/catalogsearch/result/?q=${order.supplier_sku}`;
      supplier_order_url = `https://www.dropshipzone.com.au/sales/order/view/order_id/${order.supplier_order_no}`;
    }

    if (order.supplier && order.supplier === "matterhorn") {
      supplier_sku_url = "https://matterhorn-wholesale.com//";
    }

    if (order.supplier && order.supplier === "fragrancex") {
      supplier_sku_url = ` https://www.fragrancex.com/products/${
        JSON.parse(order.line_items)[0].sku
      }`;
      supplier_order_url = `https://www.fragrancex.com/orders/confirmation.html?oid=${order.supplier_order_no}&cid=dGJtZnRBdGJtZnRxc3Avb2Z1`;
    }

    if (order.supplier && order.supplier === "wedropship") {
      supplier_sku_url = "https://www.wedropship.com.au/";
    }

    if (order.supplier && order.supplier === "idropship") {
      supplier_sku_url = `https://idropship.com.au/catalogsearch/result/?q=${order.supplier_sku}`;
    }

    if (order.supplier && order.supplier === "bigbuy") {
      //Bigbuy doesn't have node js api endpoint
      supplier_sku_url = `https://www.bigbuy.eu/search/result?q=${order.supplier_sku}`;
      supplier_order_url = `https://controlpanel.bigbuy.eu/orders/view/${order.supplier_order_no}`;
    }

    if (order.supplier && order.supplier === "ingrammicro") {
      supplier_sku_url = "https://nz.ingrammicro.com/Site/home";
    }

    if (order.supplier && order.supplier === "ibacrafts") {
      supplier_sku_url = "https://ibacrafts.com/";
    }
  }

  const handleBulkEdit = (event) => {
    if (
      editCustomer ||
      editEmail ||
      editPhone ||
      editAddress1 ||
      editAddress2 ||
      editCity ||
      editZip ||
      editFirstName ||
      editLastName ||
      editCompany ||
      editNote ||
      editSupplierOrderNo ||
      editShopifySku ||
      editSupplier ||
      editOrderNote
    ) {
      setEditCustomer(false);
      setEditFirstName(false);
      setEditLastName(false);
      setEditEmail(false);
      setEditPhone(false);
      setEditAddress1(false);
      setEditAddress2(false);
      setEditCity(false);
      setEditZip(false);
      setEditCompany(false);
      setEditProvince(false);
      setEditNote(false);
      setEditShopifySku(false);
      setEditSupplier(false);
      setEditSupplierOrderNo(false);
      setEditOrderNote(false);
    } else {
      setEditCustomer(true);
      setEditFirstName(true);
      setEditLastName(true);
      setEditEmail(true);
      setEditPhone(true);
      setEditAddress1(true);
      setEditAddress2(true);
      setEditCity(true);
      setEditZip(true);
      setEditCompany(true);
      setEditProvince(true);
      setEditNote(true);
      setEditShopifySku(true);
      setEditSupplier(true);
      setEditSupplierOrderNo(true);
      setEditOrderNote(true);
    }
  };

  // console.log("mappingProducts", mappingProducts[0]);
  // console.log("order.ali_sku", order.ali_sku);

  let sku_image_url_string = "";
  if (mappingProducts && mappingProducts.length !== 0) {
    if (mappingProducts[0].ali_info.result.aeop_ae_product_s_k_us) {
      let variantArray =
        mappingProducts[0].ali_info.result.aeop_ae_product_s_k_us
          .aeop_ae_product_sku;
      let mappedVariant = variantArray.filter(
        (item) => item.id === order.ali_sku
      );
      // console.log("mappedVariant", mappedVariant[0]);

      if (
        mappedVariant[0] &&
        mappedVariant[0].ae_sku_property_dtos &&
        mappedVariant[0].ae_sku_property_dtos !== undefined
      ) {
        mappedVariant[0].ae_sku_property_dtos.ae_sku_property_d_t_o.map(
          (prop) => {
            if (prop.sku_image !== undefined) {
              // console.log("sku_image", prop.sku_image)
              sku_image_url_string = prop.sku_image;
            }
          }
        );
      }
    }
  }
  return (
    <Card id={`${order.orderid}_card`} sx={{ width: "100%", mt: 1 }}>
      <Grid
        container
        sx={{
          height: "36px",
          backgroundColor: "bg.grayBG",
          display: "Grid",
          gridTemplateColumns: "2% 8% 8% 7% 10% 10% 22% 10% 13% 8.5% 1.5%",
          alignItems: "center",
        }}
      >
        <Grid container justifyContent="center">
          <ControlledCheckbox
            selectedOrders={selectedOrders}
            setSelectedOrders={setSelectedOrders}
            order={order}
          />
        </Grid>

        <Grid container alignItems="center">
          <img
            src={ShopifyPlusIcon}
            style={{ width: "22px", marginRight: "3px" }}
            alt="Shopify Plus Logo"
          />

          {order.supplier === "" && order.orderid.startsWith("GONZ") ? (
            <a
              href={`https://go-slash.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}

          {order.supplier === "" &&
          order.orderid.startsWith("PD") &&
          store === "aushopify" ? (
            <a
              href={`https://goslash-au.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}

          {order.orderid.startsWith("GKZ") ? (
            <a
              href={`https://gokinzo.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}

          {order.orderid.startsWith("PDINT") && store === "usshopify" ? (
            <a
              href={`https://admin.shopify.com/store/paralleldeals/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}

          {order.supplier === "aliexpress" &&
          order.orderid.startsWith("GONZ") ? (
            <a
              href={`https://go-slash.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}

          {(order.supplier === "Goslash-DIOW" || order.supplier === "manual") &&
          order.orderid.startsWith("GONZ") ? (
            <a
              href={`https://go-slash.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}

          {order.supplier === "aliexpress" &&
          order.orderid.startsWith("PD") &&
          store === "aushopify" ? (
            <a
              href={`https://goslash-au.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}

          {order.supplier === "serrano" &&
          order.orderid.startsWith("GONZ") &&
          store === "nzshopify" ? (
            <a
              href={`https://goslash-au.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}

          {order.supplier === "serrano" &&
          order.orderid.startsWith("PD") &&
          store === "aushopify" ? (
            <a
              href={`https://goslash-au.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}

          {(order.supplier === "vidaxl" || order.supplier === "sunsky") &&
            order.orderid.startsWith("GONZ") && (
              <a
                href={`https://go-slash.myshopify.com/admin/orders/${order.id}`}
                target="_blank"
              >
                <Typography variant="body2">{order.orderid}</Typography>
              </a>
            )}

          {order.supplier === "vidaxl" && order.orderid.startsWith("PD") ? (
            <a
              href={`https://goslash-au.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}
          {order.supplier === "matterhorn" &&
          order.orderid.startsWith("GONZ") ? (
            <a
              href={`https://go-slash.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}

          {order.supplier === "matterhorn" && order.orderid.startsWith("PD") ? (
            <a
              href={`https://goslash-au.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}

          {order.supplier === "dropship" && order.orderid.startsWith("GONZ") ? (
            <a
              href={`https://go-slash.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}
          {order.supplier === "dropship" && order.orderid.startsWith("PD") ? (
            <a
              href={`https://goslash-au.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}

          {order.supplier === "dove" ||
          (order.supplier === null && order.orderid.startsWith("GONZ")) ? (
            <a
              href={`https://go-slash.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}
          {order.supplier === "dove" ||
          (order.supplier === null && order.orderid.startsWith("PD")) ? (
            <a
              href={`https://goslash-au.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}
          {order.supplier === "fragrancex" &&
          order.orderid.startsWith("GONZ") ? (
            <a
              href={`https://go-slash.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}
          {order.supplier === "fragrancex" && order.orderid.startsWith("PD") ? (
            <a
              href={`https://goslash-au.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}
          {order.supplier === "bangood" && order.orderid.startsWith("GONZ") ? (
            <a
              href={`https://go-slash.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}
          {order.supplier === "bangood" && order.orderid.startsWith("PD") ? (
            <a
              href={`https://goslash-au.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}
          {order.supplier === "wefullfill" &&
          order.orderid.startsWith("GONZ") ? (
            <a
              href={`https://go-slash.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}
          {order.supplier === "wefullfill" && order.orderid.startsWith("PD") ? (
            <a
              href={`https://goslash-au.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}
          {order.supplier === "dropsite" && order.orderid.startsWith("GONZ") ? (
            <a
              href={`https://go-slash.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}
          {order.supplier === "dropsite" && order.orderid.startsWith("PD") ? (
            <a
              href={`https://goslash-au.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}
          {order.supplier === "soga" && order.orderid.startsWith("GONZ") ? (
            <a
              href={`https://go-slash.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}
          {order.supplier === "soga" && order.orderid.startsWith("PD") ? (
            <a
              href={`https://goslash-au.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}
          {order.supplier === "wedropship" &&
          order.orderid.startsWith("GONZ") ? (
            <a
              href={`https://go-slash.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}
          {order.supplier === "wedropship" && order.orderid.startsWith("PD") ? (
            <a
              href={`https://goslash-au.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}

          {order.supplier === "idropship" &&
          order.orderid.startsWith("GONZ") ? (
            <a
              href={`https://go-slash.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}

          {order.supplier === "idropship" && order.orderid.startsWith("PD") ? (
            <a
              href={`https://goslash-au.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}

          {order.supplier === "bigbuy" && order.orderid.startsWith("GONZ") ? (
            <a
              href={`https://go-slash.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}

          {order.supplier === "bigbuy" && order.orderid.startsWith("PD") ? (
            <a
              href={`https://goslash-au.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}
          {order.supplier === "sunsky" && order.orderid.startsWith("PD") ? (
            <a
              href={`https://goslash-au.myshopify.com/admin/orders/${order.id}`}
              target="_blank"
            >
              <Typography variant="body2">{order.orderid}</Typography>
            </a>
          ) : (
            ""
          )}
        </Grid>

        <Grid container alignItems="center">
          <Typography variant="body2">
            {show_orders === "" && formatDate(order.receivedAt)}
            {show_orders === null && formatDate(order.receivedAt)}
            {show_orders === "purchased" && formatDate(order.processed_at)}
            {show_orders === "fulfilled" && formatDate(order.fulfill_date)}
            {show_orders === "refunded" && formatDate(order.cancelled_at)}
          </Typography>
        </Grid>

        <Grid container alignItems="center">
          <Tooltip
            arrow
            placement="top"
            title={
              order.financial_status === "refunded"
                ? "Order Cancelled"
                : "Order Received"
            }
          >
            <PaidIcon
              color={
                order.financial_status === "refunded" ? "error" : "success"
              }
              sx={{ width: "18px", height: "18px" }}
            />
          </Tooltip>

          <Tooltip
            arrow
            placement="top"
            title={
              order.purchase_status === "purchased" ||
              order.purchase_status === "fulfilled"
                ? "Order Sent"
                : "Order Not Sent"
            }
          >
            <CheckCircleIcon
              sx={{ width: "18px", height: "18px" }}
              color={
                order.purchase_status === "purchased" ||
                order.purchase_status === "fulfilled"
                  ? "success"
                  : "disabled"
              }
            />
          </Tooltip>

          {order.purchase_status === "refunded" ? (
            <Tooltip arrow placement="top" title="Order Refunded">
              <CreditScoreIcon
                color="error"
                sx={{ width: "18px", height: "18px" }}
              />
            </Tooltip>
          ) : (
            <>
              {order.purchase_status === null && (
                <Tooltip arrow placement="top" title="Payment Status">
                  <CreditScoreIcon
                    color="disabled"
                    sx={{ width: "18px", height: "18px" }}
                  />
                </Tooltip>
              )}
              {order.purchase_status === "purchased" ||
              order.purchase_status === "fulfilled" ? (
                <Tooltip arrow placement="top" title="Order Paid">
                  <CreditScoreIcon
                    color="success"
                    sx={{ width: "18px", height: "18px" }}
                  />
                </Tooltip>
              ) : null}
            </>
          )}

          {order.supplier === "aliexpress" && order.actual_cost === null && (
            <Tooltip arrow placement="top" title="Profit / Loss">
              <ThumbUpAltIcon
                color="disabled"
                sx={{ width: "18px", height: "18px" }}
              />
            </Tooltip>
          )}

          {order.supplier === "aliexpress" && order.actual_cost !== null && (
            <>
              {parseFloat(JSON.parse(order.line_items)[0].price) *
                JSON.parse(order.line_items)[0].quantity >
              parseFloat(order.actual_cost) ? (
                <Tooltip arrow placement="top" title="Profit">
                  <ThumbUpAltIcon
                    color="success"
                    sx={{ width: "18px", height: "18px" }}
                  />
                </Tooltip>
              ) : (
                <Tooltip arrow placement="top" title="Loss">
                  <ThumbUpAltIcon
                    color="error"
                    sx={{ width: "18px", height: "18px" }}
                  />
                </Tooltip>
              )}
            </>
          )}

          <Tooltip
            arrow
            placement="top"
            title={
              JSON.parse(order.fulfillments).length === 0
                ? "Order Not Shipped"
                : "Order Shipped"
            }
          >
            <LocalShippingIcon
              sx={{ width: "18px", height: "18px" }}
              color={
                JSON.parse(order.fulfillments).length === 0
                  ? "disabled"
                  : "success"
              }
            />
          </Tooltip>
        </Grid>
        {console.log(
          "tjis s the iiiiidshddhddjj shhhhhhhhhh--->> ",
          order.tags,
          order.tags.toLowerCase()
        )}
        <Grid container alignItems="center">
          <Grid
            item
            sx={{
              px: 1,
              borderRadius: "4px",
              backgroundColor: "disabled",
            }}
          >
            <Typography variant="body2">
              {store === "nzshopify" && (
                <>
                  {order.tags === "" && "Goslash NZ"}
                  {order.tags === "trademe" && "Trade Me"}
                  {order.tags === "Kogan NZ" && "Kogan NZ"}
                  {order.tags.toLowerCase().includes("kogan") && "Kogan NZ"}
                  {order.tags.includes("Onceit") && "Onceit"}
                  {order.tags.includes("TheMarket") && "TheMarket"}
                  {order.tags.includes("MySale") && "MySale NZ"}
                </>
              )}

              {store === "aushopify" && (
                <>
                  {order.tags === "" && "Goslash AU"}
                  {order.tags.includes("Kogan") && "Kogan AU"}
                  {order.tags.includes("eBay") && "eBay"}
                  {order.tags.includes("Catch") && "Catch"}
                  {order.tags.includes("MySale") && "MySale AU"}
                  {order.tags.includes("mydeal") && "myDeal"}
                </>
              )}

              {store === "usshopify" && <>{order.tags === "" && ""}</>}

              {store === "gkshopify" && (
                <>
                  {order.tags.includes("TradeMe") && "Trade Me"}
                  {order.tags.includes("TheMarket") && "The Market"}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>

        <Grid container alignItems="center">
          {order.supplier === "bangood" && (
            <img src={BangoodLogo} alt="Banggood Logo" />
          )}

          {order.supplier === "aliexpress" && (
            <>
              <img
                src={AliExpressIcon}
                alt="AliExpress Icon"
                style={{ width: "16px" }}
              />
              {order.store_name &&
                order.store_name !== "" &&
                order.store_name !== null && (
                  <Tooltip arrow placement="top" title={order.store_name}>
                    <a
                      href={`https://www.aliexpress.com/store/${order.store_id}`}
                      target="_blank"
                    >
                      <Typography variant="body2" color="error" mx={0.5}>
                        {order.store_name.length < 16
                          ? order.store_name
                          : order.store_name.slice(0, 15) + "..."}
                      </Typography>
                    </a>
                  </Tooltip>
                )}
            </>
          )}

          {order.supplier === "wefullfill" && (
            <img
              src={WeFullFillLogo}
              alt="Wefullfill Logo"
              style={{ height: "24px" }}
            />
          )}

          {order.supplier === "dropsite" && (
            <img
              src={DropSiteLogo}
              alt="DropSite Logo"
              style={{ height: "26px" }}
            />
          )}

          {order.supplier === "soga" && (
            <Grid container py={0.5}>
              <img src={SogaLogo} alt="Soga Logo" style={{ height: "24px" }} />
            </Grid>
          )}

          {order.supplier === "vidaxl" && (
            <img
              src={VidaXLLogo}
              alt="Vidaxl Logo"
              style={{ height: "26px" }}
            />
          )}

          {order.supplier === "dropship" && (
            <img src={Dropshipzonelogo} alt="Dropshipzone Logo" />
          )}

          {order.supplier === "matterhorn" && (
            <img
              src={MatterHornLogo}
              alt="Matterhorn Logo"
              style={{ height: "26px" }}
            />
          )}

          {order.supplier === "fragrancex" && (
            <img src={FragranceXlogo} alt="Fragrancex Logo" />
          )}

          {order.supplier === "wedropship" && (
            <img
              src={WeDropshipLogo}
              alt="wedropship Logo"
              style={{ height: "28px" }}
            />
          )}

          {order.supplier === "idropship" && (
            <img
              src={IDropshipLogo}
              alt="iDropship Logo"
              style={{ height: "24px" }}
            />
          )}

          {order.supplier === "bigbuy" && (
            <img
              src={BigbuyLogo}
              alt="Bigbuy Logo"
              style={{ height: "24px" }}
            />
          )}

          {order.supplier === "sunsky" && (
            <img
              src={sunskylogo}
              alt="SunSky Logo"
              style={{ height: "18px" }}
            />
          )}

          {order.supplier === "serrano" && (
            <img
              src={serranologo}
              alt="serrano Logo"
              style={{ height: "20px" }}
            />
          )}
        </Grid>

        <SupplierOrderNumber
          order={order}
          supplier_order_no={order.supplier_order_no}
          supplier_order_link={supplier_order_url}
          editSupplierOrderNo={editSupplierOrderNo}
          setEditSupplierOrderNo={setEditSupplierOrderNo}
          handleSaveSupplierOrderNo={handleSaveSupplierOrderNo}
          handleCopyText={handleCopyText}
        />

        <Grid container alignItems="center">
          <Grid
            item
            sx={{
              px: 1,
              borderRadius: "4px",
              backgroundColor: "disabled",
            }}
          >
            <Typography variant="body2" noWrap>
              {show_orders === null && "To Purchase"}
              {show_orders === "purchased" && "Purchased"}
              {show_orders === "fulfilled" && "Shipped"}
            </Typography>
          </Grid>
        </Grid>

        {(order.purchase_status === null ||
          order.purchase_status === "refunded") && (
          <Grid container alignItems="center" pl={0.6}>
            <Typography variant="body2" noWrap></Typography>
          </Grid>
        )}

        {order.purchase_status === "purchased" && (
          <Grid container alignItems="center" pl={0.6}>
            <Typography variant="body2" noWrap sx={{ fontWeight: "700" }}>
              {order.initial_carrier_name !== null &&
                order.initial_carrier_name !== undefined &&
                order.initial_carrier_name.toUpperCase()}
            </Typography>
          </Grid>
        )}

        {order.purchase_status === "fulfilled" && (
          <Grid container alignItems="center" pl={0.6}>
            {JSON.parse(order.fulfillments).length !== 0 &&
            JSON.parse(order.fulfillments)[0] !== null &&
            JSON.parse(order.fulfillments)[0].trackingInfo !== undefined &&
            JSON.parse(order.fulfillments)[0].trackingInfo !== null &&
            JSON.parse(order.fulfillments)[0].trackingInfo.length !== 0 ? (
              <>
                <ShareLocationIcon fontSize="small" color="success" />
                <Tooltip
                  arrow
                  placement="top"
                  title={JSON.parse(order.fulfillments)[0]
                    .trackingInfo.map((item) => item.number)
                    .join()}
                >
                  <Typography
                    variant="body2"
                    component="a"
                    target="_blank"
                    href={JSON.parse(order.fulfillments)[0].trackingInfo[0].url}
                    sx={{
                      pt: "4px",
                    }}
                  >
                    {JSON.parse(order.fulfillments)[0]
                      .trackingInfo.map((item) => item.number)
                      .join().length < 14
                      ? JSON.parse(order.fulfillments)[0]
                          .trackingInfo.map((item) => item.number)
                          .join()
                      : JSON.parse(order.fulfillments)[0]
                          .trackingInfo.map((item) => item.number)
                          .join()
                          .slice(0, 14) + "..."}
                  </Typography>
                </Tooltip>
              </>
            ) : (
              JSON.parse(order.fulfillments).length !== 0 &&
              JSON.parse(order.fulfillments)[0] !== null &&
              JSON.parse(order.fulfillments)[0].tracking_numbers !==
                undefined &&
              JSON.parse(order.fulfillments)[0].tracking_numbers !== null &&
              JSON.parse(order.fulfillments)[0].tracking_numbers.length !==
                0 && (
                <>
                  <ShareLocationIcon fontSize="small" color="success" />
                  <Tooltip
                    arrow
                    placement="top"
                    title={JSON.parse(
                      order.fulfillments
                    )[0].tracking_numbers.join()}
                  >
                    <Typography
                      variant="body2"
                      component="a"
                      target="_blank"
                      href={JSON.parse(order.fulfillments)[0].tracking_url}
                      sx={{
                        pt: "4px",
                      }}
                    >
                      {JSON.parse(order.fulfillments)[0].tracking_numbers.join()
                        .length < 14
                        ? JSON.parse(
                            order.fulfillments
                          )[0].tracking_numbers.join()
                        : JSON.parse(order.fulfillments)[0]
                            .tracking_numbers.join()
                            .slice(0, 14) + "..."}
                    </Typography>
                  </Tooltip>
                </>
              )
            )}
          </Grid>
        )}

        {/* {store === "nzshopify" && order.actual_cost !== null ? (
          <Typography variant="body2">
            Cost: ${parseFloat(order.actual_cost).toFixed(2)} NZ
          </Typography>
        ) : (
          ""
        )} */}
        {/* {store === "nzshopify" &&
          storeName !== "" &&
          selected_supplier === "aliexpress" && (
            <Grid container alignContent="center">
              <img
                src={AliExpressIcon}
                alt="AliExpress Icon"
                style={{ width: "20px" }}
              />
              <Typography variant="body2" color="error" mx={0.5}>
                {storeName}
              </Typography>
            </Grid>
          )} */}
        {/* {store === "aushopify" && order.actual_cost !== null ? (
          <Typography variant="body2">
            Cost: ${parseFloat(order.actual_cost).toFixed(2)} AU
          </Typography>
        ) : (
          ""
        )} */}
        {/* {store === "aushopify" &&
          storeName !== "" &&
          selected_supplier === "aliexpress" && (
            <Grid container alignContent="center">
              <img
                src={AliExpressIcon}
                alt="AliExpress Icon"
                style={{ width: "20px" }}
              />
              <Typography variant="body2" color="error" mx={0.5}>
                {storeName}
              </Typography>
            </Grid>
          )} */}
        {/* {store === "usshopify" && order.actual_cost !== null ? (
          <Typography variant="body2">
            Cost: ${parseFloat(order.actual_cost).toFixed(2)} US
          </Typography>
        ) : (
          ""
        )} */}
        {/* {store === "usshopify" &&
          storeName !== "" &&
          selected_supplier === "aliexpress" && (
            <Grid container alignContent="center">
              <img
                src={AliExpressIcon}
                alt="AliExpress Icon"
                style={{ width: "20px" }}
              />
              <Typography variant="body2" color="error" mx={0.5}>
                {storeName}
              </Typography>
            </Grid>
          )} */}
        <Grid container alignItems="center">
          {editOrderNote ? (
            <Grid container alignContent="center">
              <NoteAddIcon
                sx={{ width: "20px", cursor: "pointer" }}
                onClick={() => setEditOrderNote(!editOrderNote)}
              />
              <input
                style={{ width: "54px", height: "24px", fontSize: "14px" }}
                type="text"
                // value={order.order_note === null ? "" : order.order_note}
                placeholder={
                  order.order_note === null
                    ? "Add Order Note"
                    : order.order_note
                }
                onKeyPress={(event) => handleChangeOrderNote(event, order)}
              />
              <SmallContainedButton
                width={50}
                variant="text"
                onClick={() => setEditOrderNote(!editOrderNote)}
              >
                Cancel
              </SmallContainedButton>
            </Grid>
          ) : (
            <Grid container alignItems="center">
              <Tooltip
                arrow
                placement="top"
                title="Add Note(Internal Use Only)"
              >
                <NoteAddIcon
                  sx={{ width: "20px", cursor: "pointer" }}
                  onClick={() => setEditOrderNote(!editOrderNote)}
                />
              </Tooltip>
              {order.order_note !== null && (
                <Tooltip arrow placement="top" title={order.order_note}>
                  <Typography variant="body2">
                    {order.order_note.length < 12
                      ? order.order_note
                      : order.order_note.slice(0, 12) + "..."}
                  </Typography>
                </Tooltip>
              )}
            </Grid>
          )}
        </Grid>

        <Grid container justifyContent="flex-end">
          <IconButton
            size="small"
            aria-label="more"
            id="more-icon"
            aria-controls={open ? "actions-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClickMoreIcon}
          >
            <MoreVertIcon fontSize="20px" />
          </IconButton>
          <Menu
            id="actions-menu"
            MenuListProps={{
              "aria-labelledby": "more-icon",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseActionMenu}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          >
            {/* {order.financial_status !== "refunded" &&
              (order.purchase_status === null ||
                order.purchase_status === "purchased") && (
                <>
                  <FulfillModal order={order} fetchorders={fetchorders} />
                  <CancelModal order={order} handleCancel={handleCancel} />
                </>
              )}

            {order.purchase_status === null &&
              order.financial_status === "refunded" && (
                <MenuItem sx={{ height: 24, fontSize: "14px" }}>
                  Archive
                </MenuItem>
              )}

            {order.purchase_status === "fulfilled" && (
              // <MenuItem>Edit Tracking</MenuItem>
              <FulfillModal order={order} fetchorders={fetchorders} />
            )} */}

            {order.financial_status === "refunded" ||
            order.financial_status === null ? (
              <>
                <MenuItem sx={{ height: 24, fontSize: "14px" }}>
                  Archive
                </MenuItem>
              </>
            ) : (
              <>
                {order.purchase_status === null && (
                  <>
                    <FulfillModal order={order} fetchorders={fetchorders} />
                    <CancelModal order={order} handleCancel={handleCancel} />
                  </>
                )}

                {order.purchase_status === "purchased" && (
                  <FulfillModal order={order} fetchorders={fetchorders} />
                )}

                {order.purchase_status === "fulfilled" && (
                  <FulfillModal order={order} fetchorders={fetchorders} />
                )}

                {order.purchase_status === "refunded" && (
                  <MenuItem sx={{ height: 24, fontSize: "14px" }}>
                    Archive
                  </MenuItem>
                )}
              </>
            )}
          </Menu>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          container
          sx={{
            display: "Grid",
            gridTemplateColumns:
              "1.4% 4.2% 19.3% 20% 8.5% 3% 10.5% 10.5% 12.6% 10%",
            alignItems: "center",
          }}
        >
          <Grid></Grid>

          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            p={0.5}
          >
            <Tooltip
              arrow
              placement="top"
              PopperProps={{
                sx: {
                  "& .MuiTooltip-tooltip": {
                    padding: 0,
                  },
                },
              }}
              title={
                <img
                  src={order.image ? order.image : no_image}
                  alt=""
                  style={{
                    width: "250px",
                  }}
                />
              }
            >
              <Grid
                item
                justifyContent="center"
                alignContent="center"
                sx={{ border: (theme) => `1px solid ${theme.palette.border}` }}
              >
                <img
                  src={order.image ? order.image : no_image}
                  alt=""
                  width="60px"
                />
              </Grid>
            </Tooltip>
          </Grid>

          {/* Title & Variants */}
          <Grid container flexDirection="column">
            <Tooltip
              arrow
              placement="top"
              title={JSON.parse(order.line_items)[0].title}
            >
              {JSON.parse(order.line_items)[0].variant_title === "" ||
              JSON.parse(order.line_items)[0].variant_title === null ? (
                <Typography variant="body2" mr={5}>
                  {JSON.parse(order.line_items)[0].title.length > 70
                    ? JSON.parse(order.line_items)[0].title.slice(0, 70) + "..."
                    : JSON.parse(order.line_items)[0].title}
                </Typography>
              ) : (
                <Typography variant="body2">
                  {JSON.parse(order.line_items)[0].title.length > 40
                    ? JSON.parse(order.line_items)[0].title.slice(0, 40) + "..."
                    : JSON.parse(order.line_items)[0].title}
                </Typography>
              )}
            </Tooltip>

            <Grid container>
              <Typography variant="body2" color="primary">
                {JSON.parse(order.line_items)[0].variant_title === "" ||
                JSON.parse(order.line_items)[0].variant_title === null
                  ? ""
                  : `Shopify Variant: ${
                      JSON.parse(order.line_items)[0].variant_title
                    }`}
              </Typography>
            </Grid>

            {order.supplier === "aliexpress" && (
              <Grid container>
                <Tooltip
                  arrow
                  placement="bottom"
                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        padding: 0,
                      },
                    },
                  }}
                  title={
                    <img
                      src={
                        sku_image_url_string ? sku_image_url_string : no_image
                      }
                      alt=""
                      style={{
                        width: "250px",
                      }}
                    />
                  }
                >
                  <Typography variant="body2" color="primary">
                    Supplier Variant:{" "}
                    {order.ali_variantname === "" ||
                    order.ali_variantname === null
                      ? "N/A"
                      : order.ali_variantname}
                  </Typography>
                </Tooltip>

                <Tooltip arrow placement="top" title="Map Variant">
                  <Typography variant="body2">
                    <EditIcon
                      fontSize="16px"
                      sx={{ cursor: "pointer", ml: 0.25 }}
                      onClick={handleOpenMapVariantModal}
                    />
                  </Typography>
                </Tooltip>

                <MapAliexpressVariantModal
                  orderId={order.orderid}
                  mappingProducts={mappingProducts}
                  openMapVariantModal={openMapVariantModal}
                  setAliVariantName={setAliVariantName}
                  setOpenMapVariantModal={setOpenMapVariantModal}
                  handleCloseMapVariantModal={handleCloseMapVariantModal}
                  count3={count3}
                  setCount3={setCount3}
                  setShopifySku={setShopifySku}
                  index={index}
                  indexM={indexM}
                  setIndexm={setIndexm}
                />
              </Grid>
            )}
          </Grid>

          <Grid container flexDirection="column">
            <>
              <Grid item container alignItems="center">
                <Typography variant="body2">
                  Supplier SKU:{" "}
                  {order.supplier_sku && order.supplier_sku.length > 28 ? (
                    <Tooltip arrow placement="top" title={order.supplier_sku}>
                      <a href={supplier_sku_url} target="_blank">
                        {order.supplier_sku.slice(0, 28) + "..."}
                      </a>
                    </Tooltip>
                  ) : (
                    <a href={supplier_sku_url} target="_blank">
                      {order.supplier_sku}
                    </a>
                  )}
                </Typography>
                {/* <Tooltip arrow placement="top" title=" Map Supplier SKU">
                  <Typography variant="body2">
                    { order.supplier == 'aliexpress' &&
                    (<EditIcon
                      fontSize="16px"
                      sx={{ cursor: "pointer", ml: 0.25 }}
                      onClick={handleOpenMapVariantModalForEditSKU}
                    />
                    )
}
                    <OrderSkuEditorModel
                     orderId={order.orderid}
                     mappingProducts={mappingProducts}
                     showeditsuppliersku={showeditsuppliersku}
                     setAliVariantName={setAliVariantName}
                     setshoweditsuppliersku={setshoweditsuppliersku}
                     handleCloseMapVariantModal={handleCloseMapVariantModal}
                     count3={count3}
                     setCount3={setCount3}
                     setShopifySku={setShopifySku}
                     index={index}
                     indexM={indexM}
                     setIndexm={setIndexm}
                    />
                    
                  </Typography>
                </Tooltip>
                 */}
                {/* <Tooltip arrow placement="top" title="Edit Supplier Sku">
                    <Typography variant="body2">
                      <EditIcon
                        fontSize="16px"
                        sx={{ cursor: "pointer", ml: 0.25 }}
                        onClick={() => setEditSupplierSku(!editSupplierSku)}
                      />
                    </Typography>
                  </Tooltip> */}

                <Tooltip arrow placement="top" title="Copy Supplier Sku">
                  <Typography variant="body2">
                    <ContentCopy
                      fontSize="16px"
                      sx={{ cursor: "pointer", ml: 0.25 }}
                      onClick={() => handleCopyText(order.supplier_sku)}
                    />
                  </Typography>
                </Tooltip>
              </Grid>

              {editShopifySku ? (
                <Typography variant="body2">
                  Shopify SKU:
                  <input
                    type="text"
                    value={shopifysku}
                    placeholder={shopifysku}
                    onChange={(event) => setShopifySku(event.target.value)}
                    onKeyPress={(event) => handleEditShopifySku(event, order)}
                  />
                  <SmallContainedButton
                    width={50}
                    variant="text"
                    onClick={() => setEditShopifySku(!editShopifySku)}
                  >
                    Cancel
                  </SmallContainedButton>
                </Typography>
              ) : (
                <Grid item container alignItems="center">
                  <Typography variant="body2">
                    Shopify SKU:{" "}
                    {shopifysku && shopifysku.length > 28 ? (
                      <Tooltip arrow placement="top" title={shopifysku}>
                        <a href={shopify_sku_url} target="_blank">
                          {shopifysku.slice(0, 28) + "..."}
                        </a>
                      </Tooltip>
                    ) : (
                      <a href={shopify_sku_url} target="_blank">
                        {shopifysku}
                      </a>
                    )}
                  </Typography>

                  <Tooltip arrow placement="top" title="Edit Shopify Sku">
                    <Typography variant="body2">
                      <EditIcon
                        fontSize="16px"
                        sx={{ cursor: "pointer", ml: 0.25 }}
                        onClick={() => setEditShopifySku(!editShopifySku)}
                      />
                    </Typography>
                  </Tooltip>

                  <Tooltip arrow placement="top" title="Copy Shopify Sku">
                    <Typography variant="body2">
                      <ContentCopy
                        fontSize="16px"
                        sx={{ cursor: "pointer", ml: 0.25 }}
                        onClick={() => handleCopyText(shopifysku)}
                      />
                    </Typography>
                  </Tooltip>
                </Grid>
              )}
            </>
          </Grid>

          {/* You Buy & You Sell Price */}
          <Grid container flexDirection="column">
            {store === "nzshopify" && (
              <>
                <Typography variant="body2">You Buy: Test</Typography>
                <Typography variant="body2">
                  {`You Sell: ${JSON.parse(order.line_items)[0].price} NZD`}
                </Typography>
              </>
            )}

            {store === "aushopify" && (
              <>
                <Typography variant="body2">You Buy: Test</Typography>
                <Typography variant="body2">
                  {`You Sell: ${JSON.parse(order.line_items)[0].price} AUD`}
                </Typography>
              </>
            )}

            {store === "usshopify" && (
              <>
                <Typography variant="body2">You Buy: Test</Typography>
                <Typography variant="body2">
                  {`You Sell: ${JSON.parse(order.line_items)[0].price} USD`}
                </Typography>
              </>
            )}

            {store === "gkshopify" && (
              <>
                <Typography variant="body2">You Buy: Test</Typography>
                <Typography variant="body2">
                  {`You Sell: ${JSON.parse(order.line_items)[0].price} NZD`}
                </Typography>
              </>
            )}
          </Grid>

          {/* Quantity */}
          <Grid container>
            <Typography variant="body2">
              x {JSON.parse(order.line_items)[0].quantity}
            </Typography>
          </Grid>

          {/* Total Buy & Total Sell */}
          <Grid container flexDirection="column">
            {store === "nzshopify" && (
              <>
                <Typography variant="body2">Total Buy: Test</Typography>
                <Typography variant="body2">
                  {`Total Sell:
                  ${(
                    parseFloat(JSON.parse(order.line_items)[0].quantity) *
                    parseFloat(JSON.parse(order.line_items)[0].price)
                  ).toFixed(2)} NZD`}
                </Typography>
              </>
            )}

            {store === "aushopify" && (
              <>
                <Typography variant="body2">Total Buy: Test</Typography>
                <Typography variant="body2">
                  {`Total Sell:
                  ${(
                    parseFloat(JSON.parse(order.line_items)[0].quantity) *
                    parseFloat(JSON.parse(order.line_items)[0].price)
                  ).toFixed(2)} AUD`}
                </Typography>
              </>
            )}

            {store === "usshopify" && (
              <>
                <Typography variant="body2">Total Buy: Test</Typography>
                <Typography variant="body2">
                  {`Total Sell:
                  ${(
                    parseFloat(JSON.parse(order.line_items)[0].quantity) *
                    parseFloat(JSON.parse(order.line_items)[0].price)
                  ).toFixed(2)} USD`}
                </Typography>
              </>
            )}

            {store === "gkshopify" && (
              <>
                <Typography variant="body2">Total Buy: Test</Typography>
                <Typography variant="body2">
                  {`Total Sell:
                  ${(
                    parseFloat(JSON.parse(order.line_items)[0].quantity) *
                    parseFloat(JSON.parse(order.line_items)[0].price)
                  ).toFixed(2)} NZD`}
                </Typography>
              </>
            )}
          </Grid>

          {/* Shipping & Tax */}
          <Grid container flexDirection="column">
            {store === "nzshopify" && (
              <>
                <Typography variant="body2">
                  Shipping: {carrier ? carrier.currency + carrier.amount : 0}
                </Typography>
                <Grid container alignItems="center">
                  <Typography variant="body2">Tax: 15%</Typography>
                  <Tooltip
                    arrow
                    placement="top"
                    title="New Zealand Govement Charge 15% GST Tax on product. New
                  Zealand Govement Charge 15% GST Tax on product."
                    PopperProps={{
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          width: 180,
                        },
                      },
                    }}
                  >
                    <HelpOutlineIcon fontSize="18px" />
                  </Tooltip>
                </Grid>
              </>
            )}

            {store === "aushopify" && (
              <>
                <Typography variant="body2">
                  Shipping: {carrier ? carrier.currency + carrier.amount : 0}
                </Typography>
                <Grid container>
                  <Typography variant="body2">Tax: 15%</Typography>
                  <Tooltip
                    arrow
                    placement="top"
                    title="New Zealand Govement Charge 15% GST Tax on product. New
                  Zealand Govement Charge 15% GST Tax on product."
                  >
                    <HelpOutlineIcon fontSize="small" />
                  </Tooltip>
                </Grid>
              </>
            )}

            {store === "usshopify" && (
              <>
                <Typography variant="body2">
                  Shipping: {carrier ? carrier.currency + carrier.amount : 0}
                </Typography>
                <Grid container>
                  <Typography variant="body2">Tax: 15%</Typography>
                  <Tooltip
                    arrow
                    placement="top"
                    title="New Zealand Govement Charge 15% GST Tax on product. New
                  Zealand Govement Charge 15% GST Tax on product."
                  >
                    <HelpOutlineIcon fontSize="small" />
                  </Tooltip>
                </Grid>
              </>
            )}

            {store === "gkshopify" && (
              <>
                <Typography variant="body2">
                  Shipping: {carrier ? carrier.currency + carrier.amount : 0}
                </Typography>
                <Grid container alignItems="center">
                  <Typography variant="body2">Tax: 15%</Typography>
                  <Tooltip
                    arrow
                    placement="top"
                    title="New Zealand Govement Charge 15% GST Tax on product. New
                  Zealand Govement Charge 15% GST Tax on product."
                    PopperProps={{
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          width: 180,
                        },
                      },
                    }}
                  >
                    <HelpOutlineIcon fontSize="18px" />
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>

          {/* Total Cost & Your Profit */}
          <Grid container flexDirection="column">
            {store === "nzshopify" && (
              <>
                <Typography variant="body2">
                  Total Cost:{" "}
                  {order.actual_cost !== null
                    ? `NZ$${parseFloat(order.actual_cost).toFixed(2)}`
                    : ""}
                </Typography>
                <Typography variant="body2">
                  {`Your Profit: 
                  ${parseFloat(
                    JSON.parse(order.line_items)[0].quantity *
                      JSON.parse(order.line_items)[0].price *
                      0.85
                  ).toFixed(2)} NZD`}
                </Typography>
              </>
            )}

            {store === "aushopify" && (
              <>
                <Typography variant="body2">
                  Total Cost:{" "}
                  {order.actual_cost !== null
                    ? `AU$${parseFloat(order.actual_cost).toFixed(2)}`
                    : ""}
                </Typography>
                <Typography variant="body2">
                  {`Your Profit: 
                  ${parseFloat(
                    JSON.parse(order.line_items)[0].quantity *
                      JSON.parse(order.line_items)[0].price *
                      0.85
                  ).toFixed(2)} AUD`}
                </Typography>
              </>
            )}

            {store === "usshopify" && (
              <>
                <Typography variant="body2">
                  Total Cost:{" "}
                  {order.actual_cost !== null
                    ? `US$${parseFloat(order.actual_cost).toFixed(2)}`
                    : ""}
                </Typography>
                <Typography variant="body2">
                  {`Your Profit: 
                  ${parseFloat(
                    JSON.parse(order.line_items)[0].quantity *
                      JSON.parse(order.line_items)[0].price *
                      0.85
                  ).toFixed(2)} USD`}
                </Typography>
              </>
            )}

            {store === "gkshopify" && (
              <>
                <Typography variant="body2">
                  Total Cost:{" "}
                  {order.actual_cost !== null
                    ? `NZ$${parseFloat(order.actual_cost).toFixed(2)}`
                    : ""}
                </Typography>
                <Typography variant="body2">
                  {`Your Profit: 
                  ${parseFloat(
                    JSON.parse(order.line_items)[0].quantity *
                      JSON.parse(order.line_items)[0].price *
                      0.85
                  ).toFixed(2)} NZD`}
                </Typography>
              </>
            )}
          </Grid>

          <Grid container justifyContent="flex-end" alignItems="center">
            {order.purchase_status === null && (
              <>
                {purchasingorder[1] && order.orderid === purchasingorder[0] ? (
                  <Loader size="20px" />
                ) : order.financial_status !== "refunded" ? (
                  <SmallContainedButton
                    color="secondary"
                    onClick={(event) => handlePurchase(order)}
                  >
                    Purchase
                  </SmallContainedButton>
                ) : (
                  <SmallContainedButton color="error">
                    Cancelled
                  </SmallContainedButton>
                )}
              </>
            )}

            {(order.purchase_status === "purchased" ||
              order.purchase_status === "fulfilled") && (
              <AutofulfillModal order={order} fetchorders={fetchorders} />
            )}

            <Button
              variant="outlined"
              sx={{
                width: 24,
                height: 24,
                minWidth: 0,
                minHeight: 0,
                padding: 0,
                mx: 0.5,
              }}
              onClick={() => setShowCustomerDetails(!showCustomerDetails)}
            >
              {showCustomerDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {showCustomerDetails && (
        <>
          <Divider variant="middle" />
          <Grid
            container
            sx={{
              display: "Grid",
              gridTemplateColumns: "1.5% 14% 14% 14% 14% 14% 21.5% 7%",
              alignItems: "center",
            }}
          >
            <Grid></Grid>
            <Grid container flexDirection="column" p={0.5}>
              <SmallOutlinedTextField
                label="First Name"
                name="first_name"
                value={values.first_name}
                onChange={handleChangeCustomerDetails}
              />
              <SmallOutlinedTextField
                label="Last Name"
                name="last_name"
                value={values.last_name}
                onChange={handleChangeCustomerDetails}
              />
              <SmallOutlinedTextField
                label="Phone"
                name="phone"
                value={
                  order.supplier === "aliexpress"
                    ? values.phone
                      ? values.phone.split(" ").join("")
                      : values.phone
                    : values.phone
                }
                onChange={handleChangeCustomerDetails}
              />
            </Grid>

            <Grid container flexDirection="column" p={0.5}>
              <SmallOutlinedTextField
                label="Email"
                name="email"
                value={values.email}
                onChange={handleChangeCustomerDetails}
              />
              <SmallOutlinedTextField
                label="Company"
                name="company"
                value={values.company}
                onChange={handleChangeCustomerDetails}
              />
              <SmallOutlinedTextField
                label="Order Memo"
                name="order_memo"
                value={values.order_memo}
                onChange={handleChangeCustomerDetails}
              />
            </Grid>

            <Grid container flexDirection="column" p={0.5}>
              <SmallOutlinedTextField
                label="Address1"
                name="address1"
                value={values.address1}
                onChange={handleChangeCustomerDetails}
              />
              <SmallOutlinedTextField
                label="Address2"
                name="address2"
                value={values.address2}
                onChange={handleChangeCustomerDetails}
              />
              <SmallOutlinedTextField
                label="Province"
                name="province"
                value={isvalid ? "Other" : values.province}
                onChange={handleChangeCustomerDetails}
              />
            </Grid>

            <Grid container flexDirection="column" p={0.5}>
              <SmallOutlinedTextField
                label="City"
                name="city"
                value={values.city}
                onChange={handleChangeCustomerDetails}
              />
              <SmallOutlinedTextField
                label="Country"
                name="country"
                value={values.country}
                onChange={handleChangeCustomerDetails}
              />
              <SmallOutlinedTextField
                label="Post Code"
                name="zip"
                value={values.zip}
                onChange={handleChangeCustomerDetails}
              />
            </Grid>

            <Grid container p={0.5}>
              <TextField
                multiline
                fullWidth
                rows={5}
                size="small"
                name="customer_message"
                label="Customer Message"
                value={values.customer_message}
                onChange={handleChangeCustomerDetails}
                InputProps={{
                  sx: {
                    fontSize: "14px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: "14px",
                    top: "2px",
                    "&.Mui-focused": {
                      transform: "translate(13px, -8px) scale(0.8)",
                    },
                  },
                }}
              />
            </Grid>
            {/* <Grid container flexDirection="column" alignItems="flex-start">
              <Typography variant="body2">
                {editFirstName ? (
                  <Box>
                    <input
                      type="text"
                      placeholder={
                        JSON.parse(order.shipping_address).first_name
                      }
                      onKeyPress={(event) =>
                        handleEditCustomer(event, order, "firstname")
                      }
                    />
                  </Box>
                ) : (
                  <Box>
                    {order.shipping_address &&
                      JSON.parse(order.shipping_address).first_name}
                  </Box>
                )}
                {editLastName ? (
                  <Box>
                    <input
                      type="text"
                      placeholder={
                        order.shipping_address &&
                        JSON.parse(order.shipping_address).last_name
                      }
                      onKeyPress={(event) =>
                        handleEditCustomer(event, order, "lastname")
                      }
                    />
                  </Box>
                ) : (
                  <Box>
                    {order.shipping_address &&
                      JSON.parse(order.shipping_address).last_name}
                  </Box>
                )}

                {editEmail ? (
                  <Box>
                    <input
                      type="text"
                      placeholder={order.email}
                      onKeyPress={(event) =>
                        handleEditCustomer(event, order, "email")
                      }
                    />
                  </Box>
                ) : (
                  <Box>{order.email}</Box>
                )}

                {editPhone ? (
                  <Box>
                    <input
                      type="text"
                      placeholder={
                        order.shipping_address &&
                        JSON.parse(order.shipping_address).phone
                      }
                      onKeyPress={(event) =>
                        handleEditCustomer(event, order, "phone")
                      }
                    />
                  </Box>
                ) : (
                  <Box>
                    {order.shipping_address &&
                      JSON.parse(order.shipping_address).phone}
                  </Box>
                )}
                {editAddress1 ? (
                  <Box>
                    <input
                      type="text"
                      placeholder={
                        order.shipping_address &&
                        JSON.parse(order.shipping_address).address1
                      }
                      onKeyPress={(event) =>
                        handleEditCustomer(event, order, "address1")
                      }
                    />
                  </Box>
                ) : (
                  <Box>
                    {order.shipping_address &&
                      JSON.parse(order.shipping_address).address1}
                  </Box>
                )}

                {editAddress2 ? (
                  <Box>
                    <input
                      type="text"
                      placeholder={
                        order.shipping_address &&
                        JSON.parse(order.shipping_address).address2
                      }
                      onKeyPress={(event) =>
                        handleEditCustomer(event, order, "address2")
                      }
                    />
                  </Box>
                ) : (
                  <Box>
                    {order.shipping_address !== null &&
                      (JSON.parse(order.shipping_address).address2 === null
                        ? "NA"
                        : JSON.parse(order.shipping_address).address2)}
                  </Box>
                )}

                {editCity ? (
                  <Box>
                    <input
                      type="text"
                      placeholder={
                        order.shipping_address &&
                        JSON.parse(order.shipping_address).city
                      }
                      onKeyPress={(event) =>
                        handleEditCustomer(event, order, "city")
                      }
                    />
                  </Box>
                ) : (
                  <Box>
                    {order.shipping_address &&
                      JSON.parse(order.shipping_address).city}
                  </Box>
                )}
              </Typography>
            </Grid> */}
            {/* {editProvince ? (
                  <Box>
                    <input
                      type="text"
                      placeholder={
                        order.shipping_address &&
                        JSON.parse(order.shipping_address).province
                      }
                      onKeyPress={(event) =>
                        handleEditCustomer(event, order, "province")
                      }
                    />
                  </Box>
                ) : (
                  <Box>
                    {order.shipping_address &&
                    JSON.parse(order.shipping_address).province
                      ? JSON.parse(order.shipping_address).province
                      : "NA"}
                  </Box>
                )}

                {editCountry ? (
                  <Box>
                    <input
                      type="text"
                      placeholder={
                        order.shipping_address &&
                        JSON.parse(order.shipping_address).country
                      }
                      onKeyPress={(event) =>
                        handleEditCustomer(event, order, "country")
                      }
                    />
                  </Box>
                ) : (
                  <Box>
                    {order.shipping_address &&
                      JSON.parse(order.shipping_address).country}
                  </Box>
                )}

                {editZip ? (
                  <Box>
                    <input
                      type="text"
                      placeholder={
                        order.shipping_address &&
                        JSON.parse(order.shipping_address).zip
                      }
                      onKeyPress={(event) =>
                        handleEditCustomer(event, order, "zip")
                      }
                    />
                  </Box>
                ) : (
                  <Box>
                    {order.shipping_address &&
                      JSON.parse(order.shipping_address).zip}
                  </Box>
                )}
                {editCompany ? (
                  <Box>
                    <input
                      type="text"
                      placeholder={
                        order.shipping_address &&
                        JSON.parse(order.shipping_address).company
                      }
                      onKeyPress={(event) =>
                        handleEditCustomer(event, order, "company")
                      }
                    />
                  </Box>
                ) : (
                  <Box>
                    {order.shipping_address &&
                      JSON.parse(order.shipping_address).company}
                  </Box>
                )}
                {editNote ? (
                  <Box>
                    <input
                      type="text"
                      placeholder={order.memo}
                      onKeyPress={(event) => handleEditMemo(event, order)}
                    />
                  </Box>
                ) : (
                  <Box>{order.memo}</Box>
                )} */}
            <Grid
              container
              flexDirection="column"
              justifyContent="center"
              p={0.5}
            >
              <FormControl fullWidth size="small">
                <InputLabel
                  id="supplier-select-label"
                  sx={{
                    top: () =>
                      order.supplier === null || order.supplier === ""
                        ? "-2px"
                        : "2px",
                    fontSize: "0.8rem",
                  }}
                >
                  Supplier
                </InputLabel>
                <Select
                  label="Supplier"
                  value={selectedSupplier}
                  labelId="supplier-select-label"
                  onChange={(event) => handleChangeSupplier(event, order)}
                  sx={{ height: 30, fontSize: "0.8rem" }}
                >
                  {suppliers.map((supplier) => (
                    <MenuItem key={supplier} value={supplier}>
                      <Typography variant="body2">
                        {supplier.charAt(0).toUpperCase() + supplier.slice(1)}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {order.purchase_status === null &&
                order.supplier === "aliexpress" && (
                  <AliexpressCarriers
                    carrier={carrier}
                    setCarrier={setCarrier}
                    order={order}
                  />
                )}

              {order.purchase_status === null &&
                order.supplier === "vidaxl" && (
                  <VidaxlCarriers
                    vidaxlCarrier={vidaxlCarrier}
                    setVidaxlCarrier={setVidaxlCarrier}
                    order={order}
                  />
                )}

              {order.purchase_status === null &&
                order.supplier === "dropship" && (
                  <DropshipzoneCarriers
                    dropshipzoneCarrier={dropshipzoneCarrier}
                    setDropshipzoneCarrier={setDropshipzoneCarrier}
                    order={order}
                  />
                )}

              {order.purchase_status === null &&
                order.supplier === "bigbuy" && (
                  <BigbuyCarriers
                    carrier={carrier}
                    setCarrier={setCarrier}
                    order={order}
                  />
                )}
            </Grid>
            <Grid container justifyContent="center" p={0.5}>
              <SmallContainedButton
                width={60}
                color="secondary"
                onClick={(event) => handleSaveCustomerDetails(event, order)}
              >
                Save
              </SmallContainedButton>
            </Grid>
            {/* <div>
              {order.purchase_status === null && (
                <>
                  {purchasingorder[1] &&
                  order.orderid === purchasingorder[0] ? (
                    <CircularProgress />
                  ) : (
                    <Grid
                      container
                      mx={1}
                      flexDirection="column"
                      alignContent="center"
                    >
                      {order.financial_status !== "refunded" ? (
                        <>
                          <Button
                            fullWidth
                            variant="contained"
                            size="small"
                            sx={{ my: 1 }}
                            onClick={(event) => handlePurchase(order)}
                          >
                            purchase
                          </Button>
                          <CancelModal
                            order={order}
                            handleCancel={handleCancel}
                          />
                        </>
                      ) : (
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          size="small"
                        >
                          Cancelled
                        </Button>
                      )}
                    </Grid>
                  )}
                </>
              )}

              {(order.purchase_status === "purchased" ||
                order.purchase_status === "fulfilled" ||
                order.purchase_status === null) && (
                <Modal_tracking order={order} fetchorders={fetchorders} />
              )}
            </div> */}
          </Grid>
          {/* <Divider /> */}
        </>
      )}
    </Card>
  );
}
