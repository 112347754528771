import React, { useState } from "react";
import KoganProductUpdaterTextfieldsGroup from "./KoganProductUpdaterTextfieldsGroup";
import KoganProductsTable from "./KoganProductsTable";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "../common/Loader";
import { Grid, Container, Typography, Select, MenuItem } from "@mui/material";

const KoganProductUpdater = ({ page }) => {
  const [searchBy, setSearchBy] = useState("type");
  const [koganProducts, setKoganProducts] = useState([]);
  const loading_products = useSelector((state) => state.loading_products);
  const [count, setCount] = useState(0);

  console.log("loading_products", loading_products);
  //Search By Select
  const SearchBySelect = (
    <Grid item container alignItems="center" p={1}>
      <Typography variant="body2" sx={{ mr: 0.5 }}>
        Search By
      </Typography>

      <Select
        size="small"
        value={searchBy}
        onChange={(event) => setSearchBy(event.target.value)}
        sx={{
          width: "200px",
          "& legend": {
            width: "unset",
          },
        }}
      >
        <MenuItem value="type" sx={{ height: 26, fontSize: "0.8rem" }}>
          <Typography variant="body2">Type</Typography>
        </MenuItem>

        <MenuItem value="skulist" sx={{ height: 26, fontSize: "0.8rem" }}>
          <Typography variant="body2">SKUs</Typography>
        </MenuItem>

        <MenuItem value="vendor" sx={{ height: 26, fontSize: "0.8rem" }}>
          <Typography variant="body2">Vendor</Typography>
        </MenuItem>
      </Select>
    </Grid>
  );

  return (
    <Grid container p={1}>
      <Container
        maxWidth="lg"
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Grid
          container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            py={2}
          >
            <Typography variant="h6">--- Kogan Product Updater ---</Typography>
          </Grid>

          <Grid container>
            <Grid item xs={4}>
              {SearchBySelect}
            </Grid>

            <Grid item xs={8}>
              <KoganProductUpdaterTextfieldsGroup
                count={count}
                page={page}
                searchBy={searchBy}
                koganProducts={koganProducts}
                setKoganProducts={setKoganProducts}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="center" alignItems="center" py={6}>
            {loading_products ? (
              <Grid container>
                <Loader size="30px" />
              </Grid>
            ) : (
              <KoganProductsTable
                count={count}
                setCount={setCount}
                koganProducts={koganProducts}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default KoganProductUpdater;
