import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import BasicSelect from "./select.js";
import {
  setSearch,
  setProductsPerPage,
  setImportPage,
  setImportTotalPages,
  setArticles,
  setSubsettings,
  setToken,
  setLoading,
  setCountrylist,
  setShippingMethods,
  setShowImportedProductsFilter,
  setCategoriesLevel1,
  setCategoriesLevel2,
  setCategoriesLevel3,
  setCategoriesLevel4,
  setCategoriesLevel5,
  setCategoriesLevel6,
  setImportedProducts,
  setImportedProductsFilter,
  setSelectedCategoryLevel2,
  setSelectedCategoryLevel3,
  setSelectedCategoryLevel4,
  setSelectedCategoryLevel5,
  setSelectedCategoryLevel6,
} from "../../actions";
import { useSelector, useDispatch } from "react-redux";
export default function AdvancedFormula({
  handleClick,
  adv_formulas,
  setAdvFormulas,
}) {
  const [margintype, setMarginType] = React.useState("Percentage");
  const [costmin, setCostMin] = useState(0);
  const [costmax, setCostMax] = useState(0);
  const [margin, setMargin] = useState(0);
  const [count, setCount] = useState(0);
  const [exist, setExist] = useState(false);

  const handleChange = (event, index) => {
    let copy = adv_formulas;
    copy[index].margintype = event.target.value;
    setAdvFormulas(copy);
    setCount(count + 1);
  };

  const handleMarginChange = (event, index) => {
    let copy = adv_formulas;
    copy[index].margin = event.target.value;
    setAdvFormulas(copy);
    setCount(count + 1);
  };

  const handleCostMinChange = (event, index) => {
    let copy = adv_formulas;
    copy[index].costmin = event.target.value;
    setAdvFormulas(copy);
    setCount(count + 1);
  };

  const handleCostMaxChange = (event, index) => {
    let copy = adv_formulas;
    copy[index].costmax = event.target.value;
    setAdvFormulas(copy);
    setCount(count + 1);
  };

  const handleSave = () => {
    fetch(`http://34.129.244.90/addpriceformulaadvanced`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ formula: adv_formulas }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCount(count + 1);
      });
  };

  const handleOverride = () => {
    fetch(`http://34.129.244.90/putpriceformula`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ formula: adv_formulas, id: "Advanced" }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCount(count + 1);
      });
  };

  useEffect(() => {
    let obj = { id: "Advanced" };
    fetch(`http://34.129.244.90/getpriceformula`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.length !== 0) {
          let obj = response[0].formula;
          setAdvFormulas(obj);
          setExist(true);
        } else {
          let obj = [
            {
              costmin: 0,
              costmax: 0,
              margin: 0,
              fixedprofit: 0,
              breakeven: 0,
              margintype: "Percentage",
            },
          ];
          setAdvFormulas(obj);
        }
      });
  }, [count]);

  console.log("copy", adv_formulas);

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "30% 20% 10% 10% 25% 5%",
          textAlign: "center",
          marginTop: "2%",
          marginBottom: "1%",
        }}
      >
        {adv_formulas.map((formula, index) => {
          return (
            <>
              <div>
                <input
                  value={formula.costmin}
                  style={{ textAlign: "center", marginLeft: "1%" }}
                  type="number"
                  min="0"
                  max="100"
                  onChange={(event) => handleCostMinChange(event, index)}
                />
                <label>-</label>
                <input
                  value={formula.costmax}
                  style={{ textAlign: "center", marginLeft: "1%" }}
                  type="number"
                  min="0"
                  max="100"
                  onChange={(event) => handleCostMaxChange(event, index)}
                />
              </div>
              <div>
                <input
                  value={formula.margin}
                  style={{ textAlign: "center", marginLeft: "1%" }}
                  type="number"
                  min="0"
                  max="100"
                  onChange={(event) => handleMarginChange(event, index)}
                />
                {margintype === "Percentage" ? (
                  <label style={{ textAlign: "center", marginLeft: "1%" }}>
                    %
                  </label>
                ) : (
                  ""
                )}
              </div>
              <BasicSelect
                margintype={formula.margintype}
                handleChange={(event) => handleChange(event, index)}
              />
              <div
                style={{ cursor: "pointer" }}
                onClick={(event) => handleClick(event)}
              >
                -
              </div>
            </>
          );
        })}
      </div>
      {exist ? (
        <Grid container my={1} justifyContent="center">
          <Button
            disabled
            variant="contained"
            color="success"
            onClick={(event) => handleSave(event)}
          >
            SAVE
          </Button>
          <Button
            variant="contained"
            color="success"
            style={{ marginLeft: "10%" }}
            onClick={(event) => handleOverride(event)}
          >
            Override Changes
          </Button>
        </Grid>
      ) : (
        <Grid container my={1} justifyContent="center">
          <Button
            variant="contained"
            color="success"
            onClick={(event) => handleSave(event)}
          >
            SAVE
          </Button>
        </Grid>
      )}
    </div>
  );
}
