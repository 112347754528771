import React from "react";
import SalesPro_300 from "../assets/images/SalesPro_300.png";
import "./loader.css";

export const Loader = ({ size }) => {
  return (
    <img
      className="spinner"
      src={SalesPro_300}
      alt="logo_loader"
      style={{
        width: size,
        height: size,
        margin: "auto",
      }}
    />
  );
};
