import React, { useState, useEffect } from "react";
import { Grid, Button, Chip, Select, MenuItem, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import CustomizedSnackbars from "./notification";
import Pagination from "../common/Pagination.js";

import {
  setLoadingProducts,
  setImportTotalPages,
  setTotalCounts,
  setImportPage,
} from "../../actions";

const KoganProductUpdaterTextfieldsGroup = ({
  page,
  count,
  searchBy,
  koganProducts,
  setKoganProducts,
}) => {
  const dispatch = useDispatch();
  const total_counts = useSelector((state) => state.total_counts);
  const import_page = useSelector((state) => state.import_page);
  const products_per_page = useSelector((state) => state.products_per_page);

  const [vendors, setVendors] = useState([]);

  const [typeValue, setTypeValue] = useState("");
  const [skulist, setSkuList] = useState("");
  const [selectedVendor, setSelectedVendor] = useState("");

  console.log("typeValue", typeValue.trim());
  console.log("import_page", import_page);
  console.log("selectedVendor", selectedVendor);
  console.log("products_per_page", products_per_page);

  useEffect(() => {
    setTypeValue("");
    setSkuList("");
    setSelectedVendor("");
    setKoganProducts([]);
    if (searchBy === "vendor") {
      fetch("https://api.saleprodev.com/getnzshopifyvendors")
        .then((response) => response.json())
        .then((response) => {
          console.log("vendor", response);
          setVendors(response);
        });
    }
  }, [searchBy]);

  const handleChangeSkuList = (event) => {
    if (event.target.value.split(/\r\n|\r|\n/).length <= 500) {
      setSkuList(event.target.value);
    } else {
      setOpenSnackbars({
        open: true,
        severity: "error",
        message: "Too many SKUs, please search 500 SKUs at most.",
      });
    }
  };

  const [openSnackbars, setOpenSnackbars] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const handleCloseSnackbars = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbars({ open: false, message: "" });
  };

  const handleSearchByType = () => {
    dispatch(setLoadingProducts(true));

    fetch("https://api.saleprodev.com/getkoganproductsbytype", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        product_type: typeValue.trim(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("KoganProducts", response);
        let offset = products_per_page * (import_page - 1);
        setKoganProducts(response.slice(offset, offset + products_per_page));

        dispatch(
          setImportTotalPages(parseInt(response.length / products_per_page) + 1)
        );
        dispatch(setTotalCounts(response.length));
        dispatch(setLoadingProducts(false));
      });
  };

  const handleSearchBySku = () => {
    dispatch(setLoadingProducts(true));

    fetch("https://api.saleprodev.com/getkoganproductsbysku", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sku: skulist.split("\n"),
        page: import_page,
        products_per_page: products_per_page,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("KoganProducts", response);
        setKoganProducts(response.products);

        dispatch(
          setImportTotalPages(parseInt(response.count / products_per_page) + 1)
        );
        dispatch(setTotalCounts(response.count));
        dispatch(setLoadingProducts(false));
      });
  };

  const handleSearchByVendor = () => {
    dispatch(setLoadingProducts(true));
    fetch("https://api.saleprodev.com/getkoganproductsbyvendor", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        vendor: selectedVendor,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("KoganProducts", response);
        let offset = products_per_page * (import_page - 1);
        setKoganProducts(response.slice(offset, offset + products_per_page));

        dispatch(
          setImportTotalPages(parseInt(response.length / products_per_page) + 1)
        );
        dispatch(setTotalCounts(response.length));
        dispatch(setLoadingProducts(false));
      });
  };

  useEffect(() => {
    if (searchBy === "type") {
      handleSearchByType();
    }

    if (searchBy === "skulist") {
      handleSearchBySku();
    }

    if (searchBy === "vendor") {
      handleSearchByVendor();
    }
  }, [count, import_page, products_per_page]);

  useEffect(() => {
    if (import_page !== 1) {
      dispatch(setImportPage(1));
    }
  }, [products_per_page]);

  return (
    <>
      {searchBy === "type" && (
        <Grid container alignItems="center">
          <Grid item xs={10} p={1}>
            <TextField
              size="small"
              value={typeValue}
              placeholder="Enter Type"
              onChange={(event) => setTypeValue(event.target.value)}
              sx={{
                width: "100%",
                fontSize: "0.8rem",
                "& legend": {
                  width: "unset",
                },
              }}
            />
          </Grid>

          <Grid item xs={2} p={1}>
            <Button
              variant="contained"
              disabled={typeValue === ""}
              onClick={handleSearchByType}
              sx={{ width: "100%" }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      )}

      {searchBy === "skulist" && (
        <Grid container alignItems="center">
          <Grid item xs={10} p={1} sx={{ position: "relative" }}>
            <TextField
              size="small"
              multiline
              maxRows={5}
              value={skulist}
              placeholder="Enter SKUs"
              onChange={handleChangeSkuList}
              sx={{
                width: "100%",
                fontSize: "0.8rem",
                "& legend": {
                  width: "unset",
                },
              }}
            />

            {skulist === "" ? (
              ""
            ) : (
              <Chip
                size="small"
                color="primary"
                variant="outlined"
                label={`${skulist.split(/\r\n|\r|\n/).length} Items`}
                sx={{
                  height: 20,
                  position: "absolute",
                  transform:
                    skulist.split(/\r\n|\r|\n/).length <= 1
                      ? "translate(-95px, 3px)"
                      : "translate(-95px, 6px)",
                }}
              />
            )}
          </Grid>

          <Grid item xs={2} p={1}>
            <Button
              variant="contained"
              disabled={skulist === ""}
              onClick={handleSearchBySku}
              sx={{ width: "100%" }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      )}

      {searchBy === "vendor" && (
        <Grid container alignItems="center">
          <Grid item xs={10} p={1}>
            <Select
              size="small"
              value={selectedVendor}
              onChange={(event) => setSelectedVendor(event.target.value)}
              sx={{
                width: "100%",
                "& legend": {
                  width: "unset",
                },
              }}
            >
              {vendors.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>

          <Grid item xs={2} p={1}>
            <Button
              variant="contained"
              disabled={selectedVendor === ""}
              onClick={handleSearchByVendor}
              sx={{ width: "100%" }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      )}

      {koganProducts.length >= 0 && (
        <Grid container justifyContent="flex-end" alignItems="center">
          <Pagination page={page} total_counts={total_counts} />
        </Grid>
      )}

      <CustomizedSnackbars
        handleClose={handleCloseSnackbars}
        open={openSnackbars.open}
        severity={openSnackbars.severity}
        message={openSnackbars.message}
      />
    </>
  );
};

export default KoganProductUpdaterTextfieldsGroup;
