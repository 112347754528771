import { createTheme } from "@mui/material/styles";

export const baseTheme = {
  typography: {
    fontFamily: "sans-serif",

    // body1: {
    //   fontSize: "",
    // },

    // body2: {
    //   fontSize: "",
    // },
  },

  // shadows: ["none"],
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // boxShadow: "none",
        },
      },
    },
  },
};

export const lightTheme = {
  palette: {
    mode: "light",

    neutral: {
      50: "#F9FAFB",
      100: "#F2F4F7",
      200: "#EAECF0",
      300: "#D0D5DD",
      400: "#98A2B3",
      500: "#667085",
      600: "#475467",
      700: "#344054",
      800: "#1D2939",
      900: "#101828",
      main: "#101828",
    },

    border: "#D0D5DD",
    disabled: "#D0D5DD",

    bg: {
      default: "#f2f7ff",
      paper: "#FFFFFF",
      grayBG: "#EAECF0",
    },

    primary: {
      contrast: "#FFFFFF",
      light: "#33B5E8",
      main: "#2F64AF",
      dark: "#190E45",
      50: "#F2E3FB",
      100: "#E5C9F6",
      200: "#D8AEF2",
      300: "#CC95EE",
      400: "#C07DE9",
      500: "#B465E5",
      600: "#A94EE1",
      700: "#9D38DC",
      800: "#9323D8",
    },

    secondary: {
      main: "#33B5E8",
      dark: "#2F64AF",
    },

    text: {
      primary: "#101828",
      contrast: "#FFFFFF",
    },

    success: {
      contrast: "#FFFFFF",
      dark: "#1B774D",
      light: "#52BB8B",
      main: "#27AB6E",
    },

    error: {
      contrast: "#FFFFFF",
      dark: "#A53531",
      light: "#EF6F6B",
      main: "#E62E04",
    },

    warning: {
      contrast: "#FFFFFF",
      dark: "#975B1E",
      light: "#E09B55",
      main: "#D9822B",
    },

    info: {
      contrast: "#FFFFFF",
      dark: "#0B4E8D",
      light: "#3F8CD4",
      main: "#1070CA",
    },
  },
};

export const darkTheme = {
  palette: {
    mode: "dark",

    neutral: {
      50: "#000000",
      100: "#D8DADE",
      200: "#BDBFC7",
      300: "#A1A4AF",
      400: "#868998",
      500: "#6C6F7F",
      600: "#545863",
      700: "#40444F",
      800: "#2B2F3C",
      900: "#1E212A",
      main: "#1E212A",
    },

    border: "#D8DADE",
    disabled: "#D0D5DD",

    bg: {
      default: "#171A21",
      paper: "#1E212A",
      grayBG: "#6C6F7F",
    },

    primary: {
      contrast: "#FFFFFF",
      light: "#85C1E9",
      main: "#1976d2",
      dark: "#0d47a1",
      50: "#F2E3FB",
      100: "#E5C9F6",
      200: "#D8AEF2",
      300: "#CC95EE",
      400: "#C07DE9",
      500: "#B465E5",
      600: "#A94EE1",
      700: "#9D38DC",
      800: "#9323D8",
    },

    secondary: {
      main: "#0d47a1",
      dark: "#33B5E8",
    },

    text: {
      primary: "#E1E3EA",
      contrast: "#FFFFFF",
    },

    success: {
      contrast: "#FFFFFF",
      dark: "#2F6B39",
      light: "#5FC16D",
      main: "#38B249",
    },

    error: {
      contrast: "#FFFFFF",
      dark: "#85372B",
      light: "#EB6D57",
      main: "#E6492D",
    },

    warning: {
      contrast: "#FFFFFF",
      dark: "#8D682D",
      light: "#F7BB57",
      main: "#F6AB2E",
    },

    info: {
      contrast: "#FFFFFF",
      dark: "#1D4580",
      light: "#4483DF",
      main: "#1664D8",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#1E212A",
        },
      },
    },

    MuiGrid: {
      styleOverrides: {
        root: {
          backgroundColor: "#1E212A",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
  },
};
