import React from 'react';
import "./checkbox.css"

export default function ControlledCheckbox({ product, setSelectedProducts, selectedProducts }) {
  const handleChange = (event) => {
    if (event.target.checked === true) {
      let array = [...selectedProducts]
      array.push(product)
      setSelectedProducts(array)
    }
    if (event.target.checked === false) {
      let array = [...selectedProducts]
      let index = array.indexOf(product)
      array.splice(index, 1)
      setSelectedProducts(array)
    }
  };

  return (
    <input type="checkbox" onChange={handleChange} id={product.id} />
  );
}
