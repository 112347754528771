import React, { useState, useEffect } from "react";
import { TextField, Autocomplete } from "@mui/material";

const DropshipVendorIdFilter = ({
  selectedDropshipVendorID,
  setSelectedDropshipVendorID,
}) => {
  const [vendorIDs, setVendorIDs] = useState([]);

  const defaultProps4 = {
    options: vendorIDs,
    getOptionLabel: (option) => option,
  };

  useEffect(() => {
    fetch("https://api.saleprodev.com/productimporter/dropshipvendors")
      .then((response) => response.json())
      .then((response) => {
        console.log("VendorIDs", response);
        let index = response.vendors.indexOf("dropship");
        if (index !== -1) {
          response.vendors[index] = "Dropship";
          response.vendors.unshift(response.vendors.splice(index, 1)[0]);
        }

        setVendorIDs(response.vendors);
      });
  }, []);

  const handleChangeVendorID = (event, value) => {
    console.log("VendorID", value);
    if (value === null) {
      setSelectedDropshipVendorID("");
    } else {
      setSelectedDropshipVendorID(value);
    }
  };

  return (
    <Autocomplete
      size="small"
      sx={{
        mr: 0.5,
        width: "200px",
        height: 26,
        fontSize: "0.8rem",
        "& legend": {
          width: "unset",
        },
        "& .MuiFormControl-root, .MuiOutlinedInput-root": {
          p: "0px!important",
          height: 26,
        },
      }}
      {...defaultProps4}
      value={selectedDropshipVendorID}
      onChange={(event, value) => handleChangeVendorID(event, value)}
      renderInput={(params) => (
        <TextField {...params} placeholder="Select a Vendor ID" />
      )}
    />
  );
};

export default DropshipVendorIdFilter;
