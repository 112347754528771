import * as React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  setSearch,
  setArticles,
  setSubsettings,
  setOrdersNzShopify,
  setSelectedSupplier,
  setDropshipOrders,
  setOrderToDate,
  setOrderFromDate,
} from "../../actions";

export default function NativePickers() {
  const order_from_date = useSelector((state) => state.order_from_date);
  const order_to_date = useSelector((state) => state.order_to_date);
  const dispatch = useDispatch();
  // console.log(order_from_date, order_to_date);
  return (
    <>
      <Grid item my={0.5} mx={1.2}>
        <TextField
          id="datetime-local"
          label="From"
          type="datetime-local"
          defaultValue=""
          size="small"
          InputProps={{
            sx: {
              height: 26,
              fontSize: "0.8rem",
              // "& legend": {
              //   width: "unset",
              // },
            },
          }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => dispatch(setOrderFromDate(event.target.value))}
        />
      </Grid>

      <Grid item my={0.5} mx={1.2}>
        <TextField
          id="datetime-local"
          label="To"
          size="small"
          type="datetime-local"
          defaultValue=""
          InputProps={{
            sx: {
              height: 26,
              fontSize: "0.8rem",
              // "& legend": {
              //   width: "unset",
              // },
            },
          }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => dispatch(setOrderToDate(event.target.value))}
        />
      </Grid>
    </>
  );
}
