import React, { useEffect, useState } from "react";
// import PaginationOutlined_listings from "./pagination.js";

import ScrollToTopOrBottom from "../tools/scrolltotoporbottom.js";
import { ListingsSideMenu } from "./ListingsSideMenu";
import { ListingsQuickFilter } from "./ListingsQuickFilter";
import { Loader } from "../common/Loader";
import Pagination from "../common/Pagination.js";

import {
  Grid,
  Card,
  Button,
  Breadcrumbs,
  Typography,
  CardHeader,
  CardContent,
  List,
  ListSubheader,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import Format from "./format.js";
import FormatKogan from "./formatkogan.js";
import Filters from "./filters.js";
import MainCheckbox from "./maincheckbox.js";
// import KoganCategorySelect from "./../sourcing/kogancategoryselect.js";
import KoganCategorySelect2 from "./../sourcing/kogancategoryselect2.js";
import CustomizedSnackbars from "./notification.js";
// import LocationSelect from "./locationselect.js"
import {
  setSearch,
  setStore,
  setListingStatus,
  setSearchFilterListings,
  setSelectedStore,
  setProductsPerPage,
  setImportPage,
  setImportTotalPages,
  setTotalCounts,
  setLoadingProducts,
  setArticles,
  setSubsettings,
  setToken,
  setLoading,
  setCountrylist,
  setShippingMethods,
  setShowImportedProductsFilter,
  setCategoriesLevel1,
  setCategoriesLevel2,
  setCategoriesLevel3,
  setCategoriesLevel4,
  setCategoriesLevel5,
  setCategoriesLevel6,
  setImportedProducts,
  setImportedProductsFilter,
  setSelectedCategoryLevel2,
  setSelectedCategoryLevel3,
  setSelectedCategoryLevel4,
  setSelectedCategoryLevel5,
  setSelectedCategoryLevel6,
} from "../../actions";
import { useSelector, useDispatch } from "react-redux";

export default function ShopifyProducts({ page }) {
  const products_per_page = useSelector((state) => state.products_per_page);
  const dispatch = useDispatch();
  const selected_supplier = useSelector((state) => state.selected_supplier);
  const total_counts = useSelector((state) => state.total_counts);
  const [koganCategoryLevel1, setKoganCategoryLevel1] =
    React.useState("Select a Category");
  const [koganCategoryLevel2, setKoganCategoryLevel2] =
    React.useState("Select a Category");
  const [products_nz_shopify, setProductsNZShopify] = useState([]);
  const [products_au_shopify, setProductsAUShopify] = useState([]);
  const [products_us_shopify, setProductsUSShopify] = useState([]);
  const [products_gk_shopify, setProductsGKShopify] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [products_kogan, setProductsKogan] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const import_page = useSelector((state) => state.import_page);
  const store = useSelector((state) => state.store);
  const import_total_pages = useSelector((state) => state.import_total_pages);
  const selected_store = useSelector((state) => state.selected_store);
  const searchField = useSelector((state) => state.searchField);
  const search_filter_listings = useSelector(
    (state) => state.search_filter_listings
  );
  const [category, setCategory] = React.useState("");
  // const [status, setStatus] = React.useState("");
  const listingStatus = useSelector((state) => state.listing_status);
  const loading_products = useSelector((state) => state.loading_products);

  const [location, setLocation] = React.useState("Select Location");
  const [openNotification, setOpenNotification] = React.useState({
    open: false,
    severity: "",
    message: "",
  });
  const handleCloseNot = () =>
    setOpenNotification({ open: false, severity: "", message: "" });

  const [selected_vendor, setSelectedVendor] = useState([]);
  const [selected_tag, setSelectedTag] = useState([]);

  useEffect(() => {
    dispatch(setLoadingProducts(true));

    if (store === "nzshopify") {
      if (listingStatus === "deleted") {
        fetch(
          "https://api.saleprodev.com/productimporter/fetchdeletedproducts",
          {
            method: "post",
            body: JSON.stringify({
              supplier: selected_supplier,
              page: import_page,
              products_per_page: products_per_page,
              store: store,
              search: searchField,
            }),
          }
        )
          .then((response) => response.json())
          .then((response) => {
            console.log("NZShopifyDeletedListings", response)
            setProductsNZShopify(response);
            dispatch(setLoadingProducts(false));
          });
      } else {
        fetch("https://api.saleprodev.com/getnzshopifyproducts", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            supplier: selected_supplier,
            page: import_page,
            products_per_page: products_per_page,
            search: searchField,
            filter: search_filter_listings,
            status: listingStatus,
            vendor: selected_vendor,
            tags: selected_tag,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            console.log("NZShopifyListings", response)
            setProductsNZShopify(response);
            dispatch(setLoadingProducts(false));
          });
      }
    }

    if (store === "aushopify") {
      if (listingStatus === "deleted") {
        fetch(
          "https://api.saleprodev.com/productimporter/fetchdeletedproducts",
          {
            method: "post",
            body: JSON.stringify({
              supplier: selected_supplier,
              page: import_page,
              products_per_page: products_per_page,
              store: store,
              search: searchField,
            }),
          }
        )
          .then((response) => response.json())
          .then((response) => {
            console.log("AUShopifyDeletedListings", response)
            setProductsAUShopify(response);
            dispatch(setLoadingProducts(false));
          });
      } else {
        fetch("https://api.saleprodev.com/getaushopifyproducts", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            supplier: selected_supplier,
            page: import_page,
            products_per_page: products_per_page,
            search: searchField,
            filter: search_filter_listings,
            status: listingStatus,
            vendor: selected_vendor,
            tags: selected_tag,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            console.log("AUShopifyListings", response)
            setProductsAUShopify(response);
            dispatch(setLoadingProducts(false));
          });
      }
    }

    if (store === "usshopify") {
      if (listingStatus === "deleted") {
        fetch(
          "https://api.saleprodev.com/productimporter/fetchdeletedproducts",
          {
            method: "post",
            body: JSON.stringify({
              supplier: selected_supplier,
              page: import_page,
              products_per_page: products_per_page,
              store: store,
              search: searchField,
            }),
          }
        )
          .then((response) => response.json())
          .then((response) => {
            console.log("USShopifyDeletedListings", response)
            setProductsUSShopify(response);
            dispatch(setLoadingProducts(false));
          });
      } else {
        fetch("https://api.saleprodev.com/getusshopifyproducts", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            supplier: selected_supplier,
            page: import_page,
            products_per_page: products_per_page,
            search: searchField,
            filter: search_filter_listings,
            status: listingStatus,
            vendor: selected_vendor,
            tags: selected_tag,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            console.log("USShopifyListings", response)
            setProductsUSShopify(response);
            dispatch(setLoadingProducts(false));
          });
      }
    }

    if (store === "gkshopify") {
      if (listingStatus === "deleted") {
        fetch(
          "https://api.saleprodev.com/productimporter/fetchdeletedproducts",
          {
            method: "post",
            body: JSON.stringify({
              supplier: selected_supplier,
              page: import_page,
              products_per_page: products_per_page,
              store: store,
              search: searchField,
            }),
          }
        )
          .then((response) => response.json())
          .then((response) => {
            console.log("GKShopifyDeletedListings", response)
            setProductsGKShopify(response);
            dispatch(setLoadingProducts(false));
          });
      } else {
        fetch("https://api.saleprodev.com/getgkshopifyproducts", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            supplier: selected_supplier,
            page: import_page,
            products_per_page: products_per_page,
            search: searchField,
            filter: search_filter_listings,
            status: listingStatus,
            vendor: selected_vendor,
            tags: selected_tag,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            console.log("GKShopifyListings", response)
            setProductsGKShopify(response);
            dispatch(setLoadingProducts(false));
          });
      }
    }

    if (store === "kogan") {
      if (listingStatus === "deleted") {
        fetch(
          "https://api.saleprodev.com/productimporter/fetchdeletedproducts",
          {
            method: "post",
            body: JSON.stringify({
              supplier: selected_supplier,
              page: import_page,
              products_per_page: products_per_page,
              store: store,
              search: searchField,
            }),
          }
        )
          .then((response) => response.json())
          .then((response) => {
            console.log("KoganDeletedListings", response)
            setProductsKogan(response);
            dispatch(setLoadingProducts(false));
          });
      } else {
        fetch("https://api.saleprodev.com/productimporter/getkoganproducts", {
          method: "get",
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => response.json())
          .then((response) => {
            console.log("KoganListings", response)
            setProductsKogan(response);
            dispatch(setLoadingProducts(false));
          });
      }
    }
  }, [
    products_per_page,
    import_page,
    store,
    searchField,
    search_filter_listings,
    listingStatus,
    selected_vendor,
    selected_tag,
    selected_supplier,
  ]);

  useEffect(() => {
    if (store === "nzshopify") {
      dispatch(setTotalCounts(products_nz_shopify.count));

      dispatch(
        setImportTotalPages(
          parseInt(products_nz_shopify.count / products_per_page + 1)
        )
      );
    }
    if (store === "aushopify") {
      dispatch(setTotalCounts(products_au_shopify.count));

      dispatch(
        setImportTotalPages(
          parseInt(products_au_shopify.count / products_per_page + 1)
        )
      );
    }
    if (store === "usshopify") {
      dispatch(setTotalCounts(products_us_shopify.count));

      dispatch(
        setImportTotalPages(
          parseInt(products_us_shopify.count / products_per_page + 1)
        )
      );
    }
    if (store === "gkshopify") {
      dispatch(setTotalCounts(products_gk_shopify.count));

      dispatch(
        setImportTotalPages(
          parseInt(products_gk_shopify.count / products_per_page + 1)
        )
      );
    }
    if (store === "kogan") {
      dispatch(setTotalCounts(products_kogan.count));

      dispatch(
        setImportTotalPages(
          parseInt(products_kogan.count / products_per_page + 1)
        )
      );
    }
  }, [products_nz_shopify, products_au_shopify, products_us_shopify, products_gk_shopify, products_kogan, store]);

  useEffect(() => {
    if (
      koganCategoryLevel1 !== "Select a Category" &&
      koganCategoryLevel2 !== "Select a Category"
    ) {
      fetch("https://api.saleprodev.com/productimporter/kogancategory", {
        method: "post",
        body: JSON.stringify({
          category1: koganCategoryLevel1,
          category2: koganCategoryLevel2,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setCategory(response);
        });
    }
  }, [koganCategoryLevel1, koganCategoryLevel2]);

  const handleSendToKogan = () => {
    if (category !== "" && location != "Select Location") {
      selectedProducts.map((product, index) => {
        const data = () => {
          fetch("https://api.saleprodev.com/productimporter/sendtokogan", {
            method: "post",
            body: JSON.stringify({
              product: product,
              category: category,
              location: location,
            }),
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.product_sku) {
                setOpenNotification({
                  open: true,
                  severity: "success",
                  message: "Success",
                });
              } else {
                setOpenNotification({
                  open: true,
                  severity: "error",
                  message: "Failed",
                });
              }
            });
        };

        setTimeout(data, index * 100);
      });
    } else {
      alert("Category or Location is missing");
    }
  };

  const handleSendToKogan2 = () => {
    console.log("here");
    let products = [];
    if (category !== "" && location != "Select Location") {
      selectedProducts.map((product, index) => {
        let images = [];

        product.images.map((item) => {
          if (images.length < 10) {
            images.push(item.src);
          }
        });

        let brand = "Unbranded";

        let tags = product.tags.toLowerCase();
        tags = tags.split(",");

        tags.map((item) => {
          if (item.includes("brand")) {
            let array = item.split("_");
            if (array.length > 1) {
              brand = array[1];
            }
          }
        });
        if (product.variants.length > 1) {
          product.variants.map((varian, index) => {
            let variant_images = [];
            if (
              product.images.filter((item) => item.id === varian.image_id)
                .length !== 0
            ) {
              variant_images.push(
                product.images.filter((item) => item.id === varian.image_id)[0]
                  .src
              );
            }
            let arr = [];
            product.options.map((item, indi) => {
              if (indi === 0) {
                arr.push({ type: item.name, value: varian.option1 });
              }
              if (indi === 1) {
                arr.push({ type: item.name, value: varian.option2 });
              }
              if (indi === 2) {
                arr.push({ type: item.name, value: varian.option3 });
              }
            });
            let facets = [
              {
                group: "General",
                items: arr,
              },
            ];
            let offer_data = {
              NZD: {
                handling_days: 2,
                kogan_first_price: null,
                price: varian.price,
                rrp: null,
                shipping: "0.00",
                tax_exempt: false,
              },
            };

            let variant = {
              group_title: product.title,
              group_id: String(varian.product_id),
            };

            facets.map((facet, index) => {
              if (index === 0) {
                variant.vary_on = {
                  group: facet.group,
                  type: facet.items[0].type,
                };
              }
              if (index === 1) {
                variant.vary_on_2 = {
                  group: facet.group,
                  type: facet.items[1].type,
                };
              }
              if (index === 2) {
                variant.vary_on_3 = {
                  group: facet.group,
                  type: facet.items[2].type,
                };
              }
            });

            let format = {
              product_sku: varian.sku,
              product_title: product.title,
              product_subtitle: "",
              product_description: product.body_html,
              product_inbox: "",
              product_gtin: varian.barcode,
              product_location: "NZ",
              product_condition: "new",
              product_multipack: 1,
              brand: brand,
              category: category,
              images: variant_images,
              stock: varian.inventory_quantity,
              enabled: true,
              offer_data: offer_data,
              facets: facets,
              variant: variant,
            };
            console.log(format);
            products.push(format);
          });
        } else {
          product.variants.map((varian, index) => {
            let variant_images = [];
            if (
              product.images.filter((item) => item.id === varian.image_id)
                .length !== 0
            ) {
              variant_images.push(
                product.images.filter((item) => item.id === varian.image_id)[0]
                  .src
              );
            }
            let arr = [];
            product.options.map((item, indi) => {
              if (indi === 0) {
                arr.push({ type: item.name, value: varian.option1 });
              }
              if (indi === 1) {
                arr.push({ type: item.name, value: varian.option2 });
              }
              if (indi === 2) {
                arr.push({ type: item.name, value: varian.option3 });
              }
            });
            let facets = [
              {
                group: "General",
                items: arr,
              },
            ];
            let offer_data = {
              NZD: {
                handling_days: 3,
                kogan_first_price: null,
                price: varian.price,
                rrp: null,
                shipping: "0.00",
                tax_exempt: false,
              },
            };

            let variant = {
              group_title: product.title,
              group_id: varian.product_id,
            };

            facets.map((facet, index) => {
              if (index === 0) {
                variant.vary_on = {
                  group: facet.group,
                  type: facet.items[0].type,
                };
              }
              if (index === 1) {
                variant.vary_on_2 = {
                  group: facet.group,
                  type: facet.items[1].type,
                };
              }
              if (index === 2) {
                variant.vary_on_3 = {
                  group: facet.group,
                  type: facet.items[2].type,
                };
              }
            });

            let format = {
              product_sku: varian.sku,
              product_title: product.title,
              product_subtitle: "",
              product_description: product.body_html,
              product_inbox: "",
              product_gtin: varian.barcode,
              product_location: location,
              product_condition: "new",
              product_multipack: 1,
              brand: brand,
              category: category,
              images: images,
              stock: varian.inventory_quantity,
              enabled: true,
              offer_data: offer_data,
            };
            console.log(format);
            products.push(format);
          });
        }
      });
      console.log("products", products);
      fetch("https://api.saleprodev.com/productimporter/createkoganproducts", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ products: products }),
      })
        .then((response) => response.json())
        .then((response) => console.log(response));
    } else {
      alert("Category or Location is missing");
    }
    // }
  };

  return (
    <div style={{ overflowX: "auto", overflowY: "hidden" }}>
      <Grid container id="content" xs={12} sx={{ minWidth: "1920px" }} p={1}>
        {/* Left Side Menu */}
        <ListingsSideMenu />

        {/* Right Side Content */}
        <Grid item xs={11} pl={1}>
          {/* Breadcrumb */}
          <Grid item>
            <Card elevation={0} sx={{ px: 2, py: 0.5 }}>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography
                  variant="body2"
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                  onClick={(event) => dispatch(setListingStatus(""))}
                >
                  {page.charAt(0).toUpperCase() + page.slice(1)}
                </Typography>

                <Typography variant="body2">
                  {listingStatus === "" && "All Listings"}
                  {listingStatus === "active" && "Active Listings"}
                  {listingStatus === "draft" && "Draft Listings"}
                  {listingStatus === "exported" && "Exported Listings"}
                  {listingStatus === "deleted" && "Deleted Listings"}
                </Typography>
              </Breadcrumbs>
            </Card>
          </Grid>

          {/* Quick Filter */}
          <Grid item my={1}>
            <ListingsQuickFilter />
          </Grid>

          {/* Pagination & Filter */}
          <Grid item container xs={12} justifyContent="flex-end" alignItems="center" my={1}>
            {/* <PaginationOutlined_listings /> */}
            <Pagination
              page={page}
              total_counts={total_counts}
              selectedOrders={selectedProducts}
              setSelectedOrders={setSelectedProducts}
            />
          </Grid>

          {/* SEND TO KOGAN */}
          <Grid item container xs={12} >
            <Grid item container xs={6} alignItems="center">
              {selectedProducts && selectedProducts.length !== 0 ? (
                <Button onClick={(event) => handleSendToKogan2()}
                >
                  SEND TO KOGAN
                </Button>
              ) : (
                <Button disabled>
                  SEND TO KOGAN
                </Button>
              )}

              {selectedProducts && selectedProducts.length !== 0 &&
                <KoganCategorySelect2
                  koganCategoryLevel1={koganCategoryLevel1}
                  koganCategoryLevel2={koganCategoryLevel2}
                  setKoganCategoryLevel1={setKoganCategoryLevel1}
                  setKoganCategoryLevel2={setKoganCategoryLevel2}
                />
              }
            </Grid>

            <Grid item container xs={6} justifyContent="flex-end" alignItems="center">
              <Filters
                selected_vendor={selected_vendor}
                setSelectedVendor={setSelectedVendor}
                selected_tag={selected_tag}
                setSelectedTag={setSelectedTag}
              />
            </Grid>

          </Grid>

          {/* Listings: Product Card */}
          <Grid item container>
            <Grid item xs={12}>
              <Card elevation={0} sx={{ width: "100%", mt: 1 }}>
                <Grid
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "2% 4% 34% 15% 15% 10% 10% 5% 5%",
                    height: "2.6rem",
                    backgroundColor: "bg.grayBG",
                    alignItems: "center",
                  }}
                >
                  <Grid container justifyContent="center">
                    {store === "nzshopify" &&
                      products_nz_shopify.products && <MainCheckbox
                        products={products_nz_shopify.products}
                        setSelectedProducts={setSelectedProducts}
                      />
                    }

                    {store === "aushopify" &&
                      products_au_shopify.products && <MainCheckbox
                        products={products_au_shopify.products}
                        setSelectedProducts={setSelectedProducts}
                      />
                    }

                    {store === "usshopify" &&
                      products_us_shopify.products && <MainCheckbox
                        products={products_us_shopify.products}
                        setSelectedProducts={setSelectedProducts}
                      />
                    }

                    {store === "gkshopify" &&
                      products_gk_shopify.products && <MainCheckbox
                        products={products_gk_shopify.products}
                        setSelectedProducts={setSelectedProducts}
                      />
                    }

                    {store === "kogan" &&
                      products_kogan && <MainCheckbox
                        products={products_kogan}
                        setSelectedProducts={setSelectedProducts}
                      />
                    }
                  </Grid>

                  <Grid container justifyContent="center"><Typography variant="body2">Image</Typography></Grid>
                  <Grid container justifyContent="center"><Typography variant="body2">Title</Typography></Grid>
                  <Grid container justifyContent="center"><Typography variant="body2">Variations</Typography></Grid>
                  <Grid container justifyContent="center"><Typography variant="body2">Price</Typography></Grid>
                  <Grid container justifyContent="center"><Typography variant="body2">Vendor</Typography></Grid>
                  <Grid container justifyContent="center"><Typography variant="body2">Stock</Typography></Grid>
                  <Grid container justifyContent="center"><Typography variant="body2">Status</Typography></Grid>
                  <Grid container justifyContent="center"><Typography variant="body2">Action</Typography></Grid>
                </Grid>
              </Card>
            </Grid>

            {loading_products === true ? (
              <Grid
                item
                container
                justifyContent="center"
                alignContent="center"
                minHeight="300px"
              >
                <Loader size="40px" />
              </Grid>
            ) : (
              <>
                {store === "nzshopify" &&
                  products_nz_shopify.products &&
                  products_nz_shopify.products.map((product, index) => {
                    return (
                      <Format
                        key={index}
                        product={product}
                        listingStatus={listingStatus}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                      />
                    );
                  })}
                {store === "aushopify" &&
                  products_au_shopify.products &&
                  products_au_shopify.products.map((product, index) => {
                    return (
                      <Format
                        key={index}
                        product={product}
                        listingStatus={listingStatus}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts} />
                    );
                  })}
                {store === "usshopify" &&
                  products_us_shopify.products &&
                  products_us_shopify.products.map((product, index) => {
                    return (
                      <Format
                        key={index}
                        product={product}
                        listingStatus={listingStatus}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts} />
                    );
                  })}
                {store === "gkshopify" &&
                  products_gk_shopify.products &&
                  products_gk_shopify.products.map((product, index) => {
                    return (
                      <Format
                        key={index}
                        product={product}
                        listingStatus={listingStatus}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts} />
                    );
                  })}
                {store === "kogan" &&
                  products_kogan.map((product, index) => {
                    return <FormatKogan
                      key={index}
                      product={product}
                      selectedProducts={selectedProducts}
                      setSelectedProducts={setSelectedProducts} />;
                  })}
              </>
            )}

            <CustomizedSnackbars
              open={openNotification.open}
              handleClose={handleCloseNot}
              message={openNotification.message}
              severity={openNotification.severity}
            />
            <ScrollToTopOrBottom
              scrollToTopId="listings_side_menu_list"
              scrollToBottomId="footer"
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
