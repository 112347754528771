import { Grid, Link, Typography } from "@mui/material";

const Footer = () => (
  <footer id="footer">
    <Grid
      container
      sx={{
        justifyContent: "center",
        textAlign: "center",
        py: 1,
      }}
    >
      <Typography variant="caption">
        © Copyright 2022 DsBot.com Powered By&nbsp;
        <Link
          href="https://salespro.net.nz/"
          target="_blank"
          underline="none"
          variant="caption"
          sx={{
            display: "inline-block",
            "&:hover": {
              opacity: 0.7,
            },
          }}
        >
          Sales Pro Limited
        </Link>
        . All Rights Reserved. PO Box 6676, Upper Riccarton. 8442. Christchurch.
        New Zealand.
      </Typography>
    </Grid>
  </footer>
);

export default Footer;
