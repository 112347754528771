import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function MultipleSelectCheckmarks({ storestoexport, setStoresToExport }) {
    const store_menu = useSelector((state) => state.store_menu);
    console.log("storestoexportNew", storestoexport);

    const [open, setOpen] = React.useState(false)

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setStoresToExport(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <FormControl size='small' sx={{ my: 1, width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">Store</InputLabel>
            <Select
                open={open}
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={storestoexport}
                onChange={handleChange}
                input={<OutlinedInput label="Store" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={{
                    PaperProps: {
                        onMouseLeave: () => setOpen(false),
                        style: {
                            maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
                            width: 250,
                        },
                    },
                }}
            >
                {store_menu.map((store) => (
                    <MenuItem key={store.value} value={store.value}>
                        <Checkbox checked={storestoexport.indexOf(store.value) > -1} />
                        <ListItemText primary={store.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}