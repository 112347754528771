import React from "react";
import { useDispatch } from "react-redux";

import { Card, Grid, Typography, Icon } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { setSettingsItem } from "../../../actions";

export const ListingSettingsListItem = ({
  item_icon,
  item_code,
  item_title,
  item_desc,
  borderBottom,
}) => {
  const dispatch = useDispatch();
  return (
    <Grid
      item
      sx={{
        display: "grid",
        gridTemplateColumns: "4% 60% 36%",
        alignItems: "center",
      }}
    >
      <Icon
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& svg": {
            width: "26px",
            height: "26px",
          },
        }}
      >
        {item_icon}
      </Icon>

      <Grid
        container
        sx={{
          py: 3,
          display: "grid",
          gridTemplateColumns: "25% 60% 15%",
          alignItems: "center",
          borderBottom: borderBottom
            ? "none"
            : (theme) => `1px solid ${theme.palette.border}`,
        }}
      >
        <Typography variant="body1">{item_title}</Typography>

        <Typography
          variant="body1"
          color={(theme) => theme.palette.neutral[400]}
        >
          {item_desc}
        </Typography>

        <Grid container justifyContent="flex-end">
          <ChevronRightIcon
            sx={{
              cursor: "pointer",
              width: "44px",
              height: "44px",
              "&:hover": {
                color: "primary.main",
              },
            }}
            onClick={() => dispatch(setSettingsItem(item_code))}
          />
        </Grid>
      </Grid>

      <Grid></Grid>
    </Grid>
  );
};

export const ListingSettingsList = ({ listingSettingsItems }) => {
  return (
    <Card elevation={0} sx={{ my: 1, pl: 3 }}>
      {listingSettingsItems.map((item, index) => (
        <ListingSettingsListItem
          key={item.item_code}
          item_icon={item.item_icon}
          item_code={item.item_code}
          item_title={item.item_title}
          item_desc={item.item_desc}
          borderBottom={index === listingSettingsItems.length - 1}
        />
      ))}
    </Card>
  );
};
