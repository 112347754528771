import React, { useEffect, useState } from "react";
import { Grid, Button, Chip, TextField, Autocomplete } from "@mui/material";
import BangoodVariantSelect from "./bangoodvariantselect.js";
import { SmallContainedButton } from "../common/SmallContainedButton";
import { InStockCheckbox } from "./instockcheckbox";

export default function BangoodCategorySelect({
  selectedBangoodCategoryLevel1,
  setSelectedBangoodCategoryLevel1,
  selectedBangoodCategoryLevel2,
  setSelectedBangoodCategoryLevel2,
  selectedBangoodCategoryLevel3,
  setSelectedBangoodCategoryLevel3,
  selectedBangoodCategoryLevel4,
  setSelectedBangoodCategoryLevel4,
  warehouse,
  setWarehouse,
  productvariants,
  setProductVariants,
  skulist,
  setSkuList,
  filterby,
  loading_products,
  fetchproductcount,
  setFetchProductCount,
  setTags,
  setProductType,
}) {
  const [categorieslevel1, setCategoriesLevel1] = useState([]);
  const [categorieslevel2, setCategoriesLevel2] = useState([]);
  const [categorieslevel3, setCategoriesLevel3] = useState([]);
  const [categorieslevel4, setCategoriesLevel4] = useState([]);
  const [categorieslevel5, setCategoriesLevel5] = useState([]);

  const [brands, setBrands] = useState([]);
  useEffect(() => {
    fetch("https://api.saleprodev.com/productimporter/bangoodcategorieslevel1")
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel1(response.categories_level_1);
      });
  }, []);

  useEffect(() => {
    setSelectedBangoodCategoryLevel2("Select a Category");
    setSelectedBangoodCategoryLevel3("Select a Category");
    setSelectedBangoodCategoryLevel4("Select a Category");
    fetch(
      "https://api.saleprodev.com/productimporter/bangoodcategorieslevel2",
      {
        method: "post",
        body: JSON.stringify({ category: selectedBangoodCategoryLevel1 }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel2(response.categories_level_2);
      });
  }, [selectedBangoodCategoryLevel1]);

  useEffect(() => {
    setSelectedBangoodCategoryLevel3("Select a Category");
    setSelectedBangoodCategoryLevel4("Select a Category");
    fetch(
      "https://api.saleprodev.com/productimporter/bangoodcategorieslevel3",
      {
        method: "post",
        body: JSON.stringify({ category: selectedBangoodCategoryLevel2 }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel3(response.categories_level_3);
      });
  }, [selectedBangoodCategoryLevel2]);

  useEffect(() => {
    setSelectedBangoodCategoryLevel4("Select a Category");
    fetch(
      "https://api.saleprodev.com/productimporter/bangoodcategorieslevel4",
      {
        method: "post",
        body: JSON.stringify({ category: selectedBangoodCategoryLevel3 }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel4(response.categories_level_4);
      });
  }, [selectedBangoodCategoryLevel3]);

  const handleChange = (event, value) => {
    console.log("value", value);
    if (value === null) {
      setSelectedBangoodCategoryLevel1("Select a Category");
    } else {
      setSelectedBangoodCategoryLevel1(value);
    }
  };

  const handleChange2 = (event, value) => {
    console.log("value", value);
    if (value === null) {
      setSelectedBangoodCategoryLevel2("Select a Category");
    } else {
      setSelectedBangoodCategoryLevel2(value);
    }
  };

  const handleChange3 = (event, value) => {
    console.log("value", value);
    if (value === null) {
      setSelectedBangoodCategoryLevel3("Select a Category");
    } else {
      setSelectedBangoodCategoryLevel3(value);
    }
  };

  const handleChange4 = (event, value) => {
    console.log("value", value);
    if (value === null) {
      setSelectedBangoodCategoryLevel4("Select a Category");
    } else {
      setSelectedBangoodCategoryLevel4(value);
    }
  };

  const defaultProps1 = {
    options: categorieslevel1,
    getOptionLabel: (option) => option,
  };

  const defaultProps2 = {
    options: categorieslevel2,
    getOptionLabel: (option) => option,
  };

  const defaultProps3 = {
    options: categorieslevel3,
    getOptionLabel: (option) => option,
  };

  const defaultProps4 = {
    options: categorieslevel4,
    getOptionLabel: (option) => option,
  };

  return filterby === "category" ? (
    <>
      <Autocomplete
        {...defaultProps1}
        size="small"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        value={selectedBangoodCategoryLevel1}
        onChange={(event, value) => handleChange(event, value)}
        renderInput={(params) => <TextField {...params} />}
      />
      <Autocomplete
        {...defaultProps2}
        size="small"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        value={selectedBangoodCategoryLevel2}
        onChange={(event, value) => handleChange2(event, value)}
        renderInput={(params) => <TextField {...params} />}
      />
      <Autocomplete
        {...defaultProps3}
        size="small"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        value={selectedBangoodCategoryLevel3}
        onChange={(event, value) => handleChange3(event, value)}
        renderInput={(params) => <TextField {...params} />}
      />
      <Autocomplete
        {...defaultProps4}
        size="small"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        value={selectedBangoodCategoryLevel4}
        onChange={(event, value) => handleChange4(event, value)}
        renderInput={(params) => <TextField {...params} />}
      />

      <TextField
        size="small"
        placeholder="Tag"
        sx={{
          mr: 0.5,
          width: "110px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        onChange={(event) => setTags(event.target.value)}
      />

      <TextField
        size="small"
        placeholder="Type"
        sx={{
          mr: 0.5,
          width: "110px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        onChange={(event) => setProductType(event.target.value)}
      />

      <InStockCheckbox />
    </>
  ) : (
    <>
      <Grid item sx={{ position: "relative" }}>
        <TextField
          multiline
          maxRows={5}
          placeholder="Enter SKUs"
          // skulist={skulist}
          // setSkuList={setSkuList}
          onChange={(event) => setSkuList(event.target.value)}
          sx={{
            mr: 0.5,
            width: "500px",
            fontSize: "0.8rem",
            "& legend": {
              width: "unset",
            },
            "& .MuiFormControl-root, .MuiOutlinedInput-root": {
              minHeight: 26,
              p: "0px!important",
            },
          }}
        />

        {skulist === "" ? (
          ""
        ) : (
          <Chip
            size="small"
            color="primary"
            variant="outlined"
            label={`${skulist.split(/\r\n|\r|\n/).length} Items`}
            sx={{
              height: 20,
              position: "absolute",
              transform:
                skulist.split(/\r\n|\r|\n/).length <= 1
                  ? "translate(-95px, 3px)"
                  : "translate(-95px, 6px)",
            }}
          />
        )}
      </Grid>

      <TextField
        size="small"
        placeholder="Tag"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        onChange={(event) => setTags(event.target.value)}
      />

      <TextField
        size="small"
        placeholder="Type"
        onChange={(event) => setProductType(event.target.value)}
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
      />

      <SmallContainedButton
        width="60px"
        disabled={loading_products ? true : false}
        onClick={(event) => setFetchProductCount(fetchproductcount + 1)}
      >
        Find
      </SmallContainedButton>
    </>
  );
}
