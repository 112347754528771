import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, Card, Checkbox, FormControlLabel, Radio, RadioGroup, FormLabel } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PaginationOutlined_orders from "./pagination.js";
import CustomizedSnackbars from "./notification.js";
import { useSelector, useDispatch } from "react-redux";
import {
  setSearch,
  setArticles,
  setSubsettings,
  setToken,
  setCountrylist,
  setSelectedCountryShipFrom,
  setSelectedCountryShipTo,
  setSelectedShippingMethod,
  setShippingMethods,
  setOrdersNzShopify,
  setImportPage,
} from "../../actions";
import { TramRounded } from "@mui/icons-material";
import { Loader } from "../common/Loader.js";

export default function Format({
  orderId,
  product,
  setOpen,
  setCount,
  count,
  count3,
  status,
  page_name,
  setCount3,
  setAliVariantName,
  handleCloseMapVariantModal,
  setOpenMapVariantModal,
  setShopifySku,
  allOrders,
  index,
  indexM,
  setIndexm
}) {
  const orders_nz_shopify = useSelector((state) => state.orders_nz_shopify);
  const import_page = useSelector((state) => state.import_page);

  const [singleOrder, setSingleOrder] = useState();
  const [selected, setSelected] = useState({
    id: "",
    sku: "",
    imageUrl: null,
  });
  const[load, setLoad] = useState(false);
  const [image, setImage] = useState("");
  const store = useSelector((state) => state.store);
  const selected_supplier = useSelector((state) => state.selected_supplier);

  const dispatch = useDispatch();

  const [currentStatus, setCurrentStatus] = useState("");
  const [variantStatus, setVariantStatus] = useState("permanent");
  const [isImageOverrideAvailable, setIsImageOverrideAvailable] =
    useState(false);
  const [isVariantOverrideAvailable, setIsVariantOverrideAvailable] =
    useState(false);
  const [isChangeInVarintStatus, setIsChangeInVarintStatus] = useState(false);

  const handleClick = (id, supplier_sku, sku, item) => {
    setSelected({ id: id, supplier_sku: supplier_sku, sku: sku, item: item });
  };

  const [checkImageOverride, setCheckImageOverride] = useState(false);
  const handleChangeImageOverrideCheckbox = (event) => {
    if (selected.item === undefined) {
      alert("Please select the variant to override the image.");
      return;
    }
    setCheckImageOverride(event.target.checked);
    setIsImageOverrideAvailable(event.target.checked);
    setCurrentStatus("Image Override");
  };

  console.log("jhfdgfhgdf--->> ", indexM, index)

  const [checkVariantOverride, setCheckVariantOverride] = useState(false);
  const handleChangeVariantOverrideCheckbox = (event) => {
    if (selected.item === undefined) {
      alert("Please select the variant to override.");
      return;
    }
    setCheckVariantOverride(event.target.checked);
    setIsVariantOverrideAvailable(event.target.checked);
    setCurrentStatus("Variant Override");
  };

  const [checkImageMissing, setCheckImageMissing] = useState(false);
  const handleChangeImageMissingCheckbox = (event) => {
    setCheckImageMissing(event.target.checked);
    setIsChangeInVarintStatus(event.target.checked);
    setCurrentStatus("Image Missing");
    setSelected({
      ...selected,
      supplier_sku: product.supplier_sku,
      sku: product.sku,
    });
  };

  const handleVarient = (e) => 
  {
    setVariantStatus(e.target.value)
  }

  const [checkProductMissing, setCheckProductMissing] = useState(false);
  const handleChangeProductMissingCheckbox = (event) => {
    setCheckProductMissing(event.target.checked);
    setIsChangeInVarintStatus(event.target.checked);
    setCurrentStatus("Product Missing");
    setSelected({
      ...selected,
      supplier_sku: product.supplier_sku,
      sku: product.sku,
    });
  };

  const [checkDiscontinued, setCheckDiscontinued] = useState(false);
  const handleChangeDiscontinuedCheckbox = (event) => {
    setCheckDiscontinued(event.target.checked);
    setIsChangeInVarintStatus(event.target.checked);
    setCurrentStatus("Discontinued By AliExpress");
    setSelected({
      ...selected,
      supplier_sku: product.supplier_sku,
      sku: product.sku,
    });
  };

  console.log("product999", product);
  console.log("page_name", page_name);

  const handleSaveMappingVariant = () => {
    setLoad(true)
    if(!indexM)
    {
        setIndexm(index+1)
        setShopifySku(JSON.parse(orders_nz_shopify[index+1].line_items)[0].sku); 
    }else{
      setIndexm(indexM+1)
      setShopifySku(JSON.parse(orders_nz_shopify[indexM].line_items)[0].sku); 
    }
   

    let variantName = "";
    let imageURL = null;
    let option1 = null;
    let option2 = null;
    let option3 = null;

    if (selected.item !== undefined) {
      let sku_properties =
        selected.item.ae_sku_property_dtos.ae_sku_property_d_t_o.filter(
          (item) => item.sku_property_name !== "Ships From"
        );

      imageURL =
        selected.item.ae_sku_property_dtos.ae_sku_property_d_t_o[0].sku_image;

      //Set the variant name
      variantName = sku_properties.map((item, index) => {
        if (item.sku_property_name !== "Ships From") {
          if (index === 0) {
            option1 = item.property_value_definition_name
              ? item.property_value_definition_name
              : item.sku_property_value;
          }
          if (index === 1) {
            option2 = item.property_value_definition_name
              ? item.property_value_definition_name
              : item.sku_property_value;
          }
          if (index === 2) {
            option3 = item.property_value_definition_name
              ? item.property_value_definition_name
              : item.sku_property_value;
          }
        }
      });
    }

    if (option1 !== null) {
      variantName = option1;
    }
    if (option2 !== null) {
      variantName = variantName + "/" + option2;
    }
    if (option3 !== null) {
      variantName = variantName + "/" + option3;
    }

    console.log("variantName", variantName);

    let endpoint;
    if (store === "nzshopify") {
      endpoint = "https://aliscript.saleprodev.com/aliexpressmapvariant";
    }

    if (store === "aushopify") {
      endpoint = "https://aliscript.saleprodev.com/aliexpressmapvariantau";
    }

    if (store === "usshopify") {
      endpoint = "https://aliscript.saleprodev.com/aliexpressmapvariantus";
    }

    if (store === "gkshopify") {
      endpoint = "https://aliscript.saleprodev.com/aliexpressmapvariantgk";
    }

    console.log("body", {
      id: selected.id,
      orderid: page_name === "order" ? orderId : null,
      supplier_sku: selected.supplier_sku,
      sku: selected.sku,
      ali_variantname: variantName,
      page_name,
      status,
      imageUrl: isImageOverrideAvailable === true ? imageURL : null,
      currentStatus,
      isImageOverrideAvailable,
      isVariantOverrideAvailable,
      isChangeInVarintStatus,
    });

    fetch(endpoint, {
      method: "post",
      body: JSON.stringify({
        id: selected.id,
        orderid: page_name === "order" ? orderId : null,
        supplier_sku: selected.supplier_sku,
        sku: selected.sku,
        ali_variantname: variantName,
        page_name,
        status,
        imageUrl: isImageOverrideAvailable === true ? imageURL : null,
        currentStatus,
        isImageOverrideAvailable,
        isVariantOverrideAvailable,
        isChangeInVarintStatus,
        variantStatus
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("response", response);
        setOpen({ open: true, message: "Success" });
        setCount(count + 1);
        setCount3(count3 + 1);
        handleCloseMapVariantModal();
        setAliVariantName(variantName);
        dispatch(setImportPage(parseInt(import_page) + 1));

        //Logic for orders page
        if (response.order && response.order !== null) {
          let index = orders_nz_shopify.findIndex(
            (order) => order.orderid === response.order.orderid
          );
          orders_nz_shopify[index] = response.order;
          setOpenMapVariantModal(true)
          setLoad(false)
          dispatch(setOrdersNzShopify(orders_nz_shopify));
        }
      }).catch(()=>
      {
        setLoad(false)
      });
  };

  const handleOnlySaveMappingVariant = () => {
    let variantName = '';
    let imageURL = null;
    let option1 = null;
    let option2 = null;
    let option3 = null;


    if (selected.item !== undefined) {
      let sku_properties =
        selected.item.ae_sku_property_dtos.ae_sku_property_d_t_o.filter(
          (item) => item.sku_property_name !== "Ships From"
        );

      imageURL = selected.item.ae_sku_property_dtos.ae_sku_property_d_t_o[0].sku_image

      //Set the variant name
      variantName = sku_properties.map((item, index) => {
        if (item.sku_property_name !== "Ships From") {
          if (index === 0) {
            option1 = item.property_value_definition_name
              ? item.property_value_definition_name
              : item.sku_property_value;
          }
          if (index === 1) {
            option2 = item.property_value_definition_name
              ? item.property_value_definition_name
              : item.sku_property_value;
          }
          if (index === 2) {
            option3 = item.property_value_definition_name
              ? item.property_value_definition_name
              : item.sku_property_value;
          }
        }
      });

    }

    if (option1 !== null) {
      variantName = option1;
    }
    if (option2 !== null) {
      variantName = variantName + "/" + option2;
    }
    if (option3 !== null) {
      variantName = variantName + "/" + option3;
    }

    console.log("variantName", variantName);

    let endpoint;
    if (store === "nzshopify") {
      endpoint = "https://aliscript.saleprodev.com/aliexpressmapvariant"
    }

    if (store === "aushopify") {
      endpoint = "https://aliscript.saleprodev.com/aliexpressmapvariantau"
    }

    if (store === "usshopify") {
      endpoint = "https://aliscript.saleprodev.com/aliexpressmapvariantus"
    }

    if (store === "gkshopify") {
      endpoint = "https://aliscript.saleprodev.com/aliexpressmapvariantgk"
    }

    console.log("body", {
      id: selected.id,
      orderid: page_name === "order" ? orderId : null,
      supplier_sku: selected.supplier_sku,
      sku: selected.sku,
      ali_variantname: variantName,
      page_name,
      status,
      imageUrl: isImageOverrideAvailable === true ? imageURL : null,
      currentStatus,
      isImageOverrideAvailable,
      isVariantOverrideAvailable,
      isChangeInVarintStatus,
    })

    fetch(endpoint, {
      method: "post",
      body: JSON.stringify({
        id: selected.id,
        orderid: page_name === "order" ? orderId : null,
        supplier_sku: selected.supplier_sku,
        sku: selected.sku,
        ali_variantname: variantName,
        page_name,
        status,
        imageUrl: isImageOverrideAvailable === true ? imageURL : null,
        currentStatus,
        isImageOverrideAvailable,
        isVariantOverrideAvailable,
        isChangeInVarintStatus,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("response", response);
        setOpen({ open: true, message: "Success" });
        setCount(count + 1);
        setCount3(count3 + 1);
        handleCloseMapVariantModal();
        setAliVariantName(variantName);

        //Logic for orders page
        if (response.order && response.order !== null) {
          let index = orders_nz_shopify.findIndex(
            (order) => order.orderid === response.order.orderid
          );
          orders_nz_shopify[index] = response.order;
          dispatch(setOrdersNzShopify(orders_nz_shopify));
        }
      });
  };

  useEffect(() => {
    if (store === "nzshopify") {
      fetch("https://aliscript.saleprodev.com/getshopifyvariantimage", {
        method: "post",
        body: JSON.stringify({
          variantid: product.variantid,
          variantType: "multiple",
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("image", response);
          if (response.src) {
            setImage(response);
          }
          if (response.errors) {
            setImage(response.errors);
          }
        });
    }
    if (store === "aushopify") {
      fetch("https://aliscript.saleprodev.com/getshopifyvariantimageau", {
        method: "post",
        body: JSON.stringify({
          variantid: product.variantid,
          variantType: "multiple",
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          if (response.src) {
            setImage(response);
          }
          if (response.errors) {
            setImage(response.errors);
          }
        });
    }
    if (store === "usshopify") {
      fetch("https://aliscript.saleprodev.com/getshopifyvariantimageus", {
        method: "post",
        body: JSON.stringify({
          variantid: product.variantid,
          variantType: "multiple",
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.src) {
            setImage(response);
          }
          if (response.errors) {
            setImage(response.errors);
          }
        });
    }

    if (store === "gkshopify") {
      fetch("https://aliscript.saleprodev.com/getshopifyvariantimagegk", {
        method: "post",
        body: JSON.stringify({
          variantid: product.variantid,
          variantType: "multiple",
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.src) {
            setImage(response);
          }
          if (response.errors) {
            setImage(response.errors);
          }
        });
    }
  }, [product]);

  console.log("selected", selected);
  return (
    load ?  <Loader size={70} /> : <Card style={{ width: "80vw", mt: "2%", borderRadius: "8px" }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography
            align="center"
            variant="h5"
            color="text.contrast"
            sx={{ backgroundColor: "primary.main" }}
          >
            {store.toUpperCase()}
          </Typography>

          <div
            style={{
              alignItems: "left",
              textAlign: "left",
              marginLeft: "10%",
              display: "grid",
              gridTemplateColumns: "50% 50%",
            }}
          >
            <div>
              <div style={{ fontWeight: "bold" }}>Sku:</div>
              <div>{product.supplier_sku}</div>
            </div>
            <div>
              <div style={{ fontWeight: "bold" }}>Shopify Sku:</div>
              <div>{product.sku}</div>
            </div>
            {image.src === "Image Not Found" ||
            image.src === "Product Not Found" ? (
              <div>
                <div style={{ color: "red" }}>{image.src}</div>
                <div>OPTION1:{image.option1}</div>
                <div>OPTION2:{image.option2}</div>
                <div>OPTION3:{image.option3}</div>
              </div>
            ) : (
              <div>
                <img src={image.src} alt="" height={100} width={100} />
                <div>OPTION1:{image.option1}</div>
                <div>OPTION2:{image.option2}</div>
                <div>OPTION3:{image.option3}</div>
              </div>
            )}
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography
            align="center"
            variant="h5"
            color="text.contrast"
            sx={{ backgroundColor: "primary.main" }}
          >
            ALIEXPRESS
          </Typography>

          <Typography align="left" variant="h6">
            Store Name: {product.ali_info.result.store_info.store_name}
          </Typography>

          <div
            style={{
              alignItems: "left",
              textAlign: "left",
              height: "700px",
              overflow: "scroll",
            }}
          >
            {product.ali_info.result.aeop_ae_product_s_k_us &&
            product.ali_info.result.aeop_ae_product_s_k_us.aeop_ae_product_sku
              .length !== 0 ? (
              product.ali_info.result.aeop_ae_product_s_k_us.aeop_ae_product_sku
                .filter((prod) => {
                  if (
                    prod?.ae_sku_property_dtos?.ae_sku_property_d_t_o &&
                    prod?.ae_sku_property_dtos?.ae_sku_property_d_t_o?.length >
                      0
                  ) {
                    let isVisible = false;
                    let isShippingMethodAvaiable =
                      prod.ae_sku_property_dtos.ae_sku_property_d_t_o.find(
                        (data) => data?.sku_property_name == "Ships From"
                      );
                    console.log(
                      "isShippingMethodAvaiable",
                      isShippingMethodAvaiable
                    );
                    if (isShippingMethodAvaiable) {
                      if (
                        store == "aushopify" &&
                        (isShippingMethodAvaiable?.sku_property_value ==
                          "China" ||
                          isShippingMethodAvaiable?.sku_property_value ==
                            "CHINA" ||
                          isShippingMethodAvaiable?.sku_property_value ==
                            "Australia")
                      ) {
                        isVisible = true;
                      } else if (
                        isShippingMethodAvaiable?.sku_property_value ==
                          "China" ||
                        isShippingMethodAvaiable?.sku_property_value == "CHINA"
                      ) {
                        isVisible = true;
                      }
                    } else {
                      isVisible = true;
                    }

                    // if(isVisible)
                    console.log("pp", isVisible, prod);

                    return isVisible;
                    // return prod.ae_sku_property_dtos.ae_sku_property_d_t_o[2].sku_property_value === "China"
                  } else {
                    return prod;
                  }
                })
                .map((item) => {
                  {
                    console.log("thisjsb dsfs================>> ", item);
                  }
                  return (
                    <Grid xs={12} sm={12} md={12} lg={12}>
                      <div
                        style={{ backgroundColor: "#f7f7f7", marginTop: "1%" }}
                      >
                        <div style={{ fontWeight: "bold" }}>{item.id}</div>
                        {selected.id === item.id ? (
                          <div
                            id={item.id}
                            style={{
                              display: "grid",
                              gridTemplateColumns: "30% 30% 30%",
                              backgroundColor: "red",
                              cursor: "pointer",
                            }}
                            onClick={(event) =>
                              handleClick(
                                item.id,
                                product.supplier_sku,
                                product.sku,
                                item
                              )
                            }
                          >
                            {item.ae_sku_property_dtos.ae_sku_property_d_t_o.map(
                              (prop) => {
                                return (
                                  <div>
                                    {prop.property_value_definition_name ? (
                                      <div>
                                        {prop.sku_property_name}:{" "}
                                        {prop.property_value_definition_name}
                                      </div>
                                    ) : (
                                      <div>
                                        {prop.sku_property_name}:{" "}
                                        {prop.sku_property_value}
                                      </div>
                                    )}

                                    {prop.sku_image && (
                                      <img
                                        src={prop.sku_image}
                                        alt=""
                                        height={100}
                                        width={100}
                                      />
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        ) : (
                          <div
                            id={item.id}
                            style={{
                              display: "grid",
                              gridTemplateColumns: "30% 30% 30%",
                              cursor: "pointer",
                            }}
                            onClick={(event) =>
                              handleClick(
                                item.id,
                                product.supplier_sku,
                                product.sku,
                                item
                              )
                            }
                          >
                            {item.ae_sku_property_dtos &&
                              item.ae_sku_property_dtos.ae_sku_property_d_t_o.map(
                                (prop) => {
                                  return (
                                    <div>
                                      {prop.property_value_definition_name ? (
                                        <div>
                                          {prop.sku_property_name}:{" "}
                                          {prop.property_value_definition_name}
                                        </div>
                                      ) : (
                                        <div>
                                          {prop.sku_property_name}:{" "}
                                          {prop.sku_property_value}
                                        </div>
                                      )}
                                      {prop.sku_image && (
                                        <img
                                          src={prop.sku_image}
                                          alt=""
                                          height={100}
                                          width={100}
                                        />
                                      )}
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        )}
                      </div>
                    </Grid>
                  );
                })
            ) : (
              <div
                style={{ fontWeight: "bold", cursor: "pointer" }}
                onClick={(event) => {
                  handleClick("Not Found", product.supplier_sku, product.sku);
                  setIsChangeInVarintStatus(true);
                  setCurrentStatus("Product not found");
                }}
              >
                NOT FOUND
              </div>
            )}

            <Grid container my={1}>
              <Checkbox
                checked={checkImageOverride}
                onChange={handleChangeImageOverrideCheckbox}
                disableRipple
                sx={{ p: 0 }}
              />
              <Typography variant="body1" sx={{ ml: 0.5, fontWeight: "bold" }}>
                IMAGE OVERRIDE
              </Typography>
            </Grid>

            <Grid container my={1}>
              <Checkbox
                checked={checkVariantOverride}
                onChange={handleChangeVariantOverrideCheckbox}
                disableRipple
                sx={{ p: 0 }}
              />
              <Typography variant="body1" sx={{ ml: 0.5, fontWeight: "bold" }}>
                VARIANT OVERRIDE
              </Typography>
            </Grid>

            <Grid container my={1}>
              <Checkbox
                checked={checkImageMissing}
                onChange={handleChangeImageMissingCheckbox}
                disableRipple
                sx={{ p: 0 }}
              />
              <Typography variant="body1" sx={{ ml: 0.5, fontWeight: "bold" }}>
                IMAGE MISSING IN SHOPIFY
              </Typography>
            </Grid>

            <Grid container my={1}>
              <Checkbox
                checked={checkProductMissing}
                onChange={handleChangeProductMissingCheckbox}
                disableRipple
                sx={{ p: 0 }}
              />
              <Typography variant="body1" sx={{ ml: 0.5, fontWeight: "bold" }}>
                PRODUCT MISSING IN SHOPIFY
              </Typography>
            </Grid>

            <Grid container my={1}>
              <Checkbox
                checked={checkDiscontinued}
                onChange={handleChangeDiscontinuedCheckbox}
                disableRipple
                sx={{ p: 0 }}
              />
              <Typography variant="body1" sx={{ ml: 0.5, fontWeight: "bold" }}>
                DISCONTINUED BY ALIEXPRESS
              </Typography>
            </Grid>

            <Grid container my={4}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Variant Change
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={variantStatus}
                  onChange={handleVarient}
                >
                  <FormControlLabel
                    value="permanent"
                    control={<Radio />}
                    label="Permanent Change in Variant"
                  />
                  <FormControlLabel
                    value="temporary"
                    control={<Radio />}
                    label="Temporary Change in Variant"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </div>
        </Grid>

        <Grid item container xs={12} justifyContent="center">
          <Grid item m={1}>
            <Button variant="outlined" onClick={handleCloseMapVariantModal}>
              Close
            </Button>
          </Grid>

          <Grid item m={1}>
            <Button
              variant="contained"
              onClick={handleOnlySaveMappingVariant}
              disabled={
                selected.item === undefined &&
                checkImageOverride === false &&
                checkVariantOverride === false &&
                checkImageMissing === false &&
                checkProductMissing === false &&
                checkDiscontinued === false
              }
            >
              Save
            </Button>
          </Grid>

          <Grid item m={1}>
            <Button
              variant="contained"
              onClick={handleSaveMappingVariant}
              disabled={
                selected.item === undefined &&
                checkImageOverride === false &&
                checkVariantOverride === false &&
                checkImageMissing === false &&
                checkProductMissing === false &&
                checkDiscontinued === false
              }
            >
              Save and Next
            </Button>
          </Grid>

          {page_name === "tool" && (
            <Grid item m={1}>
              <Button
                variant="contained"
                onClick={() =>
                  dispatch(setImportPage(parseInt(import_page) + 1))
                }
              >
                Skip
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}
