
import React, {useState, useEffect} from "react"
import {Navbar, Container,Nav,NavDropdown,Form,FormControl,Button} from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';
import {Grid} from "@material-ui/core"
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';

function ShopifyOrders() {
    const [orders, setOrders] = useState("")
    const [expanded, setExpanded] = React.useState(false)
    useEffect(() => {
      const data = async () => {
        // await fetch("https://thingproxy.freeboard.io/fetch/https://goslash-au.myshopify.com/admin/api/2021-07/orders.json?limit=250",
        await fetch(`http://localhost:3001/shopifyorders`).then(response => response.json()).then(response => setOrders(response))

      }
      data()
    }, [])

    const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  console.log("orders", orders)
  return (
    <Grid container spacing={3} style={{marginTop:"50px"}}>
    {orders.orders && orders.orders.map(order => {
      return(
        <Grid item lg={12} sm={12} xs={12} >
        <Card>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            R
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={order.name}
        subheader={order.processed_at.slice(0, 10)}
      />
      {
        // <CardMedia
        //   component="img"
        //   height="194"
        //   image="https://images.squarespace-cdn.com/content/v1/5a29e707f43b55b329f9b3af/1576102156971-TUFT46EGLVX45UDQXFW3/SS%2BBanner%2Bheader_feathersblur.jpeg?format=2500w"
        //   alt="Paella dish"
        // />
      }

      <CardContent>
        <div style={{display:"grid", gridTemplateColumns:"30% 70%"}}>
        <Typography variant="body2" color="text.secondary">
          NAME:
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {order.customer.first_name} {order.customer.last_name}
        </Typography>
        </div>
        <div style={{display:"grid", gridTemplateColumns:"30% 70%", height:"auto", marginTop:"1%"}}>
        <Typography variant="body2" color="text.secondary">
          EMAIL:
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {order.customer.email}
        </Typography>
        </div>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>

        {order.line_items.map(item => {
          return (
            <Card style={{marginTop:"1%"}}>
            <div style={{display:"grid", gridTemplateColumns:"50% 50%"}}>
            <Typography variant="body2" color="text.secondary">
              PRODUCT SKU:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {item.sku}
            </Typography>
            </div>
            <Typography variant="body2" color="text.secondary">
            <div style={{display:"grid", gridTemplateColumns:"50% 50%"}}>
            <Typography variant="body2" color="text.secondary">
              PRODUCT TITLE:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {item.title}
            </Typography>
            </div>
            </Typography>
            </Card>
          )
        })}

        </CardContent>
      </Collapse>
    </Card>
          {
        // <p>ORDER NO:{order.order_number}</p>
        // <p>CUSTOMER NAME: {order.customer.first_name}</p>
        // <p>CUSTOMER PHONE:{order.customer.default_address.phone}</p>
        // <p>CUSTOMER CITY:{order.customer.default_address.province}</p>
      }
        </Grid>
      )
    })}
    </Grid>
        )
}

export default ShopifyOrders;
