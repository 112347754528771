import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';

export default function MainCheckbox({ products, selectedProducts, setSelectedProducts }) {
  const handleChange = (event) => {
    if (event.target.checked) {
      let array = []
      products.map(product => {
        let element = document.getElementById(product.id)
        if (element.checked) {
          console.log("do nothing")
        } else {
          element.checked = true
        }

        array.push(product)
      })
      setSelectedProducts([...array])
    }
    else {
      products.map(product => {
        let element = document.getElementById(product.id)
        if (element.checked) {
          element.checked = false
        }
      })
      setSelectedProducts([])
    }
  };

  return (
    <input type="checkbox" onChange={handleChange} id="maincheckbox" />
  );
}
