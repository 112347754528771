import React, { useState, useEffect } from "react";
import moment from "moment";
import no_image from "../assets/images/no_image.jpg";
import { Card, Grid, Box, Tooltip, Typography } from "@mui/material";
import ControlledCheckbox from "./fragrancexcheckbox.js";

export default function FragrancexProductsLayout({
  product,
  checkboxID,
  fetchproducts,
  selectedProducts,
  setSelectedProducts,
  handleImport,
}) {
  const formatDate = (date) => moment(date).format("DD/MM/YYYY HH:mm:ss");

  let supplier_sku_url = '';
  let brandWithDash = product.brandname ? product.brandname.toLowerCase().replace(/ /g, '-'): '';
  let index = product.description ? product.description.toLowerCase().indexOf('by'): ''
  console.log('product index', index)

  let productNameWithDash = product.description ?  product.description.toLowerCase().slice(0, index - 1).replace(/ /g, '-') : '';
  // console.log('product', product)
  console.log('product brandname', brandWithDash)
  console.log('product productName', productNameWithDash)
  supplier_sku_url = ` https://www.fragrancex.com/products/${brandWithDash}/${productNameWithDash}`;

  return (
    <Card sx={{ width: "100%", mt: 1 }}>
      <Grid
        container
        sx={{
          minHeight: "70px",
          display: "Grid",
          gridTemplateColumns: "2% 4% 35% 8% 8% 10% 8% 5% 5% 5% 6% 4%",
          alignItems: "center",
        }}
      >
        {/* Checkbox */}
        <Grid container justifyContent="center">
          <ControlledCheckbox
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            product={product}
            checkboxID={checkboxID}
          />
        </Grid>

        {/* Image */}
        <Grid container justifyContent="center" alignItems="center" p={0.5}>
          <Tooltip
            arrow
            placement="top"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  padding: 0,
                },
              },
            }}
            title={
              <img
                src={
                  product.largeimageurl || product.smallimageurl
                    ? product.largeimageurl
                      ? product.largeimageurl
                      : product.smallimageurl
                    : no_image
                }
                alt=""
                style={{
                  width: "250px",
                }}
              />
            }
          >
            <Grid
              item
              justifyContent="center"
              alignContent="center"
              sx={{
                minHeight: "60px",
                border: (theme) => `1px solid ${theme.palette.border}`,
              }}
            >
              <img
                src={
                  product.largeimageurl || product.smallimageurl
                    ? product.largeimageurl
                      ? product.largeimageurl
                      : product.smallimageurl
                    : no_image
                }
                alt=""
                width="60px"
              />
            </Grid>
          </Tooltip>
        </Grid>

        {/* Title & Product ID */}
        <Grid container flexDirection="column" pl={1}>
          {product.productname && product.productname.length > 90 ? (
            <Tooltip arrow placement="top" title={product.productname}>
              <Typography variant="body2">
                {`${product.productname.slice(0, 90)}...`}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="body2">{product.productname}</Typography>
          )}

          <Grid item>
            <Typography component="a" href={supplier_sku_url} target="_blank" variant="body2" color="primary">
              {product.ItemId && `Product ID: ${product.ItemId}`}
            </Typography>
          </Grid>
        </Grid>

        {/* Barcode */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.upc === null || product.upc === "" ? "N/A" : product.upc}
          </Typography>
        </Grid>

        {/* Weight */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.weight === undefined ||
              product.weight === null ||
              product.weight === ""
              ? "N/A"
              : `${product.weight}`}
          </Typography>
        </Grid>

        {/* Cost */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.wholesalepriceusd}
          </Typography>
        </Grid>

        {/* Shipping Cost */}
        <Grid container justifyContent="center">
          <Typography variant="body2">N/A</Typography>
        </Grid>

        {/* Variation */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {Array.isArray(product.variants) ? product.variants.length : 0}
          </Typography>
        </Grid>

        {/* Stock */}
        <Grid container justifyContent="center">
          <Typography variant="body2">{product.quantityavailable}</Typography>
        </Grid>

        {/* Supplier */}
        <Grid container justifyContent="center">
          <Typography variant="body2">Fragrancex</Typography>
        </Grid>

        {/* Created At */}
        <Grid container justifyContent="center">
          <Typography variant="body2" textAlign="center">
            {formatDate(product.createdAt)}
          </Typography>
        </Grid>

        {/* <div style={{ textAlign: "center" }}>
          {product.import_status === null && "Not Imported"}
          {product.import_status === "imported" && "Imported"}
          {product.import_status === "exported" && "Exported"}
          {product.import_status === "deleted" && "Deleted"}
        </div> */}
        {
          // <div style={{textAlign:"center"}}>
          //   <Typography style={{color:"#5b5b5e"}}><Button variant="contained" size="small" onClick={(event) => handleImport(product)}>Import</Button></Typography>
          // </div>
        }
      </Grid>
    </Card>
  );
}
