import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Button, Card, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ExportModal({
  open,
  handleClose,
  product,
  handleExport,
}) {
  const [storestoexport, setStoresToExport] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [category, setCategory] = React.useState("");
  // console.log("storestoexport",storestoexport)

  useEffect(() => {
    setStoresToExport([]);
  }, []);

  const CalculatePrice = () => {
    let prices = [];
    if (product.variants) {
      product.variants.map((item) => {
        prices.push(parseFloat(item.price));
      });
      return `${Math.min(...prices)} - ${Math.max(...prices)}`;
    }
  };

  const CalculateStock = () => {
    let prices = [];
    if (product.variants) {
      product.variants.map((item) => {
        prices.push(parseFloat(item.inventory_quantity));
      });
      return `${Math.min(...prices)} - ${Math.max(...prices)}`;
    }
  };

  const ProductLayout = () => {
    return (
      <Card
        style={{ zIndex: "1000", marginTop: "1%", backgroundColor: "white" }}
      >
        <Grid item xs={12} sm={12} lg={12}>
          <div
            style={{
              display: "Grid",
              gridTemplateColumns: "3% 10% 40% 10% 10% 10% 10% 10%",
              gridGap: "5px",
              marginTop: "10px",
              marginBottom: "10px",
              fontSize: "14px",
            }}
          >
            <div style={{ textAlign: "left" }}></div>
            <div style={{ textAlign: "center" }}>
              <Box sx={{ marginRight: "10px", border: "1px solid #e3e3e3" }}>
                <img
                  src={
                    product.images && product.images[0]
                      ? product.images[0].src
                      : ""
                  }
                  alt=""
                  height="100"
                  width="80"
                />
              </Box>
            </div>
            <div>
              <div>
                <div>{product.title}</div>
                <div>{product.sku}</div>
              </div>
              <div style={{ color: "#999797", marginTop: "10px" }}>
                <a
                  href={product.url}
                  target="_blank"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {product.id}
                </a>
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <div>Price Range</div>
              <div>{CalculatePrice()}</div>
            </div>
            <div style={{ textAlign: "center" }}>
              <div>Variants</div>
              <div>
                {Array.isArray(product.variants) ? product.variants.length : 0}
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <div>Supplier</div>
              <a
                href="https://matterhorn-wholesale.com/"
                target="_blank"
                style={{ textDecoration: "none", color: "black" }}
              >
                {product.supplier}
              </a>
            </div>
            <div style={{ textAlign: "center" }}>
              <div>Stock Range</div>
              <div>{CalculateStock()}</div>
            </div>
            <div style={{ textAlign: "center" }}></div>
          </div>
        </Grid>
      </Card>
    );
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "25% 25% 25% 25%",
              gridGap: "1%",
            }}
          >
            {storestoexport.includes("nzshopify") ? (
              <>
                <Button
                  variant="contained"
                  onClick={(event) => {
                    let copy = storestoexport;
                    let index = copy.indexOf("nzshopify");
                    console.log(index, copy);
                    if (index !== -1) {
                      copy.splice(index, 1);
                      console.log(copy);
                      setStoresToExport(copy);
                      setCount(count + 1);
                    }
                  }}
                >
                  NZ SHOPIFY
                </Button>
              </>
            ) : (
              <Button
                variant="outlined"
                onClick={(event) =>
                  setStoresToExport([...storestoexport, "nzshopify"])
                }
              >
                NZ SHOPIFY
              </Button>
            )}
            {storestoexport.includes("aushopify") ? (
              <>
                <Button
                  variant="contained"
                  onClick={(event) => {
                    let copy = storestoexport;
                    let index = copy.indexOf("aushopify");
                    console.log(index, copy);
                    if (index !== -1) {
                      copy.splice(index, 1);
                      console.log(copy);
                      setStoresToExport(copy);
                      setCount(count + 1);
                    }
                  }}
                >
                  AU SHOPIFY
                </Button>
              </>
            ) : (
              <Button
                variant="outlined"
                onClick={(event) =>
                  setStoresToExport([...storestoexport, "aushopify"])
                }
              >
                AU SHOPIFY
              </Button>
            )}
            {storestoexport.includes("usshopify") ? (
              <>
                <Button
                  variant="contained"
                  onClick={(event) => {
                    let copy = storestoexport;
                    let index = copy.indexOf("usshopify");
                    console.log(index, copy);
                    if (index !== -1) {
                      copy.splice(index, 1);
                      console.log(copy);
                      setStoresToExport(copy);
                      setCount(count + 1);
                    }
                  }}
                >
                  US SHOPIFY
                </Button>
              </>
            ) : (
              <Button
                variant="outlined"
                onClick={(event) =>
                  setStoresToExport([...storestoexport, "usshopify"])
                }
              >
                US SHOPIFY
              </Button>
            )}
            {storestoexport.includes("gkshopify") ? (
              <>
                <Button
                  variant="contained"
                  onClick={(event) => {
                    let copy = storestoexport;
                    let index = copy.indexOf("gkshopify");
                    console.log(index, copy);
                    if (index !== -1) {
                      copy.splice(index, 1);
                      console.log(copy);
                      setStoresToExport(copy);
                      setCount(count + 1);
                    }
                  }}
                >
                  GK SHOPIFY
                </Button>
              </>
            ) : (
              <Button
                variant="outlined"
                onClick={(event) =>
                  setStoresToExport([...storestoexport, "gkshopify"])
                }
              >
                GK SHOPIFY
              </Button>
            )}
            {storestoexport.includes("kogan") ? (
              <>
                <Button
                  variant="contained"
                  onClick={(event) => {
                    let copy = storestoexport;
                    let index = copy.indexOf("kogan");
                    console.log(index, copy);
                    if (index !== -1) {
                      copy.splice(index, 1);
                      console.log(copy);
                      setStoresToExport(copy);
                      setCount(count + 1);
                    }
                  }}
                >
                  KOGAN
                </Button>
              </>
            ) : (
              <Button
                variant="outlined"
                onClick={(event) =>
                  setStoresToExport([...storestoexport, "kogan"])
                }
              >
                KOGAN
              </Button>
            )}
          </div>
          <ProductLayout />
          <div style={{ marginTop: "2%" }}>
            <Button
              variant="contained"
              onClick={(event) =>
                handleExport(product, storestoexport, category)
              }
            >
              EXPORT
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
