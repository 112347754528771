import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export default function SupplierSKU({
  selectedvariant,
  count,
  setCount,
  product,
}) {
  const [suppliersku, setSupplierSku] = React.useState("");
  const store = useSelector((state) => state.store);
  const [image, setImage] = React.useState("");

  const handleSupplierSku = () => {
    fetch("https://api.saleprodev.com/getsupplierskunz", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ sku: selectedvariant.sku, store: store }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("supplier_sku", response);
        let supplier_sku = response.supplier_sku;
        setSupplierSku(supplier_sku);
        setCount(count + 1);
      });
  };

  const handleImage = () => {
    if (store === "nzshopify") {
      fetch("https://aliscript.saleprodev.com/getshopifyvariantimage", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ variantid: selectedvariant.id }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("image", response);
          setImage(response.src);
        });
    }
    if (store === "aushopify") {
      fetch("https://aliscript.saleprodev.com/getshopifyvariantimageau", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ variantid: selectedvariant.id }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("image", response);
          setImage(response.src);
        });
    }

    if (store === "usshopify") {
      fetch("https://aliscript.saleprodev.com/getshopifyvariantimageus", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ variantid: selectedvariant.id }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("image", response);
          setImage(response.src);
        });
    }

    if (store === "gkshopify") {
      fetch("https://aliscript.saleprodev.com/getshopifyvariantimagegk", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ variantid: selectedvariant.id }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("image", response);
          setImage(response.src);
        });
    }
  };

  useEffect(() => {
    if (selectedvariant !== "Select a Variant") {
      handleSupplierSku();
    } else {
      setSupplierSku("");
    }
  }, [selectedvariant]);

  useEffect(() => {
    if (selectedvariant !== "Select a Variant") {
      handleImage();
    } else {
      setImage("Image Not Found");
    }
  }, [selectedvariant]);

  return (
    <div>
      <div
        style={{
          marginLeft: "2%",
          marginRight: "3%",
          marginTop: "3%",
          marginBottom: "2%",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "50% 50%",
            gridGap: "2%",
          }}
        >
          <label for="title">Product Image</label>
          {image !== "Image Not Found" ? (
            <img src={image} alt="" height={100} width={100} />
          ) : (
            <div style={{ color: "red" }}>Image Not Found</div>
          )}
          <label for="title">Product title</label>
          <textarea
            value={product.title}
            id="title"
            rows="3"
            cols="10"
            wrap="hard"
          />
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "50% 50%",
            marginTop: "2%",
            gridGap: "2%",
          }}
        >
          <label for="desc">Product description</label>
          <textarea value={product.body_html} name="text" rows="10" cols="10">
            {" "}
          </textarea>
          <div dangerouslySetInnerHTML={{ __html: product.desc }}></div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "15% 35% 15% 35%",
            marginTop: "2%",
            gridGap: "1%",
          }}
        >
          <label for="price">Product Price</label>
          <div style={{ width: "20%" }}>
            <input
              type="text"
              id="price"
              name="name"
              width="10%"
              value={selectedvariant.price ? selectedvariant.price : ""}
            />
          </div>
          <label for="quantity">Product Quantity</label>
          <div style={{ width: "10%" }}>
            <input
              type="text"
              id="quantity"
              name="name"
              size="10"
              value={
                selectedvariant.inventory_quantity !== undefined
                  ? selectedvariant.inventory_quantity
                  : ""
              }
            />
          </div>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "15% 35% 15% 35%",
            marginTop: "2%",
            gridGap: "1%",
          }}
        >
          <label for="SKU">Product SKU</label>
          <div style={{ width: "100%" }}>
            <input
              type="text"
              id="SKU"
              name="name"
              width="100%"
              value={selectedvariant.sku ? selectedvariant.sku : ""}
            />
          </div>
          <label for="quantity">Product Tag</label>
          <div style={{ width: "50%" }}>
            <input
              type="text"
              id="quantity"
              name="name"
              required
              minlength="10"
              maxlength="10"
              size="30"
              value={product.tags}
            />
          </div>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "15% 35% 15% 35%",
            marginTop: "2%",
            gridGap: "1%",
          }}
        >
          <label for="weight">Product Weight</label>
          <div style={{ width: "20%" }}>
            <input
              type="number"
              id="weight"
              name="name"
              width="10%"
              min="0"
              max="999"
              value={selectedvariant.weight ? selectedvariant.weight : ""}
            />
          </div>
          <label for="weight">Supplier SKU</label>
          <div style={{ width: "20%" }}>
            <input
              type="text"
              id="weight"
              name="name"
              width="10%"
              value={suppliersku}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
