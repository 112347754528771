import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setListingStatus, setSearchFilterListings } from "../../actions";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Divider,
  List,
  ListItemButton,
} from "@mui/material";

export const ListingsSideMenu = () => {
  const dispatch = useDispatch();
  const listingStatus = useSelector((state) => state.listing_status);

  //Accordion
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setExpanded("panel1");
  }, [listingStatus]);

  return (
    <Grid item xs={1}>
      <List
        component="nav"
        id="listings_side_menu_list"
        sx={{
          py: 0,
          bgcolor: "background.paper",
          "& .MuiAccordionSummary-root": {
            m: 0,
            p: 0,
            minHeight: "100%",
          },
          "& .MuiAccordionSummary-content": {
            m: 0,
          },

          "& .MuiAccordionDetails-root": {
            p: 0,
          },

          "& .MuiListItemButton-root": {
            "&:hover": {
              backgroundColor: (theme) => theme.palette.primary.light,
            },
          },
        }}
      >
        <Grid
          item
          sx={{
            p: 0.5,
            pl: 1,
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 700 }}>
            Listing Manager
          </Typography>
        </Grid>

        <Divider sx={{ color: (theme) => theme.palette.neutral[300] }} />

        <Accordion
          disableGutters
          elevation={0}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <ListItemButton
              sx={{
                p: 0.75,
                pl: 2,
                borderLeft: (theme) =>
                  listingStatus === "" ||
                  listingStatus === "draft" ||
                  listingStatus === "active" ||
                  listingStatus === "deleted"
                    ? `4px solid ${theme.palette.primary.main}`
                    : "",
              }}
            >
              <Typography variant="body2">Shopify</Typography>
            </ListItemButton>
          </AccordionSummary>

          <AccordionDetails>
            {listingStatus === "" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">All Listings</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setListingStatus(""));
                  dispatch(setSearchFilterListings("Status"));
                }}
              >
                <Typography variant="body2">All Listings</Typography>
              </ListItemButton>
            )}

            {listingStatus === "draft" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">Draft Listings</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setListingStatus("draft"));
                  dispatch(setSearchFilterListings("Status"));
                }}
              >
                <Typography variant="body2">Draft Listings</Typography>
              </ListItemButton>
            )}

            {listingStatus === "active" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">Active Listings</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setListingStatus("active"));
                  dispatch(setSearchFilterListings("Status"));
                }}
              >
                <Typography variant="body2">Active Listings</Typography>
              </ListItemButton>
            )}

            {/* {listingStatus === "exported" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">Exported Listings</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setListingStatus("exported"));
                  dispatch(setSearchFilterListings("Status"));
                }}
              >
                <Typography variant="body2">Exported Listings</Typography>
              </ListItemButton>
            )} */}

            {listingStatus === "deleted" ? (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  borderRight: (theme) =>
                    `4px solid ${theme.palette.primary.main}`,
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <Typography variant="body2">Deleted Listings</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  p: 0.75,
                  pl: 3,
                  backgroundColor: "",
                }}
                onClick={(event) => {
                  dispatch(setListingStatus("deleted"));
                  dispatch(setSearchFilterListings("Status"));
                }}
              >
                <Typography variant="body2">Deleted Listings</Typography>
              </ListItemButton>
            )}
          </AccordionDetails>
        </Accordion>
      </List>
    </Grid>
  );
};
