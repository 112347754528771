import React from "react";

const SingleCheckBox = ({ id, item, selectedItems, setSelectedItems }) => {
  const handleChange = (e) => {
    let array = [...selectedItems];
    if (e.target.checked === true) {
      array.push(item);
    } else {
      let index = array.indexOf(item);
      array.splice(index, 1);
    }
    setSelectedItems(array);
  };

  return (
    <input id={`checkbox_${id}`} type="checkbox" onChange={handleChange} />
  );
};
export default SingleCheckBox;
