import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import SupplierSelect from "./supplierselect.js"
import ShipFromSelect from "./shipfromselect.js"
import ShipToSelect from "./shiptoselect.js"
import ShippingMethodSelect from "./shippingmethodselect.js"
import BasicSelect from './select.js'
import AdvancedFormula from "./advancedformula.js"
import ShipTypeSelect from "./shiptypeselect.js"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  height:800,
  overflow:"scroll"
};

export default function BasicModal({no_formulas, setNoFormulas, handleSave, handleOverride}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [margintype, setMarginType] = React.useState('Percentage');
  const [shipmethodtype, setShipMethodType] = useState("Based on Shipping Method")
  const [margin, setMargin] = useState(0)
  const [costmin, setCostMin] = useState(0)
  const [costmax, setCostMax] = useState(0)
  const [count, setCount] = useState(0)
  const [exist, setExist] = useState(false)



  const handleClick = (event) => {
    let copy = no_formulas
    copy.formula.pop()
    setNoFormulas(copy)
    setCount(count + 1)
  }
  const [supplier, setSupplier] = useState("Bangood")

  const handleChangeSupplier = (event) => {
    setSupplier(event.target.value);
  };

    const handleChange = (event, index) => {
      let copy = no_formulas
      copy.formula[index].margintype = event.target.value
      setNoFormulas(copy)
      setCount(count + 1)
    };

  const [shipmethod, setShipMethod] = useState("LC_SHIPMENT_NZ_DIRECT_MAIL")

  const handleChangeShipMethod = (event) => {
    setShipMethod(event.target.value);
    setCount(count + 1)
  };

  const [shipmethod2, setShipMethod2] = useState("LC_SHIPMENT_NZ_DIRECT_MAIL")

  const handleChangeShipMethod2 = (event) => {
    setShipMethod2(event.target.value);
    setCount(count + 1)
  };

  const [shipfrom, setShipFrom] = useState("CN")

  const handleChangeShipFrom = (event) => {
    setShipFrom(event.target.value);
  };
  const [shipto, setShipTo] = useState("New Zealand")

  const handleChangeShipTo = (event) => {
    setShipTo(event.target.value);
  };

  useEffect(() => {

    let copy = no_formulas
    copy.shipfrom = shipfrom
    setNoFormulas(copy)

  },[shipfrom])



  useEffect(() => {
    let copy = no_formulas
    copy.shipmethod = shipmethod
    setNoFormulas(copy)
  },[shipmethod])


  useEffect(() => {
    let copy = no_formulas
    copy.shipmethod2 = shipmethod2
    setNoFormulas(copy)
  },[shipmethod2])

  useEffect(() => {
    let copy = no_formulas
    copy.supplier = supplier
    setNoFormulas(copy)
  },[supplier])

  useEffect(() => {
    let copy = no_formulas
    copy.shipto = shipto
    setNoFormulas(copy)
  },[shipto])

  useEffect(() => {
    let obj = {id:(no_formulas.supplier + "-" + no_formulas.shipfrom + "-" + no_formulas.shipto)}
    setExist(false)
    fetch(`https://api.saleprodev.com/getpriceformula`, {
          method:"post",
          headers: {'Content-Type':'application/json'},
          body: JSON.stringify(obj)
        }).then(response => response.json()).then(response => {
            console.log(response)
            if(response.length !== 0){
              let obj = {supplier:response[0].supplier, shipfrom:response[0].shipfrom, shipto:response[0].shipto, shipmethod:response[0].shipmethod, shipmethod2:response[0].shipmethod2,formula:response[0].formula}
              setNoFormulas(obj)
              setShipMethod(response[0].shipmethod)
              setShipMethod2(response[0].shipmethod2)
              setExist(true)
            } else{
              let obj = {supplier:supplier, shipfrom:shipfrom, shipto:shipto, shipmethod:shipmethod, formula:[{costmin:0,costmax:0,margin:0,fixedprofit:0,breakeven:0, margintype:"Percentage", shipmethodtype:"Based on Shipping Method", fixedshipping:0}]}
              setNoFormulas(obj)
            }
        })

  },[supplier, shipfrom, shipto])

  const handleAdd = () => {
    let copy = no_formulas
    copy.formula.push({costmin:0,costmax:0,margin:0,fixedprofit:0,breakeven:0, margintype:"Percentage"})
    setNoFormulas(copy)
    setCount(count + 1)
  }
  const handleCostMinChange = (event, index) => {
    let copy = no_formulas
    copy.formula[index].costmin = event.target.value
    setNoFormulas(copy)
    setCount(count + 1)
  }

  const handleCostMaxChange = (event, index) => {
    let copy = no_formulas
    copy.formula[index].costmax = event.target.value
    setNoFormulas(copy)
    setCount(count + 1)
  }

  const handleMarginChange = (event, index) => {
    let copy = no_formulas
    copy.formula[index].margin = event.target.value
    setNoFormulas(copy)
    setCount(count + 1)
  }
  const handleFixedProfitChange = (event, index) => {
    let copy = no_formulas
    copy.formula[index].fixedprofit = event.target.value
    setNoFormulas(copy)
    setCount(count + 1)
  }
  const handleBreakEvenChange = (event, index) => {
    let copy = no_formulas
    copy.formula[index].breakeven = event.target.value
    setNoFormulas(copy)
    setCount(count + 1)
  }

  const handleFixedShippingChange = (event, index) => {
    let copy = no_formulas
    copy.formula[index].fixedshipping = event.target.value
    setNoFormulas(copy)
    setCount(count + 1)
  }
  const handleChangeShipMethodType = (event, index) => {
    let copy = no_formulas
    copy.formula[index].shipmethodtype = event.target.value
    setNoFormulas(copy)
    setCount(count + 1)
  }

  console.log("copy", no_formulas)



  return (
    <div>
      <Button onClick={handleOpen}>Manage Formulae</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{display:"grid", gridTemplateColumns:"15% 15% 15% 25% 25% 15%"}}>
            <div>
              <label style={{marginBottom:"3%"}}>Supplier</label>
              <SupplierSelect handleChange={handleChangeSupplier} value={supplier}/>
            </div>
            <div>
              <label style={{marginBottom:"3%"}}>Ship From</label>
              <ShipFromSelect handleChange={handleChangeShipFrom} value={shipfrom}/>
            </div>
            <div>
              <label style={{marginBottom:"3%"}}>Ship To</label>
              <ShipToSelect handleChange={handleChangeShipTo} value={shipto}/>
            </div>

            <div>
              <label style={{marginBottom:"3%"}}>Shipping Method</label>
              <ShippingMethodSelect shipfrom={shipfrom} shipto={shipto} value={shipmethod} handleChange={handleChangeShipMethod}/>
            </div>
            <div>
              <label style={{marginBottom:"3%"}}>Shipping Method</label>
              <ShippingMethodSelect shipfrom={shipfrom} shipto={shipto} value={shipmethod2} handleChange={handleChangeShipMethod2}/>
            </div>
            <div style={{cursor:"pointer", alignItems:"center", marginTop:"3%", fontSize:"30px"}} onClick={(event) => handleAdd(event)}>+</div>
          </div>
          <div style={{display:"grid", gridTemplateColumns:"15% 15% 10% 10% 10% 15% 15%", textAlign:"center", marginTop:"2%", marginBottom:"1%"}}>
          <div>Range</div>
          <div>Margin</div>
          <div>Fixed Profit</div>
          <div>Breakeven</div>
          <div>Shipping Cost</div>
          <div>MarginType</div>
          <div>ShipMethod Type</div>
          </div>
          {no_formulas.formula.map((item,index)=> {
            return(
              <div style={{display:"grid", gridTemplateColumns:"15% 15% 10% 10% 10% 15% 15%", textAlign:"center", marginTop:"2%", marginBottom:"1%"}}>
                <div>
                  <input value={item.costmin} style={{textAlign:"center", marginLeft:"1%"}} type="number" min="0" max="100" onChange={(event) => handleCostMinChange(event, index)}/>
                  <label>-</label>
                  <input value={item.costmax} style={{textAlign:"center", marginLeft:"1%"}} type="number" min="0" max="100" onChange={(event) => handleCostMaxChange(event, index)}/>
                </div>
                <div>
                  <input value={item.margin} style={{textAlign:"center", marginLeft:"1%"}} type="number" min="0" max="100" onChange={(event) => handleMarginChange(event, index)}/>
                  {margintype === "Percentage"?(
                    <label style={{textAlign:"center", marginLeft:"1%"}}>%</label>
                  ):("")}

                </div>
                <div>
                  <input value={item.fixedprofit} style={{textAlign:"center", marginLeft:"1%"}} type="number" min="0" max="100" onChange={(event) => handleFixedProfitChange(event, index)}/>
                </div>
                <div>
                  <input value={item.breakeven} style={{textAlign:"center", marginLeft:"1%"}} type="number" min="0" max="100" onChange={(event) => handleBreakEvenChange(event, index)}/>
                </div>
                <div>
                  <input value={item.fixedshipping} style={{textAlign:"center", marginLeft:"1%"}} type="number" min="0" max="100" onChange={(event) => handleFixedShippingChange(event, index)}/>
                </div>
                <BasicSelect margintype={item.margintype} handleChange={(event) => handleChange(event,index)}/>
                <ShipTypeSelect shipmethodtype={item.shipmethodtype} handleChange={(event) => handleChangeShipMethodType(event, index)}/>
                <div style={{cursor:"pointer"}} onClick={(event) => handleClick(event)}>-</div>
              </div>
            )
          })}
          {exist?(
            <>
            <Button disabled variant="contained" color="success" onClick={(event) => handleSave(event)}>SAVE</Button>
            <Button variant="contained" color="success" style={{marginLeft:"10%"}} onClick={(event) => handleOverride(event)}>Override Changes</Button>
            </>
          ):(
            <Button variant="contained" color="success" onClick={(event) => handleSave(event)}>SAVE</Button>
          )}

        </Box>
      </Modal>
    </div>
  );
}
