export const changesImageResponse = (products) => [
  ...products.map((el) => {
    return {
      ...el,
      images: [
        ...el.images.map((el1) => {
          return isObject(el1) ? el1 : { src: el1 };
        }),
      ],
    };
  }),
];

export const changesImageStringResponse = (product) => {
      return {
        ...product,
        images: [
          ...product.images.map((el1) => el1.src),
        ],
      };
    }

const isObject = (obj) => obj === Object(obj);
