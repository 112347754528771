import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from 'react-intl'
// import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import SettingsIcon from "@mui/icons-material/Settings";
import LanguageIcon from '@mui/icons-material/Language';
import SalesProLogo from "../assets/images/Logo.png";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  setShowOrders,
  setShowProducts,
  setListingStatus,
  setSubsettings,
  setToolsMenu,
  setLocale,
  setReport
} from "../../actions";

import { Grid, List, ListItemButton, Divider } from "@mui/material";

// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(3),
//     width: "auto",
//   },
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   position: "absolute",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "inherit",
//   "& .MuiInputBase-input": {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("md")]: {
//       width: "20ch",
//     },
//   },
// }));

export default function PrimarySearchAppBar({
  handlePage,
  page,
  handleLogOut,
  handleTheme,
}) {
  const dispatch = useDispatch();
  const show_orders = useSelector((state) => state.show_orders);
  // const loading_orders = useSelector((state) => state.loading_orders);

  //Orders Menu
  const [ordersMenuAnchorEl, setOrdersMenuAnchorEl] = React.useState(null);
  const openOrdersMenu = Boolean(ordersMenuAnchorEl);
  const handleOpenOrdersMenu = (event) => {
    setOrdersMenuAnchorEl(event.currentTarget);
    // handlePage(event, "orders");
  };
  const handleCloseOrdersMenu = () => {
    setOrdersMenuAnchorEl(null);
  };

  //Sourcing Menu
  const [sourcingMenuAnchorEl, setSourcingMenuAnchorEl] = React.useState(null);
  const openSourcingMenu = Boolean(sourcingMenuAnchorEl);
  const handleOpenSourcingMenu = (event) => {
    setSourcingMenuAnchorEl(event.currentTarget);
    // handlePage(event, "sourcing");
  };
  const handleCloseSourcingMenu = () => {
    setSourcingMenuAnchorEl(null);
  };

  //Listings Menu
  const [listingsMenuAnchorEl, setListingsMenuAnchorEl] = React.useState(null);
  const openListingsMenu = Boolean(listingsMenuAnchorEl);
  const handleOpenListingsMenu = (event) => {
    setListingsMenuAnchorEl(event.currentTarget);
    // handlePage(event, "listings");
  };
  const handleCloseListingsMenu = () => {
    setListingsMenuAnchorEl(null);
  };


  //rEPORT Menu
  const [reportMenuAnchorEl, setReportMenuAnchorEl] = React.useState(null);
  const openReportMenu = Boolean(reportMenuAnchorEl);
  const handleOpenReportMenu  = (event) => {
    setReportMenuAnchorEl(event.currentTarget);
  };
  const handleCloseReportMenu = () => {
    setReportMenuAnchorEl(null);
  };

  //Tools Menu
  const [toolsMenuAnchorEl, setToolsMenuAnchorEl] = React.useState(null);
  const openToolsMenu = Boolean(toolsMenuAnchorEl);
  const handleOpenToolsMenu = (event) => {
    setToolsMenuAnchorEl(event.currentTarget);
  };
  const handleCloseToolsMenu = () => {
    setToolsMenuAnchorEl(null);
  };

  //Settings Menu
  const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = React.useState(null);
  const openSettingsMenu = Boolean(settingsMenuAnchorEl);
  const handleOpenSettingsMenu = (event) => {
    setSettingsMenuAnchorEl(event.currentTarget);
    // handlePage(event, "settings");
  };
  const handleCloseSettingsMenu = () => {
    setSettingsMenuAnchorEl(null);
  };

  //Language Menu
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = React.useState(null);
  const openLanguageMenu = Boolean(languageMenuAnchorEl);
  const handleOpenLanguageMenu = (event) => {
    setLanguageMenuAnchorEl(event.currentTarget);
    // handlePage(event, "settings");
  };
  const handleCloseLanguageMenu = () => {
    setLanguageMenuAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderAccountMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleLogOut}>Log out</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Grid container direction="column">
          <Button onClick={(event) => handlePage(event, "dashboard")}>
            Dashboard
          </Button>
          <Button onClick={(event) => handlePage(event, "orders")}>
            Orders
          </Button>
          <Button onClick={(event) => handlePage(event, "sourcing")}>
            Sourcing
          </Button>
          <Button onClick={(event) => handlePage(event, "listings")}>
            Listings
          </Button>
          <Button onClick={(event) => handlePage(event, "inventory")}>
            Inventory
          </Button>
          <Button onClick={(event) => handlePage(event, "customers")}>
            Customers
          </Button>
          <Button onClick={(event) => handlePage(event, "tools")}>Tools</Button>


          <Button onClick={(event) => handlePage(event, "report")}>Reports</Button>

        </Grid>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          height: 40,
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            "&.MuiToolbar-root": {
              minHeight: 40,
            },
          }}
        >
          <Grid item container xs={1} justifyContent="center">
            <img src={SalesProLogo} height="40px" />
          </Grid>

          <Grid item container xs={11}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <Grid item container xs={9}>
                <Grid
                  item
                  sx={{
                    px: "12px",
                    cursor: "pointer",
                    height: "40px",
                    borderLeft: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: (theme) =>
                      page === "dashboard" ? theme.palette.primary.dark : "",
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.primary.dark,
                    },
                  }}
                  onClick={(event) => handlePage(event, "dashboard")}
                >
                  <Typography variant="body1" sx={{ lineHeight: "40px" }}>
                    <FormattedMessage id="nav.dashboard" />
                  </Typography>
                </Grid>

                <Grid
                  item
                  sx={{
                    pl: "12px",
                    pr: "6px",
                    cursor: "pointer",
                    height: "40px",
                    borderLeft: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: (theme) =>
                      page === "orders" ? theme.palette.primary.dark : "",
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.primary.dark,
                    },
                  }}
                >
                  <Grid onClick={handleOpenOrdersMenu}>
                    <Typography variant="body1" sx={{ lineHeight: "40px" }}>
                      <FormattedMessage id="nav.orders" />
                      <ArrowDropDownIcon />
                    </Typography>
                  </Grid>

                  <Menu
                    id="orders-menu"
                    anchorEl={ordersMenuAnchorEl}
                    open={openOrdersMenu}
                    onClose={handleCloseOrdersMenu}
                    MenuListProps={{
                      "aria-labelledby": "orders-button",
                      sx: {
                        py: 0,
                        width: "180px",
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    sx={{ mt: 1 }}
                  >
                    <Grid
                      item
                      sx={{
                        p: 0.75,
                        pl: 1,
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: 700 }}>
                        Order Manager
                      </Typography>
                    </Grid>

                    <Divider
                      sx={{ color: (theme) => theme.palette.neutral[300] }}
                    />

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseOrdersMenu();
                        handlePage(event, "orders");
                        dispatch(setShowOrders(""));
                        window.localStorage.setItem("show_orders", "");
                      }}
                    >
                      <Typography variant="body2">All Orders</Typography>
                    </ListItemButton>

                    <Divider
                      sx={{ color: (theme) => theme.palette.neutral[300] }}
                    />

                    <Grid
                      sx={{
                        p: 0.75,
                        pl: 2,
                      }}
                    >
                      <Typography variant="body2">Purchase Orders</Typography>
                    </Grid>

                    <Divider
                      sx={{ color: (theme) => theme.palette.neutral[300] }}
                    />

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseOrdersMenu();
                        handlePage(event, "orders");
                        dispatch(setShowOrders(null));
                        window.localStorage.setItem("show_orders", null);
                      }}
                    >
                      <Typography variant="body2">To Purchase</Typography>
                    </ListItemButton>

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseOrdersMenu();
                        handlePage(event, "orders");
                        dispatch(setShowOrders("purchased"));
                        window.localStorage.setItem("show_orders", "purchased");
                      }}
                    >
                      <Typography variant="body2">Purchased</Typography>
                    </ListItemButton>

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseOrdersMenu();
                        handlePage(event, "orders");
                        dispatch(setShowOrders("fulfilled"));
                        window.localStorage.setItem("show_orders", "fulfilled");
                      }}
                    >
                      <Typography variant="body2">Shipped</Typography>
                    </ListItemButton>

                    <Divider
                      sx={{ color: (theme) => theme.palette.neutral[300] }}
                    />

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseOrdersMenu();
                        handlePage(event, "orders");
                        dispatch(setShowOrders("refunded"));
                        window.localStorage.setItem("show_orders", "refunded");
                      }}
                    >
                      <Typography variant="body2">Cancelled</Typography>
                    </ListItemButton>

                    <Divider
                      sx={{ color: (theme) => theme.palette.neutral[300] }}
                    />

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseOrdersMenu();
                        handlePage(event, "orders");
                        dispatch(setShowOrders("archived"));
                        window.localStorage.setItem("show_orders", "archived");
                      }}
                    >
                      <Typography variant="body2">Archived</Typography>
                    </ListItemButton>
                  </Menu>
                </Grid>

                <Grid
                  item
                  sx={{
                    pl: "12px",
                    pr: "6px",
                    cursor: "pointer",
                    height: "40px",
                    borderLeft: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: (theme) =>
                      page === "sourcing" ? theme.palette.primary.dark : "",
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.primary.dark,
                    },
                  }}
                >
                  <Grid onClick={handleOpenSourcingMenu}>
                    <Typography variant="body1" sx={{ lineHeight: "40px" }}>
                      <FormattedMessage id="nav.sourcing" />
                      <ArrowDropDownIcon />
                    </Typography>
                  </Grid>

                  <Menu
                    id="sourcing-menu"
                    anchorEl={sourcingMenuAnchorEl}
                    open={openSourcingMenu}
                    onClose={handleCloseSourcingMenu}
                    MenuListProps={{
                      "aria-labelledby": "sourcing-button",
                      sx: {
                        py: 0,
                        width: "180px",
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    sx={{ mt: 1 }}
                  >
                    <Grid
                      item
                      sx={{
                        p: 0.75,
                        pl: 1,
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: 700 }}>
                        Sourcing
                      </Typography>
                    </Grid>

                    <Divider
                      sx={{ color: (theme) => theme.palette.neutral[300] }}
                    />

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseSourcingMenu();
                        handlePage(event, "sourcing");
                        dispatch(setShowProducts("new"));
                        window.localStorage.setItem("show_products", "new");
                      }}
                    >
                      <Typography variant="body2">Product Importer</Typography>
                    </ListItemButton>

                    <Divider
                      sx={{ color: (theme) => theme.palette.neutral[300] }}
                    />

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseSourcingMenu();
                        handlePage(event, "sourcing");
                        dispatch(setShowProducts("imported"));
                        window.localStorage.setItem(
                          "show_products",
                          "imported"
                        );
                      }}
                    >
                      <Typography variant="body2">Imported Products</Typography>
                    </ListItemButton>

                    <Divider
                      sx={{ color: (theme) => theme.palette.neutral[300] }}
                    />

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseSourcingMenu();
                        handlePage(event, "sourcing");
                        dispatch(setShowProducts("exported"));
                        window.localStorage.setItem(
                          "show_products",
                          "exported"
                        );
                      }}
                    >
                      <Typography variant="body2">Exported Products</Typography>
                    </ListItemButton>

                    <Divider
                      sx={{ color: (theme) => theme.palette.neutral[300] }}
                    />

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseSourcingMenu();
                        handlePage(event, "sourcing");
                        dispatch(setShowProducts("deleted"));
                        window.localStorage.setItem("show_products", "deleted");
                      }}
                    >
                      <Typography variant="body2">Deleted Products</Typography>
                    </ListItemButton>
                  </Menu>
                </Grid>

                <Grid
                  item
                  sx={{
                    pl: "12px",
                    pr: "6px",
                    cursor: "pointer",
                    height: "40px",
                    borderLeft: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: (theme) =>
                      page === "listings" ? theme.palette.primary.dark : "",
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.primary.dark,
                    },
                  }}
                >
                  <Grid onClick={handleOpenListingsMenu}>
                    <Typography variant="body1" sx={{ lineHeight: "40px" }}>
                      <FormattedMessage id="nav.listing" />
                      <ArrowDropDownIcon />
                    </Typography>
                  </Grid>

                  <Menu
                    id="Listings-menu"
                    anchorEl={listingsMenuAnchorEl}
                    open={openListingsMenu}
                    onClose={handleCloseListingsMenu}
                    MenuListProps={{
                      "aria-labelledby": "Listings-button",
                      sx: {
                        py: 0,
                        width: "180px",
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    sx={{ mt: 1 }}
                  >
                    <Grid
                      item
                      sx={{
                        p: 0.75,
                        pl: 1,
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: 700 }}>
                        Listing Manager
                      </Typography>
                    </Grid>

                    <Divider
                      sx={{ color: (theme) => theme.palette.neutral[300] }}
                    />

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseListingsMenu();
                        handlePage(event, "listings");
                        dispatch(setListingStatus(""));
                        window.localStorage.setItem("listing_status", "");
                      }}
                    >
                      <Typography variant="body2">All Listings</Typography>
                    </ListItemButton>

                    <Divider
                      sx={{ color: (theme) => theme.palette.neutral[300] }}
                    />

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseListingsMenu();
                        handlePage(event, "listings");
                        dispatch(setListingStatus("draft"));
                        window.localStorage.setItem("listing_status", "draft");
                      }}
                    >
                      <Typography variant="body2">Draft Listings</Typography>
                    </ListItemButton>

                    <Divider
                      sx={{ color: (theme) => theme.palette.neutral[300] }}
                    />

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseListingsMenu();
                        handlePage(event, "listings");
                        dispatch(setListingStatus("active"));
                        window.localStorage.setItem("listing_status", "active");
                      }}
                    >
                      <Typography variant="body2">Active Listings</Typography>
                    </ListItemButton>

                    {/* <Divider
                      sx={{ color: (theme) => theme.palette.neutral[300] }}
                    />

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseListingsMenu();
                        handlePage(event, "listings");
                        dispatch(setListingStatus("exported"));
                        window.localStorage.setItem(
                          "listing_status",
                          "exported"
                        );
                      }}
                    >
                      <Typography variant="body2">Exported Listings</Typography>
                    </ListItemButton> */}

                    <Divider
                      sx={{ color: (theme) => theme.palette.neutral[300] }}
                    />

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseListingsMenu();
                        handlePage(event, "listings");
                        dispatch(setListingStatus("deleted"));
                        window.localStorage.setItem(
                          "listing_status",
                          "deleted"
                        );
                      }}
                    >
                      <Typography variant="body2">Deleted Listings</Typography>
                    </ListItemButton>
                  </Menu>
                </Grid>

                <Grid
                  item
                  sx={{
                    px: "12px",
                    cursor: "pointer",
                    height: "40px",
                    borderLeft: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: (theme) =>
                      page === "inventory" ? theme.palette.primary.dark : "",
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.primary.dark,
                    },
                  }}
                  onClick={(event) => handlePage(event, "inventory")}
                >
                  <Typography variant="body1" sx={{ lineHeight: "40px" }}>
                    <FormattedMessage id="nav.inventory" />
                  </Typography>
                </Grid>

                <Grid
                  item
                  sx={{
                    px: "12px",
                    cursor: "pointer",
                    height: "40px",
                    borderLeft: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: (theme) =>
                      page === "customers" ? theme.palette.primary.dark : "",
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.primary.dark,
                    },
                  }}
                  onClick={(event) => handlePage(event, "customers")}
                >
                  <Typography variant="body1" sx={{ lineHeight: "40px" }}>
                    <FormattedMessage id="nav.customers" />
                  </Typography>
                </Grid>

                <Grid
                  item
                  sx={{
                    pl: "12px",
                    pr: "6px",
                    cursor: "pointer",
                    height: "40px",
                    borderLeft: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: (theme) =>
                      page === "tools" ? theme.palette.primary.dark : "",
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.primary.dark,
                    },
                  }}
                >
                  <Grid onClick={handleOpenToolsMenu}>
                    <Typography variant="body1" sx={{ lineHeight: "40px" }}>
                      <FormattedMessage id="nav.tools" />
                      <ArrowDropDownIcon />
                    </Typography>
                  </Grid>

                  <Menu
                    id="tools-menu"
                    anchorEl={toolsMenuAnchorEl}
                    open={openToolsMenu}
                    onClose={handleCloseToolsMenu}
                    MenuListProps={{
                      "aria-labelledby": "tools-button",
                      sx: {
                        py: 0,
                        width: "180px",
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    sx={{ mt: 1 }}
                  >
                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseToolsMenu();
                        handlePage(event, "tools");
                        dispatch(setToolsMenu("aliexpress_mapping"));
                      }}
                    >
                      <Typography variant="body2">
                        AliExpress Mapping
                      </Typography>
                    </ListItemButton>

                   {false && <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseToolsMenu();
                        handlePage(event, "tools");
                        dispatch(setToolsMenu("kogan_mapping"));
                      }}
                    >
                      <Typography variant="body2">Kogan Mapping</Typography>
                    </ListItemButton>}

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseToolsMenu();
                        handlePage(event, "tools");
                        dispatch(setToolsMenu("kogan_product_updater"));
                      }}
                    >
                      <Typography variant="body2">
                        Kogan Product Updater
                      </Typography>
                    </ListItemButton>

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseToolsMenu();
                        handlePage(event, "tools");
                        dispatch(setToolsMenu("sku_mapper"));
                      }}
                    >
                      <Typography variant="body2">Sku Mapper</Typography>
                    </ListItemButton>

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseToolsMenu();
                        handlePage(event, "tools");
                        dispatch(setToolsMenu("sku_checker"));
                      }}
                    >
                      <Typography variant="body2">Sku Checker</Typography>
                    </ListItemButton>

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseToolsMenu();
                        handlePage(event, "tools");
                        dispatch(setToolsMenu("dsz_mapping"));
                      }}
                    >
                      <Typography variant="body2">DSZ Mapping</Typography>
                    </ListItemButton>
                  </Menu>
                </Grid>


                <Grid
                  item
                  sx={{
                    pl: "12px",
                    pr: "6px",
                    cursor: "pointer",
                    height: "40px",
                    borderLeft: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: (theme) =>
                      page === "tools" ? theme.palette.primary.dark : "",
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.primary.dark,
                    },
                  }}
                >
                  <Grid onClick={handleOpenReportMenu}>
                    <Typography variant="body1" sx={{ lineHeight: "40px" }}>
                      <FormattedMessage id="nav.report" />
                      <ArrowDropDownIcon />
                    </Typography>
                  </Grid>

                  <Menu
                    id="report-menu"
                    anchorEl={reportMenuAnchorEl}
                    open={openReportMenu}
                    onClose={handleCloseReportMenu}
                    MenuListProps={{
                      "aria-labelledby": "tools-button",
                      sx: {
                        py: 0,
                        width: "180px",
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    sx={{ mt: 1 }}
                  >
                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseReportMenu();
                        handlePage(event, "report");
                        dispatch(setReport("export_report"));
                      }}
                    >
                      <Typography variant="body2">
                        Product Export Report
                      </Typography>
                    </ListItemButton>

                    
                  </Menu>
                </Grid>
              </Grid>

              <Grid item container xs={3} justifyContent="flex-end">
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                    borderLeft: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: (theme) =>
                      page === "settings" ? theme.palette.primary.dark : "",
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.primary.dark,
                    },
                  }}
                >
                  <SettingsIcon
                    fontSize="small"
                    onClick={handleOpenSettingsMenu}
                  />

                  <Menu
                    id="settings-menu"
                    anchorEl={settingsMenuAnchorEl}
                    open={openSettingsMenu}
                    onClose={handleCloseSettingsMenu}
                    MenuListProps={{
                      "aria-labelledby": "settings-button",
                      sx: {
                        width: "180px",
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    sx={{ mt: 2 }}
                  >
                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseSettingsMenu();
                        handlePage(event, "settings");
                        dispatch(setSubsettings("order"));
                        // window.localStorage.setItem("show_orders", "");
                      }}
                    >
                      <Typography variant="body2">Order Settings</Typography>
                    </ListItemButton>

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseSettingsMenu();
                        handlePage(event, "settings");
                        dispatch(setSubsettings("inventory"));
                        // window.localStorage.setItem("show_orders", null);
                      }}
                    >
                      <Typography variant="body2">
                        Inventory Settings
                      </Typography>
                    </ListItemButton>

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseSettingsMenu();
                        handlePage(event, "settings");
                        dispatch(setSubsettings("dropship"));
                        // window.localStorage.setItem("show_orders", "purchased");
                      }}
                    >
                      <Typography variant="body2">Dropship Settings</Typography>
                    </ListItemButton>

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseSettingsMenu();
                        handlePage(event, "settings");
                        dispatch(setSubsettings("listing"));
                        // window.localStorage.setItem("show_orders", "fulfilled");
                      }}
                    >
                      <Typography variant="body2">Listing Settings</Typography>
                    </ListItemButton>

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseSettingsMenu();
                        handlePage(event, "settings");
                        dispatch(setSubsettings("company"));
                        // window.localStorage.setItem("show_orders", "refunded");
                      }}
                    >
                      <Typography variant="body2">Company</Typography>
                    </ListItemButton>

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseSettingsMenu();
                        handlePage(event, "settings");
                        dispatch(setSubsettings("advanced"));
                        // window.localStorage.setItem("show_orders", "refunded");
                      }}
                    >
                      <Typography variant="body2">Advanced</Typography>
                    </ListItemButton>

                    <Divider
                      sx={{ color: (theme) => theme.palette.neutral[300] }}
                    />

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseSettingsMenu();
                        handlePage(event, "settings");
                        dispatch(setSubsettings("channels"));
                        // window.localStorage.setItem("show_orders", "refunded");
                      }}
                    >
                      <Typography variant="body2">Manage Channels</Typography>
                    </ListItemButton>

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseSettingsMenu();
                        handlePage(event, "settings");
                        dispatch(setSubsettings("suppliers"));
                        // window.localStorage.setItem("show_orders", "refunded");
                      }}
                    >
                      <Typography variant="body2">Manage Suppliers</Typography>
                    </ListItemButton>

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseSettingsMenu();
                        handlePage(event, "settings");
                        dispatch(setSubsettings("carriers"));
                        // window.localStorage.setItem("show_orders", "refunded");
                      }}
                    >
                      <Typography variant="body2">Manage Carriers</Typography>
                    </ListItemButton>

                    <Divider
                      sx={{ color: (theme) => theme.palette.neutral[300] }}
                    />

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseSettingsMenu();
                        handlePage(event, "settings");
                        dispatch(setSubsettings("my_account"));
                        // window.localStorage.setItem("show_orders", "refunded");
                      }}
                    >
                      <Typography variant="body2">My Account</Typography>
                    </ListItemButton>

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseSettingsMenu();
                        handlePage(event, "settings");
                        dispatch(setSubsettings("staff_accounts"));
                        // window.localStorage.setItem("show_orders", "refunded");
                      }}
                    >
                      <Typography variant="body2">Staff Accounts</Typography>
                    </ListItemButton>

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseSettingsMenu();
                        handlePage(event, "settings");
                        dispatch(setSubsettings("change_password"));
                        // window.localStorage.setItem("show_orders", "refunded");
                      }}
                    >
                      <Typography variant="body2">Change Password</Typography>
                    </ListItemButton>

                    <Divider
                      sx={{ color: (theme) => theme.palette.neutral[300] }}
                    />

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseSettingsMenu();
                        handlePage(event, "settings");
                        dispatch(setSubsettings("billing_details"));
                      }}
                    >
                      <Typography variant="body2">Billing Details</Typography>
                    </ListItemButton>
                  </Menu>
                </Grid>

                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                    borderLeft: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: (theme) =>
                      openLanguageMenu && theme.palette.primary.dark,
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.primary.dark,
                    },
                  }}
                >
                  <LanguageIcon
                    fontSize="small"
                    onClick={handleOpenLanguageMenu}
                  />

                  <Menu
                    id="Language-menu"
                    anchorEl={languageMenuAnchorEl}
                    open={openLanguageMenu}
                    onClose={handleCloseLanguageMenu}
                    MenuListProps={{
                      "aria-labelledby": "Language-button",
                      sx: {
                        width: "180px",
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    sx={{ mt: 2 }}
                  >


                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseLanguageMenu();
                        dispatch(setLocale("en-US"));
                        window.localStorage.setItem("language", "en-US")
                      }}
                    >
                      <Typography variant="body2">
                        English
                      </Typography>
                    </ListItemButton>

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseLanguageMenu();
                        dispatch(setLocale("hi-IN"));
                        window.localStorage.setItem("language", "hi-IN")
                      }}
                    >
                      <Typography variant="body2">हिंदी</Typography>
                    </ListItemButton>

                    <ListItemButton
                      sx={{
                        p: 0.75,
                        pl: 2,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.light,
                          borderRight: (theme) =>
                            `4px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      onClick={(event) => {
                        handleCloseLanguageMenu();
                        dispatch(setLocale("zh-CN"));
                        window.localStorage.setItem("language", "zh-CN")
                      }}
                    >
                      <Typography variant="body2">简体中文</Typography>
                    </ListItemButton>

                  </Menu>
                </Grid>

                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                    borderLeft: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: (theme) =>
                      page === "help" ? theme.palette.primary.dark : "",
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.primary.dark,
                    },
                  }}
                // onClick={(event) => handlePage(event, "help")}
                >
                  <HelpOutlineIcon fontSize="small" />
                </Grid>

                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                    borderLeft: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: (theme) =>
                      page === "notification" ? theme.palette.primary.dark : "",
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.primary.dark,
                    },
                  }}
                // onClick={(event) => handlePage(event, "notification")}
                >
                  <Badge
                    badgeContent={6}
                    color="error"
                    sx={{
                      "& span": {
                        minWidth: "16px",
                        height: "16px",
                      },
                    }}
                  >
                    <NotificationsIcon fontSize="small" />
                  </Badge>
                </Grid>

                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                    borderLeft: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.primary.dark,
                    },
                  }}
                  onClick={(event) => handleTheme()}
                >
                  <Brightness4Icon fontSize="small" />
                </Grid>

                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                    borderLeft: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: (theme) =>
                      page === "account" ? theme.palette.primary.dark : "",
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.primary.dark,
                    },
                  }}
                  onClick={handleProfileMenuOpen}
                >
                  <PersonIcon fontSize="small" />
                </Grid>
              </Grid>
            </Grid>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }} />

            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Grid>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderAccountMenu}
    </Box>
  );
}
