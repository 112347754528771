import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControlLabel, Checkbox } from '@mui/material';

const StoreCheckBox = ({open, setOpen, checked, setChecked, handleImportSunsky}) => {
    
  const handleChange = (event) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if(!(checked.nz || checked.au || checked.gk || checked.us))
      {
        return alert("Please Select Store :(")
      }
    handleImportSunsky()
    console.log('Selected values:', checked);
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select Options</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={<Checkbox checked={checked.nz} onChange={handleChange} name="nz" />}
            label="NZ"
          />
          <FormControlLabel
            control={<Checkbox checked={checked.au} onChange={handleChange} name="au" />}
            label="AU"
          />
          <FormControlLabel
            control={<Checkbox checked={checked.gk} onChange={handleChange} name="gk" />}
            label="GK"
          />
          <FormControlLabel
            control={<Checkbox checked={checked.us} onChange={handleChange} name="us" />}
            label="US"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StoreCheckBox;
