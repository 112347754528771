import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import ControlledCheckbox from "./aliexpresscheckbox.js";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  setPurchasingOrder,
  setSearch,
  setStore,
  setAliExpressOrders,
  setArticles,
  setSubsettings,
  setOrdersNzShopify,
  setLoadingOrders,
  setSelectedSupplier,
  setDropshipOrders,
  setOrdersFromTo,
  setShowOrders,
  setImportTotalPages,
  setCustomSheet,
  setFragrancexOrders,
} from "../../actions";

export default function AliExpressProductsLayout({
  product,
  handleImport,
  selectedProducts,
  setSelectedProducts,
  fetchproducts,
}) {
  const [editCustomer, setEditCustomer] = useState(false);
  const [editFirstName, setEditFirstName] = useState(false);
  const [editLastName, setEditLastName] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [editAddress1, setEditAddress1] = useState(false);
  const [editAddress2, setEditAddress2] = useState(false);
  const [editCity, setEditCity] = useState(false);
  const [editProvince, setEditProvince] = useState(false);
  const [editCountry, setEditCountry] = useState(false);
  const [editZip, setEditZip] = useState(false);
  const [editCompany, setEditCompany] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [editSku, setEditSku] = useState(false);
  const purchasingorder = useSelector((state) => state.purchasingorder);
  const selected_supplier = useSelector((state) => state.selected_supplier);
  const store = useSelector((state) => state.store);

  const CalculatePrice = () => {
    let prices = [];
    if (
      product.result &&
      product.result.ae_item_sku_info_dtos &&
      Array.isArray(product.result.ae_item_sku_info_dtos.ae_item_sku_info_d_t_o)
    ) {
      product.result.ae_item_sku_info_dtos.ae_item_sku_info_d_t_o.map(
        (item) => {
          prices.push(parseFloat(item.sku_price));
        }
      );
      return `${Math.min(...prices)} - ${Math.max(...prices)}`;
    }
  };

  const CalculateStock = () => {
    let prices = [];
    if (
      product.result &&
      product.result.ae_item_sku_info_dtos &&
      Array.isArray(product.result.ae_item_sku_info_dtos.ae_item_sku_info_d_t_o)
    ) {
      product.result.ae_item_sku_info_dtos.ae_item_sku_info_d_t_o.map(
        (item) => {
          prices.push(parseFloat(item.sku_available_stock));
        }
      );
      return `${Math.min(...prices)} - ${Math.max(...prices)}`;
    }
  };

  return (
    <Card style={{ zIndex: "1000", marginTop: "1%", backgroundColor: "white" }}>
      <Grid item xs={12} sm={12} lg={12}>
        <Card
          style={{ zIndex: "1000", marginTop: "1%", backgroundColor: "white" }}
        >
          <div
            style={{
              display: "Grid",
              gridTemplateColumns: "2% 4% 24% 8% 8% 10% 8% 6% 10% 10% 6% 4%",
              gridGap: "5px",
              marginTop: "10px",
              marginBottom: "10px",
              fontSize: "14px",
            }}
          >
            <div style={{ textAlign: "left" }}>
              <ControlledCheckbox
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                product={product}
              />
            </div>
            <div style={{ textAlign: "center" }}>
              <Box sx={{ marginRight: "10px", border: "1px solid #e3e3e3" }}>
                <img
                  src={
                    product.result
                      ? product.result.ae_multimedia_info_dto.image_urls.split(
                        ";"
                      )[0]
                      : ""
                  }
                  alt=""
                  height="100"
                  width="80"
                />
              </Box>
            </div>
            <div>
              <div>
                {product.result && product.result.ae_item_base_info_dto.subject}
              </div>
              <div style={{ color: "#999797", marginTop: "10px" }}>
                <a
                  href={""}
                  target="_blank"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {product.result &&
                    product.result.ae_item_base_info_dto.product_id}
                </a>
              </div>
            </div>

            {/* Barcode */}
            <Grid container justifyContent="center">
              <Typography variant="body2">
                {product.barcode === null || product.barcode === ""
                  ? "N/A"
                  : product.barcode}
              </Typography>
            </Grid>

            {/* Weight */}
            <Grid container justifyContent="center">
              <Typography variant="body2">
                {product.weight === null || product.weight === ""
                  ? "N/A"
                  : `${product.weight}kg`}
              </Typography>
            </Grid>

            <div>
              <div style={{ textAlign: "center" }}>USD ${CalculatePrice()}</div>
            </div>
            <div style={{ textAlign: "center" }}></div>
            <div style={{ textAlign: "center" }}>
              {product.result &&
                product.result.ae_item_sku_info_dtos.ae_item_sku_info_d_t_o &&
                product.result.ae_item_sku_info_dtos.ae_item_sku_info_d_t_o
                  .length}
            </div>
            <div style={{ textAlign: "center" }}>
              <a
                href="https://matterhorn-wholesale.com/"
                target="_blank"
                style={{ textDecoration: "none", color: "black" }}
              >
                AliExpress
              </a>
            </div>
            <div style={{ textAlign: "center" }}>
              <div style={{ textAlign: "center" }}>{CalculateStock()}</div>
            </div>
            <div style={{ textAlign: "center" }}>
              {product.import_status === null && "Not Imported"}
              {product.import_status === "imported" && "Imported"}
              {product.import_status === "exported" && "Exported"}
              {product.import_status === "deleted" && "Deleted"}
            </div>
            {
              // <div style={{textAlign:"center"}}>
              //   <Typography style={{color:"#5b5b5e"}}><Button variant="contained" size="small" onClick={(event) => handleImport(product)}>Import</Button></Typography>
              // </div>
            }
          </div>
        </Card>
      </Grid>
    </Card>
  );
}
