import React from "react";
import BasicSelect from "../../select/select";
import { useSelector, useDispatch } from "react-redux";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CustomizedRadioGroup from "../CustomizedRadioGroup";
import { setSettingsItem } from "../../../actions";

import { Card, Grid, Typography, Button } from "@mui/material";

export const GeneralSettingsDetail = () => {
  const store_menu = useSelector((state) => state.store_menu);
  const sku_preference_menu = useSelector((state) => state.sku_preference_menu);
  const description_menu = useSelector((state) => state.description_menu);
  const dispatch = useDispatch();

  return (
    <Card elevation={0} sx={{ my: 1, minHeight: "90vh" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          px: 4,
          py: 2,
          borderBottom: (theme) => `1px solid ${theme.palette.border}`,
        }}
      >
        <Button
          size="small"
          variant="text"
          startIcon={<ChevronLeftIcon />}
          onClick={() => dispatch(setSettingsItem(""))}
          sx={{
            "& .MuiButton-startIcon": {
              mr: 0.5,
            },
          }}
        >
          Back
        </Button>
        <Typography variant="h6">General Settings</Typography>
        <Button size="small" variant="contained" disabled>
          Save
        </Button>
      </Grid>

      <Grid
        container
        p={4}
        xs={8}
        flexDirection="column"
        alignContent="flex-start"
      >
        <Grid
          container
          sx={{
            py: 2,
            borderBottom: (theme) => `1px dashed ${theme.palette.border}`,
          }}
        >
          <Grid item xs={3}>
            <Typography variant="body1">Default Store</Typography>
          </Grid>
          <Grid item xs={9}>
            {/* <BasicSelect
              label="Select store"
              menu={store_menu}
              type="choosestore"
            /> */}

            <CustomizedRadioGroup
              columns={3}
              menu={store_menu}
              type="choosestore"
            />
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            py: 2,
            borderBottom: (theme) => `1px dashed ${theme.palette.border}`,
          }}
        >
          <Grid item xs={3}>
            <Typography variant="body1">SKU Preference</Typography>
          </Grid>
          <Grid item xs={9}>
            {/* <BasicSelect label="Select store" menu={sku_preference_menu} /> */}
            <CustomizedRadioGroup
              columns={2}
              menu={sku_preference_menu}
              type="skupreference"
            />
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            py: 2,
            borderBottom: (theme) => `1px dashed ${theme.palette.border}`,
          }}
        >
          <Grid item xs={3}>
            <Typography variant="body1">Description Preference</Typography>
          </Grid>
          <Grid item xs={9}>
            {/* <BasicSelect
              label="Choose your preference"
              menu={description_menu}
              type="description"
            /> */}

            <CustomizedRadioGroup
              columns={4}
              menu={description_menu}
              type="description"
            />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
