import React, { useEffect, useState } from "react";
import { Grid, Chip, TextField, Autocomplete } from "@mui/material";
import { SmallContainedButton } from "../common/SmallContainedButton";
import { InStockCheckbox } from "./instockcheckbox";

export default function IDropshipCategorySelect({
  selectedIDropshipCategoryLevel1,
  setSelectedIDropshipCategoryLevel1,
  selectedIDropshipCategoryLevel2,
  setSelectedIDropshipCategoryLevel2,
  selectedIDropshipBrand,
  selectedIDropshipShippingMethod,
  setSelectedIDropshipBrand,
  setSelectedIDropshipShippingMethod,
  skulist,
  setSkuList,
  filterby,
  loading_products,
  fetchproductcount,
  setFetchProductCount,
}) {
  const [categorieslevel1, setCategoriesLevel1] = useState([]);
  const [categorieslevel2, setCategoriesLevel2] = useState([]);
  const [iDropshipBrands, setIDropshipBrands] = useState([]);

  const shippingMethods = ["Free Shipping", "Ship By AMS"];

  useEffect(() => {
    fetch(
      "https://api.saleprodev.com/productimporter/idropshipcategorieslevel1"
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCategoriesLevel1(response.categories_level_1.sort());
      });

    fetch("https://api.saleprodev.com/productimporter/idropshipbrands")
      .then((response) => response.json())
      .then((response) => {
        console.log("brands", response);
        setIDropshipBrands(response.brands.sort());
      });
  }, []);

  useEffect(() => {
    console.log(
      "selectedIDropshipCategoryLevel1",
      selectedIDropshipCategoryLevel1
    );
    setSelectedIDropshipCategoryLevel2("Select a Category");
    fetch(
      "https://api.saleprodev.com/productimporter/idropshipcategorieslevel2",
      {
        method: "post",
        body: JSON.stringify({ category: selectedIDropshipCategoryLevel1 }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("categories_level_2response", response);
        setCategoriesLevel2(response.categories_level_2.sort());
      });
  }, [selectedIDropshipCategoryLevel1]);

  const handleChange = (event, value) => {
    console.log("value", value);
    if (value === null) {
      setSelectedIDropshipCategoryLevel1("Select a Category");
    } else {
      setSelectedIDropshipCategoryLevel1(value);
    }
  };

  const handleChange2 = (event, value) => {
    console.log("value", value);
    if (value === null) {
      setSelectedIDropshipCategoryLevel2("Select a Category");
    } else {
      setSelectedIDropshipCategoryLevel2(value);
    }
  };

  const handleChangeBrand = (event, value) => {
    console.log("Brand", value);
    if (value === null) {
      setSelectedIDropshipBrand("Select a Brand");
    } else {
      setSelectedIDropshipBrand(value);
    }
  };

  const handleChangeShippingMethod = (event, value) => {
    console.log("ShippingMethod", value);
    if (value === null) {
      setSelectedIDropshipShippingMethod();
    } else {
      setSelectedIDropshipShippingMethod(value);
    }
  };

  const defaultProps1 = {
    options: categorieslevel1,
    getOptionLabel: (option) => option,
  };

  const defaultProps2 = {
    options: categorieslevel2,
    getOptionLabel: (option) => option,
  };

  const defaultProps3 = {
    options: iDropshipBrands,
    getOptionLabel: (option) => option,
  };

  const defaultProps4 = {
    options: shippingMethods,
    getOptionLabel: (option) => option,
  };

  return filterby === "category" ? (
    <>
      <Autocomplete
        size="small"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            p: "0px!important",
            height: 26,
          },
        }}
        {...defaultProps1}
        value={selectedIDropshipCategoryLevel1}
        onChange={(event, value) => handleChange(event, value)}
        renderInput={(params) => <TextField {...params} />}
      />

      <Autocomplete
        size="small"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            p: "0px!important",
            height: 26,
          },
        }}
        {...defaultProps2}
        value={selectedIDropshipCategoryLevel2}
        onChange={(event, value) => handleChange2(event, value)}
        renderInput={(params) => <TextField {...params} />}
      />

      <Autocomplete
        size="small"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        {...defaultProps3}
        value={selectedIDropshipBrand}
        onChange={(event, value) => handleChangeBrand(event, value)}
        renderInput={(params) => <TextField {...params} />}
      />

      <Autocomplete
        size="small"
        sx={{
          mr: 0.5,
          width: "200px",
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
          "& .MuiFormControl-root, .MuiOutlinedInput-root": {
            height: 26,
            p: "0px!important",
          },
        }}
        {...defaultProps4}
        value={selectedIDropshipShippingMethod}
        onChange={(event, value) => handleChangeShippingMethod(event, value)}
        renderInput={(params) => (
          <TextField {...params} placeholder="Select Shipping" />
        )}
      />

      <InStockCheckbox />
    </>
  ) : (
    <>
      <Grid item sx={{ position: "relative" }}>
        <TextField
          multiline
          maxRows={5}
          placeholder="Enter SKUs"
          onChange={(event) => setSkuList(event.target.value)}
          sx={{
            mr: 0.5,
            width: "500px",
            fontSize: "0.8rem",
            "& legend": {
              width: "unset",
            },
            "& .MuiFormControl-root, .MuiOutlinedInput-root": {
              p: "0px!important",
              minHeight: 26,
            },
          }}
        />

        {skulist === "" ? (
          ""
        ) : (
          <Chip
            size="small"
            color="primary"
            variant="outlined"
            label={`${skulist.split(/\r\n|\r|\n/).length} Items`}
            sx={{
              height: 20,
              position: "absolute",
              transform:
                skulist.split(/\r\n|\r|\n/).length <= 1
                  ? "translate(-95px, 3px)"
                  : "translate(-95px, 6px)",
            }}
          />
        )}
      </Grid>

      <Grid item>
        <SmallContainedButton
          width="60px"
          disabled={loading_products ? true : false}
          onClick={(event) => setFetchProductCount(fetchproductcount + 1)}
        >
          Find
        </SmallContainedButton>
      </Grid>
    </>
  );
}
