import * as React from "react";

export default function GriffatiMainCheckbox({
  products,
  showProducts,
  selectedProducts,
  setSelectedProducts,
}) {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      let array = [];
      products.map((product) => {
        let element = document.getElementById(
          showProducts === "new" ? product.ingrampartnumber : product.sku
        );
        if (element.checked) {
          console.log("do nothing");
        } else {
          element.checked = true;
        }

        array.push(product);
      });
      console.log("array", array);
      setSelectedProducts([...array]);
    } else {
      products.map((product) => {
        let element = document.getElementById(
          showProducts === "new" ? product.ingrampartnumber : product.sku
        );
        if (element.checked) {
          element.checked = false;
        }
      });
      setSelectedProducts([]);
    }
  };

  return <input type="checkbox" onChange={handleChange} id="maincheckbox" />;
}
