import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import ToggleButtons from "../../togglebutton/togglebutton";
import AdvancedFormula from "../../generalsettings/advancedformula";
import BasicModal from "../../generalsettings/modal";
import { setSettingsItem } from "../../../actions";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";

import { Card, Grid, Typography, Button } from "@mui/material";

export const PricingRulesDetail = () => {
  const dispatch = useDispatch();
  const pricing_rules = useSelector((state) => state.pricing_rules);
  const pricing_formula = useSelector((state) => state.pricing_formula);
  const pricing_formula_selected = useSelector(
    (state) => state.pricing_formula_selected
  );
  const pricing_rules_selected = useSelector(
    (state) => state.pricing_rules_selected
  );

  const [count, setCount] = useState(0);
  const [existingformulas, setExistingFormulas] = useState([]);
  const [adv_formulas, setAdvFormulas] = useState([
    {
      costmin: 0,
      costmax: 0,
      margin: 0,
      fixedprofit: 0,
      breakeven: 0,
      margintype: "Percentage",
      shipmethodtype: "Fixed Value",
    },
  ]);

  const [no_formulas, setNoFormulas] = useState({
    supplier: "",
    shipfrom: "",
    shipto: "",
    shipmethod: "",
    shipmethod2: "",
    formula: [
      {
        costmin: 0,
        costmax: 0,
        margin: 0,
        fixedprofit: 0,
        breakeven: 0,
        fixedshipping: 0,
        margintype: "Percentage",
        shipmethodtype: "Fixed Value",
      },
    ],
  });

  const handleClick = (event) => {
    let copy = adv_formulas;
    copy.pop();
    setAdvFormulas(copy);
    setCount(count + 1);
  };

  const fetchdata = () => {
    fetch("https://api.saleprodev.com/getallpriceformula")
      .then((response) => response.json())
      .then((response) => setExistingFormulas(response));
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const handleSave = () => {
    fetch(`https://api.saleprodev.com/addpriceformula`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(no_formulas),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        fetchdata();
        setCount(count + 1);
      });
  };

  const handleOverride = () => {
    fetch(`https://api.saleprodev.com/putpriceformula`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        formula: no_formulas.formula,
        shipmethod: no_formulas.shipmethod,
        shipmethod2: no_formulas.shipmethod2,
        id:
          no_formulas.supplier +
          "-" +
          no_formulas.shipfrom +
          "-" +
          no_formulas.shipto,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("response", response);
        fetchdata();
        setCount(count + 1);
      });
  };

  return (
    <Card elevation={0} sx={{ my: 1, minHeight: "90vh" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          px: 4,
          py: 2,
          borderBottom: (theme) => `1px solid ${theme.palette.border}`,
        }}
      >
        <Button
          size="small"
          variant="text"
          startIcon={<ChevronLeftIcon />}
          onClick={() => dispatch(setSettingsItem(""))}
          sx={{
            "& .MuiButton-startIcon": {
              mr: 0.5,
            },
          }}
        >
          Back
        </Button>
        <Typography variant="h6">Pricing Rules</Typography>
        <Button size="small" variant="contained" disabled>
          Save
        </Button>
      </Grid>

      <Grid
        container
        p={4}
        xs={8}
        flexDirection="column"
        alignContent="flex-start"
      >
        <Grid
          container
          sx={{
            py: 2,
            borderBottom: (theme) => `1px dashed ${theme.palette.border}`,
          }}
        >
          <Grid item xs={4}>
            <Typography variant="body1">Pricing Rules</Typography>
          </Grid>

          <Grid item xs={8}>
            <ToggleButtons options={pricing_rules} type="pricingrule" />
          </Grid>
        </Grid>

        {pricing_rules_selected === "ON" && (
          <Grid
            container
            sx={{
              py: 2,
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1">Mode</Typography>
            </Grid>
            <Grid item xs={8}>
              <ToggleButtons options={pricing_formula} type="formula" />
            </Grid>

            <Grid
              container
              sx={{
                py: 2,
              }}
            >
              <Grid item xs={4}>
                <Typography variant="body1">Product Price</Typography>
              </Grid>

              {pricing_formula_selected === "Simple" && (
                <Grid item xs={8}>
                  <label>Product Cost + </label>
                  <input
                    style={{ textAlign: "center", marginLeft: "1%" }}
                    type="number"
                    min="0"
                    max="100"
                  />
                  <label style={{ textAlign: "center", marginLeft: "1%" }}>
                    %
                  </label>
                </Grid>
              )}

              {pricing_formula_selected === "Advanced" && (
                <Grid item xs={8}>
                  <Card style={{ minHeight: "100px" }}>
                    <Grid
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "30% 20% 10% 10% 25% 5%",
                        textAlign: "center",
                        marginTop: "2%",
                        marginBottom: "1%",
                      }}
                    >
                      <div>Range</div>
                      <div>Margin</div>
                      <div>MarginType</div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          setAdvFormulas([
                            ...adv_formulas,
                            {
                              costmin: 0,
                              costmax: 0,
                              margin: 0,
                              fixedprofit: 0,
                              breakeven: 0,
                              margintype: "Percentage",
                            },
                          ])
                        }
                      >
                        +
                      </div>
                    </Grid>

                    <AdvancedFormula
                      handleClick={handleClick}
                      adv_formulas={adv_formulas}
                      setAdvFormulas={setAdvFormulas}
                    />
                  </Card>
                </Grid>
              )}

              {pricing_formula_selected === "Formula" && (
                <Grid item xs={8}>
                  <Grid
                    sx={{
                      cursor: "pointer",
                      fontSize: "15px",
                      color: "blue",
                      textAlign: "right",
                      mb: "1%",
                    }}
                  >
                    <BasicModal
                      no_formulas={no_formulas}
                      setNoFormulas={setNoFormulas}
                      handleSave={handleSave}
                      handleOverride={handleOverride}
                    />
                  </Grid>

                  <Card
                    sx={{
                      py: 2,
                      minHeight: "100px",
                      display: "grid",
                      gridTemplateColumns: "30% 30% 30 10%",
                    }}
                  >
                    <Grid
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "30% 15% 30% 20%",
                        textAlign: "center",
                      }}
                    >
                      <div>ID</div>
                      <div>Supplier</div>
                      <div>Shipping Method</div>
                      <div>Formula</div>
                    </Grid>
                    {existingformulas.map((item) => {
                      return (
                        <Grid
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "30% 15% 30% 20%",
                            textAlign: "center",
                            fontSize: "10px",
                          }}
                        >
                          <div>{item.id}</div>
                          <div>{item.supplier}</div>
                          <div>{item.shipmethod}</div>
                          <div style={{ color: "green" }}>
                            <NotificationsIcon />
                          </div>
                        </Grid>
                      );
                    })}
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};
