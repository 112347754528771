import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import LinearProgress from '@mui/material/LinearProgress';
import {useSelector, useDispatch} from "react-redux"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ReportModal({open_report, setOpenReport,loading_products}) {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpenReport(true);
  const handleClose = () => dispatch(setOpenReport({open:false, report:""}));


  return (
      <Modal
        open={open_report.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{width:"800px", height:"300px", overflow:"scroll"}}>
        {loading_products?(
          <>
          <Typography>IMPORTING PRODUCTS</Typography>
          <LinearProgress/>
          </>
        ):(
          <>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Report
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {Array.isArray(open_report.report) === true?(
              <div>
              <div style={{display:"grid", gridTemplateColumns:"100%"}}>
              <Typography id="modal-modal-title" variant="h6" component="h6">
                Number of ID's Failed - ({open_report.report.filter(item => item.includes("Failed")).length})
              </Typography>
              {
                open_report.report.map(item => {
                  if(item.includes("Failed")){
                    return (
                      <div>{item.split(" ")[1]}</div>
                    )
                  }
              })}
              </div>
            </div>
            ):""}
          </Typography>
          </>
        )}

        </Box>
      </Modal>
  );
}
