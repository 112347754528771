import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SourcingSideMenu } from "./SourcingSideMenu";
import { SourcingQuickFilter } from "./SourcingQuickFilter";
import { Loader } from "../common/Loader";
import Axios from "axios";

import { SmallContainedButton } from "../common/SmallContainedButton";
import Searchbar from "../common/Searchbar.js";
import PriceFilter from "../common/PriceFilter.js";
import RatingFilter from "../common/RatingFilter.js";
import Sort from "../orders/sort.js";
import moment from "moment";
import VideoDownloadSheetModal from "./videodownloadsheetmodal.js";

import NativePickers from "./select";
import CustomizedSnackbars from "./notification.js";
import CustomizedSnackbarsFailed from "./failednotification.js";
import CustomizedSnackbarsFailedEdit from "./failededitnotification.js";
import Pagination from "../common/Pagination.js";
import MainCheckbox from "./maincheckbox";
import VidaXLMainCheckbox from "./vidaxlmaincheckbox";
import ImportedMainCheckbox from "./importedmaincheckbox";
import { InStockCheckbox } from "./instockcheckbox";
import { HasVideoCheckbox } from "./hasvideocheckbox";

import SourcingSort from "./SourcingSort.js";
import SourcingSearchbar from "./SourcingSearchbar.js";
import MatterHornProductsLayout from "./matterhornproductslayout.js";
import DropshipProductsLayout from "./dropshipproductslayout.js";
import ImportedProductsLayout from "./importedproductslayout.js";
import VidaxlProductsLayout from "./vidaxlproductslayout.js";
import BangoodProductsLayout from "./bangoodproductslayout.js";
import BulkExportModal from "./bulkexportmodal.js";
import MatterHornCategorySelect from "./matterhorncategoryselect.js";
import VidaXLCategorySelect from "./vidaxlcategoryselect.js";
import DropshipCategorySelect from "./dropshipcategoryselect.js";
import IDropshipCategorySelect from "./idropshipcategoryselect.js";
import BigBuyCategorySelect from "./bigbuycategoryselect.js";
import DropshipVendorIdFilter from "./DropshipVendorIdFilter.js";
import BangoodCategorySelect from "./bangoodcategoryselect.js";
import WeFullFillProductsLayout from "./wefullfillproductslayout.js";
import IbaCraftsProductsLayout from "./ibacraftsproductlayout";
import BangoodMainCheckbox from "./bangoodmaincheckbox.js";
import BangoodVariantSelect from "./bangoodvariantselect.js";
import AliExpressProductsLayout from "./aliexpressproductslayout.js";
import FragrancexProductsLayout from "./fragrancexproductslayout.js";
import IDropshipProductsLayout from "./idropshipproductslayout.js";
import BigBuyProductsLayout from "./bigbuyproductslayout.js";
import IngramMicroProductsLayout from "./imgrammicroproductslayout.js";
import GriffatiProductsLayout from "./griffatiproductslayout.js";
import DropsiteProductsLayout from "./dropsiteproductslayout.js";
import SogaProductsLayout from "./sogaproductslayout.js";
import WeDropshipProductsLayout from "./wedropshipproductslayout.js";
import SerranoProductsLayout from "./serranoProductlayout";
import AliExpressMainCheckbox from "./aliexpressmaincheckbox.js";
import FragrancexMainCheckbox from "./fragrancexmaincheckbox.js";
import IngrammicroMainCheckbox from "./ingrammicromaincheckbox.js";
import GriffatiMainCheckbox from "./griffatimaincheckbox.js";
import DropsiteMainCheckbox from "./dropsitemaincheckbox.js";
import SogaMainCheckbox from "./sogamaincheckbox.js";
import ReportModal from "./reportmodal.js";
import FindProductsReportModal from "./FindProductsReportModal.js";
import ScrollToTopOrBottom from "../tools/scrolltotoporbottom.js";
import WeDropshipMainCheckbox from "./wedropshipmaincheckbox.js";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";
import SerranoCategorySelectCategorySelect from "./seranocategoryselect";
import { useExcelDownloder } from "react-xls";

import {
  Box,
  Card,
  Chip,
  Button,
  Typography,
  Grid,
  MenuItem,
  Menu,
  Breadcrumbs,
  Tab,
  Tabs,
  Divider,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  TextField,
} from "@mui/material";

import {
  setSubsettings,
  setSettingsItem,
  setOpenReport,
  setImportingProduct,
  setLoadingProducts,
  setStore,
  setSelectedSupplier,
  setShowProducts,
  setImportTotalPages,
  setTotalCounts,
} from "../../actions";
import SerranoCategorySelect from "./seranocategoryselect";
import {
  changesImageResponse,
  changesImageStringResponse,
} from "../../utills.js";
import Idropshipshippingselect from "./idropshipshippingselect.js";
import CustomSheetModal from "../orders/customsheetmodal.js";
import SunSkySelect from "./sunskycategoryselect.js";
import SunskyProductsLayout from "./sunskyproductslayout.js";
import StoreCheckBox from "./StoreCheckBox.js";

function SourcingNew({ page, handlePage }) {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [importedProducts, setImportedProducts] = useState([]);
  const [exportedProducts, setExportedProducts] = useState([]);
  const [deletedProducts, setDeletedProducts] = useState([]);

  const [bangoodProducts, setBangoodProducts] = useState([]);
  const [aliexpressProducts, setAliexpressProducts] = useState([]);
  const [wefullfillProducts, setWefullfillProducts] = useState([]);
  const [dropsiteProducts, setDropsiteProducts] = useState([]);
  const [sogaProducts, setSogaProducts] = useState([]);
  const [matterhornProducts, setMatterhornProducts] = useState([]);
  const [dropshipzoneProducts, setDropshipzoneProducts] = useState([]);
  const [vidaxlProducts, setVidaxlProducts] = useState([]);
  const [wedropshipProducts, setWedropshipProducts] = useState([]);
  const [fragrancexProducts, setFragrancexProducts] = useState([]);
  const [iDropshipProducts, setIDropshipProducts] = useState([]);
  const [bigbuyProducts, setBigbuyProducts] = useState([]);
  const [ingrammicroProducts, setIngrammicroProducts] = useState([]);
  const [griffatiProducts, setGriffatiProducts] = useState([]);
  const [serranoProducts, setSerranoProducts] = useState([]);
  const [ibacraftsProducts, setIbacraftsProducts] = useState([]);
  const [sunskyProducts, setSunskyProducts] = useState([]);

  const [selectedOrders, setSelectedOrders] = useState([]);
  const selected_supplier = useSelector((state) => state.selected_supplier);
  const selected_warehouse = useSelector((state) => state.selected_warehouse);
  const show_products = useSelector((state) => state.show_products);
  const import_page = useSelector((state) => state.import_page);
  const products_per_page = useSelector((state) => state.products_per_page);
  const total_counts = useSelector((state) => state.total_counts);
  const checkedInStock = useSelector((state) => state.checkedInStock);
  const checkedHasVideo = useSelector((state) => state.checkedHasVideo);
  const start = (import_page - 1) * products_per_page;
  const end = start + products_per_page;
  const searchField = useSelector((state) => state.searchField);
  const store = useSelector((state) => state.store);
  const loading_products = useSelector((state) => state.loading_products);
  const description_preference = useSelector(
    (state) => state.description_preference
  );

  const [currency, setCurrency] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const [member_price, setMember_price] = useState(null);

  const [exportingStatus, setExportingStatus] = useState("beforeExport");

  const dispatch = useDispatch();
  const [open, setOpen] = useState({ open: false, message: "" });
  const [openbulkexport, setOpenBulkExport] = useState(false);
  const [count, setCount] = useState(0);
  const [exportingProducts, setExportingProducts] = useState(false);
  const [openfailed, setOpenFailed] = useState({
    open: false,
    message: "",
    order: "",
  });
  const [openfailededit, setOpenFailedEdit] = useState({
    open: false,
    message: "",
    order: "",
  });

  const [openFindProductsReport, setOpenFindProductsReport] = useState({
    open: false,
    report: [],
  });

  const [openVideoDownloadSheet, setOpenVideoDownloadSheet] =
    React.useState(false);
  const handleCloseVideoDownloadSheet = () => {
    setOpenVideoDownloadSheet(false);
  };
  const [discontinuedProd, setDiscontinuedProd] = useState([]);
  const { ExcelDownloder, Type } = useExcelDownloder();
  const [opencustom, setOpenCustom] = React.useState(false);

  const [openList, setOpenList] = useState(false);
  const SAC = "Select a Category";

  //MatterHorn Category
  const [
    selectedMatterHornCategoryLevel1,
    setSelectedMatterHornCategoryLevel1,
  ] = useState(SAC);
  const [
    selectedMatterHornCategoryLevel2,
    setSelectedMatterHornCategoryLevel2,
  ] = useState(SAC);
  const [
    selectedMatterHornCategoryLevel3,
    setSelectedMatterHornCategoryLevel3,
  ] = useState(SAC);
  const [
    selectedMatterHornCategoryLevel4,
    setSelectedMatterHornCategoryLevel4,
  ] = useState(SAC);
  const [
    selectedMatterHornCategoryLevel5,
    setSelectedMatterHornCategoryLevel5,
  ] = useState(SAC);

  //VidaXL Category
  const [selectedVidaXLCategoryLevel1, setSelectedVidaXLCategoryLevel1] =
    useState(SAC);
  const [selectedVidaXLCategoryLevel2, setSelectedVidaXLCategoryLevel2] =
    useState(SAC);
  const [selectedVidaXLCategoryLevel3, setSelectedVidaXLCategoryLevel3] =
    useState(SAC);
  const [selectedVidaXLCategoryLevel4, setSelectedVidaXLCategoryLevel4] =
    useState(SAC);
  const [selectedVidaXLCategoryLevel5, setSelectedVidaXLCategoryLevel5] =
    useState(SAC);

  //Dropship Category
  const [selectedDropshipCategoryLevel1, setSelectedDropshipCategoryLevel1] =
    useState(SAC);
  const [selectedDropshipCategoryLevel2, setSelectedDropshipCategoryLevel2] =
    useState(SAC);
  const [selectedDropshipBrand, setSelectedDropshipBrand] =
    useState("Select a Brand");
  const [selectedDropshipVendorID, setSelectedDropshipVendorID] = useState("");

  //serrano cat
  const [selectedSerranoCategoryLevel1, setSelectedSerranoCategoryLevel1] =
    useState(SAC);
  const [selectedSerranoCategoryLevel2, setSelectedSerranoCategoryLevel2] =
    useState(SAC);

  //Bangood Category
  const [selectedBangoodCategoryLevel1, setSelectedBangoodCategoryLevel1] =
    useState(SAC);
  const [selectedBangoodCategoryLevel2, setSelectedBangoodCategoryLevel2] =
    useState(SAC);
  const [selectedBangoodCategoryLevel3, setSelectedBangoodCategoryLevel3] =
    useState(SAC);
  const [selectedBangoodCategoryLevel4, setSelectedBangoodCategoryLevel4] =
    useState(SAC);
  const [selectedBangoodCategoryLevel5, setSelectedBangoodCategoryLevel5] =
    useState(SAC);
  const [warehouse_bangood_nz, setWarehouseBangoodNZ] = useState("CN");
  const [shippingmethod_bangood_nz, setShippingMethodBangoodNZ] =
    useState("CN");

  //IDropship Category
  const [selectedIDropshipCategoryLevel1, setSelectedIDropshipCategoryLevel1] =
    useState(SAC);
  const [selectedIDropshipCategoryLevel2, setSelectedIDropshipCategoryLevel2] =
    useState(SAC);
  const [selectedIDropshipBrand, setSelectedIDropshipBrand] =
    useState("Select a Brand");
  const [selectedIDropshipShippingMethod, setSelectedIDropshipShippingMethod] =
    useState("");

  //BigBuy Category
  const [selectedBigBuyCategoryLevel1, setSelectedBigBuyCategoryLevel1] =
    useState(null);
  const [selectedBigBuyCategoryLevel2, setSelectedBigBuyCategoryLevel2] =
    useState(null);
  const [selectedBigBuyCategoryLevel3, setSelectedBigBuyCategoryLevel3] =
    useState(null);
  const [selectedBigBuyBrand, setSelectedBigBuyBrand] = useState("");
  const [selectedBigBuyCondition, setSelectedBigBuyCondition] = useState("");

  const [warehouse, setWarehouse] = useState("CN");
  const [productvariants, setProductVariants] = useState("all");
  const [carrier, setCarrier] = useState("UBI");
  const [skulist, setSkuList] = useState("");
  const [fetchproductcount, setFetchProductCount] = useState(0);
  const [ship_from, setShipFrom] = useState("CN");
  const [note, setNote] = useState(
    "Please ship the order with UBI or Yanwen Express only thanks"
  );

  //sunsky Category
  const [selectedSunskyCategoryLevel1, setSelectedSunskyCategoryLevel1] =
    useState({id: null, name: 'Select a Category'});
  const [selectedSunskyCategoryLevel2, setSelectedSunskyCategoryLevel2] =
    useState({id: null, name: 'Select a Category'});
  const [selectedSunskyCategoryLevel3, setSelectedSunskyCategoryLevel3] =
    useState({id: null, name: 'Select a Category'});
  const [selectedSunskyCategoryLevel4, setSelectedSunskyCategoryLevel4] =
    useState({id: null, name: 'Select a Category'});
  const [selectedSunskyCategoryLevel5, setSelectedSunskyCategoryLevel5] =
    useState(SAC);
  const [warehouse_Sunsky_nz, setWarehouseSunskyNZ] = useState("CN");
  const [shippingmethod_Sunsky_nz, setShippingMethodSunskyNZ] = useState("CN");

  const handleCloseBulkExport = () => setOpenBulkExport(false);
  const [filterby, setFilterBy] = useState("category");
  const [tags, setTags] = useState("");
  const [product_type, setProductType] = useState("");
  const [exportstatus, setExportStatus] = useState({
    created: 0,
    total: 0,
    failed: 0,
    message: "Exporting Products",
  });
  const [permissiontoexport, setPermissiontoexport] = useState({
    existing_products: [],
  });
  const [checkboxopen, setcheckboxOpen] = useState(false);
  const [checked, setChecked] = useState({
    nz: false,
    au: false,
    gk: false,
    us: false,
  });
  const importingproduct = useSelector((state) => state.importingproduct);
  const open_report = useSelector((state) => state.open_report);
  const handleClickList = () => {
    setOpenList(!openList);
  };

  //Price Filter
  const [prices, setPrices] = useState({
    minPrice: null,
    maxPrice: null,
  });

  const handleClearPrice = () => {
    setPrices({
      minPrice: null,
      maxPrice: null,
    });
  };

  //Sort Listings
  const [ascending, setAscending] = useState(false);
  const [sortCategory, setSortCategory] = useState("product_id");

  const handleSortAscending = () => {
    if (bangoodProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setBangoodProducts([
            ...bangoodProducts.sort((productA, productB) =>
              productA.product_id.localeCompare(productB.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setBangoodProducts([
            ...bangoodProducts.sort(
              (productA, productB) =>
                moment(productA.createdAt).format("x") -
                moment(productB.createdAt).format("x")
            ),
          ]);
      }
    }

    if (aliexpressProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setAliexpressProducts([
            ...aliexpressProducts.sort((productA, productB) =>
              productA.product_id.localeCompare(productB.product_id)
            ),
          ]);
      }

      {
        sortCategory === "product_rating" &&
          setAliexpressProducts([
            ...aliexpressProducts.sort((productA, productB) =>
              productA.aliexpress_product.result.avg_evaluation_rating.localeCompare(
                productB.aliexpress_product.result.avg_evaluation_rating
              )
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setAliexpressProducts([
            ...aliexpressProducts.sort(
              (productA, productB) =>
                moment(productA.aliexpress_product.result.gmt_create).format(
                  "x"
                ) -
                moment(productB.aliexpress_product.result.gmt_create).format(
                  "x"
                )
            ),
          ]);
      }

      {
        sortCategory === "order_count" &&
          setAliexpressProducts([
            ...aliexpressProducts.sort(
              (productA, productB) =>
                productA.aliexpress_product.result.order_count -
                productB.aliexpress_product.result.order_count
            ),
          ]);
      }
    }

    if (wefullfillProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setWefullfillProducts([
            ...wefullfillProducts.sort((productA, productB) =>
              productA.product_id.localeCompare(productB.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setWefullfillProducts([
            ...wefullfillProducts.sort(
              (productA, productB) =>
                moment(productA.createdAt).format("x") -
                moment(productB.createdAt).format("x")
            ),
          ]);
      }
    }

    if (dropsiteProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setDropsiteProducts([
            ...dropsiteProducts.sort((productA, productB) =>
              productA.product_id.localeCompare(productB.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setDropsiteProducts([
            ...dropsiteProducts.sort(
              (productA, productB) =>
                moment(productA.createdAt).format("x") -
                moment(productB.createdAt).format("x")
            ),
          ]);
      }
    }

    if (sogaProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setSogaProducts([
            ...sogaProducts.sort((productA, productB) =>
              productA.product_id.localeCompare(productB.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setSogaProducts([
            ...sogaProducts.sort(
              (productA, productB) =>
                moment(productA.createdAt).format("x") -
                moment(productB.createdAt).format("x")
            ),
          ]);
      }
    }

    if (vidaxlProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setVidaxlProducts([
            ...vidaxlProducts.sort((productA, productB) =>
              productA.product_id.localeCompare(productB.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setVidaxlProducts([
            ...vidaxlProducts.sort(
              (productA, productB) =>
                moment(productA.createdAt).format("x") -
                moment(productB.createdAt).format("x")
            ),
          ]);
      }
    }

    if (dropshipzoneProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setDropshipzoneProducts([
            ...dropshipzoneProducts.sort((productA, productB) =>
              productA.product_id.localeCompare(productB.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setDropshipzoneProducts([
            ...dropshipzoneProducts.sort(
              (productA, productB) =>
                moment(productA.createdAt).format("x") -
                moment(productB.createdAt).format("x")
            ),
          ]);
      }
    }

    if (matterhornProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setMatterhornProducts([
            ...matterhornProducts.sort((productA, productB) =>
              productA.product_id.localeCompare(productB.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setMatterhornProducts([
            ...matterhornProducts.sort(
              (productA, productB) =>
                moment(productA.createdAt).format("x") -
                moment(productB.createdAt).format("x")
            ),
          ]);
      }
    }

    if (fragrancexProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setFragrancexProducts([
            ...fragrancexProducts.sort((productA, productB) =>
              productA.product_id.localeCompare(productB.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setFragrancexProducts([
            ...fragrancexProducts.sort(
              (productA, productB) =>
                moment(productA.createdAt).format("x") -
                moment(productB.createdAt).format("x")
            ),
          ]);
      }
    }

    if (wedropshipProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setWedropshipProducts([
            ...wedropshipProducts.sort((productA, productB) =>
              productA.product_id.localeCompare(productB.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setWedropshipProducts([
            ...wedropshipProducts.sort(
              (productA, productB) =>
                moment(productA.createdAt).format("x") -
                moment(productB.createdAt).format("x")
            ),
          ]);
      }
    }

    if (iDropshipProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setIDropshipProducts([
            ...iDropshipProducts.sort((productA, productB) =>
              productA.product_id.localeCompare(productB.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setIDropshipProducts([
            ...iDropshipProducts.sort(
              (productA, productB) =>
                moment(productA.createdAt).format("x") -
                moment(productB.createdAt).format("x")
            ),
          ]);
      }
    }

    if (exportedProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setExportedProducts([
            ...exportedProducts.sort((productA, productB) =>
              productA.product_id.localeCompare(productB.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setExportedProducts([
            ...exportedProducts.sort(
              (productA, productB) =>
                moment(productA.createdAt).format("x") -
                moment(productB.createdAt).format("x")
            ),
          ]);
      }
    }

    if (deletedProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setDeletedProducts([
            ...deletedProducts.sort((productA, productB) =>
              productA.product_id.localeCompare(productB.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setDeletedProducts([
            ...deletedProducts.sort(
              (productA, productB) =>
                moment(productA.createdAt).format("x") -
                moment(productB.createdAt).format("x")
            ),
          ]);
      }
    }

    setAscending(true);
  };

  useEffect(() => {
    handleSortDescending();
  }, [sortCategory]);

  useEffect(() => {
    if(store === 'nzshopify')
      {
        setChecked({ nz: true, au: false, gk: false, us: false})
      }else if(store === 'aushopify')
        {
          setChecked({ nz: false, au: true, gk: false, us: false})
        }else if(store === 'gkshopify')
          {
            setChecked({ nz: false, au: false, gk: true, us: false})
          }else if(store === 'usshopify'){
            setChecked({ nz: false, au: false, gk: false, us: true})
          }
  }, [store]);

  const handleSortDescending = () => {
    if (bangoodProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setBangoodProducts([
            ...bangoodProducts.sort((productA, productB) =>
              productB.product_id.localeCompare(productA.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setBangoodProducts([
            ...bangoodProducts.sort(
              (productA, productB) =>
                moment(productB.createdAt).format("x") -
                moment(productA.createdAt).format("x")
            ),
          ]);
      }
    }

    if (aliexpressProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setAliexpressProducts([
            ...aliexpressProducts.sort((productA, productB) =>
              productB.product_id.localeCompare(productA.product_id)
            ),
          ]);
      }

      {
        sortCategory === "product_rating" &&
          setAliexpressProducts([
            ...aliexpressProducts.sort((productA, productB) =>
              productB.aliexpress_product.result.avg_evaluation_rating.localeCompare(
                productA.aliexpress_product.result.avg_evaluation_rating
              )
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setAliexpressProducts([
            ...aliexpressProducts.sort(
              (productA, productB) =>
                moment(productB.aliexpress_product.result.gmt_create).format(
                  "x"
                ) -
                moment(productA.aliexpress_product.result.gmt_create).format(
                  "x"
                )
            ),
          ]);
      }

      {
        sortCategory === "order_count" &&
          setAliexpressProducts([
            ...aliexpressProducts.sort(
              (productA, productB) =>
                productB.aliexpress_product.result.order_count -
                productA.aliexpress_product.result.order_count
            ),
          ]);
      }
    }

    if (wefullfillProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setWefullfillProducts([
            ...wefullfillProducts.sort((productA, productB) =>
              productB.product_id.localeCompare(productA.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setWefullfillProducts([
            ...wefullfillProducts.sort(
              (productA, productB) =>
                moment(productB.createdAt).format("x") -
                moment(productA.createdAt).format("x")
            ),
          ]);
      }
    }

    if (dropsiteProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setDropsiteProducts([
            ...dropsiteProducts.sort((productA, productB) =>
              productB.product_id.localeCompare(productA.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setDropsiteProducts([
            ...dropsiteProducts.sort(
              (productA, productB) =>
                moment(productB.createdAt).format("x") -
                moment(productA.createdAt).format("x")
            ),
          ]);
      }
    }

    if (sogaProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setSogaProducts([
            ...sogaProducts.sort((productA, productB) =>
              productB.product_id.localeCompare(productA.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setSogaProducts([
            ...sogaProducts.sort(
              (productA, productB) =>
                moment(productB.createdAt).format("x") -
                moment(productA.createdAt).format("x")
            ),
          ]);
      }
    }

    if (vidaxlProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setVidaxlProducts([
            ...vidaxlProducts.sort((productA, productB) =>
              productB.product_id.localeCompare(productA.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setVidaxlProducts([
            ...vidaxlProducts.sort(
              (productA, productB) =>
                moment(productB.createdAt).format("x") -
                moment(productA.createdAt).format("x")
            ),
          ]);
      }
    }

    if (dropshipzoneProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setDropshipzoneProducts([
            ...dropshipzoneProducts.sort((productA, productB) =>
              productB.product_id.localeCompare(productA.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setDropshipzoneProducts([
            ...dropshipzoneProducts.sort(
              (productA, productB) =>
                moment(productB.createdAt).format("x") -
                moment(productA.createdAt).format("x")
            ),
          ]);
      }
    }

    if (matterhornProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setMatterhornProducts([
            ...matterhornProducts.sort((productA, productB) =>
              productB.product_id.localeCompare(productA.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setMatterhornProducts([
            ...matterhornProducts.sort(
              (productA, productB) =>
                moment(productB.createdAt).format("x") -
                moment(productA.createdAt).format("x")
            ),
          ]);
      }
    }

    if (fragrancexProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setFragrancexProducts([
            ...fragrancexProducts.sort((productA, productB) =>
              productB.product_id.localeCompare(productA.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setFragrancexProducts([
            ...fragrancexProducts.sort(
              (productA, productB) =>
                moment(productB.createdAt).format("x") -
                moment(productA.createdAt).format("x")
            ),
          ]);
      }
    }

    if (wedropshipProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setWedropshipProducts([
            ...wedropshipProducts.sort((productA, productB) =>
              productB.product_id.localeCompare(productA.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setWedropshipProducts([
            ...wedropshipProducts.sort(
              (productA, productB) =>
                moment(productB.createdAt).format("x") -
                moment(productA.createdAt).format("x")
            ),
          ]);
      }
    }

    if (iDropshipProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setIDropshipProducts([
            ...iDropshipProducts.sort((productA, productB) =>
              productB.product_id.localeCompare(productA.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setIDropshipProducts([
            ...iDropshipProducts.sort(
              (productA, productB) =>
                moment(productB.createdAt).format("x") -
                moment(productA.createdAt).format("x")
            ),
          ]);
      }
    }

    if (bigbuyProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setBigbuyProducts([
            ...bigbuyProducts.sort((productA, productB) =>
              productB.product_id.localeCompare(productA.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setBigbuyProducts([
            ...bigbuyProducts.sort(
              (productA, productB) =>
                moment(productB.createdAt).format("x") -
                moment(productA.createdAt).format("x")
            ),
          ]);
      }
    }

    if (ingrammicroProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setIngrammicroProducts([
            ...ingrammicroProducts.sort((productA, productB) =>
              productB.product_id.localeCompare(productA.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setIngrammicroProducts([
            ...ingrammicroProducts.sort(
              (productA, productB) =>
                moment(productB.createdAt).format("x") -
                moment(productA.createdAt).format("x")
            ),
          ]);
      }
    }

    if (griffatiProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setGriffatiProducts([
            ...griffatiProducts.sort((productA, productB) =>
              productB.product_id.localeCompare(productA.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setGriffatiProducts([
            ...griffatiProducts.sort(
              (productA, productB) =>
                moment(productB.createdAt).format("x") -
                moment(productA.createdAt).format("x")
            ),
          ]);
      }
    }

    if (exportedProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setExportedProducts([
            ...exportedProducts.sort((productA, productB) =>
              productB.product_id.localeCompare(productA.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setExportedProducts([
            ...exportedProducts.sort(
              (productA, productB) =>
                moment(productB.createdAt).format("x") -
                moment(productA.createdAt).format("x")
            ),
          ]);
      }
    }

    if (deletedProducts.length !== 0) {
      {
        sortCategory === "product_id" &&
          setDeletedProducts([
            ...deletedProducts.sort((productA, productB) =>
              productB.product_id.localeCompare(productA.product_id)
            ),
          ]);
      }

      {
        sortCategory === "create_date" &&
          setDeletedProducts([
            ...deletedProducts.sort(
              (productA, productB) =>
                moment(productB.createdAt).format("x") -
                moment(productA.createdAt).format("x")
            ),
          ]);
      }
    }

    setAscending(false);
  };

  console.log("this is initial ic ba==>> ", ibacraftsProducts);
  //Rating for Aliexpress Listings
  const [rating, setRating] = useState(0);

  // Import To Store Menu
  const [importToStoreMenuAnchorEl, setImportToStoreMenuAnchorEl] =
    useState(null);
  const openImportToStoreMenu = Boolean(importToStoreMenuAnchorEl);
  const handleClickImportToStoreMenu = (event) => {
    setImportToStoreMenuAnchorEl(event.currentTarget);
  };
  const handleCloseImportToStoreMenu = () => {
    setImportToStoreMenuAnchorEl(null);
  };

  // Bulk Action Menu
  const [bulkActionsAnchorEl, setBulkActionsAnchorEl] = useState(null);
  const openBulkActions = Boolean(bulkActionsAnchorEl);
  const handleClickBulkActions = (event) => {
    setBulkActionsAnchorEl(event.currentTarget);
  };
  const handleCloseBulkActions = () => {
    setBulkActionsAnchorEl(null);
  };

  //Filter By Select
  const FilterBySelect = (
    <>
      <Typography variant="body2" sx={{ fontSize: "0.8rem", mr: 0.5 }}>
        Import By
      </Typography>
      <Select
        value={filterby}
        onChange={(event) => setFilterBy(event.target.value)}
        labelId="filter-by-select-label"
        sx={{
          mr: 0.5,
          height: 26,
          fontSize: "0.8rem",
          "& legend": {
            width: "unset",
          },
        }}
      >
        <MenuItem value="category" sx={{ height: 26, fontSize: "0.8rem" }}>
          <Typography variant="body2">Category</Typography>
        </MenuItem>

        <MenuItem value="skulist" sx={{ height: 26, fontSize: "0.8rem" }}>
          <Typography variant="body2">Product ID / SKUs</Typography>
        </MenuItem>
      </Select>
    </>
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseFailed = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenFailed({ open: false, message: "", order: "" });
  };

  const handleCloseFailedEdit = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenFailedEdit({ open: false, message: "", order: "" });
  };

  useEffect(() => {
    if (openbulkexport === false) {
      setExportStatus({
        created: 0,
        failed: 0,
        total: 0,
        message: "Exporting Products",
      });
    }
  }, [openbulkexport]);

  useEffect(() => {
    setSkuList("");
  }, [show_products, selected_supplier]);

  useEffect(() => {
    setTags("");
  }, [show_products, selected_supplier]);

  useEffect(() => {
    setProductType("");
  }, [show_products, selected_supplier]);

  useEffect(() => {
    setExportStatus({
      created: 0,
      failed: 0,
      total: 0,
      message: "Exporting Products",
    });
  }, [selectedProducts]);

  useEffect(() => {
    setSkuList("");
    setFetchProductCount(count + 1);
    dispatch(setLoadingProducts(false));
  }, [filterby]);

  useEffect(() => {
    if (filterby === "sku") {
      dispatch(setLoadingProducts(false));
    }
  }, [filterby]);

  useEffect(() => {
    if (openbulkexport === false) {
      setPermissiontoexport({ existing_products: [] });
    }
  }, [openbulkexport]);

  useEffect(() => {
    setSelectedBangoodCategoryLevel1(SAC);
  }, [filterby]);

  useEffect(() => {
console.log("here is selected sipplier,,, ", selected_supplier, show_products)
    
    setBangoodProducts([]);
    setAliexpressProducts([]);
    setWefullfillProducts([]);
    setDropsiteProducts([]);
    setSogaProducts([]);
    setMatterhornProducts([]);
    setDropshipzoneProducts([]);
    setVidaxlProducts([]);
    setFragrancexProducts([]);
    setWedropshipProducts([]);
    setIDropshipProducts([]);
    setBigbuyProducts([]);
    setIngrammicroProducts([]);
    setGriffatiProducts([]);
    setSerranoProducts([]);
    setIbacraftsProducts([]);
    setSunskyProducts([]);

    if (show_products === "new") {
      let category = "";
      let brand = "";
      let condition = "";

      if (selected_supplier === "matterhorn") {
        category = "/" + selectedMatterHornCategoryLevel1;
        if (selectedMatterHornCategoryLevel2 !== SAC) {
          category = category + "/" + selectedMatterHornCategoryLevel2;
        }
        if (selectedMatterHornCategoryLevel3 !== SAC) {
          category = category + "/" + selectedMatterHornCategoryLevel3;
        }
        if (selectedMatterHornCategoryLevel4 !== SAC) {
          category = category + "/" + selectedMatterHornCategoryLevel4;
        }
        if (selectedMatterHornCategoryLevel5 !== SAC) {
          category = category + "/" + selectedMatterHornCategoryLevel5;
        }
      }

      if (selected_supplier === "vidaxl") {
        category = selectedVidaXLCategoryLevel1;
        if (selectedVidaXLCategoryLevel2 !== SAC) {
          category = category + " > " + selectedVidaXLCategoryLevel2;
        }
        if (selectedVidaXLCategoryLevel3 !== SAC) {
          category = category + " > " + selectedVidaXLCategoryLevel3;
        }
        if (selectedVidaXLCategoryLevel4 !== SAC) {
          category = category + " > " + selectedVidaXLCategoryLevel4;
        }
        if (selectedVidaXLCategoryLevel5 !== SAC) {
          category = category + " > " + selectedVidaXLCategoryLevel5;
        }
      }

      if (selected_supplier === "dropship") {
        category = selectedDropshipCategoryLevel1;
        brand = selectedDropshipBrand;
        if (selectedDropshipCategoryLevel2 !== SAC) {
          category = category + " > " + selectedDropshipCategoryLevel2;
        }
      }
      if (selected_supplier === "serrano") {
        category = selectedSerranoCategoryLevel1;

        if (selectedSerranoCategoryLevel2 !== SAC) {
          category = category + " > " + selectedSerranoCategoryLevel2;
        }
      }

      if (selected_supplier === "bangood") {
        category = selectedBangoodCategoryLevel1;

        if (selectedBangoodCategoryLevel2 !== SAC) {
          category = selectedBangoodCategoryLevel2;
        }

        if (selectedBangoodCategoryLevel3 !== SAC) {
          category = selectedBangoodCategoryLevel3;
        }

        if (selectedBangoodCategoryLevel4 !== SAC) {
          category = selectedBangoodCategoryLevel4;
        }
      }

      if (selected_supplier === "sunsky") {
        
        category = selectedSunskyCategoryLevel1.id;

        if (selectedSunskyCategoryLevel2.name !== SAC) {
          category = selectedSunskyCategoryLevel2.id;
        }

        if (selectedSunskyCategoryLevel3.name !== SAC) {
          category = selectedSunskyCategoryLevel3.id;
        }

        if (selectedSunskyCategoryLevel4.name !== SAC) {
          category = selectedSunskyCategoryLevel4.id;
        }

        console.log("here is selected sipplier,,,22",category, selectedSunskyCategoryLevel1.name)
      }

      if (selected_supplier === "idropship") {
        category = selectedIDropshipCategoryLevel1;
        brand = selectedIDropshipBrand;
        if (selectedIDropshipCategoryLevel2 !== SAC) {
          category = category + " > " + selectedIDropshipCategoryLevel2;
        }
        console.log("idropshipcategory", category);
      }

      if (selected_supplier === "bigbuy") {
        brand = selectedBigBuyBrand;
        condition = selectedBigBuyCondition;
        if (selectedBigBuyCategoryLevel1 !== null) {
          category = selectedBigBuyCategoryLevel1.name;
          if (selectedBigBuyCategoryLevel2 !== null) {
            category = category + " > " + selectedBigBuyCategoryLevel2.name;
          }
          if (selectedBigBuyCategoryLevel3 !== null) {
            category = category + " > " + selectedBigBuyCategoryLevel3.name;
          }
          console.log("bigbuycategory", category);
        }
      }

      dispatch(setLoadingProducts(true));

      if (filterby === "category" && selectedBangoodCategoryLevel1 === SAC) {
        dispatch(setLoadingProducts(false));
      }

      if (filterby === "skulist" && skulist === "") {
        dispatch(setLoadingProducts(false));
      }

      if (selected_supplier === "aliexpress" && skulist !== "") {
        dispatch(setOpenReport({ open: true, report: [] }));
      }

      const CheckShipToNZ = () => {
        if (selectedIDropshipShippingMethod === "Free Shipping") {
          return true;
        } else if (selectedIDropshipShippingMethod === "Ship By AMS") {
          return false;
        } else {
          return [true, false];
        }
      };

      fetch("https://api.saleprodev.com/productimporter/fetchproducts", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          supplier: selected_supplier,
          warehouse: selected_warehouse,
          page: import_page,
          products_per_page: products_per_page,
          category: category,
          brand: brand,
          condition: condition,
          vendor_id:
            selectedDropshipVendorID === "Dropship"
              ? "dropship"
              : selectedDropshipVendorID,
          shipping_to_nz: CheckShipToNZ(),
          checked_in_stock: checkedInStock,
          checked_has_video: checkedHasVideo,
          search: searchField,
          skulist: skulist,
          store: store,
          ship_from: ship_from,
          description_preference: description_preference,
          tags: tags,
          product_type: product_type,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("fetched", response);
          console.log("fetched products", response.products);
          if (selected_supplier === "bangood") {
            console.log("response.count", response.count);

            let filteredValidProducts;
            let filteredInvalidProducts;
            filteredValidProducts = response.products.filter(
              (product) => product.code === 0
            );

            filteredInvalidProducts = response.products.filter(
              (product) => product.code !== 0
            );

            if (filteredInvalidProducts.length !== 0) {
              setOpenFindProductsReport({
                open: true,
                report: response.products,
              });
            } else {
              setOpen({
                open: true,
                message: `Found ${response.products.length} products, ${filteredValidProducts.length} valid`,
              });
            }

            if (productvariants === "all") {
              let product_rev = filteredValidProducts;
              setBangoodProducts(product_rev);
              dispatch(
                setImportTotalPages(
                  parseInt(product_rev.length / products_per_page) + 1
                )
              );
              dispatch(setTotalCounts(product_rev.length));
              dispatch(setLoadingProducts(false));
            }

            if (productvariants === "standard") {
              let product_rev = filteredValidProducts.filter(
                (item) => item.poa_list.length === 0
              );
              console.log("product_rev", product_rev);
              setBangoodProducts(product_rev);
              dispatch(setLoadingProducts(false));
              dispatch(setTotalCounts(product_rev.length));
              dispatch(
                setImportTotalPages(
                  parseInt(product_rev.length / products_per_page) + 1
                )
              );
            }

            if (productvariants === "multivariant") {
              let product_rev = filteredValidProducts.filter(
                (item) => item.poa_list.length !== 0
              );
              setBangoodProducts(product_rev);
              dispatch(setLoadingProducts(false));
              dispatch(setTotalCounts(product_rev.length));
              dispatch(
                setImportTotalPages(
                  parseInt(product_rev.length / products_per_page) + 1
                )
              );
            }
          } else {
            if (selected_supplier === "aliexpress") {
              if (response?.report?.includes("Failed SKUListEmpty")) {
                dispatch(
                  setOpenReport({ open: false, report: response.report })
                );
                dispatch(setLoadingProducts(false));
                console.log("opening report");
              } else {
                dispatch(
                  setOpenReport({ open: true, report: response.report })
                );
                dispatch(setLoadingProducts(false));
                console.log("opening report");
              }
            }

            if (selected_supplier === "wefullfill") {
              console.log("WefullfillProducts", response.products);
              setWefullfillProducts(response.products);
            }

            if (selected_supplier === "ibacrafts") {
              console.log("ibacrafts", response.products);

              setIbacraftsProducts(response.products);
            }

            if (selected_supplier === "sunsky") {
              console.log("sunsky", response.products);
              setSunskyProducts(response.products);
            }

            if (selected_supplier === "dropsite") {
              console.log("DropsiteProducts", response.products);
              setDropsiteProducts(response.products);
            }

            if (selected_supplier === "soga") {
              console.log("SogaProducts", response.products);
              setSogaProducts(response.products);
            }

            if (selected_supplier === "matterhorn") {
              console.log("MatterhornProducts", response.products);
              setMatterhornProducts(response.products);
            }

            if (selected_supplier === "dropship") {
              console.log("DropshipzoneProducts", response.products);
              setDropshipzoneProducts(response.products);
            }

            if (selected_supplier === "vidaxl") {
              console.log("VidaxlProducts", response.products);
              setVidaxlProducts(response.products);
            }

            if (selected_supplier === "fragrancex") {
              console.log("FragrancexProducts", response.products);
              setFragrancexProducts(response.products);
            }
            if (selected_supplier === "wedropship") {
              console.log("WedropshipProducts", response.products);
              setWedropshipProducts(response.products);
            }

            if (selected_supplier === "idropship") {
              console.log(
                "IDropshipProductsitem_subgroup",
                response.products.map((item) => item.item_subgroup)
              );
              setIDropshipProducts(response.products);
            }

            if (selected_supplier === "bigbuy") {
              console.log("BigbuyProducts", response.products);
              setBigbuyProducts(response.products);
            }

            if (selected_supplier === "ingrammicro") {
              console.log("IngrammicroProducts", response.products);
              setIngrammicroProducts(response.products);
            }

            if (selected_supplier === "griffati") {
              console.log("GriffatiProducts", response.products);
              setGriffatiProducts(response.products);
            }

            if (selected_supplier === "serrano") {
              console.log("serrano", response.products);
              setSerranoProducts(response.products);
            }

            dispatch(
              setImportTotalPages(
                parseInt(response.count / products_per_page) + 1
              )
            );
            dispatch(setTotalCounts(response.count));
            dispatch(setLoadingProducts(false));
          }
        })
        .then((err) => {
          console.log(err);
        });
    }

    if (show_products === "imported") {
      dispatch(setLoadingProducts(true));

      fetch(
        "https://api.saleprodev.com/productimporter/fetchimportedproducts",
        {
          method: "post",
          body: JSON.stringify({
            supplier: selected_supplier,
            page: import_page,
            products_per_page: products_per_page,
            vendor_id:
              selectedDropshipVendorID === "Dropship"
                ? "dropship"
                : selectedDropshipVendorID,
            store: store,
            search: searchField,
            productvariants: productvariants,
            checked_in_stock: checkedInStock,
            checked_has_video: checkedHasVideo,
            warehouse: selected_warehouse,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          console.log("==============================", response);
          if (selected_supplier === "bangood") {
            if (productvariants === "standard") {
              let product_rev = response.products.filter(
                (item) => item.options === null
              );
              console.log("product_rev", product_rev);
              setBangoodProducts(product_rev);
              dispatch(setLoadingProducts(false));
              dispatch(
                setImportTotalPages(
                  parseInt(response.count / products_per_page) + 1
                )
              );
            }
            if (productvariants === "multivariant") {
              let product_rev = response.products.filter(
                (item) => item.options !== null
              );
              setBangoodProducts(product_rev);
              dispatch(
                setImportTotalPages(
                  parseInt(response.count / products_per_page) + 1
                )
              );
              dispatch(setLoadingProducts(false));
            }
            if (productvariants === "all") {
              let product_rev = response.products;
              setBangoodProducts(product_rev);
              dispatch(
                setImportTotalPages(
                  parseInt(response.count / products_per_page) + 1
                )
              );
              dispatch(setLoadingProducts(false));
            }
          }

          // if (selected_supplier === "aliexpress") {
          //   if (response.report.includes("Failed SKUListEmpty")) {
          //     dispatch(setOpenReport({ open: false, report: response.report }));
          //     dispatch(setLoadingProducts(false));
          //     console.log("opening report");
          //   } else {
          //     dispatch(setOpenReport({ open: true, report: response.report }));
          //     dispatch(setLoadingProducts(false));
          //     console.log("opening report");
          //   }
          // }

          if (selected_supplier === "aliexpress") {
            console.log("AliexpressProducts", response.products);

            let ratingFilteredAliexpressProducts = response.products.filter(
              (product) =>
                Number(
                  product.aliexpress_product.result.avg_evaluation_rating
                ) >= rating
            );
            setAliexpressProducts(ratingFilteredAliexpressProducts);
          }

          if (selected_supplier === "wefullfill") {
            console.log("WefullfillProducts", response.products);
            setWefullfillProducts(response.products);
          }

          if (selected_supplier === "ibacrafts") {
            console.log("===========>?>?>?ibacrafts", response.products);
            const changedProducts = changesImageResponse(response.products);
            setIbacraftsProducts(changedProducts);
          }

          if (selected_supplier === "sunsky") {

            setSunskyProducts(response.products);
          }

          if (selected_supplier === "dropsite") {
            console.log("DropsiteProducts", response.products);
            setDropsiteProducts(response.products);
          }

          if (selected_supplier === "soga") {
            console.log("SogaProducts", response.products);
            setSogaProducts(response.products);
          }

          if (selected_supplier === "matterhorn") {
            console.log("MatterhornProducts", response.products);
            setMatterhornProducts(response.products);
          }

          if (selected_supplier === "dropship") {
            console.log("DropshipzoneProducts", response.products);
            setDropshipzoneProducts(response.products);
          }

          if (selected_supplier === "vidaxl") {
            console.log("VidaxlProducts", response.products);
            setVidaxlProducts(response.products);
          }

          if (selected_supplier === "fragrancex") {
            console.log("FragrancexProducts", response.products);
            setFragrancexProducts(response.products);
          }
          if (selected_supplier === "wedropship") {
            console.log("WedropshipProducts", response.products);
            setWedropshipProducts(response.products);
          }

          if (selected_supplier === "idropship") {
            console.log("IDropshipProducts", response.products);
            setIDropshipProducts(response.products);
          }

          if (selected_supplier === "bigbuy") {
            console.log("BigbuyProducts", response.products);
            setBigbuyProducts(response.products);
          }

          if (selected_supplier === "ingrammicro") {
            console.log("IngrammicroProducts", response.products);
            setIngrammicroProducts(response.products);
          }

          if (selected_supplier === "griffati") {
            console.log("GriffatiProducts", response.products);
            setGriffatiProducts(response.products);
          }

          if (selected_supplier === "serrano") {
            console.log("serrano", response.products);
            setSerranoProducts(response.products);
          }

          dispatch(
            setImportTotalPages(
              parseInt(response.count / products_per_page) + 1
            )
          );
          dispatch(setTotalCounts(response.count));
          dispatch(setLoadingProducts(false));
        })
        .then((err) => {
          console.log(err);
        });
    }

    if (show_products === "exported") {
      dispatch(setLoadingProducts(true));
      fetch(
        "https://api.saleprodev.com/productimporter/fetchexportedproducts",
        {
          method: "post",
          body: JSON.stringify({
            supplier: selected_supplier,
            page: import_page,
            products_per_page: products_per_page,
            vendor_id:
              selectedDropshipVendorID === "Dropship"
                ? "dropship"
                : selectedDropshipVendorID,
            store: store,
            search: searchField,
            checked_in_stock: checkedInStock,
            checked_has_video: checkedHasVideo,
            warehouse: selected_warehouse,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          console.log("fetchexportedproducts", response);
          if (selected_supplier === "ibacrafts") {
            const changedProduct = changesImageResponse(response.products);
            // console.log("this is222------------------->>>>>>>>>>>>",changedProduct[0]);
            setExportedProducts(changedProduct);
          } else {
            setExportedProducts(response.products);
          }
          dispatch(
            setImportTotalPages(
              parseInt(response.count / products_per_page) + 1
            )
          );
          dispatch(setTotalCounts(response.count));
          dispatch(setLoadingProducts(false));
        })
        .then((err) => {
          console.log(err);
        });
    }

    if (show_products === "deleted") {
      dispatch(setLoadingProducts(true));
      fetch("https://api.saleprodev.com/productimporter/fetchdeletedproducts", {
        method: "post",
        body: JSON.stringify({
          supplier: selected_supplier,
          page: import_page,
          products_per_page: products_per_page,
          vendor_id:
            selectedDropshipVendorID === "Dropship"
              ? "dropship"
              : selectedDropshipVendorID,
          store: store,
          search: searchField,
          warehouse: selected_warehouse,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setDeletedProducts(response.products);
          dispatch(
            setImportTotalPages(
              parseInt(response.count / products_per_page) + 1
            )
          );
          dispatch(setTotalCounts(response.count));
          dispatch(setLoadingProducts(false));
        })
        .then((err) => {
          console.log(err);
        });
    }
  }, [
    selected_supplier,
    import_page,
    products_per_page,
    show_products,
    selectedMatterHornCategoryLevel1,
    selectedMatterHornCategoryLevel2,
    selectedMatterHornCategoryLevel3,
    selectedMatterHornCategoryLevel4,
    selectedMatterHornCategoryLevel5,
    selectedVidaXLCategoryLevel1,
    selectedVidaXLCategoryLevel2,
    selectedVidaXLCategoryLevel3,
    selectedVidaXLCategoryLevel4,
    selectedVidaXLCategoryLevel5,
    selectedDropshipCategoryLevel1,
    selectedDropshipCategoryLevel2,
    selectedDropshipBrand,
    selectedDropshipVendorID,
    selectedBangoodCategoryLevel1,
    selectedBangoodCategoryLevel2,
    selectedBangoodCategoryLevel3,
    selectedBangoodCategoryLevel4,
    selectedSerranoCategoryLevel1,
    selectedSerranoCategoryLevel2,
    store,
    selectedIDropshipCategoryLevel1,
    selectedIDropshipCategoryLevel2,
    selectedIDropshipBrand,
    selectedIDropshipShippingMethod,
    selectedBigBuyCategoryLevel1,
    selectedBigBuyCategoryLevel2,
    selectedBigBuyCategoryLevel3,
    selectedBigBuyBrand,
    selectedBigBuyCondition,
    selected_warehouse,
    checkedInStock,
    checkedHasVideo,
    searchField,
    productvariants,
    fetchproductcount,
    ship_from,
    rating,
    selectedSunskyCategoryLevel1,
    selectedSunskyCategoryLevel2,
    selectedSunskyCategoryLevel3,
    selectedSunskyCategoryLevel4

    // priceChangeCount
  ]);

  // useEffect(() => {
  //   setSelectedProducts([]);
  // }, [products]);

  useEffect(() => {
    setSelectedProducts([]);
  }, [
    bangoodProducts,
    aliexpressProducts,
    wefullfillProducts,
    dropsiteProducts,
    sogaProducts,
    matterhornProducts,
    dropshipzoneProducts,
    fragrancexProducts,
    vidaxlProducts,
    iDropshipProducts,
    bigbuyProducts,
    ingrammicroProducts,
    griffatiProducts,
    serranoProducts,
    ibacraftsProducts,
    sunskyProducts,
  ]);


  console.log("le sunskyproducts", sunskyProducts)

  useEffect(() => {
    setExportingStatus("beforeExport");
  }, [selectedProducts]);

  useEffect(() => {
    if (permissiontoexport.selectedProducts) {
      setSelectedProducts(permissiontoexport.selectedProducts);
    }
  }, [permissiontoexport]);

  const handleSelectStore = (store) => {
    dispatch(setStore(store));
    window.sessionStorage.setItem("store", store);
  };

  const handleSelectSupplier = (supplier) => {
    dispatch(setSelectedSupplier(supplier));
    window.sessionStorage.setItem("selected_supplier", supplier);
  };

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const fetchproducts = () => {
    if (show_products === "new") {
      let category = "";
      let brand = "";

      if (selected_supplier === "matterhorn") {
        category = "/" + selectedMatterHornCategoryLevel1;

        if (selectedMatterHornCategoryLevel2 !== SAC) {
          category = category + "/" + selectedMatterHornCategoryLevel2;
        }

        if (selectedMatterHornCategoryLevel3 !== SAC) {
          category = category + "/" + selectedMatterHornCategoryLevel3;
        }

        if (selectedMatterHornCategoryLevel4 !== SAC) {
          category = category + "/" + selectedMatterHornCategoryLevel4;
        }

        if (selectedMatterHornCategoryLevel5 !== SAC) {
          category = category + "/" + selectedMatterHornCategoryLevel5;
        }
      }
      if (selected_supplier === "vidaxl") {
        category = selectedVidaXLCategoryLevel1;
        if (selectedVidaXLCategoryLevel2 !== SAC) {
          category = category + " > " + selectedVidaXLCategoryLevel2;
        }
        if (selectedVidaXLCategoryLevel3 !== SAC) {
          category = category + " > " + selectedVidaXLCategoryLevel3;
        }
        if (selectedVidaXLCategoryLevel4 !== SAC) {
          category = category + " > " + selectedVidaXLCategoryLevel4;
        }
        if (selectedVidaXLCategoryLevel5 !== SAC) {
          category = category + " > " + selectedVidaXLCategoryLevel5;
        }
      }
      if (selected_supplier === "dropship") {
        category = selectedDropshipCategoryLevel1;
        brand = selectedDropshipBrand;

        if (selectedDropshipCategoryLevel2 !== SAC) {
          category = category + " > " + selectedDropshipCategoryLevel2;
        }
      }

      if (selected_supplier === "sunsky") {
        
        category = selectedSunskyCategoryLevel1.id;

        if (selectedSunskyCategoryLevel2.name !== SAC) {
          category = selectedSunskyCategoryLevel2.id;
        }

        if (selectedSunskyCategoryLevel3.name !== SAC) {
          category = selectedSunskyCategoryLevel3.id;
        }

        if (selectedSunskyCategoryLevel4.name !== SAC) {
          category = selectedSunskyCategoryLevel4.id;
        }

        console.log("here is selected sipplier,,,22",category, selectedSunskyCategoryLevel1.name)
      }

      const CheckShipToNZ = () => {
        if (selectedIDropshipShippingMethod === "Free Shipping") {
          return true;
        } else if (selectedIDropshipShippingMethod === "Ship By AMS") {
          return false;
        } else {
          return [true, false];
        }
      };

      fetch("https://api.saleprodev.com/productimporter/fetchproducts", {
        method: "post",
        body: JSON.stringify({
          supplier: selected_supplier,
          warehouse: selected_warehouse,
          page: import_page,
          products_per_page: products_per_page,
          category: category,
          brand: brand,
          checked_has_video: checkedHasVideo,
          shipping_to_nz: CheckShipToNZ(),
          vendor_id:
            selectedDropshipVendorID === "Dropship"
              ? "dropship"
              : selectedDropshipVendorID,
          search: searchField,
          skulist: skulist,
          store: store,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          if (selected_supplier === "bangood") {
            if (productvariants === "standard") {
              let product_rev = response.products.filter(
                (item) => item.options === null
              );
              console.log("product_rev", product_rev);
              setBangoodProducts(product_rev);
              dispatch(setLoadingProducts(false));
              dispatch(
                setImportTotalPages(
                  parseInt(response.count / products_per_page) + 1
                )
              );
            }
            if (productvariants === "multivariant") {
              let product_rev = response.products.filter(
                (item) => item.options !== null
              );
              setBangoodProducts(product_rev);
              dispatch(
                setImportTotalPages(
                  parseInt(response.count / products_per_page) + 1
                )
              );
              dispatch(setLoadingProducts(false));
            }
            if (productvariants === "all") {
              let product_rev = response.products;
              setBangoodProducts(product_rev);
              dispatch(
                setImportTotalPages(
                  parseInt(response.count / products_per_page) + 1
                )
              );
              dispatch(setLoadingProducts(false));
            }
          }

          if (selected_supplier === "aliexpress") {
            if (response.report.includes("Failed SKUListEmpty")) {
              dispatch(setOpenReport({ open: false, report: response.report }));
              dispatch(setLoadingProducts(false));
              console.log("opening report");
            } else {
              dispatch(setOpenReport({ open: true, report: response.report }));
              dispatch(setLoadingProducts(false));
              console.log("opening report");
            }
          }

          if (selected_supplier === "wefullfill") {
            setWefullfillProducts(response.products);
          }
          if (selected_supplier === "ibacrafts") {
            setIbacraftsProducts(response.products);
          }

          if (selected_supplier === "sunsky") {
            console.log("sunsky0", response.products);
            setSunskyProducts(response.products);
          }

          if (selected_supplier === "dropsite") {
            console.log("DropsiteProducts", response.products);
            setDropsiteProducts(response.products);
          }

          if (selected_supplier === "soga") {
            setSogaProducts(response.products);
          }

          if (selected_supplier === "matterhorn") {
            setMatterhornProducts(response.products);
          }

          if (selected_supplier === "dropship") {
            setDropshipzoneProducts(response.products);
          }

          if (selected_supplier === "vidaxl") {
            setVidaxlProducts(response.products);
          }

          if (selected_supplier === "fragrancex") {
            setFragrancexProducts(response.products);
          }
          if (selected_supplier === "wedropship") {
            setWedropshipProducts(response.products);
          }

          if (selected_supplier === "idropship") {
            setIDropshipProducts(response.products);
          }

          if (selected_supplier === "bigbuy") {
            setBigbuyProducts(response.products);
          }

          if (selected_supplier === "ingrammicro") {
            console.log("IngrammicroProducts", response.products);
            setIngrammicroProducts(response.products);
          }

          if (selected_supplier === "griffati") {
            console.log("GriffatiProducts", response.products);
            setGriffatiProducts(response.products);
          }

          if (selected_supplier === "serrano") {
            setSerranoProducts(response.products);
          }

          dispatch(
            setImportTotalPages(
              parseInt(response.count / products_per_page) + 1
            )
          );
          dispatch(setTotalCounts(response.count));
        })
        .then((err) => {
          console.log(err);
        });
    }

    if (show_products === "imported") {
      fetch(
        "https://api.saleprodev.com/productimporter/fetchimportedproducts",
        {
          method: "post",
          body: JSON.stringify({
            supplier: selected_supplier,
            page: import_page,
            products_per_page: products_per_page,
            vendor_id:
              selectedDropshipVendorID === "Dropship"
                ? "dropship"
                : selectedDropshipVendorID,
            store: store,
            search: searchField,
            productvariants: productvariants,
            warehouse: selected_warehouse,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          if (selected_supplier === "bangood") {
            if (productvariants === "standard") {
              let product_rev = response.products.filter(
                (item) => item.options === null
              );
              console.log("product_rev", product_rev);
              setImportedProducts(product_rev);
              dispatch(setLoadingProducts(false));
              dispatch(
                setImportTotalPages(
                  parseInt(response.count / products_per_page) + 1
                )
              );
            }
            if (productvariants === "multivariant") {
              let product_rev = response.products.filter(
                (item) => item.options !== null
              );
              setImportedProducts(product_rev);
              dispatch(
                setImportTotalPages(
                  parseInt(response.count / products_per_page) + 1
                )
              );
              dispatch(setLoadingProducts(false));
            }
            if (productvariants === "all") {
              let product_rev = response.products;
              setImportedProducts(product_rev);
              dispatch(
                setImportTotalPages(
                  parseInt(response.count / products_per_page) + 1
                )
              );
              dispatch(setLoadingProducts(false));
            }
          }

          if (selected_supplier === "sunsky") {

            setSunskyProducts(response.products);
          }

          if (selected_supplier === "aliexpress") {
            console.log("AliexpressProducts", response.products);
            setAliexpressProducts(response.products);
          }

          if (selected_supplier === "wefullfill") {
            console.log("WefullfillProducts", response.products);
            setWefullfillProducts(response.products);
          }

          if (selected_supplier === "dropsite") {
            console.log("DropsiteProducts", response.products);
            setDropsiteProducts(response.products);
          }

          if (selected_supplier === "soga") {
            console.log("SogaProducts", response.products);
            setSogaProducts(response.products);
          }

          if (selected_supplier === "matterhorn") {
            console.log("MatterhornProducts", response.products);
            setMatterhornProducts(response.products);
          }

          if (selected_supplier === "dropship") {
            console.log("DropshipzoneProducts", response.products);
            setDropshipzoneProducts(response.products);
          }

          if (selected_supplier === "vidaxl") {
            console.log("VidaxlProducts", response.products);
            setVidaxlProducts(response.products);
          }

          if (selected_supplier === "fragrancex") {
            console.log("FragrancexProducts", response.products);
            setFragrancexProducts(response.products);
          }
          if (selected_supplier === "wedropship") {
            console.log("WedropshipProducts", response.products);
            setWedropshipProducts(response.products);
          }

          if (selected_supplier === "idropship") {
            console.log("iDropshipProducts", response.products);
            setIDropshipProducts(response.products);
          }

          if (selected_supplier === "bigbuy") {
            console.log("bigbuyProducts", response.products);
            setBigbuyProducts(response.products);
          }

          if (selected_supplier === "ingrammicro") {
            console.log("IngrammicroProducts", response.products);
            setIngrammicroProducts(response.products);
          }

          if (selected_supplier === "griffati") {
            console.log("GriffatiProducts", response.products);
            setGriffatiProducts(response.products);
          }
          if (selected_supplier === "serrano") {
            console.log("Serrano-----Products", response.products);
            setSerranoProducts(response.products);
          }

          dispatch(
            setImportTotalPages(
              parseInt(response.count / products_per_page) + 1
            )
          );
          dispatch(setTotalCounts(response.count));
          dispatch(setLoadingProducts(false));
        })
        .then((err) => {
          console.log(err);
        });
    }

    if (show_products === "exported") {
      dispatch(setLoadingProducts(true));
      fetch(
        "https://api.saleprodev.com/productimporter/fetchexportedproducts",
        {
          method: "post",
          body: JSON.stringify({
            supplier: selected_supplier,
            page: import_page,
            products_per_page: products_per_page,
            vendor_id:
              selectedDropshipVendorID === "Dropship"
                ? "dropship"
                : selectedDropshipVendorID,
            store: store,
            search: searchField,
            warehouse: selected_warehouse,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          if (selected_supplier === "ibacrafts") {
            const changedProduct = changesImageResponse(response.products);
            // console.log("this is11------------------->>>>>>>>>>>>",changedProduct[0]);
            setExportedProducts(changedProduct);
          } else {
            setExportedProducts(response.products);
          }
          dispatch(
            setImportTotalPages(
              parseInt(response.count / products_per_page) + 1
            )
          );
          dispatch(setTotalCounts(response.count));
          dispatch(setLoadingProducts(false));
        })
        .then((err) => {
          console.log(err);
        });
    }
  };

  const handleFilterByPrice = () => {
    // setPriceChangeCount(priceChangeCount + 1)

    let returnedProducts = [
      bangoodProducts,
      aliexpressProducts,
      wefullfillProducts,
      dropsiteProducts,
      sogaProducts,
      matterhornProducts,
      dropshipzoneProducts,
      fragrancexProducts,
      vidaxlProducts,
      wedropshipProducts,
      iDropshipProducts,
      bigbuyProducts,
      ingrammicroProducts,
      griffatiProducts,
      exportedProducts,
      deletedProducts,
    ].filter((item) => item.length !== 0)[0];
    console.log("returnedProducts", returnedProducts);

    if (returnedProducts && returnedProducts.length !== 0) {
      let filteredProducts = returnedProducts.filter((product) => {
        let costs = [];
        let minCost;
        let maxCost;

        //Bangood Logic
        if (
          selected_supplier === "bangood" &&
          Array.isArray(product.warehouse_price)
        ) {
          product.warehouse_price.map((item) => {
            costs.push(parseFloat(item.warehouse_price).toFixed(2));
          });
          minCost = parseFloat(Math.min(...costs));
          maxCost = parseFloat(Math.max(...costs));
        } else if (Array.isArray(product.variants)) {
          product.variants.map((item) => {
            costs.push(parseFloat(item.price).toFixed(2));
          });
          minCost = parseFloat(Math.min(...costs));
          maxCost = parseFloat(Math.max(...costs));
        }

        if (
          prices.minPrice !== null &&
          prices.minPrice !== "" &&
          prices.maxPrice !== null &&
          prices.maxPrice !== ""
        ) {
          return (
            parseFloat(prices.minPrice) <= minCost &&
            maxCost <= parseFloat(prices.maxPrice)
          );
        } else if (
          prices.minPrice !== null &&
          prices.minPrice !== "" &&
          (prices.maxPrice === null || prices.maxPrice === "")
        ) {
          return parseFloat(prices.minPrice) <= minCost;
        } else if (
          prices.maxPrice !== null &&
          prices.maxPrice !== "" &&
          (prices.minPrice === null || prices.minPrice === "")
        ) {
          return maxCost <= parseFloat(prices.maxPrice);
        }
        return true;
      });

      console.log("filteredProducts", filteredProducts);

      if (show_products === "imported") {
        if (selected_supplier === "bangood") {
          setBangoodProducts(filteredProducts);
        }
        if (selected_supplier === "aliexpress") {
          setAliexpressProducts(filteredProducts);
        }
        if (selected_supplier === "wefullfill") {
          setWefullfillProducts(filteredProducts);
        }

        if (selected_supplier === "dropsite") {
          setDropsiteProducts(filteredProducts);
        }

        if (selected_supplier === "soga") {
          setSogaProducts(filteredProducts);
        }

        if (selected_supplier === "vidaxl") {
          setVidaxlProducts(filteredProducts);
        }

        if (selected_supplier === "dropship") {
          setDropshipzoneProducts(filteredProducts);
        }
        if (selected_supplier === "matterhorn") {
          setMatterhornProducts(filteredProducts);
        }
        if (selected_supplier === "fragrancex") {
          setFragrancexProducts(filteredProducts);
        }
        if (selected_supplier === "wedropship") {
          setWedropshipProducts(filteredProducts);
        }
        if (selected_supplier === "idropship") {
          setIDropshipProducts(filteredProducts);
        }
        if (selected_supplier === "bigbuy") {
          setBigbuyProducts(filteredProducts);
        }
        if (selected_supplier === "ingrammicro") {
          setIngrammicroProducts(filteredProducts);
        }
        if (selected_supplier === "griffati") {
          setGriffatiProducts(filteredProducts);
        }
      }

      if (show_products === "exported") {
        setExportedProducts(filteredProducts);
      }

      if (show_products === "deleted") {
        setDeletedProducts(filteredProducts);
      }
    }
    //To Do: Update logic to reload and filter from original all products if price range changed.
  };

  const handleImport = (product) => {
    console.log(product);
    let storenz = '';
    let storeau = '';
    let storegk = '';
    let storeus = '';
    if(selected_supplier === 'sunsky')
      {
        if(checked.nz)
          {
            storenz = 'nzshopify'
          }
          
          if(checked.au){
             storeau = 'aushopify'
          }
          if(checked.gk)
            {
             storegk = 'gkshopify'
            }
            if(checked.us)
              {
              storeus = 'usshopify'
              }
      }
    fetch("https://api.saleprodev.com/productimporter/importproduct", {
      method: "post",
      body: JSON.stringify({
        product: product,
        supplier: selected_supplier,
        ship_from: ship_from,
        store: store,
        storenz,
        storeau,
        storegk,
        storeus,
        description_preference: description_preference,
        selectedProducts: selectedProducts,
        tags: tags,
        product_type: product_type,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.status === "success") {
          fetchproducts();
          setOpen({ open: true, message: "Success" });
        } else {
          setOpenFailed({ open: true, message: response.status });
        }
      })
      .then((err) => {
        console.log(err);
      });
  };

  const handleImport2 = (product, index, length) => {
    console.log(product);
    fetch("https://api.saleprodev.com/productimporter/importproduct", {
      method: "post",
      body: JSON.stringify({
        product: product,
        supplier: selected_supplier,
        ship_from: ship_from,
        store: store,
        description_preference: description_preference,
        tags: tags,
        product_type: product_type,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.status === "success") {
          if (index === length - 1) {
            fetchproducts();
            setOpen({ open: true, message: response.message });
          }
        } else {
          setOpenFailed({ open: true, message: response.message });
        }
      });
  };

  console.log("description_preference", description_preference);

  const handleImportAliExpress = () => {
    dispatch(setImportingProduct(true));
    fetch("https://api.saleprodev.com/productimporter/importproduct", {
      method: "post",
      body: JSON.stringify({
        supplier: selected_supplier,
        ship_from: ship_from,
        store: store,
        description_preference: description_preference,
        selectedProducts: selectedProducts,
        tags: tags,
        product_type: product_type,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        dispatch(setImportingProduct(false));
        setSelectedProducts([]);
        if (response.status === "success") {
          dispatch(setOpenReport({ open: true, report: response.report }));
        } else {
          setOpenFailed({ open: true, message: response.message });
        }
      })
      .then((err) => {
        console.log(err);
      });
  };

  const handleImport3 = (selectedProducts) => {
    const ws3 = new WebSocket("wss://api.saleprodev.com/wsapp");
    const data = () => {
      ws3.onmessage = function (event) {
        setExportStatus(JSON.parse(event.data));
        console.log("event", event.data);
      };
    };

    data();
    dispatch(setImportingProduct(true));
    fetch("https://api.saleprodev.com/productimporter/importproduct", {
      method: "post",
      body: JSON.stringify({
        selectedProducts: selectedProducts,
        supplier: selected_supplier,
        tags: tags,
        product_type: product_type,
        store: store,
        description_preference: description_preference,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.status === "success") {
          fetchproducts();
          setOpen({ open: true, message: response.message });
          dispatch(setImportingProduct(false));
        }
        if (response.status === "failed") {
          fetchproducts();
          setOpenFailed({ open: true, message: response.message });
          dispatch(setImportingProduct(false));
        }
      })
      .then((err) => {
        console.log(err);
      });
  };

  const handleImportSunsky = () => {
    selectedProducts.map((product, index) => {
      const data = () => {
        handleImport(product, selectedProducts);
      };
      setTimeout(data, index * 100);
    });
  }

  const handleBulkImport = () => {
    console.log("bulk import");
    if (selected_supplier === "aliexpress") {
      handleImportAliExpress();
      // selectedProducts.map((product, index) => {
      //   const data = () => {
      //     handleImport2(product, index, selectedProducts.length)
      //   }
      //   setTimeout(data, index * 50)
      // })
    } else {
      if (
        selected_supplier !== "wefullfill" &&
        selected_supplier !== "matterhorn" &&
        selected_supplier !== "fragrancex" &&
        selected_supplier !== "dropship" &&
        selected_supplier !== "idropship" &&
        selected_supplier !== "vidaxl" &&
        selected_supplier !== "soga" &&
        selected_supplier !== "dropsite" &&
        selected_supplier !== "wedropship" &&
        selected_supplier !== "bigbuy" &&
        selected_supplier !== "ingrammicro" &&
        selected_supplier !== "ibacrafts" &&
        selected_supplier !== "griffati"
      ) {
        // const  ws = new WebSocket("ws://localhost:8080")
        // const  ws = new WebSocket("wss://api.saleprodev.com/wsapp")

        //Banggood Products
        if(selected_supplier === 'sunsky')
          {
          setcheckboxOpen(true)
          }else{
            selectedProducts.map((product, index) => {
              const data = () => {
                handleImport(product, selectedProducts);
              };
              setTimeout(data, index * 100);
            });
          }
      } else {
        //other suppliers
        handleImport3(selectedProducts);
      }
    }
  };

  const handleExport = (product, stores, category) => {
    // const ws = new WebSocket("wss://api.saleprodev.com/wsapp");
    // // const  ws = new WebSocket("ws://localhost:8080")
    // const data = () => {
    //   ws.onmessage = function (event) {
    //     setExportStatus(JSON.parse(event.data));
    //     console.log("created", JSON.parse(event.data));
    //     console.log("created total", JSON.parse(event.data).total);
    //   };
    // };

    // setTimeout(data, 1000);
    if (selected_supplier === "ibacrafts")
      product = changesImageStringResponse(product);

    fetch("https://api.saleprodev.com/productimporter/exporttoshopify", {
      method: "post",
      body: JSON.stringify({
        product: product,
        stores: stores,
        category: category,
        warehouse_bangood_nz: warehouse_bangood_nz,
        shippingmethod_bangood_nz: shippingmethod_bangood_nz,
        tags: tags,
        product_type: product_type,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("reponse==========", response);
        if (response.status === "success") {
          fetchproducts();
          setOpen({ open: true, message: "Success" });
        } else {
          setOpenFailed({ open: true, message: response.message });
        }
        setExportingStatus("exportFinished");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleExportAliExpress = () => {
    setExportingStatus("exporting");

    const ws = new WebSocket("wss://api.saleprodev.com/wsapp");
    // const  ws = new WebSocket("ws://localhost:8080")
    const data = () => {
      ws.onmessage = function (event) {
        setExportStatus(JSON.parse(event.data));
        console.log("created", JSON.parse(event.data).created);
        console.log("created total", JSON.parse(event.data).total);
      };
    };

    setTimeout(data, 1000);

    fetch("https://api.saleprodev.com/productimporter/exporttoshopify", {
      method: "post",
      body: JSON.stringify(permissiontoexport),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("response", response);
        if (response.status === "success") {
          fetchproducts();
          setOpen({ open: true, message: "Success" });
        } else {
          setOpenFailed({ open: true, message: "Failed" });
        }
        setExportingStatus("exportFinished");
      })
      .catch((err) => {
        console.log(err);
      });
  };  

  const handleBulkExport = (stores, category) => {
    console.log("exporting bulk");
    if (selected_supplier === "aliexpress") {
      setExportingStatus("exporting");

      // const ws = new WebSocket("wss://api.saleprodev.com/wsapp");
      // // const  ws = new WebSocket("ws://localhost:8080")
      // const data = () => {
      //   ws.onmessage = function (event) {
      //     setExportStatus(JSON.parse(event.data));
      //     console.log(event.data);
      //   };
      // };

      // setTimeout(data, 1000);

      Axios.post("https://api.saleprodev.com/productimporter/exportreport", {
        product: "product",
        stores: [store],
        category: category,
        warehouse_bangood_nz: warehouse_bangood_nz,
        shippingmethod_bangood_nz: shippingmethod_bangood_nz,
        tags: tags,
        selectedProducts: selectedProducts,
        supplier: selected_supplier,
        product_type: product_type,
      })
        .then((response) => {
          console.log("new", response.data);
          setPermissiontoexport(response.data);
          setExportingStatus("beforeExport");
        })
        .then((err) => {
          console.log(err);
        });
    } else {
      console.log("selected_supplier: Not Aliexpress");
      setExportingStatus("exporting");

      const ws = new WebSocket("wss://api.saleprodev.com/wsapp");
      // const  ws = new WebSocket("ws://localhost:8080")
      const data = () => {
        ws.onmessage = function (event) {
          setExportStatus(JSON.parse(event.data));
          console.log("created", JSON.parse(event.data));
          console.log("created total", JSON.parse(event.data).total);
        };
      };

      setTimeout(data, 1000);

      selectedProducts.map((product, index) => {
        const data = () => {
          handleExport(product, stores, category);
        };
        setTimeout(data, index * 5000);
      });
      // setExportingStatus("exportFinished");
    }
  };

  const handleBulkDelete = () => {
    console.log("selectedProducts", selectedProducts);
    selectedProducts.map((product) => {
      fetch(
        "https://api.saleprodev.com/productimporter/deleteimportedproducts",
        {
          method: "post",
          body: JSON.stringify({ store: store, product: product }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          console.log("deleteimportedproducts", response);
          if (response.status === "success") {
            fetchproducts();
          }
        })
        .then((err) => {
          console.log(err);
        });
    });
  };

  const handleDeleteFromShopify = () => {
    // const  ws = new WebSocket("ws://localhost:8080")
    const ws = new WebSocket("wss://api.saleprodev.com/wsapp");
    // const  ws = new WebSocket("wss://api.saleprodev.com/wsapp")
    const data = () => {
      ws.onmessage = function (event) {
        setExportStatus(JSON.parse(event.data));
        console.log(event.data);
      };
    };
    data();
    if (store === "usshopify") {
      fetch("https://api.saleprodev.com/productimporter/deletefromshopifyus", {
        method: "post",
        body: JSON.stringify({ selectedProducts: selectedProducts }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            setOpen({ open: true, message: "Success" });
          }
        })
        .then((err) => {
          console.log(err);
        });
    }
    if (store === "nzshopify") {
      fetch("https://api.saleprodev.com/productimporter/deletefromshopifynz", {
        method: "post",
        body: JSON.stringify({ selectedProducts: selectedProducts }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            setOpen({ open: true, message: "Success" });
          }
        })
        .then((err) => {
          console.log(err);
        });
    }
    if (store === "aushopify") {
      fetch("https://api.saleprodev.com/productimporter/deletefromshopifyau", {
        method: "post",
        body: JSON.stringify({ selectedProducts: selectedProducts }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            setOpen({ open: true, message: "Success" });
          }
        })
        .then((err) => {
          console.log(err);
        });
    }

    if (store === "gkshopify") {
      fetch("https://api.saleprodev.com/productimporter/deletefromshopifygk", {
        method: "post",
        body: JSON.stringify({ selectedProducts: selectedProducts }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            setOpen({ open: true, message: "Success" });
          }
        })
        .then((err) => {
          console.log(err);
        });
    }
  };

  const handleClearFilters = () => {
    setSelectedMatterHornCategoryLevel1(SAC);
    setSelectedDropshipCategoryLevel1(SAC);
    setSelectedVidaXLCategoryLevel1(SAC);
    setSelectedDropshipBrand("Select a Brand");
  };

  const handleToken = () => {
    dispatch(setSelectedSupplier("bangood"));
    fetch("https://api.saleprodev.com/fetchtoken");
  };

  const handleDsz = () => {
    console.log(
      "here is the selected file---===============------>>>",
      selectedProducts
    );
    alert("Please Confirm, if you want to Export?");
    handleBulkExport("dropshipzonev2", "");
  };

  console.log("loading", loading_products);

  const handleDiscontinued = () => {
    fetch(
      "https://api.saleprodev.com/getDiscontinuedProduct?supplier=vidaxl&store=nz",
      {
        method: "get",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("logile dat=================== = = r", response.data);
        const disContinuedProduct = response.data;
        const newdata = disContinuedProduct.slice(0, 10).filter((el) => el);

        setDiscontinuedProd([...disContinuedProduct]);
      });
  };

  const handleCloseCustom = () => {
    setOpenCustom(false);
  };

  console.log("selected_supplier------------------------", selected_supplier);
  return (
    <div style={{ overflowX: "auto", overflowY: "hidden" }}>
      <Grid container id="content" xs={12} sx={{ minWidth: "1920px" }} p={1}>
        {/* Left Side Menu */}
        <SourcingSideMenu setShowProducts={setShowProducts} />

        {/* Right Side Content */}
        <Grid item xs={11} pl={1}>
          {/* Breadcrumb */}
          <Grid item>
            <Card elevation={0} sx={{ px: 2, py: 0.5 }}>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography
                  variant="body2"
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                  onClick={(event) => dispatch(setShowProducts("new"))}
                >
                  {page.charAt(0).toUpperCase() + page.slice(1)}
                </Typography>

                <Typography variant="body2">
                  {show_products === "new" || show_products === "imported"
                    ? "Import"
                    : null}
                  {show_products === "exported" && "Export"}
                  {show_products === "deleted" && "Delete"}
                </Typography>

                <Typography variant="body2">
                  {show_products === "new" && "Product Importer"}
                  {show_products === "imported" && "Imported Products"}
                  {show_products === "exported" && "Exported Products"}
                  {show_products === "deleted" && "Deleted Products"}
                </Typography>
              </Breadcrumbs>
            </Card>
          </Grid>

          {/* ============================= Product Importer ======================= */}
          {show_products === "new" && (
            <Grid container sx={{ py: 1.2 }}>
              {/* Tabs */}
              <Grid container>
                <Grid
                  container
                  justifyContent="space-between"
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Grid item container xs={11.2}>
                    <Tabs
                      value={tabValue}
                      onChange={handleChangeTab}
                      aria-label="Product Importer Tabs"
                      sx={{
                        "& button": {
                          textTransform: "none",
                        },

                        "& .MuiTab-root": {
                          fontSize: "1rem",
                        },
                      }}
                    >
                      <Tab label="Import Products" value={0} />
                      <Tab label="Bulk Import" value={1} />
                    </Tabs>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={0.8}
                    justifyContent="flex-end"
                    alignContent="center"
                    sx={{ cursor: "pointer" }}
                    onClick={(event) => {
                      handlePage(event, "settings");
                      dispatch(setSubsettings("dropship"));
                      dispatch(setSettingsItem("import_settings"));
                    }}
                  >
                    <SettingsIcon />
                    <Typography variant="body1" mx={1}>
                      Settings
                    </Typography>
                  </Grid>
                </Grid>

                {tabValue === 0 && (
                  <Grid
                    container
                    alignItems="center"
                    sx={{
                      py: 1,
                    }}
                  >
                    {/* Product Importer: Quick Filter */}
                    <Grid item container>
                      <SourcingQuickFilter />
                    </Grid>

                    {/* Product Importer: Import / Export / Delete Button */}
                    <Grid
                      container
                      sx={{
                        mt: 1,
                      }}
                    >
                      <Grid item xs={6}>
                        {selectedProducts.length !== 0 ? (
                          <>
                            {importingproduct === false ? (
                              <Grid container alignItems="center">
                                <SmallContainedButton
                                  width="164px"
                                  onClick={(event) => handleBulkImport()}
                                >
                                  IMPORT SELECTED
                                </SmallContainedButton>

                                <Grid item mx={1}>
                                  <Typography variant="body2">
                                    (Selected Products:{selectedProducts.length}
                                    )
                                  </Typography>
                                </Grid>

                                <Grid item mx={1}>
                                  <VideoDownloadSheetModal
                                    showProducts="new"
                                    selectedProducts={selectedProducts}
                                    openVideoDownloadSheet={
                                      openVideoDownloadSheet
                                    }
                                    setOpenVideoDownloadSheet={
                                      setOpenVideoDownloadSheet
                                    }
                                    handleCloseVideoDownloadSheet={
                                      handleCloseVideoDownloadSheet
                                    }
                                  />
                                </Grid>
                              </Grid>
                            ) : (
                              <CircularProgress size={50} />
                            )}
                          </>
                        ) : (
                          <>
                            <SmallContainedButton disabled width="164px">
                              IMPORT SELECTED
                            </SmallContainedButton>

                            <SmallContainedButton disabled width="180px">
                              Download Video Sheet
                            </SmallContainedButton>

                            {selected_supplier === "vidaxl" ||
                            selected_supplier === "dropship" ||
                            selected_supplier === "matterhorn" ? (
                              <Button
                                variant={"contained"}
                                sx={{
                                  width: "240px",
                                  height: 26,
                                  fontSize: "14px",
                                  textTransform: "none",
                                  m: 0.25,
                                }}
                                onClick={() => setOpenCustom(true)}
                              >
                                Download Discontinued Sheet
                              </Button>
                            ) : null}

                            {/* {discontinuedProd.length > 0 && <ExcelDownloder
                              data={discontinuedProd}
                              filename={"customsheet"}
                              type={"button"} // or type={'button'}
                              style={{ marginLeft: "15%" }}
                            >
                              DOWNLOAD CUSTOMS SHEET
                            </ExcelDownloder>} */}
                          </>
                        )}
                      </Grid>

                      {/* {show_products === "imported" && (
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            lg={3}
                            style={{
                              textAlign: "left",
                              marginTop: "1%",
                              marginLeft: "0%",
                            }}
                          >
                            {selectedProducts.length !== 0 ? (
                              <>
                                <Button
                                  variant="contained"
                                  onClick={(event) => setOpenBulkExport(true)}
                                >
                                  EXPORT
                                </Button>
                                <Button
                                  variant="contained"
                                  color="error"
                                  onClick={(event) => handleBulkDelete()}
                                  style={{ marginLeft: "1%" }}
                                >
                                  DELETE
                                </Button>
                                <BulkExportModal
                                  open={openbulkexport}
                                  handleClose={handleCloseBulkExport}
                                  selectedProducts={selectedProducts}
                                  handleBulkExport={handleBulkExport}
                                  warehouse_bangood_nz={warehouse_bangood_nz}
                                  setWarehouseBangoodNZ={setWarehouseBangoodNZ}
                                  shippingmethod_bangood_nz={
                                    shippingmethod_bangood_nz
                                  }
                                  setShippingMethodBangoodNZ={
                                    setShippingMethodBangoodNZ
                                  }
                                  tags={tags}
                                  setTags={setTags}
                                  exportstatus={exportstatus}
                                  product_type={product_type}
                                  setProductType={setProductType}
                                  permissiontoexport={permissiontoexport}
                                  handleExportAliExpress={handleExportAliExpress}
                                />
                                <Typography variant="subtitle">
                                  (Selected Products:{selectedProducts.length})
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Button
                                  disabled
                                  variant="contained"
                                  onClick={(event) => setOpenBulkExport(true)}
                                >
                                  EXPORT
                                </Button>
                                <Button
                                  disabled
                                  variant="contained"
                                  onClick={(event) => handleBulkDelete()}
                                  style={{ marginLeft: "1%" }}
                                >
                                  DELETE
                                </Button>
                                <BulkExportModal
                                  open={openbulkexport}
                                  handleClose={handleCloseBulkExport}
                                  selectedProducts={selectedProducts}
                                  handleBulkExport={handleBulkExport}
                                  exportstatus={exportstatus}
                                />
                              </>
                            )}
                          </Grid>
                        )} */}

                      {/* {show_products === "exported" && (
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            lg={3}
                            style={{
                              textAlign: "left",
                              marginTop: "1%",
                              marginLeft: "0%",
                            }}
                          >
                            {selectedProducts.length !== 0 ? (
                              <div>
                                {exportstatus.total === 0 ? (
                                  <div>
                                    <div>
                                      <Button
                                        variant="contained"
                                        color="error"
                                        onClick={(event) =>
                                          handleDeleteFromShopify()
                                        }
                                        style={{ marginLeft: "1%" }}
                                      >
                                        DELETE FROM SHOPIFY
                                      </Button>
                                    </div>
                                    <div>
                                      <Typography variant="subtitle">
                                        (Selected Products:
                                        {selectedProducts.length})
                                      </Typography>
                                    </div>
                                  </div>
                                ) : (
                                  <CircularProgressWithLabel
                                    variant="determinate"
                                    value={
                                      (exportstatus.created /
                                        exportstatus.total) *
                                      100
                                    }
                                    size={50}
                                  />
                                )}
                              </div>
                            ) : (
                              <>
                                {exportstatus.total === 0 ? (
                                  <Button
                                    disabled
                                    variant="contained"
                                    onClick={(event) => handleBulkDelete()}
                                    style={{ marginLeft: "1%" }}
                                  >
                                    DELETE FROM SHOPIFY
                                  </Button>
                                ) : (
                                  <CircularProgressWithLabel
                                    variant="determinate"
                                    value={
                                      (exportstatus.created /
                                        exportstatus.total) *
                                      100
                                    }
                                    size={50}
                                  />
                                )}
                              </>
                            )}
                          </Grid>
                        )} */}

                      {/* {show_products === "deleted" && (
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            lg={3}
                            style={{
                              textAlign: "left",
                              marginTop: "1%",
                              marginLeft: "0%",
                            }}
                          >
                            {selectedProducts.length !== 0 ? (
                              <>
                                <Button
                                  variant="contained"
                                  onClick={(event) => setOpenBulkExport(true)}
                                >
                                  EXPORT
                                </Button>
                                <Button
                                  variant="contained"
                                  color="error"
                                  onClick={(event) => handleBulkDelete()}
                                  style={{ marginLeft: "1%" }}
                                >
                                  DELETE
                                </Button>
                                <BulkExportModal
                                  open={openbulkexport}
                                  handleClose={handleCloseBulkExport}
                                  selectedProducts={selectedProducts}
                                  handleBulkExport={handleBulkExport}
                                  warehouse_bangood_nz={warehouse_bangood_nz}
                                  setWarehouseBangoodNZ={setWarehouseBangoodNZ}
                                  shippingmethod_bangood_nz={
                                    shippingmethod_bangood_nz
                                  }
                                  setShippingMethodBangoodNZ={
                                    setShippingMethodBangoodNZ
                                  }
                                  tags={tags}
                                  setTags={setTags}
                                  exportstatus={exportstatus}
                                  product_type={product_type}
                                  setProductType={setProductType}
                                  permissiontoexport={permissiontoexport}
                                  handleExportAliExpress={handleExportAliExpress}
                                />
                                <Typography variant="subtitle">
                                  (Selected Products:{selectedProducts.length})
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Button
                                  disabled
                                  variant="contained"
                                  onClick={(event) => setOpenBulkExport(true)}
                                >
                                  EXPORT
                                </Button>
                                <Button
                                  disabled
                                  variant="contained"
                                  onClick={(event) => handleBulkDelete()}
                                  style={{ marginLeft: "1%" }}
                                >
                                  DELETE
                                </Button>
                                <BulkExportModal
                                  open={openbulkexport}
                                  handleClose={handleCloseBulkExport}
                                  selectedProducts={selectedProducts}
                                  handleBulkExport={handleBulkExport}
                                  exportstatus={exportstatus}
                                />
                              </>
                            )}
                          </Grid>
                        )} */}

                      <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                        xs={6}
                      >
                        {/* <Grid
                          item
                          container
                          alignItems="center"
                          justifyContent="flex-end"
                          xs={4.5}
                        >
                          <SourcingSearchbar />
                        </Grid> */}

                        <Pagination
                          page={page}
                          total_counts={total_counts}
                          selectedOrders={selectedOrders}
                          setSelectedOrders={setSelectedOrders}
                        />
                      </Grid>
                    </Grid>

                    {/* Product Importer: Categories */}
                    {selected_supplier === "matterhorn" && (
                      <Grid
                        container
                        sx={{
                          p: 1,
                          mt: 1,
                          borderRadius: "6px",
                          backgroundColor: "bg.paper",
                          "& legend": {
                            width: "unset",
                          },
                        }}
                      >
                        <Grid item container alignItems="center">
                          {FilterBySelect}

                          <MatterHornCategorySelect
                            selectedMatterHornCategoryLevel1={
                              selectedMatterHornCategoryLevel1
                            }
                            setSelectedMatterHornCategoryLevel1={
                              setSelectedMatterHornCategoryLevel1
                            }
                            selectedMatterHornCategoryLevel2={
                              selectedMatterHornCategoryLevel2
                            }
                            setSelectedMatterHornCategoryLevel2={
                              setSelectedMatterHornCategoryLevel2
                            }
                            selectedMatterHornCategoryLevel3={
                              selectedMatterHornCategoryLevel3
                            }
                            setSelectedMatterHornCategoryLevel3={
                              setSelectedMatterHornCategoryLevel3
                            }
                            selectedMatterHornCategoryLevel4={
                              selectedMatterHornCategoryLevel4
                            }
                            setSelectedMatterHornCategoryLevel4={
                              setSelectedMatterHornCategoryLevel4
                            }
                            selectedMatterHornCategoryLevel5={
                              selectedMatterHornCategoryLevel5
                            }
                            setSelectedMatterHornCategoryLevel5={
                              setSelectedMatterHornCategoryLevel5
                            }
                            skulist={skulist}
                            setSkuList={setSkuList}
                            filterby={filterby}
                            loading_products={loading_products}
                            fetchproductcount={fetchproductcount}
                            setFetchProductCount={setFetchProductCount}
                          />

                          <Box sx={{ flexGrow: 1 }} />

                          <SourcingSearchbar />
                        </Grid>
                      </Grid>
                    )}

                    {selected_supplier === "vidaxl" && (
                      <Grid
                        container
                        sx={{
                          p: 1,
                          mt: 1,
                          borderRadius: "6px",
                          backgroundColor: "bg.paper",
                          "& legend": {
                            width: "unset",
                          },
                        }}
                      >
                        <Grid item container alignItems="center">
                          {FilterBySelect}

                          <VidaXLCategorySelect
                            selectedVidaXLCategoryLevel1={
                              selectedVidaXLCategoryLevel1
                            }
                            setSelectedVidaXLCategoryLevel1={
                              setSelectedVidaXLCategoryLevel1
                            }
                            selectedVidaXLCategoryLevel2={
                              selectedVidaXLCategoryLevel2
                            }
                            setSelectedVidaXLCategoryLevel2={
                              setSelectedVidaXLCategoryLevel2
                            }
                            selectedVidaXLCategoryLevel3={
                              selectedVidaXLCategoryLevel3
                            }
                            setSelectedVidaXLCategoryLevel3={
                              setSelectedVidaXLCategoryLevel3
                            }
                            selectedVidaXLCategoryLevel4={
                              selectedVidaXLCategoryLevel4
                            }
                            setSelectedVidaXLCategoryLevel4={
                              setSelectedVidaXLCategoryLevel4
                            }
                            selectedVidaXLCategoryLevel5={
                              selectedVidaXLCategoryLevel5
                            }
                            setSelectedVidaXLCategoryLevel5={
                              setSelectedVidaXLCategoryLevel5
                            }
                            skulist={skulist}
                            setSkuList={setSkuList}
                            filterby={filterby}
                            loading_products={loading_products}
                            fetchproductcount={fetchproductcount}
                            setFetchProductCount={setFetchProductCount}
                          />

                          <Box sx={{ flexGrow: 1 }} />

                          <SourcingSearchbar />
                        </Grid>
                      </Grid>
                    )}

                    {selected_supplier === "dropship" && (
                      <Grid
                        container
                        sx={{
                          p: 1,
                          mt: 1,
                          borderRadius: "6px",
                          backgroundColor: "bg.paper",
                          "& legend": {
                            width: "unset",
                          },
                        }}
                      >
                        <Grid item container alignItems="center">
                          {FilterBySelect}

                          <DropshipCategorySelect
                            selectedDropshipCategoryLevel1={
                              selectedDropshipCategoryLevel1
                            }
                            setSelectedDropshipCategoryLevel1={
                              setSelectedDropshipCategoryLevel1
                            }
                            selectedDropshipCategoryLevel2={
                              selectedDropshipCategoryLevel2
                            }
                            setSelectedDropshipCategoryLevel2={
                              setSelectedDropshipCategoryLevel2
                            }
                            selectedDropshipBrand={selectedDropshipBrand}
                            setSelectedDropshipBrand={setSelectedDropshipBrand}
                            selectedDropshipVendorID={selectedDropshipVendorID}
                            setSelectedDropshipVendorID={
                              setSelectedDropshipVendorID
                            }
                            skulist={skulist}
                            setSkuList={setSkuList}
                            filterby={filterby}
                            loading_products={loading_products}
                            fetchproductcount={fetchproductcount}
                            setFetchProductCount={setFetchProductCount}
                            selectedIDropshipShippingMethod={
                              selectedIDropshipShippingMethod
                            }
                            setSelectedIDropshipShippingMethod={
                              setSelectedIDropshipShippingMethod
                            }
                          />

                          <Box sx={{ flexGrow: 1 }} />

                          <SourcingSearchbar />
                        </Grid>
                      </Grid>
                    )}

                    {selected_supplier === "serrano" && (
                      <Grid
                        container
                        sx={{
                          p: 1,
                          mt: 1,
                          borderRadius: "6px",
                          backgroundColor: "bg.paper",
                          "& legend": {
                            width: "unset",
                          },
                        }}
                      >
                        <Grid item container alignItems="center">
                          {FilterBySelect}
                          <SerranoCategorySelectCategorySelect
                            selectedSerranoCategoryLevel1={
                              selectedSerranoCategoryLevel1
                            }
                            setSelectedSerranoCategoryLevel1={
                              setSelectedSerranoCategoryLevel1
                            }
                            selectedSerranoCategoryLevel2={
                              selectedSerranoCategoryLevel2
                            }
                            setSelectedSerranoCategoryLevel2={
                              setSelectedSerranoCategoryLevel2
                            }
                            skulist={skulist}
                            setSkuList={setSkuList}
                            filterby={filterby}
                            loading_products={loading_products}
                            fetchproductcount={fetchproductcount}
                            setFetchProductCount={setFetchProductCount}
                          />

                          <Box sx={{ flexGrow: 1 }} />

                          <SourcingSearchbar />
                        </Grid>
                      </Grid>
                    )}

                    {selected_supplier === "bangood" && (
                      <Grid
                        container
                        sx={{
                          p: 1,
                          mt: 1,
                          borderRadius: "6px",
                          backgroundColor: "bg.paper",
                          "& legend": {
                            width: "unset",
                          },
                        }}
                      >
                        <Grid item container alignItems="center">
                          {FilterBySelect}

                          <BangoodCategorySelect
                            selectedBangoodCategoryLevel1={
                              selectedBangoodCategoryLevel1
                            }
                            setSelectedBangoodCategoryLevel1={
                              setSelectedBangoodCategoryLevel1
                            }
                            selectedBangoodCategoryLevel2={
                              selectedBangoodCategoryLevel2
                            }
                            setSelectedBangoodCategoryLevel2={
                              setSelectedBangoodCategoryLevel2
                            }
                            selectedBangoodCategoryLevel3={
                              selectedBangoodCategoryLevel3
                            }
                            setSelectedBangoodCategoryLevel3={
                              setSelectedBangoodCategoryLevel3
                            }
                            selectedBangoodCategoryLevel4={
                              selectedBangoodCategoryLevel4
                            }
                            setSelectedBangoodCategoryLevel4={
                              setSelectedBangoodCategoryLevel4
                            }
                            warehouse={warehouse}
                            setWarehouse={setWarehouse}
                            productvariants={productvariants}
                            setProductVariants={setProductVariants}
                            skulist={skulist}
                            setSkuList={setSkuList}
                            filterby={filterby}
                            loading_products={loading_products}
                            fetchproductcount={fetchproductcount}
                            setFetchProductCount={setFetchProductCount}
                            setTags={setTags}
                            setProductType={setProductType}
                          />

                          <Box sx={{ flexGrow: 1 }} />

                          <SourcingSearchbar />
                        </Grid>

                        <Grid item container>
                          <BangoodVariantSelect
                            productvariants={productvariants}
                            setProductVariants={setProductVariants}
                          />
                        </Grid>
                      </Grid>
                    )}

                    {selected_supplier === "sunsky" && (
                      <Grid
                        container
                        sx={{
                          p: 1,
                          mt: 1,
                          borderRadius: "6px",
                          backgroundColor: "bg.paper",
                          "& legend": {
                            width: "unset",
                          },
                        }}
                      >
                        <Grid item container alignItems="center">
                          {FilterBySelect}

                          <SunSkySelect
                            selectedSunskyCategoryLevel1={
                              selectedSunskyCategoryLevel1
                            }
                            setSelectedSunskyCategoryLevel1={
                              setSelectedSunskyCategoryLevel1
                            }
                            selectedSunskyCategoryLevel2={
                              selectedSunskyCategoryLevel2
                            }
                            setSelectedSunskyCategoryLevel2={
                              setSelectedSunskyCategoryLevel2
                            }
                            selectedSunskyCategoryLevel3={
                              selectedSunskyCategoryLevel3
                            }
                            setSelectedSunskyCategoryLevel3={
                              setSelectedSunskyCategoryLevel3
                            }
                            selectedSunskyCategoryLevel4={
                              selectedSunskyCategoryLevel4
                            }
                            setSelectedSunskyCategoryLevel4={
                              setSelectedSunskyCategoryLevel4
                            }
                            warehouse={warehouse}
                            setWarehouse={setWarehouse}
                            skulist={skulist}
                            setSkuList={setSkuList}
                            filterby={filterby}
                            loading_products={loading_products}
                            fetchproductcount={fetchproductcount}
                            setFetchProductCount={setFetchProductCount}
                            setTags={setTags}
                            setProductType={setProductType}
                          />

                          <Box sx={{ flexGrow: 1 }} />

                          <SourcingSearchbar />
                        </Grid>

                        <Grid item container>
                          <BangoodVariantSelect
                            productvariants={productvariants}
                            setProductVariants={setProductVariants}
                          />
                        </Grid>
                      </Grid>
                    )}

                    {selected_supplier === "aliexpress" && (
                      <Grid
                        item
                        container
                        alignItems="center"
                        sx={{
                          p: 1,
                          mt: 1,
                          backgroundColor: "bg.paper",
                          borderRadius: "6px",
                          "& legend": {
                            width: "unset",
                          },
                        }}
                      >
                        <Grid item>
                          <TextField
                            multiline
                            maxRows={5}
                            placeholder="Enter Product IDs"
                            onChange={(event) => setSkuList(event.target.value)}
                            sx={{
                              mr: 0.5,
                              width: "500px",
                              fontSize: "0.8rem",
                              "& legend": {
                                width: "unset",
                              },
                              "& .MuiFormControl-root, .MuiOutlinedInput-root":
                                {
                                  minHeight: 26,
                                  p: "0px!important",
                                },
                            }}
                          />

                          {skulist === "" ? (
                            ""
                          ) : (
                            <Chip
                              size="small"
                              color="primary"
                              variant="outlined"
                              label={`${
                                skulist.split(/\r\n|\r|\n/).length
                              } Items`}
                              sx={{
                                height: 20,
                                position: "absolute",
                                transform:
                                  skulist.split(/\r\n|\r|\n/).length <= 1
                                    ? "translate(-95px, 3px)"
                                    : "translate(-95px, 6px)",
                              }}
                            />
                          )}
                        </Grid>

                        <FormControl
                          size="small"
                          sx={{
                            width: "100px",
                            mr: 0.5,
                            height: 26,
                            fontSize: "0.8rem",
                            "& legend": {
                              width: "unset",
                            },
                            "& .MuiFormControl-root, .MuiOutlinedInput-root": {
                              height: 26,
                              p: "0px!important",
                            },
                          }}
                        >
                          <InputLabel id="ship-from-select-label">
                            Ship From:
                          </InputLabel>
                          <Select
                            label="Ship From:"
                            value={ship_from}
                            onChange={(event) =>
                              setShipFrom(event.target.value)
                            }
                            labelId="ship-from-select-label"
                          >
                            <MenuItem
                              value="CN"
                              sx={{ height: 26, fontSize: "0.8rem" }}
                            >
                              CN
                            </MenuItem>
                            <MenuItem
                              value="AU"
                              sx={{ height: 26, fontSize: "0.8rem" }}
                            >
                              AU
                            </MenuItem>
                            <MenuItem
                              value="US"
                              sx={{ height: 26, fontSize: "0.8rem" }}
                            >
                              US
                            </MenuItem>
                          </Select>
                        </FormControl>

                        <TextField
                          size="small"
                          placeholder="Tag"
                          sx={{
                            mr: 0.5,
                            width: "180px",
                            height: 26,
                            fontSize: "0.8rem",
                            "& legend": {
                              width: "unset",
                            },
                            "& .MuiFormControl-root, .MuiOutlinedInput-root": {
                              height: 26,
                              p: "0px!important",
                            },
                          }}
                          onChange={(event) => setTags(event.target.value)}
                        />

                        <TextField
                          size="small"
                          placeholder="Type"
                          sx={{
                            mr: 0.5,
                            width: "180px",
                            height: 26,
                            fontSize: "0.8rem",
                            "& legend": {
                              width: "unset",
                            },
                            "& .MuiFormControl-root, .MuiOutlinedInput-root": {
                              height: 26,
                              p: "0px!important",
                            },
                          }}
                          onChange={(event) =>
                            setProductType(event.target.value)
                          }
                        />

                        <Grid item sx={{ mr: 0.5 }}>
                          <SmallContainedButton
                            width="60px"
                            disabled={loading_products ? true : false}
                            onClick={(event) =>
                              setFetchProductCount(fetchproductcount + 1)
                            }
                          >
                            Import
                          </SmallContainedButton>
                        </Grid>

                        <SmallContainedButton
                          width="60px"
                          variant="outlined"
                          disabled
                        >
                          Clear
                        </SmallContainedButton>

                        <InStockCheckbox />

                        <Box sx={{ flexGrow: 1 }} />
                        <SourcingSearchbar
                          selected_supplier={selected_supplier}
                        />
                      </Grid>
                    )}

                    {selected_supplier === "idropship" && (
                      <Grid
                        container
                        sx={{
                          p: 1,
                          mt: 1,
                          borderRadius: "6px",
                          backgroundColor: "bg.paper",
                          "& legend": {
                            width: "unset",
                          },
                        }}
                      >
                        <Grid item container alignItems="center">
                          {FilterBySelect}

                          <IDropshipCategorySelect
                            selectedIDropshipCategoryLevel1={
                              selectedIDropshipCategoryLevel1
                            }
                            setSelectedIDropshipCategoryLevel1={
                              setSelectedIDropshipCategoryLevel1
                            }
                            selectedIDropshipCategoryLevel2={
                              selectedIDropshipCategoryLevel2
                            }
                            setSelectedIDropshipCategoryLevel2={
                              setSelectedIDropshipCategoryLevel2
                            }
                            selectedIDropshipBrand={selectedIDropshipBrand}
                            setSelectedIDropshipBrand={
                              setSelectedIDropshipBrand
                            }
                            selectedIDropshipShippingMethod={
                              selectedIDropshipShippingMethod
                            }
                            setSelectedIDropshipShippingMethod={
                              setSelectedIDropshipShippingMethod
                            }
                            skulist={skulist}
                            setSkuList={setSkuList}
                            filterby={filterby}
                            loading_products={loading_products}
                            fetchproductcount={fetchproductcount}
                            setFetchProductCount={setFetchProductCount}
                          />

                          <Box sx={{ flexGrow: 1 }} />

                          <SourcingSearchbar />
                        </Grid>
                      </Grid>
                    )}

                    {selected_supplier === "bigbuy" && (
                      <Grid
                        container
                        sx={{
                          p: 1,
                          mt: 1,
                          borderRadius: "6px",
                          backgroundColor: "bg.paper",
                          "& legend": {
                            width: "unset",
                          },
                        }}
                      >
                        <Grid item container alignItems="center">
                          {FilterBySelect}

                          <BigBuyCategorySelect
                            selectedBigBuyCategoryLevel1={
                              selectedBigBuyCategoryLevel1
                            }
                            setSelectedBigBuyCategoryLevel1={
                              setSelectedBigBuyCategoryLevel1
                            }
                            selectedBigBuyCategoryLevel2={
                              selectedBigBuyCategoryLevel2
                            }
                            setSelectedBigBuyCategoryLevel2={
                              setSelectedBigBuyCategoryLevel2
                            }
                            selectedBigBuyCategoryLevel3={
                              selectedBigBuyCategoryLevel3
                            }
                            setSelectedBigBuyCategoryLevel3={
                              setSelectedBigBuyCategoryLevel3
                            }
                            selectedBigBuyBrand={selectedBigBuyBrand}
                            setSelectedBigBuyBrand={setSelectedBigBuyBrand}
                            selectedBigBuyCondition={selectedBigBuyCondition}
                            setSelectedBigBuyCondition={
                              setSelectedBigBuyCondition
                            }
                            skulist={skulist}
                            setSkuList={setSkuList}
                            filterby={filterby}
                            loading_products={loading_products}
                            fetchproductcount={fetchproductcount}
                            setFetchProductCount={setFetchProductCount}
                          />

                          <Box sx={{ flexGrow: 1 }} />

                          <SourcingSearchbar />
                        </Grid>
                      </Grid>
                    )}

                    {/* Product Importer: Product Card */}
                    <Grid container>
                      <Grid item xs={12}>
                        <Card elevation={0} sx={{ width: "100%", mt: 1 }}>
                          <Grid
                            sx={{
                              display: "grid",
                              gridTemplateColumns:
                                "2% 4% 35% 8% 8% 10% 8% 5% 5% 5% 6% 4%",
                              height: "2.6rem",
                              backgroundColor: "bg.grayBG",
                              alignItems: "center",
                            }}
                          >
                            <Grid container justifyContent="center">
                              {selected_supplier === "bangood" && (
                                <BangoodMainCheckbox
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                  products={bangoodProducts}
                                />
                              )}
                              {selected_supplier === "aliexpress" && (
                                <AliExpressMainCheckbox
                                  showProducts="new"
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                  products={aliexpressProducts}
                                />
                              )}
                              {selected_supplier === "wefullfill" && (
                                <MainCheckbox
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                  products={wefullfillProducts}
                                />
                              )}
                              {selected_supplier === "ibacrafts" && (
                                <MainCheckbox
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                  products={ibacraftsProducts}
                                />
                              )}
                              {selected_supplier === "dropsite" && (
                                <DropsiteMainCheckbox
                                  showProducts="new"
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                  products={dropsiteProducts}
                                />
                              )}
                              {selected_supplier === "wedropship" && (
                                <WeDropshipMainCheckbox
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                  products={wedropshipProducts}
                                />
                              )}
                              {selected_supplier === "soga" && (
                                <SogaMainCheckbox
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                  products={sogaProducts}
                                />
                              )}
                              {selected_supplier === "matterhorn" && (
                                <MainCheckbox
                                  showProducts="new"
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                  products={matterhornProducts}
                                />
                              )}
                              {selected_supplier === "dropship" && (
                                <MainCheckbox
                                  showProducts="new"
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                  products={dropshipzoneProducts}
                                />
                              )}
                              {selected_supplier === "vidaxl" && (
                                <VidaXLMainCheckbox
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                  products={vidaxlProducts}
                                />
                              )}
                              {selected_supplier === "fragrancex" && (
                                <FragrancexMainCheckbox
                                  showProducts="new"
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                  products={fragrancexProducts}
                                />
                              )}

                              {selected_supplier === "idropship" && (
                                <MainCheckbox
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                  products={iDropshipProducts}
                                />
                              )}

                              {selected_supplier === "bigbuy" && (
                                <MainCheckbox
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                  products={bigbuyProducts}
                                />
                              )}

                              {selected_supplier === "ingrammicro" && (
                                <IngrammicroMainCheckbox
                                  showProducts="new"
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                  products={ingrammicroProducts}
                                />
                              )}

                              {selected_supplier === "griffati" && (
                                <GriffatiMainCheckbox
                                  showProducts="new"
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                  products={griffatiProducts}
                                />
                              )}
                              {selected_supplier === "serrano" && (
                                <MainCheckbox
                                  showProducts="new"
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                  products={serranoProducts}
                                />
                              )}
                              {selected_supplier === "sunsky" && (
                                <BangoodMainCheckbox
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                products={sunskyProducts}
                              />
                              )}

                              {/* {show_products === "exported" && (
                                <ImportedMainCheckbox
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                  products={exportedProducts}
                                />
                              )}

                              {show_products === "deleted" && (
                                <ImportedMainCheckbox
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                  products={deletedProducts}
                                />
                              )} */}
                            </Grid>

                            <Grid container justifyContent="center">
                              <Typography variant="body2">Image</Typography>
                            </Grid>

                            <Grid container justifyContent="center">
                              <Typography variant="body2">Title</Typography>
                            </Grid>

                            <Grid container justifyContent="center">
                              <Typography variant="body2">Barcode</Typography>
                            </Grid>

                            <Grid container justifyContent="center">
                              <Typography variant="body2">
                                Weight(kg)
                              </Typography>
                            </Grid>
                            <Grid container justifyContent="center">
                              <Typography variant="body2">Cost</Typography>
                              <Typography variant="body2">
                                {selected_supplier === "bangood" && "(USD)"}
                                {selected_supplier === "soga" && "(NZD)"}
                                {selected_supplier === "dropship" && "(AUD)"}
                                {selected_supplier === "matterhorn" && "(USD)"}
                                {selected_supplier === "fragrancex" && "(USD)"}
                                {selected_supplier === "idropship" && "(NZD)"}
                                {selected_supplier === "ingrammicro" && "(NZD)"}
                              </Typography>
                            </Grid>
                            <Grid container justifyContent="center">
                              <Typography variant="body2">
                                Shipping Cost
                              </Typography>
                            </Grid>
                            <Grid container justifyContent="center">
                              <Typography variant="body2">
                                {selected_supplier == "vidaxl" ||
                                selected_supplier == "dropship"
                                  ? "Boxes"
                                  : "Variation"}
                              </Typography>
                            </Grid>
                            <Grid container justifyContent="center">
                              <Typography variant="body2">Stock</Typography>
                            </Grid>
                            <Grid container justifyContent="center">
                              <Typography variant="body2">Supplier</Typography>
                            </Grid>
                            <Grid container justifyContent="center">
                              <Typography variant="body2">
                                Created At
                              </Typography>
                            </Grid>
                            <Grid container justifyContent="center">
                              <Typography variant="body2">Action</Typography>
                            </Grid>
                          </Grid>
                        </Card>

                        {loading_products === false ? (
                          <>
                            {selected_supplier === "bangood" &&
                              bangoodProducts.map((product) => {
                                // console.log("hii this is the bangoodProducts producy=== ", product)
                                return (
                                  <BangoodProductsLayout
                                    key={product.product_id}
                                    product={product}
                                    handleImport={handleImport}
                                    fetchproducts={fetchproducts}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                  />
                                );
                              })}

                            {selected_supplier === "sunsky" &&
                              sunskyProducts.map((product) => {
                                return (
                                  <SunskyProductsLayout
                                    key={product.product_id}
                                    product={product}
                                    handleImport={handleImport}
                                    fetchproducts={fetchproducts}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                  />
                                );
                              })}

                            {selected_supplier === "aliexpress" &&
                              aliexpressProducts.map((product) => {
                                return (
                                  <AliExpressProductsLayout
                                    key={product.sku}
                                    product={product}
                                    handleImport={handleImport}
                                    fetchproducts={fetchproducts}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                  />
                                );
                              })}
                            {selected_supplier === "wefullfill" &&
                              wefullfillProducts.map((product, i) => {
                                if (i == 0) {
                                  console.log(
                                    "second time wefullfill data---------->> ",
                                    wefullfillProducts
                                  );
                                }
                                return (
                                  <WeFullFillProductsLayout
                                    key={product.sku}
                                    product={product}
                                    handleImport={handleImport}
                                    fetchproducts={fetchproducts}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                  />
                                );
                              })}
                            {console.log(
                              "second time ibacraft data---------->> ",
                              ibacraftsProducts
                            )}
                            {selected_supplier === "ibacrafts" &&
                              ibacraftsProducts.map((product) => {
                                return (
                                  <IbaCraftsProductsLayout
                                    key={product.sku}
                                    product={product}
                                    handleImport={handleImport}
                                    fetchproducts={fetchproducts}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                  />
                                );
                              })}
                            {selected_supplier === "wedropship" &&
                              wedropshipProducts.map((product) => {
                                return (
                                  <WeDropshipProductsLayout
                                    key={product.sku}
                                    product={product}
                                    handleImport={handleImport}
                                    fetchproducts={fetchproducts}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                  />
                                );
                              })}
                            {selected_supplier === "dropsite" &&
                              dropsiteProducts.map((product) => {
                                return (
                                  <DropsiteProductsLayout
                                    key={product.klikaCode}
                                    product={product}
                                    handleImport={handleImport}
                                    fetchproducts={fetchproducts}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                  />
                                );
                              })}
                            {selected_supplier === "soga" &&
                              sogaProducts.map((product) => {
                                return (
                                  <SogaProductsLayout
                                    key={product.sku}
                                    product={product}
                                    handleImport={handleImport}
                                    fetchproducts={fetchproducts}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                  />
                                );
                              })}
                            {selected_supplier === "matterhorn" &&
                              matterhornProducts.map((product) => {
                                return (
                                  <MatterHornProductsLayout
                                    key={product.id}
                                    product={product}
                                    checkboxID={product.id}
                                    handleImport={handleImport}
                                    fetchproducts={fetchproducts}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                  />
                                );
                              })}
                            {selected_supplier === "dropship" &&
                              dropshipzoneProducts.map((product) => {
                                return (
                                  <DropshipProductsLayout
                                    key={product.id}
                                    product={product}
                                    checkboxID={product.id}
                                    handleImport={handleImport}
                                    fetchproducts={fetchproducts}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                  />
                                );
                              })}
                            {selected_supplier === "vidaxl" &&
                              vidaxlProducts.map((product) => {
                                return (
                                  <VidaxlProductsLayout
                                    key={product.sku}
                                    product={product}
                                    handleImport={handleImport}
                                    fetchproducts={fetchproducts}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                  />
                                );
                              })}
                            {selected_supplier === "fragrancex" &&
                              fragrancexProducts.map((product) => {
                                return (
                                  <FragrancexProductsLayout
                                    product={product}
                                    key={product.ItemId}
                                    checkboxID={product.ItemId}
                                    handleImport={handleImport}
                                    fetchproducts={fetchproducts}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                  />
                                );
                              })}
                            {selected_supplier === "idropship" &&
                              iDropshipProducts.map((product) => {
                                return (
                                  <IDropshipProductsLayout
                                    key={product.sku}
                                    store={store}
                                    product={product}
                                    handleImport={handleImport}
                                    fetchproducts={fetchproducts}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                  />
                                );
                              })}
                            {selected_supplier === "bigbuy" &&
                              bigbuyProducts.map((product) => {
                                return (
                                  <BigBuyProductsLayout
                                    key={product.sku}
                                    store={store}
                                    product={product}
                                    handleImport={handleImport}
                                    fetchproducts={fetchproducts}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                  />
                                );
                              })}
                            {selected_supplier === "ingrammicro" &&
                              ingrammicroProducts.map((product) => {
                                return (
                                  <IngramMicroProductsLayout
                                    key={product.sku}
                                    store={store}
                                    product={product}
                                    handleImport={handleImport}
                                    fetchproducts={fetchproducts}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                  />
                                );
                              })}
                            {selected_supplier === "griffati" &&
                              griffatiProducts.map((product) => {
                                return (
                                  <GriffatiProductsLayout
                                    key={product.sku}
                                    store={store}
                                    product={product}
                                    handleImport={handleImport}
                                    fetchproducts={fetchproducts}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                  />
                                );
                              })}
                            {selected_supplier === "serrano" &&
                              serranoProducts.map((product) => {
                                return (
                                  <SerranoProductsLayout
                                    key={product.id}
                                    product={product}
                                    checkboxID={product.id}
                                    handleImport={handleImport}
                                    fetchproducts={fetchproducts}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                  />
                                );
                              })}
                          </>
                        ) : (
                          <CircularProgress
                            style={{
                              marginTop: "2%",
                              marginLeft: "45%",
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {tabValue === 1 && (
                  <Grid
                    container
                    alignItems="center"
                    sx={{
                      p: 2,
                      backgroundColor: "bg.paper",
                    }}
                  >
                    <Grid item container mb={1}>
                      <TextField
                        fullWidth
                        multiline
                        size="small"
                        label="Bulk Import By Product SKUs"
                        skulist={skulist}
                        setSkuList={setSkuList}
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item container xs={6}>
                        <Button
                          disabled
                          size="small"
                          variant="contained"
                          // disabled={loading_products ? true : false}
                          onClick={(event) =>
                            setFetchProductCount(fetchproductcount + 1)
                          }
                          sx={{ mr: 1 }}
                        >
                          Click To Import
                        </Button>

                        <Button
                          disabled
                          size="small"
                          variant="outlined"
                          // disabled={loading_products ? true : false}
                        >
                          Clear
                        </Button>
                      </Grid>

                      <Grid item container xs={6} justifyContent="flex-end">
                        <InfoIcon fontSize="small" color="success" />
                        <Typography
                          variant="body2"
                          ml={0.5}
                          color="success.main"
                        >
                          Support Sites: AliExpress, Banggood
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {/* Supported Suppliers */}
            </Grid>
          )}

          {/* ============================= Imported / Exported / Deleted Products ======================= */}
          {show_products !== "new" && (
            <>
              <Grid item my={1}>
                <SourcingQuickFilter />
              </Grid>

              {/* Sort & Search & Pagination */}
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                my={1}
              >
                <Grid item container xs={6} alignItems="center" px={1}>
                  <SourcingSearchbar selected_supplier={selected_supplier} />
                  <PriceFilter
                    prices={prices}
                    setPrices={setPrices}
                    handleFilterByPrice={handleFilterByPrice}
                    handleClearPrice={handleClearPrice}
                  />

                  {selected_supplier === "dropship" && (
                    <Grid item sx={{ ml: 0.5 }}>
                      <DropshipVendorIdFilter
                        selectedDropshipVendorID={selectedDropshipVendorID}
                        setSelectedDropshipVendorID={
                          setSelectedDropshipVendorID
                        }
                      />
                    </Grid>
                  )}

                  {selected_supplier === "aliexpress" && (
                    <RatingFilter setRating={setRating} />
                  )}

                  {selected_supplier === "bangood" && (
                    <BangoodVariantSelect
                      productvariants={productvariants}
                      setProductVariants={setProductVariants}
                    />
                  )}
                </Grid>

                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  xs={6}
                >
                  <SourcingSort
                    selectedSupplier={selected_supplier}
                    ascending={ascending}
                    sortCategory={sortCategory}
                    setSortCategory={setSortCategory}
                    handleSortAscending={handleSortAscending}
                    handleSortDescending={handleSortDescending}
                  />
                  <Pagination
                    page={page}
                    total_counts={total_counts}
                    selectedOrders={selectedOrders}
                    setSelectedOrders={setSelectedOrders}
                  />
                </Grid>
              </Grid>

              {/* Import To Store / Bulk Action / Settings / Import Product */}
              {show_products === "imported" && (
                <Grid
                  item
                  container
                  alignContent="center"
                  sx={{
                    p: 0.5,
                    backgroundColor: "bg.paper",
                    display: "Grid",
                    gridTemplateColumns: "50% 50%",
                    borderRadius: "4px",
                  }}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item>
                      <SmallContainedButton
                        width={130}
                        onClick={handleClickImportToStoreMenu}
                      >
                        Export To Store
                      </SmallContainedButton>

                      <Menu
                        anchorEl={importToStoreMenuAnchorEl}
                        open={openImportToStoreMenu}
                        onClose={handleCloseImportToStoreMenu}
                        MenuListProps={{
                          sx: { width: "100%" },
                        }}
                      >
                        <MenuItem
                          disabled={selectedProducts.length === 0}
                          onClick={(event) => setOpenBulkExport(true)}
                          sx={{ height: 26, fontSize: "0.8rem" }}
                        >
                          Shopify
                        </MenuItem>

                        <MenuItem
                          disabled={selectedProducts.length === 0}
                          onClick={(event) => handleDsz()}
                          sx={{ height: 26, fontSize: "0.8rem" }}
                        >
                          DSZ
                        </MenuItem>

                        <Divider
                          sx={{ color: (theme) => theme.palette.neutral[300] }}
                        />

                        <MenuItem
                          disabled
                          // onClick={(event) => handleSelectedPurchase(event)}
                          sx={{ height: 26, fontSize: "0.8rem" }}
                        >
                          Multiple Stores
                        </MenuItem>

                        <BulkExportModal
                          open={openbulkexport}
                          handleClose={handleCloseBulkExport}
                          selectedProducts={selectedProducts}
                          handleBulkExport={handleBulkExport}
                          exportingProducts={exportingProducts}
                          warehouse_bangood_nz={warehouse_bangood_nz}
                          setWarehouseBangoodNZ={setWarehouseBangoodNZ}
                          shippingmethod_bangood_nz={shippingmethod_bangood_nz}
                          setShippingMethodBangoodNZ={
                            setShippingMethodBangoodNZ
                          }
                          tags={tags}
                          setTags={setTags}
                          exportstatus={exportstatus}
                          product_type={product_type}
                          setProductType={setProductType}
                          permissiontoexport={permissiontoexport}
                          handleExportAliExpress={handleExportAliExpress}
                          exportingStatus={exportingStatus}
                          setExportingStatus={setExportingStatus}
                        />
                      </Menu>
                    </Grid>

                    <Grid item mx={1}>
                      <SmallContainedButton
                        width={110}
                        onClick={handleClickBulkActions}
                      >
                        Bulk Actions
                      </SmallContainedButton>

                      <Menu
                        anchorEl={bulkActionsAnchorEl}
                        open={openBulkActions}
                        onClose={handleCloseBulkActions}
                        MenuListProps={{
                          sx: { width: "100%" },
                        }}
                      >
                        <MenuItem
                          disabled={selectedProducts.length === 0}
                          onClick={(event) => handleBulkDelete()}
                          sx={{ height: 26, fontSize: "0.8rem" }}
                        >
                          Delete Selected
                        </MenuItem>
                      </Menu>
                    </Grid>

                    {selectedProducts.length !== 0 ? (
                      <Grid item>
                        <VideoDownloadSheetModal
                          showProducts="imported"
                          selectedProducts={selectedProducts}
                          openVideoDownloadSheet={openVideoDownloadSheet}
                          setOpenVideoDownloadSheet={setOpenVideoDownloadSheet}
                          handleCloseVideoDownloadSheet={
                            handleCloseVideoDownloadSheet
                          }
                        />
                      </Grid>
                    ) : (
                      <Grid item>
                        <SmallContainedButton disabled width="180px">
                          Download Video Sheet
                        </SmallContainedButton>
                      </Grid>
                    )}

                    {selectedProducts.length !== 0 && (
                      <Grid item>
                        <Typography variant="body2">
                          Selected Products: {selectedProducts.length}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>

                  <Grid
                    item
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <InStockCheckbox />
                    <HasVideoCheckbox />

                    <Grid
                      item
                      container
                      justifyContent="center"
                      alignContent="center"
                      sx={{ cursor: "pointer" }}
                      xs={1.4}
                      onClick={(event) => {
                        handlePage(event, "settings");
                        dispatch(setSubsettings("dropship"));
                        dispatch(setSettingsItem("import_settings"));
                      }}
                    >
                      <SettingsIcon />
                      <Typography variant="body1" mx={1}>
                        Settings
                      </Typography>
                    </Grid>

                    <SmallContainedButton disabled width="140px">
                      Import Product
                    </SmallContainedButton>
                  </Grid>
                </Grid>
              )}

              {/* Imported Listings: Import / Export / Delete Button */}
              {/* <Grid
                container
                sx={{
                  my: 1,
                }}
              >
                <Grid item xs={4}>
                  {selectedProducts.length !== 0 ? (
                    <Grid container alignItems="center">
                      <Button
                        size="small"
                        variant="contained"
                        onClick={(event) => setOpenBulkExport(true)}
                      >
                        EXPORT
                      </Button>

                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={(event) => handleBulkDelete()}
                        sx={{ mx: 1 }}
                      >
                        DELETE
                      </Button>

                      <BulkExportModal
                        open={openbulkexport}
                        handleClose={handleCloseBulkExport}
                        selectedProducts={selectedProducts}
                        handleBulkExport={handleBulkExport}
                        warehouse_bangood_nz={warehouse_bangood_nz}
                        setWarehouseBangoodNZ={setWarehouseBangoodNZ}
                        shippingmethod_bangood_nz={shippingmethod_bangood_nz}
                        setShippingMethodBangoodNZ={setShippingMethodBangoodNZ}
                        tags={tags}
                        setTags={setTags}
                        exportstatus={exportstatus}
                        product_type={product_type}
                        setProductType={setProductType}
                        permissiontoexport={permissiontoexport}
                        handleExportAliExpress={handleExportAliExpress}
                      />

                      <Typography variant="body2">
                        (Selected Products:{selectedProducts.length})
                      </Typography>
                    </Grid>
                  ) : (
                    <>
                      <Button
                        disabled
                        size="small"
                        variant="contained"
                        onClick={(event) => setOpenBulkExport(true)}
                      >
                        EXPORT
                      </Button>
                      <Button
                        disabled
                        size="small"
                        variant="contained"
                        onClick={(event) => handleBulkDelete()}
                        sx={{ mx: 1 }}
                      >
                        DELETE
                      </Button>
                      <BulkExportModal
                        open={openbulkexport}
                        handleClose={handleCloseBulkExport}
                        selectedProducts={selectedProducts}
                        handleBulkExport={handleBulkExport}
                        exportstatus={exportstatus}
                      />
                    </>
                  )}
                </Grid> */}

              {show_products === "exported" && (
                <Grid
                  item
                  container
                  alignContent="center"
                  sx={{
                    p: 0.5,
                    backgroundColor: "bg.paper",
                    display: "Grid",
                    gridTemplateColumns: "50% 50%",
                    borderRadius: "4px",
                  }}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    {selectedProducts.length !== 0 ? (
                      <>
                        {exportstatus.total === 0 ? (
                          <>
                            <SmallContainedButton
                              width="200px"
                              color="error"
                              onClick={(event) => handleDeleteFromShopify()}
                            >
                              DELETE FROM SHOPIFY
                            </SmallContainedButton>

                            <Grid item>
                              <VideoDownloadSheetModal
                                showProducts="exported"
                                selectedProducts={selectedProducts}
                                openVideoDownloadSheet={openVideoDownloadSheet}
                                setOpenVideoDownloadSheet={
                                  setOpenVideoDownloadSheet
                                }
                                handleCloseVideoDownloadSheet={
                                  handleCloseVideoDownloadSheet
                                }
                              />
                            </Grid>
                            <Typography variant="body2">
                              (Selected Products:{selectedProducts.length})
                            </Typography>
                          </>
                        ) : (
                          <CircularProgressWithLabel
                            variant="determinate"
                            value={
                              (exportstatus.created / exportstatus.total) * 100
                            }
                            size={50}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {exportstatus.total === 0 ? (
                          <>
                            <SmallContainedButton disabled width="200px">
                              DELETE FROM SHOPIFY
                            </SmallContainedButton>
                            <SmallContainedButton disabled width="180px">
                              Download Video Sheet
                            </SmallContainedButton>
                          </>
                        ) : (
                          <CircularProgressWithLabel
                            variant="determinate"
                            value={
                              (exportstatus.created / exportstatus.total) * 100
                            }
                            size={50}
                          />
                        )}
                      </>
                    )}
                  </Grid>

                  <Grid
                    item
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <InStockCheckbox />
                    <HasVideoCheckbox />
                  </Grid>
                </Grid>
              )}

              {show_products === "deleted" && (
                <Grid
                  item
                  xs={3}
                  style={{
                    textAlign: "left",
                  }}
                >
                  {selectedProducts.length === 0 ? (
                    <>
                      <Button disabled variant="contained" size="small">
                        EXPORT
                      </Button>
                      <Button
                        disabled
                        variant="contained"
                        size="small"
                        style={{ marginLeft: "1%" }}
                      >
                        DELETE
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={(event) => setOpenBulkExport(true)}
                      >
                        EXPORT
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={(event) => handleBulkDelete()}
                        style={{ marginLeft: "1%" }}
                      >
                        DELETE
                      </Button>
                      <BulkExportModal
                        open={openbulkexport}
                        handleClose={handleCloseBulkExport}
                        selectedProducts={selectedProducts}
                        handleBulkExport={handleBulkExport}
                        exportingProducts={exportingProducts}
                        warehouse_bangood_nz={warehouse_bangood_nz}
                        setWarehouseBangoodNZ={setWarehouseBangoodNZ}
                        shippingmethod_bangood_nz={shippingmethod_bangood_nz}
                        setShippingMethodBangoodNZ={setShippingMethodBangoodNZ}
                        tags={tags}
                        setTags={setTags}
                        exportstatus={exportstatus}
                        product_type={product_type}
                        setProductType={setProductType}
                        permissiontoexport={permissiontoexport}
                        handleExportAliExpress={handleExportAliExpress}
                        exportingStatus={exportingStatus}
                        setExportingStatus={setExportingStatus}
                      />
                      <Typography variant="body2">
                        (Selected Products:{selectedProducts.length})
                      </Typography>
                    </>
                  )}
                </Grid>
              )}
              {/* </Grid> */}

              {/* Imported Listings: Categories */}
              {/* <Grid container style={{ marginTop: "2%" }}>
                <Grid item xs={1.2} sm={1.2} lg={1.2}></Grid>
                {selected_supplier === "matterhorn" ? (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "200px 50%",
                      textAlign: "left",
                      alignItems: "left",
                    }}
                  >
                    <Typography style={{ marginTop: "10%" }}>
                      {filterby === "category" ? (
                        <Button onClick={(event) => setFilterBy("skulist")}>
                          Category
                        </Button>
                      ) : (
                        <Button onClick={(event) => setFilterBy("category")}>
                          SKU's
                        </Button>
                      )}
                      {
                        // <div onClick={(event) => handleClearFilters()} style={{cursor:"pointer", textAlign:"left", fontSize:"10px", color:"blue"}}>Clear All</div>
                      }
                    </Typography>
                    <MatterHornCategorySelect
                      selectedMatterHornCategoryLevel1={
                        selectedMatterHornCategoryLevel1
                      }
                      setSelectedMatterHornCategoryLevel1={
                        setSelectedMatterHornCategoryLevel1
                      }
                      selectedMatterHornCategoryLevel2={
                        selectedMatterHornCategoryLevel2
                      }
                      setSelectedMatterHornCategoryLevel2={
                        setSelectedMatterHornCategoryLevel2
                      }
                      selectedMatterHornCategoryLevel3={
                        selectedMatterHornCategoryLevel3
                      }
                      setSelectedMatterHornCategoryLevel3={
                        setSelectedMatterHornCategoryLevel3
                      }
                      selectedMatterHornCategoryLevel4={
                        selectedMatterHornCategoryLevel4
                      }
                      setSelectedMatterHornCategoryLevel4={
                        setSelectedMatterHornCategoryLevel4
                      }
                      selectedMatterHornCategoryLevel5={
                        selectedMatterHornCategoryLevel5
                      }
                      setSelectedMatterHornCategoryLevel5={
                        setSelectedMatterHornCategoryLevel5
                      }
                      skulist={skulist}
                      setSkuList={setSkuList}
                      filterby={filterby}
                      loading_products={loading_products}
                      fetchproductcount={fetchproductcount}
                      setFetchProductCount={setFetchProductCount}
                    />
                  </div>
                ) : (
                  ""
                )}

                {selected_supplier === "vidaxl" ? (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "200px 50%",
                      textAlign: "left",
                      alignItems: "left",
                    }}
                  >
                    <Typography style={{ marginTop: "10%" }}>
                      {filterby === "category" ? (
                        <Button onClick={(event) => setFilterBy("skulist")}>
                          Category
                        </Button>
                      ) : (
                        <Button onClick={(event) => setFilterBy("category")}>
                          SKU's
                        </Button>
                      )}
                      {
                        // <div onClick={(event) => handleClearFilters()} style={{cursor:"pointer", textAlign:"left", fontSize:"10px", color:"blue"}}>Clear All</div>
                      }
                    </Typography>
                    <VidaXLCategorySelect
                      selectedVidaXLCategoryLevel1={
                        selectedVidaXLCategoryLevel1
                      }
                      setSelectedVidaXLCategoryLevel1={
                        setSelectedVidaXLCategoryLevel1
                      }
                      selectedVidaXLCategoryLevel2={
                        selectedVidaXLCategoryLevel2
                      }
                      setSelectedVidaXLCategoryLevel2={
                        setSelectedVidaXLCategoryLevel2
                      }
                      selectedVidaXLCategoryLevel3={
                        selectedVidaXLCategoryLevel3
                      }
                      setSelectedVidaXLCategoryLevel3={
                        setSelectedVidaXLCategoryLevel3
                      }
                      selectedVidaXLCategoryLevel4={
                        selectedVidaXLCategoryLevel4
                      }
                      setSelectedVidaXLCategoryLevel4={
                        setSelectedVidaXLCategoryLevel4
                      }
                      selectedVidaXLCategoryLevel5={
                        selectedVidaXLCategoryLevel5
                      }
                      setSelectedVidaXLCategoryLevel5={
                        setSelectedVidaXLCategoryLevel5
                      }
                      skulist={skulist}
                      setSkuList={setSkuList}
                      filterby={filterby}
                      loading_products={loading_products}
                      fetchproductcount={fetchproductcount}
                      setFetchProductCount={setFetchProductCount}
                    />
                  </div>
                ) : (
                  ""
                )}
                {selected_supplier === "dropship" ? (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "200px 50%",
                      textAlign: "left",
                      alignItems: "left",
                    }}
                  >
                    <Typography style={{ marginTop: "2%" }}>
                      {filterby === "category" ? (
                        <Button onClick={(event) => setFilterBy("skulist")}>
                          Category
                        </Button>
                      ) : (
                        <Button onClick={(event) => setFilterBy("category")}>
                          SKU's
                        </Button>
                      )}
                      {
                        // <div onClick={(event) => handleClearFilters()} style={{cursor:"pointer", textAlign:"left", fontSize:"10px", color:"blue"}}>Clear All</div>
                      }
                    </Typography>
                    <DropshipCategorySelect
                      selectedDropshipCategoryLevel1={
                        selectedDropshipCategoryLevel1
                      }
                      setSelectedDropshipCategoryLevel1={
                        setSelectedDropshipCategoryLevel1
                      }
                      selectedDropshipCategoryLevel2={
                        selectedDropshipCategoryLevel2
                      }
                      setSelectedDropshipCategoryLevel2={
                        setSelectedDropshipCategoryLevel2
                      }
                      selectedDropshipBrand={selectedDropshipBrand}
                      setSelectedDropshipBrand={setSelectedDropshipBrand}
                      skulist={skulist}
                      setSkuList={setSkuList}
                      filterby={filterby}
                      loading_products={loading_products}
                      fetchproductcount={fetchproductcount}
                      setFetchProductCount={setFetchProductCount}
                    />
                  </div>
                ) : (
                  ""
                )}
                {selected_supplier === "bangood" && (
                  <Grid item container my={1}>
                    <Grid item xs={6}>
                      <Typography style={{ marginTop: "10%" }}>
                        {filterby === "category" ? (
                          <Button onClick={(event) => setFilterBy("skulist")}>
                            Category
                          </Button>
                        ) : (
                          <Button onClick={(event) => setFilterBy("category")}>
                            SKU's
                          </Button>
                        )}
                        {
                          // <div onClick={(event) => handleClearFilters()} style={{cursor:"pointer", textAlign:"left", fontSize:"10px", color:"blue"}}>Clear All</div>
                        }
                      </Typography>

                      <BangoodCategorySelect
                        selectedBangoodCategoryLevel1={
                          selectedBangoodCategoryLevel1
                        }
                        setSelectedBangoodCategoryLevel1={
                          setSelectedBangoodCategoryLevel1
                        }
                        selectedBangoodCategoryLevel2={
                          selectedBangoodCategoryLevel2
                        }
                        setSelectedBangoodCategoryLevel2={
                          setSelectedBangoodCategoryLevel2
                        }
                        selectedBangoodCategoryLevel3={
                          selectedBangoodCategoryLevel3
                        }
                        setSelectedBangoodCategoryLevel3={
                          setSelectedBangoodCategoryLevel3
                        }
                        selectedBangoodCategoryLevel4={
                          selectedBangoodCategoryLevel4
                        }
                        setSelectedBangoodCategoryLevel4={
                          setSelectedBangoodCategoryLevel4
                        }
                        warehouse={warehouse}
                        setWarehouse={setWarehouse}
                        productvariants={productvariants}
                        setProductVariants={setProductVariants}
                        skulist={skulist}
                        setSkuList={setSkuList}
                        filterby={filterby}
                        loading_products={loading_products}
                        fetchproductcount={fetchproductcount}
                        setFetchProductCount={setFetchProductCount}
                        setTags={setTags}
                        setProductType={setProductType}
                      />
                    </Grid>
                  </Grid>
                )}

                {show_products === "imported" &&
                  selected_supplier === "bangood" && (
                    <BangoodVariantSelect
                      productvariants={productvariants}
                      setProductVariants={setProductVariants}
                    />
                  )}

                {selected_supplier === "aliexpress" && (
                  <Grid item container my={1}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        multiline
                        size="small"
                        label="Enter SKUs"
                        skulist={skulist}
                        setSkuList={setSkuList}
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      xs={6}
                      justifyContent="space-evenly"
                      alignItems="center"
                    >
                      <FormControl size="small" sx={{ width: "120px", mx: 1 }}>
                        <InputLabel id="ship-from-select-label">
                          Ship From:
                        </InputLabel>
                        <Select
                          label="Ship From:"
                          value={ship_from}
                          onChange={(event) => setShipFrom(event.target.value)}
                          labelId="ship-from-select-label"
                        >
                          <MenuItem value="CN">CN</MenuItem>
                          <MenuItem value="AU">AU</MenuItem>
                          <MenuItem value="US">US</MenuItem>
                        </Select>
                      </FormControl>

                      <TextField
                        size="small"
                        label="Tag"
                        onChange={(event) => setTags(event.target.value)}
                      />

                      <TextField
                        size="small"
                        label="Type"
                        onChange={(event) => setProductType(event.target.value)}
                        sx={{ mx: 1 }}
                      />

                      <Button
                        size="small"
                        variant="contained"
                        disabled={loading_products ? true : false}
                        onClick={(event) =>
                          setFetchProductCount(fetchproductcount + 1)
                        }
                        sx={{ mx: 1 }}
                      >
                        Click To Import
                      </Button>

                      <Button
                        disabled
                        size="small"
                        variant="outlined"
                        // disabled={loading_products ? true : false}
                        sx={{ mx: 1 }}
                      >
                        Clear
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid> */}

              {/***************** Product Card *************/}
              <Grid item container>
                <Grid item xs={12}>
                  <Card elevation={0} sx={{ width: "100%", mt: 1 }}>
                    <Grid
                      sx={{
                        display: "grid",
                        gridTemplateColumns:
                          "2% 4% 18% 5% 5% 7% 14% 5% 4% 7% 10% 5% 5% 5% 4%",
                        height: "2.6rem",
                        backgroundColor: "bg.grayBG",
                        alignItems: "center",
                      }}
                    >
                      <Grid container justifyContent="center">
                        {/* <ImportedMainCheckbox
                          selectedProducts={selectedProducts}
                          setSelectedProducts={setSelectedProducts}
                          products={importedProducts}
                        /> */}

                        {show_products === "imported" && (
                          <>
                            {selected_supplier === "bangood" && (
                              <BangoodMainCheckbox
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                products={bangoodProducts}
                              />
                            )}
                            {selected_supplier === "aliexpress" && (
                              <AliExpressMainCheckbox
                                showProducts="imported"
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                products={aliexpressProducts}
                              />
                            )}
                            {selected_supplier === "wefullfill" && (
                              <MainCheckbox
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                products={wefullfillProducts}
                              />
                            )}
                            {selected_supplier === "dropsite" && (
                              <DropsiteMainCheckbox
                                showProducts="imported"
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                products={dropsiteProducts}
                              />
                            )}
                            {selected_supplier === "wedropship" && (
                              <WeDropshipMainCheckbox
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                products={wedropshipProducts}
                              />
                            )}
                            {selected_supplier === "soga" && (
                              <SogaMainCheckbox
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                products={sogaProducts}
                              />
                            )}
                            {selected_supplier === "matterhorn" && (
                              <MainCheckbox
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                products={matterhornProducts}
                              />
                            )}
                            {selected_supplier === "dropship" && (
                              <MainCheckbox
                                showProducts="imported"
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                products={dropshipzoneProducts}
                              />
                            )}
                            {selected_supplier === "vidaxl" && (
                              <VidaXLMainCheckbox
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                products={vidaxlProducts}
                              />
                            )}
                            {selected_supplier === "fragrancex" && (
                              <FragrancexMainCheckbox
                                showProducts="imported"
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                products={fragrancexProducts}
                              />
                            )}
                            {selected_supplier === "idropship" && (
                              <MainCheckbox
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                products={iDropshipProducts}
                              />
                            )}
                            {selected_supplier === "bigbuy" && (
                              <MainCheckbox
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                products={bigbuyProducts}
                              />
                            )}

                            {selected_supplier === "ingrammicro" && (
                              <MainCheckbox
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                products={ingrammicroProducts}
                              />
                            )}

                            {selected_supplier === "griffati" && (
                              <MainCheckbox
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                products={griffatiProducts}
                              />
                            )}
                            {selected_supplier === "serrano" && (
                              <MainCheckbox
                                showProducts="imported"
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                products={serranoProducts}
                              />
                            )}
                             {selected_supplier === "sunsky" && (
                              <BangoodMainCheckbox
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                products={sunskyProducts}
                              />
                            )}
                            {selected_supplier === "ibacrafts" && (
                              <MainCheckbox
                                showProducts="imported"
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                products={ibacraftsProducts}
                              />
                            )}
                          </>
                        )}

                        {show_products === "exported" && (
                          <ImportedMainCheckbox
                            selectedProducts={selectedProducts}
                            setSelectedProducts={setSelectedProducts}
                            products={exportedProducts}
                          />
                        )}

                        {show_products === "deleted" && (
                          <ImportedMainCheckbox
                            selectedProducts={selectedProducts}
                            setSelectedProducts={setSelectedProducts}
                            products={deletedProducts}
                          />
                        )}
                      </Grid>

                      <Grid container justifyContent="center">
                        <Typography variant="body2">Image</Typography>
                      </Grid>

                      <Grid container justifyContent="center">
                        <Typography variant="body2">Title</Typography>
                      </Grid>
                      <Grid container justifyContent="center">
                        <Typography variant="body2">Barcode</Typography>
                      </Grid>
                      <Grid container justifyContent="center">
                        <Typography variant="body2">Weight(kg)</Typography>
                      </Grid>
                      <Grid container justifyContent="center">
                        <Typography variant="body2">
                          {show_products === "imported" && "Cost"}
                          {show_products === "exported" && "Price"}
                          {show_products === "deleted" && "Price"}
                          {selected_supplier === "aliexpress" &&
                            currency !== null &&
                            `(${currency})`}
                        </Typography>
                      </Grid>
                      <Grid container justifyContent="center">
                        <Typography variant="body2">
                          Shipping Cost
                          {selected_supplier === "aliexpress" &&
                            currency !== null &&
                            `(${currency})`}
                        </Typography>
                      </Grid>
                      <Grid container justifyContent="center">
                        {selected_supplier === "aliexpress" && (
                          <Typography variant="body2">Ships From</Typography>
                        )}
                      </Grid>
                      <Grid container justifyContent="center">
                        <Typography variant="body2">
                          {selected_supplier == "vidaxl" ||
                          selected_supplier == "dropship"
                            ? "Boxes"
                            : "Variation"}
                        </Typography>
                      </Grid>
                      <Grid container justifyContent="center">
                        <Typography variant="body2">Stock</Typography>
                      </Grid>
                      <Grid container justifyContent="flex-start">
                        <Typography variant="body2">Supplier</Typography>
                      </Grid>
                      <Grid container justifyContent="flex-start">
                        <Typography variant="body2">Status</Typography>
                      </Grid>
                      <Grid container justifyContent="flex-start">
                        <Typography variant="body2">
                          Created At
                          {/* {show_products === "imported" && "Created At"}
                          {show_products === "exported" && "Created At Shopify"}
                          {show_products === "deleted" && "Created At"} */}
                        </Typography>
                      </Grid>

                      <Grid container justifyContent="flex-start">
                        <Typography variant="body2">
                          {show_products === "imported" && "Imported At"}
                          {show_products === "exported" && "Exported At"}
                          {show_products === "deleted" && "Deleted At"}
                        </Typography>
                      </Grid>

                      <Grid container justifyContent="center">
                        <Typography variant="body2">Action</Typography>
                      </Grid>
                    </Grid>
                  </Card>

                  {loading_products === false ? (
                    <>
                      {show_products === "imported" && (
                        <>
                          {selected_supplier === "bangood" &&
                            bangoodProducts.map((product) => {
                              return (
                                <ImportedProductsLayout
                                  product={product}
                                  checkboxID={product.product_id}
                                  handleImport={handleImport}
                                  handleExport={handleExport}
                                  fetchproducts={fetchproducts}
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                />
                              );
                            })}

                         {selected_supplier === "sunsky" &&
                            sunskyProducts.map((product) => {
                              return (
                                <ImportedProductsLayout
                                  product={product}
                                  checkboxID={product.product_id}
                                  handleImport={handleImport}
                                  handleExport={handleExport}
                                  fetchproducts={fetchproducts}
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                />
                              );
                            })}

                          {selected_supplier === "aliexpress" &&
                            aliexpressProducts.map((product) => {
                              return (
                                <ImportedProductsLayout
                                  key={product.product_id}
                                  prices={prices}
                                  product={product}
                                  setCurrency={setCurrency}
                                  checkboxID={product.sku}
                                  handleImport={handleImport}
                                  handleExport={handleExport}
                                  fetchproducts={fetchproducts}
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                />
                              );
                            })}

                          {selected_supplier === "wefullfill" &&
                            wefullfillProducts.map((product, i) => {
                              if (i == 0) {
                                console.log(
                                  "second1 time wefullfill data---------->> ",
                                  wefullfillProducts
                                );
                              }
                              return (
                                <ImportedProductsLayout
                                  product={product}
                                  checkboxID={product.sku}
                                  handleImport={handleImport}
                                  handleExport={handleExport}
                                  fetchproducts={fetchproducts}
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                />
                              );
                            })}

                          {selected_supplier === "ibacrafts" &&
                            ibacraftsProducts.map((product, i) => {
                              if (i == 0) {
                                console.log(
                                  "second1 time ibacrafts data---------->> ",
                                  ibacraftsProducts
                                );
                              }
                              return (
                                <ImportedProductsLayout
                                  product={product}
                                  checkboxID={product.sku}
                                  handleImport={handleImport}
                                  handleExport={handleExport}
                                  fetchproducts={fetchproducts}
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                />
                              );
                            })}

                          {selected_supplier === "wedropship" &&
                            wedropshipProducts.map((product) => {
                              return (
                                <ImportedProductsLayout
                                  product={product}
                                  checkboxID={product.sku}
                                  handleImport={handleImport}
                                  handleExport={handleExport}
                                  fetchproducts={fetchproducts}
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                />
                              );
                            })}

                          {selected_supplier === "dropsite" &&
                            dropsiteProducts.map((product) => {
                              return (
                                <ImportedProductsLayout
                                  product={product}
                                  checkboxID={product.sku}
                                  handleImport={handleImport}
                                  handleExport={handleExport}
                                  fetchproducts={fetchproducts}
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                />
                              );
                            })}

                          {selected_supplier === "soga" &&
                            sogaProducts.map((product) => {
                              return (
                                <ImportedProductsLayout
                                  product={product}
                                  checkboxID={product.sku}
                                  handleImport={handleImport}
                                  handleExport={handleExport}
                                  fetchproducts={fetchproducts}
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                />
                              );
                            })}

                          {selected_supplier === "matterhorn" &&
                            matterhornProducts.map((product) => {
                              return (
                                <ImportedProductsLayout
                                  product={product}
                                  checkboxID={product.sku}
                                  handleImport={handleImport}
                                  handleExport={handleExport}
                                  fetchproducts={fetchproducts}
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                />
                              );
                            })}

                          {selected_supplier === "dropship" &&
                            dropshipzoneProducts.map((product) => {
                              return (
                                <ImportedProductsLayout
                                  product={product}
                                  checkboxID={product.sku}
                                  handleImport={handleImport}
                                  handleExport={handleExport}
                                  fetchproducts={fetchproducts}
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                />
                              );
                            })}

                          {selected_supplier === "vidaxl" &&
                            vidaxlProducts.map((product) => {
                              return (
                                <ImportedProductsLayout
                                  product={product}
                                  checkboxID={product.sku}
                                  handleImport={handleImport}
                                  handleExport={handleExport}
                                  fetchproducts={fetchproducts}
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                />
                              );
                            })}

                          {selected_supplier === "fragrancex" &&
                            fragrancexProducts.map((product) => {
                              return (
                                <ImportedProductsLayout
                                  product={product}
                                  checkboxID={product.sku}
                                  handleImport={handleImport}
                                  handleExport={handleExport}
                                  fetchproducts={fetchproducts}
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                />
                              );
                            })}

                          {selected_supplier === "idropship" &&
                            iDropshipProducts.map((product) => {
                              return (
                                <ImportedProductsLayout
                                  product={product}
                                  checkboxID={product.sku}
                                  handleImport={handleImport}
                                  handleExport={handleExport}
                                  fetchproducts={fetchproducts}
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                />
                              );
                            })}

                          {selected_supplier === "bigbuy" &&
                            bigbuyProducts.map((product) => {
                              return (
                                <ImportedProductsLayout
                                  product={product}
                                  checkboxID={product.sku}
                                  handleImport={handleImport}
                                  handleExport={handleExport}
                                  fetchproducts={fetchproducts}
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                />
                              );
                            })}

                          {selected_supplier === "ingrammicro" &&
                            ingrammicroProducts.map((product) => {
                              return (
                                <ImportedProductsLayout
                                  product={product}
                                  checkboxID={product.sku}
                                  handleImport={handleImport}
                                  handleExport={handleExport}
                                  fetchproducts={fetchproducts}
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                />
                              );
                            })}

                          {selected_supplier === "griffati" &&
                            griffatiProducts.map((product) => {
                              return (
                                <ImportedProductsLayout
                                  product={product}
                                  checkboxID={product.sku}
                                  handleImport={handleImport}
                                  handleExport={handleExport}
                                  fetchproducts={fetchproducts}
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                />
                              );
                            })}

                          {selected_supplier === "serrano" &&
                            serranoProducts.map((product) => {
                              return (
                                <ImportedProductsLayout
                                  product={product}
                                  checkboxID={product.sku}
                                  handleImport={handleImport}
                                  handleExport={handleExport}
                                  fetchproducts={fetchproducts}
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                />
                              );
                            })}
                        </>
                      )}

                      {/* {importedProducts.map((product) => {
                        return (
                          <ImportedProductsLayout
                            product={product}
                            fetchproducts={fetchproducts}
                            selectedProducts={selectedProducts}
                            setSelectedProducts={setSelectedProducts}
                            handleExport={handleExport}
                          />
                        );
                      })} */}

                      {show_products === "exported" &&
                        exportedProducts.map((product) => {
                          return (
                            <ImportedProductsLayout
                              product={product}
                              showProducts="exported"
                              checkboxID={product.sku}
                              setCurrency={setCurrency}
                              fetchproducts={fetchproducts}
                              selectedProducts={selectedProducts}
                              setSelectedProducts={setSelectedProducts}
                              handleExport={handleExport}
                            />
                          );
                        })}

                      {show_products === "deleted" &&
                        deletedProducts.map((product) => {
                          return (
                            <ImportedProductsLayout
                              product={product}
                              showProducts="deleted"
                              checkboxID={product.sku}
                              setCurrency={setCurrency}
                              fetchproducts={fetchproducts}
                              selectedProducts={selectedProducts}
                              setSelectedProducts={setSelectedProducts}
                              handleExport={handleExport}
                            />
                          );
                        })}
                    </>
                  ) : (
                    <Grid
                      item
                      container
                      justifyContent="center"
                      alignContent="center"
                      minHeight="300px"
                    >
                      <Loader size="40px" />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>

        <ReportModal
          open_report={open_report}
          setOpenReport={setOpenReport}
          loading_products={loading_products}
        />
        <FindProductsReportModal
          openFindProductsReport={openFindProductsReport}
          setOpenFindProductsReport={setOpenFindProductsReport}
          loading_products={loading_products}
        />
        <CustomizedSnackbars
          handleClose={handleClose}
          open={open.open}
          message={open.message}
        />
        <CustomizedSnackbarsFailed
          handleClose={handleCloseFailed}
          open={openfailed.open}
          message={openfailed.message}
        />
        <CustomizedSnackbarsFailedEdit
          handleClose={handleCloseFailedEdit}
          open={openfailededit.open}
          message={openfailededit.message}
          order={openfailededit.order}
        />
        <ScrollToTopOrBottom
          scrollToTopId="sourcing_side_menu_list"
          scrollToBottomId="footer"
        />

        <CustomSheetModal
          opencustom={opencustom}
          setOpenCustom={setOpenCustom}
          handleCloseCustom={handleCloseCustom}
          check={"sourcing"}
          supplier={selected_supplier}
          store={store}
        />

       {checkboxopen && <StoreCheckBox checked={checked} setChecked={setChecked} open={checkboxopen} setOpen={setcheckboxOpen} handleImportSunsky={handleImportSunsky}/>}
      </Grid>
    </div>
  );
}

export default SourcingNew;
