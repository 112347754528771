import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import {
  Grid,
  Card,
  Checkbox,
  Typography,
  Button,
  Tab,
  Tabs,
  TextField,
  Divider,
  Link,
  InputAdornment,
  IconButton,
  FormControlLabel,
  FormHelperText,
  FormControl,
  Snackbar,
  Alert,
  AlertTitle,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import Salespro_Logo from "../assets/images/Logo.png";
import Login_BG from "../assets/images/login_bg_4.jpeg";

function Login({ handleLogin, handleApplyForAccess, handleAdminUser }) {
  let SITE_KEY = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_RECAPTCHA_SITE_KEY_REAL : process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  let SECRET_KEY = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_RECAPTCHA_SECRET_KEY_REAL : process.env.REACT_APP_RECAPTCHA_SECRET_KEY;

  let API_DOMAIN_NAME = process.env.REACT_APP_API_DOMAIN_NAME

  const [orders, setOrders] = useState("");

  const [tabValue, setTabValue] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    if (localStorage.rememberMe && localStorage.email !== "") {
      setRememberMe(true);
    }
  }, []);

  const handleCheckRememberMe = () => {
    setRememberMe(!rememberMe);
    window.localStorage.removeItem("email");
    window.localStorage.removeItem("password");
    window.localStorage.removeItem("rememberMe");
  };

  const [forgotPassword, setForgotPassword] = useState(false);
  const handleClickForgotPassword = () => {
    setForgotPassword(!forgotPassword);
  };

  const handlePasswordRecovery = async (values) => {
    let user_email = values.email;
    // fetch(
    //   `${API_DOMAIN_NAME}/auth/checkaccountforpasswordupdate`,
    //   {
    //     method: "post",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify({ user_email: user_email }),
    //   }
    // )
    //   .then((response) => response.json())
    //   .then(setOpenSnackbar(true));
    setOpenSnackbar(true);
  };

  const [captchaToken, setCaptchaToken] = useState("");
  const onCheckReCaptcha = (value) => {
    console.log("ReCaptchaValue", value);
    setCaptchaToken(value);
  };

  //Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  return (
    <>
      <Grid
        container
        sx={{
          backgroundImage: `url(${Login_BG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Overlay */}
        <Grid
          sx={{
            width: "100vw",
            height: "100vh",
            // backgroundColor: "neutral.main",
            // opacity: 0,
          }}
        ></Grid>

        {/* Content */}
        <Grid
          container
          sx={{
            position: "absolute",
            height: "100vh",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid
            item
            container
            sx={{ flexDirection: "column", alignItems: "center" }}
          >
            {/* Logo */}
            <Grid item container justifyContent="center" mt={3} mb={2}>
              <a href="https://saleprodev.com/">
                <img
                  src={Salespro_Logo}
                  alt="Logo"
                  style={{ width: "260px" }}
                />
              </a>
            </Grid>

            {/* Heading Text */}
            <Grid item container flexDirection="column" alignItems="center">
              <Typography
                fontSize="28px"
                color="primary.dark"
                textAlign="center"
              >
                Welcome Back{" "}
                <span style={{ display: "inline-block" }}>Sales Pro Team</span>
              </Typography>

              <Typography fontSize="24px" color="primary.dark">
                Automate your day&nbsp;
                <i className="fa-solid fa-hands-clapping"></i>
              </Typography>
            </Grid>

            {/* Forms */}
            <Grid
              item
              container
              sx={{
                my: 1,
                py: 2,
                width: "96vw",
                maxWidth: "400px",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                backgroundColor: "bg.paper",
                borderRadius: "16px",
                border: (theme) => `1px solid ${theme.palette.primary.light}`,
              }}
            >
              {forgotPassword ? (
                <Formik
                  enableReinitialize={true}
                  initialValues={{ email: "" }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email("invalid Email")
                      .required("Email required"),
                  })}
                  onSubmit={(values, helpers) => {
                    console.log("ssssssssss");

                    let reCaptchaInfo = {
                      // secret: process.env.REACT_APP_RECAPTCHA_SECRET_KEY_REAL,
                      secret: SECRET_KEY,
                      response: captchaToken,
                    };

                    console.log("reCaptchaInfo", reCaptchaInfo);

                    axios
                      .post(
                        `${API_DOMAIN_NAME}/login/captcha`,
                        reCaptchaInfo
                      )
                      .then((res) => {
                        console.log(
                          "res",
                          JSON.parse(JSON.stringify(res)).data[5]
                        );
                        if (
                          JSON.parse(JSON.stringify(res)).data &&
                          JSON.parse(JSON.stringify(res)).data[5].success
                        ) {
                          handlePasswordRecovery(values);
                        } else {
                          helpers.setStatus({ success: false });
                          helpers.setErrors({
                            submit: "Please complete the Captcha",
                          });
                        }
                        helpers.setSubmitting(false);
                      });
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    } = props;

                    return (
                      <form onSubmit={handleSubmit}>
                        <Grid
                          item
                          container
                          sx={{
                            width: "304px",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            item
                            sx={{
                              borderBottom: (theme) =>
                                `2px solid ${theme.palette.primary.main}`,
                            }}
                          >
                            <Typography my={1} color="primary" variant="body2">
                              FORGOT PASSWORD?
                            </Typography>
                          </Grid>

                          <Grid item sx={{ my: 1 }}>
                            <Typography variant="body2">
                              Enter the email address you used when you joined
                              and we’ll send you instructions to reset your
                              password.
                            </Typography>
                          </Grid>

                          <Grid item>
                            <TextField
                              fullWidth
                              size="small"
                              type="email"
                              name="email"
                              value={values.email}
                              placeholder="Email Address *"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(touched.email && errors.email)}
                              helperText={touched.email && errors.email}
                              sx={{
                                my: 1,
                                "& legend": {
                                  width: "unset",
                                },
                              }}
                            />

                            <Grid item xs={12} sx={{ my: 1 }}>
                              <ReCAPTCHA
                                sitekey={
                                  SITE_KEY
                                }
                                onChange={onCheckReCaptcha}
                              />
                            </Grid>

                            <Button
                              fullWidth
                              disableElevation
                              type="submit"
                              color="primary"
                              variant="contained"
                              disabled={isSubmitting}
                              sx={{ my: 1 }}
                            >
                              Send Verification Email
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    );
                  }}
                </Formik>
              ) : (
                <TabContext value={tabValue}>
                  <TabList
                    sx={{ width: "304px" }}
                    onChange={handleChangeTab}
                    aria-label="Login Tabs"
                  >
                    <Tab
                      value={0}
                      label="Log In"
                      sx={{
                        width: "50%",
                        "& span": {
                          borderBottom: (theme) =>
                            `1px solid ${theme.palette.border}`,
                        },
                      }}
                    />
                    <Tab
                      value={1}
                      label="Apply Access"
                      sx={{
                        width: "50%",
                        "& span": {
                          borderBottom: (theme) =>
                            `1px solid ${theme.palette.border}`,
                        },
                      }}
                    />
                  </TabList>
                  <TabPanel value={0} sx={{ width: "304px", px: 0, pb: 0 }}>
                    {
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          email: localStorage.email ? localStorage.email : "",
                          password: localStorage.password
                            ? localStorage.password
                            : "",
                          submit: null,
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string()
                            .email("invalid Email")
                            .required("Email required"),
                          password: Yup.string()
                            .min(6, "password must be 6 character long")
                            .required("Password required"),
                        })}
                        onSubmit={(values, helpers) => {
                          console.log("ssssssssss");

                          if (rememberMe && values.email !== "") {
                            //Not secure
                            localStorage.email = values.email;
                            localStorage.password = values.password;
                            localStorage.rememberMe = rememberMe;
                          }

                          let reCaptchaInfo = {
                            // secret:
                            //   process.env.REACT_APP_RECAPTCHA_SECRET_KEY_REAL,
                            secret: SECRET_KEY,
                            response: captchaToken,
                          };

                          console.log("reCaptchaInfo", reCaptchaInfo);

                          fetch("https://api.saleprodev.com/captcha", {
                            method: "post",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify(reCaptchaInfo),
                          })
                            .then((res) => res.json())
                            .then((res) => {
                              console.log(
                                "res",
                                JSON.parse(JSON.stringify(res))[5]
                              );
                              if (
                                JSON.parse(JSON.stringify(res)) &&
                                JSON.parse(JSON.stringify(res))[5].success
                              ) {
                                handleLogin(values);
                              } else {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({
                                  submit: "Please check the Google reCaptcha",
                                });
                              }
                              helpers.setSubmitting(false);
                            });
                        }}
                      >
                        {(props) => {
                          const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                          } = props;

                          return (
                            <form onSubmit={handleSubmit}>
                              <Grid
                                item
                                container
                                sx={{
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <TextField
                                  fullWidth
                                  size="small"
                                  type="email"
                                  name="email"
                                  value={values.email}
                                  placeholder="Email Address *"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={Boolean(touched.email && errors.email)}
                                  helperText={touched.email && errors.email}
                                  sx={{
                                    my: 1,
                                    "& legend": {
                                      width: "unset",
                                    },
                                  }}
                                />

                                <TextField
                                  fullWidth
                                  size="small"
                                  type={showPassword ? "text" : "password"}
                                  name="password"
                                  value={values.password}
                                  placeholder="Password *"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={Boolean(
                                    touched.password && errors.password
                                  )}
                                  helperText={
                                    touched.password && errors.password
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          edge="end"
                                        >
                                          {showPassword ? (
                                            <Visibility />
                                          ) : (
                                            <VisibilityOff />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{
                                    my: 1,
                                    "& legend": {
                                      width: "unset",
                                    },
                                  }}
                                />

                                <Grid
                                  item
                                  container
                                  sx={{ justifyContent: "space-between" }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        size="small"
                                        color="primary"
                                        checked={rememberMe}
                                        onChange={handleCheckRememberMe}
                                      />
                                    }
                                    label={
                                      <Typography variant="body2">
                                        Remember me
                                      </Typography>
                                    }
                                  />

                                  <Typography
                                    variant="body2"
                                    color="primary"
                                    onClick={handleClickForgotPassword}
                                    sx={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      my: "auto",
                                      "&:hover": {
                                        opacity: 0.8,
                                      },
                                    }}
                                  >
                                    Forgot Password?
                                  </Typography>
                                </Grid>

                                <Grid item xs={12} sx={{ my: 1 }}>
                                  <ReCAPTCHA
                                    sitekey={SITE_KEY}
                                    onChange={onCheckReCaptcha}
                                  />
                                </Grid>

                                <Grid item xs={12} sx={{ my: 0.5 }}>
                                  {errors.submit && (
                                    <FormHelperText error>
                                      {errors.submit}
                                    </FormHelperText>
                                  )}
                                </Grid>

                                <Button
                                  disableElevation
                                  fullWidth
                                  type="submit"
                                  variant="contained"
                                  disabled={isSubmitting}
                                >
                                  Log In
                                </Button>

                                <Grid item my={1}>
                                  <Typography variant="body2">
                                    Don't have an account?&nbsp;
                                    <Typography
                                      color="primary"
                                      variant="body2"
                                      onClick={() => setTabValue(1)}
                                      sx={{
                                        cursor: "pointer",
                                        display: "inline-block",
                                        "&:hover": {
                                          opacity: 0.8,
                                        },
                                      }}
                                    >
                                      <strong>Apply&nbsp;</strong>
                                    </Typography>
                                    access.
                                  </Typography>
                                </Grid>
                              </Grid>
                            </form>
                          );
                        }}
                      </Formik>
                    }
                  </TabPanel>

                  <TabPanel value={1} sx={{ width: "304px", px: 0, pb: 0 }}>
                    {
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          name: "",
                          email: "",
                          phone_number: "",
                          skype_account: "",
                        }}
                        validationSchema={Yup.object().shape({
                          name: Yup.string().required("Name is required"),
                          email: Yup.string()
                            .email("invalid Email")
                            .required("Email is required"),
                          phone_number: Yup.string()
                            .matches(phoneRegExp, "Phone number is not valid")
                            .required("Phone number is required"),
                          skype_account: Yup.string().required(
                            "Skype account is required"
                          ),
                        })}
                        onSubmit={(values, helpers) => {
                          console.log("ssssssssss");

                          let reCaptchaInfo = {
                            // secret:
                            //   process.env.REACT_APP_RECAPTCHA_SECRET_KEY_REAL,
                            secret: SECRET_KEY,
                            response: captchaToken,
                          };

                          console.log("reCaptchaInfo", reCaptchaInfo);

                          axios
                            .post(
                              `${API_DOMAIN_NAME}/login/captcha`,
                              reCaptchaInfo
                            )
                            .then((res) => {
                              console.log(
                                "res",
                                JSON.parse(JSON.stringify(res)).data[5]
                              );
                              if (
                                JSON.parse(JSON.stringify(res)).data &&
                                JSON.parse(JSON.stringify(res)).data[5].success
                              ) {
                                handleApplyForAccess(values);
                              } else {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({
                                  submit: "Please complete the Captcha",
                                });
                              }
                              helpers.setSubmitting(false);
                            });
                        }}
                      >
                        {(props) => {
                          const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                          } = props;

                          return (
                            <form onSubmit={handleSubmit}>
                              <Grid
                                item
                                container
                                sx={{
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <TextField
                                  fullWidth
                                  size="small"
                                  name="name"
                                  value={values.name}
                                  placeholder="Full Name *"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={Boolean(touched.name && errors.name)}
                                  helperText={touched.name && errors.name}
                                  sx={{
                                    my: 1,
                                    "& legend": {
                                      width: "unset",
                                    },
                                  }}
                                />

                                <TextField
                                  fullWidth
                                  size="small"
                                  type="email"
                                  name="email"
                                  value={values.email}
                                  placeholder="Email Address *"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={Boolean(touched.email && errors.email)}
                                  helperText={touched.email && errors.email}
                                  sx={{
                                    my: 1,
                                    "& legend": {
                                      width: "unset",
                                    },
                                  }}
                                />

                                <TextField
                                  fullWidth
                                  size="small"
                                  name="phone_number"
                                  placeholder="Phone Number *"
                                  values={values.phone_number}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={Boolean(
                                    touched.phone_number && errors.phone_number
                                  )}
                                  helperText={
                                    touched.phone_number && errors.phone_number
                                  }
                                  sx={{
                                    my: 1,
                                    "& legend": {
                                      width: "unset",
                                    },
                                  }}
                                />

                                <TextField
                                  fullWidth
                                  size="small"
                                  name="skype_account"
                                  placeholder="Skype Account *"
                                  values={values.skype_account}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={Boolean(
                                    touched.skype_account &&
                                    errors.skype_account
                                  )}
                                  helperText={
                                    touched.skype_account &&
                                    errors.skype_account
                                  }
                                  sx={{
                                    my: 1,
                                    "& legend": {
                                      width: "unset",
                                    },
                                  }}
                                />

                                <Grid item sx={{ my: 1 }}>
                                  <ReCAPTCHA
                                    sitekey={SITE_KEY}
                                    onChange={onCheckReCaptcha}
                                  />
                                </Grid>

                                <Button
                                  fullWidth
                                  disableElevation
                                  type="submit"
                                  variant="contained"
                                  disabled
                                  // disabled={isSubmitting}
                                  sx={{ my: 1 }}
                                >
                                  Apply Access
                                </Button>
                              </Grid>
                            </form>
                          );
                        }}
                      </Formik>
                    }
                  </TabPanel>
                </TabContext>
              )}
              {/* </Grid> */}
            </Grid>
          </Grid>

          {/* Privacy Policy */}
          <Grid
            item
            container
            sx={{
              justifyContent: "center",
              color: "text.primary",
            }}
          >
            {/* <Divider /> */}
            <Typography variant="caption" textAlign="center">
              Google reCAPTCHA used.&nbsp;
              <Link
                href="https://salespro.net.nz/"
                target="_blank"
                underline="none"
                variant="caption"
                sx={{
                  display: "inline-block",
                  "&:hover": {
                    opacity: 0.8,
                  },
                }}
              >
                <strong>Privacy Policy&nbsp;</strong>
              </Link>
              and&nbsp;
              <Link
                href="https://salespro.net.nz/"
                target="_blank"
                underline="none"
                variant="caption"
                sx={{
                  display: "inline-block",
                  "&:hover": {
                    opacity: 0.8,
                  },
                }}
              >
                <strong>Terms of Service&nbsp;</strong>
              </Link>
              apply.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        open={openSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          <AlertTitle>Success</AlertTitle>
          Please follow the instructions sent to your email inbox to reset your
          password.
        </Alert>
      </Snackbar>
    </>
  );
}

export default Login;
