import React, { Component, useState, useEffect } from "react";
import Chart from "react-google-charts";
import Typography from "@mui/material/Typography";
import FixModal from "./fixmodal.js";
import AccuracyModal from "./accuracymodal.js";

function PieChart({ data }) {
  const pieOptions = {
    title: `${data.supplier.toUpperCase()}`,
    pieHole: 0.4,
  };

  const [piedata, setPieData] = useState([
    ["Task", "Hours per Day"],
    ["In Stock", 2],
    ["Out of Stock", 2],
  ]);

  const [total, setTotal] = useState(0);
  const [accuracy, setAccuracy] = useState(0);
  const [discontinued, setDiscontinued] = useState(0);
  const [openerrors, setOpenErrors] = useState(false);
  const [openaccuracy, setOpenAccuracy] = useState(false);

  useEffect(() => {
    setPieData([
      ["Task", "Hours per Day"],
      ["In Stock", data.in_stock],
      ["Out of Stock", data.out_stock],
    ]);
  }, [data]);

  return (
    <div style={{ display: "grid", gridTemplateColumns: "25% 40%" }}>
      <div style={{ marginTop: "10%", textAlign: "left", marginLeft: "2%" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "75% 20%",
            fontSize: "10px",
          }}
        >
          <div>Total Products:</div>
          <div>{data.total}</div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "75% 20%",
            fontSize: "10px",
          }}
        >
          <div>In Stock:</div>
          <div>{data.in_stock}</div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "75% 20%",
            fontSize: "10px",
          }}
        >
          <div>Out of Stock:</div>
          <div>{data.out_stock}</div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "75% 20%",
            fontSize: "10px",
          }}
        >
          <div>Discontinued:</div>
          <div>{data.discontinued}</div>
          <div>(out of stock)</div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "75% 20%",
            fontSize: "10px",
          }}
        >
          <div>Active:</div>
          <div>{data.active_out_stock}</div>
          <div>(out of stock)</div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "75% 20%",
            fontSize: "10px",
          }}
        >
          <div>Accuracy:</div>
          <AccuracyModal
            open={openaccuracy}
            setOpen={setOpenAccuracy}
            error={parseFloat(data.accuracy)}
            data={data}
          />
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "75% 20%",
            fontSize: "10px",
          }}
        >
          <div>Errors:</div>
          <FixModal
            open={openerrors}
            setOpen={setOpenErrors}
            error={100 - data.accuracy}
            data={data}
          />
        </div>
      </div>
      <Chart
        width={"600px"}
        height={"320px"}
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={piedata}
        options={pieOptions}
        rootProps={{ "data-testid": "3" }}
      />
    </div>
  );
}
export default PieChart;
