import React, { useState, useEffect } from "react";
import moment from "moment";
import no_image from "../assets/images/no_image.jpg";
import { Card, Grid, Box, Tooltip, Typography } from "@mui/material";
import ControlledCheckbox from "./vidaxlcheckbox.js";

export default function VidaxlProductsLayout({
  product,
  fetchproducts,
  selectedProducts,
  setSelectedProducts,
  handleImport,
}) {
  const formatDate = (date) => moment(date).format("DD/MM/YYYY HH:mm:ss");

  return (
    <Card sx={{ width: "100%", mt: 1 }}>
      <Grid
        container
        sx={{
          minHeight: "70px",
          display: "Grid",
          gridTemplateColumns: "2% 4% 35% 8% 8% 10% 8% 5% 5% 5% 6% 4%",
          alignItems: "center",
        }}
      >
        {/* Checkbox */}
        <Grid container justifyContent="center">
          <ControlledCheckbox
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            product={product}
          />
        </Grid>

        {/* Image */}
        <Grid container justifyContent="center" alignItems="center" p={0.5}>
          <Tooltip
            arrow
            placement="top"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  padding: 0,
                },
              },
            }}
            title={
              <img
                src={product.images ? product.images.image1 : no_image}
                alt=""
                style={{
                  width: "250px",
                }}
              />
            }
          >
            <Grid
              item
              justifyContent="center"
              alignContent="center"
              sx={{
                minHeight: "60px",
                border: (theme) => `1px solid ${theme.palette.border}`,
              }}
            >
              <img
                src={product.images ? product.images.image1 : no_image}
                alt=""
                width="60px"
              />
            </Grid>
          </Tooltip>
        </Grid>

        {/* Title & Product ID */}
        <Grid container flexDirection="column" pl={1}>
          {product.title && product.title.length > 90 ? (
            <Tooltip arrow placement="top" title={product.title}>
              <Typography variant="body2">
                {`${product.title.slice(0, 90)}...`}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="body2">{product.title}</Typography>
          )}

          <Grid item>
            <Typography
              component="a"
              target="_blank"
              href={product.link}
              variant="body2"
              color="primary"
            >
              Sku #: {product.sku}
            </Typography>
          </Grid>
        </Grid>

        {/* Barcode */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.ean === undefined ||
            product.ean === null ||
            product.ean === ""
              ? "N/A"
              : product.ean}
          </Typography>
        </Grid>

        {/* Weight */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.weight === undefined ||
            product.weight === null ||
            product.weight === ""
              ? "N/A"
              : `${product.weight}kg`}
          </Typography>
        </Grid>

        {/* Cost */}
        <Grid container justifyContent="center">
          <Typography variant="body2">AU${product.b2b_price}</Typography>
        </Grid>

        {/* Shipping Cost */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            AU${parseFloat(product.shipping).toFixed(2)}
          </Typography>
        </Grid>

        {/* Variation */
        console.log("jjjjjjjjjjj", product.number_of_packages)
        }
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.number_of_packages ? product.number_of_packages : 0}
          </Typography>
        </Grid>

        {/* Stock */}
        <Grid container justifyContent="center">
          <Typography variant="body2">{product.stock}</Typography>
        </Grid>

        {/* Supplier */}
        <Grid container justifyContent="center">
          <Typography variant="body2">VidaXL</Typography>
        </Grid>

        {/* Created At */}
        <Grid container justifyContent="center">
          <Typography variant="body2" textAlign="center">
            {formatDate(product.createdAt)}
          </Typography>
        </Grid>

        {/* <div style={{ textAlign: "center" }}>
          {product.import_status === null && "Not Imported"}
          {product.import_status === "imported" && "Imported"}
          {product.import_status === "exported" && "Exported"}
          {product.import_status === "deleted" && "Deleted"}
        </div> */}
        {
          // <div style={{textAlign:"center"}}>
          //   <Typography style={{color:"#5b5b5e"}}><Button variant="contained" size="small" onClick={(event) => handleImport(product)}>Import</Button></Typography>
          // </div>
        }
      </Grid>
    </Card>
  );
}
