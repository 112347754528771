import React, {useEffect,useState} from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SupplierSKU from "./suppliersku"
import {setSearch, setProductsPerPage, setImportPage, setImportTotalPages,setArticles,setSubsettings, setToken, setLoading,setCountrylist,setShippingMethods, setShowImportedProductsFilter,setCategoriesLevel1, setCategoriesLevel2,setCategoriesLevel3, setCategoriesLevel4,setCategoriesLevel5, setCategoriesLevel6,setImportedProducts,setImportedProductsFilter,setSelectedCategoryLevel2,setSelectedCategoryLevel3, setSelectedCategoryLevel4, setSelectedCategoryLevel5, setSelectedCategoryLevel6} from "../../actions"
import {useSelector, useDispatch} from "react-redux"
export default function VariantSelect({product}) {
  const products_per_page = useSelector(state => state.products_per_page)
  const [selectedvariant, setSelectedVariant] = useState("Select a Variant")
  const [count, setCount] = useState(0)
  const dispatch = useDispatch()
  const handleChange = (event) => {
    console.log("value",event.target.value)
    setSelectedVariant(event.target.value)
    setCount(count + 1)
  };


  return (
    <div>
    <Box sx={{ minWidth: 80}}  style={{marginTop:"5px", marginLeft:"5px"}}>
      <FormControl>
        <InputLabel id="demo-simple-select-label"></InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedvariant}
          label="No"
          onChange={handleChange}
          style={{height:"30px"}}
        >
        <MenuItem value={"Select a Variant"}>{"Select a Variant"}</MenuItem>
        {product.variants.map(variant => {
          return (
            <MenuItem value={variant}>{variant.sku}</MenuItem>
          )
        })}
        </Select>
      </FormControl>
    </Box>
    <div>
      <SupplierSKU selectedvariant={selectedvariant} count={count} setCount={setCount} product={product}/>
    </div>
    </div>
  );
}
