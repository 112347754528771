import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import "./settings.css";
import { useSelector, useDispatch } from "react-redux";
import KoganCategories from "./KoganCategories.js";
import AcccessibleTable from "./table.js";
import PaginationOutlined_orders from "./pagination.js";
import { setImportTotalPages } from "../../actions";

function KoganSettings() {
  const dispatch = useDispatch();
  const [koganCategoryLevel1, setKoganCategoryLevel1] =
    React.useState("Select a Category");
  const [koganCategoryLevel2, setKoganCategoryLevel2] =
    React.useState("Select a Category");
  const [category, setCategory] = React.useState("");
  const [koganmappings, setKoganMappings] = useState([]);
  const [mappingcreated, setMappingCreated] = useState(0);
  const products_per_page = useSelector((state) => state.products_per_page);
  const import_page = useSelector((state) => state.import_page);
  const [tagname, setTagName] = useState("");
  const searchField = useSelector((state) => state.searchField);
  console.log(searchField);
  useEffect(() => {
    fetch("https://api.saleprodev.com/koganproducts/getkoganmappings", {
      method: "post",
      body: JSON.stringify({
        products_per_page: products_per_page,
        page: import_page,
        search: searchField,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response.data);
        dispatch(
          setImportTotalPages(parseInt(response.count / products_per_page) + 1)
        );
        setKoganMappings(response.data);
      });
  }, [mappingcreated, products_per_page, import_page, searchField]);

  useEffect(() => {
    if (
      koganCategoryLevel1 !== "Select a Category" &&
      koganCategoryLevel2 !== "Select a Category"
    ) {
      fetch("https://api.saleprodev.com/productimporter/kogancategory", {
        method: "post",
        body: JSON.stringify({
          category1: koganCategoryLevel1,
          category2: koganCategoryLevel2,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setCategory(response);
        });
    }
  }, [koganCategoryLevel1, koganCategoryLevel2]);

  useEffect(() => {
    setKoganCategoryLevel1("Select a Category");
  }, [mappingcreated]);

  useEffect(() => {
    setTagName("");
  }, [mappingcreated]);

  const createkoganmapping = () => {
    console.log({
      mapping: {
        tag_name: tagname,
        categories_level_1: koganCategoryLevel1,
        categories_level_2: koganCategoryLevel2,
        kogancategory: category,
      },
    });
    if (
      tagname === "" ||
      koganCategoryLevel1 === "Select a Category" ||
      koganCategoryLevel2 === "Select a Category"
    ) {
      alert("Details missing");
    } else {
      let mapping = {
        mapping: {
          tag_name: tagname,
          category_level_1: koganCategoryLevel1,
          category_level_2: koganCategoryLevel2,
          kogancategory: category,
        },
      };
      fetch("https://api.saleprodev.com/koganproducts/createkoganmappings", {
        method: "post",
        body: JSON.stringify(mapping),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          if (response.status === "success") {
            setMappingCreated(mappingcreated + 1);
          } else {
            alert(response.message);
          }
        });
    }
  };

  const handleDelete = (tag_name) => {
    fetch("https://api.saleprodev.com/koganproducts/deletekoganmappings", {
      method: "post",
      body: JSON.stringify({ tag_name: tag_name }),
    })
      .then((response) => response.json())
      .then((response) => {
        setMappingCreated(mappingcreated + 1);
      });
  };

  return (
    <Grid container p={1}>
      <Container maxWidth="xl">
        <KoganCategories
          koganCategoryLevel1={koganCategoryLevel1}
          koganCategoryLevel2={koganCategoryLevel2}
          setKoganCategoryLevel1={setKoganCategoryLevel1}
          setKoganCategoryLevel2={setKoganCategoryLevel2}
          createkoganmapping={createkoganmapping}
          setTagName={setTagName}
          tag_name={tagname}
        />
        <PaginationOutlined_orders />
        <AcccessibleTable
          koganmappings={koganmappings}
          handleDelete={handleDelete}
        />
      </Container>
    </Grid>
  );
}

export default KoganSettings;
